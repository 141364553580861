import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { ApiRecheckService } from '../api/api-recheck.service'

@Injectable({
  providedIn: 'root',
})
export class AppRecheckService {
  constructor(private apiRecheckService: ApiRecheckService) {}

  getCreateRechecklist(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiRecheckService.getCreateRechecklist(request).subscribe((res) => {
        resolve(res)
      })
    })
  }

  saveCreateRecheck(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiRecheckService.saveCreateRecheck(request).subscribe((res) => {
        resolve(res)
      })
    })
  }

  getRechecklist(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiRecheckService.getRechecklist(request).subscribe((res) => {
        resolve(res)
      })
    })
  }

  saveRecheckStatus(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiRecheckService.saveRecheckStatus(request).subscribe((res) => {
        resolve(res)
      })
    })
  }

  getManagementRecheckList(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiRecheckService
        .getManagementRecheckList(request)
        .subscribe((res) => {
          resolve(res)
        })
    })
  }

  getManagementRecheckDetail(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiRecheckService
        .getManagementRecheckDetail(request)
        .subscribe((res) => {
          resolve(res)
        })
    })
  }

  getCreateReprintList(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiRecheckService
        .getCreateReprintList(request)
        .subscribe((res) => {
          resolve(res)
        })
    })
  }

  getCreateReprintDetail(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiRecheckService
        .getCreateReprintDetail(request)
        .subscribe((res) => {
          resolve(res)
        })
    })
  }

  saveCreateReprint(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiRecheckService
        .saveCreateReprint(request)
        .subscribe((res) => {
          resolve(res)
        })
    })
  }

  deleteReprint(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiRecheckService
        .deleteReprint(request)
        .subscribe((res) => {
          resolve(res)
        })
    })
  }
}
