<mat-card>
  <mat-card-header>
    <div class="flex" style="width: 100%;justify-content: center;">
      <div style="font-size:1.3rem !important;">
        <strong>แจ้งเตือนบริษัทผู้ส่งออกยังไม่ได้ลงทะเบียนรับเอกสารอิเล็กทรอนิกส์</strong>
      </div>
    </div>
  </mat-card-header>
  <mat-dialog-content>
    <div class="pl-22 pr-22 flex flex-col gap-4">
      <div class="flex gap-2.5" style="align-items: center; flex-direction: column;">
        <div>
          ตรวจสอบพบบริษัทผู้ส่งออกยังไม่ได้ทำการลงทะเบียนอีเมล์เพื่อใช้รับเอกสารใบกำกับภาษีอิเล็กทรอนิกส์
          กรุณาลงทะเบียนโดยเข้าสู่ระบบด้วย User admin ของบริษัทผู้ส่งออก มิฉะนั้นท่านจะไม่สามารถสร้างใบคำขอชำระเงินได้
        </div>
      </div>

      <div class="flex gap-2.5" style="align-items: center; flex-direction: column;">
        <div>
          รายนามบริษัทผู้ส่งออกที่ยังไม่ได้ลงทะเบียนอีเมล์
        </div>

        <div class="mat-elevation-z8">
          <table mat-table matSort [dataSource]="dataSource">

            <!-- Position Column -->
            <ng-container matColumnDef="number">
              <th mat-header-cell *matHeaderCellDef class="text-left">ลำดับ</th>
              <td mat-cell *matCellDef="let element; let i = index" class="text-left">{{ i + 1 }}</td>
            </ng-container>

            <ng-container matColumnDef="companyName">
              <th mat-header-cell *matHeaderCellDef class="text-left">บริษัท</th>
              <td mat-cell *matCellDef="let element">{{ element.companyName }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>

      </div>


      <div class="flex justify-center gap-2.5" style="gap: 10px;">
        <button mat-raised-button [mat-dialog-close]>ปิด</button>
      </div>
    </div>
  </mat-dialog-content>