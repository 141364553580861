import { LiveAnnouncer } from '@angular/cdk/a11y'
import { Component, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort, Sort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { IgetListCOResponse } from 'app/business/interface/api/api-co.interface'
import {
  GetListCORequest,
  IcoStatus,
  IgetListCORequest,
} from 'app/business/interface/app/app-co.interface'
import { AppCOService } from 'app/business/service/app/app-co.service'
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component'
import moment from 'moment'
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'
import { AppMasterService } from 'app/business/service/app/app-master.service'
import { Router } from '@angular/router'
import { AppUserService } from 'app/business/service/app/app-user.service'
import { AppMenuService } from 'app/business/service/app/app-menu.service'
import { debounceTime, map, startWith, switchMap, takeUntil } from "rxjs/operators";
import { HttpLoadingService } from '@bundle/service/http-loading.service';
import { of, Subject } from "rxjs";
import { companySearch } from 'app/business/interface/app/app-company.interace'
import { AppCompanyService } from 'app/business/service/app/app-company.service'
import { AuthService } from 'app/core/auth/auth.service'
import { ConfirmDialogService } from '@bundle/component/confirm-dialog/confirm-dialog.service'
import { ListCoPrintPopupInquiryComponent } from '../list-co-inquiry/list-co-print-popup-inquiry/list-co-print-popup-inquiry.component'

@Component({
  selector: 'app-search-cocf-inquiry',
  templateUrl: './search-cocf-inquiry.component.html',
  styleUrls: ['./search-cocf-inquiry.component.scss'],
})
export class SearchCOCFInquiryComponent implements OnInit {
  dataSource = new MatTableDataSource<any>()
  myDate = new Date()
  displayedColumns: string[] = [
    'requestNo',
    'companyName',
    'docType',
    'expressType',
    'invoiceReference',
    'approveBy',
    'isRqStatus',
    'requestDateTime',
    'approveDateTime',
    'thtNo',
    'receiptNo',
    'action'
  ]
  companyCodeList: any = []
  count: any = 0
  searchString: string
  searchForm: any = {
    certificateNo: '',
    endDate: null,
    startDate: null,
    isRqStatus: null,
    receiptNo: '',
    requestNo: '',
    invoiceReference: '',
  }
  apiRequest: IgetListCORequest = {
    UID: 0,
    userName: '',
    requestNo: '',
    certificateNo: '',
    receiptNo: '',
    payReferenceNo: '',
    invoiceNo: '',
    documentReferenceNo: '',
    isRqstatus: '',
    docType: 'All',
    docId: 0,
    companyCode: 0,
    companyName: '',
  }
  statusOptions: IcoStatus[] = [
    {
      text: 'แสดงทั้งหมด',
      code: '1',
    },
    {
      text: 'รอการตรวจสอบ',
      code: '4',
    },
    {
      text: 'กำลังตรวจสอบ',
      code: '40',
    },
    {
      text: 'ผ่านอนุมัติ',
      code: '5',
    },
    {
      text: 'ไม่ผ่านอนุมัติ',
      code: '10',
    },
    {
      text: 'ชำระเงินสำเร็จ',
      code: '6',
    },
    {
      text: 'พิมพ์ใบรับรอง',
      code: '7',
    },
    {
      text: 'รอเซ็นสด',
      code: '38',
    },
  ]
  exporterOptions: string[]
  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort
  userProfile: any = {}
  pageIndex: any = 0
  url: any;
  buttonList: any[] = [];
  timer: any;
  searchCompanyForm: FormGroup;
  valueSelect: boolean = false;
  isLoading: boolean = false;
  filteredOptions$: any = [];
  constructor(
    private _formBuilder: FormBuilder,
    private _liveAnnouncer: LiveAnnouncer,
    private appCOService: AppCOService,
    private appMasterService: AppMasterService,
    private alertService: AlertMessageService,
    private dialog: MatDialog,
    private router: Router,
    private appUserService: AppUserService,
    private appMenuService: AppMenuService,
    private httpLoadingService: HttpLoadingService,
    private appCompanyService: AppCompanyService,
    private _authService: AuthService,
    private confirmService: ConfirmDialogService,
  ) { }

  async getRouter(): Promise<void> {

    this.url = this.router.url;

    const formData = {
      url: this.url,
      userGroupCode: this.userProfile.userGroupCode,
      userRoleCode: this.userProfile.userRoleCode,
      userCode: this.userProfile.userCode
    };
    this.buttonList = await this.appMenuService.getButtonPermissionPerPages(formData);

  }

  async ngOnInit() {


    this.userProfile = JSON.parse(
      this._authService.loginUser
    )

    this.searchCompanyForm = this._formBuilder.group({
      companySearch: ['']
    });
    this.valueChange();

    await this.getRouter();

    this.apiRequest.UID = this.userProfile.uId
    this.apiRequest.userName = this.userProfile.userName
    this.dataSource.paginator = this.paginator
    this.apiRequest.pagination = {
      Skip:
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
      Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
    }
    // this.appMasterService.getListCompany().then((res: any) => {
    //   if (res === 'error') {
    //     return
    //   } else {
    //     this.companyCodeList = res
    //   }
    // })
    this.loadData(this.apiRequest)
  }
  ngAfterViewInit() {
    this.paginator.pageIndex = 0
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
  }

  async loadData(request: IgetListCORequest) {
    this.apiRequest.keyword = typeof (this.apiRequest.keyword) !== "string" ? "" : this.apiRequest.keyword;
    let rqStatus = parseInt(this.apiRequest.isRqstatus);
      
    if (this.paginator != undefined && this.paginator != null) {
      this.paginator.pageIndex = 0
    }

    let reqList = {
      uId: this.userProfile.uId,
      gId: this.userProfile.gId,
      compId: this.apiRequest.companyCode,
      compName: this.apiRequest.companyName,
      endDate: this.apiRequest.endDate,
      refNo: this.apiRequest.invoiceNo, //ถ้าไม่เช็คมันจะเอาไป where แบบ and มันจะพัง
      reqNo: this.apiRequest.requestNo, //ถ้าไม่เช็คมันจะเอาไป where แบบ and มันจะพัง
      rqStatus: isNaN(rqStatus) ? null : rqStatus,
      thtNo: this.apiRequest.certificateNo, //ถ้าไม่เช็คมันจะเอาไป where แบบ and มันจะพัง
      startDate: this.apiRequest.startDate,
      keyword: this.apiRequest.keyword,
      approveStartDate: this.apiRequest.approveStartDate,
      approveEndDate: this.apiRequest.approveEndDate,
      pagination: {
        Skip: 0,
        Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
      }
    } as GetListCORequest

    await this.appCOService.getListSearchCOCF(reqList).then((res: any) => {
      if (res === 'error') {
        return
      } else {

        if (res !== null) {
          let a = res.response.map((item) => {
            moment.locale('th') // set default locale to Thai
            item.createDate = moment(item.createDate).format('YYYY-MM-DD')
            if (
              item.queueNumber != null &&
              item.queueNumber != undefined &&
              item.queueNumber != ''
            ) {
              item.queueNumber = parseInt(item.queueNumber.slice(-4))
            }
            switch (item.isRqStatus) {
              case '3':
                item.isRqStatus = 'ยังไม่ส่งตรวจ'
                item.action = 3
                break
              case '4':
                item.isRqStatus = 'รอตรวจสอบ'
                item.action = 4
                break
              case '40':
                item.isRqStatus = 'กำลังตรวจสอบ'
                item.action = 40
                break
              case '5':
                item.isRqStatus = 'ผ่านอนุมัติ'
                item.action = 5
                break
              case '10':
                item.isRqStatus = 'ไม่ผ่านอนุมัติ'
                item.action = 10
                break
              case '6':
                item.isRqStatus = 'ชำระเงินสำเร็จ'
                item.action = 6
                break
              case '7':
                item.isRqStatus = 'พิมพ์ใบรับรองแล้ว'
                item.action = 7
                break
              case '38':
                item.isRqStatus = 'รอเซ็นสด'
                item.action = 38
                break
              case '8':
                item.isRqStatus = 'จบกระบวนการ'
                item.action = 8
                break
              case '9':
                item.isRqStatus = 'เจ้าหน้าที่ขอตรวจสอบงานซ้ำ'
                item.action = 9
                break
              case '34':
                item.isRqStatus = 'ยกเลิกคำขอแก้ไข'
                item.action = 34
                break
            }
            switch (item.docPriority) {
              case '1':
                item.docPriority = 'งานแก้ไขเร่งด่วน'
                break
              case '2':
                item.docPriority = 'งานแก้ไขปกติ'
                break
              case '3':
                item.docPriority = 'งานแก้ไข Job '
                break
              case '4':
                item.docPriority = 'งานใหม่เร่งด่วน'
                break
              case '5':
                item.docPriority = 'งานใหม่ปกติ'
                break
            }
            return item
          })
          this.dataSource.data = a
          this.count = res.totalCount
        } else {
          this.dataSource.data = []
          this.count = 0
        }
      }
    })
    this.dataSource.paginator.length = this.count
  }

  // applyFilter(filterValue: string) {
  //   // this.apiRequest.requestNo = filterValue
  //   this.apiRequest.UID = this.userProfile.uId
  //   this.apiRequest.userName = this.userProfile.userName
  //   this.dataSource.paginator = this.paginator
  //   this.apiRequest.pagination = {
  //     Skip:
  //       (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
  //       (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
  //     Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
  //   }
  //   this.apiRequest.keyword = filterValue;
  //   this.loadData(this.apiRequest)
  // }

  applyFilter(event: string) {
    clearTimeout(this.timer); // Clear any existing timer

    this.timer = setTimeout(() => {
      // This code will be executed after a specified delay (e.g., 500 milliseconds)

      // Send the value to the backend or perform any other necessary actions
      this.apiRequest.keyword = event;
      this.dataSource.paginator = this.paginator
      this.loadData(this.apiRequest)

    }, 500); // Specify the desired delay in milliseconds
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`)
    } else {
      this._liveAnnouncer.announce('Sorting cleared')
    }
  }
  stopPropagation(event) {
    event.stopPropagation()
  }
  saveSearch() {
    this.apiRequest.keyword = "";
    this.searchString = "";
    if (
      moment(this.searchForm.startDate).format('YYYY-MM-DD') >
      moment(this.searchForm.endDate).format('YYYY-MM-DD')
    ) {
      this.alertService.open({
        message: 'กรุณาระบุวันที่สิ้นสุดให้มากกว่าวันที่เริ่มต้น',
        title: 'กรุณากรอกข้อมูลค้นหาให้ถูกต้อง',
      })
      return false
    }
    this.apiRequest.UID = this.userProfile.uId
    this.apiRequest.certificateNo = this.searchForm.certificateNo
    this.apiRequest.endDate = this.searchForm.endDate
    this.apiRequest.startDate = this.searchForm.startDate
    this.apiRequest.approveEndDate = this.searchForm.approveEndDate
    this.apiRequest.approveStartDate = this.searchForm.approveStartDate
    this.apiRequest.docType = 'CO'
    this.apiRequest.isRqstatus =
      this.searchForm.status == '1' ? null : this.searchForm.status
    this.apiRequest.receiptNo = this.searchForm.receiptNo
    this.apiRequest.requestNo = this.searchForm.requestNo
    this.apiRequest.invoiceNo = this.searchForm.invoiceReference
    this.apiRequest.documentReferenceNo = this.searchForm.invoiceReference
    this.apiRequest.companyCode = this.searchForm.companyCode
    this.apiRequest.companyName = this.searchForm.companyName
    this.dataSource.paginator = this.paginator
    this.loadData(this.apiRequest)
  }

  onRefresh() {
    this.loadData(this.apiRequest)
  }

  print(e) {
    const data = {
      Hid: e.docId,
    }
    this.appCOService.getDraftCO(data).then((res: any) => {

      if (res === 'error') {
        return
      } else {
        window.open(res.data.linkUrl, '_blank')
      }
    })
  }

  printDraft(e) {

    this.confirmService
      .open({
        header: 'ยืนยันการทำรายการ',
        content: 'ต้องการพิมพ์ Draft หรือไม่',
      }).then((res) => {
        if (res == true && e.docType == 'CO/Pack') {
          const dataList = {
            requestNo: e.requestNo ? e.requestNo : "-",
            docType: e.docType,
            expressType: e.expressType,
            packId: e.packId,
            listPrintCo: true,
            rqstatusObj: e.rqstatusId
          }
          const dialogRef = this.dialog.open(ListCoPrintPopupInquiryComponent, {
            disableClose: false,
            width: '70vw',
            data: dataList,
            maxWidth: 'none',
          })
          dialogRef.afterClosed().subscribe((res) => {
            if (res == false) {
              return
            } else {
              return
            }
          })
        } else if (res == true && e.docType == 'CO') {
          const data = {
            Hid: e.packId,
          }
          this.appCOService.getPreviewCOPack(data).then((res: any) => {
            if (res === 'error') {
              return
            } else {
              window.open(res.linkUrl, '_blank')
            }
          })
        }
      });
  }


  showDetail() { }
  deleteItem(e) {
  }
  editItem() { }
  async pageChanged(e) {
    this.apiRequest.pagination = {
      Skip: (!e.pageIndex ? 0 : e.pageIndex) * (!e.pageSize ? 0 : e.pageSize),
      Take: !e.pageSize ? 10 : e.pageSize,
    }
    this.apiRequest.keyword = typeof (this.apiRequest.keyword) !== "string" ? "" : this.apiRequest.keyword;
    let rqStatus = parseInt(this.apiRequest.isRqstatus);
    let reqList = {
      uId: this.userProfile.uId,
      gId: this.userProfile.gId,
      compId: this.apiRequest.companyCode,
      compName: this.apiRequest.companyName,
      endDate: this.apiRequest.endDate,
      refNo: this.apiRequest.invoiceNo, //ถ้าไม่เช็คมันจะเอาไป where แบบ and มันจะพัง
      reqNo: this.apiRequest.requestNo, //ถ้าไม่เช็คมันจะเอาไป where แบบ and มันจะพัง
      rqStatus: isNaN(rqStatus) ? null : rqStatus,
      thtNo: this.apiRequest.certificateNo, //ถ้าไม่เช็คมันจะเอาไป where แบบ and มันจะพัง
      startDate: this.apiRequest.startDate,
      keyword: this.apiRequest.keyword,
      approveEndDate: this.apiRequest.approveEndDate,
      approveStartDate: this.apiRequest.approveStartDate,
      pagination: this.apiRequest.pagination
    } as GetListCORequest
    await this.appCOService
      .getListSearchCOCF(reqList)
      .then((res: any) => {
        if (res === 'error') {
          return
        } else {
          if (res !== null) {
            let a = res.response.map((item) => {
              switch (item.docPriority) {
                case '1':
                  item.docPriority = 'งานแก้ไขเร่งด่วน'
                  break
                case '2':
                  item.docPriority = 'งานแก้ไขปกติ'
                  break
                case '3':
                  item.docPriority = 'งานแก้ไข Job '
                  break
                case '4':
                  item.docPriority = 'งานใหม่เร่งด่วน'
                  break
                case '5':
                  item.docPriority = 'งานใหม่ปกติ'
                  break
              }
              return item
            })

            this.dataSource.data = a
            this.count = res.totalCount
          } else {
            this.dataSource.data = []
            this.count = 0
          }
        }
      })
    this.dataSource.paginator.pageIndex = e.pageIndex
    this.dataSource.paginator.length = this.count
  }

  clearOption() {
    this.searchForm = {}
    this.apiRequest = {
      UID: 0,
      userName: '',
      requestNo: '',
      certificateNo: '',
      receiptNo: '',
      payReferenceNo: '',
      invoiceNo: '',
      documentReferenceNo: '',
      isRqstatus: '',
      docType: 'All',
      docId: 0,
      companyCode: 0,
      companyName: '',
    };

    this.searchCompanyForm.get('companySearch').setValue('');
    this.loadData(this.apiRequest);
  }

  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find(e => e.buttonCode == buttonCode);

    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }

  selectionChange(e): void {
    this.searchCompanyForm.get('companySearch').setValue(e.option.value.companyCode);
    this.companySelect(e.option.value);
  }

  companySelect(e) {
    this.searchForm.companyCode = e.cpid;
    this.searchForm.companyName = e.companyNameTh;

    this.valueSelect = true;
  }

  valueChange() {
    this.filteredOptions$ = this.searchCompanyForm.get('companySearch').valueChanges.pipe(
      debounceTime(500),
      switchMap(value => {
        if (!value) {
          this.valueSelect = false;
          this.searchForm.companyCode = null;
          return of(null);
        }

        this.httpLoadingService.enabled = false;
        this.isLoading = true;

        const key = value;
        if (key !== '') {
          if (this.valueSelect) {
            return of(null);
          }
          else {
            this.valueSelect = false;
            return this.appCompanyService.getListCompanyByFilter(key);
          }
        } else {
          this.valueSelect = false;
          this.isLoading = false;
          return;
        }

      }),
      map((res: any[]) => {
        this.isLoading = false;
        this.httpLoadingService.enabled = true;



        return res?.map(item => {
          const { companyTaxId, companyNameTh } = (item as companySearch);
          return {
            ...item,
            // tslint:disable-next-line: max-line-length
            display: `${companyTaxId} ${companyNameTh}`
          };
        });
      })
    );
  }

  showDocDetail(element) {
    // Generate the URL with queryParams
    const queryParams = {
      id: element.packId,
      disabled: true,
      search: true,
      staff: true,
      sessionId: this._authService.getUniqueId()
    };

    debugger;

    // Create the URL for the new tab
    let url = '';
    if (element.docType=='CO' || element.docType=='CO/Pack') {
      
      url = this.router.createUrlTree(['/page/request/request-co/create-co'], {
        queryParams,
      }).toString();
  
    } else if (element.docType == 'CF') {
      url = this.router.createUrlTree(['/page/request/request-cf/create-cf'], {
        queryParams,
      }).toString();  
    }

    var fullUrl = this.buildDynamicURL(url);
    // Open the URL in a new tab when the button is clicked
    window.open(fullUrl, '_blank');
  }

  buildDynamicURL(path) {
    // Get the current host from the browser's location
    const currentProtocol = window.location.protocol;
    const currentHost = window.location.hostname;
    const currentPort = window.location.port;
    
    // Remove any trailing slashes from the pathname
    const pathname = window.location.pathname.replace(/\/$/, '');
    
    // Combine the host and pathname to create the base URL
    const baseURL = currentPort ? `${currentProtocol}//${currentHost}:${currentPort}${pathname}/#` : `${currentProtocol}//${currentHost}${pathname}/#`;
    debugger;

    // Combine the base URL with the provided path
    const fullURL = `${baseURL}${path}`;
    
    return fullURL;
  }  
}
