import { MatTooltipModule } from "@angular/material/tooltip";
import { Component, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ConfigBankInquiryComponent } from "./component/config-bank-inquiry/config-bank-inquiry.component";
import { FormsModule } from "@angular/forms";
import { MatNativeDateModule } from "@angular/material/core";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatStepperModule } from "@angular/material/stepper";
import { ReactiveFormsModule } from "@angular/forms";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { MatDatepickerModule } from "@angular/material/datepicker";
import {
  MatDialogModule,
  MAT_DIALOG_DEFAULT_OPTIONS,
} from "@angular/material/dialog";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { RouterModule } from "@angular/router";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSelectModule } from "@angular/material/select";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTableModule } from "@angular/material/table";
import { MatMenuModule } from "@angular/material/menu";
import { ConfigBankPopupComponent } from "./component/config-bank-inquiry/config-bank-popup/config-bank-popup.component";
import { ConfigRelationshipInquiryComponent } from "./component/config-relationship-inquiry/config-relationship-inquiry.component";
import { ConfigRelationshipPopupComponent } from "./component/config-relationship-inquiry/config-relationship-popup/config-relationship-popup.component";
import { ExportDocInquiryComponent } from "./component/export-doc-inquiry/export-doc-inquiry.component";
import { ListCOInquiryComponent } from "./component/list-co-inquiry/list-co-inquiry.component";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { UserProfileComponent } from "./component/profile-inquiry/user-profile-inquiry.component";
import { UserManagementComponent } from "./component/user-management-inquiry/user-management-inquiry/user-management-inquiry.component";
import { UserManagementTablesInquiryComponent } from "./component/user-management-inquiry/user-management-tables-inquiry/user-management-tables-inquiry.component";
import { Moment } from "moment";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { ListCfInquiryComponent } from "./component/list-cf-inquiry/list-cf-inquiry.component";
import { CreateCfInquiryComponent } from "./component/create-cf-inquiry/create-cf-inquiry.component";
import { MatRadioModule } from "@angular/material/radio";
import {
  DxButtonModule,
  DxChartModule,
  DxDiagramModule,
  DxFileManagerModule,
  DxFileUploaderModule,
  DxHtmlEditorModule,
  DxLoadPanelModule,
  DxLookupModule,
  DxPieChartModule,
  DxPivotGridModule,
  DxProgressBarModule,
  DxTreeListModule,
  DxGalleryModule,
  DxLinearGaugeModule,
  DxDataGridModule,
  DxTemplateModule,
  DxTreeViewModule,
} from "devextreme-angular";
import { CreateCfInquiryPopupComponent } from "./component/create-cf-inquiry/create-cf-inquiry-popup/create-cf-inquiry-popup.component";
import { CreateCOInquiryComponent } from "./component/create-co-inquiry/create-co-inquiry.component";
import { MatDividerModule } from "@angular/material/divider";
import { CreateGoodListPopupComponent } from "./component/create-co-inquiry/create-goodlist-popup/create-goodlist-popup.component";
import { ListPrintInquiryComponent } from "./component/list-print-inquiry/list-print-inquiry.component";
import { ConfirmPopupComponent } from "./component/confirm-popup/confirm-popup.component";
import { ListTemplateInquiryComponent } from "./component/list-template-inquiry/list-template-inquiry.component";
import { CreateTemplateInquiryComponent } from "./component/create-template-inquiry/create-template-inquiry.component";
import { RequestJobInquiryComponent } from "./component/request-job-inquiry/request-job-inquiry.component";
import { RequestJobEditInquiryComponent } from "./component/request-job-edit-inquiry/request-job-edit-inquiry.component";
import { ListRePrintInquiryComponent } from "./component/list-re-print-inquiry/list-re-print-inquiry.component";
import { CreateCoPackPopupComponent } from "./component/create-co-inquiry/create-copack-popup/create-copack-popup.component";
import { ListPaymentInquiryComponent } from "./component/payment/list-payment-inquiry/list-payment-inquiry.component";
import { VerifyInquiryComponent } from "./component/verify-inquiry/verify-inquiry.component";
import { EmailConfigInquiryComponent } from "./component/email-config-inquiry/email-config-inquiry.component";
import { UnitControlInquiryComponent } from "./component/unit-control-inquiry/unit-control-inquiry.component";
import { WeightControlInquiryComponent } from "./component/weight-control-inquiry/weight-control-inquiry.component";
import { VerifyCOInquiryComponent } from "./component/verify-co-inquiry/verify-co-inquiry.component";
import { MatExpansionModule } from "@angular/material/expansion";
import { TuiImageEditorModule } from "tui-image-editor-angular";
import { ErrorMarkPopupComponent } from "./component/verify-co-inquiry/error-mark-popup/error-mark-popup.component";
import { MatListModule } from "@angular/material/list";
import { VerifyImageComponent } from "./component/verify-co-inquiry/verify-image/verify-image.component";
import { UnitControlPopupInquiryComponent } from "./component/unit-control-inquiry/unit-control-popup-inquiry/unit-control-popup-inquiry.component";
import { WeightControlPopupInquiryComponent } from "./component/weight-control-inquiry/weight-control-popup-inquiry/weight-control-popup-inquiry.component";
import { PrintPopupInquiryComponent } from "./component/list-print-inquiry/print-popup-inquiry/print-popup-inquiry.component";
import { ReprintPopupInquiryComponent } from "./component/list-re-print-inquiry/reprint-popup-inquiry/reprint-popup-inquiry.component";
import { AttachTypeStaffInquiryComponent } from "./component/attach-type-staff-inquiry/attach-type-staff-inquiry.component";
import { CfTypeManageStaffInquiryComponent } from "./component/cf-type-manage-staff-inquiry/cf-type-manage-staff-inquiry.component";
import { ReprintVerifyModalComponent } from "./component/list-re-print-inquiry/reprint-verify-modal/reprint-verify-modal.component";
import { CfTypeManageStaffPopupComponent } from "./component/cf-type-manage-staff-inquiry/cf-type-manage-staff-popup/cf-type-manage-staff-popup.component";
import { AttachTypeStaffPopupComponent } from "./component/attach-type-staff-inquiry/attach-type-staff-popup/attach-type-staff-popup.component";
import { SearchCOCFInquiryComponent } from "./component/search-cocf-inquiry/search-cocf-inquiry.component";
import { ExchangeRateInquiryComponent } from "./component/exchange-rate-inquiry/exchange-rate-inquiry.component";
import { ExchangeRatePopupComponent } from "./component/exchange-rate-inquiry/exchange-rate-popup/exchange-rate-popup.component";
import { ListBlacklistInquiryComponent } from "./component/list-blacklist-inquiry/list-blacklist-inquiry.component";
import { BlacklistModalComponent } from "./component/list-blacklist-inquiry/blacklist-modal/blacklist-modal.component";
import { DraftCOPopupComponent } from "./component/create-co-inquiry/draft-co-popup/draft-co-popup.component";
import { QueuePopupComponent } from "./component/queue-popup/queue-popup.component";
import { MatSortModule } from "@angular/material/sort";
import { ListFactoryHistoryInquiryComponent } from "./component/list-factory-history-inquiry/list-factory-history-inquiry.component";
import { FactoryHistoryModalComponent } from "./component/list-factory-history-inquiry/factory-history-modal/factory-history-modal.component";
import { CreateInvoicePopupComponent } from "./component/create-co-inquiry/create-invoice-popup/create-invoice-popup";
import { RequestJobPopupComponent } from "./component/request-job-inquiry/request-job-popup/request-job-popup.component";
import { DraftCFPopupComponent } from "./component/create-cf-inquiry/draft-cf-popup/draft-cf-popup.component";
import { ConfigUserAllComponent } from "./component/config-user-all/config-user-all.component";
import { UserRoleModalComponent } from "./component/user-role-modal/user-role-modal.component";
import { MatTabsModule } from "@angular/material/tabs";
import { UserFilterFormComponent } from "./component/user-filter-form/user-filter-form.component";
import { UserRoleFormComponent } from "./component/user-role-form/user-role-form.component";
import { UserListComponent } from "./component/user-list/user-list.component";
import { UserModalComponent } from "./component/user-modal/user-modal.component";
import { MenuFilterPermissionComponent } from "./component/menu-filter-permission/menu-filter-permission.component";
import { ButtonFilterPermissionComponent } from "./component/button-filter-permission/button-filter-permission.component";
import { ButtonPermissionComponent } from "./component/button-permission/button-permission.component";
import { UserRoleFilterFormComponent } from "./component/user-role-filter-form/user-role-filter-form.component";
import { UserRoleListComponent } from "./component/user-role-list/user-role-list.component";
import { UserFormComponent } from "./component/user-form/user-form.component";
import * as moment from "moment";
import "moment/locale/th"; // import Thai locale
import { NewsModalComponent } from "app/page/news-modal/news-modal.component";
import { ThaidatePipe } from "./pipe/thaidate.pipe";
import { VerifyImageDragDropComponent } from "./component/verify-co-inquiry/verify-image-drag-drop/verify-image-drag-drop.component";
import { MenuPermissionComponent } from "./component/menu-permission/menu-permission.component";
import { UserGroupFilterFormComponent } from "./component/user-group-filter-form/user-group-filter-form.component";
import { UserGroupListComponent } from "./component/user-group-list/user-group-list.component";
import { UserGroupModalComponent } from "./component/user-group-modal/user-group-modal.component";
import { CreatePaymentListInquiryComponent } from "./component/payment/create-payment-list-inquiry/create-payment-list-inquiry.component";
import { AddBillCompanyModalComponent } from "./component/payment/add-bill-company-modal/add-bill-company-modal.component";
import { AddPaymentDetailModalComponent } from "./component/payment/add-payment-detail-modal/add-payment-detail-modal.component";
import { AddPaymentRequeestModalComponent } from "./component/payment/add-payment-requset-modal/add-payment-requset-modal.component";
import { AddOtherPaymentRequsetModalComponent } from "./component/payment/add-other-payment-requset-modal/add-other-payment-requset-modal.component";
import { UserGroupFormComponent } from "./component/user-group-form/user-group-form.component";
import { ReportFilterComponent } from "./component/report/report-filter/report-filter.component";
import { ReportListComponent } from "./component/report/report-list/report-list.component";
import { ReportCoApproveComponent } from "./component/report/report-co-approve/report-co-approve.component";

import { CoErrorMarkImagePopupComponent } from "./component/create-co-inquiry/co-error-mark-image-popup/co-error-mark-image-popup.component";
import { CountryManageInquiryComponent } from "./component/country-manage-inquiry/country-manage-inquiry.component";
import { CountryManagePopupComponent } from "./component/country-manage-inquiry/country-manage-popup/country-manage-popup.component";
import { CountryGroupManageInquiryComponent } from "./component/country-group-manage-inquiry/country-group-manage-inquiry.component";
import { CountryGroupManagePopupComponent } from "./component/country-group-manage-inquiry/country-group-manage-popup/country-group-manage-popup.component";
import { CoBranchManageInquiryComponent } from "./component/co-branch-manage-inquiry/co-branch-manage-inquiry.component";
import { CoBranchManagePopupComponent } from "./component/co-branch-manage-inquiry/co-branch-manage-popup/co-branch-manage-popup.component";
import { ConfirmDialogComponent } from "@bundle/component/confirm-dialog/confirm-dialog.component";
import { AlertMessageComponent } from "@bundle/component/alert-message/alert-message.component";
import { ReportNumberCompanyComponent } from "./component/report/report-number-company/report-number-company.component";
import { ReportNumberCompanySumComponent } from "./component/report/report-number-company-sum/report-number-company-sum.component";
import { VerifyRecheckPopupSerchRequestComponent } from "./component/verify-recheck/verify-recheck-popup-serch-request/verify-recheck-popup-serch-request.component";
import { VerifyRecheckInquiryComponent } from "./component/verify-recheck/verify-recheck-inquiry/verify-recheck-inquiry.component";
import { VerifyRecheckPopupDetailComponent } from "./component/verify-recheck/verify-recheck-popup-detail/verify-recheck-popup-detail.component";
import { CfSubtitleStaffInquiryComponent } from "./component/cf-subtitle-staff-inquiry/cf-subtitle-staff-inquiry.component";
import { CfSubtitleStaffPopupComponent } from "./component/cf-subtitle-staff-inquiry/cf-subtitle-staff-popup/cf-subtitle-staff-popup.component";
 
import { FeeRateManageInquiryComponent } from "./component/fee-rate-manage-inquiry/fee-rate-manage-inquiry.component";
import { FeeRateManagePopupComponent } from "./component/fee-rate-manage-inquiry/fee-rate-manage-popup/fee-rate-manage-popup.component";
import { BankCodeManageInquiryComponent } from "./component/bank-code-manage-inquiry/bank-code-manage-inquiry.component";
import { BankCodeManagePopupComponent } from "./component/bank-code-manage-inquiry/bank-code-manage-popup/bank-code-manage-popup.component";
import { EmailConfigAgreementDialogComponent } from "./component/email-config-inquiry/email-config-agreement-dialog/email-config-agreement-dialog.component";
import { ProvinceManageInquiryComponent } from "./component/province-manage-inquiry/province-manage-inquiry.component";
import { ProvinceManagePopupComponent } from "./component/province-manage-inquiry/province-manage-popup/province-manage-popup.component";
import { DistrictManageInquiryComponent } from "./component/district-manage-inquiry/district-manage-inquiry.component";
import { DistrictManagePopupComponent } from "./component/district-manage-inquiry/district-manage-popup/district-manage-popup.component";
import { SubDistrictManageInquiryComponent } from "./component/sub-district-manage-inquiry/sub-district-manage-inquiry.component";
import { SubDistrictManagePopupComponent } from "./component/sub-district-manage-inquiry/sub-district-manage-popup/sub-district-manage-popup.component";
import { KindExportManageInquiryComponent } from "./component/kind-export-manage-inquiry/kind-export-manage-inquiry.component";
import { KindExportManagePopupComponent } from "./component/kind-export-manage-inquiry/kind-export-manage-popup/kind-export-manage-popup.component";
import { MarkTypeHeaderInquiryComponent } from "./component/mark-type-header-inquiry/mark-type-header-inquiry.component";
import { MarkTypeHeaderPopupComponent } from "./component/mark-type-header-inquiry/mark-type-header-popup/mark-type-header-popup.component";
import { MarkTypeDetailInquiryComponent } from "./component/mark-type-detail-inquiry/mark-type-detail-inquiry.component";
import { MarkTypeDetailPopupComponent } from "./component/mark-type-detail-inquiry/mark-type-detail-popup/mark-type-detail-popup.component";
import { ViewVerifyInquiryComponent } from "./component/view-verify-inquiry/view-verify-inquiry.component";
import { TwoDigitDecimaNumberDirective } from '@bundle/service/two-digit-decimal-number.directive';
import { ReportExportByCountryComponent } from "./component/report/report-export-by-country/report-export-by-country.component";
import { ReportExportByGroupCountryComponent } from "./component/report/report-export-by-group-country/report-export-by-group-country.component";
import {ListReprintCreatePopupComponent} from "./component/list-re-print-inquiry/list-reprint-create-popup/list-reprint-create-popup.component";

import { CompanyListInquiryComponent } from "./component/company-list-inquiry/company-list-inquiry.component";
import { ConnectMrDialogComponent } from "./component/connect-mr-dialog/connect-mr-dialog.component";
import { ReportCustomsTariffStatisticsComponent } from "./component/report/report-customs-tariff-statistics/report-customs-tariff-statistics.component";
import { RecheckManagementInquiryComponent } from "./component/recheck-management-inquiry/verify-recheck-inquiry/recheck-management-inquiry.component";
import { RecheckManagementPopupComponent } from "./component/recheck-management-inquiry/verify-recheck-inquiry/recheck-management-popup/recheck-management-popup.component";
import { HarmonizedRestrictedInquiryComponent } from "./component/harmonized-restricted-inquiry/harmonized-restricted-inquiry.component";
import { HarmonizedSettingDialogComponent } from "./component/harmonized-setting-dialog/harmonized-setting-dialog.component";
import { ReportProductTariffStatisticsComponent } from "./component/report/report-product-tariff-statistics/report-product-tariff-statistics.component";
import { NotRegisteredEmailAlertDialogComponent } from "./component/not-registered-email-alert-dialog/not-registered-email-alert-dialog.component";
import { CreateReprintInquiryComponent } from "./component/list-re-print-inquiry/create-reprint-inquiry/create-reprint-inquiry.component";
import { VerifyReprintInquiryComponent } from "./component/verify-reprint-inquiry/verify-reprint-inquiry.component";
import { CreateVerifyReprintInquiryComponent } from "./component/verify-reprint-inquiry/create-verify-reprint-inquiry/create-verify-reprint-inquiry.component";
import { CompanyProfileFormComponent } from "./component/company-profile-form/company-profile-form.component";
import { CompanyAddressGridComponent } from "./component/company-profile-form/company-address-grid/company-address-grid.component";
import { CompanyMemberHistoryGridComponent } from "./component/company-profile-form/company-member-history-grid/company-member-history-grid.component";
import { CompanyAuthorizedSignatureGridComponent } from "./component/company-profile-form/company-authorized-signature-grid/company-authorized-signature-grid.component";
import { UploadDocumentDatagridComponent } from "./component/upload-document-datagrid/upload-document-datagrid.component";
import { NotificationDetailsComponent } from "app/layout/common/notifications/notification-details/notification-details.component";
import { CreateTransferJobComponent } from "./component/verify-co-inquiry/create-transfer-job/create-transfer-job.component";
import { ReportMemberOfDocumentCertificateComponent } from "./component/report/report-member-of-document-certificate/report-member-of-document-certificate.component";
import { ListAuthorityViewInquiryComponent } from "./component/list-authority-view-inquiry/list-authority-view-inquiry.component";
import { VerifyHistoryListInquiryComponent } from "./component/verify-history-list-inquiry/verify-history-list-inquiry.component";
import { VerifyFactoryDetailComponent } from "./component/verify-co-inquiry/verify-factory-detail/verify-factory-detail.component";
import { ReportEditCoCfComponent } from "./component/report/report-edit-co-cf/report-edit-co-cf.component";
import { UploadDocumentReprintComponent } from "./component/upload-document-datagrid/upload-document-reprint/upload-document-reprint.component";
import { ImageModalComponent } from "./component/create-co-inquiry/Image-Modal/Image-Modal.component";
import { BlacklistSearchFactoryModalComponent } from "./component/list-blacklist-inquiry/blacklist-search-factory-modal/blacklist-search-factory-modal.component";
import { DetailBillPaymentModalComponent } from "./component/payment/detail-bill-payment-modal/detail-bill-payment-modal.component";
import { ListCoPrintPopupInquiryComponent } from "./component/list-co-inquiry/list-co-print-popup-inquiry/list-co-print-popup-inquiry.component";
import { CreateCoMemoComponent } from "./component/create-co-inquiry/create-co-memo/create-co-memo.component";
import { CreateCfMemoComponent } from "./component/create-cf-inquiry/create-cf-memo/create-cf-memo.component";
import { PrintCoPackPopupInquiryComponent } from "./component/list-print-inquiry/print-co-pack-popup-inquiry/print-co-pack-popup-inquiry.component";
import { LiveSignatureListInquiryComponent } from "./component/live-signature-list-inquiry/live-signature-list-inquiry.component";
import { ReportNumberCertificatesComponent } from "./component/report/report-number-certificates/report-number-certificates.component";
import { ReportNumberOnMonthCertificatesComponent } from "./component/report/report-number-on-month-certificates/report-number-on-month-certificates.component";
import { PullJobModalComponent } from "./component/payment/pull-job-modal/pull-job-modal.component";
import { VerifyItemDetailComponent } from "./component/verify-co-inquiry/verify-item-detail/verify-item-detail.component";
import { ListCoSendPrintToStaffPopupComponent } from "./component/list-co-inquiry/list-co-send-print-to-staff-popup-inquiry/list-co-send-print-to-staff-popup-inquiry.component";
import { LogPrintHistoryComponent } from "./component/log-print-history-component/log-print-history.component";
import { PasswordChangeComponent } from "./component/profile-inquiry/password-change/password-change.component";
import { DetailPaymentListTableComponent } from "./component/payment/detail-payment-list-table/detail-payment-list-table.component";
import { DocumentEditFormComponent } from "./component/document-edit-form/document-edit-form.component";
import { VerifyImageRemarkComponent } from "./component/verify-co-inquiry/verify-image-remark/verify-image-remark.component"; 
// import { PdfViewerModule } from "ng2-pdf-viewer";
const businessComps = [
  ConfigBankInquiryComponent,
  ConfigBankPopupComponent,
  ConfigRelationshipInquiryComponent,
  ConfigRelationshipPopupComponent,
  ExportDocInquiryComponent,
  UserProfileComponent,
  UserManagementComponent,
  UserManagementTablesInquiryComponent,
  AlertMessageComponent,
  ListCOInquiryComponent,
  // MenuComponent,
  ListCfInquiryComponent,
  CreateCfInquiryComponent,
  CreateCfInquiryPopupComponent,
  ListPrintInquiryComponent,
  ConfirmPopupComponent,
  CreateCfInquiryPopupComponent,
  CreateCOInquiryComponent,
  CreateGoodListPopupComponent,
  ListTemplateInquiryComponent,
  RequestJobInquiryComponent,
  RequestJobEditInquiryComponent,
  ListRePrintInquiryComponent,
  CreateTemplateInquiryComponent,
  CreateCoPackPopupComponent,
  EmailConfigInquiryComponent,
  EmailConfigAgreementDialogComponent,
  UnitControlInquiryComponent,
  WeightControlInquiryComponent,
  ListPaymentInquiryComponent,
  VerifyInquiryComponent,
  VerifyCOInquiryComponent,
  ErrorMarkPopupComponent,
  VerifyImageComponent,
  UnitControlPopupInquiryComponent,
  WeightControlPopupInquiryComponent,
  CfSubtitleStaffInquiryComponent,
  CfSubtitleStaffPopupComponent,
  CountryManageInquiryComponent,
  CountryManagePopupComponent,
  CountryGroupManageInquiryComponent,
  CountryGroupManagePopupComponent,
  CoBranchManageInquiryComponent,
  CoBranchManagePopupComponent,
  PrintPopupInquiryComponent,
  ReprintPopupInquiryComponent,
  AttachTypeStaffInquiryComponent,
  CfTypeManageStaffInquiryComponent,
  ReprintVerifyModalComponent,
  CfTypeManageStaffPopupComponent,
  AttachTypeStaffPopupComponent,
  SearchCOCFInquiryComponent,
  ExchangeRateInquiryComponent,
  ExchangeRatePopupComponent,
  ListBlacklistInquiryComponent,
  BlacklistModalComponent,
  DraftCOPopupComponent,
  QueuePopupComponent,
  ListFactoryHistoryInquiryComponent,
  FactoryHistoryModalComponent,
  CreateInvoicePopupComponent,
  RequestJobPopupComponent,
  DraftCFPopupComponent,
  ConfigUserAllComponent,
  UserRoleModalComponent,
  UserFilterFormComponent,
  UserRoleFormComponent,
  UserListComponent,
  UserModalComponent,
  MenuFilterPermissionComponent,
  ButtonFilterPermissionComponent,
  ButtonPermissionComponent,
  UserRoleFilterFormComponent,
  UserRoleListComponent,
  UserFormComponent,
  NewsModalComponent,
  VerifyImageDragDropComponent,
  ThaidatePipe,
  CoErrorMarkImagePopupComponent,
  MenuPermissionComponent,
  UserGroupFilterFormComponent,
  UserGroupListComponent,
  UserGroupModalComponent,
  UserGroupFormComponent,
 
  CreatePaymentListInquiryComponent,
  AddBillCompanyModalComponent,
  AddPaymentDetailModalComponent,
  AddPaymentRequeestModalComponent,
  AddOtherPaymentRequsetModalComponent,
  ConfirmDialogComponent,
  AlertMessageComponent,
  AlertMessageComponent,
  VerifyRecheckPopupSerchRequestComponent,
  VerifyRecheckInquiryComponent,
  VerifyRecheckPopupDetailComponent,
  FeeRateManageInquiryComponent,
  FeeRateManagePopupComponent,
  BankCodeManageInquiryComponent,
  BankCodeManagePopupComponent,
  ProvinceManageInquiryComponent,
  ProvinceManagePopupComponent,
  DistrictManageInquiryComponent,
  DistrictManagePopupComponent,
  SubDistrictManageInquiryComponent,
  SubDistrictManagePopupComponent,
  KindExportManageInquiryComponent,
  KindExportManagePopupComponent,
  MarkTypeHeaderInquiryComponent,
  MarkTypeHeaderPopupComponent,
  MarkTypeDetailInquiryComponent,
  MarkTypeDetailPopupComponent,
  TwoDigitDecimaNumberDirective,
  ViewVerifyInquiryComponent, 
  CompanyListInquiryComponent,
  ConnectMrDialogComponent,
  // ReportListComponent,
  // ReportFilterComponent,
  // ReportExportByGroupCountryComponent,
  // ReportExportByCountryComponent,
  // ReportCoApproveComponent,
  // ReportNumberCompanyComponent,
  // ReportCustomsTariffStatisticsComponent,
  // ReportProductTariffStatisticsComponent,
  // ReportMemberOfDocumentCertificateComponent,
  // ReportEditCoCfComponent, 
  // ReportNumberCertificatesComponent,
  // ReportNumberOnMonthCertificatesComponent,
  RecheckManagementInquiryComponent,
  RecheckManagementPopupComponent,
  HarmonizedRestrictedInquiryComponent,
  HarmonizedSettingDialogComponent,
  ListReprintCreatePopupComponent,
  NotRegisteredEmailAlertDialogComponent,
  CreateReprintInquiryComponent,
  VerifyReprintInquiryComponent,
  CreateVerifyReprintInquiryComponent,
  CompanyProfileFormComponent,
  CompanyAddressGridComponent,
  CompanyMemberHistoryGridComponent,
  CompanyAuthorizedSignatureGridComponent,
  UploadDocumentDatagridComponent,
  CreateTransferJobComponent,
  ListAuthorityViewInquiryComponent,
  NotificationDetailsComponent,
  VerifyHistoryListInquiryComponent,
  VerifyFactoryDetailComponent,
  UploadDocumentReprintComponent,
  ImageModalComponent,
  BlacklistSearchFactoryModalComponent,
  DetailBillPaymentModalComponent,
  ListCoPrintPopupInquiryComponent,
  CreateCoMemoComponent,
  CreateCfMemoComponent,
  PrintCoPackPopupInquiryComponent,
  LiveSignatureListInquiryComponent,
  PullJobModalComponent,
  VerifyItemDetailComponent,
  ListCoSendPrintToStaffPopupComponent,
  LogPrintHistoryComponent,
  PasswordChangeComponent,
  DetailPaymentListTableComponent,
  DocumentEditFormComponent,
  VerifyImageRemarkComponent, 
  // PdfViewerModule
]
const importComp = [
  MatSortModule,
  MatNativeDateModule,
  MatFormFieldModule,
  MatInputModule,
  MatStepperModule,
  ReactiveFormsModule,  
  FormsModule,
  DragDropModule,
  RouterModule,
  MatDialogModule,
  MatProgressBarModule,
  MatDatepickerModule,
  ScrollingModule,
  MatIconModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatSelectModule,
  MatPaginatorModule,
  MatTableModule,
  MatMenuModule,
  MatAutocompleteModule,
  MatSlideToggleModule,
  MatRadioModule,
  DxButtonModule,
  DxChartModule,
  DxDiagramModule,
  DxFileManagerModule,
  DxFileUploaderModule,
  DxHtmlEditorModule,
  DxLoadPanelModule,
  DxLookupModule,
  DxPieChartModule,
  DxPivotGridModule,
  DxProgressBarModule,
  DxTreeListModule,
  DxTreeViewModule,
  DxGalleryModule,
  DxLinearGaugeModule,
  DxTemplateModule,
  DxDataGridModule,
  MatDividerModule,
  MatExpansionModule,
  TuiImageEditorModule,
  MatListModule,
  MatTabsModule,
  MatTooltipModule
];

export class _DateAdapter extends MomentDateAdapter {
  format(date: Moment, displayFormat: string): string {
    return `${date.year()}-${date.format("MM")}-${date.format("DD")} `;
  }
}

@NgModule({
  declarations: [businessComps],
  imports: [CommonModule, importComp],
  exports: [businessComps, importComp],
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { maxWidth: "95vw", hasBackdrop: true },
    },
    {
      provide: MatMenuModule,
      useValue: { maxWidth: "95vw", hasBackdrop: true },
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: ["YYYY-MM-DD"],
        },
        display: {
          dateInput: "YYYY-MM-DD",
          monthYearLabel: "MMMM YYYY",
          dateA11yLabel: "LL",
          monthYearA11yLabel: "MMMM YYYY",
        },
      },
    },
  ],
})
export class BusinessModule {
  constructor(private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale("en-US");
  }
}
