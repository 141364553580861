<mat-card>
  <mat-card-header>
    <div class="flex justify-between" style="width: 100%;">
      <div class="text-lg"> Harmonized Restricted </div>
      <div>
        <button class="close-button" [mat-dialog-close]="true">
          <mat-icon class="text-white" [mat-dialog-close]>cancel</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-dialog-content>
    <div class="pl-22 pr-22 flex flex-col gap-4">
      <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
        <div class="mat-popup-header flex flex-col">
          <h4>รหัสสินค้า <span style="color:red;">*</span></h4>
          <h4>Item Code</h4>
        </div>
        <div class="mat-popup flex flex-row justify-between items-center" style="width:74%">
          <div>:</div>
          <mat-form-field style="flex: 0 95%;" class="flex">
            <input required autocomplete="off" type="text" matInput placeholder="รหัสสินค้า" matInput #input
              [disabled]="isDisabled" [(ngModel)]="form.itemCode" name="name" #name="ngModel">
          </mat-form-field>
        </div>
      </div>

      <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
        <div class="mat-popup-header flex flex-col">
          <h4>รายละเอียด <span style="color:red;">*</span></h4>
          <h4>Description</h4>
        </div>
        <div class="mat-popup flex flex-row justify-between items-center" style="width:74%">
          <div>:</div>
          <mat-form-field style="flex: 0 95%;" class="flex">
            <input required autocomplete="off" type="text" matInput placeholder="รายละเอียด" matInput #input
              [(ngModel)]="form.itemDescription" name="name" #name="ngModel">
          </mat-form-field>
        </div>
      </div>

      <div class="flex-row flex justify-between gap-2.5">
        <div class="mat-popup-header flex flex-col">
          <h4>สินค้าควบคุม</h4>
          <h4>Restricted Item</h4>
        </div>
        <div class="mat-popup flex flex-row justify-between items-center" style="width:74%">
          <div>:</div>
          <mat-slide-toggle [checked]="form.isRestricted === 'Y' ? true : false" (change)="onChangeRestricted($event)"
            style="flex: 0 95%;" class="flex">
            {{isRestricted}} </mat-slide-toggle>
        </div>
      </div>

      <ng-container *ngFor="let buttonList of buttonList">
        <ng-container *ngIf="buttonList.buttonCode=='BT085'">
          <div class="flex-row flex justify-between gap-2.5">
            <div class="mat-popup-header flex flex-col">
              <h4>ใช้งาน</h4>
              <h4>Active</h4>
            </div>
            <div class="mat-popup flex flex-row justify-between items-center" style="width:74%">
              <div>:</div>
              <mat-slide-toggle [checked]="form.isActive === 'Y' ? true : false" (change)="onChange($event)"
                style="flex: 0 95%;" class="flex">
                Active</mat-slide-toggle>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!isDisabled">
        <div class="flex-row flex justify-between gap-2.5">
          <div class="warn-message">
            <div style="align-items: center; display: flex;">
              <mat-icon svgIcon="heroicons_outline:exclamation" style="color:#a4994b;"></mat-icon>
            </div>
            <div>
              กรุณาตรวจสอบข้อมูลให้ครบถ้วนก่อนการบันทึก หากบันทึกแล้วจะไม่สามารถแก้ไขรหัสสินค้าในภายหลังได้
            </div>
          </div>
        </div>
      </ng-container>

      <div class="flex-row flex justify-center gap-2.5" style="gap: 10px;">
        <button mat-raised-button color="accent" (click)="save()">บันทึก</button>
        <button mat-raised-button [mat-dialog-close]>ยกเลิก</button>
      </div>
    </div>
  </mat-dialog-content>