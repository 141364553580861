<div class="idx">
    <mat-card-header cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <div class="flex justify-between" style="width: 100%;">
            <div class="text-lg"> เพิ่มเงื่อนไข </div>
            <div>
                <button class="close-button" (click)="cancel()">
                    <mat-icon class="text-white">cancel</mat-icon>
                </button>
            </div>
        </div>
    </mat-card-header>

    <mat-dialog-content class="mat-typography">
        <div style="display: flex;flex-flow: column;" class="pl-5 pr-5 mb-3 justify-start gap-4">
            <!-- <div class="mat-button flex flex-col"> -->
            <div class="flex-auto flex flex-row gap-3 mb-3 items-center">
                <div class="w-1/4 flex flex-col">
                    <h4>ประเภท Field <span style="color: red">*</span></h4>
                </div>
                <div class="w-3/4 flex flex-row gap-3 items-center">
                    <h4>:</h4>
                    <mat-form-field class="flex-auto ">
                        <mat-select [placeholder]="placeholderDocType" [(ngModel)]="dataForm.fieldId" name="fieldId"
                            #fieldId="ngModel" (selectionChange)="fieldTypeChange($event)" required>
                            <mat-option *ngFor="let markType of markTypeList  " [value]="markType.id">
                                {{markType.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="flex-auto flex flex-row gap-3 mb-3 items-center">
                <div class="w-1/4 flex flex-col">
                    <h4>ตัวอย่าง Mark</h4>
                </div>
                <div class="w-3/4 flex flex-row gap-3 items-center">
                    <h4>:</h4>
                    <mat-form-field class="flex-auto ">
                        <mat-select [placeholder]="placeholderField" [(ngModel)]="dataForm.TypeDescription"
                            (ngModelChange)="exampleChange($event)">
                            <mat-option *ngFor="let markDetail of markDetailList  " [value]="markDetail">
                                {{markDetail.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="flex-auto flex flex-row gap-3 mb-3">
                <div class="w-1/4 flex flex-col">
                    <h4>เพิ่มเติม <span style="color: red">*</span></h4>
                </div>
                <div class="w-3/4 flex flex-row gap-3 ">
                    <h4>:</h4>
                    <mat-form-field class="flex-auto ">
                        <textarea matInput [(ngModel)]="dataForm.remark" name="remark" #remark="ngModel"
                            maxlength="255" required></textarea>
                    </mat-form-field>
                </div>
            </div>
            <!-- </div> -->
        </div>

    </mat-dialog-content>

    <mat-dialog-actions align="center">
        <button mat-raised-button color="accent" (click)="save()" [disabled]="((dataForm.remark !== null && dataForm.remark !== undefined && dataForm.remark === '') && (dataForm.fieldId !== 0))">บันทึก</button>
        <!-- <button  mat-raised-button color="accent" mat-dialog-close (click)="cancel()">ยกเลิก</button> -->

    </mat-dialog-actions>
</div>