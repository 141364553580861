import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ConfirmPopupComponent } from '../../confirm-popup/confirm-popup.component';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { AppFactoryManagementService } from 'app/business/service/app/app-factorymanagement.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
@Component({
  selector: 'app-print-popup-inquiry',
  templateUrl: './print-popup-inquiry.component.html',
  styleUrls: ['./print-popup-inquiry.component.scss']
})
export class PrintPopupInquiryComponent implements OnInit {
  
  form: any = {
    companyNameTH: '',
    companyNameEN: '',
    contact: '',
    email: '',
    phone: '',
    address: [],
    lockStatus: '',
    grade: '',
    attachments: {},
    history: {},
    id: '',
    companyId: ''
  }
  addrOpt: any;
  bankList: any;
  displayedColumns: string[] = ['user', 'date', 'status', 'grade'];
  dataSource2 = new MatTableDataSource<any>();
  editItem: boolean
  attachTypeList: any[] = [];
  positionPrint: any[] = [false, false, false, false, false, false, false, false, false];

  checkall: any = false;

  file: any
  value: any[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef: MatDialogRef<PrintPopupInquiryComponent>,
    public dialog2: MatDialogRef<ConfirmPopupComponent>,
    private dialog: MatDialog,
    private appFactoryManagement: AppFactoryManagementService,
  ) {
    
    this.form.id = data.id;
    this.form.totalPage = data.totalPage;
    this.form.page = 0;
    this.form.checkPrint = this.positionPrint;
    this.form.hid = data.hid;
    this.form.rqNo = data.rqNo;
  }
  ngOnInit(): void {
  }


  save() {
    this.form.checkPrint = this.positionPrint;
    this.dialogRef.close({ data: this.form });
  }


  clickCheck(event: MatCheckboxChange,e) {
    if ( this.positionPrint.filter(Boolean).length < this.form.totalPage && this.positionPrint[e] == false) {
      this.positionPrint[e] = !this.positionPrint[e];
    } else if (this.positionPrint[e] == true) {
      this.positionPrint[e] = false;
    }
    
    if (this.positionPrint.filter(Boolean).length < this.form.totalPage) {
      if (this.positionPrint[e] == false) {
        this.form.page = Math.max(this.form.page - 1, 0);
      }
      else {
        this.form.page = Math.min(this.form.page + 1, this.form.totalPage);
      }
    } else {
      this.form.page = Math.min(this.form.page + 1, this.form.totalPage);
    }
    
  }

  async onCheckboxChange(event: MatCheckboxChange, e) {
    if (this.positionPrint[e]) {
      if ( this.positionPrint.filter(Boolean).length > this.form.totalPage) {
        this.positionPrint[e] = false;
        event.source.checked = false;
        event.checked = false;
      } 
      this.form.page = Math.min(this.form.page + 1, this.form.totalPage);
    } 
    else {
      this.form.page = Math.max(this.form.page - 1, 0);
    }
  }
  onCheckAll(){
    if(this.form.totalPages == 9){
      for(var i = 9; i<=9 ; i++ ){
        this.positionPrint[i] = true;
      }
      this.checkall = true;
    }
    else{
      this.checkall = false;
    }
  }

}
