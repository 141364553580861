import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AppBankService } from 'app/business/service/app/app-bank.service';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { ConfirmPopupComponent } from '../../confirm-popup/confirm-popup.component';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'
@Component({
  selector: 'app-unit-control-popup-inquiry',
  templateUrl: './unit-control-popup-inquiry.component.html',
  styleUrls: ['./unit-control-popup-inquiry.component.scss']
})
export class UnitControlPopupInquiryComponent implements OnInit {
  form: any = {};
  bankList: any;
  buttonList: any[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef: MatDialogRef<UnitControlPopupInquiryComponent>,
    private appMasterService: AppMasterService,
    private dialog: MatDialog,
    private alertMessageService: AlertMessageService
  ) {
    this.buttonList = data.buttonList;
    if (data.e) {
      this.form = data.e;
    } else {
      this.form.isActive = 'Y';
    }
    
  }
  ngOnInit(): void {
    const data = {
      "code": this.form.code,
    };
    this.appMasterService.getListUnit(data).then((res: any) => {
      if (res === "error") {
        return;
      } else {
        this.bankList = res;
        
      }
    });
  }

  save() {
    
    if (this.form.code !== undefined && this.form.code !== null && this.form.code !== "") {
      const data = {
        "name": this.form.name,
        "code": this.form.code,
        "longName": this.form.longName,
        "pluralName": this.form.pluralName,
        "isActive": this.form.isActive
      };
      this.appMasterService.saveUnit(data).then((res: any) => {
        if (res === "error") {
          this.alertMessageService.open({message: "บันทึกข้อมูลไม่สำเร็จ", title:""});
        } else {
          this.dialogRef.close();
          this.alertMessageService.open({message: "บันทึกข้อมูลสำเร็จ", title:""});
        }
      });
    } else {
      this.alertMessageService.open({message: "กรุณากรอกข้อมูลให้ครบ", title:"ข้อผิดพลาด"});
    }

  }

  onChange(e) {
    if (e.checked == true) {
      this.form.isActive = "Y";
    } else {
      this.form.isActive = "N";
    }
  }

  endPopup(e) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      disableClose: false,
      // width: '30vw',
      data: {
        header: e,
        button: 'close'
      },
      maxWidth: 'none'
    });

    dialogRef.afterClosed().subscribe(res => {
      this.dialogRef.close();
    });
  }

}
