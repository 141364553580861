import { LiveAnnouncer } from '@angular/cdk/a11y'
import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  Inject,
} from '@angular/core'
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort, Sort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { Router } from '@angular/router'
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'
import { IGetListJobRequest } from 'app/business/interface/app/app-job.interface'
import { IGetListCreatePopupVerifyRecheckRequest } from 'app/business/interface/app/app-verify-interface'
import { AppCOService } from 'app/business/service/app/app-co.service'
import { AppRecheckService } from 'app/business/service/app/app-recheck.service'
import { AppRequestService } from 'app/business/service/app/app-request.service'
import { VerifyRecheckPopupDetailComponent } from '../verify-recheck-popup-detail/verify-recheck-popup-detail.component'
import { DxDataGridComponent } from 'devextreme-angular'
import moment from 'moment'
import { ISaveRecheckStatusRequest } from 'app/business/interface/api/api-verify.interface'
import { ConfirmDialogService } from '@bundle/component/confirm-dialog/confirm-dialog.service'
import { RecheckManagementPopupComponent } from '../../recheck-management-inquiry/verify-recheck-inquiry/recheck-management-popup/recheck-management-popup.component'

@Component({
  selector: 'app-verify-recheck-popup-serch-request',
  templateUrl: './verify-recheck-popup-serch-request.component.html',
  styleUrls: ['./verify-recheck-popup-serch-request.component.css'],
})
export class VerifyRecheckPopupSerchRequestComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort
  displayedColumns: string[] = [
    'rqNo',
    'declarator',
    'expressType',
    'requestDate',
    'approveDate',
    'docType',
    'approvalResults',
    'action',
  ]
  searchForm: IGetListCreatePopupVerifyRecheckRequest = {
    rqNo: '',
    payNo: '',
    thtNo: '',
    compCode: '',
  }
  dataSource = new MatTableDataSource<any>()
  count: number
  apiRequest: IGetListCreatePopupVerifyRecheckRequest = {
    rqNo: '',
    payNo: '',
    thtNo: '',
    compCode: '',
    pagination: {
      Skip: 0,
      Take: 10,
    },
  }
  searchString: string
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private _liveAnnouncer: LiveAnnouncer,
    private appRequestService: AppRequestService,
    private dialog: MatDialog,
    private alertService: AlertMessageService,
    private AlertMessageService: AlertMessageService,
    public dialogRef: MatDialogRef<VerifyRecheckPopupSerchRequestComponent>,
    private router: Router,
    private appRecheckService: AppRecheckService,
    
    private confirmDialogService: ConfirmDialogService,
  ) {}
  ngOnInit() {
    this.dataSource.paginator = this.paginator
    this.apiRequest.pagination = {
      Skip:
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
      Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
    }
    this.loadData(this.apiRequest)
  }
  ngAfterViewInit() {
    this.paginator.pageIndex = 0
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
  }

  sendVerify(e)
  {
    
    
    const request = {
      recheckId: e.recheckId,
      isActive: 'Y',
      approveType:'W'
    } as ISaveRecheckStatusRequest
    this.confirmDialogService
      .open({ header: '', content: 'ยืนยันที่จะยื่นคำขอ?' })
      .then((res) => {
        if (res == true) {
          this.appRecheckService.saveRecheckStatus(request).then((res) => {
            if (res) {
              this.AlertMessageService.open({
                title: '',
                message: 'ยื่นคำขอสำเร็จ',
              }).then((res) => {
                this.loadData(this.apiRequest)
              })
            } else {
              return
            }
          })
        } else {
          return
        }
      })
  }
  cancel(e){
    
    
    const request = {
      recheckId: e.recheckId,
      isActive: 'Y',
      approveType:'-'
    } as ISaveRecheckStatusRequest
    this.confirmDialogService
      .open({ header: '', content: 'ยืนยันที่จะยกเลิกคำขอ?' })
      .then((res) => {
        if (res == true) {
          this.appRecheckService.saveRecheckStatus(request).then((res) => {
            if (res) {
              this.AlertMessageService.open({
                title: '',
                message: 'ยกเลิกขอสำเร็จ',
              }).then((res) => {
                this.loadData(this.apiRequest)
              })
            } else {
              return
            }
          })
        } else {
          return
        }
      })
  }

  showDetail(e) {
    const data = {
      recheckId: e.recheckid,
      packId: e.packId,
      rqNo: e.rqNo,
      compName: '',
      reason: e.reason,
      forRecheck: '',
      forRecheckName: '',
      approveType: '',
      approveTypeName: '',
    }

    const dialogRef = this.dialog.open(RecheckManagementPopupComponent, {
      data: { data: data, flag: false },
      width: '60vw',
      maxWidth: 'none',
    })

    dialogRef.afterClosed().subscribe((res) => {
      this.loadData(this.apiRequest)
    })
  }

  verifyRecheck(element: any){
    
  }
  async loadData(request: any) {
     
    if (this.paginator != undefined && this.paginator != null) {
      this.paginator.pageIndex = 0
    }
    
    request.pagination = {
      Skip: 0, // Start from page 1 (skip 0)
      Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
    };

    await this.appRecheckService
      .getCreateRechecklist(request)
      .then((res: any) => {
        if (res === 'error') {
          return
        } else {
          if (res !== null) {
            let a = res.response.map((item) => {
              moment.locale('th') // set default locale to Thai
              item.requestDate = moment(item.requestDate).format('YYYY-MM-DD')
              // switch (item.rqStatus) {
              //   case "3":
              //     item.rqStatus = "ยังไม่ส่งตรวจ";
              //     item.action = 3;
              //     break;
              //   case "4":
              //     item.rqStatus = "รอตรวจสอบ";
              //     item.action = 4;
              //     break;
              //   case "40":
              //     item.rqStatus = "กำลังตรวจสอบ";
              //     item.action = 40;
              //     break;
              //   case "5":
              //     item.rqStatus = "ผ่านอนุมัติ";
              //     item.action = 5;
              //     break;
              //   case "10":
              //     item.rqStatus = "ไม่ผ่านอนุมัติ";
              //     item.action = 10;
              //     break;
              //   case "6":
              //     item.rqStatus = "ชำระเงินสำเร็จ";
              //     item.action = 6;
              //     break;
              //   case "7":
              //     item.rqStatus = "พิมพ์ใบรับรองแล้ว";
              //     item.action = 7;
              //     break;
              //   case "38":
              //     item.rqStatus = "รอเซ็นสด";
              //     item.action = 38;
              //     break;
              //   case "8":
              //     item.rqStatus = "จบกระบวนการ";
              //     item.action = 8;
              //     break;
              //   case "9":
              //     item.rqStatus = "เจ้าหน้าที่ขอตรวจสอบงานซ้ำ";
              //     item.action = 9;
              //     break;
              //   case "34":
              //     item.rqStatus = "ยกเลิกคำขอแก้ไข";
              //     item.action = 34;
              //     break;
              // }
              // switch (item.docPriority) {
              //   case '1':
              //     item.docPriority = 'งานแก้ไขเร่งด่วน'
              //     break
              //   case '2':
              //     item.docPriority = 'งานแก้ไขปกติ'
              //     break
              //   case '3':
              //     item.docPriority = 'งานแก้ไข Job '
              //     break
              //   case '4':
              //     item.docPriority = 'งานใหม่เร่งด่วน'
              //     break
              //   case '5':
              //     item.docPriority = 'งานใหม่ปกติ'
              //     break
              // }
              return item
            })
            this.dataSource.data = a
            
            
            this.count = res.totalCount
          } else {
            this.dataSource.data = []
            this.count = 0
          }
        }
      })
    this.dataSource.paginator.length = this.count
  }

  async pageChanged(e) {
    this.apiRequest.pagination = {
      Skip: (!e.pageIndex ? 0 : e.pageIndex) * (!e.pageSize ? 0 : e.pageSize),
      Take: !e.pageSize ? 10 : e.pageSize,
    }
    await this.appRecheckService
      .getCreateRechecklist(this.apiRequest)
      .then((res: any) => {
        if (res === 'error') {
          return
        } else {
          if (res !== null) {
            let a = res.response.map((item) => {
              moment.locale('th') // set default locale to Thai
              item.requestDate = moment(item.requestDate).format('YYYY-MM-DD')
              item.approveDate = moment(item.approveDate).format('YYYY-MM-DD')
              // switch (item.rqStatus) {
              //   case "3":
              //     item.rqStatus = "ยังไม่ส่งตรวจ";
              //     item.action = 3;
              //     break;
              //   case "4":
              //     item.rqStatus = "รอตรวจสอบ";
              //     item.action = 4;
              //     break;
              //   case "40":
              //     item.rqStatus = "กำลังตรวจสอบ";
              //     item.action = 40;
              //     break;
              //   case "5":
              //     item.rqStatus = "ผ่านอนุมัติ";
              //     item.action = 5;
              //     break;
              //   case "10":
              //     item.rqStatus = "ไม่ผ่านอนุมัติ";
              //     item.action = 10;
              //     break;
              //   case "6":
              //     item.rqStatus = "ชำระเงินสำเร็จ";
              //     item.action = 6;
              //     break;
              //   case "7":
              //     item.rqStatus = "พิมพ์ใบรับรองแล้ว";
              //     item.action = 7;
              //     break;
              //   case "38":
              //     item.rqStatus = "รอเซ็นสด";
              //     item.action = 38;
              //     break;
              //   case "8":
              //     item.rqStatus = "จบกระบวนการ";
              //     item.action = 8;
              //     break;
              //   case "9":
              //     item.rqStatus = "เจ้าหน้าที่ขอตรวจสอบงานซ้ำ";
              //     item.action = 9;
              //     break;
              //   case "34":
              //     item.rqStatus = "ยกเลิกคำขอแก้ไข";
              //     item.action = 34;
              //     break;
              // }
              // switch (item.docPriority) {
              //   case '1':
              //     item.docPriority = 'งานแก้ไขเร่งด่วน'
              //     break
              //   case '2':
              //     item.docPriority = 'งานแก้ไขปกติ'
              //     break
              //   case '3':
              //     item.docPriority = 'งานแก้ไข Job '
              //     break
              //   case '4':
              //     item.docPriority = 'งานใหม่เร่งด่วน'
              //     break
              //   case '5':
              //     item.docPriority = 'งานใหม่ปกติ'
              //     break
              // }
              return item
            })
            this.dataSource.data = a
            this.count = res.totalCount
          } else {
            this.dataSource.data = []
            this.count = 0
          }
        }
      })

    this.dataSource.paginator.length = this.count
    this.dataSource.paginator.pageIndex = e.pageIndex
  }
  editItem(e) {
    this.dialog.closeAll()
  }

  createJob(e) {
    
    const data = {
      declarator: e.declarator,
      recheckId: e.recheckId,
      packId: e.packId,
      reason: e.reason,
      rqNo: e.rqNo,
      result : e.rqStatus
    }
    const dialogRef = this.dialog.open(VerifyRecheckPopupDetailComponent, {
      disableClose: false,
      width: '60vw',
      data: data,
      maxWidth: 'none',
    })
    dialogRef.afterClosed().subscribe((res) => {
      if (res == false) {
        return
      } else {
        
        this.loadData(this.apiRequest)
      }
    })
  }
  applyFilter(event: string) {
    this.apiRequest.thtNo = event
    this.apiRequest.rqNo = event
    this.dataSource.paginator = this.paginator
    this.loadData(this.apiRequest)
  }
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`)
    } else {
      this._liveAnnouncer.announce('Sorting cleared')
    }
  }
  stopPropagation(event) {
    event.stopPropagation()
  }

  clearOption() {
    this.searchForm = {}
  }
  saveSearch() {
    this.apiRequest.thtNo = this.searchForm.thtNo
    this.apiRequest.rqNo = this.searchForm.rqNo;
    this.apiRequest.payNo = this.searchForm.payNo;
    this.apiRequest.compCode = this.searchForm.compCode
    this.dataSource.paginator = this.paginator
    this.loadData(this.apiRequest)
  }
}
