// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,
  apiHops: "http://202.183.221.134/hops_enhance/api/",
  
  //------------------Local
  // api: 'http://localhost:40000',
  // api : 'http://127.0.0.1:8080',
  // api: 'https://5a0f-180-183-11-167.ngrok-free.app',

  //------------------UAT Real
  // api: 'https://dev.mudjaicrm.com/tcc-co-api',

  //------------------Prod
  api:'https://co-api.thaichamber.org/',

  version: "version 3.0.0",
  apiBackCom: "http://202.183.221.130",
  apiIntegrate: "https://localhost:5002/api/v1/integrate/",
  filterSalseSuportShopByProject: true,
  publicPassKey:
    "-----BEGIN PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAwKWjsBeya/rJoXsJ8O64XGm+AnoQSQfRphNagps/0sqRFpLiKTLfHnzcjW+woflf0BG/VxHnvTw9VgLlhuRAwrN7Jc3dXZHXuJ0LA3kkZlsyoo1J7e2lABIJ7hHuHP198maMja440U/I/b1UgPPOIjFgrLzRwxgOzm4wxCr0JFPmNGX2Jv7J/AhSmU14ta1jVFLLIQvlebUROTaXmboOtfGTtiSJATDfE+aZTk5IUOY4Hk9yQTEkJT0oNEQejkJl8IzBz4GO6gaXLzUUtFcpPF0n5/KQ2Odq3F3lpCa7/Zg3HVMslEQcLQ1jMK1CcDQFGNAzu1miE0remMKdSqCZgQIDAQAB-----END PUBLIC KEY-----",

  enableNotiPopupTcp: false,

  filterSalseSuportShopByCondition: [
    {
      project: "SR",
      data: ["ดูแลร้านค้า"],
    },
    {
      project: "VN",
      data: ["Salesman", "ดูแลร้านค้า", "ดูแลพื้นที่"],
    },
  ],
  azureAd: {
    clientId: "6ff0e886-d4b4-490b-9c0b-0b7c1931ee2e",
    tenant: "9274fc4b-71e6-4ef9-8ef7-aa70decd0605",
    authority: "https://login.microsoftonline.com",
    redirectUri: "",
  },
  protalPage: [
    {
      code: "promotion",
      name: "รายการส่งเสริมการขาย",
      activeList: ["shop"],
    },
    {
      code: "incentiveHistory",
      name: "ผลตอบแทน",
      activeList: ["shop"],
    },
    {
      code: "target",
      name: "เป้า/ยอดซื้อ",
      activeList: ["shop"],
    },
    {
      code: "activity",
      name: "กิจกรรม",
      activeList: ["shop"],
    },
    {
      code: "notification",
      name: "การแจ้งเตือน",
      activeList: ["shop"],
    },
    {
      code: "buyHistory",
      name: "ประวัติการซื้อสินค้า",
      activeList: ["shop"],
    },
    // {
    //   code: 'buyHistoryShop',
    //   name: 'ประวัติการซื้อสินค้าในเครือทั้งหมด',
    //   activeList: ['shop']
    // },
    {
      code: "memberHistory",
      name: "การปรับเปลี่ยนระดับร้านค้า",
      activeList: ["shop"],
    },

    {
      code: "contact",
      name: "เพิ่มผู้ติดต่อ",
      activeList: ["shop"],
    },

    {
      code: "shopGroup",
      name: "ร้านในเครือ",
      activeList: ["shop"],
    },
    {
      code: "sales",
      name: "Sales ที่ดูแล",
      activeList: ["shop"],
    },
  ],

  // configตาราง
  tabel: [
    {
      code: "tabelBuyHistory",
      disable: "show",
      activeList: ["shop"],
    },
  ],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
