<div style="display: flex;flex-flow: row;gap: 30px;height: 5vh;justify-content: space-between;align-items: center;color: white;
    background-color: #003f80;font-size: 20px;border-radius: 6px;" class=" pl-5 pr-5">
    <h2 style="text-align: center;">Create CO Pack</h2>
    <mat-icon svgIcon="heroicons_outline:x" style="cursor:pointer; color: white;" [mat-dialog-close]></mat-icon>
</div>

<mat-dialog-content class="mat-typography">
    <div style="display: flex;flex-flow: column;justify-content: center ;height: 10vh;background-color: lightgray;border-radius: 6px;"
        class="mb-3 pl-5">
        <h2>ข้อมูลผู้รับสินค้าปลายทาง</h2>
        <h2>Consignee Form</h2>
    </div>
    <div style="display: flex;flex-flow: column;gap: 15px;" class="pl-5 pr-5 mb-3 justify-start">
        <div class=" flex flex-col">
            <div class="flex-auto flex flex-row gap-3 mb-3">
                <div class="w-1/4 flex flex-col">
                    <h4>ประเทศแหล่งกำเนิดสินค้า</h4>
                    <h4>Country Of Origin</h4>
                </div>
                <div class="w-3/4 flex flex-row gap-3">
                    <h4>:</h4>
                    <mat-form-field class="flex-auto ">
                        <mat-select [placeholder]="placeholderOrigin" [(ngModel)]="form.countryOfOrigin"
                            [disabled]="editItem || (isRqstatus == 10 && countryofOriginEdit)"
                            [(value)]="form.countryOfOrigin">
                            <mat-option *ngFor="let destination of destinationList  "
                                [value]="destination.mC_COUNTRY_NAME">
                                {{destination.mC_COUNTRY_NAME}}
                            </mat-option>
                        </mat-select>

                    </mat-form-field>
                </div>
            </div>
            <div class="flex-auto flex flex-row gap-3 ">
                <div class="w-1/4 flex flex-col">
                    <h4>เลขใบกำกับสินค้า/วันที่</h4>
                    <h4>Invoice No./Date</h4>
                </div>
                <div class="w-3/4 flex-col">
                    <div class="flex-auto flex flex-row">
                        <h4>:</h4>
                        <div class="flex-auto">
                            <div class="flex flex-row justify-end my-3">
                                <dx-button class="justify-end w-12 h-12" icon="plus" (onClick)="addInvoice()"
                                    [disabled]="editItem || (isRqstatus == 10 && invoiceEdit)">
                                </dx-button>
                            </div>
                            <dx-data-grid #gridContainer [dataSource]="form.invoice" [allowColumnReordering]="true"
                                [showBorders]="true">

                                <dxi-column dataField="invoiceId" [visible]="false">
                                </dxi-column>
                                <dxi-column dataField="docHid" [visible]="false">
                                </dxi-column>
                                <dxi-column dataField="itemNum" [visible]="false">
                                </dxi-column>
                                <dxi-column dataField="invoiceNo" caption="เลขที่ใบกำกับสินค้า">
                                </dxi-column>
                                <dxi-column dataField="invoiceDate" caption="วันที่ใบกำกับสินค้า" [format]="'yyyy-MM-dd'"
                                    dataType="date">
                                </dxi-column>
                                <dxi-column cellTemplate="action" [width]="150">
                                </dxi-column>
                                <div *dxTemplate="let row of 'action'" class="buttonbox">
                                    <button (click)="editInvoice(row)" *ngIf="!editItem"
                                        [class.hidden]="(isRqstatus == 10 && invoiceEdit)">
                                        <mat-icon svgIcon="heroicons_outline:pencil-alt"></mat-icon>
                                    </button>
                                    <button (click)="deleteInvoice(row)" *ngIf="!editItem"
                                        [class.hidden]="(isRqstatus == 10 && invoiceEdit)">
                                        <mat-icon svgIcon="heroicons_outline:trash"></mat-icon>
                                    </button>

                                </div>
                            </dx-data-grid>
                        </div>

                    </div>
                    <mat-checkbox class="example-margin" [(ngModel)]="form.invoiceChk" name="invoiceChk"
                        #invoiceChk="ngModel"
                        [disabled]="editItem || (isRqstatus == 10 && invoiceEdit)">ไม่ปรากฏบนฟอร์ม</mat-checkbox>
                </div>

            </div>
        </div>
    </div>
    <div style="display: flex;flex-flow: column;justify-content: center ;height: 10vh;background-color: lightgray;border-radius: 6px;"
        class="mb-3 pl-5">
        <h2>รายการสินค้า</h2>
        <h2>Goods list Form</h2>
    </div>
    <div style="display: flex;flex-flow: column;gap: 15px;" class="pl-5 pr-5 mb-3 justify-start">
        <div class="flex flex-col">
            <div class="flex flex-row justify-end my-3">
                <dx-button class="justify-end w-12 h-12" icon="plus" (onClick)="addGoods()"
                    [disabled]="editItem || (isRqstatus == 10 && itemEdit)">
                </dx-button>
            </div>

            <dx-data-grid [dataSource]="form.items" [allowColumnReordering]="true" [showBorders]="true">
                <dxi-column dataField="no" caption="ลำดับ/No." [width]="100" cellTemplate="index" alignment="center">
                </dxi-column>
                <div *dxTemplate="let row of 'index'">
                    {{ (row.rowIndex+1) +(dataGrid.instance.pageIndex() *
                    dataGrid.instance.pageSize() )
                    }}
                </div>
                <!-- <dxi-column dataField="docItemId" [visible]="false">
                </dxi-column>
                <dxi-column dataField="harmonizedCode" [visible]="false">
                </dxi-column>
                <dxi-column dataField="markChk" [visible]="false">
                </dxi-column> -->
                <dxi-column dataField="markItem" caption="เครื่องหมายหีบห่อ/Mark"></dxi-column>
                <!-- <dxi-column dataField="descriptionChk" [visible]="false">
                </dxi-column> -->
                <dxi-column dataField="description" caption="ชื่อสินค้า/Description"></dxi-column>
                <dxi-column dataField="quantity" caption="ปริมาณ/Quantity" cellTemplate="quantity" alignment="center"
                    [width]="170"></dxi-column>
                <div *dxTemplate="let row of 'quantity'">
                    {{row.data?.quantity}} {{row.data?.quantityUnit}}
                </div>
                <dxi-column dataField="weight" caption="น้ำหนัก/Weight" cellTemplate="weight" alignment="center"
                    [width]="170"></dxi-column>
                <div *dxTemplate="let row of 'weight'">
                    {{row.data?.weight}} {{row.data?.weightUnit}}
                </div>
                <dxi-column cellTemplate="action" [width]="100">
                </dxi-column>
                <div *dxTemplate="let row of 'action'">
                    <button (click)="editGood(row)" *ngIf="!editJobData"
                        [class.hidden]="(isRqstatus == 10 && itemEdit) || editItem">
                        <mat-icon svgIcon="heroicons_outline:pencil-alt"></mat-icon>
                    </button>
                    <button (click)="deleteGood(row)" *ngIf="!editJobData"
                        [class.hidden]="(isRqstatus == 10 && itemEdit) || editItem"
                        (keydown.enter)="$event.preventDefault()">
                        <mat-icon svgIcon="heroicons_outline:trash"></mat-icon>
                    </button>
                    <button (click)="previewGood(row)" *ngIf="editItem || itemEdit">
                        <mat-icon svgIcon="heroicons_outline:document-search"></mat-icon>
                    </button>
                </div>
            </dx-data-grid>
            <mat-divider class="mt-3"></mat-divider>
            <mat-label>รายละเอียดเพิ่มเติมท้ายรายการสินค้า</mat-label>
            <mat-form-field class="flex-auto">
                <textarea matInput [(ngModel)]="form.remark"
                    [disabled]="editItem || (isRqstatus == 10 && footerEdit)"></textarea>
            </mat-form-field>
        </div>
    </div>

</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-raised-button color="accent" (click)="save()">บันทึก</button>
    <button mat-raised-button color="accent" mat-dialog-close (click)="cancel()">ยกเลิก</button>
</mat-dialog-actions>