import { Injectable } from '@angular/core';
import { ApiNotificationService } from '../api/api-notification.service';

@Injectable({
  providedIn: 'root'
})
export class AppNotificationService {

  constructor(
    private apiNotificationService: ApiNotificationService
  ) { }

  getNotificationList(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiNotificationService.getNotificationList(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getNotRegisteratedEmailInvoiceList(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiNotificationService.getNotRegisteratedEmailInvoiceList(request).subscribe((res) => {
        resolve(res);
      });
    });
  }
}
