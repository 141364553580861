import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Inject,
    OnInit,
  } from '@angular/core'
  import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'
import { AppCOService } from 'app/business/service/app/app-co.service'
import { AppMasterService } from 'app/business/service/app/app-master.service'
  import { AppRecheckService } from 'app/business/service/app/app-recheck.service'
  
  @Component({
    selector: 'app-co-send-print-to-staff-popup',
    templateUrl: './list-co-send-print-to-staff-popup-inquiry.component.html',
    styleUrls: ['./list-co-send-print-to-staff-popup-inquiry.component.scss'],
  })
  export class ListCoSendPrintToStaffPopupComponent implements OnInit {
    form: any = {}
    editable : any;
    branchList: any[];

    docHid : any;
    totalPage : any;
    constructor(
      @Inject(MAT_DIALOG_DATA) data,
      public dialogRef: MatDialogRef<ListCoSendPrintToStaffPopupComponent>,
      private appMasterService: AppMasterService,
      private appCoService: AppCOService,
      private alertService: AlertMessageService,
    ) {
        this.form.packId = data.packId;
        this.form.rqNo = data.requestNo;
        this.form.docType = data.docType;
        this.form.expressType = data.expressType;
        this.form.consignor = data.consignor;
    }
  
    ngOnInit(): void {
      this.loadData();
    }
  
  
    save() {
      if(!this.form.sigBranchId){
        this.alertService.open({ message: 'กรุณาระบุสาขาที่ต้องการโอนงาน', title: "" });
        return
      }
      if(!this.form.remark){
        this.alertService.open({ message: 'กรุณาระบุหมายเหตุ', title: "" });
        return
      }
    let data = {
            packId : this.form.packId,
            remark : this.form.remark,
            saveLog : {
              docType : this.form.docType == 'CF' ? 'F' : this.form.docType == 'CO' || this.form.docType == 'CO/Pack' ? 'O' : '',
              amount : 0,
              roundPrintAmount : 0,
              refId : this.docHid,
              refTableName : 'DOCUMENT_REQUEST_OBJ',
              amountTotal : this.totalPage,
              branch : this.form.sigBranchId,
              action : 'T',
            }
        }
    this.appCoService.sendPrintToStaff(data).then((res:any) => {
        if (res === 'error') {
            return
        }
        else{
            this.dialogRef.close(true);
        }
    })
    }

    async loadData(){
      let requestmaster = {
        id: 0,
        isActive: 'Y',
      }
      this.appMasterService.getListBranch(requestmaster).then((res: any) => {
        if (res === 'error') {
          return
        } else {
          this.branchList = []
          this.branchList = res
        }
      })
      let data = {
        docId : this.form.packId,
      }
      await this.appCoService.getCfTotalPage(data).then((x) => {
        this.docHid = x?.docId;
        this.totalPage = x?.totalPage;
      });   
      }
  }
  