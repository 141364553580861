import { LiveAnnouncer } from '@angular/cdk/a11y'
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core'
import { MatSort, Sort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { AppCOService } from 'app/business/service/app/app-co.service'
import { MatPaginator } from '@angular/material/paginator'
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'
import { MatDialog } from "@angular/material/dialog";
import { debounceTime, map, startWith, switchMap, takeUntil } from "rxjs/operators";
import { HttpLoadingService } from '@bundle/service/http-loading.service';
import { of, Subject } from "rxjs";
import { companySearch } from 'app/business/interface/app/app-company.interace'
import { AppCompanyService } from 'app/business/service/app/app-company.service'
import { FormControl, FormGroup, FormBuilder } from "@angular/forms";
import { AppUserService } from 'app/business/service/app/app-user.service'

@Component({
  selector: 'app-list-authority-view-inquiry',
  templateUrl: './list-authority-view-inquiry.component.html',
  styleUrls: ['./list-authority-view-inquiry.component.css'],
})
export class ListAuthorityViewInquiryComponent implements OnInit {
  searchForm: any = {
    certificateNo: "",
    endDate: null,
    startDate: null,
    isRqStatus: null,
    receiptNo: "",
    requestNo: "",
    invoiceReference: "",
  };
  color = "#009efa72";
  userProfile: any = {}
  displayedColumns: string[] = [
    "No",
    "companyName",
    "requestDateTime",
    "requestNo",
    "docType",
    "docExpressType",
    "docSendType",
    "action",
  ];
  searchString: string;
  apiRequest: any;
  apiResponse: any[];
  statusControl = new FormControl("");
  exporterControl = new FormControl("");
  exporterOptions: string[];
  dataSource = new MatTableDataSource<any>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  form: any = {};
  formData: any = {};

  searchCompanyForm: FormGroup;
  valueSelect: boolean = false;
  isLoading: boolean = false;
  filteredOptions$: any = [];
  constructor(
    private _formBuilder: FormBuilder,
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private appCOService: AppCOService,
    private httpLoadingService: HttpLoadingService,
    private appCompanyService: AppCompanyService,
    private appUserService:AppUserService,
  ) { }

  ngOnInit() {
    this.searchCompanyForm = this._formBuilder.group({
      companySearch: ['']
    });
    this.valueChange();

    this.userProfile.uId = JSON.parse(this.appUserService.getSessionUserProfile()).uId
    this.userProfile.cid = JSON.parse(this.appUserService.getSessionUserProfile()).cpId
    this.formData = {};
    this.loadData(this.apiRequest);
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  loadData(request: any) {
    if (request == null) {
      request = {
      };
    }

    this.appCOService.getAuthorityViewResponseList(request).then((res: any) => {
      if (res === "error") {
        return;
      } else {
        this.dataSource = new MatTableDataSource(res);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

      }
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce("Sorting cleared");
    }
  }
  stopPropagation(event) {
    event.stopPropagation();
  }

  onRefresh() {
    this.apiRequest = {
      startDate: this.searchForm?.startDate,
      endDate: this.searchForm?.endDate,
      requestNo: this.searchForm?.requestNo,
      companyCode: this.searchForm?.companyCode,
      companyName: this.searchForm?.companyName
    };
    this.dataSource.paginator = this.paginator
    this.loadData(this.apiRequest)
  }

  saveSearch() {
    this.apiRequest = {
      startDate: this.searchForm?.startDate,
      endDate: this.searchForm?.endDate,
      requestNo: this.searchForm?.requestNo,
      companyCode: this.searchForm?.companyCode,
      companyName: this.searchForm?.companyName
    };
    this.dataSource.paginator = this.paginator

    this.loadData(this.apiRequest);
  }


  clearOption() {
    this.searchForm = {};
    this.searchCompanyForm.get('companySearch').setValue('');
  }

  startDateChange(e){
    this.searchForm.endDate = this.searchForm.startDate 
  }

  calculateIndex(index: number): number {
    return this.dataSource.paginator.pageIndex * this.dataSource.paginator.pageSize + index + 1;
  }

  selectionChange(e): void {
    this.searchCompanyForm.get('companySearch').setValue(e.option.value.companyCode);
    this.companySelect(e.option.value);
  }

  companySelect(e) {
    this.searchForm.companyCode = e.companyCode;
    this.searchForm.companyName = e.companyNameTh;

    this.valueSelect = true;
  }

  valueChange() {
    this.filteredOptions$ = this.searchCompanyForm.get('companySearch').valueChanges.pipe(
      debounceTime(500),
      switchMap(value => {
        if (!value) {
          this.valueSelect = false;
          this.searchForm.companyCode = null;
          return of(null);
        }

        this.httpLoadingService.enabled = false;
        this.isLoading = true;

        const key = value;
        if (key !== '') {
          if (this.valueSelect) {
            return of(null);
          }
          else {
            this.valueSelect = false;
            return this.appCompanyService.getListCompanyByFilter(key);
          }
        } else {
          this.valueSelect = false;
          this.isLoading = false;
          return;
        }

      }),
      map((res: any[]) => {
        this.isLoading = false;
        this.httpLoadingService.enabled = true;

        

        return res?.map(item => {
          const { companyTaxId, companyNameTh } = (item as companySearch);
          return {
            ...item,
            // tslint:disable-next-line: max-line-length
            display: `${companyTaxId} ${companyNameTh}`
          };
        });
      })
    );
  }
}
