<mat-card>
    <mat-card-header>
        <div class="flex justify-between" style="width: 100%;">
            <div class="text-lg"> Create Job </div>
            <div>
                <button class="close-button" [mat-dialog-close]="true">
                    <mat-icon class="text-white" [mat-dialog-close]>cancel</mat-icon>
                </button>
            </div>
        </div>
    </mat-card-header>

    <mat-dialog-content class="mat-typography">

        <div class="flex flex-col justify-center gap-3">
            <div class="flex flex-row justify-end pr-10">
                <div class="mat-button" style="gap: 10px;flex: 0 40%;">
                    <mat-form-field style="display: flex;flex-flow: row;flex: 1" class="my-form-field">
                        <input autocomplete="off" type="text" matInput placeholder="Request No. / เลขที่ THT" matInput #input
                            [(ngModel)]="searchString" name="searchText" #searchText="ngModel"
                            (keyup)="applyFilter($event.target.value)">
                        <button matSuffix mat-icon-button aria-label="ค้นหา">
                            <mat-icon>search</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
            </div>
            <div class="mat-elevation-z8">
                <table mat-table matSort [dataSource]="createJobList" (matSortChange)="announceSortChange($event)">
                    <ng-container matColumnDef="rqNo">
                        <th mat-header-cell *matHeaderCellDef class="text-left"> Request No. </th>
                        <td mat-cell *matCellDef="let element"  class="text-left"> {{element.rqNo}} </td>
                    </ng-container>
                    <ng-container matColumnDef="thtNo">
                        <th mat-header-cell *matHeaderCellDef class="text-left"> เลขที่ THT</th>
                        <td mat-cell *matCellDef="let element"  class="text-left">
                            {{element.thtNo}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="requestDate">
                        <th mat-header-cell *matHeaderCellDef class="text-left"> วันที่ยื่นคำขอ
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-left">
                            {{element.requestDate | date:'yyyy-MM-dd'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="docType">
                        <th mat-header-cell *matHeaderCellDef class="text-left"> ประเภทเอกสาร</th>
                        <td mat-cell *matCellDef="let element" class="text-left"> {{element.docType}} </td>
                    </ng-container>
                    <ng-container matColumnDef="rqStatus">
                        <th mat-header-cell *matHeaderCellDef class="text-left"> สถานะคำขอ
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-left">
                            {{element.rqStatus}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="declarator">
                        <th mat-header-cell *matHeaderCellDef class="text-left"> ผู้ส่งออก </th>
                        <td mat-cell *matCellDef="let element" class="text-left">
                            {{element.declarator }} </td>
                    </ng-container>
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef class="text-left"> Action </th>
                        <td mat-cell *matCellDef="let element;let i = index" class="text-left">
                            <button mat-icon-button matTooltip="แก้ไข" (click)="createJob(element)">
                                <mat-icon svgIcon="heroicons_outline:pencil-alt"></mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons
                    aria-label="Select page of periodic elements" (page)="pageChanged($event)">
                </mat-paginator>
            </div>
        </div>

    </mat-dialog-content>
</mat-card>