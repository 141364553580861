import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-co-memo',
  templateUrl: './create-co-memo.component.html',
  styleUrls: ['./create-co-memo.component.css']
})
export class CreateCoMemoComponent implements OnInit {
  @Input() memotext= "";
  constructor(
  ) { }

  ngOnInit() {
  }
}
