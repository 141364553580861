import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '@bundle/service/api.service';

@Injectable({
  providedIn: 'root'
})
export class ApiRecheckService {

  constructor(private apiService: ApiService) { }


  getCreateRechecklist(request:any):Observable<any>{
    return this.apiService.post('/api/CO/getCreateRechecklist',request)
  }

  saveCreateRecheck(request:any):Observable<any>{
    return this.apiService.post('/api/CO/saveCreateRecheck',request)
  }

  getRechecklist(request:any):Observable<any>{
    return this.apiService.post('/api/CO/getRechecklist',request)
  }

  saveRecheckStatus(request:any):Observable<any>{
    return this.apiService.post("/api/CO/saveRecheckStatus",request)
  }

  getManagementRecheckList(request:any):Observable<any>{
    return this.apiService.post("/api/CO/getManagementRechecklist",request)
  }

  getManagementRecheckDetail(request:any):Observable<any>{
    return this.apiService.post("/api/CO/getManagementRecheckDetail",request)
  }

  getCreateReprintList(request:any):Observable<any>{
    return this.apiService.post("/api/CO/getCreateReprintList",request)
  }

  getCreateReprintDetail(request:any):Observable<any>{
    return this.apiService.post("/api/CO/getCreateReprintDetail",request)
  }

  saveCreateReprint(request:any):Observable<any>{
    return this.apiService.post("/api/CO/saveCreateReprint",request)
  }
  
  deleteReprint(request:any):Observable<any>{
    return this.apiService.post("/api/CO/deleteReprint",request)
  }
}

