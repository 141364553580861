<!-- <mat-progress-bar mode="indeterminate"></mat-progress-bar> -->
<mat-card  style="width: 100%;">
  <mat-card-header>
    <div class="flex justify-between" style="width: 100%;">
      <div class="text-lg">ค้นหาคำขอ</div>
      <div>
        <button class="close-button" [mat-dialog-close]="true">
          <mat-icon class="text-white" [mat-dialog-close]>cancel</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-dialog-content>
    <dx-data-grid class="mt-3" #gridContainer [dataSource]="dataSource" [allowColumnReordering]="true"
      [showBorders]="true" (onSelectionChanged)="selectionChanged($event)">
      <dxo-selection mode="multiple"></dxo-selection>
      <dxi-column dataField="docType" [width]="120" caption="ประเภทเอกสาร">
      </dxi-column>
      <dxi-column dataField="reqNo" caption="เลขที่คำขอ">
      </dxi-column>
      <dxi-column dataField="reqDateTime" [width]="100" dataType="date" [format]="'yyyy-MM-dd'" caption="วันที่ส่งคำขอ">
      </dxi-column>
      <dxi-column dataField="certificateNo" caption="เลขที่ THT">
      </dxi-column>
      <dxi-column dataField="reqAmount" [width]="100" caption="จำนวนชุด">
      </dxi-column>
      <dxi-column dataField="reqTypeName" [width]="100"  caption="ประเภทคำขอ">
      </dxi-column>
    </dx-data-grid>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-raised-button [mat-dialog-close]="selectionList" [disabled]="selectionList.length == 0"
      color="accent">เพิ่มคำขอ</button>
    <!-- <button mat-raised-button [mat-dialog-close]>ยกเลิก</button> -->
  </mat-dialog-actions>