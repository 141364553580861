<div style="display: flex;flex-flow: column;height: 100%;gap: 30px;">
    <div class="flex flex-col pl-10 pr-10 pt-10">
        <div class="menu">
            <div style="display: flex;flex-flow: row;gap: 10px;align-items: stretch;">
                <button *ngIf="(checkButton('BT006')) && (!isBlacklisted)" class="set-btn-unlimited" mat-raised-button
                    color="accent" (click)="createCo()">สร้างใบคำขอ CO
                </button>
                <button mat-raised-button color="accent" (click)="onRefresh()">
                    <div class="flex items-center">
                        <span class="icon-text"><mat-icon
                                svgIcon="heroicons_outline:refresh"></mat-icon>รีเฟรชข้อมูล</span>
                    </div>
                </button>
                <button mat-raised-button color="accent" (click)="getQueue()">
                    <div class="flex items-center">
                        <span class="icon-text">
                            <mat-icon svgIcon="file-stack"></mat-icon>
                            แสดงลำดับคิว
                            
                        </span>
                    </div>
                </button>
            </div> 
             
            <div style="display: flex;flex-flow: row;gap: 10px;flex: 1;justify-content: flex-end;">
                <div class="mat-button" style="gap: 10px;flex: 0 40%;">
                    <mat-form-field style="display: flex;flex-flow: row;flex: 1" class="my-form-field">
                        <input autocomplete="off" type="text" matInput placeholder="Request No. / เลขที่ THT / Invoice"
                            matInput #input [(ngModel)]="searchString" name="searchText" #searchText="ngModel"
                            (keyup)="applyFilter($event.target.value)">
                    </mat-form-field>
                </div>
                <button mat-raised-button color="accent" [matMenuTriggerFor]="menu">
                    <div style="display: flex;align-items: center;">
                        <span>เงื่อนไขการค้นหา</span>
                        <mat-icon>expand_more</mat-icon>
                    </div>
                </button>
                <mat-menu #menu="matMenu" yPosition="below" xPosition="before" class="my-menu">
                    <div style="display: flex;flex-flow: column;gap: 15px;" class="pl-2 pr-2 "
                        (click)="stopPropagation($event)">
                        <div class="mat-button p-2" style="gap: 10px;">
                            <div class="flex-col basis-1/2">
                                <mat-form-field appearance="fill" class="w-full">
                                    <mat-label>วันที่เริ่มต้น | Start Date
                                    </mat-label>
                                    <input matInput [matDatepicker]="startDate" (click)="startDate.open()" readonly
                                        type="datetime" [(ngModel)]="searchForm.startDate" [max]="myDate"
                                        name="startDateSearch" (dateInput)="datecheck($event)"
                                        #startDateSearch="ngModel" class="cursor-pointer">
                                    <mat-hint>MM/DD/YYYY</mat-hint>
                                    <mat-datepicker-toggle matIconSuffix [for]="startDate"></mat-datepicker-toggle>
                                    <mat-datepicker #startDate></mat-datepicker>
                                </mat-form-field>
                                <mat-error *ngIf="checkStartDate == false"
                                    class="p-1 text-sm text-red-800 absolute">วันที่เริ่มต้นไม่ถูกต้อง</mat-error>
                            </div>
                            <div class="flex-col basis-1/2">
                                <mat-form-field appearance="fill" class="w-full">
                                    <mat-label>วันที่สิ้นสุด | End Date</mat-label>
                                    <input matInput [matDatepicker]="endDate"
                                        (click)="endDate.open(); checkEndDate = true" readonly type="datetime"
                                        [(ngModel)]="searchForm.endDate" [max]="myDate" [min]="searchForm.startDate"
                                        (dateInput)="endDateCheck($event)" name="endDateSearch" #endDateSearch="ngModel"
                                        class="cursor-pointer">
                                    <mat-hint>MM/DD/YYYY</mat-hint>
                                    <mat-datepicker-toggle matIconSuffix [for]="endDate"></mat-datepicker-toggle>
                                    <mat-datepicker #endDate></mat-datepicker>
                                </mat-form-field>
                                <mat-error *ngIf="checkEndDate == false"
                                    class="p-1 text-sm text-red-800 absolute">วันที่สิ้นสุดไม่ถูกต้อง</mat-error>
                            </div>
                        </div>
                        <div class="mat-button  p-2" style="gap: 10px;">
                            <mat-form-field style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>เลขที่ใบคำขอ | Request No. </mat-label>
                                <input autocomplete="off" type="text" matInput placeholder="เลขที่ใบคำขอ" matInput
                                    #input [(ngModel)]="searchForm.requestNo" name="requestNo" #requestNo="ngModel">
                            </mat-form-field>
                            <mat-form-field style="display: flex;flex-flow: row;flex: 1">
                                <mat-label> เลขที่ THT | Certificate No.</mat-label>
                                <input autocomplete="off" type="text" matInput placeholder="เลขที่ THT" matInput #input
                                    [(ngModel)]="searchForm.certificateNo" name="certificateNo"
                                    #certificateNo="ngModel">
                            </mat-form-field>
                        </div>
                        <div class="mat-button  p-2" style="gap: 10px;">
                            <mat-form-field style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>เลขที่ใบกำกับสินค้า / เลขที่เอกสาร | Invoice No./ Reference No. </mat-label>
                                <input autocomplete="off" type="text" matInput placeholder="เลขที่ใบกำกับ/ เลขที่เอกสาร"
                                    matInput #input [(ngModel)]="searchForm.invoiceReferenceNo" name="invoiceReference"
                                    #invoiceReference="ngModel">
                            </mat-form-field>
                        </div>
                        <div class="mat-button  p-2" style="gap: 10px;">
                            <mat-form-field appearance="fill" style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>สถานะ | Status</mat-label>
                                <mat-select [(ngModel)]="searchForm.status" name="status" #status="ngModel">
                                    <mat-option *ngFor="let statusOption of statusOptions"
                                        [value]="statusOption.statusId">
                                        {{ statusOption.statusNameTh}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="flex justify-end pt-2">
                            <div>
                                <button mat-raised-button (click)="clearOption()"
                                    style="flex:0 50%; margin-right: 10px;">
                                    เคลียร์
                                </button>
                                <button mat-raised-button color="accent" (click)="saveSearch()" style="flex:0 50%;">
                                    ค้นหา
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-menu>
            </div>
        </div>
    </div>
    <div class="mat-elevation-z8" style="overflow-x:auto;">
        <table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">
            <ng-container matColumnDef="createDate">
                <th mat-header-cell *matHeaderCellDef>
                    วันที่ล่าสุด </th>
                <td mat-cell *matCellDef="let element">
                    {{element.createDate |date:'yyyy-MM-dd'}}<br>
                    {{element.createDate |date:'HH:mm'}} </td>
            </ng-container>
            <ng-container matColumnDef="requestNo">
                <th mat-header-cell *matHeaderCellDef>Request No.</th>
                <td mat-cell *matCellDef="let element"> {{element.requestNo}} </td>
            </ng-container>
            <ng-container matColumnDef="queueNumber">
                <th mat-header-cell *matHeaderCellDef>ลำดับคิว</th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="element.queueNumber !== 0">{{
                        element.queueNumber }}</ng-container>
                </td>
            </ng-container>
            <ng-container matColumnDef="docType">
                <th mat-header-cell *matHeaderCellDef>ประเภท</th>
                <td mat-cell *matCellDef="let element"> {{element.docType}} </td>
            </ng-container>
            <ng-container matColumnDef="docPriority">
                <th mat-header-cell *matHeaderCellDef>เร่งด่วน</th>
                <td mat-cell *matCellDef="let element"> {{element.docPriority}} </td>
            </ng-container>
            <ng-container matColumnDef="invoiceReference">
                <th mat-header-cell *matHeaderCellDef>Invoice</th>
                <td mat-cell *matCellDef="let element">
                    {{element.invoiceNo}}
                </td>
            </ng-container>
            <ng-container matColumnDef="isRqStatus">
                <th mat-header-cell *matHeaderCellDef>สถานะคำขอ</th>
                <td mat-cell *matCellDef="let element"> {{element.approveType == 'W' ? 'รอตรวจโรงงาน'
                    :element.isRqStatus}} </td>
            </ng-container>
            <ng-container matColumnDef="companyName">
                <th mat-header-cell *matHeaderCellDef>บริษัท</th>
                <td mat-cell *matCellDef="let element">
                    {{element.companyName}}
                </td>
            </ng-container>
            <ng-container matColumnDef="certificateNo">
                <th mat-header-cell *matHeaderCellDef>เลขที่ THT</th>
                <td mat-cell *matCellDef="let element">
                    {{element.thtNo}}
                </td>
            </ng-container>
            <ng-container matColumnDef="receiptNo">
                <th mat-header-cell *matHeaderCellDef>เลขที่ใบเสร็จ</th>
                <td mat-cell *matCellDef="let element"> {{element.receiptNo}} </td>
            </ng-container>
            <ng-container matColumnDef="action" style="text-align: left; min-width: 170px;" class="matSticky-panel">
                <th mat-header-cell *matHeaderCellDef style="text-align: left; min-width: 170px;"> Action </th>
                <td mat-cell *matCellDef="let element;let i = index" style="text-align: left;min-width: 170px;">
                    <div class="flex">
                        <div *ngIf="checkButton('BT007')">
                            <button mat-icon-button matTooltip="แก้ไข" matTooltipPosition="above"
                                *ngIf="element.action == 3||element.action == 10" (click)="editCo(element)">
                                <mat-icon svgIcon="heroicons_outline:pencil-alt"></mat-icon>
                            </button>
                        </div>
                        <div *ngIf="checkButton('BT008')">
                            <button mat-icon-button matTooltip="รายละเอียด" matTooltipPosition="above"
                                (click)="showDetail(element)"
                                *ngIf="element.des || element.action == 6 || element.action == 7 || element.action == 8 || element.action == 42"
                                [queryParams]="{
                                        id: element.packId,
                                        disabled: true
                                        }" routerLink="/page/request/request-co/create-co">
                                <mat-icon svgIcon="heroicons_outline:document-text"></mat-icon>
                            </button>
                        </div>
                        <div *ngIf="checkButton('BT009')">
                            <button mat-icon-button matTooltip="ยกเลิกส่งตรวจ" (click)="cancel(element)"
                                matTooltipPosition="above" *ngIf="element.action == '4'||element.systemQstatus =='2' ">
                                <mat-icon svgIcon="cancel_schedule_send"></mat-icon>
                            </button>
                        </div>
                        <div *ngIf="checkButton('BT010')">
                            <button mat-icon-button matTooltip="พิมพ์" (click)="print(element)"
                                matTooltipPosition="above"
                                *ngIf="element.action == 3 || element.action == 4 || element.action == '40'|| element.action == '10'|| (element.action == '6' && element.selfPrint == 'Y')">
                                <mat-icon svgIcon="heroicons_outline:printer"></mat-icon>
                            </button>
                        </div>
                        <div *ngIf="checkButton('BT177')">
                            <button mat-icon-button matTooltip="เปลี่ยน" (click)="openPopupSendPrintTostaff(element)"
                                matTooltipPosition="above" *ngIf="element.selfPrint == 'Y' && element.action == '6' ">
                                <mat-icon svgIcon="mat_outline:local_post_office"></mat-icon>
                            </button>
                        </div>
                        <div *ngIf="checkButton('BT011')">
                            <button mat-icon-button matTooltip="ลบ" (click)="deleteItem(element)"
                                matTooltipPosition="above" *ngIf="(element.action =='3'||element.action == '10')"
                                (keydown.enter)="$event.preventDefault()">
                                <mat-icon svgIcon="heroicons_outline:trash"></mat-icon>
                            </button>
                        </div>
                        <!-- <button mat-icon-button matTooltip="" (click)="deleteItem(element)" matTooltipPosition="above"
                        *ngIf="element.action =='3'||element.action == '10'">
                        <mat-icon svgIcon="mat_outline:article"></mat-icon>
                    </button> -->
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons
            aria-label="Select page of periodic elements" (page)="pageChanged($event)">
        </mat-paginator>
    </div>
</div>