<div style="display: flex;flex-flow: column;height: 100%;gap: 30px;">
    <div style="display: flex;flex-flow: column;margin-top: 30px;" class="pl-10 pr-10">
        <div class="menu">
            <div class="gap-2.5 flex flex-row">
                <button mat-raised-button color="accent" (click)="onRefresh()">
                  <div class="flex items-center">
                    <span class="icon-text"><mat-icon svgIcon="heroicons_outline:refresh"></mat-icon>รีเฟรชข้อมูล</span>
                  </div>
                </button>
              </div>
            <div style="display: flex;flex-flow: row;gap: 10px;flex: 1;justify-content: flex-end;">
                <div class="mat-button" style="gap: 10px;flex: 0 40%;">
                    <mat-form-field style="flex: 1" class="my-form-field flex flex-row">
                        <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" matInput #input
                            [(ngModel)]="form.search" name="search" #search="ngModel" (keyup)="applyFilter($event)">
                        <button matSuffix mat-icon-button aria-label="ค้นหา">
                            <mat-icon>search</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
                <button mat-raised-button color="accent" [matMenuTriggerFor]="menu">
                    <div style="display: flex;align-items: center;">
                        <span>เงื่อนไขการค้นหา</span>
                        <mat-icon>expand_more</mat-icon>
                    </div>
                </button>
                <mat-menu #menu="matMenu" xPosition="before" class="my-menu">
                    <div style="display: flex;flex-flow: column;" class="pl-2 pr-2"
                        (click)="stopPropagation($event)">
                        <div class="mat-button p-2" style="gap: 10px;">
                            <mat-form-field appearance="fill" style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>วันที่เริ่มต้น | Start Date</mat-label>
                                <input matInput [matDatepicker]="startDate" type="datetime"
                                    [(ngModel)]="searchForm.startDate" (click)="startDate.open()" readonly
                                    name="startDateSearch" #startDateSearch="ngModel"
                                    (dateChange)="startDateChange($event)" class="cursor-pointer">
                                <mat-hint>MM/DD/YYYY</mat-hint>
                                <mat-datepicker-toggle matIconSuffix [for]="startDate"></mat-datepicker-toggle>
                                <mat-datepicker #startDate></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field appearance="fill" style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>วันที่สิ้นสุด | End Date</mat-label>
                                <input matInput [matDatepicker]="endDate" type="datetime"
                                    [(ngModel)]="searchForm.endDate" name="endDateSearch" (click)="endDate.open()"
                                    readonly #endDateSearch="ngModel" [min]="searchForm.startDate"
                                    class="cursor-pointer">
                                <mat-hint>MM/DD/YYYY</mat-hint>
                                <mat-datepicker-toggle matIconSuffix [for]="endDate"></mat-datepicker-toggle>
                                <mat-datepicker #endDate></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="mat-button p-2" style="gap: 10px;">
                            <mat-form-field style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>เลขที่ใบคำขอ | Request No.</mat-label>
                                <input autocomplete="off" type="text" matInput placeholder="เลขที่ใบคำขอ" matInput
                                    #input [(ngModel)]="searchForm.requestNo" name="requestNo" #requestNo="ngModel">
                            </mat-form-field>
                        </div>
                        <div class="mat-button p-2" style="gap: 10px;">
                            <form name="searchCompanyForm" [formGroup]="searchCompanyForm" style="display: flex;flex-flow: row;flex: 1">
                                <mat-form-field style="display: flex;flex-flow: row;flex: 1">
                                    <mat-label>รหัสบริษัท | Company Code</mat-label>
                                    <input matInput type="text" formControlName="companySearch" [matAutocomplete]="auto"
                                        name="companyCode">
                                    <mat-autocomplete #auto="matAutocomplete" required (optionSelected)="selectionChange($event)" [panelWidth]="'auto'">
                                        <mat-option *ngIf="isLoading">
                                            <mat-spinner [diameter]="20"></mat-spinner>
                                        </mat-option>
                                        <mat-option *ngFor="let option of filteredOptions$  | async" [value]="option">
                                            {{option.display}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </form>
                        </div>
                        <div class="mat-button p-2" style="gap: 10px;">
                            <mat-form-field style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>ชื่อบริษัท | Company Name</mat-label>
                                <input autocomplete="off" type="text" matInput placeholder="ชื่อบริษัท" matInput #input
                                    [(ngModel)]="searchForm.companyName" name="companyName" #companyName="ngModel">
                            </mat-form-field>
                        </div>


                        <div class="flex justify-end pt-2">
                            <div>
                                <button mat-raised-button (click)="clearOption()"
                                    style="flex:0 50%; margin-right: 10px;">
                                    เคลียร์
                                </button>
                                <button mat-raised-button color="accent" (click)="saveSearch()" style="flex:0 50%;">
                                    ค้นหา
                                </button>
                            </div>
                        </div>
                        <!-- <div class="mat-button" style="gap: 10px;justify-content: flex-end;">
                          <button mat-raised-button color="accent" style="flex: 0 50%;"
                              (click)="saveSearch()">ค้นหา</button>
                      </div> -->
                    </div>
                </mat-menu>
            </div>
        </div>
    </div>

    <div class="mat-elevation-z8 ">
        <table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">

            <!-- Position Column -->
            <!-- bankAccountNo Column -->

            <ng-container matColumnDef="No">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> ลำดับที่ </th>
                <td mat-cell *matCellDef="let i = index"> {{calculateIndex(i)}} </td>
            </ng-container>

            <ng-container matColumnDef="companyName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> ชื่อผู้ประกอบการ</th>
                <td mat-cell *matCellDef="let element"> {{element.companyName}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="requestDateTime">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> วันที่ยื่นคำขอ
                </th>
                <td mat-cell *matCellDef="let element"> {{element.requestDateTime | date:'yyyy-MM-dd' }}<br> 
                    {{element.requestDateTime | date:'HH:mm' }}</td>
            </ng-container>

            <ng-container matColumnDef="requestNo">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> เลขที่คำขอ </th>
                <td mat-cell *matCellDef="let element">
                    {{element.requestNo}} </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="docType">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> ประเภทเอกสาร</th>
                <td mat-cell *matCellDef="let element"> {{element.docType}} </td>
            </ng-container>

            <ng-container matColumnDef="docExpressType">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> งานเร่งด่วน </th>
                <td mat-cell *matCellDef="let element">
                    {{element.docExpressType =="Y" ? "เร่งด่วน" : "ปกติ" }} </td>
            </ng-container>

            <ng-container matColumnDef="docSendType">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> ชนิดงาน </th>
                <td mat-cell *matCellDef="let element">
                    {{element.docSendType == "N" ? "ใหม่" : "แก้ไข" }} </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="text-left"> Action </th>
                <td mat-cell *matCellDef="let element;let i = index">
                    <button mat-icon-button matTooltip="รายละเอียด" matTooltipPosition="above"
                        *ngIf="element.docType == 'CF'" [queryParams]="{
                        id: element.packId,
                        disabled: true,
                        search:true
                        }" routerLink="/page/request/request-cf/create-cf">
                        <mat-icon svgIcon="heroicons_outline:document-text"></mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="รายละเอียด" matTooltipPosition="above"
                        *ngIf="element.docType == 'CO' || element.docType == 'CO/Pack'" [queryParams]="{
                        id: element.packId,
                        disabled: true,
                        search:true
                        }" routerLink="/page/request/request-co/create-co">
                        <mat-icon svgIcon="heroicons_outline:document-text"></mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons
            aria-label="Select page of periodic elements">
        </mat-paginator>
    </div>
</div>