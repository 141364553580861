<div style="display: flex;flex-flow: column;height: 100%;gap: 30px; justify-content: stretch;">
    <div style="display: flex;flex-flow: column;margin-top: 30px;" class="pl-10 pr-10">
        <div class="menu">
            <div class="flex flex-row" style="gap: 0.15rem !important">
                <div *ngIf="checkButton('BT075')">
                    <button class="set-btn-unlimited" mat-raised-button
                        color="accent" routerLink="/page/configuration/config-template/create-template">Create CO
                        Template</button>
                </div>
                <div *ngIf="checkButton('BT076')">
                    <button mat-raised-button color="warn"
                        (click)="deleteSelection()">Delete</button>
                </div>
            </div>
            <div style="display: flex;flex-flow: row;gap: 10px;flex: 1;justify-content: flex-end;">
                <div class="mat-button" style="gap: 10px;flex: 0 40%;">
                    <mat-form-field style="display: flex;flex-flow: row;flex: 1" class="my-form-field">
                        <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" matInput #input
                            [(ngModel)]="searchString" name="searchText" #searchText="ngModel"
                            (keyup)="applyFilter($event.target.value)">
                        <!-- (focus)="setupFilter('tempName')" -->
                        <button matSuffix mat-icon-button aria-label="ค้นหา">
                            <mat-icon>search</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
                <button mat-raised-button color="accent" [matMenuTriggerFor]="menu">
                    <div style="display: flex;align-items: center;">
                        <span>เงื่อนไขการค้นหา</span>
                        <mat-icon>expand_more</mat-icon>
                    </div>
                </button>
                <mat-menu #menu="matMenu" yPosition="below" xPosition="before" class="my-menu">
                    <div style="display: flex;flex-flow: column;gap: 15px;" class="pl-2 pr-2"
                        (click)="stopPropagation($event)">
                        <!-- <div class="mat-button p-2" style="gap: 10px;"> -->
                        <div class="flex flex-row">
                            <div class="basis-1/2 mat-button p-2">
                                <mat-form-field style="width: 100%;" appearance="fill">
                                    <mat-label>วันที่สร้าง</mat-label>
                                    <input matInput [matDatepicker]="createDate" type="datetime"
                                        [(ngModel)]="searchForm.createDate" name="createDateSearch"
                                        #createDateSearch="ngModel" (click)="createDate.open()" readonly
                                        class="cursor-pointer">
                                    <mat-hint>MM/DD/YYYY</mat-hint>
                                    <mat-datepicker-toggle matIconSuffix [for]="createDate"></mat-datepicker-toggle>
                                    <mat-datepicker #createDate></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="basis-1/2 mat-button p-2">
                                <mat-form-field style="width: 100%;" appearance="fill">
                                    <mat-label>ถึง</mat-label>
                                    <input matInput [matDatepicker]="endDate" type="datetime"
                                        [(ngModel)]="searchForm.endDate" name="endDate"
                                        #endDate="ngModel" (click)="endDate.open()" readonly
                                        class="cursor-pointer">
                                    <mat-hint>MM/DD/YYYY</mat-hint>
                                    <mat-datepicker-toggle matIconSuffix [for]="endDate"></mat-datepicker-toggle>
                                    <mat-datepicker #createDate></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="mat-button  p-2" style="gap: 10px;">
                            <mat-form-field style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>ชื่อต้นฉบับ </mat-label>
                                <input autocomplete="off" type="text" matInput placeholder="เลขที่ใบกำกับ/ เลขที่เอกสาร"
                                    matInput #input [(ngModel)]="searchForm.tempName" name="invoiceReference"
                                    #invoiceReference="ngModel">
                            </mat-form-field>
                        </div>
                        <!-- <mat-form-field appearance="fill" style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>วัน/เวลาที่สร้าง</mat-label>
                                <input matInput [matDatepicker]="createDate" type="datetime"
                                    [(ngModel)]="searchForm.createDate" name="createDateSearch"
                                    #createDateSearch="ngModel">
                                <mat-hint>MM/DD/YYYY</mat-hint>
                                <mat-datepicker-toggle matIconSuffix [for]="createDate"></mat-datepicker-toggle>
                                <mat-datepicker #createDate></mat-datepicker>
                            </mat-form-field> -->
                        <!-- </div> -->
                        <!-- <div class="mat-button p-2" style="gap: 10px;">
                            <mat-form-field style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>ชื่อต้นแบบ</mat-label>
                                <input autocomplete="off" type="text" matInput placeholder="เลขที่ใบคำขอ" matInput
                                    #input [(ngModel)]="searchForm.tempName" name="tempName" #tempName="ngModel">
                            </mat-form-field>
                        </div> -->
                        <div class="flex justify-end pt-2">
                            <div>
                                <button mat-raised-button (click)="clearOption()"
                                    style="flex:0 50%; margin-right: 10px;">
                                    เคลียร์
                                </button>
                                <button mat-raised-button color="accent" (click)="saveSearch()" style="flex:0 50%;">
                                    ค้นหา
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-menu>
            </div>
        </div>
    </div>
    <div class="mat-elevation-z8 ">
        <table mat-table matSort [dataSource]="templateList" (matSortChange)="announceSortChange($event)">
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef class="text-center">
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row" class="text-left">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                </td>
            </ng-container>
            <ng-container matColumnDef="createDate">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left; width:10%;">วันที่สร้าง </th>
                <td mat-cell *matCellDef="let element"> {{element.createDate | date:'yyyy-MM-dd'}} </td>
            </ng-container>

            <ng-container matColumnDef="updateDate">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left;">วันที่แก้ไขล่าสุด</th>
                <td mat-cell *matCellDef="let element"> {{element.updateDate | date:'yyyy-MM-dd'}} </td>
            </ng-container>
            <ng-container matColumnDef="tempName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left;">ชื่อต้นแบบ</th>
                <td mat-cell *matCellDef="let element"> {{element.tempName}} </td>
            </ng-container>
            <ng-container matColumnDef="declarator">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left;">ผู้ส่งออกสินค้า</th>
                <td mat-cell *matCellDef="let element"> {{element.declarator}} </td>
            </ng-container>
            <ng-container matColumnDef="destination">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left;">
                    ปลายทางที่สินค้าถูกยกลง </th>
                <td mat-cell *matCellDef="let element"> {{element.destination}} </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style="text-align: left;"> Action </th>
                <td mat-cell *matCellDef="let element;let i = index" style="text-align: left;">
                    <div class="flex flex-row" style="justify-content: start;">
                        <div *ngIf="checkButton('BT077')">
                            <button mat-icon-button matTooltip="แก้ไข" [queryParams]="{
                                id: element.id,
                                disabled: false
                            }" routerLink="/page/configuration/config-template/create-template">
                            <mat-icon svgIcon="heroicons_outline:pencil-alt"></mat-icon>
                            </button>
                        </div>
                        <div *ngIf="checkButton('BT076')">
                            <button mat-icon-button matTooltip="ลบ" (click)="deleteItem(element)"
                            matTooltipPosition="above">
                                <mat-icon svgIcon="heroicons_outline:trash"></mat-icon>
                            </button>
                        </div>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons
            aria-label="Select page of periodic elements">
        </mat-paginator>
    </div>

</div>