<div style="gap: 30px;padding-top: 30px;" class="flex flex-col h-full">
  <div class="menu justify-between flex items-stretch flex-row gap-5 px-20  pl-10  pr-10 ">
    <div class="gap-2.5 flex flex-row" style="width: 20% !important;">
      <div class="justify-start flex gap-4 items-center flex-row " style="flex: 0 40%;">
        <div *ngIf="checkButton('BT194')">
          <button class="set-btn-unlimited" mat-raised-button color="accent" (click)="popupFactory()">ค้นหารายชื่อโรงงาน
          </button>
        </div>
      </div>
      <div class="justify-start flex gap-4 items-center flex-row " style="flex: 0 40%;">
        <button mat-raised-button color="accent" (click)="onRefresh()">
          <div class="flex items-center">
            <span class="icon-text"><mat-icon svgIcon="heroicons_outline:refresh"></mat-icon>รีเฟรชข้อมูล</span>
          </div>
        </button>
      </div>
    </div>
    <div style="flex: 1; flex-basis: 100%;" class="gap-2.5 flex justify-end flex-row items-center">
      <div (click)="statusChange(1)"
        [class]="status == 1 ?'inline-flex items-center px-5 py-2.5 text-lg font-medium text-center text-white bg-green-700 rounded-lg hover:bg-green-100 hover:text-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-100 dark:focus:ring-green-800 cursor-default' :'inline-flex items-center px-5 py-2.5 text-lg font-medium text-center hover:bg-gray-200 bg-gray-100 text-gray-800 rounded-lg cursor-default'">
        สถานะรอตรวจโรงงาน
      </div>
      <div (click)="statusChange(2)"
        [class]="status == 2 ?'inline-flex items-center px-5 py-2.5 text-lg font-medium text-center text-white bg-green-700 rounded-lg hover:bg-green-100 hover:text-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-100 dark:focus:ring-green-800 cursor-default' :'inline-flex items-center px-5 py-2.5 text-lg font-medium text-center hover:bg-gray-200 bg-gray-100 text-gray-800 rounded-lg cursor-default'">
        สถานะตรวจโรงงานเรียบร้อย
      </div>
      <div (click)="statusChange(3)"
        [class]="status == 3 ?'inline-flex items-center px-5 py-2.5 text-lg font-medium text-center text-white bg-green-700 rounded-lg hover:bg-green-100 hover:text-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-100 dark:focus:ring-green-800 cursor-default' :'inline-flex items-center px-5 py-2.5 text-lg font-medium text-center hover:bg-gray-200 bg-gray-100 text-gray-800 rounded-lg cursor-default'">
        สถานะทั้งหมด
      </div>
      <button mat-raised-button color="accent" [matMenuTriggerFor]="myMenu" (menuClosed)="menuClosed()">
        <div style="display: flex; align-items: center; padding: 20px;">
          <span>เงื่อนไขการค้นหา</span>
          <mat-icon>expand_more</mat-icon>
        </div>
      </button>
      <mat-menu #myMenu="matMenu" xPosition="before" class="my-menu">
        <div class="pl-2 pr-2" (click)="stopPropagation($event)">
          <div class="pl-2 pr-2">
            <div class="flex flex-row pt-2">
              <div class="basis-full p-2">
                <form name="searchCompanyForm" [formGroup]="searchCompanyForm" class="w-full">
                  <mat-form-field style="display: flex;flex-flow: row;flex: 1">
                    <mat-label>รหัสบริษัท</mat-label>
                    <input matInput type="text" formControlName="companySearch" [matAutocomplete]="auto"
                      name="companyCode">
                    <mat-autocomplete #auto="matAutocomplete" required (optionSelected)="selectionChange($event)"
                      [panelWidth]="'auto'">
                      <mat-option *ngIf="isLoading">
                        <mat-spinner [diameter]="20"></mat-spinner>
                      </mat-option>
                      <mat-option *ngFor="let option of filteredOptions$  | async" [value]="option">
                        {{option.display}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </form>
              </div>
            </div>
            <div class="flex flex-row pt-2">
              <div class="basis-full p-2">
                <mat-form-field class="w-full">
                  <mat-label>ชื่อบริษัท</mat-label>
                  <input autocomplete="off" type="text" matInput placeholder="ชื่อบริษัท" #input
                    [(ngModel)]="form.companyName" name="companyName" #companyNameTH="ngModel" />
                </mat-form-field>
              </div>
            </div>
            <div class="flex flex-row pt-2">
              <div class="basis-full p-2">
                <mat-form-field class="w-full">
                  <mat-label>ระดับของบริษัท</mat-label>
                  <mat-select [(ngModel)]="form.gradeId">
                    <mat-option [value]="-1">ทั้งหมด</mat-option>
                    <mat-option *ngFor="let grade of gradeList" [value]="grade.gradeId">
                      {{ grade.gradeName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="flex justify-end pt-2">
              <div>
                <button mat-raised-button (click)="clearOption()" style="flex:0 50%; margin-right: 10px;">
                  เคลียร์
                </button>
                <button mat-raised-button color="accent" (click)="onSearch()" style="flex:0 50%;">
                  ค้นหา
                </button>
              </div>
            </div>

            <!-- <div class="flex justify-end pt-2">
                <div class="basis-full">
                  <button mat-raised-button color="accent" (click)="loadData()" class="w-full">
                    ค้นหา
                  </button>
                </div>
              </div> -->
          </div>
        </div>
      </mat-menu>
    </div>

  </div>
  <div class="mat-elevation-z8">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">

      <ng-container matColumnDef="cid" style="text-align: left">
        <th mat-header-cell *matHeaderCellDef class="text-left">
          รหัส
        </th>
        <td mat-cell *matCellDef="let element" class=" text-left">
          {{ element.companyCode }}
        </td>
      </ng-container>
      <ng-container matColumnDef="changeUpComDate" style="text-align: left">
        <th mat-header-cell *matHeaderCellDef class="text-left">
          วันที่สมัครเข้าระบบ
        </th>
        <td mat-cell *matCellDef="let element" class="text-left">
          {{ element.changeUpComDate | date:'yyyy-MM-dd' }}<br>
          {{ element.changeUpComDate | date:'HH:mm' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="companyNameTH" style="text-align: left;">
        <th mat-header-cell *matHeaderCellDef class="text-left">
          ชื่อบริษัท
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.companyNameTH }}
        </td>
      </ng-container>
      <ng-container matColumnDef="companyNameEN" style=" text-align: left">
        <th mat-header-cell *matHeaderCellDef>Company Name</th>
        <td mat-cell *matCellDef="let element">
          {{ element.companyNameEN }}
        </td>
      </ng-container>
      <ng-container matColumnDef="addressStreet" style="text-align: left">
        <th mat-header-cell *matHeaderCellDef class="text-left">
          ที่อยู่
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.addressStreet }}
        </td>
      </ng-container>
      <ng-container matColumnDef="province" style="text-align: left">
        <th mat-header-cell *matHeaderCellDef class="text-left">
          จังหวัด
        </th>
        <td mat-cell *matCellDef="let element" class="text-left">
          {{ element.province }}
        </td>
      </ng-container>
      <ng-container matColumnDef="phone" style="text-align: left">
        <th mat-header-cell *matHeaderCellDef class="text-left">
          เบอร์โทร
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.phone }}
        </td>
      </ng-container>
      <ng-container matColumnDef="blacklist" style="text-align: left">
        <th mat-header-cell *matHeaderCellDef class="justify-start text-left">
          สถานะ
        </th>
        <td mat-cell *matCellDef="let element" class="justify-start text-left">
          {{
          element.companyFactoryData.blacklistStatus == "Y"
          ? "Lock"
          : element.companyFactoryData.blacklistStatus == "N"
          ? "Unlock"
          : element.companyFactoryData.blacklistStatus
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="grade" style="text-align: left">
        <th mat-header-cell *matHeaderCellDef class="justify-start text-left">
          ระดับความน่าเชื่อถือ
        </th>
        <td mat-cell *matCellDef="let element" class="justify-start text-left">
          {{ element.gradeName !== null && element.gradeName !== undefined ? element.gradeName : "ยังไม่ออกตรวจ" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef style="text-align: left;">
          Action
        </th>
        <td mat-cell *matCellDef="let element; let i = index" style="text-align: left ;">

          <!-- <button mat-icon-button matTooltip="รับงาน" (click)="print()" matTooltipPosition="above">
            <mat-icon>done_outline</mat-icon>
          </button> -->
          <div class="flex flex-row" style="justify-content: center;">
            <div *ngIf="checkButton('BT049')">
              <button mat-icon-button matTooltip="รายละเอียด" (click)="getDetail(element)" matTooltipPosition="above">
                <mat-icon svgIcon="heroicons_outline:document-search"></mat-icon>
              </button>
            </div>
          </div>
          <!-- <button mat-icon-button matTooltip="รายงาน" (click)="print()" matTooltipPosition="above">
            <mat-icon>receipt</mat-icon>
          </button> -->
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons
      aria-label="Select page of periodic elements" (page)="pageChanged($event)">
    </mat-paginator>
  </div>
</div>