<div style="display: flex;flex-flow: column;height: 100%;gap: 30px; justify-content: stretch;">
    <div style="display: flex;flex-flow: column;margin-top: 30px;" class="pl-10 pr-10">
        <div class="menu flex flex-row">
            <div style="display: flex;flex-flow: row;gap: 10px;align-items: stretch;">
                <button *ngIf="(checkButton('BT034'))" mat-raised-button color="accent" (click)="createPayment()">Create
                    Payment
                </button>
                <button mat-raised-button color="accent" *ngIf="(checkButton('BT034'))" (click)="loadData()" > 
                    <span class="icon-text"><mat-icon svgIcon="heroicons_outline:refresh"></mat-icon>รีเฟรชข้อมูล</span> 
                </button>
            </div>
            <div style="display: flex;flex-flow: row;gap: 10px;flex: 1;justify-content: flex-end;">
                <div class="mat-button" style="gap: 10px;flex: 0 40%;">
                    <mat-form-field style="display: flex;flex-flow: row;flex: 1" class="my-form-field">
                        <input autocomplete="off" type="text" matInput placeholder="Reference Number" matInput #input
                            [(ngModel)]="searchString" name="searchText" #searchText="ngModel"
                            (keyup)="applyFilter($event)" (focus)="setupFilter('tempName')">
                        <button matSuffix mat-icon-button aria-label="ค้นหา">
                            <mat-icon>search</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
                <button mat-raised-button color="accent" [matMenuTriggerFor]="menu">
                    <div style="display: flex;align-items: center;">
                        <span>เงื่อนไขการค้นหา</span>
                        <mat-icon>expand_more</mat-icon>
                    </div>
                </button>
                <mat-menu #menu="matMenu" yPosition="below" xPosition="before" class="my-menu">
                    <div style="display: flex;flex-flow: column;gap: 15px;" class="pl-2 pr-2"
                        (click)="stopPropagation($event)">
                        <div class="mat-button p-2" style="gap: 10px;">
                            <mat-form-field appearance="fill" style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>วันที่เริ่มต้น | Start Date</mat-label>
                                <input matInput [matDatepicker]="startDate" type="datetime"
                                    [(ngModel)]="searchForm.startDate" name="startDateSearch" #startDateSearch="ngModel"
                                    (click)="startDate.open()" readonly class="cursor-pointer">
                                <mat-hint>MM/DD/YYYY</mat-hint>
                                <mat-datepicker-toggle matIconSuffix [for]="startDate"></mat-datepicker-toggle>
                                <mat-datepicker #startDate></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field appearance="fill" style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>วันที่สิ้นสุด | End Date</mat-label>
                                <input matInput [matDatepicker]="endDate" type="datetime"
                                    [(ngModel)]="searchForm.endDate" name="endDateSearch" #endDateSearch="ngModel"
                                    (click)="endDate.open()" readonly class="cursor-pointer">
                                <mat-hint>MM/DD/YYYY</mat-hint>
                                <mat-datepicker-toggle matIconSuffix [for]="endDate"></mat-datepicker-toggle>
                                <mat-datepicker #endDate></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="mat-button p-2" style="gap: 10px;">
                            <mat-form-field style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>เลขที่ใบคำขอชำระเงิน | Reference No.</mat-label>
                                <input autocomplete="off" type="text" matInput placeholder="เลขที่ใบคำขอชำระเงิน"
                                    matInput #input [(ngModel)]="searchForm.refNo" name="refNo" #refNo="ngModel">
                            </mat-form-field>
                            <mat-form-field appearance="fill" style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>ประเภทการชำระเงิน | Payment Type</mat-label>
                                <mat-select [(ngModel)]="searchForm.paymentType" name="paymentType"
                                    #paymentType="ngModel">
                                    <mat-option *ngFor="let payment of paymentTypeList" [value]="payment.code">
                                        {{payment.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="mat-button p-2" style="gap: 10px;width: 50%;">
                            <mat-form-field appearance="fill" style="display: flex;flex-flow: row;flex: 1; width: 50%;">
                                <mat-label>สถานะการชำระเงิน | Payment Status</mat-label>
                                <mat-select [(ngModel)]="searchForm.status" name="paymentType" #paymentType="ngModel">
                                    <mat-option *ngFor="let status of statusList" [value]="status.id">
                                        {{status.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="flex justify-end pt-2">
                            <div>
                                <button mat-raised-button (click)="clearOption()"
                                    style="flex:0 50%; margin-right: 10px;">
                                    เคลียร์
                                </button>
                                <button mat-raised-button color="accent" (click)="loadData()" style="flex:0 50%;">
                                    ค้นหา
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-menu>
            </div>
        </div>
    </div>
    <div class="mat-elevation-z8 ">
        <table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">
            <ng-container matColumnDef="refNo">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left;">Reference Number </th>
                <td mat-cell *matCellDef="let element" style="text-align: left;"> {{element.refNo}} </td>
            </ng-container>
            <ng-container matColumnDef="paymentType">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left;">Payment Type</th>
                <td mat-cell *matCellDef="let element"> {{element.paymentTypeName}} </td>
            </ng-container>
            <ng-container matColumnDef="bank">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left;">Bank</th>
                <td mat-cell *matCellDef="let element">
                    <a *ngIf="element.bank == 'ธนาคารกสิกรไทย'" target="_blank" href="https://ws06.kasikornbank.com/"
                        style="color: blue;">
                        {{element.bank}}
                    </a>
                    <a *ngIf="element.bank == 'ธนาคารกรุงเทพ'" target="_blank"
                        href="https://ebs.dimenxion.bangkokbank.com/ebs/login.jsp" style="color: blue;">
                        {{element.bank}}
                    </a>
                    <a *ngIf="element.bank == 'ธนาคารไทยพาณิชย์'" target="_blank"
                        href="https://www.scbbusinessnet.com/s1gcb/logon/sbuser" style="color: blue;">
                        {{element.bank}}
                    </a>
                    <a *ngIf="element.bank == 'ธนาคารกรุงไทย'" target="_blank"
                        href="https://www.newcb.krungthai.com/CBProject/" style="color: blue;">
                        {{element.bank}}
                    </a>
                    <!-- <p *ngIf="element.bank == 'ธนาคารกรุงไทย'">{{element.bank}}</p> -->
                </td>
            </ng-container>
            <ng-container matColumnDef="reqDate">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left;">Requset Date</th>
                <td mat-cell *matCellDef="let element"> {{element.reqDate | date:'yyyy-MM-dd HH:mm'}} </td>
            </ng-container>
            <ng-container matColumnDef="createDate">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left;">
                    Create Date
                </th>
                <td mat-cell *matCellDef="let element"> {{element.createDate | date:'yyyy-MM-dd HH:mm'}} </td>
            </ng-container>
            <ng-container matColumnDef="amount">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: right;">
                    Amount
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: right;"> {{element.amount | number: '1.2-2'||
                    0}} </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left;">
                    Status
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.statusName}}
                    <p *ngIf="element.status == 'N' && (element.payRespons != null && element.payRespons != '')"
                        style="color: red;">
                        *{{element.payRespons}}
                    </p>
                </td>
            </ng-container>
            <ng-container matColumnDef="action" style="width: 180px;">
                <th mat-header-cell *matHeaderCellDef style="text-align: center;width: 180px;"> Action </th>
                <td mat-cell *matCellDef="let element;let i = index" style="text-align: left;width: 180px;">
                    <div class="flex flex-row" style="justify-content: left;">
                        <div *ngIf="checkButton('BT035')">
                            <button *ngIf="element.stage == 26" mat-icon-button matTooltip="แก้ไข"
                                (click)="editPayment(element)">
                                <mat-icon svgIcon="heroicons_outline:pencil-alt"></mat-icon>
                            </button>
                        </div>
                        <div *ngIf="checkButton('BT157')">
                            <button *ngIf="element.stage == 27 || element.stage == 28 || element.stage == 29"
                                mat-icon-button matTooltip="รายละเอียด" [queryParams]="{
                                        pay_hid: element.hid,
                                        disabled: true
                                        }" routerLink="/page/payment/create-payment-inquiry">
                                <mat-icon svgIcon="heroicons_outline:document-text"></mat-icon>
                            </button>
                        </div>
                        <div *ngIf="checkButton('BT156')">
                            <button *ngIf="element.stage == 27 || element.stage == 28" mat-icon-button
                                matTooltip="พิมพ์ใบแจ้งยอด" (click)="exportPDFBillPayment(element.hid)"><mat-icon
                                    svgIcon="heroicons_outline:printer"></mat-icon>

                            </button>
                        </div>
                        <div *ngIf="checkButton('BT036')">
                            <button *ngIf="element.stage == 26" mat-icon-button matTooltip="ลบ"
                                (click)="updateStatusPayment(element , 39)" matTooltipPosition="above">
                                <mat-icon svgIcon="heroicons_outline:trash"></mat-icon>
                            </button>
                        </div>
                        <div *ngIf="checkButton('BT156')">
                            <button *ngIf="element.stage == 27 && element.paymentType == 1" mat-icon-button
                                matTooltip="ดึงงานกลับ" (click)="pullPaymentFromCo(element)">
                                <mat-icon svgIcon="heroicons_outline:x-circle"></mat-icon>
                            </button>
                        </div>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons
            aria-label="Select page of periodic elements">
        </mat-paginator>
    </div>
</div>