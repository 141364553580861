<div class="mat-elevation-z8" style="width: 100%">
  <table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">
    <!-- Position Column -->
    <!-- bankAccountNo Column -->
    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef class="text-left"> ลำดับ </th>
      <td mat-cell *matCellDef="let element; let i = index" class="text-left"> {{ i + 1 }} </td>
    </ng-container>

    <!-- Name Thai Column -->
    <ng-container matColumnDef="prefixTh">
      <th mat-header-cell *matHeaderCellDef class="text-left"> คำนำหน้า
      </th>
      <td mat-cell *matCellDef="let element" class="text-left"> {{element.prefixTh}} </td>
    </ng-container>

    <ng-container matColumnDef="firstnameTh">
      <th mat-header-cell *matHeaderCellDef class="text-left"> ชื่อ </th>
      <td mat-cell *matCellDef="let element" class="text-left">
        {{element.firstnameTh}} </td>
    </ng-container>

    <ng-container matColumnDef="lastnameTh">
      <th mat-header-cell *matHeaderCellDef class="text-left"> นามสกุล </th>
      <td mat-cell *matCellDef="let element" class="text-left">
        {{element.lastnameTh}} </td>
    </ng-container>

    <!-- Name English Column -->
    <ng-container matColumnDef="prefixEn">
      <th mat-header-cell *matHeaderCellDef class="text-left"> Prefix
      </th>
      <td mat-cell *matCellDef="let element" class="text-left"> {{element.prefixEn}} </td>
    </ng-container>

    <ng-container matColumnDef="firstnameEn">
      <th mat-header-cell *matHeaderCellDef class="text-left"> First Name </th>
      <td mat-cell *matCellDef="let element" class="text-left">
        {{element.firstnameEn}} </td>
    </ng-container>

    <ng-container matColumnDef="lastnameEn">
      <th mat-header-cell *matHeaderCellDef class="text-left"> Last Name </th>
      <td mat-cell *matCellDef="let element" class="text-left">
        {{element.lastnameEn}} </td>
    </ng-container>

    <ng-container matColumnDef="signature">
      <th mat-header-cell *matHeaderCellDef class="text-left"> ภาพลายมือชื่อ<br/>Signature Picture </th>
      <td mat-cell *matCellDef="let element" class="text-left">
        <img src="{{element.signature}}" class="img-radius" (click)="imagePopup(element.signature)" />
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
    aria-label="Select page of periodic elements">
  </mat-paginator>
</div>