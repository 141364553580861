import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';

@Component({
  selector: 'app-connect-mr-dialog',
  templateUrl: './connect-mr-dialog.component.html',
  styleUrls: ['./connect-mr-dialog.component.css']
})
export class ConnectMrDialogComponent implements OnInit {
  data: any = {};
  bankList: any;
  status: any;

  constructor(
    public dialogRef: MatDialogRef<ConnectMrDialogComponent>,
    private appMasterService: AppMasterService,
    private dialog: MatDialog,
    private alertMessageService: AlertMessageService
  ) { }
  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.appMasterService.getMrConnectParameter("MR_CONNECT").then(res => {
      if (res === 'error') {
        this.dialogRef.close();
      } else {
        this.data = res;
        this.status = res?.cpParamiter === 'ON' ? "ใช้งาน" : "ไม่ใช้งาน";
      }
    })
  }

  save() {
      this.appMasterService.saveMrConnectParameter(this.data).then((res: any) => {
        if (res === "error") {
          this.alertMessageService.open({message: "บันทึกข้อมูลไม่สำเร็จ", title:""});
        } else {
          this.dialogRef.close();
          this.alertMessageService.open({message: "บันทึกข้อมูลสำเร็จ", title:""});
        }
      });
  }

  onChange(e) {
    if (e.checked == true) {
      this.data.cpParamiter = "ON";
      this.status = "ใช้งาน";
    } else {
      this.data.cpParamiter = "OFF";
      this.status = "ไม่ใช้งาน";
    }
  }

  endPopup(e) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      disableClose: false,
      // width: '30vw',
      data: {
        header: e,
        button: 'close'
      },
      maxWidth: 'none'
    });

    dialogRef.afterClosed().subscribe(res => {
      this.dialogRef.close();
    });
  }
}
