import { Component, OnInit } from '@angular/core';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service';
import { AppRequestService } from 'app/business/service/app/app-request.service';

@Component({
  selector: 'app-document-edit-form',
  templateUrl: './document-edit-form.component.html',
  styleUrls: ['./document-edit-form.component.css']
})
export class DocumentEditFormComponent implements OnInit {
  form: any = {}
  data: any = {}
  searchForm: any = {}
  docHeaderList : any[] 
  docHeaderListEdit: any = {}
  showErrorMessage: any = []
  EditForm :boolean = false
  router: any;

  constructor(
    private appRequestService: AppRequestService,
    private alertMessageService: AlertMessageService
  ) { }

  ngOnInit() {
    this.searchForm.mode = "RQ";
  }

  /*
  consigneeName = u.head.DRH_CONSIGNEE_NAME,
                        consigneeNotify = u.head.DRH_CONSIGNEE_NOTIFY,
                        consignorName = u.head.DRH_CONSIGNOR,
                        consignorAddress = u.head.DRH_CONSIGNOR_ADDRESS,
                        vesselNo = u.head.DRH_VESSEL_NO,
   */
  loadForm(){}
  limitTextareaLines(event: Event) {
    const textarea = event.target as HTMLTextAreaElement;
    const maxLines = 4; // Specify the maximum number of lines you want to allow

    // Split the textarea value by line breaks and check the number of lines
    const lines = textarea.value.split('\n');
    if (lines.length > maxLines) {
      // Truncate the textarea value to the specified maximum number of lines
      textarea.value = lines.slice(0, maxLines).join('\n');
    }
  }

  checkEnglish(e) {
    if (e != null && e != "" && e != undefined) {
      const regexPattern: RegExp = /^[a-zA-Z0-9 !"$%&'()*+,-./#:;<=>?@[\\\]^_`{|}~\n\r฿]*$/;
      var validateInput = regexPattern.test(e);
      //validateInput = validateInput === true ? false : true;
    }
    return validateInput;
  }

  onSearch() {
      this.appRequestService.getDocumentEdit(this.searchForm).then((res: any) => {
        if (res === 'error') {
          return
        } else {
          this.docHeaderList = []
          if(res != null){
          this.docHeaderList = res.documentList
          this.form.documentHeadIdSelect = res.documentList[0].documentHeadId
          }else{
            this.EditForm = false;
            this.form = {};
            this.docHeaderList = null;
          }
        }
      })
  }
  onEdit(){
    this.EditForm = true
    var index = this.docHeaderList.findIndex((x) => x.documentHeadId == this.form.documentHeadIdSelect)
    this.docHeaderListEdit = this.docHeaderList[index]
    this.form.consignorName = this.docHeaderListEdit.consignorName
    this.form.consignorAddress = this.docHeaderListEdit.consignorAddress
    this.form.consigneeName = this.docHeaderListEdit.consigneeName
    this.form.consigneeNotify = this.docHeaderListEdit.consigneeNotify
    this.form.vesselNo = this.docHeaderListEdit.vesselNo
  }

  saveData(){
    const request = {
      documentHeadId: this.form.documentHeadIdSelect,
      consignorName: this.form.consignorName,
      consignorAddress: this.form.consignorAddress,
      consigneeName: this.form.consigneeName,
      consigneeNotify: this.form.consigneeNotify,
      vesselNo: this.form.vesselNo
    }
    this.appRequestService.saveDocumentEdit(request).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        this.alertMessageService
        .open({
          message: 'บันทึกสำเร็จ',
          title: 'ทำรายการสำเร็จ',
        })
        .then((x) => {
          //this.router.navigate(['/page/request/request-co/list-co'])
        })
      }
    })
  }
  
}
