import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service';
import { ConfirmDialogService } from '@bundle/component/confirm-dialog/confirm-dialog.service';
import { AppPaymentService } from 'app/business/service/app/app-payment.sevice';

@Component({
  selector: 'app-detail-payment-list-table',
  templateUrl: './detail-payment-list-table.component.html',
  styleUrls: ['./detail-payment-list-table.component.css']
})
export class DetailPaymentListTableComponent implements OnInit {
  _hId : number =0
  dataSource: any = {}
  @Input()
  set hId(value) {
    this._hId = value
    this.loadData();
  }
  get hId(): any {
    return this._hId
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  markErrorList: any = []
  constructor(
    private appPaymentService: AppPaymentService,
    public alert: AlertMessageService,
    public confirmService: ConfirmDialogService,
    public router: Router,
    private dialog: MatDialog
  ) { }
  ngOnInit() {
    this.dataSource = [];
    this.loadData();
  }

  async loadData() {
    const formData = {
      packId: this._hId
    };
    await this.appPaymentService.getPaymentDetailByPackId(formData).then((res) => {
      this.dataSource = res;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

}
