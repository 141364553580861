import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-cf-memo',
  templateUrl: './create-cf-memo.component.html',
  styleUrls: ['./create-cf-memo.component.css']
})
export class CreateCfMemoComponent implements OnInit {
  @Input() memotext= "";
  constructor() { }

  ngOnInit() {
  }

}
