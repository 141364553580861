<mat-card-header>
  <div class="flex justify-between" style="width: 100%;">
    <div class="text-lg"> รายละเอียดบริษัท</div>
    <div>
      <button class="close-button" [mat-dialog-close]="true">
        <mat-icon class="text-white" [mat-dialog-close]>cancel</mat-icon>
      </button>
    </div>
  </div>
</mat-card-header>
<mat-dialog-content>
  <mat-card-content>
    <div class="pl-22 pr-22 flex flex-row gap-4">
      <div class="flex flex-row gap-2.5 basis-1/2 items-center">
        <div div class="basis-1/4">
          <div class="flex-column" style="margin-top: 24px">
            <mat-label> ชื่อบริษัท(ไทย) </mat-label>
          </div>
          <div class="flex-column" style="color: #9ca3af">
            <mat-label> Thai Name </mat-label>
          </div>
        </div>
        <div div class="basis-3/4">
          <mat-label class="pr-2.5"> : </mat-label>
          <mat-label>
            {{ form.companyNameTH }}
          </mat-label>
        </div>
      </div>
      <div class="flex flex-row gap-2.5 basis-1/2 items-center">
        <div div class="basis-1/4">
          <div class="flex-column" style="margin-top: 24px">
            <mat-label> ชื่อบริษัท(อังกฤษ) </mat-label>
          </div>
          <div class="flex-column" style="color: #9ca3af">
            <mat-label> English Name </mat-label>
          </div>
        </div>
        <div div class="basis-3/4">
          <mat-label class="pr-2.5"> : </mat-label>
          <mat-label>
            {{ form.companyNameEN }}
          </mat-label>
        </div>
      </div>
    </div>
    <div class="pl-22 pr-22 flex flex-row gap-4">
      <div class="flex flex-row gap-2.5 basis-1/2 items-center">
        <div div class="basis-1/4">
          <div class="flex-column" style="margin-top: 24px">
            <mat-label> ชื่อโรงงาน </mat-label>
          </div>
          <div class="flex-column" style="color: #9ca3af">
            <mat-label> Factory </mat-label>
          </div>
        </div>
        <div div class="basis-3/4">
          <mat-label class="pr-2.5"> : </mat-label>
          <mat-form-field class="w-11/12" appearance="fill">
            <input matInput placeholder="ชื่อโรงงาน" [(ngModel)]="form.factoryName" name="factoryName"
              #factoryName="ngModel" disabled required>
          </mat-form-field>
        </div>
      </div>
      <div class="flex flex-row gap-2.5 basis-1/2 items-center">
        <div div class="basis-1/4">
          <div class="flex-column" style="margin-top: 24px">
            <mat-label> ชื่อผู้ติดต่อ </mat-label>
          </div>
          <div class="flex-column" style="color: #9ca3af">
            <mat-label> contact </mat-label>
          </div>
        </div>
        <div div class="basis-3/4">
          <mat-label class="pr-2.5"> : </mat-label>
          <mat-label>
            {{ form.contact }}
          </mat-label>
        </div>
      </div>
    </div>
    <div class="pl-22 pr-22 flex flex-row gap-4">
      <div class="flex flex-row gap-2.5 basis-1/2 items-center">
        <div div class="basis-1/4">
          <div class="flex-column" style="margin-top: 24px">
            <mat-label> E-mail ผู้ติดต่อ </mat-label>
          </div>
          <div class="flex-column" style="color: #9ca3af">
            <mat-label> E-mail Contact </mat-label>
          </div>
        </div>
        <div div class="basis-3/4">
          <mat-label class="pr-2.5"> : </mat-label>
          <mat-label>
            {{ form.email }}
          </mat-label>
        </div>
      </div>
      <div class="flex flex-row gap-2.5 basis-1/2 items-center">
        <div class="basis-1/4">
          <div class="flex-column" style="margin-top: 24px">
            <mat-label> เบอร์โทรศํพท์ผู้ติดต่อ </mat-label>
          </div>
          <div class="flex-column" style="color: #9ca3af">
            <mat-label> Phone </mat-label>
          </div>
        </div>
        <div div class="basis-3/4">
          <mat-label class="pr-2.5"> : </mat-label>
          <mat-label>
            {{ form.phone }}
          </mat-label>
        </div>
      </div>
    </div>
    <div class="pl-22 pr-22 flex flex-row gap-4">
      <div class="flex flex-row gap-2.5 basis-full items-center">
        <div style="flex-basis: 12.3%">
          <div class="flex-column" style="margin-top: 24px">
            <mat-label> ที่อยู่ </mat-label>
          </div>
          <div class="flex-column" style="color: #9ca3af">
            <mat-label> Company Address </mat-label>
          </div>
        </div>
        <div style="flex-basis: 87.7%">
          <mat-label class="pr-2.5"> : </mat-label>
          <mat-form-field class="w-11/12">
            <mat-select [(ngModel)]="form.selectAddress" disabled>
              <mat-option *ngFor="let address of addrOpt" [value]="address.addressId">
                {{address.companyAddressTypeName}} - {{ address.addrStreet }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <!-- <div class="pl-22 pr-22 flex flex-row gap-4">
      <div class="flex flex-row gap-2.5 basis-1/2 items-center">
        <div div class="basis-1/4">
          <div class="flex-column" style="margin-top: 24px">
            <mat-label> สถานะโรงงาน </mat-label>
          </div>
          <div class="flex-column" style="color: #9ca3af">
            <mat-label> Factory Status </mat-label>
          </div>
        </div>
        <div div class="basis-3/4">
          <mat-label class="pr-2.5"> : </mat-label>
          <mat-form-field class="w-11/12">
            <mat-select [(ngModel)]="form.isFactory" disabled>
              <mat-option value="Y">เปิด</mat-option>
              <mat-option value="N">ปิด</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="flex flex-row gap-2.5 basis-1/2 items-center">
        <div div class="basis-1/4">
          <div class="flex-column" style="margin-top: 24px">
            <mat-label> รหัสโรงงาน </mat-label>
          </div>
          <div class="flex-column" style="color: #9ca3af">
            <mat-label> Factory No. </mat-label>
          </div>
        </div>
        <div div class="basis-3/4">
          <mat-label class="pr-2.5"> : </mat-label>
          <mat-form-field class="w-11/12" appearance="fill" disabled>
            <input matInput placeholder="รหัสโรงงาน" [(ngModel)]="form.factoryNo" name="factoryNo" #factoryNo="ngModel" required disabled>
          </mat-form-field>
        </div>
      </div>
    </div> -->
    <div class="pl-22 pr-22 flex flex-row gap-4">
      <div class="flex flex-row gap-2.5 basis-1/2 items-center">
        <div div class="basis-1/4">
          <div class="flex-column" style="margin-top: 24px">
            <mat-label> วันที่ตรวจ </mat-label>
          </div>
          <div class="flex-column" style="color: #9ca3af">
            <mat-label> Veryfy Date </mat-label>
          </div>
        </div>
        <div div class="basis-3/4">
          <mat-label class="pr-2.5"> : </mat-label>
          <mat-label class="w-11/12">
            {{form.gradeDate ? form.gradeDate : '-' }}
          </mat-label>
        </div>
      </div>
      <div class="flex flex-row gap-2.5 basis-1/2 items-center">
        <div div class="basis-1/4">
          <div class="flex-column" style="margin-top: 24px">
            <mat-label> เอกสารการตรวจสอบโรงงาน </mat-label>
          </div>
          <div class="flex-column" style="color: #9ca3af">
            <mat-label> File Attachment </mat-label>
          </div>
        </div>
        <div div class="basis-3/4">
          <mat-label class="pr-2.5"> : </mat-label>
          <mat-label class="w-11/12">
            <button (click)="fileLoad()">คลิ๊กเพื่อดูรายละเอียด</button>
            <button mat-icon-button matTooltip="รายละเอียด" (click)="fileLoad()" matTooltipPosition="above">
              <mat-icon svgIcon="heroicons_outline:document-search"></mat-icon>
            </button>
          </mat-label>
        </div>
      </div>
    </div>
    <div class="pl-22 pr-22 flex flex-row gap-4" style="margin-top: 20px;">
      <div class="flex flex-row gap-2.5 basis-1/2">
        <div div class="basis-1/4">
          <div class="flex-column">
            <mat-label> ระดับของบริษัท </mat-label>
          </div>
          <div class="flex-column" style="color: #9ca3af">
            <mat-label> Grade Type </mat-label>
          </div>
        </div>
        <div div class="basis-3/4">
          <mat-label class="pr-2.5"> : </mat-label>
          <mat-form-field class="w-11/12">
            <mat-select [(ngModel)]="form.grade" disabled>
              <mat-option value="ดีเยี่ยม">ดีเยี่ยม</mat-option>
              <mat-option value="ดี">ดี</mat-option>
              <mat-option value="พอใช้">พอใช้</mat-option>
              <mat-option value="ไม่ผ่าน">ไม่ผ่าน</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="flex flex-row gap-2.5 basis-1/2">
        <div div class="basis-1/4">
          <div class="flex-column">
            <mat-label> เหตุผลระดับที่ได้รับ </mat-label>
          </div>
          <div class="flex-column" style="color: #9ca3af">
            <mat-label> Grade Reason </mat-label>
          </div>
        </div>
        <div div class="basis-3/4">
          <mat-label class="pr-2.5"> : </mat-label>
          <mat-form-field class="w-11/12" appearance="fill">
            <textarea matInput rows="3" [(ngModel)]="form.gradeReason" required="true" disabled></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="pl-22 pr-22 flex flex-row gap-4 pt-5">
      <div class="flex gap-2.5 basis-1/2 items-center" style="flex-direction: column !important;">
        <div class="flex" style="width: 100%">
          <div div class="basis-1/4">
            <div class="flex-column">
              <mat-label> สถานะการล็อก </mat-label>
            </div>
            <div class="flex-column" style="color: #9ca3af">
              <mat-label> Block Status </mat-label>
            </div>
          </div>
          <div div class="basis-3/4">
            <mat-label class="pr-2.5"> : </mat-label>
            <mat-form-field class="w-11/12">
              <mat-select [(ngModel)]="form.lockStatus">
                <mat-option value="Y">Lock</mat-option>
                <mat-option value="N">Unlock</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <ng-container *ngIf="form.lockStatus === 'N'">
          <div class="flex" style="width: 100%">
            <div div class="basis-1/4">
              <div class="flex-column">
                <mat-label> รายการที่จะปลดล็อก <span style="color: red">*</span></mat-label>
              </div>
              <div class="flex-column" style="color: #9ca3af">
                <mat-label> Unblock Items </mat-label>
              </div>
            </div>
            <div div class="basis-3/4">
              <mat-label class="pr-2.5"> : </mat-label>
              <mat-form-field class="w-11/12">
                <mat-select [(ngModel)]="form.refBlacklistId">
                  <mat-option *ngFor="let reason of reasonList" [value]="reason.logId">
                    {{reason.logReason}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="flex flex-row gap-2.5 basis-1/2">
        <div div class="basis-1/4">
          <div class="flex-column">
            <mat-label>เหตุผลสถานะการล็อก <span style="color: red" *ngIf="form.lockStatus === 'Y'">*</span></mat-label>
          </div>
          <div class="flex-column" style="color: #9ca3af">
            <mat-label> Lock Reason </mat-label>
          </div>
        </div>
        <div div class="basis-3/4">
          <mat-label class="pr-2.5"> : </mat-label>
          <mat-form-field class="w-11/12" style="height: 109.2 px;" appearance="fill">
            <textarea matInput rows="4" [(ngModel)]="form.lockReason" [required]="form.lockStatus === 'Y'"></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
    <!-- <div class="px-22 pt-5 flex flex-row gap-4 justify-start">
      <section class="px-4 flex flex-col flex-1 py-4 justify-evenly">
        <mat-label>รายละเอียดของไฟล์ภาพควรมีขนาดไม่เกิน 1024 x 800 pixel</mat-label>
        <section class="flex flex-row gap-4">
          <div class="file-uploader-block" *ngIf="!editItem">
            <dx-file-uploader style="border: none; padding: 0" [allowCanceling]="true" [(value)]="value" #fileUploader
              [multiple]="false" [uploadMode]="'useForm'" accept="image/gif, image/jpeg, image/png ,image/jpg , pdf"
              [allowedFileExtensions]="['.jpg', '.jpeg', '.gif', '.png', '.pdf']" [maxFileSize]="4000000">
            </dx-file-uploader>
          </div>
        </section>
        <div class="flex flex-row gap-4 mb-3 ml-3">
          <dx-button text="Upload" (onClick)="upload()"> </dx-button>
        </div>
        <div class="flex flex-row self-center">
          <div *ngIf="form.attachments.attachFilePic">
            <div class="text-end">
              <mat-icon (click)="Removing()">cancel</mat-icon>
            </div>
            <div style="width: 800px; height: 1024px">
              <img style="width: 800px; height: 1024px" [src]="form.attachments.attachFilePic" />
            </div>
          </div>
        </div>
      </section>
    </div> -->
    <div class="flex flex-col items-center p10 ">
      <div class="basis-1/2 pt-10">
        <button mat-raised-button style="width: 100px;" color="accent" (click)="save()">
          บันทึก
        </button>
      </div>
    </div>
  </mat-card-content>
  <hr style="margin-top: 5px" />
  <div>
    <div class="pb-5">
      <h1 style="font-size: large;">ประวัติการดำเนินการ (History Action)</h1>
    </div>
    <mat-card-content class="flex-row gap-4 pt-10">
      <div class="mat-elevation-z8" style="width: 100%;">
        <table mat-table matSort matSortActive="date" matSortDirection="desc" matSortDisableClear
          [dataSource]="dataSource2" (matSortChange)="announceSortChange($event)">
          <ng-container matColumnDef="date" style="text-align: left">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="justify-start text-left">
              วันที่
            </th>
            <td mat-cell *matCellDef="let element" class="justify-start text-left">
              {{ element.date | date : "yyyy-MM-dd HH:mm" }}
            </td>
          </ng-container>
          <ng-container matColumnDef="status" style="text-align: left">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="justify-start text-left">
              สถานะ
            </th>
            <td mat-cell *matCellDef="let element" class="justify-start text-left">
              {{ element.status == 'Y' ? 'Lock' : 'Unlock' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="reason" style="text-align: left">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="justify-start text-left">
              เหตุผลสถานะการล็อก
            </th>
            <td mat-cell *matCellDef="let element" class="justify-start text-left">
              {{element.reason}}
            </td>
          </ng-container>
          <ng-container matColumnDef="grade" style="text-align: left">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="justify-start text-left">
              ระดับของบริษัท
            </th>
            <td mat-cell *matCellDef="let element" class="justify-start text-left">
              {{ element.grade }}
            </td>
          </ng-container>
          <ng-container matColumnDef="reasonGrade" style="text-align: left">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="justify-start text-left">
              เเหตุผลระดับที่ได้รับ
            </th>
            <td mat-cell *matCellDef="let element" class="justify-start text-left">

              {{element.reasonGrade}}
            </td>
          </ng-container>
          <ng-container matColumnDef="user" style="text-align: left">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="justify-start text-left">
              ผู้ตรวจสอบ
            </th>
            <td mat-cell *matCellDef="let element" class="justify-start text-left">

              {{element.user}}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons
          aria-label="Select page of periodic elements" (page)="pageChanged($event)">
        </mat-paginator>
      </div>
    </mat-card-content>
  </div>

</mat-dialog-content>