import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiFactoryManagementservice } from '../api/api-fatorymanament.service';

@Injectable({
  providedIn: 'root'
})
export class AppFactoryManagementService {

  constructor(private apiFactoryManagementService: ApiFactoryManagementservice,) { }

  GetListBlackList(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiFactoryManagementService.GetListBlacklist(request).subscribe((res) => {
        resolve(res);
        });
    });
  }

  GetListBlacklistSearch(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiFactoryManagementService.GetListBlacklistSearch(request).subscribe((res) => {
        resolve(res);
      })
    })
  }
  
  GetDataCompany(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiFactoryManagementService.GetDataCompany(request).subscribe((res) => {
        resolve(res);
        });
    });
  }

  UpdateBlackList(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiFactoryManagementService.UpdateBlacklist(request).subscribe((res) => {
        resolve(res);
        });
    });
  }

  GetReasonLog(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiFactoryManagementService.GetReasonLog(request).subscribe((res) => {
        resolve(res);
        });
    });
  }

  GetListFactory(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiFactoryManagementService.GetListFactory(request).subscribe((res) => {
        resolve(res);
        });
    });
  }

  getFactoryGradeHistoryList(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiFactoryManagementService.getFactoryGradeHistoryList(request).subscribe((res) => {
        resolve(res);
      })
    })
  }

  saveFactoryGrade(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiFactoryManagementService.saveFactoryGrade(request).subscribe((res) => {
        resolve(res);
      })
    })
  }

  getAllReasonLog(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiFactoryManagementService.getAllReasonLog(request).subscribe((res) => {
        resolve(res);
      });
    });
  }
}
