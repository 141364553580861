import { Injectable } from "@angular/core";
import { IAppGetShipperRequest } from "app/business/interface/app/app-config-relationship";
import { Observable } from "rxjs";
import { ApiService } from '@bundle/service/api.service';

@Injectable({
    providedIn: 'root'
})

export class ApiFactoryManagementservice {

    constructor(private apiService: ApiService) {

    }

    GetListBlacklist(reqData: any): Observable<any[]> {
        return this.apiService.post("/api/getlistblacklist", reqData);
    }

    GetListBlacklistSearch(reqData: any): Observable<any> {
        return this.apiService.post("/api/getListBlacklistSearch", reqData);
    }

    GetDataCompany(reqData: any): Observable<any[]> {
        return this.apiService.post("/api/getdatacompany", reqData);
    }
    UpdateBlacklist(reqData: any): Observable<any[]> {
        return this.apiService.post("/api/validateblacklist", reqData);
    }
    
    GetReasonLog(reqData: any): Observable<any[]> {
        return this.apiService.post("/api/getreasonlog", reqData);
    }

    GetListFactory(reqData: any): Observable<any[]> {
        return this.apiService.post("/api/getlistfactory", reqData);
    }

    getFactoryGradeHistoryList(request: any): Observable<any> {
        return this.apiService.post("/api/getFactoryGradeHistoryList", {
            cpId: request
        });
    }

    saveFactoryGrade(request: any): Observable<any> {
        return this.apiService.post("/api/saveFactoryGrade", request);
    }

    getAllReasonLog(request: any): Observable<any> {
        return this.apiService.post("/api/getAllReasonLog", {
            cpId: request
        });
    }
}