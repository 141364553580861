import { LiveAnnouncer } from "@angular/cdk/a11y";
import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { FormControl } from "@angular/forms";
import {
  GetListCORequest,
  IcoStatus,
  IgetListCORequest,
} from "app/business/interface/app/app-co.interface";
import { AppCOService } from "app/business/service/app/app-co.service";
import { IgetListCOResponse } from "app/business/interface/api/api-co.interface";
import { MatPaginator } from "@angular/material/paginator";
import { Router, RouterLink } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import { DraftCOPopupComponent } from "../create-co-inquiry/draft-co-popup/draft-co-popup.component";
import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import moment from "moment";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { AppUserService } from "app/business/service/app/app-user.service";
import { AppMenuService } from "app/business/service/app/app-menu.service";
import { ListCoPrintPopupInquiryComponent } from "./list-co-print-popup-inquiry/list-co-print-popup-inquiry.component";
import { AppMasterService } from "app/business/service/app/app-master.service";
import { ListCoSendPrintToStaffPopupComponent } from "./list-co-send-print-to-staff-popup-inquiry/list-co-send-print-to-staff-popup-inquiry.component";
import { AuthService } from "app/core/auth/auth.service";
import _ from "lodash";

@Component({
  selector: "app-list-co-inquiry",
  templateUrl: "./list-co-inquiry.component.html",
  styleUrls: ["./list-co-inquiry.component.scss"],
})
export class ListCOInquiryComponent implements OnInit, AfterViewInit {
  dataSource = new MatTableDataSource<any>();
  dataSourceTemp = new MatTableDataSource<any>();
  myDate = new Date();
  displayedColumns: string[] = [
    "createDate",
    "requestNo",
    "docType",
    "docPriority",
    "invoiceReference",
    "isRqStatus",
    "companyName",
    "certificateNo",
    "receiptNo",
    "action",
  ];
  searchForm: any = {
    certificateNo: "",
    endDate: null,
    startDate: null,
    isRqStatus: null,
    receiptNo: "",
    requestNo: "",
    invoiceReference: "",
  };
  searchString: string;
  apiRequest: IgetListCORequest = {
    UID: 0,
    userName: "",
    requestNo: "",
    certificateNo: "",
    receiptNo: "",
    payReferenceNo: "",
    invoiceNo: "",
    documentReferenceNo: "",
    isRqstatus: "",
    docType: "CO",
    docId: 0,
  };
  data: any = {};
  apiResponse: IgetListCOResponse[];
  statusControl = new FormControl("");
  exporterControl = new FormControl("");
  statusOptions: any[] = [];
  exporterOptions: string[];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  userProfile: any = {};
  count: any = 0;
  listPrintCo: boolean = false;
  checkEndDate: boolean = true;
  checkStartDate: boolean = true;
  url: any;
  buttonList: any[] = [];
  public timerInterval: any;
  isBlacklisted: boolean = false;
  userToken: any;
  timer: any;
  packIdList: any[] = [];

  isShowQ: boolean = false;
  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private appCOService: AppCOService,
    private dialog: MatDialog,
    private appUserService: AppUserService,
    private appMenuService: AppMenuService,
    private appMasterService: AppMasterService,
    private confirmService: ConfirmDialogService,
    private alertService: AlertMessageService,
    private _authService: AuthService,
    private router: Router
  ) {}

  async getRouter(): Promise<void> {
    this.url = this.router.url;

    const formData = {
      url: this.url,
      userGroupCode: this.userToken.userGroupCode,
      userRoleCode: this.userToken.userRoleCode,
      userCode: this.userToken.userCode,
    };
    this.buttonList = await this.appMenuService.getButtonPermissionPerPages(
      formData
    );
  }

  async ngOnInit() {
    this.userToken = JSON.parse(this._authService.loginUser);

    await this.getRouter();
    this.isBlacklisted = this.userToken.isBlacklisted;

    moment.locale("th");
    this.userProfile.uId = this.userToken.uId;
    this.userProfile.cid = this.userToken.cpId;
    this.userProfile.userName = this.userToken.userName;

    this.userProfile.gId = this.userToken.gId;
    // ถ้าไม่ใช่ Exporter ต้องไม่เห็น Column ชื่อบริษัท
    if (this.userProfile.gId == 2) {
      this.displayedColumns = [
        "createDate",
        "requestNo",
        // "queueNumber",
        "docType",
        "docPriority",
        "invoiceReference",
        "isRqStatus",
        "certificateNo",
        "receiptNo",
        "action",
      ];
    }
    this.apiRequest.UID = this.userProfile.uId;
    this.apiRequest.userName = this.userProfile.userName;
    this.apiRequest.companyCode = this.userProfile.cid;
    // this.apiRequest.companyCode = 75
    this.dataSource.paginator = this.paginator;
    this.apiRequest.pagination = {
      Skip:
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
      Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
    };

    this.loadMaster();
    this.loadData(this.apiRequest);
  }
  ngAfterViewInit() {
    this.paginator.pageIndex = 0;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  loadMaster() {
    this.appMasterService.getMasterStatusList("CO").then((res) => {
      if (res === "error") {
        this.statusOptions = [];
      } else {
        this.statusOptions = res;

        const checkAll = this.statusOptions.find(
          (e) => e.statusNameTh === "ทั้งหมด"
        );

        if (checkAll === undefined) {
          this.statusOptions.unshift({ statusId: 1, statusNameTh: "ทั้งหมด" });
        }
      }
    });
  }

  async loadData(request: IgetListCORequest) {
    this.apiRequest.keyword =
      typeof this.apiRequest.keyword !== "string"
        ? ""
        : this.apiRequest.keyword;
    let rqStatus = parseInt(this.apiRequest.isRqstatus);
    moment.locale("th");
    // var eD = this.searchForm.endDate != null && this.searchForm.endDate != undefined ? moment(new Date(this.searchForm.endDate)).toDate() : null;
    // var sD = this.searchForm.startDate != null && this.searchForm.startDate != undefined ? moment(new Date(this.searchForm.startDate)).toDate() : null;

    // Reset to page 1 before calling the API
    if (this.paginator != undefined && this.paginator != null) {
      this.paginator.pageIndex = 0
    }

    let reqList = {
      uId: this.userProfile.uId,
      gId: this.userProfile.gId,
      // compId: this.apiRequest.companyCode,
      compName: this.apiRequest.companyName,
      endDate: this.searchForm.endDate,
      refNo: this.apiRequest.documentReferenceNo, //ถ้าไม่เช็คมันจะเอาไป where แบบ and มันจะพัง
      reqNo: this.apiRequest.requestNo, //ถ้าไม่เช็คมันจะเอาไป where แบบ and มันจะพัง
      rqStatus: isNaN(rqStatus) ? null : rqStatus,
      thtNo: this.apiRequest.certificateNo, //ถ้าไม่เช็คมันจะเอาไป where แบบ and มันจะพัง
      startDate: this.searchForm.startDate,
      keyword: this.apiRequest.keyword,
      pagination: {
        Skip: 0, // Start from page 1 (skip 0)
        Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
      },
    } as GetListCORequest;

    await this.appCOService.getListCO(reqList).then((res: any) => {
      if (res === "error") {
        return;
      } else {
        if (res !== null) {
          let a = res.response.map((item) => {
            moment.locale("th"); // set default locale to Thai
            item.createDate = moment(item.createDate).format(
              "YYYY-MM-DD HH:mm"
            );
            if (
              item.queueNumber != null &&
              item.queueNumber != undefined &&
              item.queueNumber != ""
            ) {
              item.queueNumber = parseInt(item.queueNumber.slice(-4));
            }
            return item;
          });
          this.dataSource.data = a;
          this.count = res.totalCount;

          console.log("sss  a", a);
          console.log("sss this.dataSource.data", this.dataSource.data);
          console.log("sss this.dataSource.paginator", this.paginator);
        } else {
          this.dataSource.data = [];
          this.count = 0;
        }
      }
    });
    this.dataSource.paginator.length = this.count;
  }
  // applyFilter(filterValue: string) {
  //   // this.apiRequest.receiptNo = filterValue
  //   // this.apiRequest.requestNo = filterValue
  //   // this.apiRequest.invoiceNo = filterValue
  //   this.apiRequest.keyword = filterValue
  //   this.dataSource.paginator = this.paginator
  //   this.loadData(this.apiRequest)
  // }

  applyFilter(event: string) {
    clearTimeout(this.timer); // Clear any existing timer

    this.timer = setTimeout(() => {
      // This code will be executed after a specified delay (e.g., 500 milliseconds)

      // Send the value to the backend or perform any other necessary actions
      this.apiRequest.keyword = event;
      this.dataSource.paginator = this.paginator;
      this.loadData(this.apiRequest);
    }, 500); // Specify the desired delay in milliseconds
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce("Sorting cleared");
    }
  }
  stopPropagation(event) {
    event.stopPropagation();
  }
  saveSearch() {
    this.apiRequest.keyword = "";
    this.searchString = "";
    if (
      moment(this.searchForm.startDate).format("YYYY-MM-DD") >
        moment(this.searchForm.endDate).format("YYYY-MM-DD") &&
      !moment(this.searchForm.startDate).format("YYYY-MM-DD")
    ) {
      this.alertService.open({
        message: "กรุณาระบุวันที่สิ้นสุดให้มากกว่าวันที่เริ่มต้น",
        title: "กรุณากรอกข้อมูลค้นหาให้ถูกต้อง",
      });
      this.searchForm.startDate = {};
      this.searchForm.endDate = {};
      return false;
    }
    this.apiRequest.UID = this.userProfile.uId;
    this.apiRequest.certificateNo = this.searchForm.certificateNo;
    moment.locale("th");
    // var eD = this.searchForm.endDate != null && this.searchForm.endDate != undefined ? moment(new Date(this.searchForm.endDate)).toDate() : null;
    // var sD = this.searchForm.startDate != null && this.searchForm.startDate != undefined ? moment(new Date(this.searchForm.startDate)).toDate() : null;
    this.apiRequest.endDate = this.searchForm.endDate;
    this.apiRequest.startDate = this.searchForm.startDate;
    this.apiRequest.docType = "CO";
    this.apiRequest.isRqstatus =
      this.searchForm.status == "1" ? null : this.searchForm.status;
    this.apiRequest.receiptNo = this.searchForm.receiptNo;
    this.apiRequest.requestNo = this.searchForm.requestNo;
    this.apiRequest.invoiceNo = this.searchForm.invoiceReference;
    this.apiRequest.documentReferenceNo = this.searchForm.invoiceReferenceNo;
    this.dataSource.paginator = this.paginator;
    this.checkEndDate = true;
    this.loadData(this.apiRequest);
  }

  onRefresh() {
    this.loadData(this.apiRequest);
  }

  print(e) {
    if (this.isBlacklisted) {
      this.alertService.open({
        message:
          "ไม่สามารถพิมพ์เอกสาร CO ได้ เนื่องจากบริษัทของคุณติดสถานะ Blacklist",
        title: "",
      });
    } else {
      var actionReq = {
        id: e.packId,
        action: "PRINT",
        refTable: "DOCUMENT_REQUEST_OBJ",
      };

      this.appMasterService.getActionStatus(actionReq).then((res) => {
        if (res === "error") {
          return;
        } else {
          var data = res;

          if (data.allowAction === "Y") {
            this.confirmService
              .open({
                header: "ยืนยันการทำรายการ",
                content:
                  e.rqstatusId == 6
                    ? "ต้องการพิมพ์เอกสาร"
                    : "ต้องการพิมพ์ Draft หรือไม่",
              })
              .then((res) => {
                if (res == true && e.docType == "CO/Pack") {
                  if (e.rqstatusId == 6) {
                    this.listPrintCo = true;
                  } else {
                    this.listPrintCo = false;
                  }
                  const dataList = {
                    requestNo: e.requestNo ? e.requestNo : "-",
                    docType: e.docType,
                    expressType: e.expressType,
                    packId: e.packId,
                    listPrintCo: true,
                    rqstatusObj: e.rqstatusId,
                  };
                  const dialogRef = this.dialog.open(
                    ListCoPrintPopupInquiryComponent,
                    {
                      disableClose: false,
                      width: "70vw",
                      data: dataList,
                      maxWidth: "none",
                    }
                  );
                  dialogRef.afterClosed().subscribe((res) => {
                    if (res == false) {
                      return;
                    } else {
                      return;
                    }
                  });
                } else if (res == true && e.docType == "CO") {
                  const data = {
                    Hid: e.packId,
                  };
                  if (e.rqstatusId == 6) {
                    if (e.selfPrint == "Y") {
                      this.appCOService
                        .getPrintOriginal(data)
                        .then((res: any) => {
                          if (res === "error") {
                            return;
                          } else {
                            var data = res;
                            window.open(data.linkUrl, "_blank");
                            this.loadData(this.apiRequest);
                          }
                        });
                    } else {
                      this.alertService
                        .open({
                          title: "Error Message",
                          message:
                            "ท่านได้แจ้งความประสงค์ให้ทางหอการค้าไทยพิมพ์เอกสาร",
                        })
                        .then((res) => {
                          this.loadData(this.apiRequest);
                        });
                    }
                  } else {
                    this.appCOService
                      .getPreviewCOPack(data)
                      .then((res: any) => {
                        if (res === "error") {
                          return;
                        } else {
                          window.open(res.linkUrl, "_blank");
                        }
                      });
                  }
                }
              });
          } else {
            this.alertService
              .open({
                message:
                  "ไม่สามารถพิมพ์เอกสารได้ เนื่องจากเอกสารได้ถูกพิมพ์แล้ว",
                title: "",
              })
              .then((res) => {
                this.loadData(this.apiRequest);
                return;
              });
          }
        }
      });
    }
  }
  showDetail() {}
  deleteItem(e) {
    if (this.isBlacklisted) {
      this.alertService.open({
        message:
          "ไม่สามารถลบรายการ CO ได้ เนื่องจากบริษัทของคุณติดสถานะ Blacklist",
        title: "",
      });
    } else {
      var actionReq = {
        id: e.packId,
        action: "DELETE",
        refTable: "DOCUMENT_REQUEST_OBJ",
      };

      this.appMasterService.getActionStatus(actionReq).then((res) => {
        if (res === "error") {
          return;
        } else {
          var data = res;

          if (data.allowAction === "Y") {
            this.confirmService
              .open({
                header: "ยืนยันการทำรายการ",
                content: "ยืนยันการลบข้อมูลนี้ใช่หรือไม่?",
              })
              .then((res) => {
                if (res == true) {
                  const data = {
                    id: e.docId,
                    docId: e.docId,
                    packId: e.packId,
                    receiptNo: e.receiptNo,
                    userName: this.userProfile.userName,
                  };
                  this.appCOService.deleteCO(data).then((res: any) => {
                    if (res == "error") {
                      return;
                    } else {
                      this.alertService
                        .open({
                          title: "ทำรายการสำเร็จ",
                          message: "ลบข้อมูลสำเร็จ",
                        })
                        .then((res) => {
                          this.loadData(this.apiRequest);
                        });
                    }
                  });
                }
              });
          } else {
            this.alertService
              .open({
                message: "ไม่สามารถลบรายการได้ เนื่องจากเอกสารถูกลบในระบบแล้ว",
                title: "",
              })
              .then((res) => {
                this.loadData(this.apiRequest);
                return;
              });
          }
        }
      });
    }
  }
  cancel(e) {
    if (this.isBlacklisted) {
      this.alertService.open({
        message:
          "ไม่สามารถยกเลิกคำขอส่งตรวจได้ เนื่องจากบริษัทของคุณติดสถานะ Blacklist",
        title: "",
      });
    } else {
      var actionReq = {
        id: e.packId,
        action: "UNSEND",
        refTable: "DOCUMENT_REQUEST_OBJ",
      };

      this.appMasterService.getActionStatus(actionReq).then((res) => {
        if (res === "error") {
          return;
        } else {
          var data = res;

          if (data.allowAction === "Y") {
            const data = {
              docId: e.docId,
              packId: e.packId,
              isRqStatus: "34",
            };

            this.confirmService
              .open({
                header: "ยืนยันการทำรายการ",
                content: "ต้องการยกเลิกคำขอ",
              })
              .then((res) => {
                if (res == true) {
                  this.appCOService.cencelVerify(data).then((res) => {
                    if (res == "error") {
                      return;
                    } else {
                      this.alertService
                        .open({
                          title: "ทำรายการสำเร็จ",
                          message: "ยกเลิกคำขอสำเร็จ",
                        })
                        .then((res) => {
                          this.loadData(this.apiRequest);
                        });
                    }
                  });
                }
              });
          } else {
            this.alertService
              .open({
                message:
                  "ไม่สามารถยกเลิกส่งตรวจได้ เนื่องจากสถานะเอกสารถูกเปลี่ยนในระบบแล้ว",
                title: "",
              })
              .then((res) => {
                this.loadData(this.apiRequest);
                return;
              });
          }
        }
      });
    }
  }
  async pageChanged(e) {
    this.apiRequest.pagination = {
      Skip: (!e.pageIndex ? 0 : e.pageIndex) * (!e.pageSize ? 0 : e.pageSize),
      Take: !e.pageSize ? 10 : e.pageSize,
    };
    let rqStatus = parseInt(this.apiRequest.isRqstatus);
    moment.locale("th");
    let reqList = {
      uId: this.userProfile.uId,
      gId: this.userProfile.gId,
      // compId: this.apiRequest.companyCode,
      compName: this.apiRequest.companyName,
      endDate: this.searchForm.endDate,
      refNo: this.apiRequest.invoiceNo,
      reqNo: this.apiRequest.requestNo,
      rqStatus: isNaN(rqStatus) ? null : rqStatus,
      thtNo: this.apiRequest.certificateNo,
      startDate: this.searchForm.startDate,
      pagination: {
        Skip: (!e.pageIndex ? 0 : e.pageIndex) * (!e.pageSize ? 0 : e.pageSize),
        /*(!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),*/
        Take: !e.pageSize ? 10 : e.pageSize, //!this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
      },
    } as GetListCORequest;
    await this.appCOService.getListCO(reqList).then((res: any) => {
      if (res === "error") {
        return;
      } else {
        if (res.response !== null) {
          moment.locale("th"); // set default locale to Thai

          let a = res.response.map((item) => {
            // item.createDate = new Date(item.createDate)
            //   .toISOString()
            //   .split('T')[0]
            item.createDate = moment(item.createDate).format(
              "YYYY-MM-DD HH:mm"
            );
            if (
              item.queueNumber != null &&
              item.queueNumber != undefined &&
              item.queueNumber != ""
            ) {
              item.queueNumber = parseInt(item.queueNumber.slice(-4));
            }
            return item;
          });

          this.dataSource.data = a;
          if (this.isShowQ == true) {
            this.getQueue();
          } else {
            this.displayedColumns = [
              "createDate",
              "requestNo",
              "docType",
              "docPriority",
              "invoiceReference",
              "isRqStatus",
              "companyName",
              "certificateNo",
              "receiptNo",
              "action",
            ];
          }
        } else {
          this.dataSource = new MatTableDataSource((res.response3 = []));
        }
        this.count = res.totalCount;
      }
    });
    this.dataSource.paginator.length = this.count;
    this.dataSource.paginator.pageIndex = e.pageIndex;
  }

  clearOption() {
    this.searchForm = {};
    this.apiRequest = {
      UID: 0,
      userName: "",
      requestNo: "",
      certificateNo: "",
      receiptNo: "",
      payReferenceNo: "",
      invoiceNo: "",
      documentReferenceNo: "",
      isRqstatus: "",
      docType: "CO",
      docId: 0,
    };

    this.checkEndDate = true;
    this.loadData(this.searchForm);
  }

  datecheck(e: MatDatepickerInputEvent<Date>) {
    if (
      moment(this.searchForm.startDate).format("YYYY-MM-DD") >
      moment(this.searchForm.endDate).format("YYYY-MM-DD")
    ) {
      this.searchForm.endDate = {};
      this.checkEndDate = false;
    }
  }

  endDateCheck(e) {
    if (
      moment(this.searchForm.startDate).format("YYYY-MM-DD") ==
        moment(this.searchForm.endDate).format("YYYY-MM-DD") ||
      moment(this.searchForm.startDate).format("YYYY-MM-DD") <
        moment(this.searchForm.endDate).format("YYYY-MM-DD")
    ) {
      this.checkEndDate = true;
    }
  }

  createCo() {
    if (this.isBlacklisted) {
      this.alertService.open({
        message:
          "ไม่สามารถสร้าง CO ใหม่ได้ เนื่องจากบริษัทของคุณติดสถานะ Blacklist",
        title: "",
      });
    } else {
      this.router.navigateByUrl("/page/request/request-co/create-co");
    }
  }

  editCo(e) {
    if (this.isBlacklisted) {
      this.alertService.open({
        message:
          "ไม่สามารถแก้ไข CO ได้ เนื่องจากบริษัทของคุณติดสถานะ Blacklist",
        title: "",
      });
    } else {
      var actionReq = {
        id: e.packId,
        action: "EDIT",
        refTable: "DOCUMENT_REQUEST_OBJ",
      };

      this.appMasterService.getActionStatus(actionReq).then((res) => {
        if (res === "error") {
          return;
        } else {
          var data = res;

          if (data.allowAction === "Y") {
            this.router.navigate(["/page/request/request-co/create-co"], {
              queryParams: {
                id: e.packId,
              },
            });
          } else {
            this.alertService
              .open({
                message:
                  "ไม่สามารถแก้ไข CO ได้ เนื่องจากสถานะเอกสารถูกเปลี่ยนในระบบแล้ว",
                title: "",
              })
              .then((res) => {
                this.loadData(this.apiRequest);
                return;
              });
          }
        }
      });
    }
  }

  openPopupSendPrintTostaff(e) {
    if (this.isBlacklisted) {
      this.alertService.open({
        message:
          "ไม่สามารถสั่งพิมพ์เอกสาร CO ที่หอการค้าไทยได้ เนื่องจากบริษัทของคุณติดสถานะ Blacklist",
        title: "",
      });
    } else {
      var actionReq = {
        id: e.packId,
        action: "PRINT",
        refTable: "DOCUMENT_REQUEST_OBJ",
      };

      this.appMasterService.getActionStatus(actionReq).then((res) => {
        if (res === "error") {
          return;
        } else {
          var data = res;

          if (data.allowAction === "Y") {
            let data = {
              packId: e.packId,
              requestNo: e.requestNo,
              docType: e.docType,
              expressType: e.expressType,
              consignor: e.consignor,
            };
            const dialogRef = this.dialog.open(
              ListCoSendPrintToStaffPopupComponent,
              {
                disableClose: false,
                width: "70vw",
                data: data,
                maxWidth: "none",
              }
            );
            dialogRef.afterClosed().subscribe((res) => {
              if (!res) {
                return;
              } else {
                this.loadData(this.apiRequest);
                return;
              }
            });
          } else {
            this.alertService
              .open({
                message:
                  "ไม่สามารถสั่งพิมพ์เอกสารได้ เนื่องจากได้ยื่นเรื่องให้หอการค้าไทยพิมพ์เอกสารแล้ว",
                title: "",
              })
              .then((res) => {
                this.loadData(this.apiRequest);
                return;
              });
          }
        }
      });
    }
  }

  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find((e) => e.buttonCode == buttonCode);

    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }

  getQueue() {
    this.isShowQ = true;
    this.timers(1);
    if (this.isShowQ) {
      if (this.userProfile.gId == 2) {
        this.displayedColumns = [
          "createDate",
          "requestNo",
          "queueNumber",
          "docType",
          "docPriority",
          "invoiceReference",
          "isRqStatus",
          "certificateNo",
          "receiptNo",
          "action",
        ];
      } else {
        this.displayedColumns = [
          "createDate",
          "requestNo",
          "queueNumber",
          "docType",
          "docPriority",
          "invoiceReference",
          "isRqStatus",
          "companyName",
          "certificateNo",
          "receiptNo",
          "action",
        ];
      }

      this.getQueuePackId();
    } else {
      if (this.userProfile.gId == 2) {
        this.displayedColumns = [
          "createDate",
          "requestNo",
          // "queueNumber",
          "docType",
          "docPriority",
          "invoiceReference",
          "isRqStatus",
          "certificateNo",
          "receiptNo",
          "action",
        ];
      } else {
        this.displayedColumns = [
          "createDate",
          "requestNo",
          "docType",
          "docPriority",
          "invoiceReference",
          "isRqStatus",
          "companyName",
          "certificateNo",
          "receiptNo",
          "action",
        ];
      }
    }
  }

  timers(minute) {
    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec: number = 60;

    const prefix = minute < 10 ? "0" : "";

    this.timerInterval = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;
      if (seconds == 0) {
        console.log("finished");
        this.isShowQ = false;
        this.displayedColumns = [
          "createDate",
          "requestNo",
          "docType",
          "docPriority",
          "invoiceReference",
          "isRqStatus",
          "companyName",
          "certificateNo",
          "receiptNo",
          "action",
        ];
        clearInterval(this.timerInterval);
      }
    }, 1000);
  }

  getQueuePackId() {
    this.packIdList = [];
    this.dataSource.data.forEach((element) => {
      this.packIdList.push(element.packId);
    });
    const req = this.dataSource.data.map((m) => {
      return {
        docPriority: m.priorityId,
        packId: m.packId,
      };
    });
    this.appCOService.getQueueByPackId({ docPriorities: req }).then((res) => {
      let a = [];
      res.forEach((ele) => {
        a = this.dataSource.data.map((m) => {
          if (ele.packId === m.packId) {
            m.queueNumber = ele.queue != 0 ? ele.queue : "";
          }
          return m;
        });
      });
      this.dataSource.connect().next(a);
    });
  }
}
