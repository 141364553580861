import { LiveAnnouncer } from '@angular/cdk/a11y';
import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IcoStatus } from 'app/business/interface/app/app-co.interface';
import { AppPrintService } from 'app/business/service/app/app-print.service';

@Component({
  selector: 'app-reprint-popup-inquiry',
  templateUrl: './reprint-popup-inquiry.component.html',
  styleUrls: ['./reprint-popup-inquiry.component.scss']
})
export class ReprintPopupInquiryComponent implements OnInit, AfterViewInit {
  searchForm: any = {
    certificateNo: '',
    endDate: null,
    startDate: null,
    isRqStatus: null,
    receiptNo: '',
    requestNo: '',
    invoiceReference: '',
  }
  displayedColumns: string[] = [
    'requestNo',
    'invoiceRefNo',
    'requestDatetime',
    'certNo',
    'paymentStatus',
    'action',
  ]
  searchString: string;
  apiRequest: any;
  apiResponse: any[];
  statusControl = new FormControl('')
  exporterControl = new FormControl('')
  
  exporterOptions: string[]
  dataSource = new MatTableDataSource<any>()
  selection = new SelectionModel<any>(true, []);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  formData: any = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef: MatDialogRef<ReprintPopupInquiryComponent>,
    private dialog: MatDialog,
    private _liveAnnouncer: LiveAnnouncer,
    private appPrintService: AppPrintService,
  ) {
    if (data) {
      this.formData = data;
    } else {
      this.formData.isActive = 'Y';
    }
    
  }

  ngOnInit() {
    this.loadData(this.apiRequest);
  }
  ngAfterViewInit() {
  }
  loadData(request: any) {
  }
  saveSearch() {
  }

  print() {

  }
  showDetail() {

  }
  deleteItem() {
    this.dialogRef.close()
  }

  save() {
    const request = {
      ...this.formData,
    }
    this.appPrintService.saveRePrint(request).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        this.dialogRef.close(res);
      }
    })
  }
  editItem(e) {
    
    this.formData = e;
  }
}
