<div style="gap: 30px;padding-top: 30px;" class="flex flex-col h-full">
  <div class="menu justify-between flex items-stretch flex-row gap-5 px-20  pl-10  pr-10">
    <div class="gap-2.5 flex flex-row">
      <button mat-raised-button color="accent" (click)="onRefresh()">
        <div class="flex items-center">
          <span class="icon-text"><mat-icon svgIcon="heroicons_outline:refresh"></mat-icon>รีเฟรชข้อมูล</span>
        </div>
      </button>
    </div>
    <div style="flex: 1;" class="gap-2.5 flex justify-end flex-row">
      <div class="justify-evenly flex gap-2.5 items-center flex-row" style="flex: 0 40%;">
        <mat-form-field style="flex: 1" class="my-form-field flex flex-row">
          <input autocomplete="off" type="text" matInput placeholder="ชื่อบริษัท / ชื่อผู้ตรวจ / หมายเลขคำขอ" matInput
            #input [(ngModel)]="form.search" name="search" #search="ngModel" (keyup)="applyFilter($event.target.value)">
          <button matSuffix mat-icon-button aria-label="ค้นหา">
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <button mat-raised-button color="accent" [matMenuTriggerFor]="menu">
        <div class="flex items-center">
          <span>เงื่อนไขการค้นหา</span>
          <mat-icon>expand_more</mat-icon>
        </div>
      </button>
      <mat-menu #menu="matMenu" xPosition="before" class="my-menu">
        <div class="pl-2 pr-2 flex flex-col" (click)="stopPropagation($event)">
          <div class="pt-2 pl-2 pr-2 flex flex-row">
            <mat-label style="font-size: 0.85em !important">วันเวลาที่อนุมัติ | Approve Date</mat-label>
          </div>
          <div class="p-2 justify-evenly flex gap-2.5 items-center flex-row">
            <mat-form-field style="flex: 1" class="my-form-field flex flex-row">
              <mat-label>จากวันที่ | From</mat-label>
              <input matInput [matDatepicker]="pickstart" placeholder="จากวันที่" [(ngModel)]="form.startDate"
                [ngModelOptions]="{standalone: true}" [max]="form.endDate" readonly (click)="pickstart.open()">
              <mat-datepicker-toggle matIconSuffix [for]="pickstart"></mat-datepicker-toggle>
              <mat-datepicker #pickstart></mat-datepicker>
            </mat-form-field>
            <mat-form-field style="flex: 1" class="my-form-field flex flex-row">
              <mat-label>ถึงวันที่ | To</mat-label>
              <input matInput [matDatepicker]="pickend" placeholder="ถึงวันที่" [(ngModel)]="form.endDate"
                [ngModelOptions]="{standalone: true}" [min]="form.startDate" readonly (click)="pickend.open()">
              <mat-datepicker-toggle matIconSuffix [for]="pickend"></mat-datepicker-toggle>
              <mat-datepicker #pickend></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="p-2 justify-evenly flex gap-2.5 items-center flex-row ">
            <form name="searchCompanyForm" [formGroup]="searchCompanyForm" style="flex: 1"
              class="my-form-field flex flex-row">
              <mat-form-field style="flex: 1" class="my-form-field flex flex-row">
                <mat-label>รหัสบริษัท | Company Code</mat-label>
                <input matInput type="text" formControlName="companySearch" [matAutocomplete]="auto" name="companyCode">
                <mat-autocomplete #auto="matAutocomplete" required (optionSelected)="selectionChange($event)"
                  [panelWidth]="'auto'">
                  <mat-option *ngIf="isLoading">
                    <mat-spinner [diameter]="20"></mat-spinner>
                  </mat-option>
                  <mat-option *ngFor="let option of filteredOptions$  | async" [value]="option">
                    {{option.display}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </form>
            <mat-form-field style="display: flex;flex-flow: row;flex: 1">
              <mat-label>ชื่อบริษัท | Company Name</mat-label>
              <input autocomplete="off" type="text" matInput placeholder="ชื่อบริษัท" matInput #input
                [(ngModel)]="form.companyName" name="companyName" #companyName="ngModel">
            </mat-form-field>
          </div>
          <div class="p-2 justify-evenly flex gap-2.5 items-center flex-row ">
            <mat-form-field style="flex: 1" class="my-form-field flex flex-row">
              <mat-label>เจ้าหน้าที่ผู้อนุมัติ | Verify User </mat-label>
              <mat-select [(ngModel)]="form.queueBy" name="queueBy" #queueBy="ngModel">
                <mat-option *ngFor="let user of userList" [value]="user.userId">
                  {{user.staffName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field style="flex: 1" class="my-form-field flex flex-row">
              <mat-label>สถานะชุดงาน | Status </mat-label>
              <mat-select [(ngModel)]="form.status" name="status" #status="ngModel">
                <mat-option *ngFor="let statusOption of statusOptions" [value]="statusOption.value">
                  {{statusOption.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="flex justify-end pt-2">
            <div>
              <button mat-raised-button (click)="clearFilter()" style="flex:0 50%; margin-right: 10px;">
                เคลียร์
              </button>
              <button mat-raised-button color="accent" (click)="onSearch()" style="flex:0 50%;">
                ค้นหา
              </button>
            </div>
          </div>

        </div>
      </mat-menu>
    </div>

  </div>
  <div class="mat-elevation-z8" style="overflow-x:auto;">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <table mat-table matSort style="width: 100%;" [dataSource]="dataSource"
      (matSortChange)="announceSortChange($event)">
      <!-- Position Column -->
      <ng-container matColumnDef="requestNo">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by number" class="text-left"> เลขที่คำขอ
        </th>
        <td mat-cell *matCellDef="let element" class="text-left"> {{element.requestNo}} </td>
      </ng-container>

      <ng-container matColumnDef="consignor">
        <th mat-header-cell *matHeaderCellDef class="text-left"> ชื่อบริษัท </th>
        <td mat-cell *matCellDef="let element"> {{element.declarator}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="documentType">
        <th mat-header-cell *matHeaderCellDef class="text-left"> ประเภทเอกสาร </th>
        <td mat-cell *matCellDef="let element" class="text-left"> {{element.documentType}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="expressType">
        <th mat-header-cell *matHeaderCellDef class="text-left"> สถานะเร่งด่วน </th>
        <td mat-cell *matCellDef="let element" class="text-left"> {{element.expressType === 'Y' ? 'เร่งด่วน' :
          'ไม่เร่งด่วน'}} </td>
      </ng-container>

      <ng-container matColumnDef="queueBy">
        <th mat-header-cell *matHeaderCellDef class="text-left"> ชื่อผู้ตรวจ </th>
        <td mat-cell *matCellDef="let element"> {{element.queueByName}} </td>
      </ng-container>

      <ng-container matColumnDef="approvalStatus">
        <th mat-header-cell *matHeaderCellDef class="text-left"> สถานะชุดงาน </th>
        <td mat-cell *matCellDef="let element" class="text-left"> {{element.approvalStatus }} </td>
      </ng-container>

      <ng-container matColumnDef="approvalDate">
        <th mat-header-cell *matHeaderCellDef class="text-left"> วันที่อนุมัติ </th>
        <td mat-cell *matCellDef="let element" class="text-left"> {{ datePipe.transform(element.approvalDate,
          'yyyy-MM-dd') }} <br>
          {{ datePipe.transform(element.approvalDate,'HH:mm') }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]" (page)="_page($event)" showFirstLastButtons
      aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>
</div>