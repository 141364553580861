// import { TranslateService } from "@ngx-translate/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import { environment } from "environments/environment";
import { BehaviorSubject, Observable, Subscription, of, throwError } from "rxjs";
import { catchError, delay, map } from "rxjs/operators";
import { HttpLoadingService } from "./http-loading.service";
import { ApiResponseModel } from "./model/api-model";
import jwt_decode from "jwt-decode";
import { AuthUtils } from "app/core/auth/auth.utils";
import { AuthService } from "app/core/auth/auth.service";
@Injectable({
  providedIn: "root",
})
export class ApiService {
  loading: BehaviorSubject<boolean>;
  timeout;
  tokenSubscription = new Subscription();
  userProfile :any;
  private url = environment.api;
  private httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Accept: "application/json",
    }),
    params: null,
  };
  private httpFileOptions = {
    headers: new HttpHeaders({
      responseType: "blob",
    }),
    params: null,
  };
  constructor(
    private httpClient: HttpClient,
    public alert: AlertMessageService,
    private httpLoadingService: HttpLoadingService,
    private _authService:AuthService,
    private router: Router,
    private route: ActivatedRoute // private translate: TranslateService
  ) {
    this.loading = new BehaviorSubject(false);
  
  }

  get(action: string, params?: any): Observable<any> {
    this.httpOptions.params = params;
    const httpRequest = this.httpClient.get(
      `${this.url}${action}`,
      this.httpOptions
    );
    return this.handleResponse(httpRequest);
  }

  post(
    action: string,
    requestData: any,
    params?: any,
    alert?: boolean
  ): Observable<any> {
    this.httpOptions.params = params;
    const httpRequest = this.httpClient.post<any>(
      `${this.url}${action}`,
      requestData,
      this.httpOptions
    );
    return this.handleResponse(httpRequest, alert);
  }

  put(
    action: string,
    requestData: any,
    params?: any,
    alert?: boolean
  ): Observable<any> {
    this.httpOptions.params = params;
    const httpRequest = this.httpClient.put(
      `${this.url}${action}`,
      requestData,
      this.httpOptions
    );
    return this.handleResponse(httpRequest, alert);
  }

  postForm(
    action: string,
    requestData: FormData,
    alert?: boolean
  ): Observable<any> {
    const httpRequest = this.httpClient.post(
      `${this.url}${action}`,
      requestData
    );
    return this.handleResponse(httpRequest, alert);
  }

  getFile(url: string, params?: any): Observable<Blob> {
    this.httpFileOptions.params = params;
    return this.httpClient.get<Blob>(url, this.httpFileOptions);
  }

  private handleResponse(
    httpRequest: Observable<any>,
    alert?: boolean
  ): Observable<any> {
    return httpRequest.pipe(
      map(
        (next: ApiResponseModel) => {
          this.httpLoadingService.stop();
          if (!next.success) {
  
            if (next?.success == undefined) {
            } else {
              const res = {
                title: next.description,
                message: next.message,
              };
              this.alert.open(res);
            }
            return "error";
          } else {
            //=====ใน MUDJAI ไม่มี

            let getToken = this.getDecodedAccessToken(next.key);
            getToken = {
              ...getToken,
              uId:getToken.uId,
              uid:getToken.uId,
            }
            this.userProfile = JSON.stringify(getToken);
            if (this._authService.login =='true') {
              
              this._authService.userProfile = JSON.stringify(getToken);
        
            }
            const urlLink = this.router.url;
            if(urlLink != '/condition' && urlLink != '/sign-in' && urlLink != '/'  && !getToken && getToken.userName==null){

              return;
            }
            //=======================
            this._authService.token = next.key;
            return next.items;
          }
        },
        (error: ApiResponseModel) => {
          this.alert.open({
            title: "error",
            message: "การเชื่อมต่อไม่สำเร็จ",
          });
          const res = {
            status: false,
            msg: "Http error.",
            data: error,
          };
          return "error";

        }
      ),
      catchError((err) => {
        const res = {
          status: false,
          msg: `${err.statusText} : ${err.url}`,
          data: err,
        };
        const httpError = {
          title: err.url,
          message: err.statusText,
        };
        this.alert.open(httpError);

        this.httpLoadingService.stop();
        const { token } = this.route.snapshot.queryParams;

        return throwError("error");
      })
    );
  }
  getDecodedAccessToken(token: string): any {
    return jwt_decode(token);
  
  }

  
  signOut(): Observable<any> {
    this.tokenSubscription.unsubscribe();
    return of(true);
  } 
}
