<div class="card-pd tltle-menu" *ngIf="subEdit ==false">
    User Request CO <span
        *ngIf="documentDetail?.requestNo != null && documentDetail?.requestNo!= undefined && documentDetail?.requestNo != ''">/
        {{documentDetail?.requestNo}} </span>
</div>
<div class="flex flex-col h-full gap-4">
    <!-- <div style="gap: 30px;" class="flex justify-items-stretch"
        [ngClass]="{'flex-row px-20': subEdit != true,'flex-col px-2': subEdit == true}"> -->

    <div class="flex flex-col justify-items-stretch content-start"
        [ngClass]="{'px-20': subEdit != true,'px-2': subEdit == true}" *ngIf="checkInfoDiv()">
        <div class="flex flex-col flex-1 grid-col-1 gap-4 justify-between ">
            <mat-card *ngIf="form.isRqstatus == 10 && markErrorList.length != 0">
                <mat-card-header>
                    <div class="text-center">ช้อผิดพลาดของเอกสาร | Verify Remark Document</div>
                </mat-card-header>

                <mat-card-content>
                    <section class="px-4 flex flex-col flex-1 pb-4  justify-evenly">
                        <dx-data-grid class="mt-3" #gridContainer [dataSource]="markErrorList"
                            [allowColumnReordering]="true" [showBorders]="true">
                            <dxo-editing mode="row" [allowUpdating]="false" [allowDeleting]="false">
                            </dxo-editing>
                            <dxi-column dataField="docTypeName" caption="ประเภทเอกสาร" [width]="200">
                            </dxi-column>
                            <dxi-column dataField="fieldName" caption="ชื่อฟิลด์">
                            </dxi-column>
                            <dxi-column dataField="remark" caption="ข้อผิดพลาด">
                            </dxi-column>
                            <dxi-column cellTemplate="action" caption="Action" [width]="100">
                            </dxi-column>
                            <div *dxTemplate="let row of 'action'" style="font-size:9px;">
                                <button (click)="onClick(row)" data-toggle="modal" data-target="#myModal"
                                    *ngIf="markErrorList[row.rowIndex].fieldId !== 0">
                                    <mat-icon svgIcon="mat_outline:search"></mat-icon>
                                </button>
                            </div>
                        </dx-data-grid>
                    </section>
                </mat-card-content>
            </mat-card>
        </div>

        <div class="flex flex-col flex-1 grid-col-1 gap-4 justify-between "
            *ngIf="PackidLoadData != 0 && PackidLoadData != undefined && lengthViewChildPayment != 0">
            <!-- Payment List-->
            <app-detail-payment-list-table [hId]="PackidLoadData"></app-detail-payment-list-table>
        </div>
    </div>
    <div style="gap: 30px;" class="flex flex-row justify-items-stretch content-start"
        [ngClass]="{'px-20': subEdit != true,'px-2': subEdit == true}">
        <div class="flex flex-col flex-1 grid-col-1 gap-4 justify-between ">
            <!-- Template Management -->
            <mat-card>
                <mat-card-header>
                    <div class="text-center">ข้อมูลผู้ส่งออก | EXPORTER FORM</div>
                </mat-card-header>
                <mat-card-content>
                    <div *ngIf="!isRequestJob">
                        <div class="flex flex-row mt-4 gap-3" *ngIf="editItem != true">
                            <div class=" flex flex-row items-center"
                                [ngClass]="{'flexWidth50': subEdit != true,'flexWidth100': subEdit == true}">
                                <div class="w-1/4 flex flex-col">
                                    <h4 class="">เลือกแม่แบบที่บันทึกไว้</h4>
                                </div>
                                <mat-form-field appearance="fill" class="flex-auto">
                                    <input type="text" matInput [(ngModel)]="form.tmpSearchName" (ngModelChange)="valuechange($event);" [readonly]="valueChange"
                                        name="templateId" #templateId="ngModel" [matAutocomplete]="auto" [disabled]="isDisableFieldTemplate"  [placeholder]="placeholderTemplate"/>
                                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onSelected($event);">
                                      <mat-option *ngFor="let template of templateList" [value]="template.id">
                                        {{template.tempName}}
                                      </mat-option>
                                    </mat-autocomplete>
                                    <button *ngIf="valueChange" matSuffix mat-icon-button aria-label="Clear" (click)="clearSelected()">
                                        <mat-icon>close</mat-icon>
                                    </button>                            
                                  </mat-form-field>
                                <!-- <mat-form-field appearance="fill" class="flex-auto">
                                    <mat-select [placeholder]="placeholderTemplate" [disabled]="isDisableFieldTemplate"
                                        [(ngModel)]="form.templateId" name="templateId" #templateId="ngModel">
                                        <mat-option *ngFor="let template of templateList" [value]="template.id">
                                            {{template.tempName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>  -->
                                <button class="set-btn-unlimited flex-none ml-4" mat-raised-button color="accent"
                                    (click)="loadTemplate()" [disabled]="isDisableFieldTemplate">
                                    Load Template</button>
                            </div>
                        </div>
                        <div class="flex flex-row mt-4 gap-3" *ngIf="editItem != true">
                            <mat-checkbox class="example-margin px-1" [(ngModel)]="form.isCreateTemplate"
                                [ngModelOptions]="{standalone: true}" [disabled]="isDisableFieldTemplate ">
                                ต้องการสร้างแม่แบบ(Template)
                            </mat-checkbox>
                        </div>
                        <div class="flex flex-row mt-4 gap-3" *ngIf="form.isCreateTemplate">
                            <div class="w-1/2 flex flex-row">
                                <div class="w-1/4 flex flex-col">
                                    <h4>ชื่อแม่แบบ <span class="required">*</span></h4>
                                    <h4>Template Name</h4>
                                </div>
                                <div class="flex-auto form-field-container">
                                    <!-- <div class="w-3/4 flex flex-row"> -->
                                    <mat-form-field appearance="fill" class="flex-auto pr-1 w-full">
                                        <input matInput placeholder="ระบุชื่อแม่แบบที่ต้องการบันทึก"
                                            [disabled]="!form.isCreateTemplate || editItem || form.isRqstatus == 10"
                                            [(ngModel)]="form.tempName" [ngModelOptions]="{standalone: true}"
                                            maxlength="50">
                                    </mat-form-field>
                                    <p class="flex-auto text-end" *ngIf="form.tempName?.length !=null">
                                        {{form.tempName?.length+"/50"}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex mt-4 gap-3"
                        [ngClass]="{'flexCheckJobRow': subEdit != true,'flexCheckJobColum': subEdit == true}">
                        <div class="flex flex-row"
                            [ngClass]="{'flexWidth50': subEdit != true,'flexWidth100': subEdit == true}">
                            <div class="w-1/4 flex flex-col">
                                <h4>ชื่อผู้ยื่นคำขอ</h4>
                                <h4>Request By</h4>
                            </div>
                            <mat-form-field class="flex-auto">
                                <input matInput placeholder="ชื่อผู้ยื่นคำขอ" [(ngModel)]="form.requestBy"
                                    [ngModelOptions]="{standalone: true}" [disabled]="true" maxlength="100">
                            </mat-form-field>
                        </div>
                        <div class="flex flex-row"
                            [ngClass]="{'flexWidth50': subEdit != true,'flexWidth100': subEdit == true}">
                            <div class="w-1/4 flex flex-col">
                                <div>
                                    <h4 class="">ผู้ส่งออกสินค้า <span class="required">*</span></h4>
                                </div>
                                <h4 class="">Declarator</h4>
                            </div>

                            <mat-form-field appearance="fill" class="flex-auto" *ngIf="!search && !recheck">
                                <mat-select [placeholder]="placeholderDeclarator" [(ngModel)]="form.declaratorId"
                                    name="declaratorId" #declaratorId="ngModel" [disabled]="isDisableFieldDeclarator"
                                    (selectionChange)="DropdromwChange($event, false)" required>
                                    <mat-option *ngFor="let companyRelation of declaratorListRelation"
                                        [value]="companyRelation.cid"
                                        [disabled]="companyRelation.blacklistStatus == 'Y'">
                                        {{companyRelation.companyNameEn}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="fill" class="flex-auto" *ngIf="search || recheck">
                                <input matInput [(ngModel)]="form.declarator" [ngModelOptions]="{standalone: true}"
                                    [disabled]="true">

                            </mat-form-field>
                        </div>

                    </div>
                    <div class="flex mt-4 gap-3"
                        [ngClass]="{'flexCheckJobRow': subEdit != true,'flexCheckJobColum': subEdit == true}">
                        <div class="flex flex-row"
                            [ngClass]="{'flexWidth50': subEdit != true,'flexWidth100': subEdit == true}">
                            <div class="w-1/4 flex flex-col">
                                <h4 class="">ชื่อผู้ส่งออกสินค้า<span class="required">*</span></h4>
                                <h4 class="">(ปรากฏบนฟอร์ม)</h4>
                                <h4 class="">Consignor Name</h4>
                            </div>
                            <div class="flex-auto form-field-container">
                                <mat-form-field class="flex-auto" style="width: 100%">
                                    <textarea matInput placeholder="ชื่อผู้ส่งออกสินค้า"
                                        [(ngModel)]="form.consignorName" name="consignorName" id="consignorName"
                                        [disabled]="isDisableFieldConsignor" required rows="4" cols="40" maxlength="100"
                                        (ngModelChange)="checkEnglish(form.consignorName) == false ? showErrorMessage.consignorNameEng = true : showErrorMessage.consignorNameEng = false;"></textarea>
                                </mat-form-field>
                                <div class="flex flex-row">
                                    <label *ngIf="showErrorMessage.consignorNameEng == true"
                                        class="required position-absolute">กรอกได้เฉพาะภาษาอังกฤษและสัญลักษณ์</label>
                                    <label
                                        *ngIf="showErrorMessage.consignorName == true && showErrorMessage.consignorNameEng == false"
                                        class="required position-absolute">ไม่สามารถกรอกอักขระพิเศษ</label>
                                    <p class="flex-auto text-end" *ngIf="form.consignorName?.length !=null">
                                        {{form.consignorName?.length+"/100"}}
                                    </p>
                                </div>
                            </div>

                        </div>
                        <div class="flex flex-row"
                            [ngClass]="{'flexWidth50': subEdit != true,'flexWidth100': subEdit == true}">
                            <div class="w-1/4 flex flex-col">
                                <h4 class="">ที่อยู่<span class="required">*</span></h4>
                                <h4 class="">Consignor Address</h4>
                            </div>
                            <div class="flex-auto form-field-container">
                                <mat-form-field class="flex-auto" style="width: 100%">
                                    <textarea matInput placeholder="ที่อยู่" [(ngModel)]="form.consignorAddress"
                                        name="consignorAddress" [disabled]="isDisableFieldConsignor" required rows="4"
                                        cols="40" maxlength="150" 
                                        (ngModelChange)="checkEnglish(form.consignorAddress) == false ? showErrorMessage.consignorAddressEng = true : showErrorMessage.consignorAddressEng = false;"></textarea>
                                </mat-form-field>
                                <div class="flex flex-row">
                                    <label *ngIf="showErrorMessage.consignorAddressEng == true"
                                        class="required position-absolute">กรอกได้เฉพาะภาษาอังกฤษและสัญลักษณ์</label>
                                    <label
                                        *ngIf="showErrorMessage.consignorAddress == true && showErrorMessage.consignorAddressEng == false"
                                        class="required position-absolute">ไม่สามารถกรอกอักขระพิเศษ</label>
                                    <p class="flex-auto text-end" *ngIf="form.consignorAddress?.length != null">
                                        {{ form.consignorAddress?.length + "/150" }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex  mt-4 gap-3"
                        [ngClass]="{'flexCheckJobRow': subEdit != true,'flexCheckJobColum': subEdit == true}">
                        <div class="flex flex-row"
                            [ngClass]="{'flexWidth50': subEdit != true,'flexWidth100': subEdit == true}">
                            <div class="w-1/4 flex flex-col">
                                <h4 class="">วันที่ออกใบรับรอง<span class="required">*</span></h4>
                                <h4 class="">Certificate Date</h4>
                            </div>
                            <mat-radio-group aria-label="Select an option" class="w-3/4 flex flex-col"
                                [(ngModel)]="form.certificateDateRequest" [ngModelOptions]="{standalone: true}">
                                <mat-radio-button value="approve_date" [disabled]=" isDisableFieldCertificate">
                                    <div class="flex flex-col">
                                        <div>ตามวันที่อนุมัติ <span style="color: green;" *ngIf="form.certificateDateRequest=='approve_date'">{{ form.certificateDate | date:'yyyy-MM-dd' }}</span></div>
                                    </div>

                                </mat-radio-button>
                                <mat-radio-button value="first_request" [disabled]="isDisableFieldCertificate">
                                    <div class="flex flex-col">
                                        <div>ตามวันที่ยื่นคำขอครั้งแรก<span style="color: green;" *ngIf="form.certificateDateRequest=='first_request'">{{ form.certificateDate | date:'yyyy-MM-dd' }}</span></div>
                                    </div>
                                </mat-radio-button>
                                <mat-radio-button value="on_date" class="flex-grow"
                                    [disabled]="isDisableFieldCertificate">
                                    <div class="flex flex-col flex-grow">
                                        <div class="flex flex-row gap-3 flex-grow">
                                            <div class="flex flex-col">
                                                <div>วันที่ย้อนหลัง/ล่วงหน้า</div>
                                                <div>(ชำระค่าธรรมเนียมเพิ่มเติม)</div>
                                            </div>
                                            <mat-form-field class="flex flex-col" appearance="fill"
                                                *ngIf="!(form.certificateDateRequest!='on_date')">
                                                <input matInput [matDatepicker]="picker" type="datetime"
                                                    [disabled]="isDisableFieldCertificate"
                                                    [(ngModel)]="form.certificateDate" name="certificateDate"
                                                    #certificateDate="ngModel" (click)="picker.open()" readonly
                                                    class="cursor-pointer">
                                                <mat-hint>MM/DD/YYYY</mat-hint>
                                                <mat-datepicker-toggle matIconSuffix
                                                    [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="flex flex-row"
                            [ngClass]="{'flexWidth50': subEdit != true,'flexWidth100': subEdit == true}">
                            <div class="w-1/4 flex flex-col">
                                <h4 class="">ลายเซ็นสด</h4>
                                <h4 class="">Live Signature</h4>
                            </div>
                            <mat-checkbox class="w-1/4 flexHeight40 example-margin " [(ngModel)]="form.liveSignature"
                                [disabled]="editItem || editJobData" [ngModelOptions]="{standalone: true}">ระบุสาขา
                            </mat-checkbox>
                            <mat-form-field appearance="fill" class="flex-auto"
                                [ngClass]="{'flexHeight40': subEdit != true,'flexHeight100': subEdit == true}"
                                *ngIf="form.liveSignature">
                                <mat-select [placeholder]="placeholderBranch"
                                    [disabled]="!form.liveSignature || editItem || editJobData"
                                    [(ngModel)]="form.sigBranchId" [ngModelOptions]="{standalone: true}">
                                    <mat-option *ngFor="let branch of branchList" [value]="branch.branchId" branchId>
                                        {{branch.branchName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="flex flex-row mt-4 gap-3">
                        <h3>ลายมือชื่อผู้มอบอำนาจ | SIGNATURE</h3>
                    </div>
                    <div class="flex mt-4 gap-3"
                        [ngClass]="{'flexCheckJobRow': subEdit != true,'flexCheckJobColum': subEdit == true}">
                        <div class="flex flex-row"
                            [ngClass]="{'flexWidth50': subEdit != true,'flexWidth100': subEdit == true}">
                            <div class="w-1/4 flex flex-col">
                                <h4 class="">ลายมือชื่อผู้มอบอำนาจ<span class="required">*</span></h4>
                                <h4 class="">SIGNATURE {{form.signAuthorizedId}}</h4> 
                            </div>
                            <mat-form-field appearance="fill" class="flex-auto">
                                <mat-select [placeholder]="placeholderSignature" [(ngModel)]="form.signAuthorizedId"
                                    [disabled]="editItem || editJobData"
                                    name="signAuthorizedId" #signAuthorizedId="ngModel" required>
                                    <mat-option *ngFor="let nameLastName of nameLastNameList  "
                                        [value]="nameLastName.csid">
                                        {{nameLastName.fullNameThai}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                    </div>
                    <div class="flex flex-row mt-4 gap-3" *ngIf="!isRequestJob">
                        <mat-checkbox class="example-margin px-1 pt-4" [(ngModel)]="form.isPackRq"
                            [disabled]="editItem || editJobData || form.isRqstatus == 3 || form.isRqstatus == 4 || form.isRqstatus == 10"
                            [ngModelOptions]="{standalone: true}" (ngModelChange)="isPackChange($event)">
                            ต้องการสร้าง CO PACK
                        </mat-checkbox>
                    </div>
                    <div class="flex flex-row mt-4 gap-3" *ngIf="form.isPackRq">
                        <div class="w-1/2 flex flex-row">
                            <div class="w-1/4 flex flex-col">
                                <h4 class="">จำนวนชุดที่ต้องการ<span class="required">*</span></h4>
                                <h4 class="">Amount Request</h4>
                            </div>
                            <mat-form-field class="flex-auto">
                                <input matInput placeholder="ระบุจำนวนชุดที่ต้องการ" type="number" [min]="1" [max]="15"
                                    (change)="amountRequestChanged($event)" [(ngModel)]="form.coTotalPage"
                                    [ngModelOptions]="{standalone: true}"
                                    [disabled]="!form.isPackRq || editItem || editJobData || form.isRqstatus == 10 || form.isRqstatus == 3 || form.isRqstatus == 4"
                                    onkeyup="this.value=this.value.replace(/[^0-9]/g,'');">
                            </mat-form-field>
                        </div>
                        <div class="w-1/2 flex flex-row">

                            <div class="w-1/4 flex flex-col">
                                <h4>ชื่อชุดเอกสาร<span class="required">*</span></h4>
                                <h4>Pack Name</h4>
                            </div>
                            <div class="flex-auto form-field-container">
                                <mat-form-field class="flex-auto w-full">
                                    <input matInput placeholder="ระบุชื่อชุดเอกสาร" [(ngModel)]="form.packName"
                                        [ngModelOptions]="{standalone: true}"
                                        [disabled]="!form.isPackRq || editItem || editJobData || form.isRqstatus == 10 || form.isRqstatus == 3 || form.isRqstatus == 4"
                                        maxlength="50">
                                </mat-form-field>
                                <p class="flex-auto text-end" *ngIf="form.packName?.length != null">
                                    {{ form.packName?.length + "/50" }}
                                </p>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
            <app-create-co-memo *ngIf="userProfile.gId == '4' || userProfile.gId == '5' || userProfile.gId == '1'"
                [memotext]="form.memo"></app-create-co-memo>
        </div>

    </div>








    <!-- 
    <div class="flex flex-row justify-center mb-3" *ngIf="!subEdit">
        <button class="set-btn-unlimited flex-none " mat-raised-button color="accent"
            (click)="isCreateForm = !isCreateForm">Create Form</button>
    </div> -->
    <div>
        <div style="gap: 30px;" class="flex flex-row justify-items-stretch content-start"
            [ngClass]="{'px-20': subEdit != true,'px-2': subEdit == true}">
            <div class="flex flex-col flex-1 grid-col-1 gap-4 justify-between">
                <!-- Template Management -->
                <mat-card class="flex flex-col flex-1">
                    <mat-card-header>
                        <div class="text-center">ข้อมูลผู้รับสินค้าปลายทาง | CONSIGNEE FORM </div>
                    </mat-card-header>
                    <mat-card-content>
                        <section class="px-4 flex flex-col flex-1 py-4 gap-4">
                            <div class="flex gap-4 "
                                [ngClass]="{'flex-row': subEdit != true,'flex-col': subEdit == true}">
                                <div class="flex-1 flex flex-col">
                                    <h4 class="">ผู้รับสินค้าปลายทาง<span class="required">*</span></h4>
                                    <h4 class="">Consignee</h4>
                                </div>
                                <div class="flex-auto form-field-container">
                                    <mat-form-field class="flex-auto w-full">
                                        <textarea matInput placeholder="ผู้รับสินค้าปลายทาง"
                                            [(ngModel)]="form.consigneeName" [disabled]="isDisableFieldConsignee"
                                            [ngModelOptions]="{standalone: true}" required="true" rows="4" cols="40"
                                            maxlength="185" 
                                            (ngModelChange)="checkEnglish(form.consigneeName) == false ? showErrorMessage.consigneeNameEng = true : showErrorMessage.consigneeNameEng = false;">
                                        </textarea>
                                    </mat-form-field>
                                    <div class="flex flex-row">
                                        <label *ngIf="showErrorMessage.consigneeNameEng == true"
                                            class="required position-absolute">กรอกได้เฉพาะภาษาอังกฤษและสัญลักษณ์</label>
                                        <label
                                            *ngIf="showErrorMessage.consigneeName == true && showErrorMessage.consigneeNameEng == false"
                                            class="required position-absolute">ไม่สามารถกรอกอักขระพิเศษ</label>

                                        <p class="flex-auto text-end" *ngIf="form.consigneeName?.length !=null">
                                            {{form.consigneeName?.length+"/185"}}
                                        </p>
                                    </div>

                                </div>
                                <div class="flex-1 flex flex-col">
                                    <h4 class="">ผู้ที่จะได้รับแจ้งเมื่อสินค้าไปถึง</h4>
                                    <h4 class="">Notify</h4>
                                </div>
                                <div class="flex-auto form-field-container">
                                    <mat-form-field class="flex-auto w-full">
                                        <textarea matInput placeholder="ผู้ที่จะได้รับแจ้งเมื่อสินค้าไปถึง"
                                            [(ngModel)]="form.consigneeNotify" [disabled]="isDisableFieldNotify"
                                            [ngModelOptions]="{standalone: true}" rows="4" cols="40" maxlength="380"
                                            (ngModelChange)="checkEnglish(form.consigneeNotify) == false ? showErrorMessage.consigneeNotifyEng = true : showErrorMessage.consigneeNotifyEng = false;">
                                    </textarea>
                                    </mat-form-field>
                                    <div class="flex flex-row">
                                        <label *ngIf="showErrorMessage.consigneeNotifyEng == true"
                                            class="required position-absolute">กรอกได้เฉพาะภาษาอังกฤษและสัญลักษณ์</label>
                                        <label
                                            *ngIf="showErrorMessage.consigneeNotify == true && showErrorMessage.consigneeNotifyEng == false"
                                            class="required position-absolute">ไม่สามารถกรอกอักขระพิเศษ</label>
                                        <p class="flex-auto text-end" *ngIf="form.consigneeNotify?.length !=null">
                                            {{form.consigneeNotify?.length+"/380"}}
                                        </p>
                                    </div>

                                </div>
                            </div>
                            <div class="flex gap-4 "
                                [ngClass]="{'flex-row': subEdit != true,'flex-col': subEdit == true}">
                                <div class="flex-1 flex flex-col ">
                                    <h4 class="">ชื่อพาหนะ<span class="required">*</span></h4>
                                    <h4 class="">Vessel/Flight No.</h4>
                                </div>
                                <mat-form-field appearance="fill" class="flex-auto">
                                    <mat-select [placeholder]="placeholderVessel" [(ngModel)]="form.vesselType"
                                        [ngModelOptions]="{standalone: true}" [disabled]="isDisableFieldVesselAndFlight"
                                        required="true">
                                        <mat-option *ngFor="let vesselType of vesselTypeList" [value]="vesselType.name">
                                            {{vesselType.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="flex-1 flex flex-col">
                                    <h4 class="">วันที่ขนสินค้าขึ้นพาหนะ<span class="required">*</span></h4>
                                    <h4 class="">Loading on About/</h4>
                                    <h4 class="">Flight Date</h4>
                                </div>
                                <mat-form-field appearance="fill" class="flex-auto"
                                    [ngClass]="{'w-30': subEdit != true,'w-60': subEdit == true}">
                                    <input matInput [matDatepicker]="pick" [(ngModel)]="form.loadingDate"
                                        [ngModelOptions]="{standalone: true}" readonly (click)="pick.open()"
                                        [disabled]="isDisableFieldLoadingOnAboutFlightDate" required="true"
                                        class="cursor-pointer">
                                    <mat-datepicker-toggle matIconSuffix [for]="pick"></mat-datepicker-toggle>
                                    <mat-datepicker #pick></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="flex gap-4 "
                                [ngClass]="{'flex-row': subEdit != true,'flex-col': subEdit == true}">
                                <div class="flex-1 flex flex-col">
                                </div>
                                <div class="flex-auto form-field-container">
                                    <mat-form-field class="flex-auto w-full">
                                        <textarea matInput placeholder="ชื่อพาหนะ" [(ngModel)]="form.vesselNo"
                                            [ngModelOptions]="{standalone: true}"
                                            [disabled]="isDisableFieldVesselAndFlight" required="true" maxlength="50"
                                            rows="4"
                                            cols="40"
                                            (ngModelChange)="checkEnglish(form.vesselNo) == false ? showErrorMessage.vesselNoEng = true : showErrorMessage.vesselNoEng = false;">
                                        </textarea>
                                    </mat-form-field>
                                    <div class="flex flex-row">
                                        <label *ngIf="showErrorMessage.vesselNoEng == true"
                                            class="required position-absolute">กรอกได้เฉพาะภาษาอังกฤษและสัญลักษณ์</label>
                                        <label
                                            *ngIf="showErrorMessage.vesselNo == true && showErrorMessage.vesselNoEng == false"
                                            class="required position-absolute">ไม่สามารถกรอกอักขระพิเศษ</label>
                                        <p class="flex-auto text-end" *ngIf="form.vesselNo?.length !=null">
                                            {{form.vesselNo?.length+"/50"}}
                                        </p>
                                    </div>

                                </div>
                                <div class="flex-1 flex flex-col">
                                    <h4 class="">ปลายทางสินค้าที่ถูกยกลง<span class="required">*</span></h4>
                                    <h4 class="">Destination</h4>
                                </div>
                                <div class="flex-auto form-field-container">
                                    <mat-form-field class="flex-auto w-full">
                                        <input matInput placeholder="ปลายทางที่สินค้าถูกยกลง "
                                            [(ngModel)]="form.destination" [disabled]="isDisableFieldDestination"
                                            [ngModelOptions]="{standalone: true}" required="true" maxlength="100"
                                            (ngModelChange)="checkEnglish(form.destination) == false ? showErrorMessage.destinationEng = true : showErrorMessage.destinationEng = false;">
                                    </mat-form-field>
                                    <div class="flex flex-row">
                                        <label *ngIf="showErrorMessage.destinationEng == true"
                                            class="required position-absolute">กรอกได้เฉพาะภาษาอังกฤษและสัญลักษณ์</label>
                                        <label
                                            *ngIf="showErrorMessage.destination == true && showErrorMessage.destinationEng == false"
                                            class="required position-absolute">ไม่สามารถกรอกอักขระพิเศษ</label>
                                        <p class="flex-auto text-end" *ngIf="form.destination?.length !=null">
                                            {{form.destination?.length+"/100"}}
                                        </p>
                                    </div>

                                </div>
                            </div>
                            <div class="flex gap-4 "
                                [ngClass]="{'flex-row': subEdit != true,'flex-col': subEdit == true}">
                                <div class="flex-1 flex flex-col">
                                    <h4 class="">ประเทศปลายทาง<span class="required">*</span></h4>
                                    <h4 class="">(ไม่ปรากฎบนฟอร์ม)</h4>
                                    <h4 class="">Destination Country</h4>
                                </div>
                                <mat-form-field appearance="fill" class="flex-auto">
                                    <mat-select [placeholder]="placeholderDestination"
                                        [(ngModel)]="form.destinationCountry" [disabled]="isDisableFieldDestination"
                                        [ngModelOptions]="{standalone: true}" required="true">
                                        <mat-option *ngFor="let destination of destinationList  "
                                            [value]="destination.mC_COUNTRY_NAME">
                                            <!-- <[value]="destination.mC_ID"> -->

                                            {{destination.mC_COUNTRY_NAME}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="w-3/6" *ngIf="form.isPackRq"></div>
                                <div class="flex-1 flex flex-col" *ngIf="!form.isPackRq">
                                    <h4>ประเทศแหล่งกำเนิดสินค้า<span class="required">*</span></h4>
                                    <h4>Country Of Origin</h4>
                                </div>
                                <mat-form-field class="flex-auto" *ngIf="!form.isPackRq">
                                    <mat-select [placeholder]="placeholderOrigin" [(ngModel)]="form.countryOfOrigin"
                                        [ngModelOptions]="{standalone: true}"
                                        [disabled]="isDisableFieldCountryOfOrigin">
                                        <mat-option *ngFor="let destination of destinationList  "
                                            [value]="destination.mC_COUNTRY_NAME">
                                            {{destination.mC_COUNTRY_NAME}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div *ngIf="subEdit != true">
                                <div class="flex gap-4 " *ngIf="!form.isPackRq"
                                    [ngClass]="{'flex-row': subEdit != true,'flex-col': subEdit == true}">
                                    <div class="flex-1 flex flex-col">
                                        <h4>เลขใบกำกับสินค้า/วันที่<span class="required">*</span></h4>
                                        <h4>Invoice No./Date</h4>
                                    </div>
                                    <!-- <div class="w-1/4" *ngIf="!form.isPackRq"> -->
                                    <mat-checkbox class="flex-auto ml-5 self-center" [(ngModel)]="form.invoiceChk"
                                        [ngModelOptions]="{standalone: true}" [disabled]="editItem || editJobData"
                                        [class.hidden]="(isDisableFieldInvoiceNoDate)">
                                        ไม่ปรากฎบนฟอร์ม</mat-checkbox>
                                    <!-- </div> -->
                                    <div class="w-3/6 justify-end self-center text-end">
                                        <!-- <div class="flex flex-row justify-end my-3"> -->
                                        <dx-button class="justify-end w-12 h-12 text-4xl" icon="plus"
                                            (onClick)="addInvoice()"
                                            [disabled]="editItem || editJobData || (isDisableFieldInvoiceNoDate)">
                                        </dx-button>
                                        <!-- </div> -->
                                    </div>
                                </div>
                                <div class="flex gap-4 pt-2" *ngIf="!form.isPackRq"
                                    [ngClass]="{'flex-row': subEdit != true,'flex-col': subEdit == true}">
                                    <div class="w-2/12">
                                    </div>
                                    <div class="w-10/12 ml-20">
                                        <dx-data-grid #gridContainer [dataSource]="dataSourceInvoice"
                                            [allowColumnReordering]="true" [showBorders]="true"
                                            (onInitNewRow)="onInitNewRow($event)">
                                            <dxi-column dataField="invoiceId" [visible]="false">
                                            </dxi-column>
                                            <dxi-column dataField="docHid" [visible]="false">
                                            </dxi-column>
                                            <dxi-column dataField="invoiceNum" [visible]="false">
                                            </dxi-column>
                                            <dxi-column dataField="invoiceNo" caption="เลขที่ใบกำกับสินค้า">
                                            </dxi-column>
                                            <dxi-column dataField="invoiceDate" caption="วันที่ใบกำกับสินค้า"
                                                [format]="'yyyy-MM-dd'" dataType="date">
                                            </dxi-column>
                                            <dxi-column cellTemplate="action" [width]="100">
                                            </dxi-column>
                                            <div *dxTemplate="let row of 'action'" style="font-size:9px;">
                                                <button (click)="editInvoice(row)" *ngIf="!editItem"
                                                    [class.hidden]="isDisableFieldInvoiceNoDate">
                                                    <mat-icon svgIcon="heroicons_outline:pencil-alt"></mat-icon>
                                                </button>
                                                <button (click)="deleteInvoice(row)" *ngIf="!editItem"
                                                    [class.hidden]="isDisableFieldInvoiceNoDate"
                                                    (keydown.enter)="$event.preventDefault()">
                                                    <mat-icon svgIcon="heroicons_outline:trash"></mat-icon>
                                                </button>
                                            </div>
                                        </dx-data-grid>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="subEdit == true">
                                <div class="flex gap-4 " *ngIf="!form.isPackRq"
                                    [ngClass]="{'flex-row': subEdit != true,'flex-col': subEdit == true}">
                                    <div class="flex flex-row">
                                        <div class="flex-none flex flex-col">
                                            <h4 class="">เลขใบกำกับสินค้า/วันที่<span class="required">*</span></h4>
                                            <h4 class="">Invoice No./Date</h4>
                                        </div>
                                        <div>
                                            <mat-checkbox class="example-margin px-1" [(ngModel)]="form.invoiceChk"
                                                [ngModelOptions]="{standalone: true}"
                                                [disabled]="editItem || editJobData"
                                                [class.hidden]="(isDisableFieldInvoiceNoDate)">
                                                ไม่ปรากฎบนฟอร์ม
                                            </mat-checkbox>
                                        </div>
                                    </div>
                                    <div class="flex-auto">
                                        <div class="flex flex-row justify-end my-3">
                                            <dx-button class="justify-end w-12 h-12 text-4xl" icon="plus"
                                                (onClick)="addInvoice()"
                                                [disabled]="editItem || editJobData || isDisableFieldInvoiceNoDate">
                                            </dx-button>
                                        </div>
                                        <dx-data-grid #gridContainer [dataSource]="dataSourceInvoice"
                                            [allowColumnReordering]="true" [showBorders]="true"
                                            (onInitNewRow)="onInitNewRow($event)">
                                            <dxi-column dataField="invoiceId" [visible]="false">
                                            </dxi-column>
                                            <dxi-column dataField="docHid" [visible]="false">
                                            </dxi-column>
                                            <dxi-column dataField="invoiceNum" [visible]="false">
                                            </dxi-column>
                                            <dxi-column dataField="invoiceNo" caption="เลขที่ใบกำกับสินค้า">
                                            </dxi-column>
                                            <dxi-column dataField="invoiceDate" caption="วันที่ใบกำกับสินค้า"
                                                [format]="'yyyy-MM-dd'" dataType="date">
                                            </dxi-column>
                                            <dxi-column cellTemplate="action" [width]="100">
                                            </dxi-column>
                                            <div *dxTemplate="let row of 'action'" style="font-size:9px;">
                                                <button (click)="editInvoice(row)" *ngIf="!editItem"
                                                    [class.hidden]="(isDisableFieldInvoiceNoDate)">
                                                    <mat-icon svgIcon="heroicons_outline:pencil-alt"></mat-icon>
                                                </button>
                                                <button (click)="deleteInvoice(row)" *ngIf="!editItem"
                                                    [class.hidden]="(isDisableFieldInvoiceNoDate)"
                                                    (keydown.enter)="$event.preventDefault()">
                                                    <mat-icon svgIcon="heroicons_outline:trash"></mat-icon>
                                                </button>
                                            </div>
                                        </dx-data-grid>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </mat-card-content>

                </mat-card>
                <!-- CO PACK -->
                <mat-card *ngIf="!form.isPackRq">
                    <mat-card-header>
                        <div class="text-center">รายการสินค้า | GOOD LIST FORM </div>
                    </mat-card-header>
                    <mat-card-content>
                        <section class="px-4 flex flex-col flex-1 pb-4  justify-evenly">
                            <div class="flex flex-row justify-end my-3">
                                <dx-button class="justify-end w-12 h-12" icon="plus" (onClick)="addGoods()"
                                    [disabled]="editItem || editJobData || isDisableFieldItemDescription">
                                </dx-button>
                            </div>

                            <div class="flex flex-col flex-1" style="overflow-x:scroll;">
                                <dx-data-grid [dataSource]="dataSourceGoodItem" [allowColumnReordering]="true"
                                    [allowColumnResizing]="true"
                                    [columnAutoWidth]="false"
                                    [showBorders]="true">
                                    <dxo-paging [enabled]="false"> </dxo-paging>
                                    <dxo-scrolling [useNative]="true" mode="standard" [scrollByContent]="true" [scrollByThumb]="true"
                                    showScrollbar="onHover"></dxo-scrolling>                        
                                    <dxi-column dataField="no" caption="ลำดับ/No." [width]="80" cellTemplate="index"
                                        alignment="center">
                                    </dxi-column>
                                    <div *dxTemplate="let row of 'index'">
                                        {{ (row.rowIndex+1) }}
                                    </div>
                                    <dxi-column dataField="markItem" caption="เครื่องหมายหีบห่อ/Mark"></dxi-column>
                                    <dxi-column dataField="description" caption="ชื่อสินค้า/Description"></dxi-column>
                                    <dxi-column dataField="quantity" caption="ปริมาณ/Quantity" cellTemplate="quantity"
                                        alignment="center"></dxi-column>
                                    <div *dxTemplate="let row of 'quantity'">
                                        {{row.data?.quantity | number}} {{row.data?.quantityUnit}}
                                    </div>
                                    <dxi-column dataField="weight" caption="น้ำหนัก/Weight" cellTemplate="weight"
                                        alignment="center"><dxo-format type="fixedPoint"
                                            [precision]="3"></dxo-format> </dxi-column>
                                    <div *dxTemplate="let row of 'weight'">
                                        {{row.data?.weight | number:'1.3-3'}} {{row.data?.weightUnit}}
                                    </div>
                                    <dxi-column cellTemplate="action" [width]="80">
                                    </dxi-column>
                                    <div *dxTemplate="let row of 'action'">
                                        <button (click)="editGood(row)" *ngIf="!editJobData"
                                            [class.hidden]="isDisableFieldItemDescription || editItem">
                                            <mat-icon svgIcon="heroicons_outline:pencil-alt"></mat-icon>
                                        </button>
                                        <button (click)="deleteGood(row)" *ngIf="!editJobData"
                                            [class.hidden]="isDisableFieldItemDescription || editItem"
                                            (keydown.enter)="$event.preventDefault()">
                                            <mat-icon svgIcon="heroicons_outline:trash"></mat-icon>
                                        </button>
                                        <button (click)="previewGood(row)"
                                            *ngIf="editItem || isDisableFieldItemDescription">
                                            <mat-icon svgIcon="heroicons_outline:document-search"></mat-icon>
                                        </button>
                                    </div>
                                </dx-data-grid>
                            </div>
                            <mat-divider class="mt-3"></mat-divider>
                            <mat-label>รายละเอียดเพิ่มเติมท้ายรายการสินค้า</mat-label>
                            <div class="flex-auto form-field-container">
                                <mat-form-field class="flex-auto w-full">
                                    <textarea matInput [(ngModel)]="form.remark" name="remark" #remark="ngModel"
                                        [disabled]="editItem || editJobData ||isDisableFieldFooterDescription" rows="4"
                                        cols="40" maxlength="512"
                                        (ngModelChange)="checkEnglish(form.remark) == false ? showErrorMessage.remarkNoEng = true : showErrorMessage.remarkNoEng = false;"></textarea>
                                </mat-form-field>
                                <div class="flex flex-row">
                                    <label *ngIf="showErrorMessage.remarkNoEng == true"
                                        class="required position-absolute">กรอกได้เฉพาะภาษาอังกฤษและสัญลักษณ์</label>
                                    <label
                                        *ngIf="showErrorMessage.remarkNoSymbol == true && showErrorMessage.remarkNoEng == false"
                                        class="required position-absolute">ไม่สามารถกรอกอักขระพิเศษ</label>
                                    <p class="flex-auto text-end" *ngIf="form.remark?.length !=null">
                                        {{form.remark?.length+"/512"}}
                                    </p>
                                </div>
                            </div>
                        </section>
                    </mat-card-content>
                </mat-card>
                <mat-card *ngIf="form.isPackRq">
                    <mat-card-header>
                        <div class="text-center">รายการใบ CO | LIST CO</div>
                    </mat-card-header>

                    <mat-card-content>
                        <section class="px-4 flex flex-col flex-1 pb-4  justify-evenly">
                            <div class="flex flex-row justify-end my-3" *ngIf="!editJobData"
                                [class.hidden]="form.isRqstatus == 10 || editItem ">
                                <dx-button class="justify-end w-12 h-12" icon="plus" (onClick)="addNewRow()">
                                </dx-button>
                            </div>

                            <dx-data-grid #gridContainer [dataSource]="dataSourcecoPAck" [allowColumnReordering]="false"
                                [showBorders]="true" (onInitNewRow)="onInitNewRow($event)"
                                (onSelectionChanged)="selectionChanged($event)" (onContentReady)="contentReady($event)">
                                <dxo-selection mode="single"></dxo-selection>
                                <dxo-master-detail [enabled]="false" template="detail"></dxo-master-detail>

                                <dxi-column dataField="key" caption="ลำดับ/No." [width]="100" alignment="center"
                                    [allowSorting]="false">
                                </dxi-column>

                                <dxi-column dataField="invoiceNo" caption="Invoice No" [allowSorting]="false">
                                    <dxi-validation-rule type="required"></dxi-validation-rule>
                                </dxi-column>
                                <dxi-column dataField="invoiceDate" caption="Invoice Date" dataType="date"
                                    [format]="'yyyy-MM-dd'" [allowSorting]="false">
                                    <dxi-validation-rule type="required"></dxi-validation-rule>
                                </dxi-column>
                                <dxi-column cellTemplate="action" [width]="100">
                                </dxi-column>
                                <div *dxTemplate="let row of 'action'; let i = index" class="buttonbox">
                                    <button (click)="addCOPack(row)" *ngIf="!editJobData"
                                        [class.hidden]="(isDisableFieldItemDescription && isDisableFieldInvoiceNoDate && isDisableFieldCountryOfOrigin) || editItem">
                                        <mat-icon svgIcon="heroicons_outline:pencil-alt"></mat-icon>
                                    </button>
                                    <button (click)="deleteCOPack(row)" *ngIf="!editJobData"
                                        [class.hidden]="(isDisableFieldItemDescription && isDisableFieldInvoiceNoDate && isDisableFieldCountryOfOrigin) || editItem"
                                        (keydown.enter)="$event.preventDefault()">
                                        <mat-icon svgIcon="heroicons_outline:trash"></mat-icon>
                                    </button>
                                    <button (click)="addCOPack(row)" *ngIf="editItem">
                                        <mat-icon svgIcon="heroicons_outline:document-search"></mat-icon>
                                    </button>
                                </div>

                                <div *dxTemplate="let item of 'detail'">
                                    <div class="flex" style="gap: 10px;">
                                        <div style="width: 50%">
                                            <h4 style="font-size: 1.2em; font-weight: bold; margin-bottom: 5px;">
                                                รายการสินค้า</h4>
                                            <div style="text-align:center;"
                                                *ngIf="item.data.items?.length === 0 || item.data.items === undefined">
                                                <p>ไม่พบข้อมูล</p>
                                            </div>
                                            <div class="flex flex-col" style="gap: 10px;">
                                                <div *ngFor="let i of item.data.items; let ind = index">
                                                    <div class="flex flex-col">
                                                        <div class="flex flex-row">
                                                            <div style="margin-right: 6px;">
                                                                <p>{{ind + 1}})</p>
                                                            </div>
                                                            <div class="flex flex-col"
                                                                style="width: 100%; white-space: pre-line;">
                                                                <div>
                                                                    <p style="font-weight: bold;">พิกัดศุลกากร</p>
                                                                </div>
                                                                <div style="margin-bottom: 5px;">
                                                                    <p>{{ i.harmonizedCode }}</p>
                                                                </div>
                                                                <div>
                                                                    <p style="font-weight: bold;">เครื่องหมายหีบห่อ</p>
                                                                </div>
                                                                <div style="margin-bottom: 5px;">
                                                                    <p>{{i.markItem}}</p>
                                                                </div>
                                                                <div>
                                                                    <p style="font-weight: bold;">ชื่อสินค้า</p>
                                                                </div>
                                                                <div style="margin-bottom: 5px;">
                                                                    <p>{{i.description}}</p>
                                                                </div>
                                                                <div>
                                                                    <p style="font-weight: bold;">ปริมาณ</p>
                                                                </div>
                                                                <div style="margin-bottom: 5px;">
                                                                    <p>{{i?.quantity | number}} {{i?.quantityUnit}}</p>
                                                                </div>
                                                                <div>
                                                                    <p style="font-weight: bold;">น้ำหนัก</p>
                                                                </div>
                                                                <div>
                                                                    <p>{{i?.weight | number:'1.3-3'}} {{i?.weightUnit}}
                                                                    </p>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="width: 50%">
                                            <h4 style="font-size: 1.2em; font-weight: bold; margin-bottom: 5px;">รายการ
                                                Invoice</h4>
                                            <div style="text-align:center;"
                                                *ngIf="item.data.invoice?.length === 0 || item.data.invoice === undefined">
                                                <p>ไม่พบข้อมูล</p>
                                            </div>
                                            <div *ngFor="let i of item.data.invoice; let ind = index">
                                                <p class="employeeNotes">{{ind + 1}}) {{ i.invoiceNo }} :
                                                    {{i.invoiceDate}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </dx-data-grid>
                        </section>
                    </mat-card-content>
                </mat-card>
                <!-- SIGNATURE -->
                <mat-card>
                    <mat-card-header>
                        <div class="text-center">เอกสารประกอบคำขอ | ATTACHMENT </div>
                    </mat-card-header>
                    <mat-card-content>
                        <section class="px-4 flex flex-col flex-1 py-4  justify-evenly">
                            <mat-label *ngIf="!editJobData" [class.hidden]="editItem" style="color : red;">
                                รายละเอียดของไฟล์ภาพควรมีขนาดไม่เกิน 3 MB / รูปแบบไฟล์ที่อนุญาต .jpg, .jpeg, .png
                            </mat-label>
                            <section class="flex flex-row gap-4 ">
                                <div class="file-uploader-block" *ngIf="!editJobData" [class.hidden]="editItem">
                                    <dx-file-uploader style="border: none;padding: 0;" [allowCanceling]="true"
                                        [(value)]="value" #fileUploader [multiple]="true" [uploadMode]="'useForm'"
                                        accept="image/jpeg, image/png ,image/jpg"
                                        [allowedFileExtensions]="['.jpg', '.jpeg', '.png']">
                                    </dx-file-uploader>
                                </div>
                            </section>
                            <div class="flex flex-row gap-4 mb-3 ml-3" *ngIf="!editJobData" [class.hidden]="editItem">
                                <dx-button text="Upload" (onClick)="upload()">
                                </dx-button>
                            </div>

                            <div class="flex-auto" style="margin-top:12px;">
                                <app-upload-document-datagrid [disabled]="editJobData || editItem"
                                    [uploadPhotoData]="dataSourceUpload" [uploadPhotoForm]="formSourceUpload"
                                    [documentType]="'CO'" (dataSourceUpdate)="onDataSourceUpdate($event)"
                                    (formSourceUpdate)="onFormSourceUpdate($event)">
                                </app-upload-document-datagrid>
                            </div>
                        </section>
                    </mat-card-content>
                </mat-card>

                <mat-card *ngIf="logPrint.length != 0">
                    <app-log-print-history-component [logPrintHistory]="logPrint"
                        [docType]="form.docType"></app-log-print-history-component>
                </mat-card>
            </div>
        </div>

        <div class="flex flex-row justify-center my-3 gap-4" *ngIf="!subEdit && !staff">
            <button class="set-btn-unlimited flex-none " mat-raised-button color="accent" (click)="save()"
                *ngIf="!editItem" [disabled]="clicked">บันทึก</button>
            <button class="set-btn-unlimited flex-none " mat-raised-button color="accent" (click)="saveQ()"
                *ngIf="!editItem" [disabled]="clicked">บันทึกและส่งตรวจ</button>
            <button class="set-btn-unlimited flex-none " mat-raised-button color="accent"
                (click)="backClicked()">ย้อนกลับ</button>
        </div>
    </div>
</div>