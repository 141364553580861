import { IResNotifyList } from '../notifications.types';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-notification-details',
  templateUrl: './notification-details.component.html',
  styleUrls: ['./notification-details.component.css']
})
export class NotificationDetailsComponent implements OnInit {
  notifyData: IResNotifyList

  constructor(
    @Inject(MAT_DIALOG_DATA) data: IResNotifyList
  ) {
    this.notifyData = data;
  }

  ngOnInit() {
  }

  convertDate(dateStr: any) {
    var date = new Date(dateStr);
    var thaiDate = date.toLocaleDateString('th-TH', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });

    return thaiDate;
  }
}
