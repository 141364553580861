<mat-card (keydown.enter)="$event.preventDefault()">
    <mat-card-header>
        <div class="flex justify-between" style="width: 100%;">
            <div class="text-lg"> รายละเอียดคำขอตรวจงานซ้ำ</div>
            <div>
                <button class="close-button" [mat-dialog-close]="true">
                    <mat-icon class="text-white" [mat-dialog-close] svgIcon="heroicons_outline:x"></mat-icon>
                </button>
            </div>
        </div>
    </mat-card-header>

    <mat-dialog-content class="mat-typography">
        <div class="flex flex-col">
            <div class="flex flex-row px-10 pt-6">
                <div class="basis-1/4">
                    <mat-label>เลขที่คำขอตรวจซ้ำ</mat-label>
                </div>
                <div class="basis-3/4">
                    <mat-label class="pr-2.5"> : </mat-label>{{form.rqNo?form.rqNo:""}}
                </div>
            </div>
            <div class="flex flex-row px-10 pt-6">
                <div class="basis-1/4">
                    <mat-label>ชื่อบริษัท</mat-label>
                </div>
                <div class="basis-3/4">
                    <mat-label class="pr-2.5"> : </mat-label>{{form.compName?form.compName:""}}

                </div>
            </div>
            <div class="flex flex-row px-10 pt-6">
                <div class="basis-1/4">
                    <mat-label>เหตุผลของการขอตรวจซ้ำ</mat-label>
                </div>
                <div class="basis-3/4">
                    <mat-label class="pr-2.5"> : </mat-label>{{form.reason?form.reason:""}}
                </div>
            </div>
            <div class="flex flex-row px-10 pt-4">
                <div class="basis-1/4 self-center">
                    <mat-label>สาเหตุที่ผิดพลาด</mat-label>
                </div>
                <div class="basis-3/4">
                    <mat-label> : </mat-label>
                    <mat-radio-group [(ngModel)]="form.forRecheck" [disabled]="editable==false">
                        <mat-radio-button value="C">ลูกค้า</mat-radio-button>
                        <mat-radio-button value="V">เจ้าหน้าที่</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div class="flex flex-row px-10 pt-4">
                <div class="basis-1/4 self-center">
                    <mat-label>ผลการอนุมัติ</mat-label>
                </div>
                <div class="basis-3/4">
                    <mat-label> : </mat-label>
                    <mat-radio-group [(ngModel)]="form.approveType" [disabled]="editable==false">
                        <mat-radio-button value="Y">อนุมัติ</mat-radio-button>
                        <mat-radio-button value="N">ไม่อนุมัติ</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="center" class="pb-5">
        <button mat-raised-button color="accent" [mat-dialog-close]>
            กลับ
        </button>
        <button mat-raised-button color="accent" *ngIf="editable == true" (click)="save()" [disabled]="form.approveType == 'W'">
            บันทึกผล
        </button>
    </mat-dialog-actions>

</mat-card>