<div style="display: flex;flex-flow: row;gap: 30px;height: 5vh;justify-content: space-between;align-items: center;color: white;
    background-color: #003f80;font-size: 20px;border-radius: 6px;" class=" pl-5 pr-5">
    <h2 style="text-align: center;">Add Invoice</h2>
    <mat-icon style="cursor:pointer; color: white;" mat-dialog-close (click)="cancel()">cancel</mat-icon>
</div>

<mat-dialog-content class="mat-typography">
    <!-- <div style="display: flex;flex-flow: column;justify-content: center ;height: 10vh;background-color: lightgray;border-radius: 6px;"
        class="mb-3 pl-5">
        <h2>เลขใบกำกับสินค้า / วันที่</h2>
        <h2>Invoice No./ Date</h2>
    </div> -->
    <div style="display: flex;flex-flow: column;gap: 15px;" class="pl-5 pr-5 mb-3 justify-start">
        <div class=" flex flex-col gap-4">
            <div class="flex-auto flex flex-row gap-3 pr-20">
                <div class="w-2/6 flex flex-col">
                    <h4>เลขที่ใบกำกับสินค้า <span class="required">*</span></h4>
                    <h4>Invoice No</h4>
                </div>
                <div class="flex-auto flex flex-row">
                    <mat-form-field class="flex-auto">
                        <input autocomplete="off" type="text" matInput [(ngModel)]="form.invoiceNo" name="invoiceNo"
                            #invoiceNo="ngModel" maxlength="30">
                            <p *ngIf="form.invoiceNo?.length !=null">
                                {{form.invoiceNo?.length+"/30"}}
                            </p>
                    </mat-form-field>
                </div>
            </div>
            <div class="flex-auto flex flex-row gap-3 pr-20">
                <div class="w-2/6 flex flex-col">
                    <h4>วันที่ <span class="required">*</span></h4>
                    <h4>Date</h4>
                </div>
                <div class="flex-auto flex flex-row">
                    <mat-form-field appearance="fill" class="flex-auto">
                        <input matInput [matDatepicker]="picker" type="datetime" [disabled]="editItem"
                            [(ngModel)]="form.invoiceDate" name="invoiceDate" #invoiceDate="ngModel"
                            (click)="picker.open()"
                            readonly 
                            class="cursor-pointer">
                        <mat-hint>yyyy-MM-dd</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>

</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-raised-button color="accent" (click)="save()">บันทึก</button>
    <button mat-raised-button color="accent" mat-dialog-close (click)="cancel()">ยกเลิก</button>

</mat-dialog-actions>