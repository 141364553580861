import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { ActivatedRoute, Router } from '@angular/router'
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'
import { ConfirmDialogService } from '@bundle/component/confirm-dialog/confirm-dialog.service'
import { Location } from '@angular/common'
import { AppMasterService } from 'app/business/service/app/app-master.service'
import { Buffer } from 'buffer'
import { CoErrorMarkImagePopupComponent } from '../../create-co-inquiry/co-error-mark-image-popup/co-error-mark-image-popup.component'
import { AppVerifyService } from 'app/business/service/app/app-verify.service'
import { ImageModalComponent } from '../../create-co-inquiry/Image-Modal/Image-Modal.component'
import { AppUserService } from 'app/business/service/app/app-user.service'
import { AuthService } from 'app/core/auth/auth.service'

@Component({
  selector: 'app-create-verify-reprint-inquiry',
  templateUrl: './create-verify-reprint-inquiry.component.html',
  styleUrls: ['./create-verify-reprint-inquiry.component.css']
})
export class CreateVerifyReprintInquiryComponent implements OnInit, AfterViewInit {

  staff: boolean = false;
  dataSourceAttachPic: any[] = []
  form: any = {}
  value: any[] = []
  fileImg: any
  file: any
  dataList: any[] = []
  deleteAttachmentList: any = []
  request: any
  editItem: boolean
  editorOptions: any = {
    placeholder: 'Enter some text',
    maxLength: 50,
  }
  imageResize: any
  recheck: any

  attachTypeList: any[] = []
  declaratorList: any
  destinationList: any
  branchList: any
  docList: any
  
  resolution: any = {}
  userProfile: any = {}
  companyName: any[] = []
  clicked: boolean
  packId: string
  search: any
  markErrorList: any = []
  editJobData: boolean
  hId : number
  reprintId : number
  disableData : string
  dataSourceUpload: any[] = [];
  formSourceUpload: any[] = [];


  constructor(
    private appMasterService: AppMasterService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private alertService: AlertMessageService,
    private confirmService: ConfirmDialogService,
    private router: Router,
    private _location: Location,
    private appVerifierService : AppVerifyService,
    private appUserService:AppUserService,
    private _authService:AuthService,
  ) {}
  backClicked() {
    this._location.back()
  }
  ngOnInit(): void {
    this.userProfile = JSON.parse(
      this._authService.loginUser
    )

    //this.userProfile.uId = JSON.parse(this.appUserService.getSessionUserProfile()).uId
    const { reprintId, hId, disable, staff } = this.route.snapshot.queryParams
    this.hId = hId;
    this.reprintId = reprintId;
    this.disableData = disable;
    this.staff = staff;
    this.editItem = this.disableData == 'true' ? true : false
    this.editJobData = true
    this.loadData()
  }

  ngAfterViewInit() {}

  async loadData() {
    let req = {
      reprintId: this.reprintId,
      hId: this.hId
    }
    await this.appVerifierService.getVerifyReprintListDetail(req).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        if (res !== null) {
          this.form = { ...this.form, ...res };
            this.dataSourceAttachPic = this.form.attachments == null? []: [...this.form.attachments]
            this.formSourceUpload = this.form.attachments == null ? [] : [...this.form.attachments];
            this.dataSourceUpload = this.form.attachments == null ? [] : [...this.form.attachments];
        }
      }
    })
  }

  validForm(e): boolean {
    if (
      (!e.isPayment ||
        e.isPayment === undefined ||
        e.isPayment == 0 ||
        e.isPayment == '')
    ) {
      this.alertService.open({
        message: 'กรุณาระบุพิจารณาค่าใช้จ่าย',
        title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
      })
      return false
    }

    if (
      (!e.verifyRemark ||
        e.verifyRemark === undefined ||
        e.verifyRemark == 0 ||
        e.verifyRemark == '')
    ) {
      this.alertService.open({
        message: 'กรุณาระบุหมายเหตุ',
        title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
      })
      return false
    }

    return true
  }

  back() {
    window.history.back()
  }
 
  onView(e) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.minHeight = '30vh';
    dialogConfig.minWidth = '30vh';
    dialogConfig.maxHeight = '100vh';
    dialogConfig.data = {
      fullImageUrl: e.data.attachFilePic
    };

    const dialogRef = this.dialog.open(ImageModalComponent, dialogConfig)
  }
 
  async save() {
    let req = {
      reprintId: this.reprintId,
      reprintStatus : this.form.isPayment == "Y" ? 17 : 18,
      isActive : "Y",
      verifyRemark : this.form.verifyRemark,
      isPayment: this.form.isPayment
    }
    let checked = this.validForm(this.form)
    if (!checked) {
      return
    }
    await this.appVerifierService.saveReprintStatus(req).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        if (res !== null) {
          this.alertService
          .open({
            message: 'บันทึกสำเร็จ',
            title: 'ทำรายการสำเร็จ',
          })
          .then((x) => {
            this.router.navigate(['/page/verify/verify-reprint'])
          })
        }
      }
    })
  }
  async saveReject(reprintStatusId) {
    let req = {
      reprintId: this.reprintId,
      reprintStatus : reprintStatusId,
      isActive : "Y",
      verifyRemark : this.form.verifyRemark,
      isPayment: this.form.isPayment
    }
    let checked = this.validForm(this.form)
    if (!checked) {
      return
    }
    await this.appVerifierService.saveReprintStatus(req).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        if (res !== null) {
          this.alertService
          .open({
            message: 'บันทึกสำเร็จ',
            title: 'ทำรายการสำเร็จ',
          })
          .then((x) => {
            this.router.navigate(['/page/verify/verify-reprint'])
          })
        }
      }
    })
  }
  

}
