import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core'
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog'
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'
import { AppMasterService } from 'app/business/service/app/app-master.service'
import { DxDataGridComponent } from 'devextreme-angular'
import { CreateGoodListPopupComponent } from '../create-goodlist-popup/create-goodlist-popup.component'
import { CreateInvoicePopupComponent } from '../create-invoice-popup/create-invoice-popup'
import { ConfirmDialogService } from '@bundle/component/confirm-dialog/confirm-dialog.service'

@Component({
  selector: 'app-create-copack-popup',
  templateUrl: './create-copack-popup.component.html',
  styleUrls: ['./create-copack-popup.component.scss'],
})
export class CreateCoPackPopupComponent implements OnInit, AfterViewInit {
  editItem: boolean = false
  form: any = {
    id:0,
    items: [],
    invoice: [],
    invoiceChk: false,
    countryOfOrigin: 0,
  }
  unitList = []
  placeholderOrigin: string = 'ประเทศแหล่งกำเนิดสินค้า'
  destinationList = []
  weightList = []
  id: number
  listDeleteInvoice = []
  deleteInvoiceList = []
  deleteItemsList = []
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent

  invoiceEdit: boolean;
  itemEdit: boolean;
  countryofOriginEdit: boolean;
  footerEdit: boolean;

  isRqstatus : any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private appMasterService: AppMasterService,
    public dialogRef: MatDialogRef<CreateCoPackPopupComponent>,
    public alertService: AlertMessageService,
    public confirmService:ConfirmDialogService
  ) { }
  ngOnInit() {
    this.id = this.data.id
    this.editItem = this.data.editItem
    this.form = {...this.data};
    this.loadData()

  }
  ngAfterViewInit() {

  }
  loadData() {
    let requestmaster = {
      id: 0,
      isActive: 'Y',
    }
    this.appMasterService.getListCountry(requestmaster).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        this.destinationList = res;
        if(this.data.countryOfOrigin == undefined || this.data.countryOfOrigin == null ){ 
          var index = res.findIndex((x) => x.mC_COUNTRY_NAME == 'THAILAND')
          this.form.countryOfOrigin = res[index].mC_COUNTRY_NAME
        } 
        else{
          this.form.countryOfOrigin = this.data.countryOfOrigin;
        }
      }
    })
    if (this.data.invoice != undefined) {
      this.form.invoice = this.data.invoice
    }
    if (this.data.items != undefined) {
      this.form.items = this.data.items
    }
    if(this.data.invoiceChk != undefined) {
      this.form.invoiceChk = this.data.invoiceChk == 'Y' ? true : false;
    }
    this.form.remark = this.data.remark
  }
  save() {
    let validate = this.validate(this.form)
    if (validate) {
      this.form.invoice =
        this.deleteInvoiceList.length == 0
          ? this.form.invoice
          : this.form.invoice?.concat(this.deleteInvoiceList)
      this.dialogRef.close(this.form)
    } else {
      return
    }
  }
  validate(form): boolean {
    if (form.invoice.length < 1) {
      this.alertService.open({
        message: 'กรุณาระบุเลขใบกำกับสินค้า/วันที่',
        title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
      })
      return false
    }
    if (form.items.length < 1) {
      this.alertService.open({
        message: 'กรุณาระบุรายการสินค้า',
        title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
      })
      return false
    }
    return true
  }
  cancel() { }
  onInitNewRow(e) {
    e.data.invoiceId = 0
    e.data.docHid = 0
    e.data.itemNum = 0
  }
  addGoods() {
    const dialogGood = this.dialog.open(CreateGoodListPopupComponent, {
      disableClose: false,
      width: '70vw',
      maxHeight: '100vh',
      data: {
        data: {},
        edit:true
        
      },
      maxWidth: 'none',
    })

    dialogGood.afterClosed().subscribe((res) => {
      // receive data from dialog in res
      res.id = this.form.items.length + 1
      const data = {
        docItemId: 0,
        harmonizedCode: res.harmonizedCode,
        markChk: res.markCHK == true ? 'Y' : 'N',
        markItem: res.markItem,
        descriptionChk: res.descriptionCHK == true ? 'Y' : 'N',
        description: res.description,
        quantity: res.quantity,
        quantityChk: res.quantityCHK == true ? 'Y' : 'N',
        quantityUnit: res.quantityObj.name,
        quantityUnitCode: res.quantityObj.code,
        weight: res.weight,
        weightChk: res.weightCHK == true ? 'Y' : 'N',
        weightUnit: res.weightObj.name,
        weightUnitCode: res.weightObj.code,
        grossWeight: res.grossWeight,
        grossWeightChk: res.grossWeightCHK == true ? 'Y' : 'N',
        grossWeightUnit: res.grossWeightObj.name,
        grossWeightUnitCode: res.grossWeightObj.code,
        exportValue: res.exportValue,
        exchangeRate: res.exchangeRate,
        exchangeValue: res.exchangeValue,
        docHeaderId: 0,
        isActive: 'Y',
      }
      this.form.items.push(data)
    })
  }

  onRowRemoving(e) {
    if (this.id != 0) {
      e.data.isActive = 'N'
      switch (e.data.invoiceId) {
        case 0:
          break
        default:
          this.listDeleteInvoice = this.listDeleteInvoice?.concat(e.data)
      }
    }
  }
  addInvoice() {
    if (this.form.invoice.length >= 4) {
      return
    }
    const dialogRef = this.dialog.open(CreateInvoicePopupComponent, {
      disableClose: false,
      width: '70vw',
      data: {
        form: { invoiceId: 0, docHid: 0, itemNum: 0, isActive: 'Y' },
        editItem: this.editItem,
      },
      maxWidth: 'none',
    })
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.form.invoice.push(res)
      }
    })
  }
  deleteInvoice(e) {
    this.confirmService.open({header:"ยืนยันการทำรายการ",content:"ยืนยันการลบข้อมูลนี้ใช่หรือไม่?"}).then((res)=>{
      if (res == true) {
        let a = this.form.invoice.splice(e.rowIndex, 1)
        if (a[0].invoiceId != 0) {
          switch (a[0].invoiceId) {
            case 0:
              break
            default:
              this.deleteInvoiceList = this.deleteInvoiceList.concat(a)
          }
        }
      }
    })
  }


  editInvoice(e) {
    const dialogRef = this.dialog.open(CreateInvoicePopupComponent, {
      disableClose: false,
      width: '70vw',
      data: {
        form: {
          invoiceNo: e.data.invoiceNo,
          invoiceDate: e.data.invoiceDate,
          invoiceId: e.data.invoiceId,
          docHid: e.data.docHid,
          itemNum: e.data.itemNum,
        },
        editItem: this.editItem,
      },
      maxWidth: 'none',
    })
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.form.invoice.splice(e.rowIndex, 1, res)
      }
    })
  }
  editGood(e) {
    let newData = { ...e.data }
    const dialogRef = this.dialog.open(CreateGoodListPopupComponent, {
      disableClose: false,
      width: '70vw',
      maxHeight: '100vh',
      data: { data: newData, edit: true },
      maxWidth: 'none',
    })

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        e.data = newData
        res.id = e.data.id
        const data = {
          docItemId: res.docItemId,
          harmonizedCode: res.harmonizedCode,
          markChk: res.markCHK == true ? 'Y' : 'N',
          markItem: res.markItem,
          descriptionChk: res.descriptionCHK == true ? 'Y' : 'N',
          description: res.description,
          quantity: res.quantity,
          quantityChk: res.quantityCHK == true ? 'Y' : 'N',
          quantityUnit: res.quantityObj.name,
          quantityUnitCode: res.quantityObj.code,
          weight: res.weight,
          weightChk: res.weightCHK == true ? 'Y' : 'N',
          weightUnit: res.weightObj.name,
          weightUnitCode: res.weightObj.code,
          grossWeight: res.grossWeight,
          grossWeightChk: res.grossWeightCHK == true ? 'Y' : 'N',
          grossWeightUnit: res.grossWeightObj.name,
          grossWeightUnitCode: res.grossWeightObj.code,
          exportValue: res.exportValue,
          exchangeRate: res.exchangeRate,
          exchangeValue: res.exchangeValue,
          docHeaderId: res.docHeaderId,
          isActive: 'Y',
        }
        this.form.items.splice(e.rowIndex, 1, data)
      }
    })

    // dialogRef.afterClosed().subscribe((res) => {
    //   if (res) {
    //     res.id = e.data.id
    //     const data = {
    //       docItemId: res.docItemId,
    //       harmonizedCode: res.harmonizedCode,
    //       markChk: res.markCHK == true ? 'Y' : 'N',
    //       markItem: res.markItem,
    //       descriptionChk: res.descriptionCHK == true ? 'Y' : 'N',
    //       description: res.description,
    //       quantity: res.quantity,
    //       quantityChk: res.quantityCHK == true ? 'Y' : 'N',
    //       quantityUnit: res.quantityObj.name,
    //       quantityUnitCode: res.quantityObj.code,
    //       weight: res.weight,
    //       weightChk: res.weightCHK == true ? 'Y' : 'N',
    //       weightUnit: res.weightObj.name,
    //       weightUnitCode: res.weightObj.code,
    //       grossWeight: res.grossWeight,
    //       grossWeightChk: res.grossWeightCHK == true ? 'Y' : 'N',
    //       grossWeightUnit: res.grossWeightObj.name,
    //       grossWeightUnitCode: res.grossWeightObj.code,
    //       exportValue: res.exportValue,
    //       exchangeRate: res.exchangeRate,
    //       exchangeValue: res.exchangeValue,
    //       docHeaderId: res.docHeaderId,
    //       isActive: 'Y',
    //     }
    //     this.form.items.splice(e.rowIndex, 1, data)
    //   }
    // })
  }

  deleteGood(e) {
    this.confirmService.open({header:"ยืนยันการทำรายการ",content:"ยืนยันการลบข้อมูลนี้ใช่หรือไม่"}).then(
      (res)=>{
         if (res == true) {
        let a = this.form.items.splice(e.rowIndex, 1)
        if (a[0].docItemId != 0) {
          switch (a[0].docItemId) {
            case 0:
              break
            default:
              a[0].isActive = 'N'
              this.deleteItemsList = this.deleteItemsList.concat(a)
          }
        }
      }
      }
    )
  }
  weighDisplay(rowData){
    return rowData.weight + " "+ rowData.weightUnit;
  }

  quantityDisplay(rowData){
    return rowData.quantity + " "+ rowData.quantityUnit;
  }
  
  previewGood(e) {
    let newData = { ...e.data }
        const dialogRef = this.dialog.open(CreateGoodListPopupComponent, {
      disableClose: false,
      width: '70vw',
      maxHeight: '100vh',
      data: { data: newData, edit: false },
      maxWidth: 'none',
    })
  }
}
