import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'
import { AppMasterService } from 'app/business/service/app/app-master.service'

@Component({
  selector: 'app-create-invoice-popup',
  templateUrl: './create-invoice-popup.html',
  styleUrls: ['./create-invoice-popup.scss'],
})
export class CreateInvoicePopupComponent {
  form: any = {}
  editItem: boolean
  symbolRegex : RegExp
  engRegex : RegExp
  regexPattern: RegExp = /^[a-zA-Z0-9 !"$%&'()*+,-./#:;<=>?@[\\\]^_`{|}~\n\r฿]*$/;

  showErrorMessage: any = []

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public apiMasterService: AppMasterService,
    public dialogRef: MatDialogRef<CreateInvoicePopupComponent>,
    public alertService: AlertMessageService,
    private appMasterService: AppMasterService,
  ) {
    this.form = data.form
  }
  ngOnInit(){
    this.loadCheckValidateConfigD();
  }

  loadCheckValidateConfigD() {
    // Load Validate Input symbol
    let validateRequest = {
      configHcode: "VALIDATE",
      configDcode: 'V1',
    }
    this.appMasterService.getValidateInput(validateRequest).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        this.symbolRegex = new RegExp(res.configDEName)
      }
    })

    // Load Validate Input english
    let validateRequestInput = {
      configHcode: "VALIDATE",
      configDcode: 'V2',
    }
    this.appMasterService.getValidateInput(validateRequestInput).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        this.engRegex = new RegExp(res.configDEName);
      }
    })
  }
  cancel() {
    this.dialogRef.close()
  }
  save() {
    let validate = this.validate(this.form)
    if (validate) {
      this.dialogRef.close(this.form)
    }
  }
  validate(form) {
    if (
      !form.invoiceNo ||
      form.invoiceNo === undefined ||
      form.invoiceNo == ''
    ) {
      this.alertService.open({
        message: 'กรุณาระบุเลขใบกำกับสินค้า',
        title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
      })
      return false
    }
    if (!this.regexPattern.test(form.invoiceNo)) {
      this.alertService.open({
        message: 'เลขใบกำกับสินค้ากรอกได้เฉพาะภาษาอังกฤษกับสัญลักษณ์',
        title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
      })
      return false
    }
    if (
      !form.invoiceDate ||
      form.invoiceDate === undefined ||
      form.invoiceDate == ''
    ) {
      this.alertService.open({
        message: 'กรุณาระบุวันที่ใบกำกับสินค้า',
        title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
      })
      return false
    }
    return true
  }
}
