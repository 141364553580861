<mat-card class="flex flex-col h-full gap-4 px-4"
    style="overflow-y: scroll;overflow-x: hidden;background-color: #fff7e0;">
    <div id="mySidepanel" [style]="styleSidepanel" class="sidepanel idx">
        <button class="closebtn" (click)="closePanel()">&times;</button>
        <app-verify-item-detail [data]="itemDetail"></app-verify-item-detail>
    </div>
    <div style="width:100%">
        <!---------------- HEAD DESCRIPTION -------------------->
        <div class="flex flex-col justify-items-start gap-3" style="gap: 30px; margin-top:30px;">
            <div class="flex flex-row">
                <h4 class="w-1/6 text-start">Request No</h4>
                <h4 class="w-5/6 text-start">: {{form.requestNo}}</h4>
            </div>
            <hr>
            <div class="flex flex-row">
                <h4 class="w-1/6 text-start">Document Type</h4>
                <h4 class="w-5/6 text-start">: {{form.docType}}</h4>
            </div>
            <hr>
            <div class="flex flex-row">
                <h4 class="w-1/6 text-start">Express Type</h4>
                <!-- <h4 class="w-5/6 text-start">: {{form.expressType}}</h4> -->
                <h4 class="w-5/6 text-start">: {{newConceptExpressType}}</h4>
            </div>
            <hr *ngIf="form.jobRemark !=null && form.jobRemark!='' && form.jobRemark != undefined">
            <div class="flex flex-row"
                *ngIf="form.jobRemark !=null && form.jobRemark!='' && form.jobRemark != undefined">
                <h4 class="w-1/6 text-start">สาเหตุที่ผิดพลาด/หมายเหตุ</h4>
                <h4 class="w-5/6 text-start">: {{form.jobRemark}}</h4>
            </div>
            <hr *ngIf="form.branchName != undefined && form.branchName != null && form.branchName != ''">
            <div class="flex flex-row"
                *ngIf="form.branchName != undefined && form.branchName != null && form.branchName != ''">
                <h4 class="w-1/6 text-start">ลายเซ็นสด</h4>
                <h4 class="w-5/6 text-start">: {{form.branchName}}</h4>
            </div>
        </div>
        <!------------------------------------------------------->
        <div style="gap: 30px; margin-top:30px;" class="flex flex-col w-full">
            <!-------------------------- mark error ---------------------------->
            <div (click)="expand(1)" class="px-6 flex flex-row justify-between bgheader">
                <div class="basis-1/2 py-4 txtHrd">
                    รายละเอียดข้อผิดพลาดของเอกสาร/ Mark Error
                </div>
                <div class="basis-1/2 flex flex-row justify-end py-4 txtHrd">
                    <span *ngIf="collapse.markError">
                        <mat-icon svgIcon="mat_outline:arrow_drop_down"></mat-icon>
                    </span>
                    <span *ngIf="!collapse.markError">
                        <mat-icon svgIcon="mat_outline:arrow_drop_up"></mat-icon>
                    </span>
                </div>
            </div>

            <section class="collapsible" [@collapse]="collapse.markError">
                <div class="collapsible">
                    <dx-data-grid class="mt-3" #gridContainer [dataSource]="markErrorList"
                        [allowColumnReordering]="true" [showBorders]="true">
                        <dxi-column dataField="no" caption="ลำดับ" cellTemplate="index">
                        </dxi-column>
                        <div *dxTemplate="let row of 'index'">
                            {{ (row.rowIndex+1) +(dataGrid.instance.pageIndex() *
                            dataGrid.instance.pageSize() )
                            }}
                        </div>
                        <dxi-column dataField="docTypeName" caption="ประเภทเอกสาร">
                        </dxi-column>
                        <dxi-column dataField="fieldName" caption="ชื่อฟิลด์">
                        </dxi-column>
                        <dxi-column dataField="remark" caption="ข้อผิดพลาด">
                        </dxi-column>
                        <dxi-column dataField="markByName" caption="ผู้ตรวจสอบ">
                        </dxi-column>
                        <dxi-column cellTemplate="errorAction">
                        </dxi-column>
                        <div *dxTemplate="let row of 'errorAction'" style="font-size:9px;">
                            <button (click)="editError(row)" [disabled]="row.data.docTypeId === 0">
                                <mat-icon svgIcon="heroicons_outline:pencil-alt"></mat-icon>
                            </button>
                            <button (click)="deleteError(row)">
                                <mat-icon svgIcon="heroicons_outline:trash"></mat-icon>
                            </button>
                        </div>
                    </dx-data-grid>
                </div>
                <div class="flex flex-row gap-4">
                    <mat-checkbox class="justify-start" (change)="docMark($event)" [(ngModel)]="mark"></mat-checkbox>
                    <h4 class="self-center">เอกสารไม่สมบูรณ์</h4>
                </div>
                <div class="flex flex-row gap-4">
                    <h4 class="flex-none">บันทึกช่วยจำ:</h4>
                    <mat-form-field appearance="fill" class="flex-auto">
                        <textarea matInput [(ngModel)]="form.remark"></textarea>
                    </mat-form-field>
                </div>
            </section>
            <!------------------------------------------------------------------->


            <!-- CO exporter Form  รอเช็คเงื่อนไข doctype -->
            <div class="pt-2.5" *ngIf="docType == 'CO' || docType == 'CO/Pack'">
                <div>
                    <div (click)="expand(2)" class="px-6 flex flex-row justify-between bgheader">
                        <div class="basis-1/2 py-4 txtHrd">
                            ข้อมูลผู้ส่งออก/ Exporter Form
                        </div>
                        <div class="basis-1/2 flex flex-row justify-end py-4 txtHrd">
                            <span *ngIf="collapse.exporterForm">
                                <mat-icon svgIcon="mat_outline:arrow_drop_down"></mat-icon>
                            </span>
                            <span *ngIf="!collapse.exporterForm">
                                <mat-icon svgIcon="mat_outline:arrow_drop_up"></mat-icon>
                            </span>
                        </div>
                    </div>

                    <section class="collapsible" [@collapse]="collapse.exporterForm">
                        <!------------------------------------------------------------------------- Verify Request Regular--------------------------------------------------------------------------------->
                        <div class="collapsible" *ngIf="oldPackId == 0 || oldPackId== undefined">
                            <div class="flex flex-col">
                                <div class="flex flex-row items-center">
                                    <h4 class="w-1/6 text-start">Request By</h4>
                                    <h4 class="w-4/6 break-words text-start">: {{form.companyName}}</h4>
                                    <mat-checkbox class="w-1/6 justify-end" style="visibility: hidden;">
                                    </mat-checkbox>
                                </div>
                                <hr>
                                <div class="flex flex-row items-center">
                                    <h4 class="w-1/6 text-start">ความน่าเชื่อถือ</h4>
                                    <h4 class="w-4/6 break-words text-start">: {{form.companyGrade}}</h4>
                                    <mat-checkbox class="w-1/6 justify-end" style="visibility: hidden;">
                                    </mat-checkbox>
                                </div>
                                <hr>
                                <div class="flex flex-row items-center">
                                    <h4 class="w-1/6 text-start">Declarator</h4>
                                    <h4 class="w-4/6 break-words text-start">: {{form.declaratorName}}</h4>
                                    <mat-checkbox class="w-1/6 justify-end" style="visibility: hidden;">
                                    </mat-checkbox>
                                </div>
                                <hr>
                                <div class="flex flex-row items-center">
                                    <h4 class="w-1/6 text-start">Consignor</h4>
                                    <h4 class="w-4/6 break-words text-start">: {{form.consignorName}}</h4>
                                    <mat-checkbox class="w-1/6 justify-end" [(ngModel)]="checkbox.consignor">
                                    </mat-checkbox>
                                </div>
                                <hr>
                                <div class="flex flex-row items-center">
                                    <h4 class="w-1/6 text-start">Address</h4>
                                    <h4 class="w-4/6 break-words text-start break-all">
                                        : {{form.consignorAddress}}
                                    </h4>
                                    <mat-checkbox class="w-1/6 justify-end" style="visibility: hidden;">
                                    </mat-checkbox>
                                </div>
                                <hr *ngIf="form.docType !== 'CF'">
                                <div class="flex flex-row items-center" *ngIf="form.docType !== 'CF'">
                                    <h4 class="w-1/6 text-start">ลายมือชื่อผู้รับมอบอำนาจ</h4>
                                    <h4 class="w-5/6 text-start">: {{form.signAuthorizationName}}</h4>
                                </div>
                            </div>
                        </div>
                        <!------------------------------------------------------------------------- Verify Request Job--------------------------------------------------------------------------------->
                        <div class="collapsible" *ngIf="oldPackId != 0 && oldPackId != undefined">
                            <div class="flex flex-col">
                                <div class="flex flex-row">
                                    <h4 class="w-1/6 text-start">Request By</h4>
                                    <h4 class="w-4/6 break-words text-start">: {{formRequest.companyName}}</h4>
                                    <mat-checkbox class="w-1/6 justify-end" style="visibility: hidden;">
                                    </mat-checkbox>
                                </div>
                                <hr>
                                <div class="flex flex-row">
                                    <h4 class="w-1/6 text-start">ความน่าเชื่อถือ</h4>
                                    <h4 class="w-4/6 break-words text-start">: {{formRequest.companyGrade}}</h4>
                                    <mat-checkbox class="w-1/6 justify-end" style="visibility: hidden;">
                                    </mat-checkbox>
                                </div>
                                <hr>
                                <div class="flex flex-row">
                                    <h4 class="w-1/6 text-start">Declarator<span
                                            *ngIf="oldPackId != 0 && form.declaratorName != null"><br>(เดิม)</span></h4>
                                    <h4 class="w-4/6 break-words text-start">: {{formRequest.declaratorName}}</h4>
                                    <mat-checkbox class="w-1/6 justify-end" style="visibility: hidden;">
                                    </mat-checkbox>
                                </div>
                                <hr>
                                <div class="flex flex-row items-center"
                                    *ngIf="oldPackId != 0 && form.declaratorName != null">
                                    <h4
                                        [class]="formRequest.declaratorName != form.declaratorName ?' w-1/6 text-start self-center red':' w-1/6 text-start self-center'">
                                        Declarator<br>(แก้ไข)</h4>
                                    <h4
                                        [class]="formRequest.declaratorName != form.declaratorName ?' w-4/6 text-start self-center red':' w-4/6 text-start self-center'">
                                        : {{form.declaratorName}}
                                    </h4>
                                </div>
                                <hr>
                                <div class="flex flex-row items-center">
                                    <h4 class="w-1/6 text-start">Consignor<span
                                            *ngIf="oldPackId != 0 && form.consignorName != null"><br>(เดิม)</span></h4>
                                    <h4 class="w-4/6 break-words text-start">: {{formRequest.consignorName}}</h4>
                                    <mat-checkbox class="w-1/6 justify-end" [(ngModel)]="checkbox.consignor">
                                    </mat-checkbox>
                                </div>
                                <hr>
                                <div class="flex flex-row items-center"
                                    *ngIf="oldPackId != 0 && form.consignorName != null">
                                    <h4
                                        [class]="form.consignorName != formRequest.consignorName ?'w-1/6 text-start self-center red':'w-1/6 text-start self-center'">
                                        Consignor<br>(แก้ไข)</h4>
                                    <h4
                                        [class]="form.consignorName != formRequest.consignorName ?'w-4/6 break-words text-start self-center red':'w-4/6 break-words text-start self-center'">
                                        : {{form.consignorName}}
                                    </h4>
                                </div>
                                <hr>
                                <div class="flex flex-row items-center">
                                    <h4 class="w-1/6 text-start">Address<span><br>(เดิม)</span></h4>
                                    <h4 class="w-4/6 break-words text-start break-all">
                                        : {{formRequest.consignorAddress}}
                                    </h4>
                                    <mat-checkbox class="w-1/6 justify-end" style="visibility: hidden;">
                                    </mat-checkbox>
                                </div>
                                <hr>
                                <div class="flex flex-row items-center"
                                    *ngIf="oldPackId != 0 && formRequest.consignorAddress != null">
                                    <h4
                                        [class]="formRequest.consignorAddress != form.consignorAddress?'w-1/6 text-start red':'w-1/6 text-start'">
                                        Address<br>(แก้ไข)</h4>
                                    <h4
                                        [class]="formRequest.consignorAddress != form.consignorAddress?'w-4/6 break-words text-start break-all red':'w-4/6 break-words text-start break-all'">
                                        : {{form.consignorAddress}}
                                    </h4>
                                </div>
                                <hr *ngIf="form.docType !== 'CF'">
                                <div class="flex flex-row items-center" *ngIf="form.docType !== 'CF'">
                                    <h4 class="w-1/6 text-start">ลายมือชื่อผู้รับมอบอำนาจ <span><br>(เดิม)</span></h4>
                                    <h4 class="w-5/6 text-start">: {{formRequest.signAuthorizationName}}</h4>
                                </div>
                                <hr *ngIf="form.docType !== 'CF'">
                                <div class="flex flex-row items-center"
                                    *ngIf="oldPackId != 0 && formRequest.signAuthorizationName != null">
                                    <h4
                                        [class]="formRequest.signAuthorizationId != form.signAuthorizationId?'w-1/6 text-start red':'w-1/6 text-start'">
                                        ลายมือชื่อผู้รับมอบอำนาจ<br>(แก้ไข)</h4>
                                    <h4
                                        [class]="formRequest.signAuthorizationId != form.signAuthorizationId?'w-4/6 break-words text-start break-all red':'w-4/6 break-words text-start break-all'">
                                        : {{form.signAuthorizationName}}
                                    </h4>
                                </div>

                            </div>
                        </div>
                        <!-------------------------------------------------------------------------End Verify Request Job--------------------------------------------------------------------------------->
                    </section>
                </div>
            </div>

            <!-- CF exporter Form  ยังไม่ได้เช็คเงื่อนไข doctype ต้องเช็คเพิ่ม -->
            <!------------------------------------------------------------------------- Verify Request Regular--------------------------------------------------------------------------------->
            <div class="pt-2.5" *ngIf="docType == 'CF' && oldPackId == undefined">
                <div>
                    <div (click)="expand(2)" class="px-6 flex flex-row justify-between bgheader">
                        <div class="basis-1/2 py-4 txtHrd">
                            ข้อมูลผู้ส่งออก/ Exporter Form
                        </div>
                        <div class="basis-1/2 flex flex-row justify-end py-4 txtHrd">
                            <span *ngIf="collapse.exporterForm">
                                <mat-icon svgIcon="mat_outline:arrow_drop_down"></mat-icon>
                            </span>
                            <span *ngIf="!collapse.exporterForm">
                                <mat-icon svgIcon="mat_outline:arrow_drop_up"></mat-icon>
                            </span>
                        </div>
                    </div>
                    <section class="collapsible" [@collapse]="collapse.exporterForm">
                        <div class="collapsible">
                            <div class="flex flex-col">
                                <div class="flex flex-row">
                                    <h4 class="w-1/6 text-start self-center">Request By</h4>
                                    <h4 class="w-4/6 break-words text-start self-center">: {{form.companyName}}</h4>
                                    <mat-checkbox class="w-1/6 justify-end" style="visibility: hidden;">
                                    </mat-checkbox>
                                </div>
                                <hr>
                                <div class="flex flex-row">
                                    <h4 class="w-1/6 text-start self-center">ความน่าเชื่อถือ</h4>
                                    <h4 class="w-4/6 break-words text-start self-center">: {{form.companyGrade}}
                                    </h4>
                                    <mat-checkbox class="w-1/6 justify-end" style="visibility: hidden;">
                                    </mat-checkbox>
                                </div>
                                <hr>
                                <div class="flex flex-row items-center">
                                    <h4 class="w-1/6 text-start self-center">Consignor</h4>
                                    <h4 class="w-4/6 break-words text-start self-center">: {{form.declaratorName}}
                                    </h4>
                                    <mat-checkbox class="w-1/6 justify-end" [(ngModel)]="checkbox.consignor">
                                    </mat-checkbox>
                                </div>
                                <hr>
                                <div class="flex flex-row">
                                    <h4 class="w-1/6 text-start self-center">Destination</h4>
                                    <h4 class="w-4/6 break-words text-start self-center">: {{form.destination}}</h4>
                                    <mat-checkbox class="w-1/6 justify-end" [(ngModel)]="checkbox.destination">
                                    </mat-checkbox>
                                </div>
                                <hr>
                                <div class="flex flex-row">
                                    <h4 class="w-1/6 text-start self-center">Document Subject/Tittle header</h4>
                                    <h4 class="w-4/6 break-words text-start self-center">: {{form.title}}</h4>
                                    <mat-checkbox class="w-1/6 justify-end" [(ngModel)]="checkbox.title">
                                    </mat-checkbox>
                                </div>
                                <hr>
                                <div class="flex flex-row">
                                    <h4 class="w-1/6 text-start self-center">Document Reference No</h4>
                                    <h4 class="w-4/6 break-words text-start self-center">:
                                        {{form.refNo}}</h4>
                                    <mat-checkbox class="w-1/6 justify-end" [(ngModel)]="checkbox.docRef">
                                    </mat-checkbox>
                                </div>
                                <hr>
                                <div class="flex flex-row">
                                    <h4 class="w-1/6 text-start self-center">Document Date</h4>
                                    <h4 class="w-4/6 break-words text-start self-center">:
                                        {{form.documentDate | date:'yyyy-MM-dd' }}</h4>
                                    <mat-checkbox class="w-1/6 justify-end" [(ngModel)]="checkbox.docDate">
                                    </mat-checkbox>
                                </div>
                                <hr>
                                <div class="flex flex-row">
                                    <h4 class="w-1/6 text-start self-center">Total page</h4>
                                    <h4 class="w-4/6 break-words text-start self-center">: {{form.totalPages}}</h4>
                                    <mat-checkbox class="w-1/6 justify-end" [(ngModel)]="checkbox.totalPage">
                                    </mat-checkbox>
                                </div>
                                <hr>
                                <div class="flex flex-row">
                                    <h4 class="w-1/6 text-start self-center">ลักษณะเอกสารที่ยื่นขอ</h4>
                                    <h4 class="w-4/6 break-words text-start self-center">: {{form.book == 'N' ?
                                        'ไม่เป็นเล่ม' :
                                        form.book == 'Y' ? 'เป็นเล่ม' : ''}}</h4>
                                    <mat-checkbox class="w-1/6 justify-end" [(ngModel)]="checkbox.book">
                                    </mat-checkbox>
                                </div>
                                <hr>
                                <div class="flex flex-row">
                                    <h4 class="w-1/6 text-start self-center">Certificate Date</h4>
                                    <h4 class="w-4/6 break-words text-start self-center">:
                                        {{ form.certificateDateRequest == 'approve_date' ? 'ตามวันที่อนุมัติ':
                                        form.certificateDateRequest == 'first_request' ? 'ตามวันที่ยื่นคำร้อง':
                                        form.certificateDateRequest == 'on_date' ? 'วันที่ย้อนหลัง/ล่วงหน้า (ชำระค่าธรรมเนียม)' : '' }}
                                        <span
                                            *ngIf="form.certificateDateRequest != 'approve_date'">{{form.certificateDate  | date:'yyyy-MM-dd'}}</span>
                                    </h4>
                                    <mat-checkbox class="w-1/6 justify-end" [(ngModel)]="checkbox.cerDate">
                                    </mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <!-- CF exporter Form  ยังไม่ได้เช็คเงื่อนไข doctype ต้องเช็คเพิ่ม -->
            <!------------------------------------------------------------------------- Verify Request job--------------------------------------------------------------------------------->
            <div class="pt-2.5" *ngIf="docType == 'CF' && (oldPackId != 0 && oldPackId != undefined)">
                <div>
                    <div (click)="expand(2)" class="px-6 flex flex-row justify-between bgheader">
                        <div class="basis-1/2 py-4 txtHrd">
                            ข้อมูลผู้ส่งออก/ Exporter Form
                        </div>
                        <div class="basis-1/2 flex flex-row justify-end py-4 txtHrd">
                            <span *ngIf="collapse.exporterForm">
                                <mat-icon svgIcon="mat_outline:arrow_drop_down"></mat-icon>
                            </span>
                            <span *ngIf="!collapse.exporterForm">
                                <mat-icon svgIcon="mat_outline:arrow_drop_up"></mat-icon>
                            </span>
                        </div>
                    </div>
                    <section class="collapsible" [@collapse]="collapse.exporterForm">
                        <div class="collapsible">
                            <div class="flex flex-col">
                                <div class="flex flex-row">
                                    <h4 class="w-1/6 text-start self-center">Request By</h4>
                                    <h4 class="w-4/6 break-words text-start self-center">: {{formRequest.companyName}}
                                    </h4>
                                    <mat-checkbox class="w-1/6 justify-end" style="visibility: hidden;">
                                    </mat-checkbox>
                                </div>
                                <hr>
                                <div class="flex flex-row">
                                    <h4 class="w-1/6 text-start self-center">ความน่าเชื่อถือ</h4>
                                    <h4 class="w-4/6 break-words text-start self-center">: {{formRequest.companyGrade}}
                                    </h4>
                                    <mat-checkbox class="w-1/6 justify-end" style="visibility: hidden;">
                                    </mat-checkbox>
                                </div>
                                <hr>
                                <div class="flex flex-row">
                                    <h4 class="w-1/6 text-start self-center">Consignor<span
                                            *ngIf="oldPackId != 0 && form.declaratorName != null"><br>(เดิม)</span></h4>
                                    <h4 class="w-4/6 break-words text-start self-center">:
                                        {{formRequest.declaratorName}}</h4>
                                    <mat-checkbox class="w-1/6 justify-end" [(ngModel)]="checkbox.consignor">
                                    </mat-checkbox>
                                </div>
                                <hr>
                                <div class="flex flex-row items-center"
                                    *ngIf="oldPackId != 0 && form.consignorName != null">
                                    <h4
                                        [class]="formRequest.declaratorName != form.declaratorName? 'w-1/6 text-start red':'w-1/6 text-start'">
                                        Consignor<br>(แก้ไข)</h4>
                                    <h4
                                        [class]="formRequest.declaratorName != form.declaratorName? 'w-4/6 break-words text-start red':'w-4/6 break-words text-start'">
                                        : {{form.consignorName}}</h4>
                                </div>
                                <hr>
                                <div class="flex flex-row">
                                    <h4 class="w-1/6 text-start self-center">Destination <span
                                            *ngIf="oldPackId != 0 && form.destination != null"><br>(เดิม)</span></h4>
                                    <h4 class="w-4/6 break-words text-start self-center">: {{formRequest.destination}}
                                    </h4>
                                    <mat-checkbox class="w-1/6 justify-end" [(ngModel)]="checkbox.destination">
                                    </mat-checkbox>
                                </div>
                                <hr>
                                <div class="flex flex-row items-center"
                                    *ngIf="oldPackId != 0 && form.destination != null">
                                    <h4
                                        [class]="formRequest.destination != form.destination? 'w-1/6 text-start red':'w-1/6 text-start'">
                                        Destination<br>(แก้ไข)</h4>
                                    <h4
                                        [class]="formRequest.destination != form.destination? 'w-4/6 break-words text-start red':'w-4/6 break-words text-start'">
                                        : {{form.destination}}</h4>

                                </div>
                                <hr>
                                <div class="flex flex-row">
                                    <h4 class="w-1/6 text-start self-center">Document Subject/Tittle header<span
                                            *ngIf="oldPackId != 0 && form.title != null"><br>(เดิม)</span></h4>
                                    <h4 class="w-4/6 break-words text-start self-center">: {{formRequest.title}}</h4>
                                    <mat-checkbox class="w-1/6 justify-end" [(ngModel)]="checkbox.title">
                                    </mat-checkbox>
                                </div>
                                <hr>
                                <div class="flex flex-row items-center" *ngIf="oldPackId != 0 && form.title != null">
                                    <h4
                                        [class]="formRequest.title != form.title? 'w-1/6 text-start red':'w-1/6 text-start'">
                                        Document Subject/Tittle header<br>(แก้ไข)</h4>
                                    <h4
                                        [class]="formRequest.title != form.title? 'w-4/6 break-words text-start red':'w-4/6 break-words text-start'">
                                        : {{form.title}}</h4>
                                </div>
                                <hr>
                                <div class="flex flex-row">
                                    <h4 class="w-1/6 text-start self-center">Document Reference No<span
                                            *ngIf="oldPackId != 0 && form.refNo != null"><br>(เดิม)</span></h4>
                                    <h4 class="w-4/6 break-words text-start self-center">:
                                        {{formRequest.refNo}}</h4>
                                    <mat-checkbox class="w-1/6 justify-end" [(ngModel)]="checkbox.docRef">
                                    </mat-checkbox>
                                </div>
                                <hr>
                                <div class="flex flex-row items-center" *ngIf="oldPackId != 0 && form.refNo != null">
                                    <h4
                                        [class]="formRequest.refNo != form.refNo? 'w-1/6 text-start red':'w-1/6 text-start'">
                                        Document Reference No<br>(แก้ไข)</h4>
                                    <h4
                                        [class]="formRequest.refNo != form.refNo? 'w-4/6 break-words text-start red':'w-4/6 break-words text-start'">
                                        : {{form.refNo}}</h4>
                                </div>
                                <hr>
                                <div class="flex flex-row">
                                    <h4 class="w-1/6 text-start self-center">Document Date<span
                                            *ngIf="oldPackId != 0 && form.documentDate != null"><br>(เดิม)</span></h4>
                                    <h4 class="w-4/6 break-words text-start self-center">:
                                        {{formRequest.documentDate | date:'yyyy-MM-dd' }}</h4>
                                    <mat-checkbox class="w-1/6 justify-end" [(ngModel)]="checkbox.docDate">
                                    </mat-checkbox>
                                </div>
                                <hr>
                                <div class="flex flex-row items-center"
                                    *ngIf="oldPackId != 0 && form.documentDate != null">

                                    <h4
                                        [class]="formRequest.documentDate != form.documentDate? 'w-1/6 text-start red':'w-1/6 text-start'">
                                        Document Date<br>(แก้ไข)</h4>
                                    <h4
                                        [class]="formRequest.documentDate != form.documentDate? 'w-4/6 break-words text-start red':'w-4/6 break-words text-start'">
                                        : {{form.documentDate | date:'yyyy-MM-dd' }}</h4>
                                </div>
                                <hr>
                                <div class="flex flex-row">
                                    <h4 class="w-1/6 text-start self-center">Total page<span
                                            *ngIf="oldPackId != 0 && form.totalPages != null"><br>(เดิม)</span></h4>
                                    <h4 class="w-4/6 break-words text-start self-center">: {{formRequest.totalPages}}
                                    </h4>
                                    <mat-checkbox class="w-1/6 justify-end" [(ngModel)]="checkbox.totalPage">
                                    </mat-checkbox>
                                </div>
                                <hr>
                                <div class="flex flex-row items-center"
                                    *ngIf="oldPackId != 0 && form.totalPages != null">
                                    <h4
                                        [class]="form.totalPages != formRequest.totalPages ?'w-1/6 text-start self-center red':'w-1/6 text-start self-center'">
                                        Total page<br>(แก้ไข)</h4>
                                    <h4
                                        [class]="form.totalPages != formRequest.totalPages ?'w-4/6 break-words text-start self-center red':'w-4/6 break-words text-start self-center'">
                                        : {{form.totalPages}}
                                    </h4>
                                </div>
                                <hr>
                                <div class="flex flex-row">
                                    <h4 class="w-1/6 text-start self-center">ลักษณะเอกสารที่ยื่นขอ<span
                                            *ngIf="oldPackId != 0 && form.totalPages != null"><br>(เดิม)</span></h4>
                                    <h4 class="w-4/6 break-words text-start self-center">
                                        : {{form.book == 'N' ?
                                        'ไม่เป็นเล่ม' :
                                        form.book == 'Y' ? 'เป็นเล่ม' : ''}}
                                    </h4>
                                </div>
                                <hr>
                                <div class="flex flex-row items-center" *ngIf="oldPackId != 0 && form.book != null">
                                    <h4
                                        [class]="form.book != formRequest.book ?'w-1/6 text-start self-center red':'w-1/6 text-start self-center'">
                                        ลักษณะเอกสารที่ยื่นขอ<br>(แก้ไข)</h4>
                                    <h4
                                        [class]="form.book != formRequest.book ?'w-4/6 break-words text-start self-center red':'w-4/6 break-words text-start self-center'">
                                        : {{formRequest.book == 'N' ?
                                        'ไม่เป็นเล่ม' :
                                        formRequest.book == 'Y' ? 'เป็นเล่ม' : ''}}</h4>
                                    <mat-checkbox class="w-1/6 justify-end" [(ngModel)]="checkbox.book">
                                    </mat-checkbox>
                                </div>
                                <hr>
                                <div class="flex flex-row">
                                    <h4 class="w-1/6 text-start self-center">Certificate Date <span
                                            *ngIf="oldPackId != 0 && form.certificateDateRequest != null"><br>(เดิม)</span>
                                    </h4>
                                    <h4 class="w-4/6 break-words text-start self-center">:
                                        {{ formRequest.certificateDateRequest == 'approve_date' ? 'ตามวันที่อนุมัติ':
                                        formRequest.certificateDateRequest == 'first_request' ? 'ตามวันที่ยื่นคำร้อง':
                                        formRequest.certificateDateRequest == 'on_date' ? 'วันที่ย้อนหลัง/ล่วงหน้า (ชำระค่าธรรมเนียม)' :    '' }} {{formRequest.certificateDate | date:'yyyy-MM-dd'}}</h4>
                                    <mat-checkbox class="w-1/6 justify-end" [(ngModel)]="checkbox.cerDate">
                                    </mat-checkbox>
                                </div>
                                <hr>
                                <div class="flex flex-row items-center"
                                    *ngIf="oldPackId != 0 && form.certificateDateRequest != null">
                                    <h4
                                        [class]="checkCertificateEdit(form, formRequest) ?'w-1/6 text-start self-center red':'w-1/6 text-start self-center'">
                                        Certificate Date<br>(แก้ไข)</h4>
                                    <h4
                                        [class]="checkCertificateEdit(form, formRequest) ?'w-4/6 break-words text-start self-center red':'w-4/6 break-words text-start self-center'">
                                        :
                                        {{ form.certificateDateRequest == 'approve_date' ? 'ตามวันที่อนุมัติ':
                                        form.certificateDateRequest == 'first_request' ? 'ตามวันที่ยื่นคำร้อง':
                                        form.certificateDateRequest == 'on_date' ? 'วันที่ย้อนหลัง/ล่วงหน้า (ชำระค่าธรรมเนียม)' :
                                        '' }} <span
                                            *ngIf="form.certificateDateRequest != 'approve_date'">{{form.certificateDate
                                            | date:'yyyy-MM-dd'}}</span></h4>
                                </div>

                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <!-------------------------------------------------------------------------End Verify Request job--------------------------------------------------------------------------------->
            <!-- Consignee Form  เช็คว่าแสดงเมื่อเป็น CO-->
            <!------------------------------------------------------------------------- Verify Request Regular--------------------------------------------------------------------------------->
            <div class="pt-2.5" *ngIf="(docType == 'CO' || docType == 'CO/Pack') && oldPackId == undefined">
                <div>
                    <div (click)="expand(3)" class="px-6 flex flex-row justify-between bgheader">
                        <div class="basis-1/2 py-4 txtHrd">
                            ข้อมูลผู้รับสินค้าปลายทาง/ Consignee Form
                        </div>
                        <div class="basis-1/2 flex flex-row justify-end py-4 txtHrd">
                            <span *ngIf="collapse.consigneeForm">
                                <mat-icon svgIcon="mat_outline:arrow_drop_down"></mat-icon>
                            </span>
                            <span *ngIf="!collapse.consigneeForm">
                                <mat-icon svgIcon="mat_outline:arrow_drop_up"></mat-icon>
                            </span>
                        </div>
                    </div>
                    <section class="collapsible" [@collapse]="collapse.consigneeForm">
                        <div class="collapsible">
                            <div class="flex flex-col">
                                <div class="flex flex-row items-center">
                                    <h4 class="w-1/6 text-start">Consignee</h4>
                                    <h4 class="w-4/6 break-words text-start">: {{form.consigneeName}}</h4>
                                    <mat-checkbox class="w-1/6 justify-end" [(ngModel)]="checkbox.consignee">
                                    </mat-checkbox>
                                </div>
                                <hr>
                                <div class="flex flex-row items-center">
                                    <h4 class="w-1/6 text-start">Notify</h4>
                                    <h4 class="w-4/6 break-words text-start">: {{form.consigneeNotify}}</h4>
                                    <mat-checkbox class="w-1/6 justify-end" [(ngModel)]="checkbox.consigneeNotif">
                                    </mat-checkbox>
                                </div>
                                <hr>
                                <div class="flex flex-row items-center">
                                    <h4 class="w-1/6 text-start">Vessel/ Flight No.</h4>
                                    <h4 class="w-4/6 break-words text-start">: {{form.vesselType}} {{form.vesselNo}}
                                    </h4>
                                    <mat-checkbox class="w-1/6 justify-end" [(ngModel)]="checkbox.vessel">
                                    </mat-checkbox>
                                </div>
                                <hr>
                                <div class="flex flex-row items-center">
                                    <h4 class="w-1/6 text-start">Loading on or About</h4>
                                    <h4 class="w-4/6 break-words text-start">:
                                        {{form.loadingDate | date:'yyyy-MM-dd' }}
                                    </h4>
                                    <mat-checkbox class="w-1/6 justify-end" [(ngModel)]="checkbox.loadingDate">
                                    </mat-checkbox>
                                </div>
                                <hr>
                                <div class="flex flex-row items-center">
                                    <h4 class="w-1/6 text-start">Certificate Date</h4>
                                    <h4 class="w-4/6 break-words text-start">:
                                        {{ form.certificateDateRequest == 'approve_date' ? 'ตามวันที่อนุมัติ':
                                        form.certificateDateRequest == 'first_request' ? 'ตามวันที่ยื่นคำร้อง':
                                        form.certificateDateRequest == 'on_date' ? 'วันที่ย้อนหลัง/ล่วงหน้า (ชำระค่าธรรมเนียม)' : '' }} <span
                                            *ngIf="form.certificateDateRequest != 'approve_date'">{{form.certificateDate
                                            | date:'yyyy-MM-dd'}}</span></h4>
                                    <mat-checkbox class="w-1/6 justify-end" [(ngModel)]="checkbox.cerDate">
                                    </mat-checkbox>
                                </div>
                                <hr>
                                <div class="flex flex-row items-center">
                                    <h4 class="w-1/6 text-start">Destination</h4>
                                    <h4 class="w-4/6 break-words text-start">: {{form.destination}}</h4>
                                    <mat-checkbox class="w-1/6 justify-end" [(ngModel)]="checkbox.destination">
                                    </mat-checkbox>
                                </div>
                                <hr>
                                <div class="flex flex-row items-center">
                                    <h4 class="w-1/6 text-start">Destination Country</h4>
                                    <h4 class="w-4/6 break-words text-start">: {{form.destinationCountry}}</h4>
                                    <mat-checkbox class="w-1/6 justify-end" [(ngModel)]="checkbox.destinationCountry">
                                    </mat-checkbox>
                                </div>
                            </div>

                        </div>
                    </section>
                </div>
            </div>
            <!------------------------------------------------------------------------- Verify Request job--------------------------------------------------------------------------------->
            <div class="pt-2.5"
                *ngIf="(docType == 'CO' || docType == 'CO/Pack') && (oldPackId != 0 && oldPackId != undefined)">
                <div>
                    <div (click)="expand(3)" class="px-6 flex flex-row justify-between bgheader">
                        <div class="basis-1/2 py-4 txtHrd">
                            ข้อมูลผู้รับสินค้าปลายทาง/ Consignee Form
                        </div>
                        <div class="basis-1/2 flex flex-row justify-end py-4 txtHrd">
                            <span *ngIf="collapse.consigneeForm">
                                <mat-icon svgIcon="mat_outline:arrow_drop_down"></mat-icon>
                            </span>
                            <span *ngIf="!collapse.consigneeForm">
                                <mat-icon svgIcon="mat_outline:arrow_drop_up"></mat-icon>
                            </span>
                        </div>
                    </div>
                    <section class="collapsible" [@collapse]="collapse.consigneeForm">
                        <div class="collapsible">
                            <div class="flex flex-col">
                                <div class="flex flex-row items-center">
                                    <h4 class="w-1/6 text-start">Consignee<span
                                            *ngIf="oldPackId != 0 && form.consigneeName != null"><br>(เดิม)</span></h4>
                                    <h4 class="w-4/6 break-words text-start">: {{formRequest.consigneeName}}</h4>
                                    <mat-checkbox class="w-1/6 justify-end" [(ngModel)]="checkbox.consignee">
                                    </mat-checkbox>
                                </div>
                                <div class="flex flex-row items-center"
                                    *ngIf="oldPackId != 0 && form.consigneeName != null">
                                    <h4
                                        [class]="formRequest.consigneeName != form.consigneeName?'w-1/6 text-start red':'w-1/6 text-start'">
                                        Consignee<br>(แก้ไข)</h4>
                                    <h4
                                        [class]="formRequest.consigneeName != form.consigneeName?'w-4/6 break-words text-start red':'w-1/6 text-start'">
                                        : {{form.consigneeName}}</h4>
                                </div>
                                <hr>
                                <div class="flex flex-row items-center">
                                    <h4 class="w-1/6 text-start">Notify<span *ngIf="oldPackId != 0"><br>(เดิม)</span>
                                    </h4>
                                    <h4 class="w-4/6 break-words text-start">: {{formRequest.consigneeNotify}}</h4>
                                    <mat-checkbox class="w-1/6 justify-end" [(ngModel)]="checkbox.consigneeNotif">
                                    </mat-checkbox>
                                </div>
                                <div class="flex flex-row items-center"
                                    *ngIf="oldPackId != 0 && form.consigneeNotify != null">
                                    <h4
                                        [class]="formRequest.consigneeNotify != form.consigneeNotify? 'w-1/6 text-start red':'w-1/6 text-start'">
                                        Notify<br>(แก้ไข)</h4>
                                    <h4
                                        [class]="formRequest.consigneeNotify != form.consigneeNotify? 'w-4/6 break-words text-start red':'w-4/6 break-words text-start'">
                                        : {{form.consigneeNotify}}</h4>
                                </div>
                                <hr>
                                <div class="flex flex-row items-center">
                                    <h4 class="w-1/6 text-start">Vessel/ Flight No.<span
                                            *ngIf="oldPackId != 0 && form.vesselType != null"><br>(เดิม)</span></h4>
                                    <h4 class="w-4/6 break-words text-start">: {{formRequest.vesselType}}
                                        {{formRequest.vesselNo}}
                                    </h4>
                                    <mat-checkbox class="w-1/6 justify-end" [(ngModel)]="checkbox.vessel">
                                    </mat-checkbox>
                                </div>
                                <div class="flex flex-row items-center" *ngIf="oldPackId != 0 &&
                                        form.vesselType !=null">
                                    <h4
                                        [class]="formRequest.vesselType != form.vesselType || formRequest.vesselNo != form.vesselNo?'w-1/6 text-start red':'w-1/6 text-start'">
                                        Vessel/ Flight No.<br>(แก้ไข)</h4>
                                    <h4
                                        [class]="formRequest.vesselType != form.vesselType || formRequest.vesselNo != form.vesselNo?'w-4/6 break-words text-start red':'w-4/6 break-words text-start'">
                                        : {{form.vesselType}} {{form.vesselNo}}</h4>
                                </div>
                                <hr>
                                <div class="flex flex-row items-center">
                                    <h4 class="w-1/6 text-start">Loading on or About<span
                                            *ngIf="oldPackId != 0 && form.loadingDate != null"><br>(เดิม)</span></h4>
                                    <h4 class="w-4/6 break-words text-start">:
                                        {{formRequest.loadingDate | date:'yyyy-MM-dd' }}
                                    </h4>
                                    <mat-checkbox class="w-1/6 justify-end" [(ngModel)]="checkbox.loadingDate">
                                    </mat-checkbox>
                                </div>
                                <div class="flex flex-row items-center" *ngIf="oldPackId != 0 &&
                                        form.loadingDate !=null">
                                    <h4
                                        [class]="formRequest.loadingDate != form.loadingDate || formRequest.vesselNo != form.vesselNo?'w-1/6 text-start red':'w-1/6 text-start'">
                                        Loading on or About <br>(แก้ไข)</h4>
                                    <h4
                                        [class]="formRequest.loadingDate != form.loadingDate || formRequest.vesselNo != form.vesselNo?'w-4/6 break-words text-start red':'w-4/6 break-words text-start'">
                                        : {{form.loadingDate | date:'yyyy-MM-dd'}}</h4>
                                </div>
                                <hr>
                                <div class="flex flex-row items-center">
                                    <h4 class="w-1/6 text-start">Certificate Date<span
                                            *ngIf="oldPackId != 0 && form.certificateDateRequest != null"><br>(เดิม)</span>
                                    </h4>
                                    <h4 class="w-4/6 break-words text-start">:
                                        {{ formRequest.certificateDateRequest == 'approve_date' ? 'ตามวันที่อนุมัติ':
                                        formRequest.certificateDateRequest == 'first_request' ? 'ตามวันที่ยื่นคำร้อง':
                                        formRequest.certificateDateRequest == 'on_date' ? 'วันที่ย้อนหลัง/ล่วงหน้า (ชำระค่าธรรมเนียม)' :
                                        '' }} {{formRequest.certificateDate | date:'yyyy-MM-dd'}}
                                    </h4>
                                    <mat-checkbox class="w-1/6 justify-end" [(ngModel)]="checkbox.cerDate">
                                    </mat-checkbox>
                                </div>
                                <div class="flex flex-row items-center"
                                    *ngIf="oldPackId != 0 && form.certificateDateRequest != null">
                                    <h4
                                        [class]="checkCertificateEdit(form, formRequest) ?'w-1/6 text-start red':'w-1/6 text-start'">
                                        Certificate Date<br>(แก้ไข)</h4>
                                    <h4
                                        [class]="checkCertificateEdit(form, formRequest) ?'w-4/6 break-words text-start red':'w-4/6 break-words text-start'">
                                        :
                                        {{ form.certificateDateRequest == 'approve_date' ?
                                        'ตามวันที่อนุมัติ':
                                        form.certificateDateRequest == 'first_request' ?
                                        'ตามวันที่ยื่นคำร้อง':
                                        form.certificateDateRequest == 'on_date' ? 'วันที่ย้อนหลัง/ล่วงหน้า (ชำระค่าธรรมเนียม)' :
                                        '' }} <span
                                            *ngIf="form.certificateDateRequest != 'approve_date'">{{form.certificateDate
                                            | date:'yyyy-MM-dd'}}</span></h4>
                                </div>
                                <hr>
                                <div class="flex flex-row items-center">
                                    <h4 class="w-1/6 text-start">Destination<span
                                            *ngIf="oldPackId != 0 && form.destination != null"><br>(เดิม)</span></h4>
                                    <h4 class="w-4/6 break-words text-start">: {{formRequest.destination}}</h4>
                                    <mat-checkbox class="w-1/6 justify-end" [(ngModel)]="checkbox.destination">
                                    </mat-checkbox>
                                </div>
                                <div class="flex flex-row items-center"
                                    *ngIf="oldPackId != 0 && form.destination != null">
                                    <h4
                                        [class]="formRequest.destination != form.destination ?'w-1/6 text-start red':'w-1/6 text-start'">
                                        Destination<br>(แก้ไข)</h4>
                                    <h4
                                        [class]="formRequest.destination != form.destination ?'w-4/6 break-words text-start red':'w-4/6 break-words text-start'">
                                        : {{form.destination}}</h4>
                                </div>
                                <hr>
                                <div class="flex flex-row items-center">
                                    <h4 class="w-1/6 text-start">Destination Country<span
                                            *ngIf="oldPackId != 0 && form.destinationCountry != null"><br>(เดิม)</span>
                                    </h4>
                                    <h4 class="w-4/6 break-words text-start">: {{formRequest.destinationCountry}}</h4>
                                    <mat-checkbox class="w-1/6 justify-end" [(ngModel)]="checkbox.destinationCountry">
                                    </mat-checkbox>
                                </div>
                                <div class="flex flex-row items-center"
                                    *ngIf="oldPackId != 0 && form.destinationCountry != null">
                                    <h4
                                        [class]="formRequest.destinationCountry != form.destinationCountry ?'w-1/6 text-start red':'w-1/6 text-start'">
                                        Destination Country<br>(แก้ไข)</h4>
                                    <h4
                                        [class]="formRequest.destinationCountry != form.destinationCountry ?'w-4/6 break-words text-start red':'w-4/6 break-words text-start'">
                                        : {{form.destinationCountry}}</h4>
                                </div>
                            </div>

                        </div>
                    </section>
                </div>

            </div>
            <!-------------------------------------------------------------------------End Verify Request job--------------------------------------------------------------------------------->
            <!------------------------------------------------------------------------- Verify Request job--------------------------------------------------------------------------------->
            <div *ngIf="(docType == 'CO' || docType == 'CO/Pack') && (oldPackId != 0 && oldPackId != undefined)">
                <div *ngFor="let d of formRequest.packList;let i = index">
                    <div *ngFor="let e of form.packList;let i = index">
                        <div class="pt-2.5">
                            <div>
                                <div (click)="expandCoPack(i)" class="px-6 flex flex-row justify-between bgheader">
                                    <div class="basis-1/2 py-4 txtHrd">
                                        {{formRequest.isCoPack == 'N' ? 'ข้อมูลรายการสินค้า/ Item Description #' +(i+1)
                                        : "CO :"
                                        +(i+1)}}
                                    </div>
                                    <div class="basis-1/2 flex flex-row justify-end py-4 txtHrd">
                                        <span *ngIf="collapseCopack[i]">
                                            <mat-icon svgIcon="mat_outline:arrow_drop_down"></mat-icon>
                                        </span>
                                        <span *ngIf="!collapseCopack[i]">
                                            <mat-icon svgIcon="mat_outline:arrow_drop_up"></mat-icon>
                                        </span>
                                    </div>
                                </div>
                                <section class="collapsible" [@collapse]="collapseCopack[i]">
                                    <div class="collapsible">
                                        <div style="height: 5vh;background-color: #003f80;font-size: 20px;"
                                            class="grid  justify-items-center text-white rounded-t-md">
                                            <h2 class="text-start  self-center">ข้อมูลใบกำกับสินค้า & Origin สินค้า</h2>
                                        </div>
                                        <div class="flex flex-col border rounded-b-md px-2.5 pt-2.5">
                                            <div class="flex flex-row">
                                                <label class="w-1/6 text-start">Invoice
                                                    No./Date<span><br>(เดิม)</span></label>
                                                <div class="w-3/6 break-words text-start">
                                                    <div *ngFor="let a of d.invoiceList;">: {{ a.invoiceNo + "/" +
                                                        (a.invoiceDate | date: "yyyy-MM-dd")}}
                                                    </div> <br>
                                                </div>
                                                <div class="flex flex-auto break-words text-start">
                                                    <div *ngIf="formRequest.invoiceChkForm=='Y'">
                                                        <mat-checkbox class="example-margin"
                                                            [(ngModel)]="formRequest.invoiceChkForm"
                                                            name="invoiceChkForm" #invoiceChkForm="ngModel"
                                                            [disabled]="true">
                                                            ไม่ปรากฏบนฟอร์ม</mat-checkbox>
                                                    </div>
                                                </div>
                                                <mat-checkbox class="w-1/6 justify-end" [(ngModel)]="invoice[i]">
                                                </mat-checkbox>
                                            </div>
                                            <div class="flex flex-row"
                                                *ngIf="oldPackId != 0 && e.invoiceList.length != 0">

                                                <label [class]="checkInvoiceEdit(d, e, i) ? 'w-1/6 text-start red':''">
                                                    Invoice No./Date<br>(แก้ไข)</label>

                                                :&nbsp;
                                                <div *ngFor="let b of e.invoiceList;"
                                                    [class]="checkInvoiceEdit(d, e, i) ? 'w-4/6 break-words text-start red':''">
                                                    {{ b.invoiceNo + "/" + (b.invoiceDate | date: "yyyy-MM-dd")}}</div>
                                            </div>


                                            <div class="flex flex-row">
                                                <label class="w-1/6 text-start">Country of Origin<br>(เดิม)</label>
                                                <label class="w-4/6 break-words text-start">: {{d.countryOfOrigin}}
                                                </label>
                                                <mat-checkbox class="w-1/6 justify-end"
                                                    [(ngModel)]="countryOfOrigin[i]">
                                                </mat-checkbox>
                                            </div>

                                            <div class="flex flex-row"
                                                *ngIf="oldPackId != 0 && e.countryOfOrigin != null">
                                                <label
                                                    [class]="d.countryOfOrigin != e.countryOfOrigin || d.countryOfOrigin != e.countryOfOrigin?'w-1/6 text-start red':'w-1/6 text-start'">
                                                    Country of Origin<br>(แก้ไข)</label>
                                                <label
                                                    [class]="d.countryOfOrigin != e.countryOfOrigin || d.countryOfOrigin != e.countryOfOrigin?'w-4/6 break-words text-start red':'w-4/6 break-words text-start'">
                                                    : {{e.countryOfOrigin}}</label>
                                            </div>
                                        </div>


                                        <div style="height: 5vh;background-color: #003f80;font-size: 20px;"
                                            class="grid  justify-items-center text-white rounded-t-md mt-5">
                                            <h2 class="text-start self-center">ข้อมูลรายการสินค้า</h2>
                                        </div>
                                        <div>
                                            <table mat-table [dataSource]="d.goodsList" class="mat-elevation-z8">

                                                <ng-container matColumnDef="position">
                                                    <th mat-header-cell *matHeaderCellDef>ลำดับ</th>
                                                    <td mat-cell *matCellDef="let element;"> {{element.position}}
                                                    </td>
                                                    <td mat-footer-cell *matFooterCellDef class="my-footer-cell">
                                                        SUMMARY
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="markItem">
                                                    <th mat-header-cell *matHeaderCellDef>เครื่องหมายหีบห่อ/Mark
                                                    </th>
                                                    <td mat-cell *matCellDef="let element"
                                                        style="word-break: break-all;"> {{element.markItem}}
                                                    </td>
                                                    <td mat-footer-cell *matFooterCellDef class="my-footer-cell">
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="description">
                                                    <th mat-header-cell *matHeaderCellDef>ชื่อสินค้า/Description
                                                    </th>
                                                    <td mat-cell *matCellDef="let element"
                                                        style="word-break: break-all;"> {{element.description}}
                                                    </td>
                                                    <td mat-footer-cell *matFooterCellDef class="my-footer-cell">
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="quantity">
                                                    <th mat-header-cell *matHeaderCellDef>ปริมาณ/Quantity</th>
                                                    <td mat-cell *matCellDef="let element"> {{element.quantity}}
                                                        <br>
                                                        {{element.quantityUnit}}
                                                    </td>
                                                    <td mat-cell *matCellDef="let element"> </td>
                                                    <td mat-footer-cell *matFooterCellDef
                                                        [innerHTML]="sumQuantityJobRequest[i]" class="my-footer-cell">
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="weight">
                                                    <th mat-header-cell *matHeaderCellDef>น้ำหนัก/N.W</th>
                                                    <td mat-cell *matCellDef="let element"> {{element.weight}}
                                                        <br>{{element.weightUnit}}
                                                    </td>
                                                    <td mat-footer-cell *matFooterCellDef
                                                        [innerHTML]="sumWeightJobRequest[i]" class="my-footer-cell">
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="grossWeight">
                                                    <th mat-header-cell *matHeaderCellDef>น้ำหนัก/G.W</th>
                                                    <td mat-cell *matCellDef="let element"> {{element.grossWeight}}
                                                        <br>
                                                        {{element.grossWeightUnit}}
                                                    </td>
                                                    <td mat-footer-cell *matFooterCellDef
                                                        [innerHTML]="sumGrossWeightJobRequest[i]"
                                                        class="my-footer-cell"></td>
                                                </ng-container>
                                                <ng-container matColumnDef="Action">
                                                    <th mat-header-cell *matHeaderCellDef>Action</th>
                                                    <td mat-cell *matCellDef="let element">
                                                        <button mat-icon-button matTooltip="รายละเอียด"
                                                            (click)="detailGood(element)" matTooltipPosition="above">
                                                            <mat-icon
                                                                svgIcon="heroicons_outline:document-search"></mat-icon>
                                                        </button>
                                                    </td>
                                                    <td mat-footer-cell *matFooterCellDef class="my-footer-cell">
                                                        <mat-checkbox [(ngModel)]="items[i]"></mat-checkbox>
                                                    </td>
                                                </ng-container>

                                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                                <tr mat-footer-row *matFooterRowDef="displayedColumns;"></tr>
                                            </table>

                                        </div>
                                        <div class="flex flex-col mt-5">
                                            <mat-label
                                                *ngIf="d.remark != e.remark || d.remark == e.remark">รายละเอียดเพิ่มเติมท้ายรายการสินค้า
                                                (เดิม)</mat-label>
                                            <mat-label
                                                *ngIf="!(d.remark != e.remark || d.remark == e.remark)">รายละเอียดเพิ่มเติมท้ายรายการสินค้า</mat-label>
                                            <mat-label class="label-remark">{{d.remark}}</mat-label>
                                            <!-- <mat-form-field class="flex-auto">
                                                    <textarea matInput disabled value="{{d.remark}}"></textarea>
                                                </mat-form-field> -->
                                        </div>
                                        <div *ngIf="oldPackId != 0">
                                            <div style="height: 5vh;background-color: #003f80;font-size: 20px;"
                                                class="grid  justify-items-center text-white rounded-t-md mt-5">
                                                <h2
                                                    [class]="e.goodsList != d.goodsList ? 'text-start self-center':'text-start self-center'">
                                                    ข้อมูลรายการสินค้าใหม่</h2>
                                            </div>
                                            <div>
                                                <table mat-table [dataSource]="e.goodsList"
                                                    [class]="e.goodsList !=d.goodsList ? 'mat-elevation-z8':'mat-elevation-z8'">
                                                    <ng-container matColumnDef="position">
                                                        <th mat-header-cell *matHeaderCellDef>ลำดับ</th>
                                                        <td mat-cell *matCellDef="let element;let i =index"> {{i+1}}
                                                        </td>
                                                        <td mat-footer-cell *matFooterCellDef class="my-footer-cell">
                                                            SUMMARY
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="markItem">
                                                        <th mat-header-cell *matHeaderCellDef>เครื่องหมายหีบห่อ/Mark
                                                        </th>
                                                        <td mat-cell *matCellDef="let element;let i =index"
                                                            [class]="checkMarkEdit(element, d.goodsList, i) ? 'red' : ''"
                                                            style="word-break: break-all;"> {{element.markItem}}
                                                        </td>
                                                        <td mat-footer-cell *matFooterCellDef class="my-footer-cell">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="description">
                                                        <th mat-header-cell *matHeaderCellDef>ชื่อสินค้า/Description
                                                        </th>
                                                        <td mat-cell *matCellDef="let element;let i =index"
                                                            [class]="checkDescriptionEdit(element, d.goodsList, i) ? 'red' : ''"
                                                            style="word-break: break-all;">
                                                            {{element.description}}
                                                        </td>
                                                        <td mat-footer-cell *matFooterCellDef class="my-footer-cell">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="quantity">
                                                        <th mat-header-cell *matHeaderCellDef>ปริมาณ/Quantity</th>
                                                        <td mat-cell *matCellDef="let element;let i =index"
                                                            [class]="checkQuantityEdit(element, d.goodsList, i) ? 'red' : ''">
                                                            {{element.quantity}}
                                                            <br>
                                                            {{element.quantityUnit}}
                                                        </td>
                                                        <td mat-cell *matCellDef="let element"> </td>
                                                        <td mat-footer-cell *matFooterCellDef
                                                            [innerHTML]="sumQuantity[i]"
                                                            [class]="sumQuantity[i] != sumQuantityJobRequest[i]? 'my-footer-cell red' : 'my-footer-cell'">
                                                        </td>
                                                    </ng-container>

                                                    <ng-container matColumnDef="weight">
                                                        <th mat-header-cell *matHeaderCellDef>น้ำหนัก/N.W</th>
                                                        <td mat-cell *matCellDef="let element;let i =index"
                                                            [class]="checkNwEdit(element, d.goodsList, i) ? 'red' : ''">
                                                            {{element.weight}}
                                                            <br>{{element.weightUnit}}
                                                        </td>
                                                        <td mat-footer-cell *matFooterCellDef [innerHTML]="sumWeight[i]"
                                                            [class]="sumWeight[i] != sumWeightJobRequest[i]? 'my-footer-cell red' : 'my-footer-cell'">
                                                        </td>
                                                    </ng-container>

                                                    <ng-container matColumnDef="grossWeight">
                                                        <th mat-header-cell *matHeaderCellDef>น้ำหนัก/G.W</th>
                                                        <td mat-cell *matCellDef="let element;let i =index"
                                                            [class]="checkGwEdit(element, d.goodsList, i) ? 'red' : ''">
                                                            {{element.grossWeight}} <br>
                                                            {{element.grossWeightUnit}} </td>
                                                        <td mat-footer-cell *matFooterCellDef
                                                            [innerHTML]="sumGrossWeight[i]"
                                                            [class]="sumGrossWeight[i] != sumGrossWeightJobRequest[i]? 'my-footer-cell red' : 'my-footer-cell'">
                                                        </td>
                                                    </ng-container>

                                                    <ng-container matColumnDef="Action">
                                                        <th mat-header-cell *matHeaderCellDef>Action</th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <button mat-icon-button matTooltip="รายละเอียด"
                                                                (click)="detailGood(element)"
                                                                matTooltipPosition="above">
                                                                <mat-icon
                                                                    svgIcon="heroicons_outline:document-search"></mat-icon>
                                                            </button>
                                                        </td>
                                                        <td mat-footer-cell *matFooterCellDef class="my-footer-cell">

                                                        </td>
                                                    </ng-container>

                                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                                    </tr>
                                                    <tr mat-footer-row *matFooterRowDef="displayedColumns;"></tr>
                                                </table>


                                            </div>
                                            <div class="flex flex-col mt-5">
                                                <mat-label
                                                    [class]="d.remark != e.remark ? 'red' : ''">รายละเอียดเพิ่มเติมท้ายรายการสินค้า
                                                    (แก้ไข)</mat-label>
                                                <mat-label
                                                    [class]="d.remark != e.remark ? 'red label-remark' : 'label-remark'">{{e.remark}}</mat-label>
                                                <!-- <mat-form-field class="flex-auto">
                                                    <textarea matInput disabled value="{{d.remark}}"></textarea>
                                                </mat-form-field> -->
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-------------------------------------------- Regular Verify data----------------------->
            <div
                *ngIf="(docType == 'CO' || docType == 'CO/Pack') && (oldPackId == null || oldPackId == undefined || oldPackId == 0)">
                <div *ngFor="let d of form.packList;let i = index">
                    <div class="pt-2.5">
                        <div>
                            <div (click)="expandCoPack(i)" class="px-6 flex flex-row justify-between bgheader">
                                <div class="basis-1/2 py-4 txtHrd">
                                    {{form.isCoPack == 'N' ? 'ข้อมูลรายการสินค้า/ Item Description #' +(i+1) : "CO :"
                                    +(i+1)}}
                                </div>
                                <div class="basis-1/2 flex flex-row justify-end py-4 txtHrd">
                                    <span *ngIf="collapseCopack[i]">
                                        <mat-icon svgIcon="mat_outline:arrow_drop_down"></mat-icon>
                                    </span>
                                    <span *ngIf="!collapseCopack[i]">
                                        <mat-icon svgIcon="mat_outline:arrow_drop_up"></mat-icon>
                                    </span>
                                </div>
                            </div>
                            <section class="collapsible" [@collapse]="collapseCopack[i]">
                                <div class="collapsible">
                                    <div style="height: 5vh;background-color: #003f80;font-size: 20px;"
                                        class="grid  justify-items-center text-white rounded-t-md">
                                        <h2 class="text-start  self-center">ข้อมูลใบกำกับสินค้า & Origin สินค้า</h2>
                                    </div>
                                    <div class="flex flex-col border rounded-b-md px-2.5 pt-2.5">
                                        <div class="flex flex-row">
                                            <h4 class="w-1/6 text-start">Invoice No./Date</h4>
                                            <div class="w-3/6 break-words text-start">
                                                <div *ngFor="let a of d.invoiceList">: {{ a.invoiceNo + "/" +
                                                    (a.invoiceDate | date: "yyyy-MM-dd" )}}
                                                </div>
                                            </div>
                                            <div class="flex flex-auto break-words text-start">
                                                <div *ngIf="form.invoiceChkForm=='Y'">
                                                    <mat-checkbox class="example-margin"
                                                        [(ngModel)]="form.invoiceChkForm" name="invoiceChkForm"
                                                        #invoiceChkForm="ngModel" [disabled]="true">
                                                        ไม่ปรากฏบนฟอร์ม</mat-checkbox>
                                                </div>
                                            </div>
                                            <mat-checkbox class="w-1/6 justify-end" [(ngModel)]="invoice[i]">
                                            </mat-checkbox>
                                        </div>

                                        <div class="flex flex-row">
                                            <h4 class="w-1/6 text-start">Country of Origin</h4>
                                            <h4 class="w-4/6 break-words text-start">: {{d.countryOfOrigin}}</h4>
                                            <mat-checkbox class="w-1/6 justify-end" [(ngModel)]="countryOfOrigin[i]">
                                            </mat-checkbox>
                                        </div>

                                    </div>

                                    <div style="height: 5vh;background-color: #003f80;font-size: 20px;"
                                        class="grid  justify-items-center text-white rounded-t-md mt-5">
                                        <h2 class="text-start self-center">ข้อมูลรายการสินค้า</h2>
                                    </div>

                                    <table mat-table [dataSource]="d.goodsList" class="mat-elevation-z8">
                                        <ng-container matColumnDef="position">
                                            <th mat-header-cell *matHeaderCellDef>ลำดับ</th>
                                            <td mat-cell *matCellDef="let element;let i = index"> {{i+1}} </td>
                                            <td mat-footer-cell *matFooterCellDef class="my-footer-cell">SUMMARY
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="markItem">
                                            <th mat-header-cell *matHeaderCellDef>เครื่องหมายหีบห่อ/ Mark</th>
                                            <td mat-cell *matCellDef="let element" style="word-break: break-all;">
                                                {{element.markItem}} </td>
                                            <td mat-footer-cell *matFooterCellDef class="my-footer-cell"></td>
                                        </ng-container>
                                        <ng-container matColumnDef="description">
                                            <th mat-header-cell *matHeaderCellDef>ชื่อสินค้า/ Description</th>
                                            <td mat-cell *matCellDef="let element" style="word-break: break-all;">
                                                {{element.description}}
                                            </td>
                                            <td mat-footer-cell *matFooterCellDef class="my-footer-cell"></td>
                                        </ng-container>
                                        <ng-container matColumnDef="quantity">
                                            <th mat-header-cell *matHeaderCellDef>ปริมาณ/ Quantity</th>
                                            <td mat-cell *matCellDef="let element"> {{element.quantity}} <br>
                                                {{element.quantityUnit}} </td>
                                            <td mat-cell *matCellDef="let element"> </td>
                                            <td mat-footer-cell *matFooterCellDef [innerHTML]="sumQuantity[i]"
                                                class="my-footer-cell"></td>
                                        </ng-container>

                                        <ng-container matColumnDef="weight">
                                            <th mat-header-cell *matHeaderCellDef>น้ำหนัก/ N.W</th>
                                            <td mat-cell *matCellDef="let element"> {{element.weight}}
                                                <br>{{element.weightUnit}}
                                            </td>
                                            <td mat-footer-cell *matFooterCellDef [innerHTML]="sumWeight[i]"
                                                class="my-footer-cell"></td>
                                        </ng-container>

                                        <ng-container matColumnDef="grossWeight">
                                            <th mat-header-cell *matHeaderCellDef>น้ำหนัก/ G.W</th>
                                            <td mat-cell *matCellDef="let element"> {{element.grossWeight}} <br>
                                                {{element.grossWeightUnit}} </td>
                                            <td mat-footer-cell *matFooterCellDef [innerHTML]="sumGrossWeight[i]"
                                                class="my-footer-cell"></td>
                                        </ng-container>

                                        <ng-container matColumnDef="Action">
                                            <th mat-header-cell *matHeaderCellDef>Action</th>
                                            <td mat-cell *matCellDef="let element">
                                                <button mat-icon-button matTooltip="รายละเอียด"
                                                    (click)="detailGood(element)" matTooltipPosition="above">
                                                    <mat-icon svgIcon="heroicons_outline:document-search"></mat-icon>
                                                </button>
                                            </td>
                                            <td mat-footer-cell *matFooterCellDef class="my-footer-cell">
                                                <mat-checkbox [(ngModel)]="items[i]"></mat-checkbox>
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                        <tr mat-footer-row *matFooterRowDef="displayedColumns;"></tr>
                                    </table>

                                    <div class="flex flex-col mt-5">
                                        <mat-label>รายละเอียดเพิ่มเติมท้ายรายการสินค้า</mat-label>
                                        <mat-label class="label-remark">{{d.remark}}</mat-label>
                                        <!-- <mat-form-field class="flex-auto">
                                                    <textarea matInput disabled value="{{d.remark}}"></textarea>
                                                </mat-form-field> -->
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="form.jobRemark != undefined && form.jobRemark != null && form.jobRemark != ''">
                <div class="flex flex-col">
                    <mat-label>สาเหตุที่ผิดพลาด/หมายเหตุ</mat-label>
                    <mat-label class="label-remark">{{form.jobRemark}}</mat-label>
                    <!-- <mat-form-field class="flex-auto">
                                <textarea matInput disabled value="{{d.remark}}"></textarea>
                            </mat-form-field> -->
                </div>
            </ng-container>

            <!-- company seal & signature -->
            <div class="pt-2.5">
                <div>
                    <div (click)="expand(4)" class="px-6 flex flex-row justify-between bgheader">
                        <div class="basis-1/2 py-4 txtHrd">
                            Company Seal & Signature
                        </div>
                        <div class="basis-1/2 flex flex-row justify-end py-4 txtHrd">
                            <span *ngIf="collapse.signature">
                                <mat-icon svgIcon="mat_outline:arrow_drop_down"></mat-icon>
                            </span>
                            <span *ngIf="!collapse.signature">
                                <mat-icon svgIcon="mat_outline:arrow_drop_up"></mat-icon>
                            </span>
                        </div>
                    </div>
                    <section class="collapsible" [@collapse]="collapse.signature">
                        <div class="collapsible">
                            <div class="flex flex-col">
                                <div class="flex flex-row">
                                    <h4 class="w-1/6 text-start">Company Seal</h4>
                                    <h4 class="w-4/6 break-words text-start">: Exporter</h4>
                                    <mat-checkbox class="w-1/6 justify-end" [(ngModel)]="checkbox.seal">
                                    </mat-checkbox>
                                </div>
                                <div class="flex flex-row">
                                    <div class="w-1/6"></div>
                                    <div class="flex flex-col w-4/6 break-words">
                                        <div *ngFor="let a of form.exporterSealList">
                                            <img class="boxImg border" [src]="a.signPic">
                                        </div>
                                        <div class="mt-5 flex flex col">
                                            <h4 class="self-center">: Shipping</h4>
                                        </div>
                                        <div *ngIf="(form.gId == '3' || form.gId =='8');">
                                            <div *ngFor="let a of form.shippingSealList">
                                                <img class="boxImg border" [src]="a.signPic">
                                            </div>
                                        </div>
                                        <div #seal>
                                            <label>-</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex flex-row mt-5">
                                    <h4 class="w-1/6 text-start">Signature</h4>
                                    <h4 class="w-4/6 break-words text-start">: Exporter</h4>
                                    <mat-checkbox class="w-1/6 justify-end" [(ngModel)]="checkbox.sign">
                                    </mat-checkbox>
                                </div>
                                <div class="flex flex-row">
                                    <div class="w-1/6"></div>
                                    <table class="w-4/6 break-words">
                                        <div class="grid grid-cols-2">
                                            <div *ngFor="let a of form.exporterSignList">
                                                <tr align="center">
                                                    <td class="border">
                                                        <img class="w180" [src]="a.signPic">
                                                    </td>
                                                </tr>
                                                <tr tr align="center">
                                                    <td class="border">
                                                        ({{a.signFname}} {{a.signLname}})
                                                    </td>
                                                </tr>
                                            </div>
                                        </div>
                                    </table>
                                </div>
                                <div class="flex flex-row mt-5">
                                    <h4 class="w-1/6 text-start"></h4>
                                    <h4 class="w-4/6 break-words text-start self-center">: Shipping</h4>
                                </div>
                                <div class="flex flex-row">

                                    <div class="w-1/6"></div>
                                    <table class="w-4/6 break-words"
                                        *ngIf="form.shippingSignList.length > 0 && (form?.gId == '3' || form?.gId =='8')">
                                        <div class="grid grid-cols-2">
                                            <div *ngFor="let a of form.shippingSignList">
                                                <tr align="center">
                                                    <td class="border">
                                                        <img class="w180" src="{{a.signPic}}">
                                                    </td>
                                                </tr>
                                                <tr tr align="center">
                                                    <td class="border">
                                                        ({{a.signFname}} {{a.signLname}})
                                                    </td>
                                                </tr>
                                            </div>
                                        </div>
                                    </table>
                                    <div #sign class="w-4/6"
                                        *ngIf="form.shippingSignList.length == 0 && (form?.gId == '3' || form?.gId =='8')">
                                        <label>-</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <!-- ------------------------ -->
            <div class="flex flex-row items-center mt-4">
                <h4>ผลการอนุมัติ:</h4>
                <mat-radio-group aria-label="Select an option" [(ngModel)]="approveType"
                    (ngModelChange)="rdbApprove($event)">
                    <mat-radio-button value="1">ไม่อนุมัติ</mat-radio-button>
                    <mat-radio-button value="2">อนุมัติ</mat-radio-button>
                    <mat-radio-button value="3">รอตรวจสอบโรงงาน</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </div>

    <app-verify-factory-detail [cId]="form.declarationId"></app-verify-factory-detail>

    <div style="margin-bottom: 30px;">
        <div class="flex flex-row justify-center mt-4 gap-4">
            <button class="set-btn-unlimited flex-none " mat-raised-button color="accent" (click)="sendTo($event)"
                *ngIf="queueStatus === '1' || queueStatus === '2' || (oldPackId !=0 && oldPackId != null && oldPackId != undefined) "
                (keydown.enter)="$event.preventDefault()">โอนงาน</button>
            <button class="set-btn-unlimited flex-none " mat-raised-button color="accent" (click)="save()"
                (keydown.enter)="$event.preventDefault()">บันทึก</button>
            <button class="set-btn-unlimited flex-none " mat-raised-button color="accent" (click)="saveQ()"
                (keydown.enter)="$event.preventDefault()">บันทึกและส่งผลตรวจ</button>
        </div>
    </div>
</mat-card>