import { LiveAnnouncer } from '@angular/cdk/a11y';
import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { ConfigBankPopupComponent } from '../config-bank-inquiry/config-bank-popup/config-bank-popup.component';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'
import { ProvinceManagePopupComponent } from './province-manage-popup/province-manage-popup.component';
import { Router } from '@angular/router';
import { AppMenuService } from 'app/business/service/app/app-menu.service';
import { AppUserService } from 'app/business/service/app/app-user.service';
import { ConfirmDialogService } from '@bundle/component/confirm-dialog/confirm-dialog.service';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
  selector: 'app-province-manage-inquiry',
  templateUrl: './province-manage-inquiry.component.html',
  styleUrls: ['./province-manage-inquiry.component.css']
})
export class ProvinceManageInquiryComponent implements OnInit, AfterViewInit {
  BenefitTarget: any = [];
  form: any = {};
  BenefitTargetValue: string;
  displayedColumns: string[] = ['select', 'provinceCode', 'provinceName', 'provinceEname', 'geoId', 'action'];
  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);
  url: any;
  buttonList: any[] = [];
  itemList: any[];
  userProfile:any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private appMasterService: AppMasterService,
    private alertMessageService: AlertMessageService,
    private router: Router,
    private appMenuService: AppMenuService,
    private appUserService: AppUserService,
    private confirmDialogService: ConfirmDialogService,
    private _authService:AuthService,
  ) { }

  async getRouter(): Promise<void> {
    
    this.url = this.router.url;

    const formData = {
      url: this.url,
      userGroupCode: this.userProfile.userGroupCode,
      userRoleCode: this.userProfile.userRoleCode,
      userCode: this.userProfile.userCode
    };
    this.buttonList = await this.appMenuService.getButtonPermissionPerPages(formData);
    
  }
  
  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find(e => e.buttonCode == buttonCode);

    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }

  async ngOnInit() {
    this.userProfile = JSON.parse(
      this._authService.loginUser
    );
    await this.getRouter();
    this.BenefitTarget = [{ benefitTargetCode: 1, benefitTargetTName: '10' }, { benefitTargetCode: 2, benefitTargetTName: '20' }];
    this.form.isActive = 'A';
    this.loadData();
  }
  loadData() {
    const data = {
      "provinceCode": this.form.provinceCode,
      "provinceName": this.form.provinceName,
      "provinceEname": this.form.provinceName,
      "geoId": this.form.geoId
    };

    this.appMasterService.getMasterProvince(data).then(res => {
      if (res === "error") {
        return;
      } else {
        this.itemList = res;
        this.dataSource = new MatTableDataSource(res)
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        
      }
    })
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  selectionPercent() {

  }

  editItem(e) {
    const data = { ...e };
    const dialogRef = this.dialog.open(ProvinceManagePopupComponent, {
      disableClose: false,
      width: '60vw',
      data: data,
      maxWidth: 'none'
    });

    dialogRef.afterClosed().subscribe(res => {
      this.loadData();
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
    
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();

    if (filterValue?.length > 0) {
      var res = this.itemList.filter(e => (e.provinceCode.toString().includes(filterValue) || e.provinceName.toLowerCase().includes(filterValue) || e.provinceEname.toLowerCase().includes(filterValue)));

      this.dataSource = new MatTableDataSource(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    } else {
      this.dataSource = new MatTableDataSource(this.itemList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  formatCurrency(value: number) {
    return new Intl.NumberFormat('th-TH', {
      style: 'currency',
      currency: 'THB',
      currencyDisplay: "code",
    })
      .format(value)
      .replace("THB", "")
      .trim();
  }

  save() {

  }

  addAccount(data): void {
    const dialogRef = this.dialog.open(ProvinceManagePopupComponent, {
      disableClose: false,
      width: '60vw',
      data: null,
      maxWidth: 'none'
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.loadData();
      }
      this.selection.clear(true);
    });
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  delete(e) {
    this.confirmDialogService.open({ header: "", content: "คุณต้องการลบข้อมูลนี้หรือไม่?" }).then(res => {
      if (res) {
        const data = {
          provinceId: e.provinceId,
          provinceCode: e.provinceCode,
          provinceName: e.provinceName,
          provinceEname: e.provinceEname,
          geoId: e.geoId,
          isActive: "N"
        };

        this.appMasterService.saveProvince(data).then((res: any) => {
          if (res === "error") {
            return;
          } else {
            this.loadData();
          }
        });
      }
    });
  }

  deleteSelection() {
    if (this.selection?.selected?.length > 0) {
      this.confirmDialogService.open({ header: "", content: "คุณต้องการลบข้อมูลนี้หรือไม่?" }).then(res => {
        if (res) {
          var selectedKey = this.selection.selected.map((item, index, chats) => {
            return {
              provinceId: item.provinceId,
              provinceCode: item.provinceCode,
              provinceName: item.provinceName,
              provinceEname: item.provinceEname,
              geoId: item.geoId,
              isActive: "N"
            }
          });

          

          const req = {
            saveList: selectedKey
          };

          this.appMasterService.updateProvinceBatch(req).then(res => {
            if (res === 'error') {
              this.alertMessageService.open({ message: "เกิดข้อผิดพลาดระหว่างบันทึกข้อมูล", title: "ข้อผิดพลาด" });
            } else {
              this.selection.clear(true);
              this.loadData();
            }
          });

        }
      });
    } else {
      this.alertMessageService.open({ message: "กรุณาเลือกรายการที่จะลบข้อมูล", title: "ข้อผิดพลาด" });
    }
  }
}
