<mat-card (keydown.enter)="$event.preventDefault()">
    <mat-card-header>
        <div class="flex justify-between" style="width: 100%;">
            <div class="text-lg"> รายละเอียดคำขอตรวจงานซ้ำ</div>
            <div>
                <button class="close-button" [mat-dialog-close]="true">
                    <mat-icon class="text-white" [mat-dialog-close]>cancel</mat-icon>
                </button>
            </div>
        </div>
    </mat-card-header>

  <mat-dialog-content class="mat-typography pb-5">
    <div class="flex flex-col">
        <div class="flex flex-row">
            <div class="w-2/6">
                <mat-label>เลขที่คำขอตรวจซ้ำ</mat-label>
            </div>
            <div class="w-4/6">
                <mat-label class="pr-2.5"> : </mat-label>{{form.rqNo}}
            </div>
        </div>
        <div class="flex flex-row">
            <div class="w-2/6">
                <mat-label>ชื่อบริษัท</mat-label>
            </div>
            <div class="w-4/6">
                <mat-label class="pr-2.5"> : </mat-label>{{form.declarator}}
            </div>
        </div>
        <div class="flex flex-row">
            <div class="w-2/6">
                <mat-label>เหตุผลของการขอตรวจซ้ำ</mat-label> 
            </div>
            <div class="flex-auto form-field-container">
                <div class="basis-4/6 flex flex-row pr-5">
                    <mat-label class="pr-2.5"> : </mat-label>
                    <mat-form-field class="w-full" appearance="fill">
                    <textarea matInput required rows="3" [(ngModel)]="form.reason" maxlength="255" class="w-full"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="flex flex-row">
            <div class="w-full pr-5">
                <p class="text-end" *ngIf="form.reason?.length !=null">
                    {{form.reason?.length+"/255"}}
                </p>
            </div>
        </div>
        <div class="flex flex-row">
            <div class="w-2/6">
                <mat-label>ผลของการอนุมัติ</mat-label>
            </div>
            <div class="w-4/6">
                <mat-label class="pr-2.5"> : </mat-label>{{form.result}}
            </div>
        </div>
    </div>
  </mat-dialog-content>

    <mat-dialog-actions align="center">
        <button mat-raised-button color="accent" class="w-" (click)="save()" [disabled]="form.reason?.length === 0">
            สร้างคำขอ
        </button>
        <button mat-raised-button color="accent" [mat-dialog-close]>
            กลับ
        </button>
    </mat-dialog-actions>

</mat-card>