import { AppUserService } from "app/business/service/app/app-user.service";
import { Component, OnInit } from "@angular/core";
import { AppNotificationService } from "app/business/service/app/app-notification.service";
import { MatDialog } from "@angular/material/dialog";
import _ from "underscore";
import { NotRegisteredEmailAlertDialogComponent } from "../not-registered-email-alert-dialog/not-registered-email-alert-dialog.component";
import { PasswordChangeComponent } from "./password-change/password-change.component";
import { AuthService } from "app/core/auth/auth.service";

@Component({
  selector: "app-user-profile-inquiry",
  templateUrl: "./user-profile-inquiry.component.html",
  styleUrls: ["./user-profile-inquiry.component.scss"],
})
export class UserProfileComponent implements OnInit {
  user: any = {};
  userProfile: any = {};
  alertMessage: any;
  isAlert: boolean = false;
  isWarn: boolean = false;
  notRegisteredData: any;
  viewPin: boolean = false;
  userPw: any;
  constructor(
    private appUserService: AppUserService,
    private appNotificationService: AppNotificationService,
    private _authService: AuthService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    let data = JSON.parse(this._authService.loginUser);
    this.userProfile.uId = data.uId ? data.uId : data.uid;

    this.userProfile.userName = data.userName;

    this.userProfile.gId = data.gId;
    const loginUser = JSON.parse(localStorage.getItem("loginUser"));
    console.log("uId", loginUser);
    
    const formData = {
      username: this.userProfile.userName,
      uId: this.userProfile.uId ? this.userProfile.uId : loginUser.uid,
    };
    this.appUserService.getUserProfile(formData).then((res) => {
      this.user = res;
    });

    const companyId = parseInt(data.cpId);
    const groupCode = data.userGroupCode;

    if (groupCode != "1" && groupCode != "4" && groupCode != "5") {
      const actionReq = {
        companyId: companyId,
        notificationTypeId: "A",
        listNotificationReadStatus: ["A"],
      };

      this.appNotificationService.getNotificationList(actionReq).then((res) => {
        if (res === "error" || res == null) {
          return;
        } else {
          const alertMessage = _.first(res);

          this.alertMessage = alertMessage;

          if (res.length > 1) {
            const message = res.map((e, i) => {
              return (i + 1 + ") " + e.notificationDescTh) as string;
            });

            this.alertMessage.message = message.join("\n");
          } else {
            this.alertMessage.message = this.alertMessage.notificationDescTh;
          }

          if (this.alertMessage?.notificationStatus === "A") {
            this.isAlert = true;
          } else if (this.alertMessage?.notificationStatus === "W") {
            this.isWarn = true;
          }
        }
      });

      if (groupCode === "3" || groupCode === "8") {
        this.appNotificationService
          .getNotRegisteratedEmailInvoiceList(companyId)
          .then((res) => {
            if (res === "error") {
              return;
            } else {
              if (res?.totalItem > 0) {
                this.notRegisteredData = res;

                this.dialog.open(NotRegisteredEmailAlertDialogComponent, {
                  width: "60vw",
                  data: this.notRegisteredData,
                  disableClose: true,
                });
              }
            }
          });
      }
    }
  }

  changePassword() {
    var dialogRef = this.dialog.open(PasswordChangeComponent, {
      disableClose: true,
      width: "50vw",
      maxWidth: "none",
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.viewPassword(false); 
        const loginUser = JSON.parse(localStorage.getItem("loginUser"));
        console.log("uId", loginUser);
        const formData = {
          username: this.userProfile.userName,
          uId: this.userProfile.uId ? this.userProfile.uId : loginUser.uId,
        };
        this.appUserService.getUserProfile(formData).then((res) => {
          this.user = res;
        });
      }
    });
  }

  viewPassword(bool: boolean) {
    this.viewPin = bool;

    if (bool) {
      var reqData = this.user.passwordHashed;

      reqData.uId = this.userProfile.uId;

      this.appUserService.getUserPassword(reqData).then((res: any) => {
        if (res === "error") {
          this.viewPin = false;
          return;
        } else {
          this.userPw = this.decryptCaesar(res.password, 3);
        }
      });
    } else {
      this.userPw = null;
    }
  }

  decryptCaesar(ciphertext, shift): string {
    let plaintext = "";
    for (let i = 0; i < ciphertext.length; i++) {
      const char = ciphertext[i];
      if (char.match(/[a-z]/i)) {
        const baseChar = char.toUpperCase() === char ? "A" : "a";
        const decryptedChar = String.fromCharCode(
          ((char.charCodeAt(0) - baseChar.charCodeAt(0) - shift + 26) % 26) +
            baseChar.charCodeAt(0)
        );
        plaintext += decryptedChar;
      } else {
        plaintext += char;
      }
    }
    return plaintext;
  }
}
