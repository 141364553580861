
<mat-card-header>
  <div class="flex justify-between" style="width: 100%;">
    <div class="text-lg"> {{data.header || 'ตัวอย่างเอกสาร'}} </div>
    <div>
      <button class="close-button" [mat-dialog-close]="true">
        <mat-icon class="text-white" [mat-dialog-close]>cancel</mat-icon>
      </button>
    </div>
  </div>
</mat-card-header>
<mat-dialog-content class="full-height-dialog">
  <img [src]="data.fullImageUrl" alt="Full Image" class="h-auto  max-w-full">
</mat-dialog-content>