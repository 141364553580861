import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AppBankService } from 'app/business/service/app/app-bank.service';
import { ConfirmPopupComponent } from '../../confirm-popup/confirm-popup.component';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { DxDataGridComponent } from 'devextreme-angular'
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { AppFactoryManagementService } from 'app/business/service/app/app-factorymanagement.service';
import { DxValidatorModule } from 'devextreme-angular';
import _ from 'underscore';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service';
import { CoErrorMarkImagePopupComponent } from '../../create-co-inquiry/co-error-mark-image-popup/co-error-mark-image-popup.component';
import { ImageModalComponent } from '../../create-co-inquiry/Image-Modal/Image-Modal.component';

@Component({
    selector: 'app-factory-history-modal',
    templateUrl: './factory-history-modal.component.html',
    styleUrls: ['./factory-history-modal.component.scss']
})
export class FactoryHistoryModalComponent implements OnInit, AfterViewInit {
    form: any = {
        companyNameTH: '',
        companyNameEN: '',
        contact: '',
        email: '',
        phone: '',
        address: [],
        lockStatus: '',
        grade: '',
        attachments: {},
        id: '',
        companyId: ''
    }
    addrOpt: any;
    bankList: any;
    displayedColumns: string[] = ['date', 'grade', 'attachment' ,'user'];
    dataSource = new MatTableDataSource<any>();
    editItem: boolean
    attachTypeList: any[] = [];
    dataList: any[] = [];
    addressList: any[] = [];
    file: any;
    fileUpload:any;
    value: any[] = [];
    curentDate: Date = new Date;
    reasonList: any[] = [];
    gradeList: any[] = [
        {
            gradeId: 0,
            gradeName: "กรุณาเลือก"
        },
        {
            gradeId: 4,
            gradeName: "ดีเยี่ยม"
        },
        {
            gradeId: 3,
            gradeName: "ดี"
        },
        {
            gradeId: 2,
            gradeName: "พอใช้"
        },
        {
            gradeId: 1,
            gradeName: "ไม่ผ่าน"
        },
    ];

    blacklist: any = false;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

    constructor(
        @Inject(MAT_DIALOG_DATA) data,
        public dialogRef: MatDialogRef<FactoryHistoryModalComponent>,
        public dialog2: MatDialogRef<ConfirmPopupComponent>,
        private dialog: MatDialog,
        private _liveAnnouncer: LiveAnnouncer,
        private appMasterService: AppMasterService,
        private appFactoryManagement: AppFactoryManagementService,
        private alertMessageService: AlertMessageService,
        private alertService: AlertMessageService,
    ) {
        this.form.id = data.cid;
        this.form.companyId = data.companyId;
        this.form.companyNameTH = data.companyNameTh;
        this.form.companyNameEN = data.companyNameEn;
        this.form.contact = data.contactName;
        this.form.email = data.contactEmail;
        this.form.phone = data.contactPhone;
        this.form.companyCode = data.companyCode;
        this.form.attachments = null
        this.addressList = data.addressLists;
        const mainAddressList = this.addressList.filter(e => e.companyAddressType === "M");

        if (mainAddressList !== undefined && mainAddressList.length > 0) {
            const preValue = _.first(mainAddressList);
            this.form.selectAddress = preValue.comAddId;
        } else if (this.addressList.length > 0) {
            const preValue = _.first(this.addressList);
            this.form.selectAddress = preValue.comAddId;
        } else {
            this.form.selectAddress = '';
        }

        this.form.lockStatus = data.blacklistStatus;
        this.form.grade = 0;
    }

    ngOnInit(): void {
        this.loadData();
    }

    loadData() {
        this.appFactoryManagement.getAllReasonLog(this.form.id).then(res => {
            if (res === 'error') {
                this.reasonList = [];
            } else {
                this.reasonList = res;

                if (this.reasonList.length === 0) {
                    this.reasonList.unshift({logId: '', logReason: 'ไม่พบข้อมูล'});
                    this.form.refBlacklistId = '';
                } else if (this.reasonList.length === 1) {
                    const preValue = _.first(this.reasonList);
                    this.form.refBlacklistId = preValue.logId;
                } else {
                    var checkAll = this.reasonList.find(e => e.logReason === 'กรุณาเลือก');
                    
                    if (checkAll === undefined) {
                        this.reasonList.unshift({logId: '', logReason: 'กรุณาเลือก'});
                        this.form.refBlacklistId = '';
                    }
                }
            }
        })

        this.appFactoryManagement.getFactoryGradeHistoryList(this.form.id).then(res => {
            if (res === 'error') {
                return;
            } else {
                this.dataSource = new MatTableDataSource(res);
            }
        })
    }
    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    save() {
        if(this.form.grade == 0){
        this.alertService.open({ message: "กรุณาระบุระดับของบริษัท", title: "" });
        return false
        }
        
        if(!this.form.gradeDate){
            this.alertService.open({ message: "กรุณาระบุวันที่ออกตรวจโรงงาน", title: "" });
            return false
        }

        if(this.form.gradeReason === undefined || this.form.gradeReason === null || this.form.gradeReason.length === 0){
            this.alertService.open({ message: "กรุณาระบุเหตุผลการให้ระดับโรงงาน", title: "" });
            return false
        }

        if (this.blacklist === true) {
            if (this.form.lockStatus === 'N') {
                if (this.form.refBlacklistId === '' || this.form.gradeReason === 0){
                    this.alertService.open({ message: "กรุณาเลือกรายการที่จะปลดล็อค", title: "" });
                    return false
                }    
            } else {
                if (this.form.lockReason === undefined || this.form.lockReason === null || this.form.lockReason.length === 0){
                    this.alertService.open({ message: "กรุณาระบุเหตุผลสถานะการล็อค", title: "" });
                    return false
                }    
            }
        }


        let saveData = {
            cpId: this.form.id,
            companyCode: this.form.companyCode,
            gradeTypeId : this.form.grade,
            gradeName: this.gradeList.find(e => e.gradeId === this.form.grade).gradeName,
            gradeReason : this.form.gradeReason,
            fileAttachment : this.form.attachments,
            factoryAddress : this.addressList.find(e => e.comAddId === this.form.selectAddress).companyAddress,
            verifyFactoryDate : this.form.gradeDate,
            blacklist : this.blacklist,
            lockStatus : this.form.lockStatus,
            lockReason :this.form.lockReason,
            blacklistType: "M",
            refBlacklistId: this.form.lockStatus === 'N' ? this.form.refBlacklistId : null
        }

        this.appFactoryManagement.saveFactoryGrade(saveData).then(res => {
            if (res === 'error') {
                return;
            } else {
                this.dataSource = new MatTableDataSource(res);
                
                this.form.grade = 0;
                this.form.gradeReason = "";
                this.form.attachments = null;
                this.form.gradeDate = null;
                debugger;
                const mainAddressList = this.addressList.filter(e => e.companyAddressType === "M");

                if (mainAddressList !== undefined) {
                    const preValue = _.first(mainAddressList);
                    this.form.selectAddress = preValue.comAddId;
                } else {
                    const preValue = _.first(this.addressList);
                    this.form.selectAddress = preValue.comAddId;
                }        
            }
        });
    }



    announceSortChange(sortState: Sort) {
        if (sortState.direction) {
            this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
        } else {
            this._liveAnnouncer.announce("Sorting cleared");
        }
    }


    pageChanged($event) {

    }

    async upload(): Promise<void> {
        
        if (this.value.length === 0) {
            return
        }
        const file = _.first(this.value);
        debugger;

        if (file.size > 3145728) {
            this.alertMessageService.open({message: "ไฟล์ที่เลือกมีขนาดใหญ่กว่า 3MB กรุณาเลือกไฟล์ใหม่", title:""});
        } else {
            if (file.type == "application/pdf") {
                this.file = file;
                this.onUploadBr();
                this.value = [];    
            } else {
                this.alertMessageService.open({message: "กรุณาอัปโหลดเฉพาะไฟล์ .PDF เท่านั้น", title:""});
            }
        }
    }

    async onFileChangedBr(event): Promise<void> {
        if (event.value.length === 0) {
            return
        }
        // const file = event.value.files[0];
        event.value.forEach((item) => {
            this.file = item
            this.onUploadBr()
        })
        this.value = []
    }

    onUploadBr(): void {
        const formData = new FormData()
        formData.append('image', this.file, this.file.name)
        formData.append('name', 'Attachments');

        this.appMasterService.uploadImageToBlobs(formData).subscribe((result) => {
            if (result) {
                
                const firstRes = _.first(result);
                this.fileUpload = firstRes;
                this.form.attachments = firstRes.filePath;
                
            }
        })
    }

    Removing(): void {
        this.form.attachments = null;
        // this.removeItem(e.data);
    }

    ReasonLockPopup(e) {
        const id = { id: e.id}
        this.appFactoryManagement.GetReasonLog(id).then((res: any) => {
            if (res) {
                
                const ReasonPopup = this.dialog.open(ConfirmPopupComponent, {
                    disableClose: false,
                    // width: '30vw',
                    data: {
                        header: res.reasonLock,
                        button: 'close'
                    },
                    maxWidth: 'none'
                });

                ReasonPopup.afterClosed().subscribe(res => {
                });
            }
            else {
                return
            }
        })
    }

    ReasonGradePopup(e) {
        this.alertMessageService.open({ message: e.gradeReason, title: "" });
    }

    openAttachment(e) {
        let ext = e.fileAttachment.split('.').pop();

        if (ext.toLowerCase() === "jpg" || ext.toLowerCase() === "jpeg" || ext.toLowerCase() === "gif" || ext.toLowerCase() === "png"){
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = false;
            dialogConfig.autoFocus = false;
            dialogConfig.minHeight = '30vh';
            dialogConfig.minWidth = '30vh';
            dialogConfig.maxHeight = '100vh';
            dialogConfig.data = {
              fullImageUrl: e.fileAttachment,
            };
        
            const dialogRef = this.dialog.open(ImageModalComponent, dialogConfig)
        } else {
            window.open(e.fileAttachment, '_blank').focus();
        }
    }
}
