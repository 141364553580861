import { LiveAnnouncer } from '@angular/cdk/a11y'
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort, Sort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { Router } from '@angular/router'
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'
import { IGetListJobRequest } from 'app/business/interface/app/app-job.interface'
import { AppCOService } from 'app/business/service/app/app-co.service'
import { AppRequestService } from 'app/business/service/app/app-request.service'
import { DxDataGridComponent } from 'devextreme-angular'
import moment from 'moment'

@Component({
  selector: 'app-request-job-popup',
  templateUrl: './request-job-popup.component.html',
  styleUrls: ['./request-job-popup.component.scss'],
})
export class RequestJobPopupComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort
  displayedColumns: string[] = [
    'rqNo',
    'thtNo',
    'requestDate',
    'docType',
    'rqStatus',
    'declarator',
    'action',
  ]
  createJobList = new MatTableDataSource<any>()
  count: number
  apiRequest: IGetListJobRequest = {
    thtNo: '',
    rqNo: '',
    pagination: {
      Skip: 0,
      Take: 10,
    },
  }
  searchString: string
  timer: any;
  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private appRequestService: AppRequestService,
    private dialog: MatDialog,
    private alertService: AlertMessageService,
    public dialogRef: MatDialogRef<RequestJobPopupComponent>,
    private router: Router,
  ) {}
  ngOnInit() {
    this.createJobList.paginator = this.paginator
    this.apiRequest.pagination = {
      Skip:
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
      Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
    }
    this.loadData(this.apiRequest)
  }
  ngAfterViewInit() {
    this.paginator.pageIndex = 0
    this.createJobList.paginator = this.paginator
    this.createJobList.sort = this.sort
  }

  async loadData(request: any) {
    if (this.paginator != undefined && this.paginator != null) {
      this.paginator.pageIndex = 0
    }
    
    request.pagination = {
      Skip: 0, // Start from page 1 (skip 0)
      Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
    };

    await this.appRequestService.getDocCreate(request).then((res: any) => {
      
      if (res === 'error') {
        return
      } else {
        if (res !== null) {
          let a = res.response.map((item) => {
            moment.locale('th') // set default locale to Thai
            item.requestDate = moment(item.requestDate).format('YYYY-MM-DD')
            switch (item.rqStatus) {
              case 3:
                item.rqStatus = 'ยังไม่ส่งตรวจ'
                item.action = 3
                break
              case 4:
                item.rqStatus = 'รอตรวจสอบ'
                item.action = 4
                break
              case 40:
                item.rqStatus = 'กำลังตรวจสอบ'
                item.action = 40
                break
              case 5:
                item.rqStatus = 'ผ่านอนุมัติ'
                item.action = 5
                break
              case 10:
                item.rqStatus = 'ไม่ผ่านอนุมัติ'
                item.action = 10
                break
              case 6:
                item.rqStatus = 'ชำระเงินสำเร็จ'
                item.action = 6
                break
              case 7:
                item.rqStatus = 'พิมพ์ใบรับรองแล้ว'
                item.action = 7
                break
              case 38:
                item.rqStatus = 'รอเซ็นสด'
                item.action = 38
                break
              case 8:
                item.rqStatus = 'จบกระบวนการ'
                item.action = 8
                break
              case 9:
                item.rqStatus = 'เจ้าหน้าที่ขอตรวจสอบงานซ้ำ'
                item.action = 9
                break
              case 34:
                item.rqStatus = 'ยกเลิกคำขอแก้ไข'
                item.action = 34
                break
            }
            return item
          })
          this.createJobList.data = a
          this.count = res.totalCount
        } else {
          this.createJobList.data = []
          this.count = 0
        }
      }
    })
    this.createJobList.paginator.length = this.count
  }

  async pageChanged(e) {
    
    this.apiRequest.pagination = {
      Skip: (!e.pageIndex ? 0 : e.pageIndex) * (!e.pageSize ? 0 : e.pageSize),
      Take: !e.pageSize ? 10 : e.pageSize,
    }
    // กำหนด pageIndex
    await this.appRequestService
      .getDocCreate(this.apiRequest)
      .then((res: any) => {
        if (res === 'error') {
          return
        } else {
          if (res !== null) {
            let a = res.response.map((item) => {
              moment.locale('th') // set default locale to Thai
              item.requestDate = moment(item.requestDate).format('YYYY-MM-DD')
              switch (item.rqStatus) {
                case 3:
                  item.rqStatus = 'ยังไม่ส่งตรวจ'
                  item.action = 3
                  break
                case 4:
                  item.rqStatus = 'รอตรวจสอบ'
                  item.action = 4
                  break
                case 40:
                  item.rqStatus = 'กำลังตรวจสอบ'
                  item.action = 40
                  break
                case 5:
                  item.rqStatus = 'ผ่านอนุมัติ'
                  item.action = 5
                  break
                case 10:
                  item.rqStatus = 'ไม่ผ่านอนุมัติ'
                  item.action = 10
                  break
                case 6:
                  item.rqStatus = 'ชำระเงินสำเร็จ'
                  item.action = 6
                  break
                case 7:
                  item.rqStatus = 'พิมพ์ใบรับรองแล้ว'
                  item.action = 7
                  break
                case 38:
                  item.rqStatus = 'รอเซ็นสด'
                  item.action = 38
                  break
                case 8:
                  item.rqStatus = 'จบกระบวนการ'
                  item.action = 8
                  break
                case 9:
                  item.rqStatus = 'เจ้าหน้าที่ขอตรวจสอบงานซ้ำ'
                  item.action = 9
                  break
                case 34:
                  item.rqStatus = 'ยกเลิกคำขอแก้ไข'
                  item.action = 34
                  break
              }
              return item
            })
            this.createJobList.data = a
            this.count = res.totalCount
          } else {
            this.createJobList.data = []
            this.count = 0
          }
        }
      })

    this.createJobList.paginator.length = this.count
    this.createJobList.paginator.pageIndex = e.pageIndex
  }
  editItem(e) {
    this.dialog.closeAll()
  }

  createJob(e) {
    const data = {
      oldPackId: e.packId,
      hId:e.hId
    }
    this.appRequestService.createJobRequest(data).then((res) => {
      if (res!="error") {
        
        this.dialogRef.close();
        this.router.navigate(['/page/request/list-job-edit'], {
          queryParams: {
            oldPackId: res.oldPackid,
            newPackId: res.newPackid,
            docType: e.docType,
          },
        })
      } else {
        return
      }
    })
  }
  applyFilter(event: string) {
    clearTimeout(this.timer); // Clear any existing timer
    this.timer = setTimeout(() => {
    this.apiRequest.thtNo = event
    this.apiRequest.rqNo = event
    this.createJobList.paginator = this.paginator
    this.loadData(this.apiRequest)}, 500);
  }
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`)
    } else {
      this._liveAnnouncer.announce('Sorting cleared')
    }
  }
}
