<mat-card>
  <mat-card-header>
    <div class="flex justify-between" style="width: 100%;">
      <div class="text-lg"> เพิ่ม/แก้ไข กลุ่มผู้ใช้งานระบบ </div>
      <div>
        <button class="close-button" [mat-dialog-close]="true">
          <mat-icon class="text-white" [mat-dialog-close]>cancel</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-header>
<mat-dialog-content class="mat-typography">
  <!-- {{dataUser | json}} -->
  <app-user-group-form [dataInput]="dataUserGroup" (userGroupFormEmit)="userGroupFormEmit($event)"></app-user-group-form>
</mat-dialog-content>
<div class="flex-row flex justify-center gap-2.5" style="margin-bottom: 20px;">
  <button mat-raised-button color="accent" (click)="userGroupFormSubmit()"> บันทึก</button>
  <button mat-raised-button  class= "default-button" mat-dialog-close>ปิด</button>
</div>
