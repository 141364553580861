import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AppBankService } from 'app/business/service/app/app-bank.service';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { ConfirmPopupComponent } from '../../confirm-popup/confirm-popup.component';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'

@Component({
  selector: 'app-kind-export-manage-popup',
  templateUrl: './kind-export-manage-popup.component.html',
  styleUrls: ['./kind-export-manage-popup.component.css']
})
export class KindExportManagePopupComponent implements OnInit {
  form: any = {};
  bankList: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef: MatDialogRef<KindExportManagePopupComponent>,
    private appMasterService: AppMasterService,
    private dialog: MatDialog,
    private alertMessageService: AlertMessageService
  ) {
    if (data) {
      this.form = data;
    } else {
      this.form.isActive = 'Y';
    }
    
  }
  ngOnInit(): void {

  }

  save() {
    
    if ((this.form.koeName !== undefined && this.form.koeName !== null && this.form.koeName !== "") && (this.form.koeEname !== undefined && this.form.koeEname !== null && this.form.koeEname !== "")) {
      const data = {
        "id": this.form.id,
        "koeName": this.form.koeName,
        "koeEname": this.form.koeEname,
        "isActive": this.form.isActive
      };

      this.appMasterService.saveMasterKindOfExportation(data).then((res: any) => {
        if (res === "error") {
          this.alertMessageService.open({message: "บันทึกข้อมูลไม่สำเร็จ", title:""});
        } else {
          this.dialogRef.close();
          this.alertMessageService.open({message: "บันทึกข้อมูลสำเร็จ", title:""});
        }
      });
    } else {
      this.alertMessageService.open({ message: "กรุณากรอกข้อมูลให้ครบ", title: "ข้อผิดพลาด" });
    }
  }

  onChange(e) {
    if (e.checked == true) {
      this.form.isActive = "Y";
    } else {
      this.form.isActive = "N";
    }
  }

  endPopup(e) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      disableClose: false,
      // width: '30vw',
      data: {
        header: e,
        button: 'close'
      },
      maxWidth: 'none'
    });

    dialogRef.afterClosed().subscribe(res => {
      this.dialogRef.close();
    });
  }
}
