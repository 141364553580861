import { Component, Inject } from '@angular/core'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { AppMasterService } from 'app/business/service/app/app-master.service'
import { ConfirmPopupComponent } from '../../confirm-popup/confirm-popup.component'
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'
@Component({
  selector: 'app-exchange-rate-popup',
  templateUrl: './exchange-rate-popup.component.html',
  styleUrls: ['./exchange-rate-popup.component.scss'],
})
export class ExchangeRatePopupComponent {
  form: any = {
    createDate: new Date(Date.now()).toISOString().split('T')[0],
    isActive: 'Y',
  }
  currencyList: any = []
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public apiMasterService: AppMasterService,
    public dialogRef: MatDialogRef<ExchangeRatePopupComponent>,
    private dialog: MatDialog,
    private alertMessageService: AlertMessageService
  ) {
    this.form = data.form

    if (this.form.currency === undefined || this.form.currency === null) {
      this.form.currency = "USD";
    }
  }
  cancel() {
    this.dialogRef.close();
  }
  
  save() {
    
    if ((this.form.currency !== undefined && this.form.currency !== null && this.form.currency !== "") && (this.form.rate !== undefined && this.form.rate !== null && this.form.rate !== "") && (this.form.startDate !== undefined && this.form.startDate !== null && this.form.startDate !== "")) {
      this.apiMasterService.saveExchangeRate(this.form).then((res) => {
        if (res == 'error') {
          return
        }
        else {
          this.dialogRef.close(true)
          this.alertMessageService.open({message: "บันทึกข้อมูลสำเร็จ", title:""});
        }
      })
    } else {
      this.alertMessageService.open({ message: "กรุณากรอกข้อมูลให้ครบ", title: "ข้อผิดพลาด" });
    }
  }

  onChange(e) {
    if (e.checked == true) {
      this.form.isActive = 'Y'
    } else {
      this.form.isActive = 'N'
    }
  }
}
