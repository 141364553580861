<div style="display: flex;flex-flow: column;height: 100%;gap: 30px;">
    <div style="margin-top: 30px;" class="flex flex-col pl-10 pr-10">
        <div class="menu">
            <div style="display: flex;flex-flow: row;gap: 10px;align-items: stretch;">
                <button *ngIf="(checkButton('BT020')) && (!checkJob) && (!isBlacklisted)" mat-raised-button
                    color="accent" (click)="createJob()">
                    <div class="flex items-center">
                        <span>สร้างใบคำขอแก้ไข</span>
                    </div>
                </button>
                <button mat-raised-button color="accent" (click)="onRefresh()">
                    <div class="flex items-center">
                        <span class="icon-text"><mat-icon
                                svgIcon="heroicons_outline:refresh"></mat-icon>รีเฟรชข้อมูล</span>
                    </div>
                </button>
            </div>
            <div style="display: flex;flex-flow: row;gap: 10px;flex: 1;justify-content: flex-end;">
                <div class="mat-button" style="gap: 10px;flex: 0 40%;">
                    <mat-form-field style="display: flex;flex-flow: row;flex: 1" class="my-form-field">
                        <input autocomplete="off" type="text" matInput placeholder="Request No. / เลขที่ THT" matInput
                            #input [(ngModel)]="searchForm.searchString" name="searchText" #searchText="ngModel"
                            (keyup)="applyFilter($event)">
                        <button matSuffix mat-icon-button aria-label="ค้นหา">
                            <mat-icon>search</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
                <button mat-raised-button color="accent" [matMenuTriggerFor]="menu">
                    <div style="display: flex;align-items: center;">
                        <span>เงื่อนไขการค้นหา</span>
                        <mat-icon>expand_more</mat-icon>
                    </div>
                </button>
                <mat-menu #menu="matMenu" xPosition="before" class="customize-menu-panel">
                    <div style="display: flex;flex-flow: column;gap: 15px;" class="pl-2 pr-2"
                        (click)="stopPropagation($event)">
                        <div class="mat-button p-2" style="gap: 10px;">
                            <div class="flex-col basis-1/2">
                                <mat-form-field appearance="fill" class="w-full">
                                    <mat-label>วันที่เริ่มต้น | Start Date
                                    </mat-label>
                                    <input matInput [matDatepicker]="startDate" (click)="startDate.open()" readonly
                                        class="cursor-pointer" type="datetime" [(ngModel)]="searchForm.startDate"
                                        [max]="myDate" name="startDateSearch" (dateInput)="datecheck($event)"
                                        #startDateSearch="ngModel" class="cursor-pointer">
                                    <mat-hint>MM/DD/YYYY</mat-hint>
                                    <mat-datepicker-toggle matIconSuffix [for]="startDate"></mat-datepicker-toggle>
                                    <mat-datepicker #startDate></mat-datepicker>
                                </mat-form-field>
                                <mat-error *ngIf="checkStartDate == false"
                                    class="p-1 text-sm text-red-800 absolute">วันที่เริ่มต้นไม่ถูกต้อง</mat-error>
                            </div>
                            <div class="flex-col basis-1/2">
                                <mat-form-field appearance="fill" class="w-full">
                                    <mat-label>วันที่สิ้นสุด | End Date</mat-label>
                                    <input matInput [matDatepicker]="endDate"
                                        (click)="endDate.open(); checkEndDate = true" readonly type="datetime"
                                        [(ngModel)]="searchForm.endDate" [max]="myDate" [min]="searchForm.startDate"
                                        (dateInput)="endDateCheck($event)" name="endDateSearch" #endDateSearch="ngModel"
                                        class="cursor-pointer">
                                    <mat-hint>MM/DD/YYYY</mat-hint>
                                    <mat-datepicker-toggle matIconSuffix [for]="endDate"></mat-datepicker-toggle>
                                    <mat-datepicker #endDate></mat-datepicker>
                                </mat-form-field>
                                <mat-error *ngIf="checkEndDate == false"
                                    class="p-1 text-sm text-red-800 absolute">วันที่สิ้นสุดไม่ถูกต้อง</mat-error>
                            </div>
                        </div>
                        <div class="mat-button p-2" style="gap: 10px;">
                            <mat-form-field style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>เลขที่ใบคำขอ | Request No.</mat-label>
                                <input autocomplete="off" type="text" matInput placeholder="เลขที่ใบคำขอ" matInput
                                    #input [(ngModel)]="searchForm.rqNo" name="requestNo" #requestNo="ngModel">
                            </mat-form-field>
                            <mat-form-field style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>เลขที่ THT | Certificate No.</mat-label>
                                <input autocomplete="off" type="text" matInput placeholder="เลขที่ THT" matInput #input
                                    [(ngModel)]="searchForm.thtNo" name="certificateNo" #certificateNo="ngModel">
                            </mat-form-field>
                        </div>
                        <div class="flex justify-end pt-2">
                            <div>
                                <button mat-raised-button (click)="clearOption()"
                                    style="flex:0 50%; margin-right: 10px;">
                                    เคลียร์
                                </button>
                                <button mat-raised-button color="accent" (click)="saveSearch()" style="flex:0 50%;">
                                    ค้นหา
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-menu>
            </div>
        </div>
    </div>
    <div class="mat-elevation-z8">
        <table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">
            <ng-container matColumnDef="docType">
                <th mat-header-cell *matHeaderCellDef class="text-left">ประเภทเอกสาร</th>
                <td mat-cell *matCellDef="let element" class="text-left"> {{element.docType}} </td>
            </ng-container>

            <ng-container matColumnDef="requestNo">
                <th mat-header-cell *matHeaderCellDef class="text-left"> Request No.</th>
                <td mat-cell *matCellDef="let element" class="text-left"> {{element.rqNo}} </td>
            </ng-container>

            <ng-container matColumnDef="thtNoOld">
                <th mat-header-cell *matHeaderCellDef class="text-left"> เลขที่ THT เก่า
                </th>
                <td mat-cell *matCellDef="let element" class="text-left">
                    {{element.thtNoOld}}
                </td>
            </ng-container>

            <ng-container matColumnDef="thtNoNew">
                <th mat-header-cell *matHeaderCellDef class="text-left"> เลขที่ THT ใหม่
                </th>
                <td mat-cell *matCellDef="let element" class="text-left">
                    {{element.thtNoNew}}
                </td>
            </ng-container>

            <ng-container matColumnDef="requestDate">
                <th mat-header-cell *matHeaderCellDef class="text-left"> วันที่ยื่นคำขอ </th>
                <td mat-cell *matCellDef="let element" class="text-left">
                    {{element.requestDate | date:'yyyy-MM-dd'}} <br>
                    {{element.requestDate | date:'HH:mm'}}
                 </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="refNo" >
                <th mat-header-cell  *matHeaderCellDef class="text-left"> Invoice
                    No./ Reference No.
                </th>
                <td mat-cell *matCellDef="let element" class="text-left">
                    {{element.refNo}}
                </td>
            </ng-container>

            <ng-container matColumnDef="rqStatus">
                <th mat-header-cell *matHeaderCellDef class="text-left"> สถานะคำขอ </th>
                <td mat-cell *matCellDef="let element" class="text-left">
                    {{element.rqStatusName}} </td>
            </ng-container>

            <ng-container matColumnDef="action" >
                <th mat-header-cell *matHeaderCellDef class="text-left" > Action </th>
                <td mat-cell *matCellDef="let element;let i = index" class="text-left">
                    <div class="flex">
                        <div *ngIf="checkButton('BT021')">
                            <button *ngIf="element.rqStatus == 3||element.rqStatus == 10" mat-icon-button
                                matTooltip="แก้ไข" (click)="editItem(element)">
                                <mat-icon svgIcon="heroicons_outline:pencil-alt"></mat-icon>
                            </button>
                        </div>
                        <div *ngIf="checkButton('BT022')">
                            <button mat-icon-button matTooltip="รายละเอียด"
                                *ngIf="element.rqStatus == 4 || element.rqStatus == 40 || element.rqStatus == 5 || element.rqStatus == 6 
                                || element.rqStatus == 7 || element.rqStatus == 9 || element.rqStatus == 34 || element.rqStatus == 38 || element.rqStatus == 8  || element.rqStatus == 24 "
                                matTooltipPosition="above" [queryParams]="{
                                        oldPackId: element.oldPackId,
                                        newPackId: element.newPackId,
                                        docType: element.docType,
                                        isJobDetail : true
                                        }" routerLink="/page/request/list-job-edit">
                                <mat-icon svgIcon="heroicons_outline:document-text"></mat-icon>
                            </button>
                        </div>
                        <div *ngIf="checkButton('BT023')">
                            <button mat-icon-button matTooltip="ยกเลิกส่งตรวจ" (click)="cancel(element)"
                                matTooltipPosition="above"
                                *ngIf="element.rqStatus == '4'||element.systemQstatus =='2' ">
                                <mat-icon svgIcon="cancel_schedule_send"></mat-icon>
                            </button>
                        </div>
                        <div *ngIf="checkButton('BT024')">
                            <button mat-icon-button matTooltip="พิมพ์" (click)="print(element)"
                                matTooltipPosition="above"
                                *ngIf="(element.rqStatus ==3 && (element.docType == 'CO' || element.docType == 'CO/Pack' ))
                                || (element.rqStatus ==4 && (element.docType == 'CO' ||  element.docType == 'CO/Pack' ))
                                || (element.rqStatus == '40' && (element.docType == 'CO' || element.docType == 'CO/Pack' ))
                                || (element.rqStatus == '10'&& (element.docType == 'CO' || element.docType == 'CO/Pack' ))
                                || element.rqStatus == '6'">
                                <mat-icon svgIcon="heroicons_outline:printer"></mat-icon>
                            </button>
                        </div>
                        <div *ngIf="checkButton('BT179')">
                            <button mat-icon-button matTooltip="เปลี่ยน" (click)="openPopupSendPrintTostaff(element)"
                                matTooltipPosition="above" *ngIf="element.selfPrint == 'Y' && element.rqStatus == '6'">
                                <mat-icon svgIcon="mat_outline:local_post_office"></mat-icon>
                            </button>
                        </div>
                        <div *ngIf="checkButton('BT025')">
                            <button mat-icon-button matTooltip="ลบ" *ngIf="element.rqStatus ==3||element.rqStatus == 10"
                                (click)="deleteItem(element)" matTooltipPosition="above">
                                <mat-icon svgIcon="heroicons_outline:trash"></mat-icon>
                            </button>
                        </div>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons
            aria-label="Select page of periodic elements" (page)="pageChanged($event)">
        </mat-paginator>
    </div>
</div>