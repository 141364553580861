import { Component, OnInit, Inject, NgModule } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogInputModel } from './confirm-dialog-model';

@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogInputModel
  ) {
    data.confirmText = data.confirmText != undefined && data.confirmText != null ? data.confirmText : "ยืนยัน";
    data.cancelText = data.cancelText != undefined && data.cancelText != null ? data.cancelText : "ปิด";
    data.showCancelBtn = data.showCancelBtn != undefined && data.showCancelBtn != null ? data.showCancelBtn : true;
  }

  onEnterKey(event: KeyboardEvent) {
    if (event.key === 'Enter') {
    event.preventDefault();
    // ทำสิ่งที่คุณต้องการทำเมื่อกดปุ่ม Enter
  }
  }
}
