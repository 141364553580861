<div class="flex flex-col h-full gap-4" style="padding-left: 6em; padding-right: 6em;">
  <div style="gap: 30px;" class="flex justify-items-stretch">
    <div class="flex flex-col flex-1 grid-col-1 justify-between" style="gap: 30px;">
      <!-- User Information -->
      <mat-card>
        <mat-card-header style="height:50px">
          <div class="text-center">รายละเอียดผู้ยื่นขอใช้งาน | USER INFORMATION</div>
        </mat-card-header>
        <mat-card-content style="padding: 30px 30px 30px 30px;">
          <div class="flex gap-x row-items">
            <div class="flex flex50-item">
              <div class="w-1/4 flex flex-col">
                <h4>ชื่อ</h4>
                <h4>Name</h4>
              </div>
              <mat-form-field class="flex-auto">
                <input matInput placeholder="ชื่อ" [(ngModel)]="form.name" [ngModelOptions]="{standalone: true}"
                  [disabled]="isMREnabled">
              </mat-form-field>
            </div>
            <div class="flex flex50-item">
              <div class="w-1/4 flex flex-col">
                <h4>นามสกุล</h4>
                <h4>Last Name</h4>
              </div>
              <mat-form-field class="flex-auto">
                <input matInput placeholder="นามสกุล" [(ngModel)]="form.lastName" [ngModelOptions]="{standalone: true}"
                  [disabled]="isMREnabled">
              </mat-form-field>
            </div>
          </div>
          <div class="flex gap-x gap-y row-items">
            <div class="flex flex50-item">
              <div class="w-1/4 flex flex-col">
                <h4>เลขประจำตัวประชาชน</h4>
                <h4>Citizen ID</h4>
              </div>
              <mat-form-field class="flex-auto">
                <input matInput placeholder="เลขประจำตัวประชาชน" [(ngModel)]="form.citizenId"
                  [ngModelOptions]="{standalone: true}" [disabled]="isMREnabled">
              </mat-form-field>
            </div>
            <div class="flex flex50-item">
              <div class="w-1/4 flex flex-col">
                <h4>ตำแหน่งหน้าที่</h4>
                <h4>Position</h4>
              </div>
              <mat-form-field class="flex-auto">
                <input matInput placeholder="ตำแหน่งหน้าที่" [(ngModel)]="form.position"
                  [ngModelOptions]="{standalone: true}" [disabled]="isMREnabled">
              </mat-form-field>
            </div>
          </div>
          <div class="flex gap-x gap-y row-items">
            <div class="flex flex50-item">
              <div class="w-1/4 flex flex-col">
                <h4>โทรศัพท์</h4>
                <h4>Telephone Number</h4>
              </div>
              <mat-form-field class="flex-auto">
                <input matInput placeholder="โทรศัพท์" [(ngModel)]="form.phoneNo" [ngModelOptions]="{standalone: true}"
                  [disabled]="isMREnabled">
              </mat-form-field>
            </div>
            <div class="flex flex50-item">
              <div class="w-1/4 flex flex-col">
                <h4>อีเมล์</h4>
                <h4>E-Mail</h4>
              </div>
              <mat-form-field class="flex-auto">
                <input matInput placeholder="อีเมล์" [(ngModel)]="form.email" [ngModelOptions]="{standalone: true}"
                  [disabled]="isMREnabled">
              </mat-form-field>
            </div>
          </div>
          <div class="flex gap-x gap-y row-items">
            <div class="flex flex50-item">
              <div class="w-1/4 flex flex-col">
                <h4>บริษัทที่ปฏิบัติงาน</h4>
                <h4>Company</h4>
              </div>
              <mat-form-field class="flex-auto">
                <input matInput placeholder="บริษัทที่ปฏิบัติงาน" [(ngModel)]="form.companyName"
                  [ngModelOptions]="{standalone: true}" [disabled]="isMREnabled">
              </mat-form-field>
            </div>
            <div class="flex flex50-item">
            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <!-- Company Information -->
      <mat-card>
        <mat-card-header style="height:50px">
          <div class="text-center">ข้อมูลบริษัท / ห้างฯ | COMPANY INFORMATION</div>
        </mat-card-header>
        <mat-card-content style="padding: 30px 30px 30px 30px;">
          <div class="flex gap-x row-items">
            <div class="flex flex50-item">
              <div class="w-1/4 flex flex-col">
                <h4>ชื่อบริษัท (ไทย)</h4>
                <h4>Company Name (Th)</h4>
              </div>
              <mat-form-field class="flex-auto">
                <input matInput placeholder="ชื่อบริษัท (ไทย)" [(ngModel)]="form.companyNameTh"
                  [ngModelOptions]="{standalone: true}" [disabled]="isMREnabled">
              </mat-form-field>
            </div>
            <div class="flex flex50-item">
              <div class="w-1/4 flex flex-col">
                <h4>ชื่อบริษัท (อังกฤษ)</h4>
                <h4>Company Name (En)</h4>
              </div>
              <mat-form-field class="flex-auto">
                <input matInput placeholder="ชื่อบริษัท (อังกฤษ)" [(ngModel)]="form.companyNameEn"
                  [ngModelOptions]="{standalone: true}" [disabled]="isMREnabled">
              </mat-form-field>
            </div>
          </div>
          <div class="flex gap-x gap-y row-items">
            <div class="flex flex50-item">
              <div class="w-1/4 flex flex-col">
                <h4>เลขทะเบียนนิติบุคคล</h4>
                <h4>NID</h4>
              </div>
              <mat-form-field class="flex-auto">
                <input matInput placeholder="เลขทะเบียนนิติบุคคล" [(ngModel)]="form.nId"
                  [ngModelOptions]="{standalone: true}" [disabled]="isMREnabled">
              </mat-form-field>
            </div>
            <div class="flex flex50-item">
              <div class="w-1/4 flex flex-col">
                <h4>วันที่จดทะเบียน</h4>
                <h4>NID Date</h4>
              </div>
              <mat-form-field class="flex-auto">
                <input matInput [matDatepicker]="picknid" placeholder="วันที่จดทะเบียน" [(ngModel)]="form.nIdDate"
                [ngModelOptions]="{standalone: true}" readonly (click)="picknid.open()"
                [disabled]="isMREnabled" required="true" class="cursor-pointer">
                <mat-datepicker-toggle matIconSuffix [for]="picknid"></mat-datepicker-toggle>
                <mat-datepicker #picknid></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <div class="flex gap-x gap-y row-items">
            <div class="flex flex50-item">
              <div class="w-1/4 flex flex-col">
                <h4>ประกอบกิจการ</h4>
                <h4>Operation on</h4>
              </div>
              <mat-form-field class="flex-auto">
                <mat-select placeholder="ประกอบกิจการ" [(ngModel)]="form.operationOn" name="status" #status="ngModel"
                  [disabled]="isMREnabled">
                  <mat-option *ngFor="let ctl of companyTypeList" [value]="ctl.companyTypeId">
                    {{ctl.companyTypeName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="flex flex50-item">
              <div class="w-1/4 flex flex-col">
                <h4>วันหมดอายุสมาชิก</h4>
                <h4>Expire Date</h4>
              </div>
              <mat-form-field class="flex-auto">
                <input matInput [matDatepicker]="pickexp" placeholder="วันหมดอายุสมาชิก" [(ngModel)]="form.expireDate"
                [ngModelOptions]="{standalone: true}" readonly (click)="pickexp.open()"
                [disabled]="isMREnabled" required="true" class="cursor-pointer">
                <mat-datepicker-toggle matIconSuffix [for]="pickexp"></mat-datepicker-toggle>
                <mat-datepicker #pickexp></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <div class="flex gap-x gap-y row-items">
            <div class="flex flex50-item">
              <div class="w-1/4 flex flex-col">
                <h4>อีเมล์</h4>
                <h4>E-Mail</h4>
              </div>
              <mat-form-field class="flex-auto">
                <input matInput placeholder="อีเมล์" [(ngModel)]="form.companyEmail"
                  [ngModelOptions]="{standalone: true}" [disabled]="isMREnabled">
              </mat-form-field>
            </div>
            <div class="flex flex50-item">
              <div class="w-1/4 flex flex-col">
                <h4>อนุญาตให้ตัดจ่าย E-Payment</h4>
              </div>
              <mat-form-field class="flex-auto">
                <mat-select placeholder="อนุญาตให้ตัดจ่าย E-Payment" [(ngModel)]="form.isAllowEpayment" name="status"
                  #status="ngModel">
                  <mat-option *ngFor="let item of isEpayment" [value]="item.value">
                    {{item.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="flex gap-x gap-y row-items">
            <div class="flex flex50-item">
            </div>
            <div class="flex flex50-item">
              <!-- <div class="w-1/4 flex flex-col">
                <h4>แนะนำโดยหอการค้าจังหวัด</h4>
              </div>
              <mat-form-field class="flex-auto">
                <mat-select placeholder="แนะนำโดยหอการค้าจังหวัด" [(ngModel)]="form.recommendedBy" name="provinces"
                  #status="ngModel">
                  <mat-option *ngFor="let item of provinceList" [value]="item.provinceId">
                    {{item.provinceName}}
                  </mat-option>
                </mat-select>
              </mat-form-field> -->
            </div>
          </div>
          <div class="flex gap-x gap-y row-items">
            <div class="flex flex50-item">
              <mat-checkbox [(ngModel)]="form.isAllowETaxReceipt" [disabled]="isMREnabled">
                ข้าพเจ้ายินยอมให้ระบบส่งใบกำกับภาษี
              </mat-checkbox>
            </div>
            <div class="flex flex50-item">
            </div>
          </div>
          <hr />
          <div class="flex gap-x gap-y row-items">
            <div class="col-item">
              <app-company-address-grid [inputData]="form.companyAddress"></app-company-address-grid>
            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <!-- Member Information -->
      <mat-card>
        <mat-card-header style="height:50px">
          <div class="text-center">รายละเอียดข้อมูลสมาชิก | MEMBER INFORMATION</div>
        </mat-card-header>
        <mat-card-content style="padding: 30px 30px 30px 30px;">
          <div class="flex gap-x row-items">
            <div class="col-item">
              <app-company-member-history-grid [inputData]="form.companyMemberHistory"></app-company-member-history-grid>
            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <!-- Exporter Information -->
      <mat-card>
        <mat-card-header style="height:50px">
          <div class="text-center">รายละเอียดกิจการผู้ส่งออก | EXPORTER INFORMATION</div>
        </mat-card-header>
        <mat-card-content style="padding: 30px 30px 30px 30px;">
          <div class="flex gap-x row-items">
            <div class="flex flex70-item">
              <div class="w-1/4 flex flex-col">
                <h4>โรงงาน</h4>
                <h4>Factory</h4>
              </div>
              <mat-form-field class="flex-auto">
                <mat-select placeholder="ไม่มีโรงงานผลิต (กรุณาระบุโรงงาน / บริษัทที่ซื้อ)" [(ngModel)]="form.factoryData.factory"
                  name="factory" #status="ngModel" [disabled]="isMREnabled">
                  <mat-option *ngFor="let item of isFactory" [value]="item.value">
                    {{item.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="flex flex30-item">
            </div>
          </div>
          <div class="flex gap-x gap-y row-items">
            <div class="flex flex70-item">
              <div class="w-1/4 flex flex-col">
                <h4>รหัสโรงงาน</h4>
                <h4>Factory Code</h4>
              </div>
              <mat-form-field class="flex-auto">
                <input matInput placeholder="รหัสโรงงาน" [(ngModel)]="form.factoryData.factoryCode"
                  [ngModelOptions]="{standalone: true}" [disabled]="isMREnabled">
              </mat-form-field>
            </div>
            <div class="flex flex30-item">
            </div>
          </div>
          <div class="flex gap-x gap-y row-items">
            <div class="flex flex70-item">
              <div class="w-1/4 flex flex-col">
                <h4>โรงงาน / บริษัทที่ซื้อ</h4>
                <h4>Factory / Buy From</h4>
              </div>
              <mat-form-field class="flex-auto">
                <input matInput placeholder="โรงงาน / บริษัทที่ซื้อ" [(ngModel)]="form.factoryData.factoryName"
                  [ngModelOptions]="{standalone: true}" [disabled]="isMREnabled">
              </mat-form-field>
            </div>
            <div class="flex flex30-item">
              <div class="w-1/4 flex flex-col">
              </div>
            </div>
          </div>
          <div class="flex gap-x gap-y row-items">
            <div class="flex flex70-item">
              <div class="w-1/4 flex flex-col">
                <h4>สินค้าหลักที่ส่งออก / ประเภทธุรกิจ</h4>
                <h4>Exports / Business</h4>
              </div>
              <mat-form-field class="flex-auto">
                <input matInput placeholder="สินค้าหลักที่ส่งออก / ประเภทธุรกิจ" [(ngModel)]="form.factoryData.exportsBusiness"
                  [ngModelOptions]="{standalone: true}" [disabled]="isMREnabled">
              </mat-form-field>
            </div>
            <div class="flex flex30-item">
            </div>
          </div>
          <div class="flex gap-x gap-y row-items">
            <div class="flex flex70-item">
              <div class="w-1/4 flex flex-col">
                <h4>ประเทศที่ส่งออก / ขอวีซ่าไปประเทศ</h4>
                <h4>Destination Country</h4>
              </div>
              <mat-form-field class="flex-auto">
                <input matInput placeholder="ประเทศที่ส่งออก / ขอวีซ่าไปประเทศ" [(ngModel)]="form.factoryData.destinationCountry"
                  [ngModelOptions]="{standalone: true}" [disabled]="isMREnabled">
              </mat-form-field>
            </div>
            <div class="flex flex30-item">
            </div>
          </div>
          <div class="flex gap-x gap-y row-items">
            <div class="flex flex70-item">
              <div class="w-1/4 flex flex-col">
                <h4>วัตถุดิบ / สินค้าที่นำเข้า</h4>
                <h4>Raw Material / Import Goods</h4>
              </div>
              <mat-form-field class="flex-auto">
                <input matInput placeholder="วัตถุดิบ / สินค้าที่นำเข้า" [(ngModel)]="form.factoryData.rawMaterial"
                  [ngModelOptions]="{standalone: true}" [disabled]="isMREnabled">
              </mat-form-field>
            </div>
            <div class="flex flex30-item">
            </div>
          </div>
          <div class="flex gap-x gap-y row-items">
            <div class="flex flex70-item">
              <div class="w-1/4 flex flex-col">
                <h4>นำเข้าจากประเทศ</h4>
                <h4>Import From</h4>
              </div>
              <mat-form-field class="flex-auto">
                <input matInput placeholder="นำเข้าจากประเทศ" [(ngModel)]="form.factoryData.importFrom"
                  [ngModelOptions]="{standalone: true}" [disabled]="isMREnabled">
              </mat-form-field>
            </div>
            <div class="flex flex30-item">
            </div>
          </div>

          <hr class="gap-y" />
          <div class="flex gap-x gap-y row-items">
            <div class="col-item">
              <div class="box-subheader">
                ลายมือชื่อผู้รับมอบอำนาจลงนาม
              </div>
              <app-company-authorized-signature-grid [inputData]="form.authorizedSignature"></app-company-authorized-signature-grid>
            </div>
          </div>

          <hr class="gap-y" />
          <div class="flex gap-x gap-y row-items">
            <div class="col-item">
              <div class="box-subheader-2line">
                <span>ลายมือชื่อและพิมพ์ชื่อผู้มีอำนาจลงนามผูกพันบริษัท ตามระบุในหนังสือรับรองกระทรวงพาณิชย์</span>
                <span>(ข้าพเจ้าขอมอบอำนาจบุคคลผู้มีลายมือชื่อดังกล่าวข้างต้น เป็นผู้รับมอบอำนาจลงนามเอกสาร)</span>
              </div>
              <app-company-authorized-signature-grid [inputData]="form.authorizedPerson"></app-company-authorized-signature-grid>
            </div>
          </div>

          <hr class="gap-y" />
          <div class="flex gap-x gap-y row-items">
            <div class="col-item">
              <div class="box-subheader">
                ตราบริษัท
              </div>
              <div class="mat-elevation-z8" style="width: 100%">
                <div class="flex gap-x row-items" style="padding: 20px;">
                  <div class="flex flex10-item">
                    <div class="flex-auto">
                      <h4>ภาพตราบริษัท</h4>
                      <h4>Company Picture</h4>
                    </div>
                  </div>
                  <div class="flex flex90-item">
                    <div class="flex-auto">
                      <div class="flex flex-col align-center">
                        <img src="{{form?.companyPicture}}" class="img-radius" (click)="imagePopup(form?.companyPicture)" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div>
    <div class="flex flex-row justify-center mt-3 gap-4">
      <button class="set-btn-unlimited flex-none " mat-raised-button color="accent" (click)="save()">บันทึก</button>
      <button class="set-btn-unlimited flex-none " mat-raised-button color="accent"
        (click)="backClicked()">ย้อนกลับ</button>
    </div>
  </div>
</div>