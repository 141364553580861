<table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">
  <!-- Position Column -->
  <ng-container matColumnDef="position">
    <th mat-header-cell *matHeaderCellDef class="text-center"> No. </th>
    <td mat-cell *matCellDef="let element; let i = index" class="text-center"> {{i + 1}} </td>
  </ng-container>


  <!-- Weight Column -->
  <ng-container matColumnDef="attachUrl">
    <th mat-header-cell *matHeaderCellDef class="text-center"> ภาพตัวอย่าง<br>Example </th>
    <td mat-cell *matCellDef="let element" class="text-center">
      <div style="display: flex;flex-direction: column;align-items: center;">
        <img class="h100" (click)="onView(element.attachUrl)" [src]="element.attachUrl" />
      </div>
    </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="remark">
    <th mat-header-cell *matHeaderCellDef class="text-center"> หมายเหตุ<br>Remark </th>
    <td mat-cell *matCellDef="let element">
      <mat-form-field floatLabel="never" style="width:100%;margin-top: 4px;margin-bottom: 4px;">
        <input matInput placeholder="หมายเหตุ/Remark" [value]="element.remark" [(ngModel)]="element.remark" (keyup)="onChanged($event)" [disabled]="isDisabled" maxlength="1000">
      </mat-form-field>
    </td>
  </ng-container>

  <!-- Action -->
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef class="text-center">  </th>
    <td mat-cell *matCellDef="let element;let i = index" class="text-center">
      <div style="margin-top:6px;">
        <button (click)="deleteAttachment(element, i)">
          <mat-icon svgIcon="heroicons_outline:trash"></mat-icon>
        </button>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>