<meta name="viewport" content="width=device-width, initial-scale=1">
<div class="card-pd tltle-menu" *ngIf="subEdit==false">
    User Request CF <span
        *ngIf="documentDetail?.requestNo != null && documentDetail?.requestNo!= undefined && documentDetail?.requestNo != ''">/
        {{documentDetail?.requestNo}} </span>
</div>
<div class="flex flex-col h-full gap-4">
    <div style="gap: 15px;" class="flex-col justify-items-stretch flex" [ngClass]="{'px-20': subEdit != true}">
        <!-- <mat-card *ngIf="editItem || editJobData">
            <mat-card-header>
                <div class="text-center">รายละเอียดเอกสาร | Detail</div>
            </mat-card-header>

            <mat-card-content>
                <section class="flex-col flex-1">
                    <div class="flex flex-row" style="margin-top: 16px !important;">
                        <div class="flex flex-row" style="width: 100%">
                            <div class="flex flex-col" style="width: 130px">
                                <h4>เลขที่คำขอ</h4>
                                <h4>Request No.</h4>
                            </div>
                            <div class="flex form-field-container">
                                <p style="font-size: 1.2em;">
                                    {{documentDetail?.requestNo}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-row mt-2">
                        <div class="flex flex-row" style="width: 100%">
                            <div class="flex flex-col" style="width: 130px">
                                <h4>เลขที่ THT</h4>
                                <h4>Certificate No.</h4>
                            </div>
                            <div class="flex form-field-container"
                                style="flex-direction: column; align-items: flex-start !important;">
                                <p style="font-size: 1.2em;">
                                    {{documentDetail?.certificateList}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-row mt-2">
                        <div class="flex flex-row" style="width: 100%">
                            <div class="flex flex-col" style="width: 130px">
                                <h4>วันที่ยื่นคำขอ</h4>
                                <h4>Request Date</h4>
                            </div>
                            <div class="flex form-field-container">
                                <p style="font-size: 1.2em;">
                                    {{documentDetail?.requestDate | date: 'yyyy-MM-dd HH:mm:ss'}}
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </mat-card-content>
        </mat-card> -->

        <mat-card *ngIf="form.isRqstatus == 10 && markErrorList.length != 0">
            <mat-card-header>
                <div class="text-center">ข้อผิดพลาดของเอกสาร | Verify Remark Document</div>
            </mat-card-header>

            <mat-card-content>
                <section class="px-4 flex flex-col flex-1 pb-4  justify-evenly">
                    <dx-data-grid class="mt-3" #gridContainer [dataSource]="markErrorList"
                        [allowColumnReordering]="true" [showBorders]="true">
                        <dxo-editing mode="row" [allowUpdating]="false" [allowDeleting]="false">
                        </dxo-editing>
                        <dxi-column dataField="docTypeName" caption="ประเภทเอกสาร" [width]="100">
                        </dxi-column>
                        <dxi-column dataField="fieldName" caption="ชื่อฟิลด์">
                        </dxi-column>
                        <dxi-column dataField="remark" caption="ข้อผิดพลาด">
                        </dxi-column>
                        <dxi-column cellTemplate="action" caption="Action" [width]="100">
                        </dxi-column>
                        <div *dxTemplate="let row of 'action'" style="font-size:9px;">
                            <button (click)="onClick(row)" data-toggle="modal" data-target="#myModal"
                                *ngIf="markErrorList[row.rowIndex].fieldId !== 0">
                                <mat-icon svgIcon="mat_outline:search"></mat-icon>
                            </button>
                        </div>
                    </dx-data-grid>
                </section>
            </mat-card-content>
        </mat-card>
        <!-- Payment List-->
        <app-detail-payment-list-table [hId]="PackidLoadData"
            *ngIf="PackidLoadData != 0 && PackidLoadData != undefined && this.lengthViewChildPayment != 0"></app-detail-payment-list-table>
        <mat-card class="flex flex-col gap-4">
            <mat-card-header>
                <h2 class="text-center cursor-pointer">ข้อมูลผู้ส่งออก | Exporter Form </h2>
            </mat-card-header>
            <mat-card-content>
                <!-- line 1 -->
                <div class="flex mt-4 gap-3 "
                    [ngClass]="{'flexCheckJobRow': subEdit != true,'flexCheckJobColum': subEdit == true}">
                    <div class="flex flex-row"
                        [ngClass]="{'flexWidth50': subEdit != true,'flexWidth100': subEdit == true}">
                        <div class="w-1/4 flex flex-col">
                            <h4>ชื่อผู้ยื่นคำขอ</h4>
                            <h4>Request By</h4>
                        </div>
                        <mat-form-field class="flex-auto">
                            <input matInput placeholder="ชื่อผู้ยื่นคำขอ" [(ngModel)]="form.firstName" disabled
                                [ngModelOptions]="{standalone: true}">
                        </mat-form-field>
                    </div>
                    <div class="flex flex-row"
                        [ngClass]="{'flexWidth50': subEdit != true,'flexWidth100': subEdit == true}">
                        <div class="w-1/4 flex flex-col">
                            <h4>ผู้ส่งออกสินค้า<span class="requiredField">*</span></h4>
                            <h4>Declarator</h4>
                        </div>
                        <mat-form-field class="flex-auto" *ngIf="!search && !recheck">
                            <mat-select [(ngModel)]="form.firstNameDeclaratorId" name="firstNameDeclaratorId"
                                #firstNameDeclaratorId="ngModel" (ngModelChange)="selectionDeclarator($event)"
                                [disabled]="editJobData || editItem">
                                <mat-option *ngFor="let declarator of declaratorList" [value]="declarator.cid"
                                    [disabled]="declarator.blacklistStatus == 'Y'">
                                    {{declarator.companyNameEn}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="flex-auto" *ngIf="search || recheck">
                            <input matInput [(ngModel)]="form.firstName" [ngModelOptions]="{standalone: true}"
                                [disabled]="true">

                        </mat-form-field>
                    </div>
                </div>
                <!-- line 2 -->
                <div class="flex mt-4 gap-3"
                    [ngClass]="{'flexCheckJobRow': subEdit != true,'flexCheckJobColum': subEdit == true}">
                    <div class="flex flex-row"
                        [ngClass]="{'flexWidth50': subEdit != true,'flexWidth100': subEdit == true}">
                        <div class="w-1/4 flex flex-col">
                            <h4>ประเทศปลายทาง<span class="requiredField">*</span></h4>
                            <h4>Destination</h4>
                        </div>
                        <mat-form-field class="flex-auto">
                            <mat-select [(ngModel)]="form.selectedCountry" name="destination" #destination="ngModel"
                                (selectionChange)="selectionDestination()" [disabled]="editJobData || editItem">
                                <mat-option *ngFor="let country of countryList" [value]="country.mC_ID">
                                    {{country.mC_COUNTRY_NAME}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="flex flex-row"
                        [ngClass]="{'flexWidth50': subEdit != true,'flexWidth100': subEdit == true}">
                        <div class="w-1/4 flex flex-col">
                            <h4>ประเภทเอกสาร<span class="requiredField">*</span></h4>
                            <h4>Document Subject/Title</h4>
                        </div>
                        <div class="w-3/4 flex flex-col">
                            <div class="flex flex-row gap-4">
                                <mat-form-field class="w-1/2">
                                    <mat-select [(ngModel)]="form.docSubTitle" name="docSubTitle" #docSubTitle="ngModel"
                                        (selectionChange)="selectionDocSubTitle()" [disabled]="editJobData || editItem">
                                        <mat-option *ngFor="let doc of docList" [value]="doc.sdtId">
                                            {{doc.sdtName}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="flex-auto form-field-container">
                                    <mat-form-field class="flex-auto w-full" *ngIf="form.docSubTitle == etc">
                                        <input autocomplete="off" type="text" matInput placeholder="อื่นๆ" matInput
                                            #input [(ngModel)]="form.docSubTitleEtc" name="firstName"
                                            [disabled]="editJobData || editItem"
                                            #docSubTitleEtc="ngModel" maxlength="23"
                                            (ngModelChange)="checkEnglish(form.docSubTitleEtc) == false ? showErrorMessage.etcNoEng = true : showErrorMessage.etcNoEng = false;">
                                    </mat-form-field>
                                    <div class="flex flex-auto">
                                        <label *ngIf="showErrorMessage.etcNoEng == true"
                                            class="required position-absolute">กรอกได้เฉพาะภาษาอังกฤษ</label>
                                        <label
                                            *ngIf="showErrorMessage.etcNoSymbol == true && showErrorMessage.etcNoEng == false"
                                            class="required position-absolute">ไม่สามารถกรอกอักขระพิเศษ</label>
                                            <p class="flex-auto text-end"
                                            *ngIf="form.docSubTitleEtc?.length !=null && form.docSubTitle == etc">
                                            {{form.docSubTitleEtc?.length+"/23"}}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <mat-radio-group class="flex-auto" [(ngModel)]="form.documentSubjectTitleType"
                                aria-label="Select an option" name="documentSubjectTitleType"
                                #documentSubjectTitleType="ngModel" [disabled]="editJobData || editItem">
                                <mat-radio-button value="General">ทั่วไป/General</mat-radio-button>
                                <mat-radio-button value="Contract" [disabled]="true">สัญญา/Contract</mat-radio-button>
                            </mat-radio-group>


                        </div>
                    </div>

                </div>
                <!-- line 3 -->
                <div class="flex mt-4 gap-3"
                    [ngClass]="{'flexCheckJobRow': subEdit != true,'flexCheckJobColum': subEdit == true}">
                    <div class="flex flex-row"
                        [ngClass]="{'flexWidth50': subEdit != true,'flexWidth100': subEdit == true}">
                        <div class="w-1/4 flex flex-col">
                            <h4>เลขที่เอกสาร<span class="requiredField">*</span></h4>
                            <h4>Document Referent No</h4>
                        </div>
                        <div class="flex flex-col w-3/4">
                            <mat-form-field class="flex-auto">
                                <input autocomplete="off" type="text" matInput placeholder="เลขที่เอกสาร" matInput #input
                                    [(ngModel)]="form.documentReferentNo" name="documentReferentNo" maxLength="45"
                                    #documentReferentNo="ngModel" [disabled]="editJobData || editItem"
                                    (ngModelChange)="checkEnglish(form.documentReferentNo) == false ? showErrorMessage.docRefNoEng = true : showErrorMessage.docRefNoEng = false;">
                            </mat-form-field>
                            <div class="flex flex-auto">
                                <label *ngIf="showErrorMessage.docRefNoEng == true"
                                    class="required position-absolute">กรอกได้เฉพาะภาษาอังกฤษ</label>
                                <label
                                    *ngIf="showErrorMessage.docRefNoSymbol == true && showErrorMessage.docRefNoEng == false"
                                    class="required position-absolute">ไม่สามารถกรอกอักขระพิเศษ</label>
                                <p class="flex-auto text-end" *ngIf="form.documentReferentNo?.length !=null">
                                    {{form.documentReferentNo?.length+"/45"}}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-row"
                        [ngClass]="{'flexWidth50': subEdit != true,'flexWidth100': subEdit == true}">
                        <div class="w-1/4 flex flex-col">
                            <h4>วันที่ในเอกสาร<span class="requiredField">*</span></h4>
                            <h4>Document Date</h4>
                        </div>
                        <mat-form-field appearance="fill" class="flex-auto" [ngClass]="{'w-60': subEdit == true}">
                            <input matInput [matDatepicker]="pick" [(ngModel)]="form.documentDate"
                                [ngModelOptions]="{standalone: true}" [disabled]="editJobData || editItem"
                                required="true" (click)="pick.open()" readonly class="cursor-pointer">
                            <mat-datepicker-toggle matIconSuffix [for]="pick"></mat-datepicker-toggle>
                            <mat-datepicker #pick></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <!-- line 4 -->
                <div class="flex mt-4 gap-3"
                    [ngClass]="{'flexCheckJobRow': subEdit != true,'flexCheckJobColum': subEdit == true}">
                    <div class="flex flex-row"
                        [ngClass]="{'flexWidth50': subEdit != true,'flexWidth100': subEdit == true}">
                        <div class="w-1/4 flex flex-col">
                            <h4>จำนวนหน้า<span class="requiredField">*</span></h4>
                            <h4>Total Pages</h4>
                        </div>
                        <mat-form-field class="flex-auto"
                            [ngClass]="{'flexHeight40': subEdit != true,'flexHeight100': subEdit == true}">
                            <input autocomplete="off" type="number" min="1" matInput placeholder="จำนวนหน้า" matInput
                                #input [(ngModel)]="form.totalPages" name="totalPages" #totalPages="ngModel"
                                onKeyPress="if(this.value.length==4) return false;" (change)="totalChange()"
                                [disabled]="editJobData || editItem">
                        </mat-form-field>
                        <mat-radio-group class="flex-auto" [(ngModel)]="form.book" name="book" #book="ngModel"
                            aria-label="Select an option" [disabled]="true">
                            <mat-radio-button value="Y">เป็นเล่ม</mat-radio-button>
                            <mat-radio-button value="N">ไม่เป็นเล่ม</mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <div class="flex flex-row"
                        [ngClass]="{'flexWidth50': subEdit != true,'flexWidth100': subEdit == true}">
                        <div class="w-1/4 flex flex-col">
                            <h4>วันที่ออกเอกสาร<span class="requiredField">*</span></h4>
                            <h4>Certificate Date</h4>
                        </div>
                        <div class="flex-auto">
                            <mat-radio-group aria-label="Select an option" class="flex flex-col"
                                [(ngModel)]="form.certificateDateRequest">
                                <mat-radio-button value="approve_date" [disabled]="editJobData || editItem">
                                    <div class="flex flex-col">
                                        <div>ตามวันที่อนุมัติ <span style="color: green;" *ngIf="form.certificateDateRespone=='approve_date'">{{ form.certificateDateResponeDate | date:'yyyy-MM-dd' }}</span></div>
                                    </div>
                                </mat-radio-button>
                                <mat-radio-button value="first_request" [disabled]="editJobData || editItem">
                                    <div class="flex flex-col">
                                        <div>ตามวันที่ยื่นคำขอครั้งแรก<span style="color: green;" *ngIf="form.certificateDateRespone=='first_request'">{{ form.certificateDateResponeDate | date:'yyyy-MM-dd' }}</span></div>
                                    </div>
                                </mat-radio-button>
                                <mat-radio-button value="on_date" class="flex flex-row"
                                    [disabled]="editJobData || editItem">
                                    <div class="flex flex-row gap-3">
                                        <div class="flex flex-col">
                                            <div>วันที่ย้อนหลัง/ล่วงหน้า</div>
                                            <div>(ชำระค่าธรรมเนียมเพิ่มเติม)</div>
                                        </div>
                                        <div class="flex">
                                            <mat-form-field appearance="fill"
                                                *ngIf="!(form.certificateDateRequest!='on_date')">
                                                <input matInput [matDatepicker]="picker" type="datetime"
                                                    [disabled]="editJobData || editItem"
                                                    [(ngModel)]="form.certificateDateRequestDate"
                                                    name="certificateDateRequestDate"
                                                    #certificateDateRequestDate="ngModel" (click)="picker.open()"
                                                    readonly class="cursor-pointer">
                                                <mat-hint>MM/DD/YYYY</mat-hint>
                                                <mat-datepicker-toggle matIconSuffix
                                                    [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </mat-radio-button>

                            </mat-radio-group>
                        </div>
                    </div>
                </div>
                <!--Line 5-->
                <div class="flex mt-4 gap-3"
                    [ngClass]="{'flexCheckJobRow': subEdit != true,'flexCheckJobColum': subEdit == true}">
                    <div class="flex flex-row"
                        [ngClass]="{'flexWidth50': subEdit != true,'flexWidth100': subEdit == true}">
                        <div class="w-1/4 flex flex-col mr-3">
                            <h4>ลายเซ็นสด</h4>
                            <h4>Live Signature</h4>
                        </div>
                        <mat-checkbox class="flex-auto" [disabled]="editJobData || editItem"
                            [(ngModel)]="form.liveSignature">ระบุสาขา</mat-checkbox>

                        <ng-container *ngIf="form.liveSignature">
                            <mat-form-field class="my-form-field flex flex-row flex-auto">
                                <mat-select [(ngModel)]="form.branchId" name="branchId" #branchId="ngModel"
                                    (selectionChange)="selectionBranch()"
                                    [disabled]="!form.liveSignature||editJobData || editItem">
                                    <mat-option *ngFor="let branch of branchList" [value]="branch.branchId">
                                        {{branch.branchName}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>
                    </div>

                    <div class="w-1/2 flex flex-row">
                        <div class="w-1/4 flex flex-col ml-10">

                        </div>
                        <div class="w-3/4 flex flex-col">

                        </div>
                    </div>
                </div>

            </mat-card-content>
        </mat-card>
        <app-create-cf-memo *ngIf="userProfile.gId == '4' || userProfile.gId == '5' || userProfile.gId == '1'"
            [memotext]="form.memo"></app-create-cf-memo>
        <mat-card class="flex flex-col gap-4">
            <mat-card-header>
                <div class="text-center">เอกสารประกอบคำขอ | ATTACHMENT <span class="requiredField">*</span></div>
            </mat-card-header>
            <mat-card-content>
                <mat-label *ngIf="!editJobData" [class.hidden]="editItem" style="color : red;">
                    รายละเอียดของไฟล์ภาพควรมีขนาดไม่เกิน 3 MB / รูปแบบไฟล์ที่อนุญาต .jpg, .jpeg, .png
                </mat-label>
                <section class="flex flex-row gap-4 ">
                    <div class="file-uploader-block" *ngIf="!editJobData" [class.hidden]="editItem">
                        <dx-file-uploader style="border: none;padding: 0;" [allowCanceling]="true" [(value)]="value"
                            #fileUploader [multiple]="true" [uploadMode]="'useForm'"
                            accept="image/jpeg, image/png ,image/jpg"
                            [allowedFileExtensions]="['.jpg', '.jpeg', '.png']">
            </dx-file-uploader>

                    </div>

                </section>
                <div class="flex flex-row gap-4 mb-3 ml-3" *ngIf="!editJobData" [class.hidden]="editItem">
                    <dx-button text="Upload" (onClick)="upload()">
                    </dx-button>
                </div>

                <div class="flex-auto" style="margin-top:12px;">
                    <app-upload-document-datagrid [disabled]="editJobData || editItem"
                        [uploadPhotoData]="dataSourceUpload" [uploadPhotoForm]="formSourceUpload" [documentType]="'CF'"
                        (dataSourceUpdate)="onDataSourceUpdate($event)" (formSourceUpdate)="onFormSourceUpdate($event)">
                    </app-upload-document-datagrid>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card *ngIf="logPrint.length != 0">
            <app-log-print-history-component [logPrintHistory]="logPrint"
                [docType]="form.docType"></app-log-print-history-component>
        </mat-card>
        <!-- <mat-card *ngIf="!subEdit"> -->
        <!-- <div class="flex flex-row justify-center my-3 gap-4" *ngIf="!subEdit"
            [ngClass]="{'pl-22 pr-22': subEdit != true,'pl-10 pr-10': subEdit == true}">
            <div class="flex-row flex justify-center m-10" *ngIf="!editItem">
                <button mat-raised-button color="accent" (click)="save()">บันทึก</button>
            </div>
            <div class="flex-row flex justify-center m-10" *ngIf="!editItem">
                <button mat-raised-button color="accent" (click)="savePopup()">บันทึกและส่งตรวจ</button>
            </div>
            <div class="flex-row flex justify-center m-10">
                <button mat-raised-button color="accent" (click)="back()">ย้อนกลับ</button>
            </div>
        </div>
        <div class="flex justify-center " *ngIf="subEdit"
            [ngClass]="{'pl-22 pr-22': subEdit != true,'pl-10 pr-10': subEdit == true}">
            <div class="flex-row flex justify-center m-10">
                <button mat-raised-button color="accent" *ngIf="!editItem" (click)="save()">บันทึก</button>
            </div>
        </div> -->
        <!-- </mat-card> -->
        <div class="flex flex-row justify-center my-3 gap-4" *ngIf="!subEdit && !staff">
            <button class="set-btn-unlimited flex-none " mat-raised-button color="accent" (click)="save()"
                *ngIf="!editItem" [disabled]="clicked">บันทึก</button>
            <button class="set-btn-unlimited flex-none " mat-raised-button color="accent" (click)="savePopup()"
                *ngIf="!editItem" [disabled]="clicked">บันทึกและส่งตรวจ</button>

            <button class="set-btn-unlimited flex-none " mat-raised-button color="accent"
                (click)="backClicked()">ย้อนกลับ</button>
            <!-- <button class="set-btn-unlimited flex-none " mat-raised-button color="accent" *ngIf="!search && !recheck"
                routerLink="/page/request/request-cf/list-cf">ย้อนกลับ</button>
                <button class="set-btn-unlimited flex-none " mat-raised-button color="accent" *ngIf="search"
                    routerLink="/page/verify/search-cocf">ย้อนกลับ</button>
                <button class="set-btn-unlimited flex-none " mat-raised-button color="accent" *ngIf="recheck"
                routerLink="/page/verify/verify-recheck">ย้อนกลับ</button> -->
        </div>


    </div>
</div>