import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  catchError,
  delay,
  Observable,
  of,
  Subscription,
  switchMap,
  throwError,
  window,
} from "rxjs";
import { AuthUtils } from "app/core/auth/auth.utils";
import { UserService } from "app/core/user/user.service";
import { Router } from "@angular/router";

@Injectable()
export class AuthService {
  private _authenticated: boolean = false;
  tokenSubscription = new Subscription();
  timeout;
  uniqueId: string;
  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _router: Router,
    private _userService: UserService
  ) {
    this.generateUniqueId();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem(`${this.getUniqueId()}-accessToken`, token);
  }

  get accessToken(): string {
    return localStorage.getItem(`${this.getUniqueId()}-accessToken`) ?? "";
  }

  set login(token: string) {
    localStorage.setItem(`${this.getUniqueId()}-login`, token);
  }

  get login(): string {
    return localStorage.getItem(`${this.getUniqueId()}-login`) ?? "";
  }

  set userProfile(token: string) {
    localStorage.setItem(`${this.getUniqueId()}-userProfile`, token);
  }

  get userProfile(): string {
    return localStorage.getItem(`${this.getUniqueId()}-userProfile`) ?? "";
  }

  set token(token: string) {
    localStorage.setItem(`${this.getUniqueId()}-token`, token);
  }

  get token(): string {
    return localStorage.getItem(`${this.getUniqueId()}-token`) ?? "";
  }

  set userMenu(menu: string) { 
    localStorage.setItem(`${this.getUniqueId()}-userMenu`, menu);
  }

  get userMenu(): string {
    return localStorage.getItem(`${this.getUniqueId()}-userMenu`) ?? "";
  }

  set loginUser(token: string) {
    console.log("loginUser token", token);
    localStorage.setItem(`${this.getUniqueId()}-loginUser`, token);
    localStorage.setItem(`loginUser`, token);
  }

  get loginUser(): string {
    return localStorage.getItem(`${this.getUniqueId()}-loginUser`) ?? "";
  }

  set setTabLocalStorage(key: string) {
    localStorage.setItem(this.getUniqueId(), key);
  }
  get setTabLocalStorage(): string {
    return localStorage.getItem(this.getUniqueId()) ?? "";
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  clear(): void {
    console.log("clear cahch", this.getUniqueId());
    localStorage.removeItem(`${this.getUniqueId()}-loginUser`);
    localStorage.removeItem(`${this.getUniqueId()}-userMenu`);
    localStorage.removeItem(`${this.getUniqueId()}-token`);
    localStorage.removeItem(`${this.getUniqueId()}-userProfile`);
    localStorage.removeItem(`${this.getUniqueId()}-login`);
    localStorage.removeItem(`${this.getUniqueId()}-accessToken`);
    localStorage.removeItem(this.getUniqueId());
  }
  clearAll(): void {
    localStorage.clear();
  }
  clearSessionMenu(): void {}
  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(email: string): Observable<any> {
    return this._httpClient.post("api/auth/forgot-password", email);
  }

  /**
   * Reset password
   *
   * @param password
   */
  resetPassword(password: string): Observable<any> {
    return this._httpClient.post("api/auth/reset-password", password);
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: { email: string; password: string }): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError("User is already logged in.");
    }
    //debugger;

    return this._httpClient.post("api/auth/sign-in", credentials).pipe(
      switchMap((response: any) => {
        //debugger;
        // Store the access token in the local storage
        this.accessToken = response.accessToken;

        // Set the authenticated flag to true
        this._authenticated = true;

        // Store the user on the user service
        this._userService.user = response.user;

        // localStorage.setItem(this.getUniqueId(),'getUniqueId');
        // Return a new observable with the response
        return of(response);
      })
    );
  }
  private generateUniqueId(): void {
    const timestamp = Date.now().toString(36);
    const randomValue = Math.random().toString(36).substr(2, 9);
    this.uniqueId = `${timestamp}-${randomValue}`;
  }

  public setUniqueId(key: string): string {
    return (this.uniqueId = key);
  }
  public getUniqueId(): string {
    return this.uniqueId;
  }
  signInNew(email: string, accessToken: string, user: any): any {
    // Throw error, if the user is already logged in
    // this.accessToken = accessToken;
    this._authenticated = true;
    this._userService.user = user;
  }
  /**
   * Sign in using the access token
   */
  signInUsingToken(): Observable<any> {
    // Sign in using the token
    return this._httpClient
      .post("api/auth/sign-in-with-token", {
        accessToken: this.accessToken,
      })
      .pipe(
        catchError(() =>
          // Return false
          of(false)
        ),
        switchMap((response: any) => {
          // Replace the access token with the new one if it's available on
          // the response object.
          //
          // This is an added optional step for better security. Once you sign
          // in using the token, you should generate a new one on the server
          // side and attach it to the response object. Then the following
          // piece of code can replace the token with the refreshed one.
          if (response.accessToken) {
            this.accessToken = response.accessToken;
          }

          // Set the authenticated flag to true
          this._authenticated = true;

          // Store the user on the user service
          this._userService.user = response.user;

          // Return true
          return of(true);
        })
      );
  }

  // expirationCounter(timeout) {
  //   this.tokenSubscription.unsubscribe();
  //   this.tokenSubscription = of(null).pipe(delay(timeout)).subscribe((expired) => {

  //     this.signOut();
  //     location.reload();
  //     this._router.navigate(["/sign-out"]);
  //   });
  // }
  /**
   * Sign out
   */
  signOut(): Observable<any> {
    // debugger;
    // Remove the access token from the local storage
    localStorage.clear();
    // localStorage.removeItem("accessToken");
    // localStorage.removeItem("menu");
    // localStorage.removeItem("timeout");
    // localStorage.removeItem("userProfile");
    // localStorage.removeItem("token");
    // localStorage.removeItem("loginUser");
    // localStorage.removeItem("userMenu");
    // localStorage.removeItem(this.getUniqueId());

    localStorage.removeItem(`${this.getUniqueId()}-accessToken`);
    localStorage.removeItem(`${this.getUniqueId()}-login`);
    localStorage.removeItem(`${this.getUniqueId()}-userProfile`);
    localStorage.removeItem(`${this.getUniqueId()}-token`);
    localStorage.removeItem(`${this.getUniqueId()}-userMenu`);
    localStorage.removeItem(`${this.getUniqueId()}-loginUser`);
    localStorage.removeItem(this.getUniqueId());

    this.tokenSubscription.unsubscribe();
    this._router.navigate(["/sign-in"]);
    // location.reload();
    // localStorage.removeItem('userProfile');
    // debugger;
    // Set the authenticated flag to false
    this._authenticated = false;

    // Return the observable
    return of(true);
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(user: {
    name: string;
    email: string;
    password: string;
    company: string;
  }): Observable<any> {
    return this._httpClient.post("api/auth/sign-up", user);
  }

  /**
   * Unlock session
   *
   * @param credentials
   */
  unlockSession(credentials: {
    email: string;
    password: string;
  }): Observable<any> {
    return this._httpClient.post("api/auth/unlock-session", credentials);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // debugger;
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    // Check the access token expire date
    if (JSON.parse(this.loginUser).userName == null) {
      return of(false);
    }

    // If the access token exists and it didn't expire, sign in using it
    return this.signInUsingToken();
  }
}
