<div class="idx">
  <mat-card-header cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="flex justify-between" style="width: 100%;">
      <div class="text-lg"> หมายเหตุประกอบภาพ </div>
      <div>
        <button class="close-button" [mat-dialog-close]>
          <mat-icon class="text-white" [mat-dialog-close]>cancel</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-header>

  <mat-dialog-content class="mat-typography">
    <div style="display: flex;flex-flow: column;" class="pl-5 pr-5 justify-start">
      <!-- <div class="mat-button flex flex-col"> -->
      <div class="flex-auto flex flex-row items-center">
        <div class="flex flex-col">
          <h4>{{form.remark}}</h4>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="center">
    <button mat-raised-button color="accent" [mat-dialog-close]>ปิด</button>
  </mat-dialog-actions>
</div>