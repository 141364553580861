import { LiveAnnouncer } from '@angular/cdk/a11y';
import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AppMenuService } from 'app/business/service/app/app-menu.service';
import { AppUserService } from 'app/business/service/app/app-user.service';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
  selector: 'app-live-signature-list-inquiry',
  templateUrl: './live-signature-list-inquiry.component.html',
  styleUrls: ['./live-signature-list-inquiry.component.css']
})
export class LiveSignatureListInquiryComponent implements OnInit, AfterViewInit {
  BenefitTarget: any = [];
  form: any = {};
  BenefitTargetValue: string;
  displayedColumns: string[] = ['docPackName', 'requestDate', 'docType', 'requestNo', 'requestAmount', 'status', 'action'];
  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);
  isLoading = false;
  userProfile: any = {}
  count: number;
  timer: any;
  apiRequest: any = {};

  url: any;
  buttonList: any[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private router: Router,
    private appMenuService: AppMenuService,
    private appUserService: AppUserService,
    private _authService :AuthService,
  ) { }

  async getRouter(): Promise<void> {
    
    this.url = this.router.url;

    const formData = {
      url: this.url,
      userGroupCode: this.userProfile.userGroupCode,
      userRoleCode: this.userProfile.userRoleCode,
      userCode: this.userProfile.userCode
    };
    this.buttonList = await this.appMenuService.getButtonPermissionPerPages(formData);
    
  }

  async ngOnInit() {

    this.userProfile = JSON.parse(
      this._authService.loginUser
    );

    await this.getRouter();

    this.dataSource.paginator = this.paginator
    this.apiRequest.pagination = {
      Skip:
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
      Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
    }
    
    this.loadData(this.apiRequest)
    this.mockData()

  }

  ngAfterViewInit() {
    this.paginator.pageIndex = 0
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
  }
  async loadData(request: any) {
    this.isLoading = false;
    // await this.appMasterService.getHarmonizedItemList(request).then((res: any) => {
    //   if (res === 'error') {
    //     return
    //   } else {
    //     if (res !== null) {
    //       this.dataSource.data = res.items;
    //       this.count = res.totalItem;
    //       this.isLoading = false;
    //     } else {
    //       this.dataSource.data = []
    //       this.count = 0
    //       this.isLoading = false;
    //     }
    //   }
    // })
    // this.dataSource.paginator.length = this.count
  }

  applyFilter(event: string) {
    clearTimeout(this.timer); // Clear any existing timer

    this.timer = setTimeout(() => {
      // This code will be executed after a specified delay (e.g., 500 milliseconds)
      
      // Send the value to the backend or perform any other necessary actions
      this.apiRequest.filterValue = event;
      this.dataSource.paginator = this.paginator
      this.loadData(this.apiRequest)

    }, 500); // Specify the desired delay in milliseconds
  }

  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`)
    } else {
      this._liveAnnouncer.announce('Sorting cleared')
    }
  }
  stopPropagation(event) {
    event.stopPropagation()
  }
  onSearch() {
    this.apiRequest.itemCode = this.form.itemCode;
    this.apiRequest.itemDescription = this.form.itemDescription;
    this.apiRequest.isActive = this.form.isActive;
    this.apiRequest.isRestricted = this.form.isRestricted;
    this.dataSource.paginator = this.paginator
    this.loadData(this.apiRequest)
  }

  print() { }
  showDetail() { }

  async _page(e) {
    this.isLoading = false;
    this.apiRequest.pagination = {
      Skip: (!e.pageIndex ? 0 : e.pageIndex) * (!e.pageSize ? 0 : e.pageSize),
      Take: !e.pageSize ? 10 : e.pageSize,
    }

    // await this.appMasterService.getHarmonizedItemList(this.apiRequest).then((res: any) => {
    //   if (res === 'error') {
    //     return
    //   } else {
    //     if (res.response !== null) {
    //       this.dataSource.data = res.items;
    //     } else {
    //       this.dataSource = new MatTableDataSource((res.response3 = []))
    //     }
    //     this.count = res.totalItem
    //   }
    // })
    this.dataSource.paginator.length = this.count
    this.dataSource.paginator.pageIndex = e.pageIndex
    this.isLoading = false;
  }

  clearOption() {
    this.form = {}
    this.apiRequest.itemCode = '';
    this.apiRequest.itemDescription = '';
    this.apiRequest.isActive = '';
    this.apiRequest.isRestricted = '';
    this.loadData(this.apiRequest)
  }

  detail(e: any) {

  }

  transfer(e: any) {

  }

  printDoc(e: any) {

  }

  mockData() {
    var data = [
      {
        docPackName:"-",
        requestDate:"2020-10-16T15:19:14",
        docType:"CO",
        requestNo:"RQ23061600002",
        requestAmount:"1",
        status:"พิมพ์เอกสารแล้ว"
      },
      {
        docPackName:"-",
        requestDate:"2020-10-16T15:19:14",
        docType:"CO",
        requestNo:"RQ23061500068",
        requestAmount:"1",
        status:"พิมพ์เอกสารแล้ว"
      },
      {
        docPackName:"-",
        requestDate:"2020-10-16T15:19:14",
        docType:"CF",
        requestNo:"RQ23061500066",
        requestAmount:"1",
        status:"พิมพ์เอกสารแล้ว"
      },
      {
        docPackName:"-",
        requestDate:"2020-10-16T15:19:14",
        docType:"CF",
        requestNo:"RQ23061500067",
        requestAmount:"1",
        status:"พิมพ์เอกสารแล้ว"
      }
    ];

    this.dataSource = new MatTableDataSource(data);
  }

  clearFilter() {
    this.form = {}
  }

  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find(e => e.buttonCode == buttonCode);

    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }
}
