<mat-card>
    <mat-card-header>
        <div class="flex justify-between" style="width: 100%;">
            <div class="text-lg"> Unit </div>
            <div>
                <button class="close-button" [mat-dialog-close]="true">
                    <mat-icon class="text-white" [mat-dialog-close]>cancel</mat-icon>
                </button>
            </div>
        </div>
    </mat-card-header>
    <mat-dialog-content>
        <div class="pl-22 pr-22 flex flex-col gap-4">
            <div class="flex-row flex justify-between gap-2.5">
                <div class="mat-popup-header flex flex-col">
                    <h4>ตัวย่อ <span style="color:red;">*</span></h4>
                    <h4>Code</h4>
                </div>
                <div class="mat-popup flex flex-row justify-between items-center">
                    <div>:</div>
                    <mat-form-field style="flex: 0 80%;" class="flex">
                        <input required autocomplete="off" type="text" maxlength="10" matInput placeholder="ตัวย่อ"
                            matInput #input [(ngModel)]="form.code" name="code" #code="ngModel">
                    </mat-form-field>
                </div>
            </div>
            <div class="flex-row flex justify-between gap-2.5">
                <div class="mat-popup-header flex flex-col">
                    <h4>ชื่อเต็ม</h4>
                    <h4>Long Name</h4>
                </div>
                <div class="mat-popup flex flex-row justify-between items-center">
                    <div>:</div>
                    <mat-form-field style="flex: 0 80%;" class="flex">
                        <input autocomplete="off" type="text" matInput placeholder="ชื่อเต็ม" matInput #input
                            [(ngModel)]="form.longName" name="longName" #longName="ngModel">
                    </mat-form-field>
                </div>
            </div>
            <div class="flex-row flex justify-between gap-2.5">
                <div class="mat-popup-header flex flex-col">
                    <h4>ชื่อ (เอกพจน์)</h4>
                    <h4>Name</h4>
                </div>
                <div class="mat-popup flex flex-row justify-between items-center">
                    <div>:</div>
                    <mat-form-field style="flex: 0 80%;" class="flex">
                        <input autocomplete="off" type="text" matInput placeholder="ชื่อ (เอกพจน์)" matInput #input
                            [(ngModel)]="form.name" name="name" #name="ngModel">
                    </mat-form-field>
                </div>
            </div>
            <div class="flex-row flex justify-between gap-2.5">
                <div class="mat-popup-header flex flex-col">
                    <h4>ชื่อ (พหูพจน์)</h4>
                    <h4>Plural Name</h4>
                </div>
                <div class="mat-popup flex flex-row justify-between items-center">
                    <div>:</div>
                    <mat-form-field style="flex: 0 80%;" class="flex">
                        <input autocomplete="off" type="text" matInput placeholder="ชื่อ (พหูพจน์)" matInput #input
                            [(ngModel)]="form.pluralName" name="pluralName" #pluralName="ngModel">
                    </mat-form-field>
                </div>
            </div>
            <ng-container *ngFor="let buttonList of buttonList">
                <ng-container *ngIf="buttonList.buttonCode=='BT098'">
                    <div class="flex-row flex justify-between gap-2.5">
                        <div class="mat-popup-header flex flex-col">
                            <h4>ใช้งาน</h4>
                            <h4>Active</h4>
                        </div>
                        <div class="mat-popup flex flex-row justify-between items-center">
                            <div>:</div>
                            <mat-slide-toggle [checked]="form.isActive === 'Y' ? true : false"
                                (change)="onChange($event)" style="flex: 0 80%;" class="flex">
                                Active</mat-slide-toggle>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <div class="flex-row flex justify-center gap-2.5" style="gap: 10px;">
                <button mat-raised-button color="accent" (click)="save()">บันทึก</button>
                <button mat-raised-button [mat-dialog-close]>ยกเลิก</button>
            </div>
        </div>
    </mat-dialog-content>