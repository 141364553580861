<div style="gap: 30px;padding-top: 30px;" class="flex flex-col h-full">
  <div class="menu flex items-center flex-row gap-5 px-20 pr-10 pl-10">
    <div class="gap-2.5 flex flex-row">
      <mat-radio-group class="form-block w-full" [(ngModel)]="searchForm.mode" name="book" #book="ngModel"
        aria-label="Select an option">
        <mat-radio-button [value]="'RQ'" [checked] = "true">เลขที่คำขอ</mat-radio-button>
        <mat-radio-button [value]="'THT'">เลขที่ใบรับรอง</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="gap-2.5 flex flex-row">
      <mat-form-field  class="my-form-field flex flex-row w-64">
        <input autocomplete="off" type="text" matInput
          [placeholder]="searchForm.mode === 'RQ' ? 'เลขที่คำขอ / RQ No.' : 'เลขที่ใบรับรอง / THT No.'" matInput #input
          [(ngModel)]="searchForm.value" name="search" #search="ngModel">
        <button matSuffix mat-icon-button aria-label="ค้นหา" (click)="onSearch()">
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="gap-2.5 flex flex-row" *ngIf="docHeaderList != null && docHeaderList != undefined"> 
      <mat-form-field appearance="fill" class="flex-auto w-64">
        <mat-select [(ngModel)]="form.documentHeadIdSelect" name="documentHeadIdSelect" #documentHeadIdSelect="ngModel">
            <mat-option *ngFor="let docHeader of docHeaderList" [value]="docHeader.documentHeadId" >
                {{docHeader.documentHeadId}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    </div>
     <!-- Data Edit-->
    <button *ngIf="docHeaderList != null && docHeaderList != undefined " 
    class="set-btn-unlimited flex-none ml-4" mat-raised-button color="accent" (click)="onEdit()">
    Edit</button> 
  </div>
 
  <!-- </div> -->
  <div class="mat-elevation-z8">
  </div>
</div>


<div style="gap: 30px;padding-top: 30px;" class="flex flex-col h-full" *ngIf="EditForm == true">
  <div class="items-center  px-20 pr-10 pl-10">
  <!-- SIGNATURE -->
    <mat-card>
      <mat-card-header>
          <div class="text-center">แก้ไขข้อมูลเอกสาร | Document </div>
      </mat-card-header>
      <mat-card-content>
          <section class="px-4 flex flex-col flex-1 py-4  justify-evenly">
            <div class="flex mt-4 gap-3 flexCheckJobRow">
                        <div class="flex flex-row flexWidth50">
                            <div class="w-1/4 flex flex-col">
                                <h4 class="">ชื่อผู้ส่งออกสินค้า<span class="required">*</span></h4>
                                <h4 class="">(ปรากฏบนฟอร์ม)</h4>
                                <h4 class="">Consignor Name</h4>
                            </div>
                            <div class="flex-auto form-field-container">
                                <mat-form-field class="flex-auto" style="width: 100%">
                                    <textarea matInput placeholder="ชื่อผู้ส่งออกสินค้า"
                                        [(ngModel)]="form.consignorName" name="consignorName" id="consignorName"
                                        required rows="4" cols="40" maxlength="100"
                                        (input)="limitTextareaLines($event);"
                                        (ngModelChange)="checkEnglish(form.consignorName) == false ? showErrorMessage.consignorNameEng = true : showErrorMessage.consignorNameEng = false;"></textarea>
                                </mat-form-field>
                                <div class="flex flex-row">
                                    <label *ngIf="showErrorMessage.consignorNameEng == true"
                                        class="required position-absolute">กรอกได้เฉพาะภาษาอังกฤษและสัญลักษณ์</label>
                                    <label
                                        *ngIf="showErrorMessage.consignorName == true && showErrorMessage.consignorNameEng == false"
                                        class="required position-absolute">ไม่สามารถกรอกอักขระพิเศษ</label>
                                    <p class="flex-auto text-end" *ngIf="form.consignorName?.length !=null">
                                        {{form.consignorName?.length+"/100"}}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-row flexWidth50">
                            <div class="w-1/4 flex flex-col">
                                <h4 class="">ที่อยู่<span class="required">*</span></h4>
                                <h4 class="">Consignor Address</h4>
                            </div>
                            <div class="flex-auto form-field-container">
                                <mat-form-field class="flex-auto" style="width: 100%">
                                    <textarea matInput placeholder="ที่อยู่" [(ngModel)]="form.consignorAddress"
                                        name="consignorAddress"  required rows="4"
                                        cols="40" maxlength="150" (input)="limitTextareaLines($event)"
                                        (ngModelChange)="checkEnglish(form.consignorAddress) == false ? showErrorMessage.consignorAddressEng = true : showErrorMessage.consignorAddressEng = false;"></textarea>
                                </mat-form-field>
                                <div class="flex flex-row">
                                    <label *ngIf="showErrorMessage.consignorAddressEng == true"
                                        class="required position-absolute">กรอกได้เฉพาะภาษาอังกฤษและสัญลักษณ์</label>
                                    <label
                                        *ngIf="showErrorMessage.consignorAddress == true && showErrorMessage.consignorAddressEng == false"
                                        class="required position-absolute">ไม่สามารถกรอกอักขระพิเศษ</label>
                                    <p class="flex-auto text-end" *ngIf="form.consignorAddress?.length != null">
                                        {{ form.consignorAddress?.length + "/150" }}
                                    </p>
                                </div>
                            </div>
                        </div>
              </div>
              <div class="flex mt-4 gap-3 flexCheckJobRow">
                <div class="flex flex-row flexWidth50">
                    <div class="w-1/4 flex flex-col">
                        <h4 class="">ผู้รับสินค้าปลายทาง<span class="required">*</span></h4>
                        <h4 class="">Consignee</h4>
                    </div>
                    <div class="flex-auto form-field-container">
                        <mat-form-field class="flex-auto w-full">
                          <textarea matInput placeholder="ผู้รับสินค้าปลายทาง"
                              [(ngModel)]="form.consigneeName"
                              [ngModelOptions]="{standalone: true}" required="true" rows="4" cols="40"
                              maxlength="185" (input)="limitTextareaLines($event)"
                              (ngModelChange)="checkEnglish(form.consigneeName) == false ? showErrorMessage.consigneeNameEng = true : showErrorMessage.consigneeNameEng = false;">
                          </textarea>
                      </mat-form-field>
                      <div class="flex flex-row">
                          <label *ngIf="showErrorMessage.consigneeNameEng == true"
                              class="required position-absolute">กรอกได้เฉพาะภาษาอังกฤษและสัญลักษณ์</label>
                          <label
                              *ngIf="showErrorMessage.consigneeName == true && showErrorMessage.consigneeNameEng == false"
                              class="required position-absolute">ไม่สามารถกรอกอักขระพิเศษ</label>

                          <p class="flex-auto text-end" *ngIf="form.consigneeName?.length !=null">
                              {{form.consigneeName?.length+"/185"}}
                          </p>
                      </div>
                    </div>
                </div>
                <div class="flex flex-row flexWidth50">
                    <div class="w-1/4 flex flex-col">
                        <h4 class="">ผู้ที่จะได้รับแจ้งเมื่อสินค้าไปถึง</h4>
                        <h4 class="">Notify</h4>
                    </div>
                    <div class="flex-auto form-field-container">
                        <mat-form-field class="flex-auto w-full">
                          <textarea matInput placeholder="ผู้ที่จะได้รับแจ้งเมื่อสินค้าไปถึง"
                              [(ngModel)]="form.consigneeNotify"
                              [ngModelOptions]="{standalone: true}" rows="4" cols="40" maxlength="380"
                              (ngModelChange)="checkEnglish(form.consigneeNotify) == false ? showErrorMessage.consigneeNotifyEng = true : showErrorMessage.consigneeNotifyEng = false;">
                      </textarea>
                      </mat-form-field>
                      <div class="flex flex-row">
                          <label *ngIf="showErrorMessage.consigneeNotifyEng == true"
                              class="required position-absolute">กรอกได้เฉพาะภาษาอังกฤษและสัญลักษณ์</label>
                          <label
                              *ngIf="showErrorMessage.consigneeNotify == true && showErrorMessage.consigneeNotifyEng == false"
                              class="required position-absolute">ไม่สามารถกรอกอักขระพิเศษ</label>
                          <p class="flex-auto text-end" *ngIf="form.consigneeNotify?.length !=null">
                              {{form.consigneeNotify?.length+"/380"}}
                          </p>
                      </div>
                    </div>
                </div>
              </div>
              <div class="flex mt-4 gap-3 flexCheckJobRow">
                <div class="flex flex-row flexWidth50">
                    <div class="w-1/4 flex flex-col">
                        <h4 class="">ชื่อพาหนะ<span class="required">*</span></h4>
                    </div>
                    <div class="flex-auto form-field-container">
                        <mat-form-field class="flex-auto w-full">
                          <input matInput placeholder="ชื่อพาหนะ" [(ngModel)]="form.vesselNo"
                              [ngModelOptions]="{standalone: true}"
                               required="true" maxlength="50"
                              (ngModelChange)="checkEnglish(form.vesselNo) == false ? showErrorMessage.vesselNoEng = true : showErrorMessage.vesselNoEng = false;">
                      </mat-form-field>
                      <div class="flex flex-row">
                          <label *ngIf="showErrorMessage.vesselNoEng == true"
                              class="required position-absolute">กรอกได้เฉพาะภาษาอังกฤษและสัญลักษณ์</label>
                          <label
                              *ngIf="showErrorMessage.vesselNo == true && showErrorMessage.vesselNoEng == false"
                              class="required position-absolute">ไม่สามารถกรอกอักขระพิเศษ</label>
                          <p class="flex-auto text-end" *ngIf="form.vesselNo?.length !=null">
                              {{form.vesselNo?.length+"/50"}}
                          </p>
                      </div>
                    </div>
                </div>
                <div class="flex flex-row flexWidth50">
                    
  
                </div>
              </div>
              <div>
                <button class="set-btn-unlimited flex-none ml-4" mat-raised-button color="accent" (click)="saveData()">บันทึก</button>
              </div>
          </section>
      </mat-card-content>
    </mat-card>
  </div>
</div>