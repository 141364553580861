import { filter } from "rxjs";
import { AppMasterService } from "app/business/service/app/app-master.service";
import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AppPaymentService } from "app/business/service/app/app-payment.sevice";
import _ from "lodash";
import { AppUserService } from "app/business/service/app/app-user.service";
import { AuthService } from "app/core/auth/auth.service";

@Component({
  selector: "app-add-other-payment-requset-modal",
  templateUrl: "./add-other-payment-requset-modal.component.html",
  styleUrls: ["./add-other-payment-requset-modal.component.css"],
})
export class AddOtherPaymentRequsetModalComponent implements OnInit {
  form: any = {};
  detailRc: any = {};
  detailExdList: any = [];
  feeRateList: any = [];
  dataSource: any = [];

  userProfile: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<AddOtherPaymentRequsetModalComponent>,
    private appMasterService: AppMasterService,
    private appPaymentService: AppPaymentService,
    private appUserService:AppUserService,
    private _authService:AuthService,
  ) {
    this.userProfile = JSON.parse(
      this._authService.loginUser
    )
  }

  ngOnInit() {
    this.appMasterService.getMasterFeeRateList({}).then((res) => {
      this.feeRateList = res.filter((f) => f.type == "M");
    });

    if (this.data.detailExdList != undefined) {
      this.detailExdList = this.data.detailExdList.map((res) => {
        return {
          th_number: res.certificateNo,
        };
      });
    }
    // 
  }

  selectionFeeRate(e) {
    // 
  }

  selectionChanged(e) {
    this.form.reprint = e.selectedRowsData;
  }

  tabChanged(e) {
    if (e.index == 1) {
      this.appPaymentService
        .dataReprintForPay({
          cpId: this.userProfile.cpId,
          declaratorId: this.data.declaratorId,
        })
        .then((res) => {
          this.dataSource = res;
          this.dataSource = _.differenceBy(
            this.dataSource,
            this.detailExdList,
            "th_number"
          );
          
        });
    }
  }
}
