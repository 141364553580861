import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'environments/environment'
import { reject, values } from 'lodash'
import { ApexYAxis } from 'ng-apexcharts'
import {
  BehaviorSubject,
  map,
  Observable,
  of,
  Subject,
  Subscription,
  switchMap,
} from 'rxjs'
import { ApiVerifyService } from '../api/api-verify.service'
import {
  IGetVerifyCOResponse,
  MarkError,
} from 'app/business/interface/app/app-co.interface'
import { IResGetQueueAddition } from 'app/business/interface/api/api-verify.interface'

@Injectable({
  providedIn: 'root',
})
export class AppVerifyService {
  errorList: BehaviorSubject<MarkError[]>
  subscription?: Subscription
  constructor(
    private httpClient: HttpClient,
    private apiVerifyService: ApiVerifyService,
  ) {
    this.errorList = new BehaviorSubject([])
  }

  setErrorList(data: MarkError[]) {
    this.errorList.next(data)
  }

  getQueueNum(): any {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
    return new Promise((resolve, reject) => {
      this.subscription = this.apiVerifyService.getQueue().subscribe((res) => {
        resolve(res)
      })
    })
  }

  checkQueueNum(): any {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
    return new Promise((resolve, reject) => {
      this.subscription = this.apiVerifyService
        .checkQueue()
        .subscribe((res) => {
          resolve(res)
        })
    })
  }

  
  getQueueOrder(): any {
    return new Promise((resolve, reject) => {
      this.apiVerifyService.getQueueOrder().subscribe((res) => {
        resolve(res)
      })
    })
  }
  getVerifyCO(request: any): Promise<IGetVerifyCOResponse> {
    // if (this.subscription) {
    //   this.subscription.unsubscribe();
    // }
    return new Promise((resolve, reject) => {
      this.subscription = this.apiVerifyService
        .getVerifyCO(request)
        .pipe(
          switchMap((val) => {
            return of(val)
          }),
        )
        .subscribe((res) => {
          resolve(res)
        })
    })
  }

  saveErrorMark(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiVerifyService.saveErrorMark(request).subscribe((res) => {
        resolve(res)
      })
    })
  }

  saveVerifyCO(request: any): Promise<any> {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
    return new Promise((resolve, reject) => {
      this.subscription = this.apiVerifyService
        .saveVerifyCO(request)
        .subscribe((res) => {
          resolve(res)
        })
    })
  }

  setQueueStatus(request: any) {
    return new Promise((resolve, reject) => {
      this.apiVerifyService.setQueueStatus(request).subscribe((res) => {
        resolve(res)
      })
    })
  }

  saveVerifyRecheck(request: any) {
    return new Promise((resolve, reject) => {
      this.apiVerifyService.saveVerifyRecheck(request).subscribe((res) => {
        resolve(res)
      })
    })
  }

  saveTranferDocumentRequest(request: any) {
    return new Promise((resolve, reject) => {
      this.apiVerifyService.saveTranferDocumentRequest(request).subscribe((res) => {
        resolve(res)
      })
    })
  }
  getVerifyReprintList(request: any) {
    return new Promise((resolve, reject) => {
      this.apiVerifyService.getVerifyReprintList(request).subscribe((res) => {
        resolve(res)
      })
    })
  }

  getVerifyReprintListDetail(request: any) {
    return new Promise((resolve, reject) => {
      this.apiVerifyService.getVerifyReprintListDetail(request).subscribe((res) => {
        resolve(res)
      })
    })
  }

  saveReprintStatus(request: any) {
    return new Promise((resolve, reject) => {
      this.apiVerifyService.saveReprintStatus(request).subscribe((res) => {
        resolve(res)
      })
    })
  }

  getVerifyHistoryList(request: any) {
    return new Promise((resolve, reject) => {
      this.apiVerifyService.getVeirfyHistoryList(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getAllQueueOrder() {
    return new Promise((resolve, reject) => {
      this.apiVerifyService.getAllQueueOrder({}).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getQueueAddition() : Promise<IResGetQueueAddition> {
    return new Promise((resolve, reject) => {
      this.apiVerifyService.getQueueAddition({}).subscribe((res) => {
        resolve(res);
      });
    });
  }

  checkUserQ() : any {
    return new Promise((resolve, reject) => {
      this.apiVerifyService.checkUserQ().subscribe((res) => {
        resolve(res);
      });
    });
  }

  saveErrorMarker(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiVerifyService.saveErrorMarker(request).subscribe((res) => {
        resolve(res);
      })
    })
  }

  saveErrorMarkActiveStatus(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiVerifyService.saveErrorMarkActiveStatus(request).subscribe((res) => {
        resolve(res);
      })
    })
  }

  getCoErrorMarkList(request: number): any {
    return new Promise((resolve, reject) => {
      this.apiVerifyService.getCoErrorMarkList(request).subscribe((res) => {
        this.errorList.next(res);
        resolve(res);
      })
    })
  }
}
