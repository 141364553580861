import { LiveAnnouncer } from '@angular/cdk/a11y';
import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'
import { DatePipe } from '@angular/common';
import { AppCOService } from 'app/business/service/app/app-co.service';
import { IReqGetViewVerifyWorkListRequest } from 'app/business/interface/app/app-verify-interface';
import { AppFactoryManagementService } from 'app/business/service/app/app-factorymanagement.service';
import { BlacklistModalComponent } from '../blacklist-modal/blacklist-modal.component';
import { FactoryHistoryModalComponent } from '../../list-factory-history-inquiry/factory-history-modal/factory-history-modal.component';
import { debounceTime, map, startWith, switchMap, takeUntil } from "rxjs/operators";
import { HttpLoadingService } from '@bundle/service/http-loading.service';
import { of, Subject } from "rxjs";
import { companySearch } from 'app/business/interface/app/app-company.interace'
import { AppCompanyService } from 'app/business/service/app/app-company.service'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { AuthService } from 'app/core/auth/auth.service';

@Component({
  selector: 'app-blacklist-search-factory-modal',
  templateUrl: './blacklist-search-factory-modal.component.html',
  styleUrls: ['./blacklist-search-factory-modal.component.scss']
})
export class BlacklistSearchFactoryModalComponent implements OnInit, AfterViewInit {


  displayedColumns: string[] = [
    "cid",
    "changeUpComDate",
    "companyNameTH",
    "companyNameEN",
    "addressStreet",
    "province",
    "phone",
    "blacklist",
    "grade",
    "action",
  ];
  dataSource = new MatTableDataSource<any>();
  count: number
  selection = new SelectionModel<any>(true, []);
  datePipe: DatePipe = new DatePipe('en-US');

  companyDetail: any = {};
  data: any = {};
  searchForm: any = {}
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  pageSend: any;
  searchCompanyForm: FormGroup;
  valueSelect: boolean = false;
  isLoading: boolean = false;
  filteredOptions$: any = [];
  userToken:any;
  constructor(
    private _formBuilder: FormBuilder,

    @Inject(MAT_DIALOG_DATA) data,
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private appCoService: AppCOService,
    private alertMessageService: AlertMessageService,
    private appFactoryManagement: AppFactoryManagementService,
    private httpLoadingService: HttpLoadingService,
    private appCompanyService: AppCompanyService,
    private _authService:AuthService,
  ) {
    this.pageSend = data;
  }

  ngOnInit(): void {
    this.userToken = JSON.parse(
      this._authService.loginUser
    )
    this.searchCompanyForm = this._formBuilder.group({
      companySearch: ['']
    });
    this.valueChange();

    this.loadData();
  }

  async loadData() {
    
    if (this.paginator != undefined && this.paginator != null) {
      this.paginator.pageIndex = 0
    }
    this.dataSource.paginator = this.paginator;
    this.data.pagination = {
      Skip: 0,
      Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
    };
    this.data.companyName = this.searchForm.companyName,
    this.data.cid = this.searchForm.compCode
    this.data.action = "searchbox";

    await this.appFactoryManagement.GetListBlacklistSearch(this.data).then((res) => {
      if (res === "error") {
        return;
      } else {
        this.dataSource.data = res.blacklist;
        this.count = res.totalCount;
      }
    }); // กำหนดค่า total
    this.dataSource.paginator.length = this.count;
  }
  ngAfterViewInit() {
    this.paginator.pageIndex = 0;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce("Sorting cleared");
    }
  }

  async pageChanged(e) {

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.data.pagination = {
      Skip: (!e.pageIndex ? 0 : e.pageIndex) * (!e.pageSize ? 0 : e.pageSize),
      Take: !e.pageSize ? 10 : e.pageSize,
    };
    this.data.companyNameTH = this.searchForm.companyName,
      this.data.companyNameEN = this.searchForm.companyName,
      this.data.cid = this.searchForm.compCode

    await this.appFactoryManagement.GetListBlacklistSearch(this.data).then((res) => {
      if (res === "error") {
        return;
      } else {
        this.dataSource.data = res.blacklist;
        this.count = res.totalCount;
      }
    }); // กำหนดค่า total
    this.dataSource.paginator.length = this.count;
    this.dataSource.paginator.pageIndex = e.pageIndex; // กำหนด pageIndex
  }


  async getDetailBlacklist(e) {
    const data = {
      id: e == null ? 0 : e.id,
      companyCode: e == null ? "" : e.cid,
    };
    await this.appFactoryManagement.GetDataCompany(data).then((res: any) => {
      if (res === "error") {
        return;
      } else {
        this.companyDetail = res;
      }
    });
    const dialogRef = this.dialog.open(BlacklistModalComponent, {
      disableClose: false,
      width: "90vw",
      data: this.companyDetail,
      maxWidth: "none",
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        const add = {
          uid: this.userToken.uId,
          id: res.data.id,
          companyId: res.data.companyId,
          grade: res.data.grade,
          gradeReason: res.data.gradeReason,
          lockStatus: res.data.lockStatus,
          lockReason: res.data.lockReason,
          factoryName: res.data.factoryName,
          factoryNo: res.data.factoryNo,
          isFactory: res.data.isFactory,
          gradeDate: res.data.gradeDate,
          gradeFilePath: res.data.gradeFilePath,
          blacklistType: "M",
          refBlacklistId: res.data.lockStatus === 'N' ? res.data.refBlacklistId : null
        };
        this.appFactoryManagement.UpdateBlackList(add).then((res: any) => {
          if (res == "error") {
            return
          } else {
            this.loadData();
          }
        });
      } else {
        return;
      }
    });
  }

  async getDetailFactory(e) {
    debugger;
    const dialogRef = this.dialog.open(FactoryHistoryModalComponent, {
      disableClose: false,
      width: '90vw',
      data: e.companyFactoryData,
      maxWidth: 'none',
    })

    dialogRef.afterClosed().subscribe(res => {
      this.loadData();
    })
  }

  clear() {
    this.searchForm.companyName = "";
    this.searchForm.compCode = "";

    this.searchCompanyForm.get('companySearch').setValue("");
  }

  selectionChange(e): void {
    this.searchCompanyForm.get('companySearch').setValue(e.option.value.companyCode);
    this.companySelect(e.option.value);
  }

  companySelect(e) {
    this.searchForm.compCode = e.companyCode;
    this.searchForm.companyName = e.companyNameTh;

    this.valueSelect = true;
  }

  valueChange() {
    this.filteredOptions$ = this.searchCompanyForm.get('companySearch').valueChanges.pipe(
      debounceTime(500),
      switchMap(value => {
        if (!value) {
          this.valueSelect = false;
          this.searchForm.companyCode = null;
          return of(null);
        }

        this.httpLoadingService.enabled = false;
        this.isLoading = true;

        const key = value;
        if (key !== '') {
          if (this.valueSelect) {
            return of(null);
          }
          else {
            this.valueSelect = false;
            return this.appCompanyService.getListCompanyByFilter(key);
          }
        } else {
          this.valueSelect = false;
          this.isLoading = false;
          return;
        }

      }),
      map((res: any[]) => {
        this.isLoading = false;
        this.httpLoadingService.enabled = true;

        return res?.map(item => {
          const { companyCode, companyNameTh } = (item as companySearch);
          return {
            ...item,
            // tslint:disable-next-line: max-line-length
            display: `${companyCode} ${companyNameTh}`
          };
        });
      })
    );
  }

}
