<!-- Button -->
<button
    mat-icon-button
    [matTooltip]="tooltip || 'ขยายเต็มหน้าจอ'"
    (click)="toggleFullscreen()">
    <ng-container [ngTemplateOutlet]="iconTpl || defaultIconTpl"></ng-container>
</button>

<!-- Default icon -->
<ng-template #defaultIconTpl>
    <mat-icon style="color: #fff;" [svgIcon]="'heroicons_outline:arrows-expand'"></mat-icon>
</ng-template>
