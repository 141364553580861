import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AppBankService } from 'app/business/service/app/app-bank.service';
import { ConfirmPopupComponent } from '../../confirm-popup/confirm-popup.component';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { DxDataGridComponent } from 'devextreme-angular'
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { AppFactoryManagementService } from 'app/business/service/app/app-factorymanagement.service';
import { CoErrorMarkImagePopupComponent } from '../../create-co-inquiry/co-error-mark-image-popup/co-error-mark-image-popup.component';

import _ from 'underscore';
import { ConfirmDialogService } from '@bundle/component/confirm-dialog/confirm-dialog.service';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service';
import { ImageModalComponent } from '../../create-co-inquiry/Image-Modal/Image-Modal.component';
@Component({
    selector: 'app-blacklist-modal',
    templateUrl: './blacklist-modal.component.html',
    styleUrls: ['./blacklist-modal.component.scss']
})
export class BlacklistModalComponent implements OnInit, AfterViewInit {
    form: any = {
        companyNameTH: '',
        companyNameEN: '',
        contact: '',
        email: '',
        phone: '',
        address: [],
        lockStatus: '',
        grade: '',
        attachments: {},
        history: {},
        id: '',
        companyId: '',
        factoryName: ''
    }
    addrOpt: any;
    bankList: any;
    displayedColumns: string[] = ['date','status', 'reason','grade','reasonGrade' , 'user'  ];
    dataSource2 = new MatTableDataSource<any>();
    editItem: boolean
    attachTypeList: any[] = [];
    dataList: any[] = [];


    file: any
    value: any[] = [];
    reasonList: any[] = [];

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

    constructor(
        @Inject(MAT_DIALOG_DATA) data,
        public dialogRef: MatDialogRef<BlacklistModalComponent>,
        public dialog2: MatDialogRef<ConfirmPopupComponent>,
        private dialog: MatDialog,
        private _liveAnnouncer: LiveAnnouncer,
        private appMasterService: AppMasterService,
        private appFactoryManagement: AppFactoryManagementService,
        private confirmService: ConfirmDialogService,
        private alertService: AlertMessageService,
    ) {
        this.form.id = data.cid;
        this.form.companyId = data.companyId;
        this.form.companyNameTH = data.companyNameTH;
        this.form.companyNameEN = data.companyNameEN;
        this.form.contact = data.contact;
        this.form.email = data.email;
        this.form.phone = data.phone;
        this.form.address.push(data.companyAddress);
        this.form.lockStatus = data.lockStatus;
        this.form.grade = data.grade;
        this.form.factoryName = data.factoryName;
        this.form.factoryNo = data.factoryNo;
        this.form.isFactory = data.isFactory;
        this.addrOpt = data.companyAddress;
        this.form.history = data.history;
        this.dataSource2.paginator = this.paginator;
        this.dataSource2.sort = this.sort;
        this.dataSource2.data = this.form.history;
        this.form.gradeDate = data.gradeDate;
        this.form.gradeFilePath = data.gradeFilePath

        const mainAddressList = this.addrOpt.filter(e => e.companyAddressType === "M");

        if (mainAddressList !== undefined && mainAddressList.length > 0) {
            const preValue = _.first(mainAddressList);
            this.form.selectAddress = preValue.addressId;
        } else if (this.addrOpt.length > 0) {
            const preValue = _.first(this.addrOpt);
            this.form.selectAddress = preValue.addressId;
        } else {
            this.form.selectAddress = '';
        }
    }
    ngOnInit(): void {
        this.appFactoryManagement.getAllReasonLog(this.form.id).then(res => {
            if (res === 'error') {
                this.reasonList = [];
            } else {
                this.reasonList = res;

                if (this.reasonList.length === 0) {
                    this.reasonList.unshift({logId: '', logReason: 'ไม่พบข้อมูล'});
                    this.form.refBlacklistId = '';
                } else if (this.reasonList.length === 1) {
                    const preValue = _.first(this.reasonList);
                    this.form.refBlacklistId = preValue.logId;
                } else {
                    var checkAll = this.reasonList.find(e => e.logReason === 'กรุณาเลือก');
                    
                    if (checkAll === undefined) {
                        this.reasonList.unshift({logId: '', logReason: 'กรุณาเลือก'});
                        this.form.refBlacklistId = '';
                    }
                }
            }
        })
    }

    ngAfterViewInit() {
        this.dataSource2.paginator = this.paginator;
        this.dataSource2.sort = this.sort;
    }
    fileLoad(){
        let ext = this.form.gradeFilePath.split('.').pop
        if (ext.toLowerCase() === "jpg" || ext.toLowerCase() === "jpeg" || ext.toLowerCase() === "gif" || ext.toLowerCase() === "png"){
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = false;
            dialogConfig.autoFocus = false;
            dialogConfig.minHeight = '30vh';
            dialogConfig.minWidth = '30vh';
            dialogConfig.maxHeight = '100vh';
            dialogConfig.data = {
              fullImageUrl: this.form.gradeFilePath
            };
        
            const dialogRef = this.dialog.open(ImageModalComponent, dialogConfig)
        } else {
            window.open(this.form.gradeFilePath, '_blank').focus();
        }
    }
    save() {
        if (!this.form.lockReason && this.form.lockStatus === 'Y') {
            
        this.alertService.open({
            message: 'กรุณาระบุเหตุผลที่ต้องการล็อค',
            title: 'กรุณาตรวจสอบข้อมูลให้ครบถ้วน',
          })
          return false
        } else {
            if (this.form.lockStatus === 'N') {
                if (this.form.refBlacklistId === '' || this.form.gradeReason === 0){
                    this.alertService.open({ message: "กรุณาเลือกรายการที่จะปลดล็อค", title: "" });
                    return false
                }    
            }
            
            this.confirmService.open({
                header: 'ยืนยันทำรายการ',
                content : 'ต้องการบันทึกข้อมูล'
            }).then((res)=>{
                if(res == true){
                this.dialogRef.close({ data: this.form });
                }
                else{
                    return
                }
            })
        }
    }



    announceSortChange(sortState: Sort) {
        if (sortState.direction) {
            this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
        } else {
            this._liveAnnouncer.announce("Sorting cleared");
        }
    }


    pageChanged($event) {

    }

    async upload(): Promise<void> {
        
        if (this.value.length === 0) {
            return
        }
        // const file = event.value.files[0];
        this.value.forEach((item) => {
            this.file = item
            
            this.onUploadBr()
        })
        this.value = []
    }

    async onFileChangedBr(event): Promise<void> {
        if (event.value.length === 0) {
            return
        }
        // const file = event.value.files[0];
        event.value.forEach((item) => {
            this.file = item
            this.onUploadBr()
        })
        this.value = []
    }

    onUploadBr(): void {
        const formData = new FormData()
        formData.append('image', this.file, this.file.name)
        formData.append('name', 'Attachments');

        this.appMasterService.uploadImageToBlobs(formData).subscribe((result) => {
            if (result) {
                const attachments = {
                    attachPicId: 0,
                    docHid: 0,
                    attachFilePic: result[0]?.filePath,
                    attachType: '',
                    remark: '',
                    attachHeaderType: 0,
                    isActive: 'Y',
                }
                
                this.form.attachments = attachments;
                
            }
        })
    }

    Removing(): void {
        this.form.attachments = {};
        // this.removeItem(e.data);
    }

    ReasonLockPopup(e) {
        const id = { id: e.id}
        this.appFactoryManagement.GetReasonLog(id).then((res: any) => {
            if (res) {
                
                const ReasonPopup = this.dialog.open(ConfirmPopupComponent, {
                    disableClose: false,
                    // width: '30vw',
                    data: {
                        header: res.reasonLock,
                        button: 'close'
                    },
                    maxWidth: 'none'
                });

                ReasonPopup.afterClosed().subscribe(res => {
                });
            }
            else {
                return
            }
        })
    }

    ReasonGradePopup(e) {
        const id = { id: e.id}
        this.appFactoryManagement.GetReasonLog(id).then((res: any) => {
            if (res) {

                const ReasonPopup = this.dialog.open(ConfirmPopupComponent, {
                    disableClose: false,
                    // width: '30vw',
                    data: {
                        header: res.reasonGrade,
                        button: 'close'
                    },
                    maxWidth: 'none'
                });

                ReasonPopup.afterClosed().subscribe(res => {
                });
            }
            else {
                return
            }
        })
    }

}
