import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '@bundle/service/api.service';

@Injectable({
  providedIn: 'root'
})
export class ApiQueueService {

  constructor(private apiService: ApiService) { }

  getQueueCount(request:any): Observable<any[]> {
    return this.apiService.post("/api/getQueueCount",request);
  }

}
