<mat-card-header>
  <div class="flex justify-between" style="width: 100%;">
    <div class="text-lg"> CF Print Position</div>
    <div>
      <button class="close-button" [mat-dialog-close]="true">
        <mat-icon class="text-white" [mat-dialog-close]>cancel</mat-icon>
      </button>
    </div>
  </div>
</mat-card-header>
<mat-dialog-content style="max-height: 90vh !important;">
  <div class="flex flex-row">
    <div class="basis-full">
      <div class="card flex-column" style="margin-bottom: 0px !important;padding-bottom: 0px !important;">
        <div>
          <h1 class="text-xl"><b> Request No :&nbsp; </b>{{form.rqNo}}</h1>
        </div>
        <div>
          <h1 class="text-xl"><b>Total : </b> {{form.page}} / {{form.totalPage}}</h1>
        </div>
        <div class="flex flex-row justify-between pb-0">
          <div>
            <h1 class="text-xl"><b>ตำแหน่งในการพิมพ์เอกสาร</b></h1>
          </div>
          <!-- <div>
            <mat-checkbox class="text-xl" (change)="onCheckAll()" [(ngModel)]="checkall">Check All
            </mat-checkbox>
          </div> -->
        </div>
        <div class="basis-full text-center imgBlackWhite">
          <div class="flex-column">
            <div class="flex flex-row justify-center">
              <div class="flex flex-row p-3">
                <mat-checkbox [(ngModel)]="positionPrint[0]" [checked]="positionPrint[0]"
                  (change)="onCheckboxChange($event,0)"></mat-checkbox>
                <mat-card class="w-30 imgBlackWhite" (click)="clickCheck($event,0)">
                  <img src="assets\icons\icon.png" class="imgBlackWhite" />
                </mat-card>
              </div>
              <div class="flex flex-row p-3">
                <mat-checkbox [(ngModel)]="positionPrint[1]" [checked]="positionPrint[1]"
                  (change)="onCheckboxChange($event,1)"></mat-checkbox>
                <mat-card class="w-30 imgBlackWhite" (click)="clickCheck($event,1)">
                  <img src="assets\icons\icon.png" class="imgBlackWhite" />
                </mat-card>
              </div>
              <div class="flex flex-row p-3">
                <mat-checkbox [(ngModel)]="positionPrint[2]" [checked]="positionPrint[2]"
                  (change)="onCheckboxChange($event,2)"></mat-checkbox>
                <mat-card class="w-30 imgBlackWhite" (click)="clickCheck($event,2)">
                  <img src="assets\icons\icon.png" class="imgBlackWhite" />
                </mat-card>
              </div>
            </div>
          </div>
          <div class="flex-column">
            <div class="flex flex-row justify-center">
              <div class="flex flex-row p-3">
                <mat-checkbox [(ngModel)]="positionPrint[3]" [checked]="positionPrint[3]"
                  (change)="onCheckboxChange($event,3)"></mat-checkbox>
                <mat-card class="w-30 imgBlackWhite" (click)="clickCheck($event,3)">
                  <img src="assets\icons\icon.png" class="imgBlackWhite" />
                </mat-card>
              </div>
              <div class="flex flex-row p-3">
                <mat-checkbox [(ngModel)]="positionPrint[4]" [checked]="positionPrint[4]"
                  (change)="onCheckboxChange($event,4)"></mat-checkbox>
                <mat-card class="w-30 imgBlackWhite" (click)="clickCheck($event,4)">
                  <img src="assets\icons\icon.png" class="imgBlackWhite" />
                </mat-card>
              </div>
              <div class="flex flex-row p-3">
                <mat-checkbox [(ngModel)]="positionPrint[5]" [checked]="positionPrint[5]"
                  (change)="onCheckboxChange($event,5)"></mat-checkbox>
                <mat-card class="w-30 imgBlackWhite" (click)="clickCheck($event,5)">
                  <img src="assets\icons\icon.png" class="imgBlackWhite" />
                </mat-card>
              </div>
            </div>
          </div>
          <div class="flex-column">
            <div class="flex flex-row justify-center">
              <div class="flex flex-row p-3">
                <mat-checkbox [(ngModel)]="positionPrint[6]" [checked]="positionPrint[6]"
                  (change)="onCheckboxChange($event,6)"></mat-checkbox>
                <mat-card class="w-30 imgBlackWhite" (click)="clickCheck($event,6)">
                  <img src="assets\icons\icon.png" class="imgBlackWhite" />
                </mat-card>
              </div>
              <div class="flex flex-row p-3">
                <mat-checkbox [(ngModel)]="positionPrint[7]" [checked]="positionPrint[7]"
                  (change)="onCheckboxChange($event,7)"></mat-checkbox>
                <mat-card class="w-30 imgBlackWhite" (click)="clickCheck($event,7)">
                  <img src="assets\icons\icon.png" class="imgBlackWhite" />
                </mat-card>
              </div>
              <div class="flex flex-row p-3">
                <mat-checkbox [(ngModel)]="positionPrint[8]" [checked]="positionPrint[8]"
                  (change)="onCheckboxChange($event,8)"></mat-checkbox>
                <mat-card class="w-30 imgBlackWhite" (click)="clickCheck($event,8)">
                  <img src="assets\icons\icon.png" class="imgBlackWhite" />
                </mat-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <button mat-raised-button color="accent" class="mr-5" (click)="save()">
    <mat-icon svgIcon="heroicons_outline:printer"></mat-icon>&nbsp;
    Print
  </button>
  <button mat-raised-button [mat-dialog-close]>
    ย้อนกลับ
  </button>
</mat-dialog-actions>