import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { ConfirmPopupComponent } from '../../confirm-popup/confirm-popup.component';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'

@Component({
  selector: 'app-co-branch-manage-popup',
  templateUrl: './co-branch-manage-popup.component.html',
  styleUrls: ['./co-branch-manage-popup.component.css']
})
export class CoBranchManagePopupComponent implements OnInit {
  form: any = {};
  bankList: any;
  provinceList: any[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef: MatDialogRef<CoBranchManagePopupComponent>,
    private appMasterService: AppMasterService,
    private dialog: MatDialog,
    private alertMessageService: AlertMessageService
  ) {
    if (data) {
      this.form = data;
      if (this.form.provinceCode === 0) {
        this.form.provinceCode = '';
      }
    } else {
      this.form.isActive = 'Y';
      this.form.isMemberDiscount = 0;
      this.form.isMainCenter = 0;
      this.form.provinceCode = '';
    }
  }
  ngOnInit(): void {
    this.appMasterService.getMasterProvince({}).then((res: any) => {
      if (res === "error") {
        return;
      } else {
        this.provinceList = res;
        if (this.provinceList.length === 1) {
          this.form.provinceCode = this.provinceList[0].provinceCode;
        } else {
          const allProvince = this.provinceList.find(element => element.provinceCode === '');
          if (allProvince === undefined) {
            this.provinceList.unshift({ "provinceCode": "", "provinceName": "กรุณาเลือก" });
          }
        }

      }
    });
  }

  save() {
    
    if (
      (this.form.coBranchName !== undefined && this.form.coBranchName !== null && this.form.coBranchName !== "") &&
      (this.form.description !== undefined && this.form.description !== null && this.form.description !== "") &&
      (this.form.provinceCode !== undefined && this.form.provinceCode !== null && this.form.provinceCode !== "") &&
      (this.form.taxId !== undefined && this.form.taxId !== null && this.form.taxId !== "") &&
      (this.form.accountRef !== undefined && this.form.accountRef !== null && this.form.accountRef !== "") &&
      (this.form.rateReceive !== undefined && this.form.rateReceive !== null && this.form.rateReceive !== "") &&
      (this.form.rcProfileId !== undefined && this.form.rcProfileId !== null && this.form.rcProfileId !== "") &&
      (this.form.rcProfileCode !== undefined && this.form.rcProfileCode !== null && this.form.rcProfileCode !== "")
    ) {
      const data = {
        "coBranchId": this.form.coBranchId,
        "coBranchName": this.form.coBranchName.trim(),
        "description": this.form.description.trim(),
        "provinceCode": this.form.provinceCode,
        "provinceName": this.provinceList.find(e => e.provinceCode === this.form.provinceCode).provinceName.trim(),
        "taxId": this.form.taxId.trim(),
        "accountRef": this.form.accountRef.trim(),
        "rateReceive": this.form.rateReceive,
        "rcProfileId": this.form.rcProfileId,
        "rcProfileCode": this.form.rcProfileCode.trim(),
        "isMainCenter": this.form.isMainCenter,
        "isMemberDiscount": this.form.isMemberDiscount,
        "isActive": this.form.isActive
      };

      this.appMasterService.saveCoBranch(data).then((res: any) => {
        if (res === "error") {
          this.alertMessageService.open({message: "บันทึกข้อมูลไม่สำเร็จ", title:""});
        } else {
          this.dialogRef.close();
          this.alertMessageService.open({message: "บันทึกข้อมูลสำเร็จ", title:""});
        }
      });
    } else {
      this.alertMessageService.open({ message: "กรุณากรอกข้อมูลให้ครบ", title: "ข้อผิดพลาด" });
    }
  }

  onChange(e) {
    if (e.checked == true) {
      this.form.isActive = "Y";
    } else {
      this.form.isActive = "N";
    }
  }

  onChangeDiscount(e) {
    if (e.checked == true) {
      this.form.isMemberDiscount = 1;
    } else {
      this.form.isMemberDiscount = 0;
    }
  }

  onChangeMainCenter(e) {
    if (e.checked == true) {
      this.form.isMainCenter = 1;
    } else {
      this.form.isMainCenter = 0;
    }
  }

  endPopup(e) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      disableClose: false,
      // width: '30vw',
      data: {
        header: e,
        button: 'close'
      },
      maxWidth: 'none'
    });

    dialogRef.afterClosed().subscribe(res => {
      this.dialogRef.close();
    });
  }
}
