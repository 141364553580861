<div style="gap: 30px;padding-top: 30px;" class="flex flex-col h-full">
  <!-- <div class="pl-20 pr-20 menu-top justify-between flex items-center flex-row text-white">
      <div class="menu-top-sub flex flex-row">
          <h2 class="text-center cursor-pointer">Request</h2>
          <h2 class="text-center cursor-pointer">Payment</h2>
          <h2 class="text-center cursor-pointer">Print</h2>
          <h2 class="text-center cursor-pointer">Configuration</h2>
      </div>


      <div>
          <h2 class="text-center cursor-pointer">Master bank account</h2>
      </div>
  </div> -->
  <div class="menu justify-between flex items-stretch flex-row gap-5 px-20 pr-10 pl-10">
    <div class="gap-2.5 flex flex-row">
      <div *ngIf="checkButton('BT080')">
        <button  mat-raised-button color="accent" (click)="openMrConfig()">
          <div class="flex items-center">
            <span>Connect MR</span>
          </div>
        </button>
      </div>
      <!--  <button mat-raised-button color="accent" (click)="deleteAccount()">
              <div class="flex items-center">
                  <span>Delete Account</span>
              </div>
          </button> -->
    </div>
    <div style="flex: 1;" class="gap-2.5 flex justify-end flex-row">
      <!-- <div class="justify-evenly flex gap-2.5" style="flex: 0 40%;">
                  <h4>Showing</h4>
                  <mat-form-field style="flex-flow: row;flex: 1" class="flex">
                      <mat-select [(ngModel)]="BenefitTargetValue" name="BenefitTargetValue"
                          (selectionChange)="selectionPercent()">
                          <mat-option *ngFor="let percent of BenefitTarget" [value]="percent.benefitTargetCode">
                              {{percent.benefitTargetTName}}</mat-option>
                      </mat-select>
                  </mat-form-field>
              </div> -->
      <div class="justify-evenly flex gap-2.5 items-center flex-row gap-0" style="flex: 0 40%;">
        <mat-form-field style="flex: 1" class="my-form-field flex flex-row">
          <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" matInput #input [(ngModel)]="form.search"
            name="search" #search="ngModel" (keyup)="applyFilter($event.target.value)">
          <button matSuffix mat-icon-button aria-label="ค้นหา">
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <button mat-raised-button color="accent" [matMenuTriggerFor]="menu">
        <div class="flex items-center">
          <span>เงื่อนไขการค้นหา</span>
          <mat-icon>expand_more</mat-icon>
        </div>
      </button>
      <mat-menu #menu="matMenu" xPosition="before" class="my-menu">
        <div class="pl-2 pr-2 gap-4 flex flex-col" (click)="stopPropagation($event)">
          <div class="justify-evenly flex gap-2.5 items-center flex-row gap-0">
            <mat-form-field style="flex: 1" class="my-form-field flex flex-row">
              <mat-label>Company Code | รหัสบริษัท</mat-label>
              <input autocomplete="off" type="text" matInput placeholder="รหัสบริษัท" matInput #input
                [(ngModel)]="form.companyCode" name="companyCode" #bankAccountName="ngModel">
            </mat-form-field>
          </div>
          <div class="justify-evenly flex gap-2.5 items-center flex-row gap-0">
            <mat-form-field style="flex: 1" class="my-form-field flex flex-row">
              <mat-label>Company Tax No. | เลขที่ประจำตัวผู้เสียภาษี</mat-label>
              <input autocomplete="off" type="text" matInput placeholder="เลขที่ประจำตัวผู้เสียภาษี" matInput #input
                [(ngModel)]="form.companyTaxId" name="companyTaxId" #accTaxNo="ngModel">
            </mat-form-field>
          </div>
          <div class="justify-evenly flex gap-2.5 items-center flex-row">
            <mat-form-field style="flex: 1" class="my-form-field flex flex-row">
              <mat-label>ภาษาไทย</mat-label>
              <input autocomplete="off" type="text" matInput placeholder="ชื่อบริษัท" matInput #input
                [(ngModel)]="form.companyNameTh" name="companyNameTh" #bankAccountNo="ngModel">
            </mat-form-field>
            <mat-form-field style="flex: 1" class="my-form-field flex flex-row">
              <mat-label>English</mat-label>
              <input autocomplete="off" type="text" matInput placeholder="Company Name" matInput #input
                [(ngModel)]="form.companyNameEn" name="companyNameEn" #bankAccountName="ngModel">
            </mat-form-field>
          </div>
          <div class="flex justify-end pt-2">
            <div>
              <button mat-raised-button (click)="clearOption()" style="flex:0 50%; margin-right: 10px;">
                เคลียร์
              </button>
              <button mat-raised-button color="accent" (click)="onSearch()" style="flex:0 50%;">
                ค้นหา
              </button>
            </div>
          </div>
        </div>
      </mat-menu>
    </div>

  </div>
  <!-- </div> -->
  <div class="mat-elevation-z8 ">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <table mat-table matSort style="width: 100%;" [dataSource]="dataSource"
      (matSortChange)="announceSortChange($event)">
      <ng-container matColumnDef="companyTaxId">
        <th mat-header-cell *matHeaderCellDef class="text-left"> เลขที่ประจำตัวผู้เสียภาษี </th>
        <td mat-cell *matCellDef="let element" class="text-left"> {{element.companyTaxId}} </td>
      </ng-container>

      <ng-container matColumnDef="companyCode">
        <th mat-header-cell *matHeaderCellDef class="text-left"> รหัสบริษัท </th>
        <td mat-cell *matCellDef="let element" class="text-left"> {{element.companyCode}} </td>
      </ng-container>

      <ng-container matColumnDef="companyNameTh">
        <th mat-header-cell *matHeaderCellDef> ชื่อบริษัท </th>
        <td mat-cell *matCellDef="let element"> {{element.companyNameTh}} </td>
      </ng-container>

      <ng-container matColumnDef="companyNameEn">
        <th mat-header-cell *matHeaderCellDef> Company Name </th>
        <td mat-cell *matCellDef="let element"> {{element.companyNameEn}} </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="text-left"> การจัดการ </th>
        <td mat-cell *matCellDef="let element;let i = index" class="text-left">
          <div class="flex flex-row" style="justify-content: start;">
              <div *ngIf="checkButton('BT081')">
                <button mat-icon-button matTooltip="รายละเอียด" matTooltipPosition="above"
                  routerLink="/page/config-company/company-profile/" [queryParams]="{
                      companyId: element.cpId
                    }">
                  <mat-icon svgIcon="heroicons_outline:pencil-alt"></mat-icon>
                </button>
                <!-- <button mat-icon-button matTooltip="ลบ" (click)="delete(element)" matTooltipPosition="above">
                  <mat-icon>delete</mat-icon>
              </button> -->
              </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]" (page)="_page($event)" showFirstLastButtons
      aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>

</div>