import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { ActivatedRoute, Router } from '@angular/router'
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'
import { ConfirmDialogService } from '@bundle/component/confirm-dialog/confirm-dialog.service'
import { Location } from '@angular/common'
import { AppMasterService } from 'app/business/service/app/app-master.service'
import { Buffer } from 'buffer'
import { CoErrorMarkImagePopupComponent } from '../../create-co-inquiry/co-error-mark-image-popup/co-error-mark-image-popup.component'
import { AppRecheckService } from 'app/business/service/app/app-recheck.service'
import { IResGetCreateReprintDetail } from 'app/business/interface/app/app-co.interface'
import _ from 'underscore'
import { ImageModalComponent } from '../../create-co-inquiry/Image-Modal/Image-Modal.component'
@Component({
  selector: 'app-create-reprint-inquiry',
  templateUrl: './create-reprint-inquiry.component.html',
  styleUrls: ['./create-reprint-inquiry.component.css']
})
export class CreateReprintInquiryComponent implements OnInit, AfterViewInit {

  dataSourceAttachPic: any[] = []
  form: IResGetCreateReprintDetail = { attachments: [] } as IResGetCreateReprintDetail
  value: any[] = []
  fileImg: any
  file: any
  dataList: any[] = []
  deleteAttachmentList: any = []
  request: any
  editItem: boolean
  imageResize: any
  recheck: any

  countryList: any[] = []
  attachTypeList: any[] = []
  declaratorList: any
  destinationList: any
  branchList: any
  docList: any
  PackidLoadData: number
  resolution: any = {}
  userProfile: any = {}
  companyName: any[] = []
  clicked: boolean
  packId: string
  fieldError: any = {
    declarator: true,
    consignor: true,
    consignee: true,
    notify: true,
    vessel: true,
    loading: true,
    certificateDate: true,
    destination: true,
    invoice: true,
    country: true,
    item: true,
    footer: true,
    title: true,
  }
  search: any
  markErrorList: any = []
  editJobData: boolean
  reprintId: number = 0;
  hId: number = 0;
  disableData: string
  dataSourceUpload: any[] = [];
  formSourceUpload: any[] = [];
  staff: boolean = false;

  constructor(
    private appMasterService: AppMasterService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private alertService: AlertMessageService,
    private confirmService: ConfirmDialogService,
    private router: Router,
    private _location: Location,
    private appRecheckService: AppRecheckService
  ) { }
  backClicked() {
    this._location.back()
  }
  ngOnInit(): void {
    //this.userProfile.uId = JSON.parse(localStorage.getItem('userProfile')).uId
    const { reprintId, hId, disable, staff } = this.route.snapshot.queryParams
    this.hId = hId;
    this.reprintId = reprintId;
    this.disableData = disable;
    this.staff = staff;
    let req = {
      reprintId: this.reprintId,
      hId: this.hId
    }
    this.editItem = this.disableData == 'true' ? true : false
    this.loadData(req)
  }

  ngAfterViewInit() { }

  async loadData(req) {
    req = {
      reprintId: this.reprintId,
      hId: this.hId
    }
    await this.appRecheckService.getCreateReprintDetail(req).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        if (res !== null) {
          this.form = { ...this.form, ...res };
          this.dataSourceAttachPic = this.form.attachments == null ? [] : [...this.form.attachments]
          this.formSourceUpload = this.form.attachments == null ? [] : [...this.form.attachments];
          this.dataSourceUpload = this.form.attachments == null ? [] : [...this.form.attachments];
        }
      }
    })
  }

  validForm(e): boolean {

    if (
      (!e.remark ||
        e.remark === undefined ||
        e.remark == 0 ||
        e.remark == '')
    ) {
      this.alertService.open({
        message: 'กรุณาระบุสาเหตุที่ผิดพลาด',
        title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
      })
      return false
    }

    return true
  }


  async onFileChangedBr(): Promise<void> {
    if (this.value.length === 0) {
      return
    }
    // const file = event.value.files[0];
    this.value.forEach((item) => {
      this.file = item
      if (this.file.size > 3000000) {
        this.alertService.open({
          message: 'รูปมีขนาดใหญ่กว่า 3 MB',
          title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
        })
      } else if (this.file.type == "image/gif") {
        this.alertService.open({
          message: 'ประเภทรูปภาพไม่ถูกต้อง',
          title: 'โปรดตรวจสอบขนาดรูปภาพ',
        })
      }
      else {
        this.resizeImage(this.file)
      }
    })
    this.value = []
  }

  resizeImage(file) {
    var maxW = 1024 //กำหนดความกว้าง
    var maxH = 800 //กำหนดความสูง
    var myReader: FileReader = new FileReader()
    var canvas = document.createElement('canvas')
    var context = canvas.getContext('2d')

    var img = document.createElement('img')
    var that = this
    var result = ''

    img.onload = async function () {
      var iw = img.width
      var ih = img.height

      var scale = Math.min(maxW / iw, maxH / ih)
      var iwScaled = iw * scale
      var ihScaled = ih * scale

      canvas.width = iwScaled
      canvas.height = ihScaled

      context.drawImage(img, 0, 0, iwScaled, ihScaled)

      result += canvas.toDataURL('image/jpeg', 0.5) //0.5 คือ คุณภาพของรูป ที่ Resize

      that.imageResize = result //Base64 รูปภาพ ที่ย่อขนาดแล้ว

      await that.onUpload(that.dataUrlToFile(result, 'test.jpeg'))
      var a = myReader.readAsDataURL(file)
    }

    img.src = URL.createObjectURL(file)
  }

  dataUrlToFile(dataUrl: string, filename: string): File | undefined {
    const arr = dataUrl.split(',')
    if (arr.length < 2) {
      return undefined
    }
    const mimeArr = arr[0].match(/:(.*?);/)
    if (!mimeArr || mimeArr.length < 2) {
      return undefined
    }
    const mime = mimeArr[1]
    const buff = Buffer.from(arr[1], 'base64')
    return new File([buff], filename, { type: mime })
  }

  onUpload(file: any): void {
    if (file.size > 3000000) {
      this.alertService.open({
        message: 'รูปมีขนาดใหญ่กว่า 3 MB',
        title: 'โปรดตรวจสอบขนาดรูปภาพ',
      });
      return;
    }
  
    this.checkIfImageIsValid(file).then((isValid) => {
      if (!isValid) {
        this.alertService.open({
          message: "รูปที่เลือกไม่ถูกต้อง กรุณาอัปโหลดไฟล์ที่ไม่ใช่ Animated PNG",
          title: 'โปรดตรวจสอบรูปภาพ',
        });
        return;
      }
  
      const formData = new FormData();
      formData.append('image', file, file.name);
      formData.append('name', 'Attachments');
  
      this.appMasterService.uploadImageToBlobs(formData).subscribe((result) => {
        if (result) {
          const data = {
            attachId: 0,
            attachUrl: _.first(result)?.filePath,
            remark: ' ',
            isActive: 'Y',
          };
          if (this.form.attachments == null || this.form.attachments == undefined) {
            this.form.attachments = [];
          }
          this.form.attachments.push(data);
          this.dataSourceAttachPic.push(data);
  
          this.formSourceUpload = [];
          this.formSourceUpload = [...this.form.attachments];
          this.dataSourceUpload = [];
          this.dataSourceUpload = [...this.dataSourceAttachPic];
        }
      });
    });
  }  

  async upload() {
    if (this.value.length > 0) {
      const formData = new FormData();
  
      if (this.value.filter(x => x.size > 3145728).length > 0) {
        this.alertService.open({
          message: 'รูปมีขนาดใหญ่กว่า 3 MB',
          title: 'โปรดตรวจสอบขนาดรูปภาพ',
        });
        return;
      }
  
      if (this.value.filter(x => x.type != "image/jpeg" && x.type != "image/png").length > 0) {
        this.alertService.open({
          message: 'รูปที่เลือกไม่ถูกต้อง กรุณาอัปโหลดเฉพาะไฟล์ .JPG หรือ .PNG เท่านั้น หรือตรวจสอบการตั้งค่าเครื่องสแกนเนอร์',
          title: '',
        });
        return;
      }
  
      for (let file of this.value) {
        const isValid = await this.checkIfImageIsValid(file);
        if (!isValid) {
          this.alertService.open({
            message: "รูปที่เลือกไม่ถูกต้อง กรุณาอัปโหลดไฟล์ที่ไม่ใช่ Animated PNG",
            title: 'โปรดตรวจสอบรูปภาพ',
          });
          return;
        }
  
        const fileName = file.name;
        formData.append('image', file, fileName);
      }
  
      await this.appMasterService.uploadFileBatch(formData).subscribe((result) => {
        if (result) {
          result?.forEach(element => {
            const data = {
              attachId: 0,
              attachUrl: element?.filePath,
              remark: ' ',
              isActive: 'Y',
            };
  
            if (this.form.attachments == null || this.form.attachments == undefined) {
              this.form.attachments = [];
            }
  
            this.form.attachments.push(data);
            this.dataSourceAttachPic.push(data);
          });
  
          this.formSourceUpload = [];
          this.formSourceUpload = [...this.form.attachments];
          this.dataSourceUpload = [];
          this.dataSourceUpload = [...this.dataSourceAttachPic];
  
          this.value = [];
        } else {
          return;
        }
      });
    } else {
      this.alertService.open({ message: "กรุณาเลือกรูปภาพหรือเอกสารอย่างน้อยชนิด", title: "" });
    }
  }
  
  checkIfImageIsValid(file: File): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onload = () => {
        const buffer = reader.result as ArrayBuffer;
        const uint8Array = new Uint8Array(buffer);
  
        // PNG signature bytes: 137 80 78 71 13 10 26 10
        const pngSignature = [137, 80, 78, 71, 13, 10, 26, 10];
        // JPEG signature bytes: 255 216 255
        const jpgSignature = [255, 216, 255];
  
        // Check if it's a PNG by matching the signature
        const isPng = pngSignature.every((byte, i) => uint8Array[i] === byte);
  
        // Check if it's a JPEG by matching the signature
        const isJpg = jpgSignature.every((byte, i) => uint8Array[i] === byte);
  
        if (isPng) {
          // Search for the acTL chunk in the PNG file (animation chunk)
          const acTLIndex = this.findAcTLChunk(uint8Array);
          if (acTLIndex !== -1) {
            resolve(false); // Animated PNG
          } else {
            resolve(true); // Static PNG
          }
        } else if (isJpg) {
          resolve(true); // Valid JPEG
        } else {
          reject(new Error('Invalid file format')); // Not PNG or JPEG
        }
      };
  
      reader.onerror = () => reject(reader.error);
  
      reader.readAsArrayBuffer(file);
    });
  }
  
  findAcTLChunk(uint8Array: Uint8Array): number {
    // Search for the "acTL" chunk in the PNG file starting after the 8-byte PNG header
    const acTLString = "acTL";
    const decoder = new TextDecoder("ascii");
  
    for (let i = 12; i < uint8Array.length - 4; i++) {
      const chunk = decoder.decode(uint8Array.slice(i, i + 4));
      if (chunk === acTLString) {
        return i; // Found the acTL chunk
      }
    }
    return -1; // acTL chunk not found
  }  


  back() {
    window.history.back()
  }


  onView(e) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.minHeight = '30vh';
    dialogConfig.minWidth = '30vh';
    dialogConfig.maxHeight = '100vh';
    dialogConfig.data = {
      fullImageUrl: e.data.attachFilePic
    };

    const dialogRef = this.dialog.open(ImageModalComponent, dialogConfig)
  }

  async save(reprintStatusId) {
    this.clicked = true;
    this.form.reprintId = this.reprintId != 0 ? this.reprintId : null;
    this.form.reprintStatusId = reprintStatusId;
    this.form.statusReprint = "Y";
    let checked = this.validForm(this.form)
    if (!checked) {
      this.clicked = false;
      return
    }
    await this.appRecheckService.saveCreateReprint(this.form).then((res: any) => {
      if (res === 'error') {
        this.clicked = false;
        return
      } else {
        if (res !== null) {
          this.alertService
            .open({
              message: 'บันทึกสำเร็จ',
              title: 'ทำรายการสำเร็จ',
            })
            .then((x) => {
              this._location.back()
            })
        }
      }
    })
  }

  onDataSourceUpdate(e) {
    this.dataSourceAttachPic = e;
  }

  onFormSourceUpdate(e) {
    this.form.attachments = e;
  }
}
