<mat-card>
  <mat-card-header>
    <div class="flex justify-between" style="width: 100%;">
      <div class="text-lg"> เพิ่ม/แก้ไข ผู้ใช้งานระบบ </div>
      <div>
        <button class="close-button" [mat-dialog-close]="true">
          <mat-icon class="text-white" [mat-dialog-close]>cancel</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-dialog-content class="mat-typography">
    <!-- {{dataUser | json}} -->
    <app-user-form [dataInput]="dataUser" (userFormEmit)="userFormEmit($event)"></app-user-form>
  </mat-dialog-content>
  <div class="flex-row flex justify-center gap-2.5" style="margin-bottom: 20px;">
    <button mat-raised-button color="accent" (click)="userFormSubmit()">บันทึก</button>
    <button mat-raised-button [mat-dialog-close]>ยกเลิก</button>
  </div>
