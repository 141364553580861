<mat-card>
  <mat-card-header>
    <div class="flex justify-between" style="width: 100%;">
      <div class="text-lg"> Fee Rate </div>
      <div>
        <button class="close-button" [mat-dialog-close]="true">
          <mat-icon class="text-white" [mat-dialog-close]>cancel</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-dialog-content>
    <div class="pl-22 pr-22 flex flex-col gap-4">
      <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
        <div class="mat-popup-header flex flex-col">
          <h4>รหัสใบค่าธรรมเนียม <span style="color:red;">*</span></h4>
          <h4>Fee Rate Code</h4>
        </div>
        <div class="mat-popup flex flex-row justify-between items-center" style="width:74%">
          <div>:</div>
          <mat-form-field style="flex: 0 95%;" class="flex">
            <input required autocomplete="off" type="text" matInput placeholder="รหัสใบค่าธรรมเนียม" matInput #input
              [(ngModel)]="form.code" name="name" #name="ngModel">
          </mat-form-field>
        </div>
      </div>

      <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
        <div class="mat-popup-header flex flex-col">
          <h4>รายละเอียดค่าธรรมเนียม <span style="color:red;">*</span></h4>
          <h4>Fee Rate Description</h4>
        </div>
        <div class="mat-popup flex flex-row justify-between items-center" style="width:74%">
          <div>:</div>
          <mat-form-field style="flex: 0 95%;" class="flex">
            <input required autocomplete="off" type="text" matInput placeholder="รายละเอียดค่าธรรมเนียม" matInput #input
              [(ngModel)]="form.name" name="name" #name="ngModel">
          </mat-form-field>
        </div>
      </div>

      <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
        <div class="mat-popup-header flex flex-col">
          <h4>ค่าธรรมเนียม <span style="color:red;">*</span></h4>
          <h4>Fee</h4>
        </div>
        <div class="mat-popup flex flex-row justify-between items-center" style="width:74%">
          <div>:</div>
          <mat-form-field style="flex: 0 95%;" class="flex">
            <input required autocomplete="off" type="text" matInput placeholder="ค่าธรรมเนียม" matInput #input
              [(ngModel)]="form.val" name="name" #name="ngModel" oninput="this.value=this.value.replace(/[^0-9]/g,'')">
          </mat-form-field>
        </div>
      </div>

      <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
        <div class="mat-popup-header flex flex-col">
          <h4>หน่วย <span style="color:red;">*</span></h4>
          <h4>Unit</h4>
        </div>
        <div class="mat-popup flex flex-row justify-between items-center" style="width:74%">
          <div>:</div>
          <mat-form-field style="flex: 0 95%;" class="flex">
            <input required autocomplete="off" type="text" matInput placeholder="หน่วย" matInput #input
              [(ngModel)]="form.feeunit" name="name" #name="ngModel">
          </mat-form-field>
        </div>
      </div>

      <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
        <div class="mat-popup-header flex flex-col">
          <h4>รูปแบบการคิดค่าธรรมเนียม <span style="color:red;">*</span></h4>
          <h4>Fee Type</h4>
        </div>
        <div class="mat-popup flex flex-row justify-between items-center" style="width:74%">
          <div>:</div>
          <mat-form-field style="flex: 0 95%;" class="flex">
            <mat-select required name="type" [(ngModel)]="form.type">
              <mat-option *ngFor="let fee of feetypeList" [value]="fee.typeCode">
                {{fee.typeName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <ng-container *ngFor="let buttonList of buttonList">
        <ng-container *ngIf="buttonList.buttonCode=='BT089'">
          <div class="flex-row flex justify-between gap-2.5">
            <div class="mat-popup-header flex flex-col">
              <h4>ใช้งาน</h4>
              <h4>Active</h4>
            </div>
            <div class="mat-popup flex flex-row justify-between items-center" style="width:74%">
              <div>:</div>
              <mat-slide-toggle [checked]="form.isActive === 'Y' ? true : false" (change)="onChange($event)"
                style="flex: 0 95%;" class="flex">
                Active</mat-slide-toggle>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <div class="flex-row flex justify-center gap-2.5" style="gap: 10px;">
        <button mat-raised-button color="accent" (click)="save()">บันทึก</button>
        <button mat-raised-button [mat-dialog-close]>ยกเลิก</button>
      </div>
    </div>
  </mat-dialog-content>