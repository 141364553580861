import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-create-cf-inquiry-popup',
  templateUrl: './create-cf-inquiry-popup.component.html',
  styleUrls: ['./create-cf-inquiry-popup.component.scss']
})
export class CreateCfInquiryPopupComponent implements OnInit {
  form: any = {};
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef: MatDialogRef<CreateCfInquiryPopupComponent>,
  ) {
    
  }

  ngOnInit(): void {
  }

}
