import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { IResGetQueueAddition } from "app/business/interface/api/api-verify.interface";
import { AppVerifyService } from "app/business/service/app/app-verify.service";
import { DxDataGridComponent } from "devextreme-angular";
import { debounce } from "lodash";
import _ from "underscore";

@Component({
  selector: "app-verify-inquiry",
  templateUrl: "./verify-inquiry.component.html",
  styleUrls: ["./verify-inquiry.component.scss"],
})
export class VerifyInquiryComponent implements OnInit {
  jobNum: string = "0";
  eSNum: string = "0";
  eENum: string = "0";
  eJNum: string = "0";
  nSNum: string = "0";
  nNNum: string = "0";
  form: any = {};
  queueDataList: any = {};
  queueData: any = {};
  linkJob: any;
  queueDataLists: any[] = [];
  onQueue: any = {};
  isLoadQueue: boolean = false;

  getQueueAdditionData = {} as IResGetQueueAddition;
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;
  clickJob: boolean = false;
  constructor(
    public appVerifyService: AppVerifyService,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.getQueueOrder = debounce(this.getQueueOrder, 500);
  }

  ngOnInit(): void {
    this.loadQueue();
  }

  onRefresh() {
    this.loadQueue();
  }

  loadQueue() {
    this.appVerifyService.getQueueNum().then((res: any) => {
      if (res === "error") {
        return;
      } else {
        let sumNum: number = 0;
        res.map((x) => {
          sumNum += x.number;
        });
        this.jobNum = sumNum.toString();
        this.nSNum = "0";
        this.nNNum = "0";
        this.eENum = "0";
        this.eJNum = "0";
        this.eSNum = "0";
        res.map((x) => {
          switch (x.priority) {
            case 4:
              this.nSNum = x.numberOfPriority;
              break;
            case 5:
              this.nNNum = x.numberOfPriority;
              break;
            case 2:
              this.eENum = x.numberOfPriority;
              break;
            case 3:
              this.eJNum = x.numberOfPriority;
              break;
            case 1:
              this.eSNum = x.numberOfPriority;
              break;
          }
        });
        // this.appVerifyService.checkQueueNum().then((res: any) => {
        //   if (res === 'error') {
        //     return
        //   }
        //   else {
        //     if (!res) {
        //       return
        //     }
        //     else {
        //       this.queueDataList = res
        //       this.queueData = res
        //       switch (this.queueData.docPriority) {
        //         case "1":
        //           this.queueData.docPriority = "งานแก้ไขเร่งด่วน";
        //           break;
        //         case "2":
        //           this.queueData.docPriority = "งานแก้ไขปกติ";
        //           break;
        //         case "3":
        //           this.queueData.docPriority = "งานแก้ไข Job";
        //           break;
        //         case "4":
        //           this.queueData.docPriority = "งานใหม่เร่งด่วน";
        //           break;
        //         case "5":
        //           this.queueData.docPriority = "งานใหม่ปกติ";
        //           break;
        //       }

        //       const data = {
        //         id: this.queueDataList.id,
        //         docType: this.queueDataList.docType,
        //         oldPackId: this.queueDataList.oldPackId,
        //         isRqstatus: this.queueDataList.isRqstatus
        //       }

        //       this.linkJob = data
        //       this.isLoadQueue = true
        //     }
        //   }
        // })
      }
    });

    this.appVerifyService.getAllQueueOrder().then((res: any) => {
      if (res === "error" || res.length === 0) {
        this.queueDataLists = [];
      } else {
        this.queueDataLists = res;
        console.log("this.appVerifyService.getAllQueueOrder()", res);

        this.queueDataLists.forEach((e) => {
          switch (e.docPriority) {
            case "1":
              e.docPriority = "งานแก้ไขเร่งด่วน";
              break;
            case "2":
              e.docPriority = "งานแก้ไขปกติ";
              break;
            case "3":
              e.docPriority = "งานแก้ไข Job";
              break;
            case "4":
              e.docPriority = "งานใหม่เร่งด่วน";
              break;
            case "5":
              e.docPriority = "งานใหม่ปกติ";
              break;
          }
        });
      }
    });

    this.appVerifyService.checkUserQ().then((res: any) => {
      if (res === "error" || res.length === 0) {
        this.onQueue = [];
      } else {
        this.onQueue = res;
        console.log("this.appVerifyService.checkUserQ", res);

        this.onQueue.forEach((e) => {
          switch (e.docPriority) {
            case "1":
              e.docPriority = "งานแก้ไขเร่งด่วน";
              break;
            case "2":
              e.docPriority = "งานแก้ไขปกติ";
              break;
            case "3":
              e.docPriority = "งานแก้ไข Job";
              break;
            case "4":
              e.docPriority = "งานใหม่เร่งด่วน";
              break;
            case "5":
              e.docPriority = "งานใหม่ปกติ";
              break;
          }
        });

        this.isLoadQueue = true;
      }
    });

    this.appVerifyService.getQueueAddition().then((res) => {
      if (res) {
        this.getQueueAdditionData = res;
      }
    });
  }

  async getQueueOrder() {
    this.clickJob = true;
    let result = await this.appVerifyService
      .getQueueOrder()
      .then(async (res: any) => {
        if (res === "error") {
          return;
        } else {
          this.queueDataList = res;
          this.queueData = res;
          switch (this.queueData.docPriority) {
            case "1":
              this.queueData.docPriority = "งานแก้ไขเร่งด่วน";
              break;
            case "2":
              this.queueData.docPriority = "งานแก้ไขปกติ";
              break;
            case "3":
              this.queueData.docPriority = "งานแก้ไข Job";
              break;
            case "4":
              this.queueData.docPriority = "งานใหม่เร่งด่วน";
              break;
            case "5":
              this.queueData.docPriority = "งานใหม่ปกติ";
              break;
          }
          const data = {
            id: this.queueDataList.id,
            docType: this.queueDataList.docType,
            oldPackId: this.queueDataList.oldPackId,
            isRqstatus: this.queueDataList.isRqstatus,
          };
          await this.appVerifyService.setQueueStatus(data).then((res: any) => {
            if (res == "error") {
              return;
            } else {
              if (res) {
                if (res?.statusGetQ == true) {
                  this.getQueueOrder();
                  return;
                }
                this.linkJob = data;
                this.isLoadQueue = true;
              }
              this.appVerifyService.getQueueNum().then((res: any) => {
                if (res === "error") {
                  return;
                } else {
                  let sumNum: number = 0;
                  res.map((x) => {
                    sumNum += x.number;
                  });
                  this.jobNum = sumNum.toString();
                  this.nSNum = "0";
                  this.nNNum = "0";
                  this.eENum = "0";
                  this.eJNum = "0";
                  this.eSNum = "0";
                  res.map((x) => {
                    switch (x.priority) {
                      case 4:
                        this.nSNum = x.numberOfPriority;
                        break;
                      case 5:
                        this.nNNum = x.numberOfPriority;
                        break;
                      case 2:
                        this.eENum = x.numberOfPriority;
                        break;
                      case 3:
                        this.eJNum = x.numberOfPriority;
                        break;
                      case 1:
                        this.eSNum = x.numberOfPriority;
                        break;
                    }
                  });
                }
              });

              this.appVerifyService.checkUserQ().then((res: any) => {
                if (res === undefined || res === null || res === "error" || res.length === 0) {
                  this.onQueue = [];
                } else {
                  this.onQueue = res;

                  this.onQueue.forEach((e) => {
                    switch (e.docPriority) {
                      case "1":
                        e.docPriority = "งานแก้ไขเร่งด่วน";
                        break;
                      case "2":
                        e.docPriority = "งานแก้ไขปกติ";
                        break;
                      case "3":
                        e.docPriority = "งานแก้ไข Job";
                        break;
                      case "4":
                        e.docPriority = "งานใหม่เร่งด่วน";
                        break;
                      case "5":
                        e.docPriority = "งานใหม่ปกติ";
                        break;
                    }
                  });

                  this.isLoadQueue = true;
                }
              });
            }
          });
        }
      });
    this.clickJob = false;
  }

  sendData() {
    this.router.navigate(["/page/verify/verify-co"], {
      queryParams: this.linkJob,
    });
    // const que = {};
    // this.appVerifyService.getQueueOrder(que).then((res: any) => {
    //   if (res === "error") {
    //     return;
    //   } else {
    //     this.queueDataList = res;
    //     this.queueData = res[0];
    //     this.isLoadQueue = true;

    //     const data = {
    //       id: this.queueDataList[0].id,
    //       docType: this.queueDataList[0].docType,
    //     };
    //     this.appVerifyService.setQueueStatus(data).then((res) => {
    //       if (res == "error") {
    //         return;
    //       } else {
    //       }
    //     });
    //   }
    // });
  }

  sendWaitingData(e) {
    const data = {
      id: e.id,
      docType: e.docType,
      oldPackId: e.oldPackId,
      isRqstatus: e.isRqstatus,
    };

    this.router.navigate(["/page/verify/verify-co"], {
      queryParams: data,
    });
  }
}
