import { AppPaymentService } from "app/business/service/app/app-payment.sevice";
import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AppMasterService } from "app/business/service/app/app-master.service";
import { AppUserService } from "app/business/service/app/app-user.service";
import { AuthService } from "app/core/auth/auth.service";

@Component({
  selector: "app-add-bill-company-modal",
  templateUrl: "./add-bill-company-modal.component.html",
  styleUrls: ["./add-bill-company-modal.component.css"],
})
export class AddBillCompanyModalComponent implements OnInit {
  form: any = {};
  companyList: any = [];
  userProfile: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<AddBillCompanyModalComponent>,
    public appPayment: AppPaymentService,
    public appMaster: AppMasterService,
    private appUserService:AppUserService,
    private _authService:AuthService,
  ) {
    this.userProfile = JSON.parse(
      this._authService.loginUser
    )
  }

  ngOnInit() {
    
    this.loadData();
  }

  loadData() {
    this.appPayment
      .getCompanyListForPayment({ 
        cpId: this.data.cpId,
        gId : this.data.gId
      })
      .then((res) => {
        this.companyList = res;
        
      });

    // this.appMaster
    //   .getCompanyExporterByShipping({ cpId: this.data.cpId })
    //   .then((res) => {
    //     this.companyList = res;
    //   });
  }

  selectionCompany(e) {
    // 
    // this.form.branch = e.branch;
    // this.form.address = e.address;
    this.form = e;
  }
}
