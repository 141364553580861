import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service';
import { FormHelperService } from '@bundle/service/form-helper.service';
import { IApiSaveUserRequest } from 'app/business/interface/api/api-user.interface';
import { AppUserService } from 'app/business/service/app/app-user.service';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.scss']
})
export class UserModalComponent implements OnInit {
  dataUser: any;
  dataForm: any;
  userToken:any;
  constructor(
    private appUserService: AppUserService,
    private alertService: AlertMessageService,
    private formHelper: FormHelperService,
    public dialogRef: MatDialogRef<UserModalComponent>,
    private _authService:AuthService,
    @Inject(MAT_DIALOG_DATA) data: any,
  ) {
    this.dataUser = data;
  }

  ngOnInit(): void {
    this.userToken = JSON.parse(
      this._authService.loginUser
    );
  }

  userFormSubmit(): void {
    if (this.userFormValidate()) {
      const data = {
        userId: this.dataUser.userId,
        userCode: this.dataUser.userCode,
        userName: this.dataForm.value.userName,
        userGroupCode: this.dataForm.value.userGroupCode,
        userRoleCode: this.dataForm.value.userRoleCode,
        clientCode: this.dataForm.value.clientCode,
        name: this.dataForm.value.name,
        lastName: this.dataForm.value.lastName,
        email: this.dataForm.value.email,
        cpId: parseInt(this.userToken.cpId),
        isActive: this.dataForm.value.isActive,
        statusLogin: this.dataForm.value.statusLogin,
        pathPicture : this.dataForm.value.pathPicture,
        activeBranchId: this.dataForm.value.activeBranchId
      } as IApiSaveUserRequest;
      

      this.appUserService.saveUser(data).then(res => {
        if (res !== 'error') {
          this.alertService.info({ title: 'การบันทึก', message: 'บันทึกข้อมูลเรียบร้อยแล้ว' });
          this.dialogRef.close(data);
        }
      });
    }

  }

  userFormEmit(event: any): void {
    this.dataForm = event;
  }
  userFormValidate(): boolean {
    const isValid = this.dataForm.valid;
    this.formHelper.validateForm(this.dataForm);
    return isValid;
  }


}
