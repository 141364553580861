<mat-card>
  <mat-card-header>
    <div class="flex" style="width: 100%;justify-content: center;">
      <div style="font-size:1.3rem !important;"> <strong>เงื่อนไขการขอรับเอกสารทางการเงินอิเล็กทรอนิกส์</strong></div>
    </div>
  </mat-card-header>
  <mat-dialog-content>
    <div class="pl-22 pr-22 flex flex-col gap-4">
      <div class="flex gap-2.5" style="align-items: center; flex-direction: column;">
        <div>
          1. หอการค้าไทยจะจัดส่งเอกสาร ใบแจ้งหนี้/ใบวางบิล ใบเสร็จรับเงิน/ใบกำกับภาษี ใบกำกับภาษี (เต็มรูป) ใบกำกับภาษี (อย่างย่อ) ใบเพิ่มหนี้ ใบลดหนี้ ใบรับเงิน ใบเสร็จรับเงิน และเอกสารทางการเงินอื่นๆ ในรูปแบบอิเล็กทรอนิกส์ทางอีเมล์ที่ท่านได้ลงทะเบียนไว้แทนการนำส่งเอกสารดังกล่าวข้างต้นผ่านไปรษณีย์หรือสถานที่อื่นที่เคยได้แจ้งไว้กับหอการค้าไทย โดยการลงทะเบียนขอรับเอกสารอิเล็กทรอนิกส์นี้ ถือว่า ท่านประสงค์ยกเลิกบริการการส่งเอกสารดังกล่าวข้างต้นผ่านทางไปรษณีย์แบบเดิม
        </div>
        
        <div>
          2. ภายหลังจากลงทะเบียนแล้ว หอการค้าไทยจะเริ่มส่งเอกสารทางอิเล็กทรอนิกส์ให้ท่านในทันที ภายหลังจากทำการลงทะเบียนและยืนยันตอบรับการลงทะเบียน หากมีการเปลี่ยนแปลงวิธีการส่งเอกสารหอการค้าไทยจะแจ้งให้ท่านทราบก่อนทางอีเมลที่ท่านลงทะเบียนไว้
        </div>

        <div>
          3. ข้อมูลที่ใช้ในการลงทะเบียนของท่านจะถูกเก็บเป็นความลับ และถือปฏิบัติตามนโยบายและแนวปฏิบัติในการคุ้มครองข้อมูลส่วนบุคคลของหอการค้าไทย
        </div>
      </div>

      <div class="flex justify-center gap-2.5">
        <mat-checkbox [(ngModel)]="agreementCheck">
          ข้าพเจ้าได้อ่านแล้ว ตกลงยินยอมและยอมรับ
        </mat-checkbox>
      </div>

      <div class="flex justify-center gap-2.5" style="gap: 10px;">
        <button mat-raised-button color="accent" (click)="save()" [disabled]="!agreementCheck">ยืนยัน</button>
        <button mat-raised-button [mat-dialog-close]>ยกเลิก</button>
      </div>
    </div>
  </mat-dialog-content>
