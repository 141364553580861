import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-Image-Modal',
  templateUrl: './Image-Modal.component.html',
  styleUrls: ['./Image-Modal.component.css']
})
export class ImageModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ImageModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { fullImageUrl: string, header?: string }
  ) { }

  ngOnInit() {
  }

}
