import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { AppVerifyService } from "app/business/service/app/app-verify.service";
import { DxDataGridComponent } from "devextreme-angular";
import { ErrorMarkPopupComponent } from "./error-mark-popup/error-mark-popup.component";
import {
  AUTO_STYLE,
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import {
  IGetVerifyCOResponse,
  MarkError,
} from "app/business/interface/app/app-co.interface";
import { ISaveVerifyCORequest } from "app/business/interface/api/api-verify.interface";
import { debounce } from "lodash";
import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import { CreateTransferJobComponent } from "./create-transfer-job/create-transfer-job.component";
import { AppUserService } from "app/business/service/app/app-user.service";
import { AuthService } from "app/core/auth/auth.service";
import * as _ from "lodash";

const DEFAULT_DURATION = 50;
@Component({
  selector: "app-verify-co-inquiry",
  templateUrl: "./verify-co-inquiry.component.html",
  styleUrls: ["./verify-co-inquiry.component.scss"],
  animations: [
    trigger("collapse", [
      state("false", style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state("true", style({ height: "0", visibility: "hidden" })),
      transition("false => true", animate(DEFAULT_DURATION + "ms ease-in")),
      transition("true => false", animate(DEFAULT_DURATION + "ms ease-out")),
    ]),
  ],
})
export class VerifyCOInquiryComponent implements OnInit {
  sumGrossWeight: any = [];
  sumWeight: any = [];
  oldPackId: number;
  recheckId: number;
  sumQuantity: any = [];
  sumGrossWeightJobRequest: any = [];
  sumWeightJobRequest: any = [];
  sumQuantityJobRequest: any = [];
  userProfile: any = {};
  isError: boolean = false;
  mark: boolean = false;
  newConceptExpressType: string;
  markErrorList: MarkError[];
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;
  queueStatus: string;
  hasRestrict: boolean = false;
  saveForm: ISaveVerifyCORequest;
  saveRecheckForm: any = {};
  styleSidepanel: string = "width:0px";
  itemDetail: any = {};
  lastErrNum: number = 0;
  form: IGetVerifyCOResponse = {
    queueStatus: "",
    remark: "",
    markList: [],
    attachmentList: [],
    companySealList: [],
    exporterSignList: [],
    packList: [],
    shippingSignList: [],
    exporterSealList: [],
    shippingSealList: [],
    docPriority: "",
    jobRemark: "",
  } as IGetVerifyCOResponse;

  collapse: any = {
    markError: false,
    exporterForm: false,
    consigneeForm: false,
    signature: false,
  };
  formRequest: IGetVerifyCOResponse = {
    remark: "",
    markList: [],
    attachmentList: [],
    companySealList: [],
    exporterSignList: [],
    packList: [],
    shippingSignList: [],
    exporterSealList: [],
    shippingSealList: [],
  } as IGetVerifyCOResponse;

  checkbox: any = {
    consignor: false,
    destination: false,
    title: false,
    docRef: false,
    docDate: false,
    totalPage: false,
    book: false,
    cerDate: false,
    consignee: false,
    consigneeNotif: false,
    vessel: false,
    loadingDate: false,
    destinationCountry: false,
    seal: false,
    sign: false,
  };

  invoice: any = [];
  countryOfOrigin: any = [];
  items: any = [];

  collapseCopack: any = [];
  documentId: number;
  documentData: any = {};
  docType: string;
  approveType: string;
  @Output() newForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitPackId: EventEmitter<any> = new EventEmitter<any>();
  @Input() attachment: any;
  errorMarkFormImage: MarkError[] = [];
  set errorMark(value) {
    this.errorMarkFormImage = value;
  }
  get errorMark(): any {
    return this.errorMarkFormImage;
  }

  displayedColumns: string[] = [
    "position",
    "markItem",
    "description",
    "quantity",
    "weight",
    "grossWeight",
    "Action",
  ];

  incompleteDocument: MarkError[] = [];

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private appVerifyService: AppVerifyService,
    private alertService: AlertMessageService,
    private confirmService: ConfirmDialogService,
    private appUserService: AppUserService,
    private _authService: AuthService
  ) {
    this.saveQ = debounce(this.saveQ, 500);
    this.appVerifyService.errorList.subscribe((res) => {
      this.errorMark = res;
      if (
        this.lastErrNum < this.errorMark.length &&
        this.errorMark.length != 0
      ) {
        this.approveType = "1";
        this.rdbApprove("1");
      }
      this.lastErrNum = this.errorMark.length;
    });
  }

  async ngOnInit(): Promise<void> {
    this.userProfile = JSON.parse(this._authService.loginUser);

    const { id, docType, oldPackId, recheckId } =
      this.route.snapshot.queryParams;
    this.docType = docType;
    this.documentId = id;
    this.oldPackId = oldPackId;
    this.recheckId = recheckId;
    this.emitPackId.emit(id);

    this.appVerifyService.errorList.subscribe((res) => {
      this.markErrorList = res;
    });

    await this.loadData(id);
    if (this.oldPackId != 0) {
      this.loadDataRequest(this.oldPackId, this.form.documentReferentNo);
      this.appVerifyService.getCoErrorMarkList(this.documentId);
    }
  }
  async loadData(id) {
    if (id != undefined && id != null) {
      const data = {
        uid: this.userProfile.uId,
        packId: id,
        isOld: "N",
      };
      await this.appVerifyService.getVerifyCO(data).then((res: any) => {
        if (res === "error") {
          return;
        } else {
          if (res) {
            this.form = res;
            this.queueStatus = this.form.queueStatus;
            switch (this.form.companyGrade) {
              case "1":
                this.form.companyGrade = "ไม่ผ่าน";
                break;
              case "2":
                this.form.companyGrade = "ยังไม่ออกตรวจ";
                break;
              case "3":
                this.form.companyGrade = "พอใช้";
                break;
              case "4":
                this.form.companyGrade = "ดี";
                break;
              case "5":
                this.form.companyGrade = "ดีเยี่ยม";
                break;
            }
            this.form.expressType =
              this.form.expressType == "Y"
                ? "เร่งด่วน"
                : this.form.expressType == "N"
                ? "ไม่เร่งด่วน"
                : this.form.expressType;

            switch (this.form.docPriority) {
              case "1":
                this.newConceptExpressType = "งานแก้ไขเร่งด่วน";
                break;
              case "2":
                this.newConceptExpressType = "งานแก้ไขปกติ";
                break;
              case "3":
                this.newConceptExpressType = "งานแก้ไข Job";
                break;
              case "4":
                this.newConceptExpressType = "งานใหม่เร่งด่วน";
                break;
              case "5":
                this.newConceptExpressType = "งานใหม่ปกติ";
                break;
            }

            if (res.docType != "CF") {
              res.packList.map((item, index) => {
                item.index = index + 1;
              });
              this.coPackEnv();
            }
            this.newForm.emit(res.attachmentList);

            if (this.form) {
              if (
                this.form.packList != null &&
                this.form.packList != undefined &&
                this.form.packList.length > 0
              ) {
                this.form.packList = this.form.packList.map((item) => {
                  return {
                    ...item,
                    goodsList: item.goodsList.map((i) => {
                      return {
                        ...i,
                        exportValue: parseFloat(i.exportValue).toFixed(3),
                        weight: parseFloat(i.weight).toFixed(3),
                        grossWeight: parseFloat(i.grossWeight).toFixed(3),
                        exchangeValue: parseFloat(i.exchangeValue).toFixed(3),
                      };
                    }),
                  };
                });
              }
            }
          }
        }
      });
      this.appVerifyService.getCoErrorMarkList(this.documentId);

      const checkField = this.markErrorList.find((e) => e.fieldId == 0);
      if (checkField) {
        this.mark = true;
        this.rdbApprove("1");
      }
    }
  }

  async loadDataRequest(id, referNo) {
    if (id != undefined && id != null) {
      const data = {
        uid: this.userProfile.uId,
        packId: id,
        referNo: referNo,
        isOld: "Y",
      };
      await this.appVerifyService.getVerifyCO(data).then((resRequest: any) => {
        if (resRequest === "error") {
          return;
        } else {
          if (resRequest) {
            //
            this.formRequest = resRequest;

            if (resRequest.docType != "CF") {
              resRequest.packList.map((item, index) => {
                item.index = index + 1;
              });
              this.coPackEnvJobRequest();
            }
            if (this.formRequest) {
              if (
                this.formRequest.packList != null &&
                this.formRequest.packList != undefined &&
                this.formRequest.packList.length > 0
              ) {
                this.formRequest.packList = this.formRequest.packList.map(
                  (item) => {
                    return {
                      ...item,
                      goodsList: item.goodsList.map((i) => {
                        return {
                          ...i,
                          exportValue: parseFloat(i.exportValue).toFixed(3),
                          weight: parseFloat(i.weight).toFixed(3),
                          grossWeight: parseFloat(i.grossWeight).toFixed(3),
                          exchangeValue: parseFloat(i.exchangeValue).toFixed(3),
                        };
                      }),
                    };
                  }
                );
              }
            }
          }
        }
      });

      const checkField = this.markErrorList.find((e) => e.fieldId == 0);

      if (checkField) {
        this.mark = true;
        this.rdbApprove("1");
      }
    }
  }

  deleteError(e) {
    e.data.isActive = "N";
    this.confirmService
      .open({
        header: "ยืนยันการทำรายการ",
        content: "ยืนยันการลบ Mark Error ?",
        disableClose: true,
      })
      .then((res) => {
        if (res == true) {
          console.log(e);
          var delReq = {
            markId: e.data.markId,
            isActive: "N",
          };
          this.appVerifyService
            .saveErrorMarkActiveStatus(delReq)
            .then((res) => {
              if (res === "error") {
                this.alertService.open({
                  message: "เกิดข้อผิดพลาดระหว่างการบันทึกข้อมูล",
                  title: "",
                });
              } else {
                this.alertService
                  .open({ title: "ทำรายการสำเร็จ", message: "ลบข้อมูลสำเร็จ" })
                  .then((res) => {
                    this.appVerifyService.getCoErrorMarkList(this.documentId);
                    if (
                      this.markErrorList.filter((x) => x.fieldId == 0).length ==
                      0
                    ) {
                      this.mark = false;
                    }
                  });
              }
            });
        }
      });
  }
  editError(e) {
    const formData = { ...e.data };
    const dialogRef = this.dialog.open(ErrorMarkPopupComponent, {
      disableClose: false,
      width: "50vw",
      data: {
        attachment: this.attachment,
        form: formData,
      },
      maxWidth: "none",
      hasBackdrop: false,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res != null) {
        this.appVerifyService.getCoErrorMarkList(this.documentId);
      }
    });
  }

  rdbApprove(e) {
    console.log("rdbApprove", e);
    switch (e) {
      case "1":
        this.form.approveType = "N";
        Object.keys(this.checkbox).forEach((key) => {
          this.checkbox[key] = true;
        });
        for (let i = 0; i < this.form.packList.length; i++) {
          this.invoice[i] = true;
          this.countryOfOrigin[i] = true;
          this.items[i] = true;
        }
        break;
      case "2":
        this.form.approveType = "Y";
        Object.keys(this.checkbox).forEach((key) => {
          this.checkbox[key] = true;
        });
        for (let i = 0; i < this.form.packList.length; i++) {
          this.invoice[i] = true;
          this.countryOfOrigin[i] = true;
          this.items[i] = true;
        }
        break;
      case "3":
        this.form.companyBlackList = "Y";
        this.form.approveType = "W";
        Object.keys(this.checkbox).forEach((key) => {
          this.checkbox[key] = true;
        });
        for (let i = 0; i < this.form.packList.length; i++) {
          this.invoice[i] = true;
          this.countryOfOrigin[i] = true;
          this.items[i] = true;
        }
        break;
    }
  }
  detailGood(e) {
    this.styleSidepanel = "width:40vw";
    this.itemDetail = { data: e, edit: false };
  }
  save() {
    if (this.checkOpenDialog()) {
      this.confirmService
        .open({
          header: "ยืนยันการทำรายการ",
          content: "ยืนยันการบันทึกข้อมูลนี้ใช่หรือไม่?",
          disableClose: true,
        })
        .then((res) => {
          if (res == true) {
            if (
              this.recheckId !== null &&
              this.recheckId !== undefined &&
              this.recheckId !== 0
            ) {
              this.saveRecheckForm = {
                recheckId: this.recheckId,
                remark: this.form.remark,
                markList: this.markErrorList,
                rqStatus: "9",
              };
              this.appVerifyService
                .saveVerifyRecheck(this.saveRecheckForm)
                .then((res) => {
                  if (res === "error" || !res) {
                    return;
                  } else {
                    this.alertService
                      .open({
                        message: "บันทึกสำเร็จ",
                        title: "ทำรายการสำเร็จ",
                      })
                      .then((res) => {
                        this.router.navigate(["/page/verify/verify-recheck"]);
                      });
                  }
                });
            } else {
              this.saveForm = {
                editedForm: null,
                packId: this.form.packId,
                remark: this.form.remark,
                markList: this.markErrorList,
                rqStatus: "40",
                approveType: null,
              };
              this.appVerifyService.saveVerifyCO(this.saveForm).then((res) => {
                if (res == "error") {
                  return;
                } else {
                  this.alertService
                    .open({ message: "ทำรายการสำเร็จ", title: "บันทึกสำเร็จ" })
                    .then((res) => {
                      this.router.navigate(["/page/verify/verify"]);
                    });
                }
              });
            }
          }
        });
    }
  }

  saveQ() {
    if (this.checkOpenDialog()) {
      this.confirmService
        .open({
          header: "ยืนยันการทำรายการ",
          content: "ยืนยันการบันทึกและส่งผลตรวจใช่หรือไม่?",
          disableClose: true,
        })
        .then((res) => {
          if (res == true) {
            //เช็คว่ามีการระบุผลอนุมัติรึยัง
            if (this.approveType) {
              this.form.approveBy = this.userProfile.userName;
              if (this.markErrorList.length == 0 && this.approveType == "1") {
                this.alertService.open({
                  message: "ระบุผลเป็นไม่อนุมัติ กรุณาใส่รายละเอียดข้อผิดพลาด",
                  title: "กรุณาตรวจสอบข้อมูลให้ครบถ้วน",
                });
                return;
              } else {
                let check = Object.keys(this.checkbox).find(
                  (key) => this.checkbox[key] === false
                );
                let statusCheck = this.validate(check);
                let itemCheck = true;
                // เช็ต checkbox ใน copack
                if (
                  (this.docType == "CO" || this.docType == "CO/Pack") &&
                  this.form.approveType == "Y"
                ) {
                  for (let i = 0; i < this.items.length; i++) {
                    if (this.items[i] === false) {
                      itemCheck = false;
                      let a = i;
                      this.alertService.open({
                        message:
                          "กรุณาตรวจสอบ ช่อง : รายการสินค้า ชุดที่ " + (a + 1),
                        title: "กรุณาตรวจสอบข้อมูลให้ครบถ้วน",
                      });
                      return;
                    }
                  }
                  for (let i = 0; i < this.countryOfOrigin.length; i++) {
                    if (this.countryOfOrigin[i] === false) {
                      itemCheck = false;
                      let a = i;
                      this.alertService.open({
                        message:
                          "กรุณาตรวจสอบ ช่อง : ประเทศแหล่งกำเนิดสินค้า ชุดที่ " +
                          (a + 1),
                        title: "กรุณาตรวจสอบข้อมูลให้ครบถ้วน",
                      });
                      return;
                    }
                  }
                  for (let i = 0; i < this.invoice.length; i++) {
                    if (this.invoice[i] === false) {
                      itemCheck = false;
                      let a = i;
                      this.alertService.open({
                        message:
                          "กรุณาตรวจสอบ ช่อง : เลขที่ใบกำกับสินค้า ชุดที่ " +
                          (a + 1),
                        title: "กรุณาตรวจสอบข้อมูลให้ครบถ้วน",
                      });
                      return;
                    }
                  }
                }

                // เช็คว่า checkbox ครบแล้ว
                if (
                  (statusCheck && itemCheck && this.form.approveType == "Y") ||
                  this.form.approveType != "Y"
                ) {
                  if (
                    this.recheckId !== null &&
                    this.recheckId !== undefined &&
                    this.recheckId !== 0
                  ) {
                    this.saveRecheckForm = {
                      editedForm:
                        this.oldPackId != undefined && this.oldPackId != 0
                          ? this.checkEditField()
                          : null,
                      recheckId: this.recheckId,
                      remark: this.form.remark,
                      markList: this.markErrorList,
                      rqStatus:
                        this.form.approveType == "N"
                          ? "10"
                          : this.form.approveType == "Y"
                          ? "5"
                          : "40",
                    };
                    console.log("saveVerifyRecheck");
                    // return;
                    this.appVerifyService
                      .saveVerifyRecheck(this.saveRecheckForm)
                      .then((res) => {
                        if (res === "error" || !res) {
                          return;
                        } else {
                          this.alertService
                            .open({
                              message: "บันทึกสำเร็จ",
                              title: "ทำรายการสำเร็จ",
                            })
                            .then((res) => {
                              this.router.navigate([
                                "/page/verify/verify-recheck",
                              ]);
                            });
                        }
                      });
                  } else {
                    if (this.approveType == "1") {
                      this.form.approveType = "N";
                    } else if (this.approveType == "2") {
                      this.form.approveType = "Y";
                    } else if (this.approveType == "3") {
                      this.form.approveType = "W";
                    }
                    this.saveForm = {
                      editedForm:
                        this.oldPackId != undefined && this.oldPackId != 0
                          ? this.checkEditField()
                          : null,
                      packId: this.form.packId,
                      remark: this.form.remark,
                      markList: this.markErrorList,
                      rqStatus:
                        this.form.approveType == "N"
                          ? "10"
                          : this.form.approveType == "Y"
                          ? "5"
                          : "40",
                      approveType: this.form.approveType,
                    };
                    // return;
                    this.appVerifyService
                      .saveVerifyCO(this.saveForm)
                      .then((res) => {
                        console.log("saveVerifyCO", res);

                        if (res === "error" || !res) {
                          this.router.navigate(["/page/verify/verify"]);
                          return;
                        } else {
                          this.alertService
                            .open({
                              message: "บันทึกสำเร็จ",
                              title: "ทำรายการสำเร็จ",
                            })
                            .then((res) => {
                              this.router.navigate(["/page/verify/verify"]);
                            });
                        }
                      });
                  }
                }
              }
            } else {
              this.alertService.open({
                message: "ยังไม่ได้ระบุผลการอนุมัติ",
                title: "กรุณาตรวจสอบข้อมูลให้ครบถ้วน",
              });
              return;
            }
            // this.alertService
            //   .open({ message: "บันทึกสำเร็จ", title: "ทำรายการสำเร็จ" })
            //   .then((res) => {
            //     this.router.navigate(["/page/verify/verify-recheck"]);
            //   });
          }
        });
    }
  }

  expand(e) {
    switch (e) {
      case 1:
        this.collapse.markError = !this.collapse.markError;
        break;
      case 2:
        this.collapse.exporterForm = !this.collapse.exporterForm;
        break;
      case 3:
        this.collapse.consigneeForm = !this.collapse.consigneeForm;
        break;
      case 4:
        this.collapse.signature = !this.collapse.signature;
        break;
      default:
        return;
    }
  }

  validate(e): boolean {
    if (this.form.approveType != "Y") {
      return true;
    }
    if (e == "consignor") {
      this.alertService.open({
        message: "กรุณาตรวจสอบ ช่อง : Consignor",
        title: "กรุณาตรวจสอบข้อมูลให้ครบถ้วน",
      });
      return false;
    }
    if (e == "cerDate") {
      this.alertService.open({
        message: "กรุณาตรวจสอบ ช่อง : วันที่ออกใบรับรอง",
        title: "กรุณาตรวจสอบข้อมูลให้ครบถ้วน",
      });
      return false;
    }
    if (e == "destination") {
      this.alertService.open({
        message: "กรุณาตรวจสอบ ช่อง : ปลายทางที่สินค้าถูกยกลง",
        title: "กรุณาตรวจสอบข้อมูลให้ครบถ้วน",
      });
      return false;
    }
    if (e == "cerDate") {
      this.alertService.open({
        message: "กรุณาตรวจสอบ ช่อง : วันที่ออกเอกสาร",
        title: "กรุณาตรวจสอบข้อมูลให้ครบถ้วน",
      });
      return false;
    }

    if (e == "seal") {
      this.alertService.open({
        message: "กรุณาตรวจสอบ ช่อง : Company Seal",
        title: "กรุณาตรวจสอบข้อมูลให้ครบถ้วน",
      });
      return false;
    }

    if (e == "sign") {
      this.alertService.open({
        message: "กรุณาตรวจสอบ ช่อง : Signature",
        title: "กรุณาตรวจสอบข้อมูลให้ครบถ้วน",
      });
      return false;
    }

    if (this.docType == "CO" || this.docType == "CO/Pack") {
      if (e == "consignee") {
        this.alertService.open({
          message: "กรุณาตรวจสอบ ช่อง : ผู้รับสินค้าปลายทาง",
          title: "กรุณาตรวจสอบข้อมูลให้ครบถ้วน",
        });
        return false;
      }

      if (e == "consigneeNotif") {
        this.alertService.open({
          message: "กรุณาตรวจสอบ ช่อง : ผู้ที่จะได้รับแจ้งเมื่อสินค้าไปถึง",
          title: "กรุณาตรวจสอบข้อมูลให้ครบถ้วน",
        });
        return false;
      }

      if (e == "vessel") {
        this.alertService.open({
          message: "กรุณาตรวจสอบ ช่อง : ชื่อพาหนะ",
          title: "กรุณาตรวจสอบข้อมูลให้ครบถ้วน",
        });
        return false;
      }

      if (e == "loadingDate") {
        this.alertService.open({
          message: "กรุณาตรวจสอบ ช่อง : วันที่ขนสินค้าขึ้นพาหนะ",
          title: "กรุณาตรวจสอบข้อมูลให้ครบถ้วน",
        });
        return false;
      }

      if (e == "destinationCountry") {
        this.alertService.open({
          message: "กรุณาตรวจสอบ ช่อง : ประเทศปลายทาง",
          title: "กรุณาตรวจสอบข้อมูลให้ครบถ้วน",
        });
        return false;
      }
    }
    if (this.docType == "CF") {
      if (e == "title") {
        this.alertService.open({
          message: "กรุณาตรวจสอบ ช่อง : ประเภทเอกสาร",
          title: "กรุณาตรวจสอบข้อมูลให้ครบถ้วน",
        });
        return false;
      }
      if (e == "docRef") {
        this.alertService.open({
          message: "กรุณาตรวจสอบ ช่อง : เลขที่เอกสาร",
          title: "กรุณาตรวจสอบข้อมูลให้ครบถ้วน",
        });
        return false;
      }
      if (e == "docDate") {
        this.alertService.open({
          message: "กรุณาตรวจสอบ ช่อง : วันที่เอกสาร",
          title: "กรุณาตรวจสอบข้อมูลให้ครบถ้วน",
        });
        return false;
      }

      if (e == "totalPage") {
        this.alertService.open({
          message: "กรุณาตรวจสอบ ช่อง : จำนวน",
          title: "กรุณาตรวจสอบข้อมูลให้ครบถ้วน",
        });
        return false;
      }

      if (e == "book") {
        this.alertService.open({
          message: "กรุณาตรวจสอบ ช่อง : ลักษณะเอกสารที่ยื่นขอ",
          title: "กรุณาตรวจสอบข้อมูลให้ครบถ้วน",
        });
        return false;
      }
    }

    return true;
  }

  expandCoPack(e) {
    this.collapseCopack[e] = !this.collapseCopack[e];
  }

  docMark(e) {
    let name = this.userProfile.userName;
    if (e.checked == true) {
      this.approveType = "1";
      const data: MarkError = {
        docTypeName: "-",
        fieldId: 0,
        fieldName: "-",
        remark: "เอกสารไม่สมบูรณ์",
        markById: this.userProfile.uId,
        markByName: name,
        markId: 0,
        docTypeId: 0,
        markTypeId: 0,
        typeDescription: "",
        attachPicId: 0,
        pos_x: 0,
        pos_y: 0,
        indexNo: 0,
        packId: this.documentId,
      } as MarkError;
      this.appVerifyService.saveErrorMarker(data).then((res) => {
        if (res === "error") {
          this.mark = false;
          this.alertService.open({
            message: "เกิดข้อผิดพลาดระหว่างการบันทึกข้อมูล",
            title: "",
          });
        } else {
          data.markId = res.markId;
          if (this.markErrorList.filter((x) => x.fieldId == 0).length == 0) {
            this.appVerifyService.getCoErrorMarkList(this.documentId);
            this.mark = true;
            this.rdbApprove("1");
          }
        }
      });
    } else {
      this.confirmService
        .open({
          header: "ยืนยันการทำรายการ",
          content: "ยืนยันการลบ Mark Error ?",
        })
        .then((res) => {
          if (res == true) {
            if (this.markErrorList) {
              let markDoc = this.markErrorList.find((x) => x.fieldId == 0);

              let delReq = {
                markId: markDoc.markId,
                isActive: "N",
              };

              this.appVerifyService
                .saveErrorMarkActiveStatus(delReq)
                .then((res) => {
                  if (res === "error") {
                    this.alertService.open({
                      message: "เกิดข้อผิดพลาดระหว่างการบันทึกข้อมูล",
                      title: "",
                    });
                  } else {
                    this.appVerifyService.getCoErrorMarkList(this.documentId);
                    this.mark = false;
                    this.rdbApprove("1");
                  }
                });
            }
          } else {
            this.mark = true;
            this.rdbApprove("1");
          }
        });
    }
  }

  coPackEnv() {
    for (let i = 0; i < this.form.packList.length; i++) {
      this.collapseCopack[i] = false; // กำหนดค่า expand / collasped
      this.invoice[i] = false;
      this.countryOfOrigin[i] = false;
      this.items[i] = false;

      const sumGrossWeight = this.form.packList[i].goodsList.reduce(
        (acc, curr) => {
          const grossWeightUnit = curr.grossWeightUnit;
          if (!acc[grossWeightUnit]) {
            acc[grossWeightUnit] = { grossWeightUnit, grossWeight: 0 };
          }
          acc[grossWeightUnit].grossWeight += curr.grossWeight;
          return acc;
        },
        {}
      );
      const sumNetWeight = this.form.packList[i].goodsList.reduce(
        (acc, curr) => {
          const netWeightUnit = curr.weightUnit;
          if (!acc[netWeightUnit]) {
            acc[netWeightUnit] = { netWeightUnit, weight: 0 };
          }
          acc[netWeightUnit].weight += curr.weight;
          return acc;
        },
        {}
      );
      const sumQuantity = this.form.packList[i].goodsList.reduce(
        (acc, curr) => {
          const quantityUnit = curr.quantityUnit;
          if (!acc[quantityUnit]) {
            acc[quantityUnit] = { quantityUnit, quantity: 0 };
          }
          acc[quantityUnit].quantity += parseFloat(curr.quantity);
          return acc;
        },
        {}
      );

      let grossWeightNet = "";
      Object.keys(sumGrossWeight).forEach((key) => {
        grossWeightNet +=
          parseFloat(sumGrossWeight[key].grossWeight).toFixed(3) +
          "<br>" +
          sumGrossWeight[key].grossWeightUnit +
          "<br>";
      });
      let weightNet = "";
      Object.keys(sumNetWeight).forEach((key) => {
        weightNet +=
          parseFloat(sumNetWeight[key].weight).toFixed(3) +
          "<br>" +
          sumNetWeight[key].netWeightUnit +
          "<br>";
      });
      let quantityNet = "";
      Object.keys(sumQuantity).forEach((key) => {
        quantityNet +=
          sumQuantity[key].quantity +
          "<br>" +
          sumQuantity[key].quantityUnit +
          "<br>";
      });
      this.sumGrossWeight.push(grossWeightNet);
      this.sumWeight.push(weightNet);
      this.sumQuantity.push(quantityNet);
    }
  }

  coPackEnvJobRequest() {
    for (let i = 0; i < this.formRequest.packList.length; i++) {
      this.collapseCopack[i] = false; // กำหนดค่า expand / collasped
      this.invoice[i] = false;
      this.countryOfOrigin[i] = false;
      this.items[i] = false;
      const sumGrossWeight = this.formRequest.packList[i].goodsList.reduce(
        (acc, curr) => {
          const grossWeightUnit = curr.grossWeightUnit;
          if (!acc[grossWeightUnit]) {
            acc[grossWeightUnit] = { grossWeightUnit, grossWeight: 0 };
          }
          acc[grossWeightUnit].grossWeight += curr.grossWeight;
          return acc;
        },
        {}
      );
      const sumNetWeight = this.formRequest.packList[i].goodsList.reduce(
        (acc, curr) => {
          const netWeightUnit = curr.weightUnit;
          if (!acc[netWeightUnit]) {
            acc[netWeightUnit] = { netWeightUnit, weight: 0 };
          }
          acc[netWeightUnit].weight += curr.weight;
          return acc;
        },
        {}
      );
      const sumQuantity = this.formRequest.packList[i].goodsList.reduce(
        (acc, curr) => {
          const quantityUnit = curr.quantityUnit;
          if (!acc[quantityUnit]) {
            acc[quantityUnit] = { quantityUnit, quantity: 0 };
          }
          acc[quantityUnit].quantity += parseFloat(curr.quantity);
          return acc;
        },
        {}
      );

      let grossWeightNet = "";
      Object.keys(sumGrossWeight).forEach((key) => {
        grossWeightNet +=
          parseFloat(sumGrossWeight[key].grossWeight).toFixed(3) +
          "<br>" +
          sumGrossWeight[key].grossWeightUnit +
          "<br>";
      });
      let weightNet = "";
      Object.keys(sumNetWeight).forEach((key) => {
        weightNet +=
          parseFloat(sumNetWeight[key].weight).toFixed(3) +
          "<br>" +
          sumNetWeight[key].netWeightUnit +
          "<br>";
      });
      let quantityNet = "";
      Object.keys(sumQuantity).forEach((key) => {
        quantityNet +=
          sumQuantity[key].quantity +
          "<br>" +
          sumQuantity[key].quantityUnit +
          "<br>";
      });
      this.sumGrossWeightJobRequest.push(grossWeightNet);
      this.sumWeightJobRequest.push(weightNet);
      this.sumQuantityJobRequest.push(quantityNet);
    }
  }

  sendTo(e) {
    if (this.checkOpenDialog()) {
      const dialogRef = this.dialog.open(CreateTransferJobComponent, {
        disableClose: false,
        width: "40vw",
        data: {
          packId: this.form.packId,
          requestNo: this.form.requestNo,
          uId: this.userProfile.uId,
          companyName: this.form.companyName,
          getTaskFromVerifyId: this.form?.getTaskFromVerifyId,
          getTaskFromVerifyName: this.form.getTaskFromVerifyName,
        },
        maxWidth: "120vw",
        maxHeight: "90vh",
      });
    }
  }

  checkOpenDialog() {
    if (!this.dialog.openDialogs || !this.dialog.openDialogs.length) {
      return true;
    } else {
      this.alertService.open({
        message: "กรุณาปิดหน้าต่างเพิ่มเงื่อนไข ก่อนการบันทึกหรือโอนงาน",
        title: "",
      });
      return false;
    }
  }
  closePanel() {
    this.styleSidepanel = "width:0px";
  }

  checkEditField() {
    // debugger;
    var editedItem = [];

    if (this.formRequest.declaratorName != this.form.declaratorName) {
      editedItem.push("Declarator Name");
    }

    if (this.formRequest.consignorName != this.form.consignorName) {
      editedItem.push("Consignor Name");
    }

    if (this.formRequest.consignorAddress != this.form.consignorAddress) {
      editedItem.push("Consignor Address");
    }

    if (this.formRequest.destination != this.form.destination) {
      editedItem.push("Destination");
    }

    if (this.formRequest.signAuthorizationId != this.form.signAuthorizationId) {
      editedItem.push("Signature");
    }

    if (this.formRequest.title != this.form.title) {
      editedItem.push("Document Subject/Title");
    }

    if (this.formRequest.refNo != this.form.refNo) {
      editedItem.push("Document Reference No");
    }

    if (this.formRequest.documentDate != this.form.documentDate) {
      editedItem.push("Document Date");
    }

    if (this.formRequest.totalPages != this.form.totalPages) {
      editedItem.push("Total Page");
    }

    if (this.formRequest.book != this.form.book) {
      editedItem.push("ลักษณะเอกสารที่ยื่นขอ");
    }

    if (this.checkCertificateEdit(this.form, this.formRequest)) {
      editedItem.push("Certificate Date Request");
    }

    if (this.checkCertificateEdit(this.form, this.formRequest)) {
      editedItem.push("Certificate Date");
    }

    if (this.formRequest.consigneeName != this.form.consigneeName) {
      editedItem.push("Consignee Name");
    }

    if (this.formRequest.consigneeNotify != this.form.consigneeNotify) {
      editedItem.push("Notify");
    }

    if (this.formRequest.vesselType != this.form.vesselType) {
      editedItem.push("Vessel Type");
    }

    if (this.formRequest.vesselNo != this.form.vesselNo) {
      editedItem.push("Vessel Number");
    }

    if (this.formRequest.loadingDate != this.form.loadingDate) {
      editedItem.push("Loading Date");
    }

    if (this.formRequest.destinationCountry != this.form.destinationCountry) {
      editedItem.push("Destination Country");
    }

    this.formRequest.packList.forEach((d) => {
      this.form.packList.forEach((e, i) => {
        if (d.countryOfOrigin != e.countryOfOrigin) {
          editedItem.push("Country of Origin");
        }

        if (d.remark != e.remark) {
          editedItem.push("Remark");
        }

        e.invoiceList.forEach((f) => {
          d.invoiceList.forEach((g) => {
            if (f.invoiceDate != g.invoiceDate) {
              editedItem.push("Invoice Date");
            }

            if (f.invoiceNo != g.invoiceNo) {
              editedItem.push("Invoice Number");
            }
          });
        });

        e.goodsList.forEach((h, i) => {
          var goodItem = d.goodsList[i];

          if (goodItem) {
            if (h.markItem != goodItem.markItem) {
              editedItem.push("Mark");
            }

            if (h.description != goodItem.description) {
              editedItem.push("Description");
            }

            if (
              h.quantity != goodItem.quantity ||
              h.quantityUnit != goodItem.quantityUnit
            ) {
              editedItem.push("Quantity");
            }

            if (
              h.weight != goodItem.weight ||
              h.weightUnit != goodItem.weightUnit
            ) {
              editedItem.push("Net Weight");
            }

            if (
              h.grossWeight != goodItem.grossWeight ||
              h.grossWeightUnit != goodItem.grossWeightUnit
            ) {
              editedItem.push("Gross Weight");
            }
          }
        });

        if (this.sumQuantity[i] != this.sumQuantityJobRequest[i]) {
          editedItem.push("Total Quantity");
        }

        if (this.sumWeight[i] != this.sumWeightJobRequest[i]) {
          editedItem.push("Total Net Weight");
        }

        if (this.sumGrossWeight[i] != this.sumGrossWeightJobRequest[i]) {
          editedItem.push("Total Gross Weight");
        }
      });
    });

    const result: string[] = _.uniq(editedItem);

    return result.join(", ");
  }

  checkCertificateEdit(e, f) {
    if (e.certificateDateRequest != f.certificateDateRequest) {
      return true;
    } else {
      if (e.certificateDateRequest == "approve_date") {
        return false;
      } else {
        if (e.certificateDate != f.certificateDate) {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  checkInvoiceEdit(e, f, i) {
    var dataE = e.invoiceList[i];
    var dataF = f.invoiceList[i];

    if (dataE?.invoiceNo != dataF?.invoiceNo) {
      return true;
    } else if (dataE?.invoiceDate != dataF?.invoiceDate) {
      return true;
    } else {
      return false;
    }
  }

  checkMarkEdit(e, f, i) {
    var dataE = e;
    var dataF = f[i];

    if (dataF) {
      if (dataE?.markItem != dataF?.markItem) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  checkDescriptionEdit(e, f, i) {
    var dataE = e;
    var dataF = f[i];

    if (dataF) {
      if (dataE?.description != dataF?.description) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  checkQuantityEdit(e, f, i) {
    var dataE = e;
    var dataF = f[i];

    if (dataF) {
      if (dataE?.quantity != dataF?.quantity) {
        return true;
      } else {
        if (dataE?.quantityUnit != dataF?.quantityUnit) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return true;
    }
  }

  checkNwEdit(e, f, i) {
    var dataE = e;
    var dataF = f[i];

    if (dataE?.weight != dataF?.weight) {
      return true;
    } else {
      if (dataE?.weightUnitCode != dataF?.weightUnitCode) {
        return true;
      } else {
        return false;
      }
    }
  }

  checkGwEdit(e, f, i) {
    var dataE = e;
    var dataF = f[i];

    if (dataE?.grossWeight != dataF?.grossWeight) {
      return true;
    } else {
      if (dataE?.grossWeightUnitCode != dataF?.grossWeightUnitCode) {
        return true;
      } else {
        return false;
      }
    }
  }
}
