<div style="display: flex;flex-flow: column;height: 100%;gap: 30px;">
    <div class="flex flex-col pl-10 pr-10 pt-10">
        <div class="menu">
            <div style="display: flex;flex-flow: row;gap: 10px;align-items: stretch;">
                <div *ngIf="checkButton('BT030')">
                    <button mat-raised-button color="accent" (click)="createVerifyRecheck()">
                        <div class="flex items-center">
                            <span>สร้างคำร้องขอตรวจซ้ำ</span>
                        </div>
                    </button>
                </div>
            </div>
            <div class="gap-2.5 flex flex-row">
                <button mat-raised-button color="accent" (click)="onRefresh()">
                    <div class="flex items-center">
                        <span class="icon-text"><mat-icon
                                svgIcon="heroicons_outline:refresh"></mat-icon>รีเฟรชข้อมูล</span>
                    </div>
                </button>
            </div>
            <div style="display: flex;flex-flow: row;gap: 10px;flex: 1;justify-content: flex-end;">
                <!-- <div class="mat-button" style="gap: 10px;flex: 0 40%;">
                    <mat-form-field style="flex: 1" class="my-form-field flex flex-row">
                        <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" matInput #input
                            [(ngModel)]="form.search" name="search" #search="ngModel" (keyup)="applyFilter($event)">
                        <button matSuffix aria-label="ค้นหา">
                            <mat-icon svgIcon="heroicons_outline:search"></mat-icon>
                        </button>
                    </mat-form-field>
                </div> -->
                <div (click)="statusChange(1)"
                    [class]="statusButton == 1 ?'inline-flex items-center px-5 py-2.5 text-lg font-medium text-center text-white bg-green-700 rounded-lg hover:bg-green-100 hover:text-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-100 dark:focus:ring-green-800 cursor-default' :'inline-flex items-center px-5 py-2.5 text-lg font-medium text-center hover:bg-gray-200 bg-gray-100 text-gray-800 rounded-lg cursor-default'">
                    รอการพิจารณาแก้ไข
                </div>
                <div (click)="statusChange(2)"
                    [class]="statusButton == 2 ?'inline-flex items-center px-5 py-2.5 text-lg font-medium text-center text-white bg-green-700 rounded-lg hover:bg-green-100 hover:text-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-100 dark:focus:ring-green-800 cursor-default' :'inline-flex items-center px-5 py-2.5 text-lg font-medium text-center hover:bg-gray-200 bg-gray-100 text-gray-800 rounded-lg cursor-default'">
                    แสดงสถานะทั้งหมด
                </div>
                <button mat-raised-button color="accent" [matMenuTriggerFor]="menu">
                    <div style="display: flex;align-items: center;">
                        <span>เงื่อนไขการค้นหา</span>
                        <mat-icon svgIcon="heroicons_outline:chevron-down"></mat-icon>
                    </div>
                </button>
                <mat-menu #menu="matMenu" yPosition="below" xPosition="before" class="my-menu">
                    <div style="display: flex;flex-flow: column;" class="pl-2 pr-2 " (click)="stopPropagation($event)">
                        <div class="flex flex-row mat-button p-2" style="gap: 10px;">
                            <mat-form-field appearance="fill" style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>วันที่เริ่มต้น | Start Date
                                </mat-label>
                                <input matInput [matDatepicker]="startDate" type="datetime"
                                    [(ngModel)]="searchForm.startDate" [max]="myDate" name="startDateSearch"
                                    #startDateSearch="ngModel" (click)="startDate.open()" readonly
                                    class="cursor-pointer">
                                <mat-hint>MM/DD/YYYY</mat-hint>
                                <mat-datepicker-toggle matIconSuffix [for]="startDate"></mat-datepicker-toggle>
                                <mat-datepicker #startDate></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field appearance="fill" style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>วันที่สิ้นสุด | End Date</mat-label>
                                <input matInput [matDatepicker]="endDate" type="datetime"
                                    [(ngModel)]="searchForm.endDate" [max]="myDate" [min]="searchForm.startDate"
                                    name="endDateSearch" #endDateSearch="ngModel" (click)="endDate.open()" readonly
                                    class="cursor-pointer">
                                <mat-hint>MM/DD/YYYY</mat-hint>
                                <mat-datepicker-toggle matIconSuffix [for]="endDate"></mat-datepicker-toggle>
                                <mat-datepicker #endDate></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="mat-button  p-2" style="gap: 10px;">
                            <mat-form-field style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>เลขที่ใบคำขอ | Request No. </mat-label>
                                <input autocomplete="off" type="text" matInput placeholder="เลขที่ใบคำขอ" matInput
                                    #input [(ngModel)]="searchForm.requestNo" name="requestNo" #requestNo="ngModel">
                            </mat-form-field>
                        </div>
                        <div class="mat-button  p-2" style="gap: 10px;">
                            <mat-form-field style="display: flex;flex-flow: row;flex: 1">
                                <mat-label> เลขที่ THT | Certificate No.</mat-label>
                                <input autocomplete="off" type="text" matInput placeholder="เลขที่ THT" matInput #input
                                    [(ngModel)]="searchForm.certificateNo" name="certificateNo"
                                    #certificateNo="ngModel">
                            </mat-form-field>
                        </div>
                        <div class="mat-button  p-2" style="gap: 10px;">
                            <mat-form-field style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>เลขที่ใบกำกับสินค้า / เลขที่เอกสาร | Invoice No./ Reference No. </mat-label>
                                <input autocomplete="off" type="text" matInput placeholder="เลขที่ใบกำกับ/ เลขที่เอกสาร"
                                    matInput #input [(ngModel)]="searchForm.invoiceReference" name="invoiceReference"
                                    #invoiceReference="ngModel">
                            </mat-form-field>
                        </div>
                        <div class="mat-button  p-2" style="gap: 10px;">
                            <form name="searchCompanyForm" [formGroup]="searchCompanyForm"
                                style="display: flex;flex-flow: row;flex: 1">
                                <mat-form-field style="display: flex;flex-flow: row;flex: 1">
                                    <mat-label>รหัสบริษัท | Company Code</mat-label>
                                    <input matInput type="text" formControlName="companySearch" [matAutocomplete]="auto"
                                        name="companyCode">
                                    <mat-autocomplete #auto="matAutocomplete" required
                                        (optionSelected)="selectionChange($event)" [panelWidth]="'auto'">
                                        <mat-option *ngIf="isLoading">
                                            <mat-spinner [diameter]="20"></mat-spinner>
                                        </mat-option>
                                        <mat-option *ngFor="let option of filteredOptions$  | async" [value]="option">
                                            {{option.display}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </form>
                        </div>
                        <div class="mat-button  p-2" style="gap: 10px;">
                            <mat-form-field style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>ชื่อบริษัท | Company Name</mat-label>
                                <input autocomplete="off" type="text" matInput placeholder="ชื่อบริษัท" matInput #input
                                    [(ngModel)]="searchForm.companyName" name="companyName" #companyName="ngModel">
                            </mat-form-field>
                        </div>
                        <div class="mat-button  p-2" style="gap: 10px;">
                            <mat-form-field appearance="fill" style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>สถานะ | Status</mat-label>
                                <mat-select [(ngModel)]="searchForm.status" name="status" #status="ngModel">
                                    <mat-option *ngFor="let statusOption of statusOptions"
                                        [value]="statusOption.mtConfigDCode">
                                        {{statusOption.mtConfigDTName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <!-- <mat-form-field appearance="fill" style="display: flex;flex-flow: row;flex: 1">
                            <mat-label>Exporter | ผู้ส่งออก</mat-label>
                            <mat-select>
                                <mat-option *ngFor="let exporterOption of exporterOptions | async"
                                    [value]="exporterOption">
                                    {{exporterOption}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field> -->

                        </div>
                        <div class="flex justify-end pt-2">
                            <div>
                                <button mat-raised-button (click)="clearOption()"
                                    style="flex:0 50%; margin-right: 10px;">
                                    เคลียร์
                                </button>
                                <button mat-raised-button color="accent" (click)="saveSearch()" style="flex:0 50%;">
                                    ค้นหา
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-menu>
            </div>
        </div>
    </div>

    <div class="mat-elevation-z8 ">
        <table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">

            <!-- Position Column -->
            <!-- bankAccountNo Column -->

            <ng-container matColumnDef="no">
                <th mat-header-cell *matHeaderCellDef class="text-left"> ลำดับ </th>
                <td mat-cell *matCellDef="let element;let i = index" class="text-left">
                    {{ (i+1)+(paginator?.pageIndex * 10) }} </td>
            </ng-container>
            <ng-container matColumnDef="requestBy">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> ผู้ยื่นคำขอ </th>
                <td mat-cell *matCellDef="let element"> {{element.requestBy}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="declarator">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> ผู้ส่งออก
                </th>
                <td mat-cell *matCellDef="let element"> {{element.declarator}} </td>
            </ng-container>

            <ng-container matColumnDef="rqNo">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> เลขที่คำขอ RQ NO. </th>
                <td mat-cell *matCellDef="let element"> {{element.rqNo}} </td>
            </ng-container>

            <ng-container matColumnDef="docType">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> ประเภทเอกสาร </th>
                <td mat-cell *matCellDef="let element"> {{element.docType}} </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="invoiceRef">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> Invoice No. / Reference No.
                </th>
                <td mat-cell *matCellDef="let element">{{element.invoiceRef}}</td>
            </ng-container>

            <ng-container matColumnDef="thtNo">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> เลขที่ THT </th>
                <td mat-cell *matCellDef="let element"> {{element.thtNo}} </td>
            </ng-container>
            <ng-container matColumnDef="createDate">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> วันที่สร้างรายการ </th>
                <td mat-cell *matCellDef="let element">
                    {{element.createDate | date:'yyyy-MM-dd'}} </td>
            </ng-container>
            <ng-container matColumnDef="verifyBy">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> เจ้าหน้าที่ตรวจสอบ </th>
                <td mat-cell *matCellDef="let element"> {{element.verifyBy}} </td>
            </ng-container>
            <ng-container matColumnDef="rqStatus">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> สถานะแก้ไข </th>
                <td mat-cell *matCellDef="let element"> {{element.rqStatus}} </td>
            </ng-container>
            <!-- Action ไม่ขึ้น -->
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="text-left"> Action </th>
                <td mat-cell *matCellDef="let element;let i = index" class="text-left">
                    <div class="flex">
                        <div>
                            <!-- *ngIf="buttonList.buttonCode=='BT031'" -->
                            <div *ngIf="checkButton('BT031')">
                                <!-- <button mat-icon-button matTooltip="รายละเอียด" matTooltipPosition="above"
                                    (click)="showDetail(element)"
                                    *ngIf="element.docType=='CO' || element.docType=='CO/Pack'" [queryParams]="{
                                        id: element.packId,
                                        disabled: true,
                                        recheck:true
                                        }" routerLink="/page/request/request-co/create-co">
                                    <mat-icon svgIcon="heroicons_outline:document-search"></mat-icon>
                                </button>
                                <button mat-icon-button matTooltip="รายละเอียด" matTooltipPosition="above"
                                    (click)="showDetail(element)" *ngIf="element.docType=='CF'"
                                    routerLink="/page/request/request-cf/create-cf" [queryParams]="{
                                        id: element.packId,
                                        disabled: true,
                                        recheck:true
                                        }">
                                    <mat-icon svgIcon="heroicons_outline:document-search"></mat-icon>
                                </button> -->
                                <button mat-icon-button matTooltip="รายละเอียด" matTooltipPosition="above"
                                    (click)="showDocDetail(element)">
                                    <mat-icon svgIcon="heroicons_outline:document-search"></mat-icon>
                                </button>
                            </div>
                        </div>
                        <!-- *ngIf="buttonList.buttonCode=='BT154'" -->
                        <div *ngIf="checkButton('BT154')">
                            <button mat-icon-button matTooltip="แก้ไข" *ngIf="element.action=='-'"
                                (click)="edit(element)" matTooltipPosition="above">
                                <mat-icon svgIcon="heroicons_outline:pencil-alt"></mat-icon>
                            </button>
                        </div>
                        <!-- *ngIf="buttonList.buttonCode=='BT151'" -->
                        <div *ngIf="checkButton('BT151')">
                            <button mat-icon-button matTooltip="ส่งตรวจ" *ngIf="element.action =='-'"
                                (click)="sendVerify(element)" matTooltipPosition="above">
                                <mat-icon svgIcon="heroicons_outline:paper-airplane"></mat-icon>
                            </button>
                        </div>
                        <!-- *ngIf="buttonList.buttonCode=='BT152'" -->
                        <div *ngIf="checkButton('BT152')">
                            <button mat-icon-button matTooltip="ยกเลิกส่งตรวจ" *ngIf="element.action=='W'"
                                (click)="cancel(element)" matTooltipPosition="above">
                                <mat-icon svgIcon="cancel_schedule_send"></mat-icon>
                            </button>
                        </div>
                        <!-- *ngIf="buttonList.buttonCode=='BT153'" -->
                        <div *ngIf="checkButton('BT153')">
                            <button mat-icon-button matTooltip="ลบ" *ngIf="element.action=='-'"
                                (click)="deleteItem(element)" matTooltipPosition="above">
                                <mat-icon svgIcon="heroicons_outline:trash"></mat-icon>
                            </button>
                        </div>
                        <div *ngIf="checkButton('BT155')">
                            <button mat-icon-button matTooltip="ตรวจสอบ"
                                *ngIf="element.action=='Y' && element.verifyResult==null" matTooltipPosition="above"
                                [queryParams]="{
                                        id: element.packId,
                                        disabled: true,
                                        recheckId: element.recheckId,
                                        docType : element.docType
                                        }" routerLink="/page/verify/verify-co">
                                <mat-icon svgIcon="heroicons_outline:pencil-alt"></mat-icon>
                            </button>
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons
            aria-label="Select page of periodic elements" (page)="pageChanged($event)">
        </mat-paginator>
    </div>

</div>