import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { ConfirmPopupComponent } from '../../confirm-popup/confirm-popup.component';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'
@Component({
  selector: 'app-country-group-manage-popup',
  templateUrl: './country-group-manage-popup.component.html',
  styleUrls: ['./country-group-manage-popup.component.css']
})
export class CountryGroupManagePopupComponent implements OnInit {
  form: any = {};
  bankList: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef: MatDialogRef<CountryGroupManagePopupComponent>,
    private appMasterService: AppMasterService,
    private dialog: MatDialog,
    private alertMessageService: AlertMessageService
  ) {
    if (data) {
      this.form = data;
    } else {
      this.form.isActive = 'Y';
    }
  }
  ngOnInit(): void {
  }

  save() {
    if (this.form.countryGroupName !== undefined && this.form.countryGroupName !== null && this.form.countryGroupName !== "") {
      const data = {
        "countryGroupId": this.form.countryGroupId,
        "countryGroupName": this.form.countryGroupName,
        "countryGroupDescription": this.form.countryGroupDescription,
        "isActive": this.form.isActive
      };

      this.appMasterService.saveCountryGroup(data).then((res: any) => {
        if (res === "error") {
          this.alertMessageService.open({message: "บันทึกข้อมูลไม่สำเร็จ", title:""});
        } else {
          this.dialogRef.close();
          this.alertMessageService.open({message: "บันทึกข้อมูลสำเร็จ", title:""});
        }
      });
    } else {
      this.alertMessageService.open({ message: "กรุณากรอกข้อมูลให้ครบ", title: "ข้อผิดพลาด" });
    }
  }

  onChange(e) {
    if (e.checked == true) {
      this.form.isActive = "Y";
    } else {
      this.form.isActive = "N";
    }
  }

  endPopup(e) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      disableClose: false,
      // width: '30vw',
      data: {
        header: e,
        button: 'close'
      },
      maxWidth: 'none'
    });

    dialogRef.afterClosed().subscribe(res => {
      this.dialogRef.close();
    });
  }
}
