import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-verify-image-remark',
  templateUrl: './verify-image-remark.component.html',
  styleUrls: ['./verify-image-remark.component.css']
})
export class VerifyImageRemarkComponent implements OnInit {
  form: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.form = data;
  }

  ngOnInit() {
  }
}
