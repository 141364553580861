import { Injectable } from "@angular/core";
import { ApiService } from "@bundle/service/api.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ApiPaymentService {
  constructor(private apiService: ApiService) {}

  getPaymentList(request: any): Observable<any[]> {
    return this.apiService.post("/api/getPaymentList", request);
  }

  getPaymentObjList(request: any): Observable<any[]> {
    return this.apiService.post("/api/paymentObjList", request);
  }

  saveCreaterPaymantRequest(request: any): Observable<any[]> {
    return this.apiService.post("/api/saveCreaterPaymantRequest", request);
  }

  saveCreaterPaymantRequestNew(request: any): Observable<any[]> {
    return this.apiService.post("/api/saveCreaterPaymantRequestNew", request);
  }
  getMasterFeeRateList(request: any): Observable<any[]> {
    return this.apiService.post("/api/getMasterFeeRateList", request);
  }

  getRelationshipList(request: any): Observable<any[]> {
    return this.apiService.post("/api/getRelationshipList", request);
  }

  getDetailPaymentRequest(request: any): Observable<any[]> {
    return this.apiService.post("/api/getDetailPaymentRequest", request);
  }

  updateStatusPayment(request: any): Observable<any[]> {
    return this.apiService.post("/api/updateStatusPayment", request);
  }

  getCompanyListForPayment(request: any): Observable<any[]> {
    return this.apiService.post("/api/getCompanyListForPayment", request);
  }

  pullPaymentFromCo(request: any): Observable<any[]> {
    return this.apiService.post("/api/pullPaymentFromCo", request);
  }
  
  dataReprintForPay(request: any): Observable<any[]> {
    return this.apiService.post("/api/dataReprintForPay", request);
  }
  
  getCompanyAddressReciept(request: any): Observable<any[]> {
    return this.apiService.post("/api/getCompanyAddressReciept", request);
  }

  checkIsSeftPrint(request: any): Observable<any[]> {
    return this.apiService.post("/api/checkIsSeftPrint", request);
  }

  exportBillPayment(request: any): Observable<any[]> {
    return this.apiService.post("/api/exportBillPayment", request);
  }

  getPaymentDetailByPackId(request: any): Observable<any[]> {
    return this.apiService.post("/api/paymentListGetByPackId", request);
  }

  paymentGetEmailETax(request: any): Observable<any[]> {
    return this.apiService.post("/api/paymentGetEmailETax", request);
  }
  getStatusPaymentList(): Observable<any[]> {
    return this.apiService.get("/api/getStatusPaymentList");
  }
  reportPayment(request: any): Observable<any[]> {
    return this.apiService.post("/api/reportPayment", request);
  }
  reportApporvedCoCf(request: any): Observable<any[]> {
    return this.apiService.post("/api/reportApporvedCoCf", request);
  }
}
