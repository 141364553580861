import { filter } from "rxjs";
import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AppPaymentService } from "app/business/service/app/app-payment.sevice";
import _ from "lodash";
import { AppUserService } from "app/business/service/app/app-user.service";
import { AuthService } from "app/core/auth/auth.service";

@Component({
  selector: "app-add-payment-requset-modal",
  templateUrl: "./add-payment-requset-modal.component.html",
  styleUrls: ["./add-payment-requset-modal.component.css"],
})
export class AddPaymentRequeestModalComponent implements OnInit {
  form: any = {};
  dataSource: any = [];
  detail_list: any = [];
  detailExdList: any = [];
  selectionList: any = [];
  temp_detail_list: any = [];
  userProfile: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<AddPaymentRequeestModalComponent>,
    private appPaymentService: AppPaymentService,
    private appUserService:AppUserService,
    private _authService:AuthService,
  ) {

    this.userProfile = JSON.parse(
      this._authService.loginUser
    );
    
  }

  ngOnInit() {
    if (this.data.detail_list != undefined) {
      this.detail_list = this.data.detail_list.map((res) => {
        return {
          certificateNo: res.th_number,
        };
      });
    }
    if (this.data.detailExdList != undefined) {
      this.detailExdList = this.data.detailExdList.map((res) => {
        return {
          certificateNo: res.certificateNo,
        };
      });
    }
    const formData = {
      uId: Number(this.userProfile.uId),
    };

    this.temp_detail_list = this.data.temp_detail_list;
    
    
    this.appPaymentService.getPaymentObjList(formData).then((res) => {
      this.dataSource = res;
      
      this.dataSource.push(...this.data.temp_detail_list);
      // 
      
      this.dataSource = this.dataSource.filter(
        (f) => f.companyId == this.data.companyId
      );
      
      this.dataSource = _.differenceBy(
        this.dataSource,
        this.detailExdList,
        "certificateNo"
      );
    });
  }

  selectionChanged(e) {
    this.selectionList = e.selectedRowsData;
  }
}
