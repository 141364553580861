import { Component } from '@angular/core';

@Component({
  selector: 'app-request-job-edit-inquiry',
  templateUrl: './request-job-edit-inquiry.component.html',
  styleUrls: ['./request-job-edit-inquiry.component.scss']
})
export class RequestJobEditInquiryComponent {

}
