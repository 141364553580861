import { LiveAnnouncer } from '@angular/cdk/a11y'
import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core'
import { MatSort, Sort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { FormControl } from '@angular/forms'
import {
  IcoStatus,
  IgetListCORequest,
} from 'app/business/interface/app/app-co.interface'
import { AppCOService } from 'app/business/service/app/app-co.service'
import { IgetListCOResponse } from 'app/business/interface/api/api-co.interface'
import { MatPaginator } from '@angular/material/paginator'
import { AppPrintService } from 'app/business/service/app/app-print.service'
import { DxDataGridComponent } from 'devextreme-angular'
import { MatDialog } from '@angular/material/dialog'
import { SelectionModel } from '@angular/cdk/collections'
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'
import { RequestJobPopupComponent } from './request-job-popup/request-job-popup.component'
import { AppRequestService } from 'app/business/service/app/app-request.service'
import { IGetListJobRequest } from 'app/business/interface/app/app-job.interface'
import { IGetListJobResponse } from 'app/business/interface/api/api-job.interface'
import moment from 'moment'
import { MatDatepickerInputEvent } from '@angular/material/datepicker'
import { debounce } from 'lodash'
import { Router } from '@angular/router'
import { AppUserService } from 'app/business/service/app/app-user.service'
import { AppMenuService } from 'app/business/service/app/app-menu.service'
import { ConfirmDialogService } from '@bundle/component/confirm-dialog/confirm-dialog.service'
import { PrintCoPackPopupInquiryComponent } from '../list-print-inquiry/print-co-pack-popup-inquiry/print-co-pack-popup-inquiry.component'
import { PrintPopupInquiryComponent } from '../list-print-inquiry/print-popup-inquiry/print-popup-inquiry.component'
import { ListCoSendPrintToStaffPopupComponent } from '../list-co-inquiry/list-co-send-print-to-staff-popup-inquiry/list-co-send-print-to-staff-popup-inquiry.component'
import { AppMasterService } from 'app/business/service/app/app-master.service'
import { AuthService } from 'app/core/auth/auth.service'
@Component({
  selector: 'app-request-job-inquiry',
  templateUrl: './request-job-inquiry.component.html',
  styleUrls: ['./request-job-inquiry.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RequestJobInquiryComponent implements OnInit, AfterViewInit {
  searchForm: any = {
    rqNo: '',
    thtNo: '',
    startDate: null,
    endDate: null,
    searchString: '',
  }
  myDate = new Date();
  displayedColumns: string[] = [
    'docType',
    'requestNo',
    'thtNoOld',
    'thtNoNew',
    'requestDate',
    'refNo',
    'rqStatus',
    'action',
  ]
  searchString: string
  apiRequest: IGetListJobRequest = {
    thtNo: '',
    rqNo: '',
    startDate: null,
    endDate: null,
    pagination: {
      Skip: 0,
      Take: 10,
    },
    searchString: '',
  }
  isBlacklisted: boolean = false;
  apiResponse: IGetListJobResponse[] = []
  dataList: any[] = []
  statusControl = new FormControl('')
  exporterControl = new FormControl('')
  statusOptions: IcoStatus[] = [
    {
      text: 'แสดงทั้งหมด',
      code: null,
    },
    {
      text: 'เคยพิมพ์เอกสาร',
      code: 'Y',
    },
    {
      text: 'ไม่เคยพิมพ์เอกสาร',
      code: 'N',
    },
  ]
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent
  exporterOptions: string[]
  dataSource = new MatTableDataSource<any>()
  selection = new SelectionModel<any>(true, [])

  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort
  dataTempList: any[]
  checkJob: boolean
  form: any = {}
  count: any = 0
  checkEndDate: boolean = true;
  checkStartDate: boolean = true;
  url: any;
  buttonList: any[] = [];
  timer: any;


  totalPageCF: number;
  docHid: number;
  userProfile:any;
  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private appRequestService: AppRequestService,
    private dialog: MatDialog,
    private alertService: AlertMessageService,
    private router: Router,
    private appUserService: AppUserService,
    private appMenuService: AppMenuService,
    private confirmService: ConfirmDialogService,
    private appCOService: AppCOService,
    private appPrintService: AppPrintService,
    private appMasterService: AppMasterService,
    private _authService:AuthService,
  ) {

    this.applyFilter = debounce(this.applyFilter, 500)
  }

  async getRouter(): Promise<void> {
    
    this.url = this.router.url;

    const profile = JSON.parse(this.appUserService.getSessionUserProfile());
    const formData = {
      url: this.url,
      userGroupCode: this.userProfile.userGroupCode,
      userRoleCode: this.userProfile.userRoleCode,
      userCode: this.userProfile.userCode
    };
    this.buttonList = await this.appMenuService.getButtonPermissionPerPages(formData);
    
  }

  async ngOnInit() {
    this.userProfile = JSON.parse(
      this._authService.loginUser
    );
    await this.getRouter();
    this.isBlacklisted = this.userProfile.isBlacklisted;

    this.dataSource.paginator = this.paginator
    this.apiRequest.pagination = {
      Skip:
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
      Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
    }
    this.loadData(this.apiRequest)
  }
  ngAfterViewInit() {
    this.paginator.pageIndex = 0
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
  }
  async loadData(request: IGetListJobRequest) {
    this.apiRequest.searchString = typeof (this.apiRequest.searchString) !== "string" ? "" : this.apiRequest.searchString;
  
    if (this.paginator != undefined && this.paginator != null) {
      this.paginator.pageIndex = 0
    }

    let reqList = {
      uId: this.userProfile.uId,
      gId: this.userProfile.gId,
      thtNo: this.apiRequest.thtNo, //ถ้าไม่เช็คมันจะเอาไป where แบบ and มันจะพัง
      rqNo: this.apiRequest.rqNo, //ถ้าไม่เช็คมันจะเอาไป where แบบ and มันจะพัง
      searchString: this.apiRequest.searchString,
      startDate: this.apiRequest.startDate,
      endDate: this.apiRequest.endDate,
      pagination: {
        Skip: 0,
        Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
      }
    } as IGetListJobRequest

    await this.appRequestService.getListJob(reqList).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        if (res !== null) {
          this.dataSource.data = res.response
          this.count = res.totalCount
        } else {
          this.dataSource.data = []
          this.count = 0
        }
      }
    })
    this.dataSource.paginator.length = this.count
  }

  applyFilter(event: string) {
    clearTimeout(this.timer); // Clear any existing timer

    this.timer = setTimeout(() => {
      // This code will be executed after a specified delay (e.g., 500 milliseconds)
      
      // Send the value to the backend or perform any other necessary actions
      this.apiRequest.searchString = this.searchForm.searchString;
      this.dataSource.paginator = this.paginator
      this.loadData(this.apiRequest)

    }, 500); // Specify the desired delay in milliseconds
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`)
    } else {
      this._liveAnnouncer.announce('Sorting cleared')
    }
  }
  stopPropagation(event) {
    event.stopPropagation()
  }
  saveSearch() {
    this.searchForm.searchString = ""
    if (
      (moment(this.searchForm.startDate).format('YYYY-MM-DD') >
        moment(this.searchForm.endDate).format('YYYY-MM-DD')) && !moment(this.searchForm.startDate).format('YYYY-MM-DD')
    ) {
      this.alertService.open({
        message: 'กรุณาระบุวันที่สิ้นสุดให้มากกว่าวันที่เริ่มต้น',
        title: 'กรุณากรอกข้อมูลค้นหาให้ถูกต้อง',
      })
      this.searchForm.startDate = null;
      this.searchForm.endDate = null
      return false
    }
    this.apiRequest.thtNo = this.searchForm.thtNo
    this.apiRequest.rqNo = this.searchForm.rqNo
    this.apiRequest.endDate = this.searchForm.endDate
    this.apiRequest.startDate = this.searchForm.startDate
    this.dataSource.paginator = this.paginator
    this.checkEndDate = true;
    this.loadData(this.apiRequest)
  }

  onRefresh() {
    this.loadData(this.apiRequest);
  }

  print(e) {
    if (this.isBlacklisted) {
      this.alertService.open({ message: "ไม่สามารถพิมพ์เอกสารได้ เนื่องจากบริษัทของคุณติดสถานะ Blacklist", title: "" });
    } else {
      var actionReq = {
        id: e.newPackId,
        action: "PRINT",
        refTable: "DOCUMENT_REQUEST_OBJ"
      };

      this.appMasterService.getActionStatus(actionReq).then(async res => {
        if (res === 'error') {
          return;
        } else {
          var data = res;

          if (data.allowAction === 'Y') {
            
            this.confirmService
              .open({
                header: 'ยืนยันการทำรายการ',
                content: e.rqStatus == 6?'ต้องการพิมพ์เอกสาร':'ต้องการพิมพ์ Draft หรือไม่',
              })
              .then((res) => {
                
                if (res == true && e.docType == 'CO/Pack') {
                  const dataList = {
                    requestNo: e.rqNo,
                    docType: e.docType,
                    expressType: 'งานแก้ไข Job',
                    packId: e.newPackId,
                    printPack: true
                  }
                  const dialogRef = this.dialog.open(PrintCoPackPopupInquiryComponent, {
                    disableClose: false,
                    width: '70vw',
                    data: dataList,
                    maxWidth: 'none',
                  })
                  dialogRef.afterClosed().subscribe((res) => {
                    if (res == false) {
                      return
                    } else {
                      return
                    }
                  })
                }
                else if (res == true && e.docType == 'CO') {
                  const data = {
                    Hid: e.newPackId,
                  }
                  if (e.rqStatus == 6) {
                    if (e.selfPrint == "Y") {
                      this.appCOService.getPrintOriginal(data).then((res: any) => {
                        if (res === 'error') {
                          return
                        } else {
                          window.open(res.linkUrl, '_blank')
                          this.loadData(this.apiRequest)
                        }
                      })
                    } else {
                      this.alertService
                        .open({ title: 'Error Message', message: 'ท่านได้แจ้งความประสงค์ให้ทางหอการค้าไทยพิมพ์เอกสาร' })
                        .then((res) => {
                          this.loadData(this.apiRequest)
                        })
                    }
                  } else {
                    this.appCOService.getPreviewCOPack(data).then((res: any) => {
                      if (res === 'error') {
                        return
                      } else {
                        window.open(res.linkUrl, '_blank')
                      }
                    })
                  }
                } else if (res == true && e.docType == 'CF') {
                  
                  this.printCFPopup(e)
                }
              })
          } else {
            this.alertService.open({ message: "ไม่สามารถพิมพ์เอกสารได้ เนื่องจากเอกสารได้ถูกพิมพ์แล้ว", title: "" }).then(res => {
              this.loadData(this.apiRequest);
              return;
            });
          }
        }
      });
    }
  }

  sendVerify(e) {
    const data = {
      packId: e.newPackId,
    }
    this.appRequestService.sendToVerify(data).then((res) => {
      if (res == true) {
        this.alertService
          .open({ title: 'ทำรายการสำเร็จ', message: 'ส่งตรวจสำเร็จ' })
          .then(() => {
            this.loadData(this.apiRequest)
          })
      } else {
        return
      }
    })
  }
  showDetail() { }
  deleteItem(e) {
    if (this.isBlacklisted) {
      this.alertService.open({ message: "ไม่สามารถลบคำขอได้ เนื่องจากบริษัทของคุณติดสถานะ Blacklist", title: "" });
    } else {
      var actionReq = {
        id: e.newPackId,
        action: "DELETE",
        refTable: "DOCUMENT_REQUEST_OBJ"
      };

      this.appMasterService.getActionStatus(actionReq).then(res => {
        if (res === 'error') {
          return;
        } else {
          var data = res;

          if (data.allowAction === 'Y') {
            this.confirmService
              .open({
                header: 'ยืนยันการทำรายการ',
                content: 'ต้องการยกเลิกคำขอนี้?',
              })
              .then((res) => {
                if (res == true) {
                  this.appCOService.rejectRequestJob({ packId: e.newPackId }).then((res: any) => {
                    if (res === 'error') {
                      return
                    } else {
                      this.loadData(this.apiRequest);
                    }
                  })
                }
              })
          } else {
            this.alertService.open({ message: "ไม่สามารถลบรายการได้ เนื่องจากเอกสารถูกลบในระบบแล้ว", title: "" }).then(res => {
              this.loadData(this.apiRequest);
              return;
            });
          }
        }
      });
    }
  }
  editItem(e) {
    if (this.isBlacklisted) {
      this.alertService.open({ message: "ไม่สามารถแก้ไขคำขอได้ เนื่องจากบริษัทของคุณติดสถานะ Blacklist", title: "" });
    } else {
      var actionReq = {
        id: e.newPackId,
        action: "EDIT",
        refTable: "DOCUMENT_REQUEST_OBJ"
      };

      this.appMasterService.getActionStatus(actionReq).then(res => {
        if (res === 'error') {
          return;
        } else {
          var data = res;

          if (data.allowAction === 'Y') {
            this.router.navigate(['/page/request/list-job-edit'], {
              queryParams: {
                oldPackId: e.oldPackId,
                newPackId: e.newPackId,
                docType: e.docType
              }
            });
          } else {
            this.alertService.open({ message: "ไม่สามารถแก้ไขคำขอได้ เนื่องจากสถานะเอกสารถูกเปลี่ยนในระบบแล้ว", title: "" }).then(res => {
              this.loadData(this.apiRequest);
              return;
            });
          }
        }
      });
    }
  }

  createJob() {

    if (this.isBlacklisted) {
      this.alertService.open({ message: "ไม่สามารถสร้างคำขอใหม่ได้ เนื่องจากบริษัทของคุณติดสถานะ Blacklist", title: "" });
    } else {
      const dialogRef = this.dialog.open(RequestJobPopupComponent, {
        disableClose: false,
        width: '80vw',
        data: {},
        maxWidth: 'none',
      })
      dialogRef.afterClosed().subscribe((res) => {
        if (res == false) {
          return
        } else {
          return
        }
      })
    }
  }

  async pageChanged(e) {
    this.dataSource = new MatTableDataSource([])
    this.apiRequest.pagination = {
      Skip: (!e.pageIndex ? 0 : e.pageIndex) * (!e.pageSize ? 0 : e.pageSize),
      Take: !e.pageSize ? 10 : e.pageSize,
    }
    await this.appRequestService
      .getListJob(this.apiRequest)
      .then((res: any) => {
        if (res === 'error') {
          return
        } else {
          if (res.response !== null) {

            this.dataSource.data = res.response
            this.count = res.totalCount
          } else {
            this.dataSource = new MatTableDataSource((res.response = []))
            this.count = 0
          }
        }
      })
    this.dataSource.paginator.length = this.count
    this.dataSource.paginator.pageIndex = e.pageIndex
  }

  clearOption() {
    this.searchForm = {}
    this.apiRequest = {
      thtNo: '',
      rqNo: '',
      startDate: null,
      endDate: null,
      pagination: {
        Skip: 0,
        Take: 10,
      },
      searchString: '',
    }

    this.checkEndDate = true;
    this.loadData(this.apiRequest);
  }


  datecheck(e: MatDatepickerInputEvent<Date>) {
    if (
      moment(this.searchForm.startDate).format('YYYY-MM-DD') >
      moment(this.searchForm.endDate).format('YYYY-MM-DD')
    ) {
      this.searchForm.endDate = null;
      this.checkEndDate = false;
    }
  }

  endDateCheck(e) {
    if (
      moment(this.searchForm.startDate).format('YYYY-MM-DD') ==
      moment(this.searchForm.endDate).format('YYYY-MM-DD') ||
      moment(this.searchForm.startDate).format('YYYY-MM-DD') <
      moment(this.searchForm.endDate).format('YYYY-MM-DD')
    ) {
      this.checkEndDate = true;
    }
  }

  async printCFPopup(e) {
    if (e.printStatus == "Y") {
      
      this.alertService.open({
        title: "ผิดพลาด",
        message:
          "คุณได้ดำเนินการพิมพ์เอกสารไปแล้ว กรุณาติดต่อเจ้าหน้าที่ หรือทำการขอ Reprint ได้ใหม่โดยการเข้าไปที่เมนู Print > Reprint",
      }).then((x) => {
        this.dialog.closeAll();
      });
    } else {
      let data = {
        docId: e.newPackId
      }
      await this.appCOService.getCfTotalPage(data).then((x) => {
        this.docHid = x?.docId;
        this.totalPageCF = x?.totalPage;
      });
      let dataPopup = {
        id: e.newPackId,
        totalPage: this.totalPageCF ? this.totalPageCF : 0,
        hid: this.docHid,
        rqNo: e.requestNo
      }
      
      const dialogRef = this.dialog.open(PrintPopupInquiryComponent, {
        disableClose: false,
        width: "60vw",
        data: dataPopup,
        maxWidth: "none",
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          if (res.data.checkPrint.filter(Boolean).length !== 0) {
            const data = {
              uid: this.userProfile.uId,
              Hid: res.data.hid,
              id: res.data.id,
              checkPrint: res.data.checkPrint,
            };
            this.appPrintService.getPrintOriginalCF(data).then((res: any) => {
              if (res === "error") {
                return;
              } else {
                this.dataSource.paginator = this.paginator;
                this.loadData(this.apiRequest);
                window.open(res.linkUrl, "_blank");
              }
            });
          } else {
            this.alertService.open({
              title: "กรุณากรอกข้อมูลให้ครบถ้วน",
              message: "กรุณาเลือกตำแหน่งที่ต้องการพิมพ์",
            }).then((x) => {
              this.dialog.closeAll();
            });
          }
        }
      });
    }
  }
  cancel(e) {
    if (this.isBlacklisted) {
      this.alertService.open({ message: "ไม่สามารถยกเลิกคำขอส่งตรวจได้ เนื่องจากบริษัทของคุณติดสถานะ Blacklist", title: "" });
    } else {
      var actionReq = {
        id: e.newPackId,
        action: "UNSEND",
        refTable: "DOCUMENT_REQUEST_OBJ"
      };

      this.appMasterService.getActionStatus(actionReq).then(res => {
        if (res === 'error') {
          return;
        } else {
          var data = res;

          if (data.allowAction === 'Y') {
            const data = {
              docId: e.docId,
              packId: e.newPackId,
              queueId: e.queueId,
              isRqStatus: '34',
            }
            this.confirmService
              .open({
                header: 'ยืนยันการทำรายการ',
                content: 'ต้องการยกเลิกคำขอรายการนี้ใช่หรือไม่?',
              })
              .then((res) => {
                if (res == true) {
                  this.appCOService.cencelVerify(data).then((res) => {
                    if (res == 'error') {
                      return
                    } else {
                      this.alertService
                        .open({ title: 'ทำรายสำเร็จ', message: 'ยกเลิกคำขอสำเร็จ' })
                        .then((res) => {
                          this.dataSource.paginator = this.paginator
                          this.loadData(this.apiRequest)
                        })
                    }
                  })
                }
              })
          } else {
            this.alertService.open({ message: "ไม่สามารถยกเลิกส่งตรวจได้ เนื่องจากสถานะเอกสารถูกเปลี่ยนในระบบแล้ว", title: "" }).then(res => {
              this.loadData(this.apiRequest);
              return;
            });
          }
        }
      });
    }
  }
  openPopupSendPrintTostaff(e) {
    if (this.isBlacklisted) {
      this.alertService.open({ message: "ไม่สามารถพิมพ์เอกสารได้ เนื่องจากบริษัทของคุณติดสถานะ Blacklist", title: "" });
    } else {

      var actionReq = {
        id: e.newPackId,
        action: "PRINT",
        refTable: "DOCUMENT_REQUEST_OBJ"
      };

      this.appMasterService.getActionStatus(actionReq).then(res => {
        if (res === 'error') {
          return;
        } else {
          var data = res;

          if (data.allowAction === 'Y') {

            
            let data = {
              packId: e.newPackId,
              requestNo: e.rqNo,
              docType: e.docType,
              expressType: e.expressType,
              consignor: e.consignor
            }
            
            const dialogRef = this.dialog.open(ListCoSendPrintToStaffPopupComponent, {
              disableClose: false,
              width: '70vw',
              data: data,
              maxWidth: 'none',
            })
            dialogRef.afterClosed().subscribe((res) => {
              if (!res) {
                return
              } else {
                this.loadData(this.apiRequest);
                return
              }
            })
          }
        }
      });
    }
  }

  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find(e => e.buttonCode == buttonCode);

    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }
}
