<mat-card>
    <mat-card-header>
        <div class="flex justify-between" style="width: 100%;">
            <div class="text-lg"> ค้นหาคำขอ</div>
            <div>
                <button class="close-button" [mat-dialog-close]="true">
                    <mat-icon class="text-white" [mat-dialog-close]>cancel</mat-icon>
                </button>
            </div>
        </div>
    </mat-card-header>

    <mat-dialog-content class="mat-typography">
        <div class="flex flex-col justify-center gap-3">
            <div class="flex flex-row gap-3">
                <div class="w-1/6 flex flex-col">
                    <h3 class="black-text">เลขที่ใบคำขอ</h3>
                    <small class="black-text">Request No.</small>
                </div>
                <mat-form-field class="w-2/6">
                    <input autocomplete="off" type="text" matInput placeholder="เลขที่ใบคำขอ" matInput #input
                        [(ngModel)]="searchForm.rqNo" name="requestNo" #requestNo="ngModel">
                </mat-form-field>
                <div class="w-1/6 flex flex-col">
                    <h3 class="black-text">เลขที่ THT</h3>
                    <small class="black-text">Certificate No.</small>
                </div>
                <mat-form-field class="w-2/6">
                    <input autocomplete="off" type="text" matInput placeholder="เลขที่ THT" matInput #input
                        [(ngModel)]="searchForm.thtNo" name="thtNo" #thtNo="ngModel">
                </mat-form-field>

            </div>
            <div class="flex flex-row gap-3">
                <div class="w-1/6 flex flex-col">
                    <h3 class="black-text">เลขที่ใบกำกับสินค้า / เลขที่เอกสาร</h3>
                    <small class="black-text">Invoice No. / Reference No.</small>
                </div>
                <mat-form-field class="w-2/6">
                    <input autocomplete="off" type="text" matInput placeholder="เลขที่ใบกำกับสินค้า / เลขที่เอกสาร"
                        matInput #input [(ngModel)]="searchForm.payNo" name="payNo" #payNo="ngModel">
                </mat-form-field>
                <div class="w-1/6 flex flex-col">
                    <h3 class="black-text">รหัสบริษัท / ผู้ส่งออก</h3>
                    <small class="black-text">Company Code / Declarator</small>
                </div>
                <mat-form-field class="w-2/6">
                    <input autocomplete="off" type="text" matInput placeholder="รหัสบริษัท / ผู้ส่งออก" matInput #input
                        [(ngModel)]="searchForm.compCode" name="compCode" #compCode="ngModel">
                </mat-form-field>
            </div>

            <div class="flex flex-row justify-end">
                <button mat-raised-button color="accent" (click)="saveSearch()" class="w-1/6">
                    ค้นหา
                </button>
            </div>


            <div class="mat-elevation-z8 ww">
                <table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">
                    <ng-container matColumnDef="rqNo">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> Request No. </th>
                        <td mat-cell *matCellDef="let element"> {{element.rqNo}} </td>
                    </ng-container>
                    <ng-container matColumnDef="declarator">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> ผู้ส่งออก</th>
                        <td mat-cell *matCellDef="let element">
                            {{element.declarator}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="expressType">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> Express Type
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.expressType == 'Y' ? 'เร่งด่วน' : element.expressType == 'N' ? 'ปกติ' : element.expressType  }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="requestDate">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> Request Date time</th>
                        <td mat-cell *matCellDef="let element"> {{element.requestDate}} </td>
                    </ng-container>
                    <ng-container matColumnDef="approveDate">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> Approve Datetime
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.approveDate | date:'yyyy-MM-dd'}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="docType">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> Document Type </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.docType }} </td>
                    </ng-container>

                    <ng-container matColumnDef="approvalResults">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> Status </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.rqStatus }} {{(element.action == 5 || element.action == 10) && element.hasPayment == 'Y'?'(มีการร้องขอชำระเงิน)':''}} </td>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef class="text-left"> Action </th>
                        <td mat-cell *matCellDef="let element;let i = index">

                            <button mat-icon-button matTooltip="สร้างคำขอตรวจซ้ำ" (click)="createJob(element)" *ngIf="(element.action == 5 || element.action == 10) && element.hasPayment == 'N'">
                                <mat-icon svgIcon="heroicons_outline:pencil-alt"></mat-icon>
                            </button>
                            <button mat-icon-button matTooltip="ส่งตรวจ" *ngIf="(element.action == 9 && element.recheckApproveType !='W')"
                                (click)="sendVerify(element)" matTooltipPosition="above">
                                <mat-icon svgIcon="heroicons_outline:paper-airplane"></mat-icon>
                            </button>    
                            <!-- <button mat-icon-button matTooltip="ยกเลิกส่งตรวจ"  *ngIf="(element.action == 9 && element.recheckApproveType !='W')"
                                (click)="cancel(element)" matTooltipPosition="above">
                                <mat-icon svgIcon="cancel_schedule_send"></mat-icon>
                            </button>   -->
                            <button mat-icon-button matTooltip="รายละเอียดคำขอ" matTooltipPosition="above" *ngIf="(element.action == 9 && element.recheckApproveType =='W')"
                                (click)="showDetail(element)">
                                <mat-icon svgIcon="heroicons_outline:document-search"></mat-icon>
                            </button>                                                  
                            <!-- <button mat-icon-button matTooltip="ตรวจสอบ"  matTooltipPosition="above" *ngIf="(element.action == 9 && element.recheckApproveType !='W')"
                                [queryParams]="{
                                        id: element.packId,
                                        disabled: true,
                                        recheckId: element.recheckId,
                                        docType : element.docType
                                        }" routerLink="/page/verify/verify-co">
                                <mat-icon svgIcon="heroicons_outline:pencil-alt"></mat-icon>
                            </button>           -->
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons
                    aria-label="Select page of periodic elements" (page)="pageChanged($event)">
                </mat-paginator>
            </div>
        </div>

    </mat-dialog-content>
</mat-card>