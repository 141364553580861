import { Navigation } from "./../../../core/navigation/navigation.types";
import { AppMenuService } from "./../../../business/service/app/app-menu.service";
import { Injectable } from "@angular/core";
import { cloneDeep } from "lodash-es";
import { FuseNavigationItem } from "@fuse/components/navigation";
import { FuseMockApiService } from "@fuse/lib/mock-api";
import {
  compactNavigation,
  defaultNavigation,
  futuristicNavigation,
  horizontalNavigation,
} from "app/mock-api/common/navigation/data";
import { AppUserService } from "app/business/service/app/app-user.service";

@Injectable({
  providedIn: "root",
})
export class NavigationMockApi {
  private readonly _compactNavigation: FuseNavigationItem[] = compactNavigation;
  private readonly _defaultNavigation: FuseNavigationItem[] = defaultNavigation;
  private readonly _futuristicNavigation: FuseNavigationItem[] =
    futuristicNavigation;
  private readonly _horizontalNavigation: FuseNavigationItem[] =
    horizontalNavigation;

  /**
   * Constructor
   */
  constructor(
    private _fuseMockApiService: FuseMockApiService,
    private appMenuService: AppMenuService,
    private appUserService: AppUserService,
  ) {
    var userProfile = this.appUserService.getSessionUserProfile() != "" ? this.appUserService.getSessionUserProfile():null;
    if (userProfile != null && userProfile != undefined) {
      this.appMenuService.getMenuPermission().then((res) => {
        let menus =  this.appMenuService.setNavigation(res);
        let fuseItem = menus.map((x) => {
          return {
            ...x,
            children: x.children == undefined ? null : x.children,
            icon: x.icon,
            id: x.id,
            link: x.url,
            title: x.title,
            type: x.type == "item" ? "basic" : x.type, //x.type,
          } as FuseNavigationItem;
        });
        this._futuristicNavigation.push(...fuseItem);
        this._compactNavigation.push(...fuseItem);
        this._defaultNavigation.push(...fuseItem);
        this._horizontalNavigation.push(...fuseItem);
      });
    }
    // Register Mock API handlers
    this.registerHandlers();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Register Mock API handlers
   */
  registerHandlers(): void {
    // -----------------------------------------------------------------------------------------------------
    // @ Navigation - GET
    // -----------------------------------------------------------------------------------------------------

    this._fuseMockApiService.onGet("api/common/navigation").reply(() => {
      // Fill compact navigation children using the default navigation
      this._compactNavigation.forEach((compactNavItem) => {
        this._defaultNavigation.forEach((defaultNavItem) => {
          if (defaultNavItem.id === compactNavItem.id) {
            compactNavItem.children = cloneDeep(defaultNavItem.children);
          }
        });
      });

      // Fill futuristic navigation children using the default navigation
      this._futuristicNavigation.forEach((futuristicNavItem) => {
        this._defaultNavigation.forEach((defaultNavItem) => {
          if (defaultNavItem.id === futuristicNavItem.id) {
            futuristicNavItem.children = cloneDeep(defaultNavItem.children);
          }
        });
      });

      // Fill horizontal navigation children using the default navigation
      this._horizontalNavigation.forEach((horizontalNavItem) => {
        this._defaultNavigation.forEach((defaultNavItem) => {
          if (defaultNavItem.id === horizontalNavItem.id) {
            horizontalNavItem.children = cloneDeep(defaultNavItem.children);
          }
        });
      });

      // Return the response
      return [
        200,
        {
          compact: cloneDeep(this._compactNavigation),
          default: cloneDeep(this._defaultNavigation),
          futuristic: cloneDeep(this._futuristicNavigation),
          horizontal: cloneDeep(this._horizontalNavigation),
        },
      ];
    });
  }
}
