import { Injectable } from '@angular/core';
import { ApiService } from '@bundle/service/api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiCompanyService {

  constructor(
    private apiService: ApiService
  ) { }

  getCompanyProfileList(request: any): Observable<any> {
    return this.apiService.post("/api/getCompanyProfileList", request);
  }

  getCompanyProfile(request: number): Observable<any> {
    return this.apiService.post("/api/getCompanyProfile", {
      cpId: request
    });
  }

  getGradeFactory(request:any): Observable<any> {
    return this.apiService.post("/api/getGradeFactory", request);
  }

  updateCompanyEPayment(request: any): Observable<any> {
    return this.apiService.post("/api/updateCompanyEPayment", request);
  }

  getListCompanyByFilter(request: any): Observable<any> {
    return this.apiService.post("/api/getListCompanyByFilter", {
      key: request
    });
  }
  getListReqCompanyByFilter(request: any): Observable<any> {
    return this.apiService.post("/api/getListCompanyByFilter", {
      key: request
    });
  }
  getListDeclaratorFilter(request: any): Observable<any> {
    return this.apiService.post("/api/getListCompanyByFilter", {
      key: request
    });
  }
  getListThtByFilter(request: any): Observable<any> {
    return this.apiService.post("/api/getThtFillter", {
      fillter: request
    });
  }
  getListTariffByFilter(request: any): Observable<any> {
    return this.apiService.post("/api/getTariffFillter", {
      fillter: request
    });
  }
}
