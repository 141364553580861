<div style="gap: 30px;padding-top: 30px;" class="flex flex-col h-full">
  <!-- <div class="pl-20 pr-20 menu-top justify-between flex items-center flex-row text-white">
      <div class="menu-top-sub flex flex-row">
          <h2 class="text-center cursor-pointer">Request</h2>
          <h2 class="text-center cursor-pointer">Payment</h2>
          <h2 class="text-center cursor-pointer">Print</h2>
          <h2 class="text-center cursor-pointer">Configuration</h2>
      </div>


      <div>
          <h2 class="text-center cursor-pointer">Master bank account</h2>
      </div>
  </div> -->
  <div class="menu justify-between flex items-stretch flex-row gap-5 px-20 pr-10 pl-10">
    <div class="gap-2.5 flex flex-row">
      <button mat-raised-button color="accent" (click)="addAccount($event)">
        <div class="flex items-center">
          <span>Add</span>
        </div>
      </button>
      <!--  <button mat-raised-button color="accent" (click)="deleteAccount()">
              <div class="flex items-center">
                  <span>Delete Account</span>
              </div>
          </button> -->
    </div>
    <div style="flex: 1;" class="gap-2.5 flex justify-end flex-row">
      <!-- <div class="justify-evenly flex gap-2.5" style="flex: 0 40%;">
                  <h4>Showing</h4>
                  <mat-form-field style="flex-flow: row;flex: 1" class="flex">
                      <mat-select [(ngModel)]="BenefitTargetValue" name="BenefitTargetValue"
                          (selectionChange)="selectionPercent()">
                          <mat-option *ngFor="let percent of BenefitTarget" [value]="percent.benefitTargetCode">
                              {{percent.benefitTargetTName}}</mat-option>
                      </mat-select>
                  </mat-form-field>
              </div> -->
      <div class="justify-evenly flex gap-2.5 items-center flex-row" style="flex: 0 40%;">
        <mat-form-field style="flex: 1" class="my-form-field flex flex-row">
          <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" matInput #input [(ngModel)]="form.search"
            name="search" #search="ngModel" (keyup)="applyFilter($event)">
          <button matSuffix mat-icon-button aria-label="ค้นหา">
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <!-- <button mat-raised-button color="accent" [matMenuTriggerFor]="menu">
              <div class="flex items-center">
                  <span>เงื่อนไขการค้นหา</span>
                  <mat-icon>expand_more</mat-icon>
              </div>
          </button>
          <mat-menu #menu="matMenu" xPosition="before" class="my-menu">
              <div class="pl-2 pr-2 gap-4 flex flex-col" (click)="stopPropagation($event)">
                  <div class="justify-evenly flex gap-2.5 items-center flex-row">
                      <mat-form-field style="flex: 1" class="my-form-field flex flex-row">
                          <mat-label>Account No. | เลขที่บัญชี</mat-label>
                          <input autocomplete="off" type="text" matInput placeholder="เลขที่บัญชี" matInput #input
                              [(ngModel)]="form.bankAccountNo" name="bankAccountNo" #bankAccountNo="ngModel">
                      </mat-form-field>
                      <mat-form-field style="flex: 1" class="my-form-field flex flex-row">
                          <mat-label>Account Name. | ชื่อบัญชี</mat-label>
                          <input autocomplete="off" type="text" matInput placeholder="ชื่อบัญชี" matInput #input
                              [(ngModel)]="form.bankAccountName" name="bankAccountName" #bankAccountName="ngModel">
                      </mat-form-field>
                  </div>
                  <div class="justify-evenly flex gap-2.5 items-center flex-row gap-0">
                      <mat-form-field style="flex: 1" class="my-form-field flex flex-row">
                          <mat-label>Tax No. | เลขที่ใบกำกับ</mat-label>
                          <input autocomplete="off" type="text" matInput placeholder="เลขที่ใบกำกับ" matInput #input
                              [(ngModel)]="form.accTaxNo" name="accTaxNo" #accTaxNo="ngModel">
                      </mat-form-field>
                  </div>
                  <div class="justify-evenly flex gap-2.5 justify-end items-center flex-row gap-0"
                      style="flex: 0 50%;">
                      <mat-form-field style="flex: 1" class="my-form-field flex flex-row">
                          <mat-label>Status | สถานะ</mat-label>
                          <mat-select [(ngModel)]="form.isActive" name="isActive" #isActive="ngModel">
                              <mat-option value="Y">ใช้งาน</mat-option>
                              <mat-option value="N">ไม่ใช้งาน</mat-option>
                          </mat-select>
                      </mat-form-field>
                  </div>
                  <div class="justify-evenly flex gap-2.5 justify-end items-center flex-row gap-0">
                      <button mat-raised-button color="accent" (click)="loadData()"
                          style="flex: 0 50%;">ค้นหา</button>
                  </div>
              </div>
          </mat-menu> -->
    </div>

  </div>
  <!-- </div> -->
  <div class="mat-elevation-z8" style="overflow-x:auto;">
    <table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">
      <!-- Symbol Column -->
      <ng-container matColumnDef="marktypeName">
        <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> ประเภทมาร์ก </th>
        <td mat-cell *matCellDef="let element"> {{element.marktypeName}} </td>
      </ng-container>
      <!-- Position Column -->

      <ng-container matColumnDef="marktypeDetail">
        <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> รายละเอียดมาร์ก </th>
        <td mat-cell *matCellDef="let element"> {{element.marktypeDetail}} </td>
      </ng-container>

      <ng-container matColumnDef="createDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> วันที่สร้างมาร์ก </th>
        <td mat-cell *matCellDef="let element"> {{ datePipe.transform(element.createDate, 'dd-MM-yyyy') }} </td>
      </ng-container>

      <ng-container matColumnDef="updateDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> อัปเดตมาร์กล่าสุด </th>
        <td mat-cell *matCellDef="let element"> {{ element.updateDate ? (datePipe.transform(element.updateDate,
          'dd-MM-yyyy') || '-') : '-' }} </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="text-left"> การจัดการ </th>
        <td mat-cell *matCellDef="let element;let i = index" class="text-left">
          <button mat-icon-button matTooltip="แก้ไข" (click)="editItem(element)" matTooltipPosition="above">
            <mat-icon svgIcon="heroicons_outline:pencil-alt"></mat-icon>
          </button>
          <button mat-icon-button matTooltip="ลบ" (click)="delete(element)" matTooltipPosition="above">
            <mat-icon svgIcon="heroicons_outline:trash"></mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons
      aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>
</div>