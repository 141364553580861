<mat-card>
  <mat-card-header>
    <div class="flex justify-between" style="width: 100%;">
      <div class="text-lg"> รายละเอียดบริษัท</div>
      <div>
        <button class="close-button" [mat-dialog-close]="true">
          <mat-icon class="text-white" [mat-dialog-close]>cancel</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-dialog-content class="mat-typography">
    <div class="pl-22 pr-22 flex flex-row gap-4">
      <div class="flex flex-row gap-2.5 basis-1/2 items-center">
        <div div class="basis-1/4">
          <div class="flex-column" style="margin-top: 24px">
            <mat-label> ชื่อบริษัท(ไทย) </mat-label>
          </div>
          <div class="flex-column" style="color: #9ca3af">
            <mat-label> Thai Name </mat-label>
          </div>
        </div>
        <div div class="basis-3/4">
          <mat-label class="pr-2.5"> : </mat-label>
          <mat-label>
            {{ form.companyNameTH }}
          </mat-label>
        </div>
      </div>
      <div class="flex flex-row gap-2.5 basis-1/2 items-center">
        <div div class="basis-1/4">
          <div class="flex-column" style="margin-top: 24px">
            <mat-label> ชื่อบริษัท(อังกฤษ) </mat-label>
          </div>
          <div class="flex-column" style="color: #9ca3af">
            <mat-label> English Name </mat-label>
          </div>
        </div>
        <div div class="basis-3/4">
          <mat-label class="pr-2.5"> : </mat-label>
          <mat-label>
            {{ form.companyNameEN }}
          </mat-label>
        </div>
      </div>
    </div>
    <div class="pl-22 pr-22 flex flex-row gap-4">
      <div class="flex flex-row gap-2.5 basis-1/2 items-center">
        <div div class="basis-1/4">
          <div class="flex-column" style="margin-top: 24px">
            <mat-label> ชื่อผู้ติดต่อ </mat-label>
          </div>
          <div class="flex-column" style="color: #9ca3af">
            <mat-label> contact </mat-label>
          </div>
        </div>
        <div div class="basis-3/4">
          <mat-label class="pr-2.5"> : </mat-label>
          <mat-label>
            {{ form.contact }}
          </mat-label>
        </div>
      </div>
      <div class="flex flex-row gap-2.5 basis-1/2 items-center">
        <div div class="basis-1/4">
          <div class="flex-column" style="margin-top: 24px">
            <mat-label> สถานะการล็อก </mat-label>
          </div>
          <div class="flex-column" style="color: #9ca3af">
            <mat-label> Lock Status </mat-label>
          </div>
        </div>
        <div div class="basis-3/4">
          <mat-label class="pr-2.5"> : </mat-label>
          <mat-label>
            {{ form.blacklistStatus === "Y" ? "Lock" : "Unlock" }}
          </mat-label>
        </div>
      </div>
    </div>
    <div class="pl-22 pr-22 flex flex-row gap-4">
      <div class="flex flex-row gap-2.5 basis-1/2 items-center">
        <div div class="basis-1/4">
          <div class="flex-column" style="margin-top: 24px">
            <mat-label> E-mail ผู้ติดต่อ </mat-label>
          </div>
          <div class="flex-column" style="color: #9ca3af">
            <mat-label> E-mail Contact </mat-label>
          </div>
        </div>
        <div div class="basis-3/4">
          <mat-label class="pr-2.5"> : </mat-label>
          <mat-label>
            {{ form.email }}
          </mat-label>
        </div>
      </div>
      <div class="flex flex-row gap-2.5 basis-1/2 items-center">
        <div class="basis-1/4">
          <div class="flex-column" style="margin-top: 24px">
            <mat-label> เบอร์โทรศํพท์ผู้ติดต่อ </mat-label>
          </div>
          <div class="flex-column" style="color: #9ca3af">
            <mat-label> Phone </mat-label>
          </div>
        </div>
        <div div class="basis-3/4">
          <mat-label class="pr-2.5"> : </mat-label>
          <mat-label>
            {{ form.phone }}
          </mat-label>
        </div>
      </div>
    </div>
    <div class="pl-22 pr-22 flex flex-row gap-4" style="margin-top:2rem !important;">
      <div class="flex flex-row gap-2.5 basis-full items-center">
        <div style="flex-basis: 12.3%">
          <div class="flex-column">
            <mat-label> ที่อยู่ </mat-label>
          </div>
          <div class="flex-column" style="color: #9ca3af">
            <mat-label> Company Address </mat-label>
          </div>
        </div>
        <div style="flex-basis: 87.7%">
          <mat-label class="pr-2.5"> : </mat-label>
          <mat-form-field style="width:96.5%">
            <mat-select [(ngModel)]="form.selectAddress">
              <mat-option *ngFor="let address of addressList" [value]="address.comAddId">
                {{address.companyAddressTypeName}} - {{ address.companyAddress }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="pl-22 pr-22 flex flex-row gap-4" style="margin-top:2rem !important;">
      <div class="flex flex-row gap-2.5 basis-1/2 items-center">
        <div div class="basis-1/4">
          <div class="flex-column">
            <mat-label> ระดับของบริษัท </mat-label>
          </div>
          <div class="flex-column" style="color: #9ca3af">
            <mat-label> Grade Type </mat-label>
          </div>
        </div>
        <div div class="basis-3/4">
          <mat-label class="pr-2.5"> : </mat-label>
          <mat-form-field class="w-11/12">
            <mat-select [(ngModel)]="form.grade">
              <mat-option *ngFor="let grade of gradeList" [value]="grade.gradeId">
                {{ grade.gradeName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div div class="flex flex-row gap-2.5 basis-1/2 items-center">
        <div div class="basis-1/4">
          <div class="flex-column">
            <mat-label> วันที่ตรวจโรงงาน</mat-label>
          </div>
          <div class="flex-column" style="color: #9ca3af">
            <mat-label> Grade Date </mat-label>
          </div>
        </div>
        <div div class="basis-3/4">
          <mat-label class="pr-2.5"> : </mat-label>
          <mat-form-field class="w-11/12">
            <input matInput [matDatepicker]="pickstart" placeholder="วันที่ให้ระดับ" [(ngModel)]="form.gradeDate"
              [ngModelOptions]="{standalone: true}" [max]="curentDate" readonly (click)="pickstart.open()">
            <mat-datepicker-toggle matIconSuffix [for]="pickstart"></mat-datepicker-toggle>
            <mat-datepicker #pickstart></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="pl-22 pr-22 flex flex-row gap-4" style="margin-top:2rem !important;">
      <div class="flex flex-row gap-2.5 basis-full">
        <div style="flex-basis: 12.3%">
          <div class="flex-column">
            <mat-label> เหตุผลระดับที่ได้รับ <span style="color: red">*</span></mat-label>
          </div>
          <div class="flex-column" style="color: #9ca3af">
            <mat-label> Grade Reason </mat-label>
          </div>
        </div>
        <div style="flex-basis: 87.7%">
          <mat-label class="pr-2.5"> : </mat-label>
          <mat-form-field style="width:96.5%" appearance="fill">
            <textarea matInput rows="3" [(ngModel)]="form.gradeReason" required="true"></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="px-22 pt-5 flex flex-row gap-4 justify-start">
      <mat-slide-toggle [(ngModel)]="blacklist">ระบุสถานะการล็อค</mat-slide-toggle>
    </div>
    <div class="pl-22 pr-22 flex flex-row gap-4 pt-5" *ngIf="blacklist">
      <div class="flex gap-2.5 basis-1/2 items-center" style="flex-direction: column !important;">
        <div class="flex" style="width: 100%">
          <div div class="basis-1/4">
            <div class="flex-column">
              <mat-label> สถานะการล็อก </mat-label>
            </div>
            <div class="flex-column" style="color: #9ca3af">
              <mat-label> Block Status </mat-label>
            </div>
          </div>
          <div div class="basis-3/4">
            <mat-label class="pr-2.5"> : </mat-label>
            <mat-form-field class="w-11/12">
              <mat-select [(ngModel)]="form.lockStatus">
                <mat-option value="Y">Lock</mat-option>
                <mat-option value="N">Unlock</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <ng-container *ngIf="form.lockStatus === 'N'">
          <div class="flex" style="width: 100%">
            <div div class="basis-1/4">
              <div class="flex-column">
                <mat-label> รายการที่จะปลดล็อก </mat-label>
              </div>
              <div class="flex-column" style="color: #9ca3af">
                <mat-label> Unblock Items </mat-label>
              </div>
            </div>
            <div div class="basis-3/4">
              <mat-label class="pr-2.5"> : </mat-label>
              <mat-form-field class="w-11/12">
                <mat-select [(ngModel)]="form.refBlacklistId">
                  <mat-option *ngFor="let reason of reasonList" [value]="reason.logId">
                    {{reason.logReason}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="flex flex-row gap-2.5 basis-1/2">
        <div div class="basis-1/4">
          <div class="flex-column">
            <mat-label>เหตุผลสถานะการล็อก <span style="color: red" *ngIf="form.lockStatus === 'Y'">*</span></mat-label>
          </div>
          <div class="flex-column" style="color: #9ca3af">
            <mat-label> Lock Reason </mat-label>
          </div>
        </div>
        <div div class="basis-3/4">
          <mat-label class="pr-2.5"> : </mat-label>
          <mat-form-field class="w-11/12" style="height: 109.2 px;" appearance="fill">
            <textarea matInput rows="4" [(ngModel)]="form.lockReason" [required]="form.lockStatus === 'Y'"></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
    
    <div class="px-22 pt-5 flex flex-row gap-4 justify-start">
      <section class="px-4 flex flex-col flex-1 py-4 justify-evenly">
        <mat-label>แนบเอกสาร</mat-label>
        <mat-label style="color:red;">อนุญาตเฉพาะไฟล์ .pdf ขนาดไม่เกิน 3 MB</mat-label>
        <section class="flex flex-row gap-4">
          <div class="file-uploader-block" *ngIf="!editItem">
            <dx-file-uploader style="border: none; padding: 0" [allowCanceling]="true" [(value)]="value" #fileUploader
              [multiple]="false" [uploadMode]="'useForm'" accept=".pdf"
              [allowedFileExtensions]="['.pdf']" [maxFileSize]="3145728">
            </dx-file-uploader>
          </div>
        </section>
        <div class="flex flex-row gap-4 mb-3 ml-3">
          <dx-button text="Upload" (onClick)="upload()"> </dx-button>
        </div>
        <div class="flex flex-row self-left">
          <div *ngIf="form.attachments !== undefined && form.attachments !== null && form.attachments !== ''">
            <div class="text-end">
              <mat-icon (click)="Removing()">cancel</mat-icon>
            </div>
            <div style="width: 100px; height: 100px">
              <a href="{{form.attachments}}" target="_blank"><img src="assets/images/logo/pdf-icon-png-pdf.png"></a>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="flex flex-col items-center p10">
      <div class="flex basis-1/2 gap-3">
        <button mat-raised-button style="width: 100px;" color="accent" (click)="save()">
          บันทึก
        </button>
        <button mat-raised-button style="width: 100px;" [mat-dialog-close]>
          ปิด
        </button>
      </div>
    </div>
    <hr style="margin-top: 32px" />
    <mat-card>
      <mat-card-header>
        ประวัติการดำเนินการ (History Action)
      </mat-card-header>
      <mat-card-content>
        <div class="flex-row p-10 justify-start">
          <div class="mat-elevation-z8" style="width: 100% !important">
            <table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">
              <ng-container matColumnDef="user" style="text-align: left">
                <th mat-header-cell *matHeaderCellDef class="justify-start text-left">
                  ผู้บันทึก
                </th>
                <td mat-cell *matCellDef="let element" class="justify-start text-left">
                  {{ element.createByName }}
                </td>
              </ng-container>
              <ng-container matColumnDef="date" style="text-align: left">
                <th mat-header-cell *matHeaderCellDef class="justify-start text-left">
                  วันที่ตรวจโรงงาน
                </th>
                <td mat-cell *matCellDef="let element" class="justify-start text-left">
                  {{ element.verifyFactoryDate | date:'yyyy-MM-dd' }}
                </td>
              </ng-container>
              <ng-container matColumnDef="grade" style="text-align: left">
                <th mat-header-cell *matHeaderCellDef class="justify-start text-left">
                  ระดับของบริษัท
                </th>
                <td mat-cell *matCellDef="let element" class="justify-start">
                  <div class="grade">
                    {{ element.gradeName }}
                    <button mat-icon-button matTooltip="เหตุผลประกอบ" (click)="ReasonGradePopup(element)"
                      matTooltipPosition="above">
                      <mat-icon svgIcon="heroicons_outline:information-circle"></mat-icon>
                    </button>  
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="attachment" style="text-align: left">
                <th mat-header-cell *matHeaderCellDef class="justify-start text-left">
                  รูปภาพแนบ
                </th>
                <td mat-cell *matCellDef="let element" class="justify-start">
                  <div class="grade">
                    <button mat-icon-button matTooltip="รูปภาพแนบ" (click)="openAttachment(element)"
                    matTooltipPosition="above" [disabled]="element.fileAttachment === undefined || element.fileAttachment === null">
                    <mat-icon svgIcon="picture_as_pdf"></mat-icon>
                  </button>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
            <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
              aria-label="Select page of periodic elements" (page)="pageChanged($event)">
            </mat-paginator>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </mat-dialog-content>
</mat-card>