import { Component, Inject, ViewChild } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { AppCOService } from 'app/business/service/app/app-co.service'
import { AppMasterService } from 'app/business/service/app/app-master.service'
import { DxDataGridComponent } from 'devextreme-angular'

@Component({
  selector: 'app-draft-co-popup',
  templateUrl: './draft-co-popup.component.html',
  styleUrls: ['./draft-co-popup.component.scss'],
})
export class DraftCOPopupComponent {
  dataSource: any = []
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent
  data: any = {}
  previewPrint:any ={}
  updatePreviewPrint:any ={}
  request:any ={}
  isPack:string ="N"
  isNS: boolean
  isCheck: boolean
  listClick : boolean
  respone : string
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public apiMasterService: AppMasterService,
    public dialogRef: MatDialogRef<DraftCOPopupComponent>,
    private appCOService: AppCOService,
  ) {
    this.dataSource = data.data
    this.data = data.data
    this.listClick = data.listClick
    this.previewPrint = this.data.previewPrint;
    this.isPack = data.data.isPack;
    this.respone = data.data.linkUrl
    
  }

 

  onAction(e:any){
    if(e.docHid != 0)
    {
      const data = {
        Hid : e.docHid,
        isCoPack : "Y"
      }
      this.appCOService.getPreviewCOPack(data).then((res: any) => {
        if (res === 'error') {
          return
        } else {  
          window.open(res.linkUrl, '_blank')
        }
        })
    }else{
    this.request = this.previewPrint
    this.updatePreviewPrint = this.request.coPack
    this.request.coPack = [e]
    const data = {
      previewCO : this.request,
      isCoPack : "Y"
    }
    this.appCOService.getPreviewCOPack(data).then((res: any) => {
      if (res === 'error') {
        return
      } else {  
        window.open(res.linkUrl, '_blank')
        
      }
      })
      this.request.coPack = this.updatePreviewPrint
    }
  }
  preview() {
    window.open(this.respone, '_blank')
  }
  save() {
    this.dialogRef.close();
  }
  cancel() {
    this.dialogRef.close(false)
  }
  
}
