<div style="display: flex;flex-flow: row;gap: 30px;height: 5vh;justify-content: space-between;align-items: center;color: white;
    background-color: #003f80;font-size: 20px;border-radius: 6px;" class=" pl-5 pr-5"
    (keydown.enter)="$event.preventDefault()">
    <h2 style="text-align: center;">ตัวอย่างเอกสาร</h2>
    <mat-icon style="cursor:pointer; color: white;" mat-dialog-close (click)="cancel()">cancel</mat-icon>
</div>

<mat-dialog-content class="mat-typography" *ngIf="listClick != true">
    <div class="flex flex-col">
        <div class="w-full flex justify-center" *ngIf="isPack!='Y'">
            <button mat-raised-button color="accent" *ngIf="isPack!='Y'" (click)="preview()">
                <div class="flex flex-row items-center gap-3 ">
                    คลิ๊กเพื่อตรวจสอบเอกสาร
                    <!-- <mat-icon>open_in_new</mat-icon> -->
                    <mat-icon svgIcon="heroicons_outline:external-link"></mat-icon>
                </div>
            </button>
        </div>    
        <div class="w-full" *ngIf="isPack =='Y'">            
                <mat-action-list style="max-width: 100%;" *ngIf="isPack =='Y'" >
                    <h4 class="text-center"><b>รายการ Print ใน CO/PACK</b></h4>
                    <mat-list-item style="text-overflow: ellipsis; max-width: 100%;" *ngFor="let item of previewPrint.coPack; let i = index;">
                        <a matListItemTitle href="..."> {{i+1}} . {{previewPrint.packName +"  :  "+ item.invoiceNo }}</a>
                        <button mat-icon-button matTooltip="พิมพ์" (click)="onAction(item)" matListItemMeta>
                              <mat-icon svgIcon="heroicons_outline:printer"></mat-icon>
                          </button>
                    </mat-list-item>
                </mat-action-list>

        </div>
        
        <div class="flex flex-row justify-center">
            <mat-checkbox class="example-margin px-1 pt-3" matInput [(ngModel)]="isCheck">
                ยืนยันเอกสารถูกต้องครบถ้วนสมบูรณ์
            </mat-checkbox>
        </div>
    </div>

    <!-- <div *dxTemplate="let row of 'test'">
                <button mat-icon-button matTooltip="ตรวจสอบ" (click)="preview(row)" matTooltipPosition="above">
                    <mat-icon>
                        find_in_page
                    </mat-icon>
                </button>
              
            </div> -->
            

</mat-dialog-content>

<mat-dialog-actions align="center" *ngIf="listClick != true" >
    <button mat-raised-button color="accent" class="gap-3" mat-dialog-close (click)="cancel()">ยกเลิก</button>
    <button mat-raised-button color="accent" class="gap-3" (click)="save()" [disabled]="!isCheck">ยืนยันการส่งตรวจ</button>


</mat-dialog-actions>


<mat-dialog-content class="mat-typography" *ngIf="listClick == true">
    <div class="flex flex-col">
        <div class="flex flex-row justify-center">
            <button mat-raised-button color="accent" (click)="preview()">
                <div class="flex flex-row items-center gap-3 ">
                    คลิ๊กเพื่อตรวจสอบเอกสาร
                    <!-- <mat-icon>open_in_new</mat-icon> -->
                    <mat-icon svgIcon="heroicons_outline:external-link"></mat-icon>
                </div>
            </button>
        </div>
        
    </div>

</mat-dialog-content>