import { AppMasterService } from "app/business/service/app/app-master.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatSort, Sort } from "@angular/material/sort";
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import { IcoStatus } from "app/business/interface/app/app-co.interface";
import { AppPaymentService } from "app/business/service/app/app-payment.sevice";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import { Router } from "@angular/router";
import { AppUserService } from "app/business/service/app/app-user.service";
import { AppMenuService } from "app/business/service/app/app-menu.service";
import { MatDialog } from "@angular/material/dialog";
import { PullJobModalComponent } from "../pull-job-modal/pull-job-modal.component";
import { AuthService } from "app/core/auth/auth.service";

@Component({
  selector: "app-list-payment-inquiry",
  templateUrl: "./list-payment-inquiry.component.html",
  styleUrls: ["./list-payment-inquiry.component.scss"],
})
export class ListPaymentInquiryComponent implements OnInit {
  dataSource = new MatTableDataSource<any>();

  searchString: string;
  statusList: any = [];
  searchForm: any = {
    certificateNo: "",
    endDate: null,
    startDate: null,
    isRqStatus: "0",
    receiptNo: "",
    requestNo: "",
    invoiceReference: "",
  };
  paymentTypeList: any = [
    {
      name: "เงินสด / Bill Payment",
      code: 1,
    },
    {
      name: "E-payment",
      code: 2,
    },
  ];
  statusOptions: IcoStatus[] = [
    {
      text: "แสดงทั้งหมด",
      code: "0",
    },
    {
      text: "ยังไม่ส่งตรวจ",
      code: "1",
    },
    {
      text: "กำลังตรวจสอบ",
      code: "2",
    },
    {
      text: "ผ่านอนุมัติ",
      code: "3",
    },
  ];

  displayedColumns: string[] = [
    "refNo",
    "paymentType",
    "bank",
    "reqDate",
    "createDate",
    "amount",
    "status",
    "action",
  ];
  url: any;
  buttonList: any[] = [];
  _liveAnnouncer: any;
  isBlacklisted: boolean = false;
  userProfile: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private appPaymentService: AppPaymentService,
    public alert: AlertMessageService,
    public appMasterService: AppMasterService,
    public confirmService: ConfirmDialogService,
    public router: Router,
    public appUserService: AppUserService,
    public appMenuService: AppMenuService,
    public _authService: AuthService,
    private dialog: MatDialog
  ) {
    this.userProfile = JSON.parse(this._authService.loginUser);
  }

  async getRouter(): Promise<void> {
    //
    this.url = this.router.url;

    const formData = {
      url: this.url,
      userGroupCode: this.userProfile.userGroupCode,
      userRoleCode: this.userProfile.userRoleCode,
      userCode: this.userProfile.userCode,
    };
    this.buttonList = await this.appMenuService.getButtonPermissionPerPages(
      formData
    );
    //
  }

  async ngOnInit() {
    await this.getRouter();
    this.isBlacklisted = JSON.parse(
      this.appUserService.getSessionUserProfile()
    ).isBlacklisted;

    this.userProfile.userName = this.userProfile.name;
    this.loadData();
  }

  loadData() {
    const formData = {
      startDate: this.searchForm.startDate,
      endDate: this.searchForm.endDate,
      refNo: this.searchForm.refNo,
      paymentType: this.searchForm.paymentType,
      status: this.searchForm.status,
    };
    this.appPaymentService.getPaymentList(formData).then((res) => {
      this.dataSource = new MatTableDataSource(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });

    this.appPaymentService.getStatusPaymentList().then((r) => {
      this.statusList = r;
      console.log("getStatusPaymentList", r);
    });
    // this.appMasterService.getListMstStatus().then((res) => {
    //   this.dataSource = res;
    // });
  }

  applyFilter($event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.searchForm.refNo = filterValue;
    console.log("filterValue" , filterValue);
    if (filterValue == null || filterValue == "") {
      this.clearOption();
    }
  }

  setupFilter(column: string) {}

  stopPropagation(event) {
    event.stopPropagation();
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce("Sorting cleared");
    }
  }

  clearOption() {
    this.searchForm = {};
    this.loadData();
  }

  updateStatusPayment(e, status) {
    var actionReq = {
      id: e.hid,
      action: "DELETE",
      refTable: "DOCUMENT_PAYMENT_REQUEST",
    };

    this.appMasterService.getActionStatus(actionReq).then((res) => {
      if (res === "error") {
        return;
      } else {
        var data = res;

        if (data.allowAction === "Y") {
          this.confirmService
            .open({
              header: "ยืนยันการทำรายการ",
              content: "ต้องการลบใบชำระเงิน",
            })
            .then((res) => {
              if (res == true) {
                this.appPaymentService
                  .updateStatusPayment({
                    pay_hid: e.hid,
                    stage: status,
                    description: null,
                    createBy: this.userProfile.uId,
                  })
                  .then((res) => {
                    this.alert
                      .open({
                        title: "ลบใบชำระเงิน",
                        message: "ลบใบชำระเงินสำเร็จ",
                      })
                      .then((res) => {
                        this.loadData();
                      });
                  });
              }
            });
        } else {
          this.alert
            .open({
              message:
                "ไม่สามารถลบรายการชำระเงินได้ เนื่องจากสถานะการชำระเงินถูกเปลี่ยนในระบบแล้ว",
              title: "",
            })
            .then((res) => {
              this.loadData();
              return;
            });
        }
      }
    });
  }

  pullPaymentFromCo(e) {
    var actionReq = {
      id: e.hid,
      action: "PULL",
      refTable: "DOCUMENT_PAYMENT_REQUEST",
    };

    this.appMasterService.getActionStatus(actionReq).then((res) => {
      if (res === "error") {
        return;
      } else {
        var data = res;

        if (data.allowAction === "Y") {
          const dialogRef = this.dialog.open(PullJobModalComponent, {
            disableClose: false,
            width: "45vw",
            data: {
              ref_no: e.refNo,
              description: "ดึงงานกลับ",
            },
            maxWidth: "none",
          });
          dialogRef.afterClosed().subscribe((res) => {
            if (res.remark != undefined) {
              this.appPaymentService
                .pullPaymentFromCo({
                  ref_no: e.refNo,
                  description: "ดึงงานกลับ",
                  reason: res.remark,
                })
                .then((res) => {
                  this.alert
                    .open({
                      title: "ดึงงานกลับเรียบร้อย",
                      message: res.description,
                    })
                    .then((s) => {
                      this.loadData();
                    });
                });
            }
          });
        } else {
          this.alert
            .open({
              message:
                "ไม่สามารถดึงรายการชำระเงินกลับได้ เนื่องจากสถานะการชำระเงินถูกเปลี่ยนในระบบแล้ว",
              title: "",
            })
            .then((res) => {
              this.loadData();
              return;
            });
        }
      }
    });
  }

  exportPDFBillPayment(id) {
    // var actionReq = {
    //   id: id,
    //   action: "PRINT",
    //   refTable: "DOCUMENT_PAYMENT_REQUEST",
    // };

    // this.appMasterService.getActionStatus(actionReq).then((res) => {
    //   if (res === "error") {
    //     return;
    //   } else {
    //     var data = res;
    //     console.log(data);
    //     if (data.allowAction === "Y") {
    this.appPaymentService.exportBillPayment({ pay_hid: id }).then((res) => {
      if (res != null) {
        window.open(res.linkUrl, "_blank");
      }
    });
    //     } else {
    //       this.alert
    //         .open({
    //           message:
    //             "ไม่สามารถพิมพ์เอกสารได้ เนื่องจากสถานะการชำระเงินถูกเปลี่ยนในระบบแล้ว",
    //           title: "",
    //         })
    //         .then((res) => {
    //           this.loadData();
    //           return;
    //         });
    //     }
    //   }
    // });
  }

  createPayment() {
    this.router.navigateByUrl("/page/payment/create-payment");
  }

  editPayment(e) {
    var actionReq = {
      id: e.hid,
      action: "EDIT",
      refTable: "DOCUMENT_PAYMENT_REQUEST",
    };

    this.appMasterService.getActionStatus(actionReq).then((res) => {
      if (res === "error") {
        return;
      } else {
        var data = res;

        if (data.allowAction === "Y") {
          this.router.navigate(["/page/payment/create-payment-inquiry"], {
            queryParams: {
              pay_hid: e.hid,
              disabled: false,
            },
          });
        } else {
          this.alert
            .open({
              message:
                "ไม่สามารถแก้ไขรายการชำระเงินได้ เนื่องจากสถานะการชำระเงินถูกเปลี่ยนในระบบแล้ว",
              title: "",
            })
            .then((res) => {
              this.loadData();
              return;
            });
        }
      }
    });
  }

  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find((e) => e.buttonCode == buttonCode);

    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }
}
