import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { FuseNavigationService } from "@fuse/components/navigation";
import { AppMenuService } from "app/business/service/app/app-menu.service";
import { AppUserService } from "app/business/service/app/app-user.service";
import { AuthService } from "app/core/auth/auth.service";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent {
  @Input() headname: string;
  menuForm: any = [];
  userProfile: any = {}
  isDisable:boolean;
  constructor(
    private appMenuService: AppMenuService,
    private _fuseNavigationService: FuseNavigationService,
    private router: Router,
    private appUserService:AppUserService,
    private _authService:AuthService,
  ) {
    this.appMenuService.isMenuDisable.subscribe(res => {
      this.isDisable = res;
    });

    this.appMenuService.userProfile.subscribe(res => {
      // console.log('this.appMenuService.userProfile.subscribe(res => {',res);
      //debugger
      this.userProfile = res;
    });    
    
    this.appMenuService.menuPermissionList.subscribe(res=>{
      //debugger;
      console.log("this.appMenuService.menuList.subscribe(res=>{",res);
      
      this.menuForm =this.appMenuService.setNavigation(res);

    });
  }
  async ngOnInit(): Promise<void> {
    const menu = await this.appMenuService.getMenuPermission();
    const navigation = this.appMenuService.setNavigation(menu);
    //debugger;
    // this.userProfile = JSON.parse(this.appUserService.getSessionUserProfile());

    // this.appMenuService.isMenuDisable.subscribe(res => {
    //   this.userProfile.isDisable = res;
    // });

    this.appMenuService.setSessionMenu(navigation);
    if (
      this._authService.userMenu =="" || 
      this._authService.userMenu == null ||
      this._authService.userMenu == undefined
    ) {
      this.appMenuService.clearSessionMenu();
      this.router.navigate(["/sign-in"]);
    } 

    // this.menuForm = JSON.parse(this._authService.userMenu);
    // 
  }
  stopPropagation(event) {
    event.stopPropagation();
  }
}
