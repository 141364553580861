<div>
  <div >
      <mat-card>
          <mat-card-content fxLayout="column" fxLayoutGap="10px">
              <mat-tab-group  mat-stretch-tabs>
                  <mat-tab label="ผู้ใช้งานระบบ">
                      <div class="flexEnd">
                            <div *ngIf="checkButton('BT001')">
                                <button matTooltip="เพิ่ม User"
                                    matTooltipPosition="above" mat-raised-button color="accent" (click)="addUser()">
                                    <mat-icon>add</mat-icon>เพิ่ม User
                                </button>
                            </div>
                      </div>
                      <app-user-filter-form></app-user-filter-form>
                  </mat-tab>
                  <mat-tab label="จัดการข้อมูลสิทธิ์ผู้ใช้">
                      <div class="flexEnd">
                            <div *ngIf="checkButton('BT002')">
                                <button  matTooltip="เพิ่มสิทธิ์ผู้ใช้"
                                    matTooltipPosition="above" mat-raised-button color="accent" (click)="addUserRole()">
                                    <mat-icon>add</mat-icon>เพิ่มสิทธิ์ผู้ใช้
                                </button>
                            </div>
                      </div>
                      <app-user-role-filter-form (filter)="dataFormRoleFilterEmit($event)">
                      </app-user-role-filter-form>
                      <app-user-role-list [filter]="userRoleFilter"></app-user-role-list>
                  </mat-tab>
                  <mat-tab label="จัดการข้อมูลกลุ่มผู้ใช้" *ngIf="checkAccessGroupTab(userToken?.gId)">
                    <div class="flexEnd">
                        <div *ngIf="checkButton('BT003')">
                            <button  matTooltip="เพิ่มกลุ่มผู้ใช้"
                                matTooltipPosition="above" mat-raised-button color="accent" (click)="addUserGroup()">
                                <mat-icon>add</mat-icon>เพิ่มกลุ่มผู้ใช้
                            </button>
                        </div>
                    </div>
                    <app-user-group-filter-form (filter)="dataFormGroupFilterEmit($event)">
                    </app-user-group-filter-form>
                    <app-user-group-list [filter]="userGroupFilter"></app-user-group-list>
                </mat-tab>
                <mat-tab label="สิทธิ์การใช้เมนู" *ngIf="checkAccessTab(userToken?.gId)">
                    <app-menu-filter-permission (menuFormFilterEmit)="dataMenuPermissionFilterEmit($event)">
                    </app-menu-filter-permission>
                    <div>
                        <app-menu-permission [dataInput]="menuPermissionFilter"></app-menu-permission>
                    </div>
                </mat-tab>
                <mat-tab label="สิทธิ์การใช้ปุ่ม" *ngIf="checkAccessTab(userToken?.gId)">
                    <app-button-filter-permission (buttonFormFilterEmit)="dataButtonPermissionFilterEmit($event)">
                    </app-button-filter-permission>
                    <div>
                        <app-button-permission [dataInput]="buttonPermissionFilter"></app-button-permission>
                    </div>
                </mat-tab>
              </mat-tab-group>
          </mat-card-content>
      </mat-card>
  </div>

</div>