<div style="gap: 30px;padding-top: 30px;" class="flex flex-col h-full">
  <div class="menu justify-between flex items-stretch flex-row gap-5 px-20 pr-10 pl-10">
    <div class="gap-2.5 flex flex-row">
      <div *ngIf="checkButton('BT083')">
        <button  mat-raised-button color="accent" (click)="addItem()">
          <div class="flex items-center">
            <span>Add</span>
          </div>
        </button>
      </div>
    </div>

    <div style="flex: 1;" class="gap-2.5 flex justify-end flex-row">
      <div class="justify-evenly flex gap-2.5 items-center flex-row" style="flex: 0 50%;">
        <mat-form-field style="flex: 0 40%" class="my-form-field flex flex-row">
          <mat-select required placeholder="สินค้าควบคุม" name="type" (selectionChange)="onRestrictedChange($event)">
            <mat-option *ngFor="let restricted of restrictedList" [value]="restricted.value">
              {{restricted.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field style="flex: 1" class="my-form-field flex flex-row">
          <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" matInput #input [(ngModel)]="form.search"
            name="search" #search="ngModel" (keyup)="applyFilter($event.target.value)">
          <button matSuffix mat-icon-button aria-label="ค้นหา">
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>

  </div>
  <!-- </div> -->
  <div class="mat-elevation-z8 ">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <table mat-table matSort style="width: 100%;" [dataSource]="dataSource"
      (matSortChange)="announceSortChange($event)">
      <ng-container matColumnDef="itemCode">
        <th mat-header-cell *matHeaderCellDef class="text-left"> รหัสสินค้า </th>
        <td mat-cell *matCellDef="let element" class="text-left"> {{element.itemCode}} </td>
      </ng-container>

      <ng-container matColumnDef="itemDescription">
        <th mat-header-cell *matHeaderCellDef> รายละเอียดสินค้า </th>
        <td mat-cell *matCellDef="let element"> {{element.itemDescription}} </td>
      </ng-container>

      <ng-container matColumnDef="isRestricted">
        <th mat-header-cell *matHeaderCellDef class="text-left"> สินค้าควบคุม </th>
        <td mat-cell *matCellDef="let element" class="text-left">
          <div *ngIf="element.isRestricted === 'Y'">
            <mat-icon class="check-restricted">check</mat-icon>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="text-left"> การจัดการ </th>
        <td mat-cell *matCellDef="let element;let i = index" class="text-left">
          <div class="flex flex-row" style="justify-content: start;">
            <div *ngIf="checkButton('BT084')">
              <button mat-icon-button matTooltip="รายละเอียด" (click)="editItem(element)" matTooltipPosition="above">
                <mat-icon svgIcon="heroicons_outline:pencil-alt"></mat-icon>
              </button>
            </div>
            <div *ngIf="checkButton('BT085')">
              <button mat-icon-button matTooltip="ลบ" (click)="delete(element)" matTooltipPosition="above">
                <mat-icon svgIcon="heroicons_outline:trash"></mat-icon>
              </button>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]" (page)="_page($event)" showFirstLastButtons
      aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>

</div>