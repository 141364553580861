import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppMenuService } from 'app/business/service/app/app-menu.service';
import { AppUserService } from 'app/business/service/app/app-user.service';
import * as moment from 'moment';
import { UserModalComponent } from '../user-modal/user-modal.component';
import { MatPaginator } from '@angular/material/paginator';
import { DxDataGridComponent } from 'devextreme-angular';
import { MatTableDataSource } from '@angular/material/table';
import CustomStore from 'devextreme/data/custom_store';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
  dataSource = new MatTableDataSource<any>();
  userListDataSource: any[];
  tempData: { data: any; totalCount: any; };
  dataSourceList: any;
  @Input() filter: any;
  url;
  buttonList: any;
  lookupStatusCode: any[];
  userToken:any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;


  constructor(
    private dialog: MatDialog,
    private appUserService: AppUserService,
    private appMenuService: AppMenuService,
    private router: Router,
    private _authService:AuthService,
  ) {
    this.userListDataSource = new Array();
  }

  async ngOnInit() {
    this.userToken = JSON.parse(
      this._authService.loginUser
    );

    await this.getRouter();
    //this.setSubcribe();
    this.getSaleOrderList();
  }

  async getRouter(): Promise<void>{
    
    this.url = this.router.url;

    // const profile = JSON.parse(this.appUserService.getSessionUserProfile());
    const formData = {
      url: this.url,
      userGroupCode: this.userToken.userGroupCode,
      userRoleCode: this.userToken.userRoleCode,
      userCode: this.userToken.userCode
    };
    this.buttonList = await this.appMenuService.getButtonPermissionPerPages(formData);
  }
  getSaleOrderList(): void {
    //debugger
    this.dataSourceList = new CustomStore({
      useDefaultSearch: true,
      load: async (loadOptions) => {
        
        if (loadOptions.take > 0) {
          this.filter.cpId = parseInt(this.userToken.cpId);
          this.dataSource.paginator = this.paginator;
          this.filter.pagination = {
            skip: loadOptions.skip,
            take: loadOptions.take
          };
          const result: any = await this.appUserService.getUserList(this.filter);
          var resDet = result.dataDetail;
          if (resDet || resDet.length !== 0) {
            resDet.forEach(result => {
              result.createDate = result.createDate ? moment(result.createDate).format('DD/MM/YYYY') : '';
              result.updateDate = result.updateDate ? moment(result.updateDate).format('DD/MM/YYYY') : '';
              result.status = result.isActive === 'Y' ? 'ใช้งาน' : 'ยกเลิก';
            });
          }
          this.tempData = {
            data: resDet,
            totalCount: result.totalResults
          };
          return this.tempData;
        }
        else {
          return this.tempData;
        }
      }
    });
  }

  getDataUserList(): void {
    // this.filter.cpId = parseInt(JSON.parse(this.appUserService.getSessionUserProfile()).cpId);
    // this.dataSource.paginator = this.paginator;
    // this.filter.pagination = {
    //   Skip:
    //     (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
    //     (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
    //   Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
    // }
    // this.appUserService.getUserList(this.filter).then(res => {
    //   this.userListDataSource = res;
    // });
  }

  setSubcribe(): void {
    this.lookupStatusCode = [
      { code: "Y", name: "ใช้งาน" },
      { code: "N", name: "ยกเลิก" },
    ];

    this.appUserService.userList.subscribe(res => {
      if (res && res.length > 0) {
        this.userListDataSource = res;
        this.userListDataSource.forEach((item, i) => {
          item.createDate = item.createDate ? moment(item.createDate).format('DD/MM/YYYY') : '';
          item.updateDate = item.updateDate ? moment(item.updateDate).format('DD/MM/YYYY') : '';
        });
      } else {
        this.userListDataSource = new Array();
      }
    });
  }

  editUser(row: any): void {
    
    const userModalRef = this.dialog.open(UserModalComponent, {
      disableClose: true,
      width: '60vw',
      data: row
    });

    userModalRef.afterClosed().subscribe(res => {
      this.getDataUserList();
    });
  }
  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find(e => e.buttonCode == buttonCode);

    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }

}
