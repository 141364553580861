import { Component, LOCALE_ID, OnInit, ViewEncapsulation } from '@angular/core'
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import {
  MAT_DATE_LOCALE,
  DateAdapter,
  MAT_DATE_FORMATS,
} from '@angular/material/core'
import { MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer } from "@angular/platform-browser";
import { NavigationEnd, Route, Router } from '@angular/router';
import moment, { Moment } from 'moment'
import { AppUserService } from './business/service/app/app-user.service';
import { AuthService } from './core/auth/auth.service';
// import * as $ from "jquery";

moment.locale('th')
moment.fn.toJSON = function (): any {
  return this.format()
}

export class MyDateAdapter extends MomentDateAdapter {
  format(date: Moment, displayFormat: string): string {
    return `${date.format('DD')}/${date
      .locale('en-US')
      .format('MM')}/${date.year()} `
  }
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    { provide: LOCALE_ID, useValue: 'en-US' },
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: ['YYYY-MM-DD'],
        },
        display: {
          dateInput: 'YYYY-MM-DD',
          monthYearLabel: 'MMMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit   {
  /**
   * Constructor
   */
  ipAddress: any;
  keyTab:string;
  currentTabId: string;
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private _authService:AuthService,
    private router: Router,
    private appUserService:AppUserService
  ) {
    this.matIconRegistry.addSvgIcon(
      'password',
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/password.svg")
    );

    this.matIconRegistry.addSvgIcon(
      'doc-recall',
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/document-recall.svg")
    );

    this.matIconRegistry.addSvgIcon(
      'eye-open',
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/eye-open.svg")
    );

    this.matIconRegistry.addSvgIcon(
      'eye-close',
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/eye-close.svg")
    );

    this.matIconRegistry.addSvgIcon(
      'file-stack',
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/file-stack.svg")
    );

    // this.currentTabId = this.tabIdentifierService.getUniqueId();
  }
  ngOnInit(): void {
    // this.currentTabId = this.tabIdentifierService.getUniqueId();
    localStorage.setItem(this.currentTabId ,"");
    this.router.events.subscribe(async (val) => {
      if (val instanceof NavigationEnd) {
        //
        //debugger;

        const rrToken = localStorage.getItem("token");
        if (rrToken && rrToken !== "") {
          this.activityLog(this.ipAddress);
          }

        }
    });
   

}
async activityLog(e): Promise<void> { 
  const urlLink = this.router.url

  if (urlLink !== '/sign-in') {
    var currentDate = new Date();
    let urlTree = this.router.parseUrl(this.router.url);
    urlTree.queryParams = {};
    var url = urlTree.toString();
    const profile = JSON.parse(this._authService.loginUser);
    let userActivityLog: any = {};
    userActivityLog = {
      user: profile?.userName,
      uId: parseInt(profile?.uId),//profile?.uId,
      url: url,
      menuCode: null,
      date: currentDate,
      browser: null,
      activityType: "P",
      title: null,
      ipAddress: "",
    }; 
    this.appUserService.saveUserActivityLog(userActivityLog);  
  }
}
}

