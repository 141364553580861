<form name="userRoleForm" [formGroup]="userGroupForm" novalidate class="formFlex">
    <div class="pl-22 pr-22 flex flex-col gap-4">
        <ng-container *ngIf="userGroupForm.get('userGroupCode').value !== ''">
            <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
                <div class="mat-popup-header flex flex-col">
                    <h4>รหัสกลุ่มผู้ใช้</h4>
                    <h4>Group Code</h4>
                </div>
                <div class="mat-popup flex flex-row justify-between items-center" style="width:75%">
                    <div>:</div>
                    <mat-form-field style="flex: 0 95%;" class="flex">
                        <input autocomplete="off" matInput formControlName="userGroupCode" value="" maxlength="10"
                            readonly>
                    </mat-form-field>
                </div>
            </div>
        </ng-container>

        <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
            <div class="mat-popup-header flex flex-col">
                <h4>ชื่อกลุ่มผู้ใช้ <span style="color:red;">*</span></h4>
                <h4>Group Name</h4>
            </div>
            <div class="mat-popup flex flex-row justify-between items-center" style="width:75%">
                <div>:</div>
                <mat-form-field style="flex: 0 95%;" class="flex">
                    <input autocomplete="off" matInput formControlName="groupName" value="" required maxlength="100">
                    <mat-error *ngIf="userGroupForm.get('groupName').hasError('required')">
                        กรุณากรอกชื่อกลุ่มผู้ใช้
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="flex-row flex justify-between gap-2.5">
            <div class="mat-popup-header flex flex-col">
                <h4>ใช้งาน</h4>
                <h4>Active</h4>
            </div>
            <div class="mat-popup flex flex-row items-center" style="width:75%">
                <div>:</div>
                <mat-radio-group formControlName="isActive" required style="margin-left:20px;">
                    <mat-radio-button class="mr-12" [value]="'Y'">ใช้งาน</mat-radio-button>
                    <mat-radio-button class="mr-12" [value]="'N'">ยกเลิก</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="userGroupForm.get('isActive').hasError('required')">
                    กรุณาระบุสถานะ
                </mat-error>
            </div>
        </div>
    </div>
</form>