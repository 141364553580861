import { Injectable } from "@angular/core";
import { IAppGetShipperRequest } from "app/business/interface/app/app-config-relationship";
import { Observable } from "rxjs";
import { ApiService } from '@bundle/service/api.service';

@Injectable({
    providedIn: 'root'
  })
  export class ApiConfigRelationshipService {
  
    constructor(private apiService:ApiService) { 

    } 
    getListRelationship(reqData: any): Observable<any[]> {
      return this.apiService.post("/api/getListRelationship", reqData);
    }  

    getShipperRelationship(reqData: IAppGetShipperRequest): Observable<any[]> {
        return this.apiService.post("/api/getShipper", reqData);
    }

    saveRelationship(reqData: any): Observable<any[]> {
        return this.apiService.post("/api/saveRelationship", reqData);
    }

    deleeteRelationship(reqData: any): Observable<any[]> {
      return this.apiService.post("/api/deleteRelationship", reqData);
  }
  }