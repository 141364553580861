<mat-card>
    <mat-card-header>
        <div class="flex justify-between" style="width: 100%;">
            <div class="text-lg"> Bank Account </div>
            <div>
                <button class="close-button" [mat-dialog-close]="true">
                    <mat-icon class="text-white" [mat-dialog-close]>cancel</mat-icon>
                </button>
            </div>
        </div>
    </mat-card-header>
    <mat-dialog-content>
        <div class="pl-22 pr-22 flex flex-col gap-4">
            <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
                <div class="mat-popup-header flex flex-col">
                    <h4>เลขประจำตัวผู้เสียภาษี <span style="color:red;">*</span></h4>
                    <h4>Registration No</h4>
                </div>
                <div class="mat-popup flex flex-row justify-between items-center" style="width:80%">
                    <div>:</div>
                    <mat-form-field style="flex: 0 95%;" class="flex">
                        <input required autocomplete="off" type="text" maxlength="13" matInput
                            placeholder="เลขประจำตัวผู้เสียภาษี" matInput #input [(ngModel)]="form.accTaxNo"
                            name="accTaxNo" #accTaxNo="ngModel" onkeyup="this.value=this.value.replace(/[^0-9]/g,'');">
                    </mat-form-field>
                </div>
            </div>
            <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
                <div class="mat-popup-header flex flex-col">
                    <h4>ธนาคาร <span style="color:red;">*</span></h4>
                    <h4>Bank</h4>
                </div>
                <div class="mat-popup flex flex-row justify-between items-center" style="width:80%">
                    <div>:</div>
                    <mat-form-field style="flex: 0 95%;" class="flex">
                        <mat-select [(ngModel)]="form.bankCode" name="bankCode" #bankCode="ngModel" required>
                            <mat-option [value]="''">กรุณาเลือก</mat-option>
                            <mat-option *ngFor="let bank of bankList" [value]="bank.bankCode">
                                {{bank.bankCode}} : {{bank.bankName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
                <div class="mat-popup-header flex flex-col">
                    <h4>เลขที่บัญชี <span style="color:red;">*</span></h4>
                    <h4>Account No</h4>
                </div>
                <div class="mat-popup flex flex-row justify-between items-center" style="width:80%">
                    <div>:</div>
                    <mat-form-field style="flex: 0 95%;" class="flex">
                        <input required autocomplete="off" type="text" maxlength="10" matInput placeholder="เลขที่บัญชี"
                            matInput #input [(ngModel)]="form.bankAccountNo"
                            onkeyup="this.value=this.value.replace(/[^0-9]/g,'');" name="bankAccountNo"
                            #bankAccountNo="ngModel">
                    </mat-form-field>
                </div>
            </div>
            <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
                <div class="mat-popup-header flex flex-col">
                    <h4>ชื่อบัญชี <span style="color:red;">*</span></h4>
                    <h4>Account Name</h4>
                </div>
                <div class="mat-popup flex flex-row justify-between items-center" style="width:80%">
                    <div>:</div>
                    <mat-form-field style="flex: 0 95%;" class="flex">
                        <input required autocomplete="off" type="text" matInput placeholder="ชื่อบัญชี" matInput #input
                            [(ngModel)]="form.bankAccountName" name="bankAccountName" #bankAccountName="ngModel" maxlength="35">
                    </mat-form-field>
                </div>
            </div>
            <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
                <div class="mat-popup-header flex flex-col">
                    <h4>รหัสสาขา <span style="color:red;">*</span></h4>
                    <h4>Branch Code</h4>
                </div>
                <div class="mat-popup flex flex-row justify-between items-center" style="width:80%">
                    <div>:</div>
                    <mat-form-field style="flex: 0 95%;" class="flex">
                        <input required autocomplete="off" type="text" matInput placeholder="รหัสสาขา" matInput #input
                            [(ngModel)]="form.bankBranch" name="bankBranch" #bankBranch="ngModel" maxlength="3"
                            onkeyup="this.value=this.value.replace(/[^0-9]/g,'');">
                    </mat-form-field>
                </div>
            </div>
            <ng-container *ngFor="let buttonList of buttonList">
                <ng-container *ngIf="buttonList.buttonCode=='BT068'">
                    <div class="flex-row flex justify-between gap-2.5">
                        <div class="mat-popup-header flex flex-col">
                            <h4>ใช้งาน</h4>
                            <h4>Active</h4>
                        </div>
                        <div class="mat-popup flex flex-row justify-between items-center" style="width:80%">
                            <div>:</div>
                            <mat-slide-toggle [checked]="form.isActive === 'Y' ? true : false"
                                (change)="onChange($event)" style="flex: 0 95%;" class="flex">
                                Active</mat-slide-toggle>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <div class="flex-row flex justify-center gap-2.5" style="gap: 10px;">
                <button mat-raised-button color="accent" (click)="save()">บันทึก</button>
            </div>
        </div>
    </mat-dialog-content>
</mat-card>