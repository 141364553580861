import { LiveAnnouncer } from "@angular/cdk/a11y";
import { SelectionModel } from "@angular/cdk/collections";
import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl, FormGroup, FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { IcoStatus } from "app/business/interface/app/app-co.interface";
import { ApiVerifyService } from "app/business/service/api/api-verify.service";
import { AppPrintService } from "app/business/service/app/app-print.service";
import { AppVerifyService } from "app/business/service/app/app-verify.service";
import { CreateCfInquiryPopupComponent } from "../create-cf-inquiry/create-cf-inquiry-popup/create-cf-inquiry-popup.component";
import { ListReprintCreatePopupComponent } from "../list-re-print-inquiry/list-reprint-create-popup/list-reprint-create-popup.component";
import { Router } from "@angular/router";
import { AppMenuService } from "app/business/service/app/app-menu.service";
import { AppUserService } from "app/business/service/app/app-user.service";
import { debounceTime, map, startWith, switchMap, takeUntil } from "rxjs/operators";
import { HttpLoadingService } from '@bundle/service/http-loading.service';
import { of, Subject } from "rxjs";
import { companySearch } from 'app/business/interface/app/app-company.interace'
import { AppCompanyService } from 'app/business/service/app/app-company.service'
import { AuthService } from "app/core/auth/auth.service";


@Component({
  selector: "app-verify-reprint-inquiry",
  templateUrl: "./verify-reprint-inquiry.component.html",
  styleUrls: ["./verify-reprint-inquiry.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class VerifyReprintInquiryComponent implements OnInit, AfterViewInit {
  searchForm: any = {
    certificateNo: "",
    endDate: null,
    startDate: null,
    isRqStatus: null,
    receiptNo: "",
    requestNo: "",
    invoiceReference: "",
  };
  color = "#009efa72";
  displayedColumns: string[] = [
    "No",
    "requestByName",
    "declaratorName",
    "rqNo",
    "docType",
    "refNo",
    "thtNo",
    "requestDate",
    "verifyName",
    "rqStatusName",
    "action",
  ];
  searchString: string;
  apiRequest: any = {};
  apiResponse: any[];
  statusControl = new FormControl("");
  exporterControl = new FormControl("");
  exporterOptions: string[];
  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);
  userProfile: any = {}
  request: any = {}
  count: any = 0
  status: number = 2;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  form: any = {};
  formData: any = {};
  statusId: number = 16;
  url: any;
  buttonList: any[] = [];
  searchCompanyForm: FormGroup;
  valueSelect: boolean = false;
  isLoading: boolean = false;
  filteredOptions$: any = [];
  constructor(
    private _formBuilder: FormBuilder,
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private appPrintService: AppPrintService,
    private appVerrifyService: AppVerifyService,
    private router: Router,
    private appUserService: AppUserService,
    private appMenuService: AppMenuService,
    private httpLoadingService: HttpLoadingService,
    private appCompanyService: AppCompanyService,
    private _authService:AuthService,
  ) { }

  async getRouter(): Promise<void> {
    
    this.url = this.router.url;

    const formData = {
      url: this.url,
      userGroupCode: this.userProfile.userGroupCode,
      userRoleCode: this.userProfile.userRoleCode,
      userCode: this.userProfile.userCode
    };
    this.buttonList = await this.appMenuService.getButtonPermissionPerPages(formData);
    
  }

  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find(e => e.buttonCode == buttonCode);

    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }

  async ngOnInit() {

    this.userProfile = JSON.parse(
      this._authService.loginUser
    )

    this.searchCompanyForm = this._formBuilder.group({
      companySearch: ['']
    });
    this.valueChange();

    await this.getRouter();

    this.formData = {};
    //this.loadData(this.apiRequest);
    this.statusChange()
  }
  ngAfterViewInit() {
    this.paginator.pageIndex = 0
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  async loadData(request: any) {    
    if (this.paginator != undefined && this.paginator != null) {
      this.paginator.pageIndex = 0
    }
    
    if (request == null) {
      request = {
        pagination: {
          Skip: 0,
          Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
        }
      };
    }
    await this.appVerrifyService.getVerifyReprintList(request).then((res: any) => {
      if (res === "error") {
        this.dataSource.data = []
        this.count = 0
        return;
      } else {
        this.dataSource.data = res.getListVerifyRePrint;
        this.count = res.totalCount
      }
    });
    this.dataSource.paginator.length = this.count
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce("Sorting cleared");
    }
  }
  stopPropagation(event) {
    event.stopPropagation();
  }

  onRefresh() {
    this.apiRequest.UID = this.userProfile.uId;
    this.apiRequest.endDatetime = this.searchForm.endDate;
    this.apiRequest.statusId = this.statusId;
    this.apiRequest.startDatetime = this.searchForm.startDate;
    this.apiRequest.requestNo = this.searchForm.requestNo;
    this.apiRequest.thtNo = this.searchForm.certificateNo;
    this.apiRequest.companyCode = this.searchForm.companyCode;
    this.apiRequest.companyName = this.searchForm.companyName;
    this.apiRequest.pagination = {
      Skip:
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
      Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
    };
    this.dataSource.paginator = this.paginator
    this.loadData(this.apiRequest)
  }

  saveSearch() {
    this.apiRequest.UID = this.userProfile.uId;
    this.apiRequest.endDatetime = this.searchForm.endDate;
    this.apiRequest.statusId = this.statusId;
    this.apiRequest.startDatetime = this.searchForm.startDate;
    this.apiRequest.requestNo = this.searchForm.requestNo;
    this.apiRequest.thtNo = this.searchForm.certificateNo;
    this.apiRequest.companyCode = this.searchForm.companyCode;
    this.apiRequest.companyName = this.searchForm.companyName;
    this.apiRequest.pagination = {
      Skip:
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
      Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
    };
    this.dataSource.paginator = this.paginator;
    this.loadData(this.apiRequest);
  }

  clearOption() {
    this.searchForm = {};
    this.searchCompanyForm.get('companySearch').setValue('');
  }
  CreateReprint() {
    const dialogRef = this.dialog.open(ListReprintCreatePopupComponent, {
      disableClose: false,
      width: '80vw',
      data: {},
      maxWidth: 'none',
    })
    dialogRef.afterClosed().subscribe((res) => {
      if (typeof (res) == 'number') {
        this.router.navigate(["/page/print/create-reprint"], {
          queryParams: {
            hId: res,
          }
        })
      } else {
        return
      }
    })
  }
  calculateIndex(index: number): number {
    return this.dataSource.paginator.pageIndex * this.dataSource.paginator.pageSize + index + 1;
  }

  editItem(e) {
    this.router.navigate(["/page/verify/create-verify-reprint"], { queryParams: { reprintId: e.reprintId, hId: e.hid } })
  }
  showDetail(e) {
    const queryParams = {
      reprintId: e.reprintId,
      disable: true,
      hId: e.hid,
      staff: true,
      sessionId: this._authService.getUniqueId()
    };

    var url = this.router.createUrlTree(["/page/verify/create-verify-reprint"], {
      queryParams
    }).toString();

    var fullUrl = this.buildDynamicURL(url);
    // Open the URL in a new tab when the button is clicked
    window.open(fullUrl, '_blank');
  }

  buildDynamicURL(path) {
    // Get the current host from the browser's location
    const currentProtocol = window.location.protocol;
    const currentHost = window.location.hostname;
    const currentPort = window.location.port;
    
    // Remove any trailing slashes from the pathname
    const pathname = window.location.pathname.replace(/\/$/, '');
    
    // Combine the host and pathname to create the base URL
    const baseURL = currentPort ? `${currentProtocol}//${currentHost}:${currentPort}${pathname}/#` : `${currentProtocol}//${currentHost}${pathname}/#`;
    debugger;

    // Combine the base URL with the provided path
    const fullURL = `${baseURL}${path}`;
    
    return fullURL;
  }

  async pageChanged(e) {
    
    const paginationData = {
      Skip: (!e.pageIndex ? 0 : e.pageIndex) * (!e.pageSize ? 0 : e.pageSize),
      Take: !e.pageSize ? 10 : e.pageSize,
    }
    const request = {
     UID : this.userProfile.uId,
     endDatetime : this.searchForm.endDate,
     statusId : this.statusId,
     startDatetime : this.searchForm.startDate,
     requestNo : this.searchForm.requestNo,
     thtNo : this.searchForm.certificateNo,
     companyCode : this.searchForm.companyCode,
     companyName : this.searchForm.companyName,
     pagination: paginationData
    };
    await this.appVerrifyService.getVerifyReprintList(request).then((res: any) => {
      if (res === "error") {

        this.dataSource.data = []
        this.count = 0
        return;
      } else {
        this.dataSource.data = res.getListVerifyRePrint;
        this.dataSource.sort = this.sort;
        this.count = res.totalCount

      }
    });
    this.dataSource.paginator.length = this.count
    this.dataSource.paginator.pageIndex = e.pageIndex
  }
  statusChange() {
    this.status = 2
    this.statusId = 16;
    const request = {
      statusId: this.statusId,
      pagination: {
        Skip:
          (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
          (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
        Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
      }
    };
    this.loadData(request);
  }
  searchAll() {
    this.status = 1
    this.statusId = 0;
    const request = {
      statusId: this.statusId,
      pagination: {
        Skip:
          (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
          (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
        Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
      }
    };
    this.loadData(request);
  }

  selectionChange(e): void {
    this.searchCompanyForm.get('companySearch').setValue(e.option.value.companyCode);
    this.companySelect(e.option.value);
  }

  companySelect(e) {
    this.searchForm.companyCode = e.cpid;
    this.searchForm.companyName = e.companyNameEn;

    this.valueSelect = true;
  }

  valueChange() {
    this.filteredOptions$ = this.searchCompanyForm.get('companySearch').valueChanges.pipe(
      debounceTime(500),
      switchMap(value => {
        if (!value) {
          this.valueSelect = false;
          this.searchForm.companyCode = null;
          return of(null);
        }

        this.httpLoadingService.enabled = false;
        this.isLoading = true;

        const key = value;
        if (key !== '') {
          if (this.valueSelect) {
            return of(null);
          }
          else {
            this.valueSelect = false;
            return this.appCompanyService.getListCompanyByFilter(key);
          }
        } else {
          this.valueSelect = false;
          this.isLoading = false;
          return;
        }

      }),
      map((res: any[]) => {
        this.isLoading = false;
        this.httpLoadingService.enabled = true;

        

        return res?.map(item => {
          const { companyTaxId, companyNameEn } = (item as companySearch);
          return {
            ...item,
            // tslint:disable-next-line: max-line-length
            display: `${companyTaxId} ${companyNameEn}`
          };
        });
      })
    );
  }
}
