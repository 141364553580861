import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { VerifyRecheckPopupSerchRequestComponent } from '../verify-recheck-popup-serch-request/verify-recheck-popup-serch-request.component'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort, Sort } from '@angular/material/sort'
import { LiveAnnouncer } from '@angular/cdk/a11y'
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'
import { MatTableDataSource } from '@angular/material/table'
import moment from 'moment'
import { IcoStatus } from 'app/business/interface/app/app-co.interface'
import { AppRecheckService } from 'app/business/service/app/app-recheck.service'
import { VerifyRecheckPopupDetailComponent } from '../verify-recheck-popup-detail/verify-recheck-popup-detail.component'
import { ISaveRecheckStatusRequest } from 'app/business/interface/api/api-verify.interface'
import { ConfirmDialogService } from '@bundle/component/confirm-dialog/confirm-dialog.service'
import { Router } from '@angular/router'
import { AppUserService } from 'app/business/service/app/app-user.service'
import { AppMenuService } from 'app/business/service/app/app-menu.service'
import { AppMasterService } from 'app/business/service/app/app-master.service'
import { debounceTime, map, startWith, switchMap, takeUntil } from "rxjs/operators";
import { HttpLoadingService } from '@bundle/service/http-loading.service';
import { of, Subject } from "rxjs";
import { companySearch } from 'app/business/interface/app/app-company.interace'
import { AppCompanyService } from 'app/business/service/app/app-company.service'
import { FormGroup, FormBuilder } from '@angular/forms'
import { AuthService } from 'app/core/auth/auth.service'

@Component({
  selector: 'app-verify-recheck-inquiry',
  templateUrl: './verify-recheck-inquiry.component.html',
  styleUrls: ['./verify-recheck-inquiry.component.scss'],
})
export class VerifyRecheckInquiryComponent implements OnInit, AfterViewInit {
  myDate = new Date()
  form: any = {}
  searchForm: any = {
    startDate: null,
    endDate: null,
    rqNo: '',
    invoiceRef: '',
    thtNo: '',
    status: '',
  }
  statusOptions = [];
  timer: any;
  statusButton : number = 1
  displayedColumns: string[] = [
    'no',
    'requestBy',
    'declarator',
    'rqNo',
    'docType',
    'invoiceRef',
    'thtNo',
    'createDate',
    'verifyBy',
    'rqStatus',
    'action',
  ]
  count: any = 0
  searchString: string
  apiRequest: any = {
    UID: 0,
    rqNo: '',
    invoiceRef: '',
    thtNo: '',
    status: '',
  }
  apiResponse: any[]
  dataSource = new MatTableDataSource<any>()
  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort
  userProfile: any = {}
  url: any;
  buttonList: any[] = [];
  searchCompanyForm: FormGroup;
  valueSelect: boolean = false;
  isLoading: boolean = false;
  filteredOptions$: any = [];
  constructor(
    private _formBuilder: FormBuilder,
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private AlertMessageService: AlertMessageService,
    private appRecheckService: AppRecheckService,
    private confirmDialogService: ConfirmDialogService,
    private router: Router,
    private appUserService: AppUserService,
    private appMenuService: AppMenuService,
    private appMasterService: AppMasterService,
    private httpLoadingService: HttpLoadingService,
    private appCompanyService: AppCompanyService,
    private _authService:AuthService,
  ) {}

  async getRouter(): Promise<void> {
    
    this.url = this.router.url;

    const formData = {
      url: this.url,
      userGroupCode: this.userProfile.userGroupCode,
      userRoleCode: this.userProfile.userRoleCode,
      userCode: this.userProfile.userCode
    };
    this.buttonList = await this.appMenuService.getButtonPermissionPerPages(formData);
    
  }

  async ngOnInit() {
    this.userProfile = JSON.parse(
      this._authService.loginUser
    );
   

    this.searchCompanyForm = this._formBuilder.group({
      companySearch: ['']
    });
    this.valueChange();

    await this.getRouter();
    
    this.apiRequest.UID = this.userProfile.uId
    this.apiRequest.userName = this.userProfile.userName
    this.dataSource.paginator = this.paginator
    this.apiRequest.pagination = {
      Skip:
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
      Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
    }
    //this.loadData(this.apiRequest);
    const dataReq = {
      "configHCode":"APPROVETYPE"
    }
    this.appMasterService.getMstConfigByHCode(dataReq).then((res: any) => {
      if (res === "error") {
        return;
      } else {
        this.statusOptions = res;
      }
    });
    this.statusChange(1);
  }

  ngAfterViewInit() {
    this.paginator.pageIndex = 0
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
  }
  applyFilter(event: string) {
    this.clearOption();
    clearTimeout(this.timer); // Clear any existing timer
    this.timer = setTimeout(() => {
      this.apiRequest = {
        textSearch: this.form.search,
      }
      this.apiRequest.pagination = {
        Skip:
          (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
          (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
        Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
      }
      this.loadData(this.apiRequest);
    }, 500);
    
  }
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`)
    } else {
      this._liveAnnouncer.announce('Sorting cleared')
    }
  }
  stopPropagation(event) {
    event.stopPropagation()
  }

  onRefresh() {
    this.form.search= "";
    this.apiRequest.id = 0;
    this.apiRequest.endDatetime = this.searchForm.endDate;
    this.apiRequest.startDatetime = this.searchForm.startDate;
    this.apiRequest.requestNo = this.searchForm.requestNo;
    this.apiRequest.refNo = this.searchForm.invoiceReference;
    this.apiRequest.certNo = this.searchForm.certificateNo;
    this.apiRequest.companyCode = this.searchForm.companyCode;
    this.apiRequest.companyName = this.searchForm.companyName;
    this.apiRequest.pagination = {
      Skip:
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
      Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
    }
    this.dataSource.paginator = this.paginator
    this.loadData(this.apiRequest);
  }

  saveSearch() {
    this.form.search= "";
    if (
      moment(this.searchForm.startDate).format('YYYY-MM-DD') >
      moment(this.searchForm.endDate).format('YYYY-MM-DD')
    ) {
      this.AlertMessageService.open({
        message: 'กรุณาระบุวันที่สิ้นสุดให้มากกว่าวันที่เริ่มต้น',
        title: 'กรุณากรอกข้อมูลค้นหาให้ถูกต้อง',
      })
      return false
    }

    this.apiRequest.id = 0;
    this.apiRequest.endDatetime = this.searchForm.endDate;
    this.apiRequest.startDatetime = this.searchForm.startDate;
    this.apiRequest.requestNo = this.searchForm.requestNo;
    this.apiRequest.refNo = this.searchForm.invoiceReference;
    this.apiRequest.certNo = this.searchForm.certificateNo;
    this.apiRequest.companyCode = this.searchForm.companyCode;
    this.apiRequest.companyName = this.searchForm.companyName;
    this.apiRequest.pagination = {
      Skip:
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
      Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
    }
    this.dataSource.paginator = this.paginator
    this.loadData(this.apiRequest)
  }
  createVerifyRecheck() {
    const dialogRef = this.dialog.open(
      VerifyRecheckPopupSerchRequestComponent,
      {
        disableClose: false,
        width: '90vw',
        data: {},
        maxWidth: 'none',
      },
    )
    dialogRef.afterClosed().subscribe((res) => {
      if (res == false) {
        return
      } else {
        this.loadData(this.apiRequest)
      }
    })
  }

  async loadData(request) {     
    if (this.paginator != undefined && this.paginator != null) {
      this.paginator.pageIndex = 0
    }
    
    request.pagination = {
      Skip: 0, // Start from page 1 (skip 0)
      Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
    };

    await this.appRecheckService.getRechecklist(request).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        if (res !== null) {
          let a = res.response.map((item) => {
            moment.locale('th') // set default locale to Thai
            item.createDate = moment(item.createDate).format('YYYY-MM-DD')
            switch (item.rqStatus) {
              case '3':
                item.rqStatus = 'ยังไม่ส่งตรวจ'
                item.action = 3
                break
              case '4':
                item.rqStatus = 'รอตรวจสอบ'
                item.action = 4
                break
              case '40':
                item.rqStatus = 'กำลังตรวจสอบ'
                item.action = 40
                break
              case '5':
                item.rqStatus = 'ผ่านอนุมัติ'
                item.action = 5
                break
              case '10':
                item.rqStatus = 'ไม่ผ่านอนุมัติ'
                item.action = 10
                break
              case '6':
                item.rqStatus = 'ชำระเงินสำเร็จ'
                item.action = 6
                break
              case '7':
                item.rqStatus = 'พิมพ์ใบรับรองแล้ว'
                item.action = 7
                break
              case '38':
                item.rqStatus = 'รอเซ็นสด'
                item.action = 38
                break
              case '8':
                item.rqStatus = 'จบกระบวนการ'
                item.action = 8
                break
              case '9':
                item.rqStatus = 'รอพิจารณาแก้ไข'
                item.action = 9
                break
              case '34':
                item.rqStatus = 'ยกเลิกคำขอแก้ไข'
                item.action = 34
                break
            }
            switch (item.docPriority) {
              case '1':
                item.docPriority = 'งานแก้ไขเร่งด่วน'
                break
              case '2':
                item.docPriority = 'งานแก้ไขปกติ'
                break
              case '3':
                item.docPriority = 'งานแก้ไข Job '
                break
              case '4':
                item.docPriority = 'งานใหม่เร่งด่วน'
                break
              case '5':
                item.docPriority = 'งานใหม่ปกติ'
                break
            }
            return item
          })
          this.dataSource.data = a
          this.count = res.totalCount
        } else {
          this.dataSource.data = []
          this.count = 0
        }
      }
    })
    this.dataSource.paginator.length = this.count
  }

  async pageChanged(e) {
    this.apiRequest.pagination = {
      Skip: (!e.pageIndex ? 0 : e.pageIndex) * (!e.pageSize ? 0 : e.pageSize),
      Take: !e.pageSize ? 10 : e.pageSize,
    }
    await this.appRecheckService
      .getRechecklist(this.apiRequest)
      .then((res: any) => {
        if (res === 'error') {
          return
        } else {
          if (res !== null) {
            let a = res.response.map((item) => {
              moment.locale('th') // set default locale to Thai
              item.createDate = moment(item.createDate).format('YYYY-MM-DD')
              switch (item.rqStatus) {
                case '3':
                  item.rqStatus = 'ยังไม่ส่งตรวจ'
                  item.action = 3
                  break
                case '4':
                  item.rqStatus = 'รอตรวจสอบ'
                  item.action = 4
                  break
                case '40':
                  item.rqStatus = 'กำลังตรวจสอบ'
                  item.action = 40
                  break
                case '5':
                  item.rqStatus = 'ผ่านอนุมัติ'
                  item.action = 5
                  break
                case '10':
                  item.rqStatus = 'ไม่ผ่านอนุมัติ'
                  item.action = 10
                  break
                case '6':
                  item.rqStatus = 'ชำระเงินสำเร็จ'
                  item.action = 6
                  break
                case '7':
                  item.rqStatus = 'พิมพ์ใบรับรองแล้ว'
                  item.action = 7
                  break
                case '38':
                  item.rqStatus = 'รอเซ็นสด'
                  item.action = 38
                  break
                case '8':
                  item.rqStatus = 'จบกระบวนการ'
                  item.action = 8
                  break
                case '9':
                  item.rqStatus = 'รอพิจารณาแก้ไข'
                  item.action = 9
                  break
                case '34':
                  item.rqStatus = 'ยกเลิกคำขอแก้ไข'
                  item.action = 34
                  break
              }
              switch (item.docPriority) {
                case '1':
                  item.docPriority = 'งานแก้ไขเร่งด่วน'
                  break
                case '2':
                  item.docPriority = 'งานแก้ไขปกติ'
                  break
                case '3':
                  item.docPriority = 'งานแก้ไข Job '
                  break
                case '4':
                  item.docPriority = 'งานใหม่เร่งด่วน'
                  break
                case '5':
                  item.docPriority = 'งานใหม่ปกติ'
                  break
              }
              return item
            })
            this.dataSource.data = a
            this.count = res.totalCount
          } else {
            this.dataSource.data = []
            this.count = 0
          }
        }
      })
    this.dataSource.paginator.length = this.count
    this.dataSource.paginator.pageIndex = e.pageIndex
  }
  clearOption() {
    this.searchForm = {}
    this.apiRequest.certNo = ""
    this.apiRequest.endDatetime =""
    this.apiRequest.startDatetime =""
    this.apiRequest.refNo = ""
    this.apiRequest.requestNo = ""
    this.searchCompanyForm.get('companySearch').setValue('');
  }
  edit(e) {
    
    const data = {
      declarator: e.declarator,
      recheckId: e.recheckId,
      packId: e.packId,
      reason: e.reason,
      rqNo: e.rqNo,
      result : e.rqStatus
    }
    const dialogRef = this.dialog.open(VerifyRecheckPopupDetailComponent, {
      disableClose: false,
      width: '60vw',
      data: data,
      maxWidth: 'none',
    })
    dialogRef.afterClosed().subscribe((res) => {
      if (res == false) {
        return
      } else {
        this.loadData(this.apiRequest)
      }
    })
  }

  deleteItem(e) {
    
    const request = {
      recheckId: e.recheckId,
      isActive: 'N',
    } as ISaveRecheckStatusRequest
    this.confirmDialogService
      .open({ header: '', content: 'ยืนยันที่จะลบข้อมูลนี้?' })
      .then((res) => {
        if (res == true) {
          this.appRecheckService.saveRecheckStatus(request).then((res) => {
            if (res) {
              this.AlertMessageService.open({
                title: '',
                message: 'ลบข้อมูลสำเร็จ',
              }).then((res) => {
                this.loadData(this.apiRequest)
              })
            } else {
              return
            }
          })
        } else {
          return
        }
      })
  }

  sendVerify(e)
  {
    
    const request = {
      recheckId: e.recheckId,
      isActive: 'Y',
      approveType:'W'
    } as ISaveRecheckStatusRequest
    this.confirmDialogService
      .open({ header: '', content: 'ยืนยันที่จะยื่นคำขอ?' })
      .then((res) => {
        if (res == true) {
          this.appRecheckService.saveRecheckStatus(request).then((res) => {
            if (res) {
              this.AlertMessageService.open({
                title: '',
                message: 'ยื่นคำขอสำเร็จ',
              }).then((res) => {
                this.loadData(this.apiRequest)
              })
            } else {
              return
            }
          })
        } else {
          return
        }
      })
  }

  cancel(e){
    const request = {
      recheckId: e.recheckId,
      isActive: 'Y',
      approveType:'-'
    } as ISaveRecheckStatusRequest
    this.confirmDialogService
      .open({ header: '', content: 'ยืนยันที่จะยกเลิกคำขอ?' })
      .then((res) => {
        if (res == true) {
          this.appRecheckService.saveRecheckStatus(request).then((res) => {
            if (res) {
              this.AlertMessageService.open({
                title: '',
                message: 'ยกเลิกขอสำเร็จ',
              }).then((res) => {
                this.loadData(this.apiRequest)
              })
            } else {
              return
            }
          })
        } else {
          return
        }
      })
  }

  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find(e => e.buttonCode == buttonCode);

    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }
  statusChange(e){
    this.statusButton = e;
    if(this.statusButton == 1){
      this.clearOption();
      this.apiRequest.status = "W"
      this.apiRequest.pagination = {
        Skip:
          (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
          (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
        Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
      }
      this.loadData(this.apiRequest);
    /*this.data.filterValue = e;
    this.apiRequest(this.data);*/
    }
    else
    {
      this.apiRequest.status = " "
      this.apiRequest.UID = this.userProfile.uId
      this.apiRequest.userName = this.userProfile.userName
      this.dataSource.paginator = this.paginator
      this.apiRequest.pagination = {
      Skip:
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
      Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
      }
      this.loadData(this.apiRequest);
    }
  }

  selectionChange(e): void {
    this.searchCompanyForm.get('companySearch').setValue(e.option.value.companyCode);
    this.companySelect(e.option.value);
  }

  companySelect(e) {
    this.searchForm.companyCode = e.companyCode;
    this.searchForm.companyName = e.companyNameEn;

    this.valueSelect = true;
  }

  valueChange() {
    this.filteredOptions$ = this.searchCompanyForm.get('companySearch').valueChanges.pipe(
      debounceTime(500),
      switchMap(value => {
        if (!value) {
          this.valueSelect = false;
          this.searchForm.companyCode = null;
          return of(null);
        }

        this.httpLoadingService.enabled = false;
        this.isLoading = true;

        const key = value;
        if (key !== '') {
          if (this.valueSelect) {
            return of(null);
          }
          else {
            this.valueSelect = false;
            return this.appCompanyService.getListCompanyByFilter(key);
          }
        } else {
          this.valueSelect = false;
          this.isLoading = false;
          return;
        }

      }),
      map((res: any[]) => {
        this.isLoading = false;
        this.httpLoadingService.enabled = true;

        

        return res?.map(item => {
          const { companyTaxId, companyNameEn } = (item as companySearch);
          return {
            ...item,
            // tslint:disable-next-line: max-line-length
            display: `${companyTaxId} ${companyNameEn}`
          };
        });
      })
    );
  }

  showDocDetail(element) {
    // Generate the URL with queryParams
    const queryParams = {
      id: element.packId,
      disabled: true,
      recheck: true,
      staff: true,
      sessionId: this._authService.getUniqueId()
    };

    debugger;

    // Create the URL for the new tab
    let url = '';
    if (element.docType=='CO' || element.docType=='CO/Pack') {
      
      url = this.router.createUrlTree(['/page/request/request-co/create-co'], {
        queryParams,
      }).toString();
  
    } else if (element.docType == 'CF') {
      url = this.router.createUrlTree(['/page/request/request-cf/create-cf'], {
        queryParams,
      }).toString();  
    }

    var fullUrl = this.buildDynamicURL(url);
    // Open the URL in a new tab when the button is clicked
    window.open(fullUrl, '_blank');
  }

  buildDynamicURL(path) {
    // Get the current host from the browser's location
    const currentProtocol = window.location.protocol;
    const currentHost = window.location.hostname;
    const currentPort = window.location.port;
    
    // Remove any trailing slashes from the pathname
    const pathname = window.location.pathname.replace(/\/$/, '');
    
    // Combine the host and pathname to create the base URL
    const baseURL = currentPort ? `${currentProtocol}//${currentHost}:${currentPort}${pathname}/#` : `${currentProtocol}//${currentHost}${pathname}/#`;
    debugger;

    // Combine the base URL with the provided path
    const fullURL = `${baseURL}${path}`;
    
    return fullURL;
  }  
}
