<mat-card-header>
  <div class="flex justify-between" style="width: 100%;">
    <div class="text-lg"> {{header ?? 'ตัวอย่างเอกสาร'}} </div>
    <div>
      <button class="close-button" [mat-dialog-close]="true">
        <mat-icon class="text-white" [mat-dialog-close]>cancel</mat-icon>
      </button>
    </div>
  </div>
</mat-card-header>
<mat-dialog-content style="padding: 0px;">
  <div class="boundary">
    <div id="previewImg" lass="boundary" style="width: 100%;
      height: 100%;z-index: -1;">
      <img id="imagePreview" #imagePreview src="{{imgSrc}}" style="
            position: absolute;
            max-width: 100%;
            display: block;
            cursor: move;
            z-index: 0;
            left: 0px;
            " />
      <div id="divData" #divData class="drag-source box" style="position: absolute; 
             text-align: center;z-index: 99; max-width: 500px;word-wrap: break-word;"
        [ngStyle]="{'transform': 'translate(' + px + 'px, ' + py + 'px)'}">
        <label style="white-space: pre-wrap;">{{ mark }}</label>
      </div>
    </div>
  </div>
</mat-dialog-content>