<div>
  <div style="
        display: flex;
        flex-flow: column;
        justify-content: center;
        height: 10vh;
        background-color: lightgray;
        border-radius: 6px;
      " class="mb-3 pl-5">
    <h2 style="font-size: large !important; font-weight: bold">
      ข้อมูลรายการสินค้า
    </h2>
    <h2 style="font-size: large !important; font-weight: bold">
      Goods Detail Form
    </h2>
  </div>
  <mat-divider></mat-divider>
  <h2 style="font-size: large !important; font-weight: bold">
    ข้อมูลที่ปรากฎลงบนฟอร์ม
  </h2>
  <mat-divider class="mb-3"></mat-divider>
  <div class="flex flex-col pl-5 pr-5 mb-3 justify-start gap-4">
    <div class="flex-auto flex flex-row gap-3">
      <div class="w-3/12 flex flex-col">
        <h4>เครื่องหมายหีบห่อ<span class="required">*</span></h4>
        <h4>Mark</h4>
        <mat-checkbox class="example-margin" [(ngModel)]="form.markCHK" name="markCHK" #markCHK="ngModel" [disabled]="true">
          ไม่ปรากฏบนฟอร์ม</mat-checkbox>
      </div>
      <div class="w-9/12 flex flex-row">
        <div class="flex-auto form-field-container">
          <mat-form-field class="flex-auto" style="width: 100%">
            <textarea autocomplete="off" type="text" required matInput [(ngModel)]="form.markItem" name="markItem"
              #markItem="ngModel" [disabled]="edit == false" maxlength="300" rows="4" cols="40"></textarea>
          </mat-form-field>
          <p class="flex-auto text-end" *ngIf="form.markItem?.length != null">
            {{ form.markItem?.length + "/300" }}
          </p>
        </div>
      </div>
    </div>
    <div class="flex-auto flex flex-row gap-3">
      <div class="w-3/12 flex flex-col">
        <h4>ชื่อสินค้า<span class="required">*</span></h4>
        <h4>Description</h4>
        <mat-checkbox class="example-margin" [(ngModel)]="form.descriptionCHK" name="descriptionCHK"
          #descriptionCHK="ngModel" [disabled]="true">ไม่ปรากฏบนฟอร์ม</mat-checkbox>
      </div>
      <div class="w-9/12 flex flex-row">
        <div class="flex-auto form-field-container">
          <mat-form-field class="flex-auto" style="width: 100%">
            <textarea autocomplete="off" type="text" required matInput [(ngModel)]="form.description" name="description"
              #description="ngModel" [disabled]="edit == false" maxlength="500" rows="4" cols="40"></textarea>
          </mat-form-field>
          <p class="flex-auto text-end" *ngIf="form.description?.length != null">
            {{ form.description?.length + "/500" }}
          </p>
        </div>
      </div>
    </div>

    <div class="flex-auto flex flex-row gap-3">
      <div class="w-3/12 flex flex-col">
        <h4>ปริมาณ<span class="required">*</span></h4>
        <h4 style="margin-right: 80px">Quantity</h4>
        <div class = "flex-row">
          <mat-checkbox class="example-margin" [(ngModel)]="form.quantityCHK" name="quantityCHK"
                    #quantityCHK="ngModel" [disabled]="true">ไม่ปรากฏบนฟอร์ม</mat-checkbox> 
        </div>
      </div>
      <div class="w-9/12 flex flex-row" *ngIf="edit == true">
        <mat-form-field class="flex-auto">
          <input autocomplete="off" type="text"
            onkeydown="if (event.key === '-' || event.key === '.' || isNaN(Number(event.key)) && event.key !== 'Tab' && event.key !== 'Delete' && event.key !== 'Backspace') event.preventDefault();"
            onkeyup="this.value = this.value.replace(/[^0-9]/g, '');" required matInput [(ngModel)]="form.quantity"
            name="quantity" #quantity="ngModel" maxlength="10" />
        </mat-form-field>
        <mat-form-field class="flex-auto ml-2">
          <mat-select [(ngModel)]="form1.quantityObj" required name="quantityObj" #quantityObj="ngModel">
            <mat-option *ngFor="let unit of unitList" [value]="unit.code">
              {{ unit.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-checkbox class="example-margin" [(ngModel)]="form.quantityCHK" name="quantityCHK"
          #quantityCHK="ngModel">ไม่ปรากฏบนฟอร์ม</mat-checkbox>
      </div>
      <div class="w-9/12 flex flex-row items-center gap-3" *ngIf="edit == false">
        <mat-form-field class="flex-auto">
          <input autocomplete="off" type="text" matInput [(ngModel)]="this.detail.quantity" name="quantityEdit"
            #quantityEdit="ngModel" [disabled]="this.edit == false" maxlength="10" />
        </mat-form-field>
        <!-- <mat-checkbox class="example-margin" [(ngModel)]="form.quantityCHK" name="quantityCHK"
                  #quantityCHK="ngModel">ไม่ปรากฏบนฟอร์ม</mat-checkbox> -->
      </div>
    </div>

    <div class="flex-auto flex flex-row gap-3">
      <div class="w-3/12 flex flex-col">
        <h4>น้ำหนัก<span class="required">*</span></h4>
        <h4 style="margin-right: 60px">Net Weight</h4>
        <div class = "flex-row">
          <mat-checkbox class="example-margin" [(ngModel)]="form.weightCHK" name="weightCHK" #weightCHK="ngModel" [disabled]="true">
            ไม่ปรากฏบนฟอร์ม</mat-checkbox>
        </div>
      </div>
      <div class="w-9/12 flex flex-row" *ngIf="edit == true">
        <mat-form-field class="flex-auto">
          <input autocomplete="off" type="text" min="0" required matInput [(ngModel)]="form.weight" name="weight"
            #weight="ngModel" onkeydown="if (event.key === '-') event.preventDefault();"
            onkeyup="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            (input)="form.weight = weight.value.replace('-', '');" maxlength="16" appTwoDigitDecimaNumber />
        </mat-form-field>
        <mat-form-field class="flex-auto ml-2">
          <mat-select [(ngModel)]="form1.weightObj" name="weightObj" #weightObj="ngModel" required>
            <mat-option *ngFor="let weight of weightList" [value]="weight.code">
              {{ weight.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="w-9/12 flex flex-row items-center gap-3" *ngIf="edit == false">
        <mat-form-field class="flex-auto">
          <input autocomplete="off" type="text" min="0" matInput [(ngModel)]="detail.weight" name="weightEdit"
            #weightEdit="ngModel" [disabled]="!edit" onkeydown="if (event.key === '-') event.preventDefault();"
            onkeyup="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            (input)="detail.weight = weightEdit.value.replace('-', '');" maxlength="16" appTwoDigitDecimaNumber />
        </mat-form-field>
        <p *ngIf="form.weightCHK == true && edit == false">ไม่ปรากฏบนฟอร์ม</p>
      </div>
    </div>
    <div class="flex-auto flex flex-row gap-3">
      <div class="w-3/12 flex flex-col">
        <h4>น้ำหนักรวม<span class="required">*</span></h4>
        <h4>
          Gross Weight
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </h4>
        <div class = "flex-row">
          <mat-checkbox class="example-margin" [(ngModel)]="form.grossWeightCHK" name="grossWeightCHK"
            #grossWeightCHK="ngModel" [disabled]="true">ไม่ปรากฏบนฟอร์ม</mat-checkbox>
        </div>
      </div>
      <div class="w-9/12 flex flex-row" *ngIf="edit == true">
        <mat-form-field class="flex-auto">
          <input autocomplete="off" type="text" min="0" required matInput [(ngModel)]="form.grossWeight"
            name="grossWeight" #grossWeight="ngModel" onkeydown="if (event.key === '-') event.preventDefault();"
            onkeyup="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            (input)="form.grossWeight = grossWeight.value.replace('-', '');" maxlength="16" appTwoDigitDecimaNumber />
        </mat-form-field>
        <mat-form-field class="flex-auto ml-2">
          <mat-select [(ngModel)]="form1.grossWeightObj" name="grossWeightObj" #grossWeightObj="ngModel" required>
            <mat-option *ngFor="let weight of weightList" [value]="weight.code">
              {{ weight.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="w-9/12 flex flex-row items-center gap-3" *ngIf="edit == false">
        <mat-form-field class="flex-auto">
          <input autocomplete="off" type="text" min="0" matInput [(ngModel)]="detail.grossWeight" name="grossWeightEdit"
            #grossWeightEdit="ngModel" [disabled]="!edit" onkeydown="if (event.key === '-') event.preventDefault();"
            onkeyup="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            (input)="detail.grossWeight = grossWeightEdit.value.replace('-', '');" maxlength="16"
            appTwoDigitDecimaNumber />
        </mat-form-field>
      </div>
    </div>
  </div>

  <mat-divider></mat-divider>
  <h2 style="font-size: large !important; font-weight: bold">ข้อมูลทางสถิติ</h2>
  <mat-divider class="mb-3"></mat-divider>

  <div class="flex flex-col pl-5 pr-5 justify-start gap-4">
    <div class="flex-auto flex flex-row gap-3">
      <div class="w-3/12 flex flex-col">
        <h4>พิกัดศุลกากร<span class="required">*</span></h4>
        <h4>Harmonized Code</h4>
      </div>
      <div class="w-9/12 flex flex-row">
        <mat-form-field class="flex-1">
          <input type="text" matInput [(ngModel)]="Harmonizedform" (ngModelChange)="valuechange($event)"
            name="harmonizedSearch" #harmonizedSearch="ngModel" [matAutocomplete]="auto" [disabled]="edit == false" />
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onInput($event)">
            <!-- (onSelectionChange)="onInput(options)" -->
            <mat-option *ngFor="let options of filteredOptions" [value]="options">
              {{ options.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <div class="flex-auto flex flex-row gap-3">
      <div class="w-3/12 flex flex-col">
        <h4>มูลค่าการส่งออก<span class="required">*</span></h4>
        <h4>Export Value</h4>
      </div>
      <div class="w-9/12 flex flex-row items-center gap-3">
        <mat-form-field class="w-1/3">
          <input required autocomplete="off" type="text" min="0" matInput [(ngModel)]="form.exportValue"
            name="exportValue" #exportValue="ngModel" (ngModelChange)="updateExchangeValue()"
            onkeydown="if (event.key === '-') event.preventDefault();"
            onkeyup="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" maxlength="14"
            appTwoDigitDecimaNumber [disabled]="edit == false" />
        </mat-form-field>
        <div>USD</div>
      </div>
    </div>
    <div class="flex-auto flex flex-row gap-3">
      <div class="w-3/12 flex flex-col pr-5">
        <h4>อัตราแลกเปลี่ยน</h4>
        <h4>Exchange Rate</h4>
      </div>
      <div class="w-9/12 flex flex-row items-center gap-3">
        <mat-form-field class="w-1/3">
          <input required autocomplete="off" type="number" min="0" matInput [(ngModel)]="form.exchangeRate"
            name="exchangeRate" #exchangeRate="ngModel" [disabled]="true" />
        </mat-form-field>
        <div>THB/USD</div>
      </div>
    </div>
    <div class="flex-auto flex flex-row gap-3">
      <div class="w-3/12 flex flex-col pr-5">
        <h4>มูลค่าการแลกเปลี่ยน</h4>
        <h4>Exchange Value</h4>
      </div>
      <div class="w-9/12 flex flex-row items-center gap-3">
        <mat-form-field class="w-1/3">
          <input required autocomplete="off" type="number" min="0" matInput [(ngModel)]="form.exchangeValue"
            name="exchangeValue" #exchangeValue="ngModel" [disabled]="true" />
        </mat-form-field>
        <div>THB</div>
      </div>
    </div>
  </div>
</div>