import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { AppRecheckService } from 'app/business/service/app/app-recheck.service'

@Component({
  selector: 'app-recheck-management-popup',
  templateUrl: './recheck-management-popup.html',
  styleUrls: ['./recheck-management-popup.scss'],
})
export class RecheckManagementPopupComponent implements OnInit {
  form: any = {rqNo:"",compName:"",reason:""}
  editable : any
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef: MatDialogRef<RecheckManagementPopupComponent>,
    private appRecheckSerivce: AppRecheckService,
  ) {
    this.form = data.data
    this.editable = data.flag
    
    this.loadData(data.data.recheckId)
  }

  ngOnInit(): void {
  }

  async loadData(id) {
    const data = {
      recheckId: id,
    }
    await this.appRecheckSerivce
      .getManagementRecheckDetail(data)
      .then((res) => {
        if (res == 'error') {
          return
        } else {
          this.form = res
          if(this.editable) {
            this.form.forRecheck = 'C';
          }
        }
      })
  }

  save() {
    
    const data = {
      recheckId: this.form.recheckId,
      isActive: 'Y',
      approveType: this.form.approveType,
      forRecheck: this.form.forRecheck,
    }
    this.appRecheckSerivce.saveRecheckStatus(data).then((res)=>{
      if(res==='error')
      {
        return
      }
      else{
          this.dialogRef.close();
      }
    })
  }
}
