<mat-card>
  <mat-card-header>
    <div class="flex justify-between" style="width: 100%;">
      <div class="text-lg"> {{notifyData.notificationTopicTh}} </div>
      <div>
        <button class="close-button" [mat-dialog-close]="true">
          <mat-icon class="text-white" [mat-dialog-close]>cancel</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-dialog-content>
    <div class="pl-22 pr-22 flex flex-col gap-4">
      <ng-container *ngIf="notifyData.notificationImagePath">
        <div class="flex gap-2.5" style="align-items: center; flex-direction: column;">
          <img [src]="notifyData.notificationImagePath">
        </div>
      </ng-container>

      <div class="flex gap-2.5" style="flex-direction: column;font-size: medium;color: black;">
        <div>{{notifyData.notificationDescTh}}</div>
      </div>

      <div class="flex gap-2.5" style="flex-direction: column;font-size: small;">
        <div><strong>{{convertDate(notifyData?.notificationDate)}} {{notifyData.notificationDate | date : "HH:mm"}}</strong></div>
      </div>
    </div>
  </mat-dialog-content>