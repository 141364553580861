<mat-chip-list #chipList fxLayout="row" fxLayoutAlign="center center" *ngIf="!showField">
    <div *ngFor="let item of filterList ">
        <mat-chip *ngIf="item.value !== '' " [removable]="removableFilter(item)" (removed)="removeFilter(item)">
            <span>{{item.label}}</span>
            <mat-icon *ngIf="removableFilter(item)" matChipRemove>cancel</mat-icon>
        </mat-chip>
    </div>
</mat-chip-list>

<mat-accordion class="ml-10 mr-10">
    <mat-expansion-panel (opened)="showField = true" (closed)="showField = false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{showField ? 'ย่อแถบการค้นหา': 'ค้นหาเพิ่มเติม'}}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="mt-12 mb-12" class="pr-10 pl-10" fxLayout="column" fxLayoutAlign="space-between none"
            style="line-height: 23px;display: flex;width: 100%;margin-bottom: 20px;flex-direction: column;">
            <div class="input-row" fxLayout.lt-md="column" fxLayoutGap.lt-md="0px"
                style="width: 100%;margin-right: 15px;display: flex;gap: 10px;">
                <div fxLayout="row" fxLayoutGap="10px" style="width: 100%;margin-bottom: 15px;">
                    <mat-form-field class="form-block" style="width: 100%">
                        <mat-label>Username</mat-label>
                        <input type="text" matInput placeholder="Username" [(ngModel)]="form.userName"
                            (ngModelChange)="userNameChange();">
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="10px" style="width: 100%;margin-bottom: 15px;">
                    <mat-form-field class="form-block w-full">
                        <mat-label>ชื่อผู้ใช้</mat-label>
                        <input type="text" matInput placeholder="ชื่อผู้ใช้" [(ngModel)]="form.name"
                            (ngModelChange)="nameChange();">
                    </mat-form-field>
                </div>
                <ng-container
                    *ngIf="userToken?.userGroupCode === '1' || userToken?.userGroupCode === '4' || userToken?.userGroupCode === '5'">
                    <div fxLayout="row" fxLayoutGap="10px" style="width: 100%;margin-bottom: 15px;">
                        <form name="searchCompanyForm" [formGroup]="searchCompanyForm" class="form-block w-full">
                            <mat-form-field class="form-block w-full">
                                <mat-label>รหัสบริษัท</mat-label>
                                <input matInput type="text" formControlName="companySearch" [matAutocomplete]="auto"
                                    name="companyCode" placeholder="ระบุเลขที่ผู้เสียภาษี / ชื่อบริษัท / รหัสบริษัท">
                                <mat-autocomplete #auto="matAutocomplete" required
                                    (optionSelected)="selectionChange($event)">
                                    <mat-option *ngIf="isLoading">
                                        <mat-spinner [diameter]="20"></mat-spinner>
                                    </mat-option>
                                    <mat-option *ngFor="let option of filteredOptions$  | async" [value]="option">
                                        {{option.display}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </form>
                    </div>
                </ng-container>
            </div>

            <div class="input-row" fxLayout.lt-md="column" fxLayoutGap.lt-md="0px"
                style="width: 100%;margin-right: 15px;display: flex;gap: 10px;">
                <ng-container
                    *ngIf="userToken?.userGroupCode === '1' || userToken?.userGroupCode === '4' || userToken?.userGroupCode === '5'">
                    <div fxLayout="row" fxLayoutGap="10px" style="width: 100%;margin-bottom: 15px;">
                        <mat-form-field class="form-block" style="width: 100%">
                            <mat-label>กลุ่มผู้ใช้</mat-label>
                            <mat-select flex="20" [(ngModel)]="form.userGroupCode" (ngModelChange)="userGroupChange();">
                                <mat-option [value]="''">
                                    ทั้งหมด
                                </mat-option>
                                <mat-option *ngFor="let item of userGroupList" [value]="item.userGroupCode">
                                    {{item.groupName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </ng-container>
                <div fxLayout="row" fxLayoutGap="10px" class="margin" style="width: 100%;margin-bottom: 15px;">
                    <mat-form-field class="form-block" style="width: 100%">
                        <mat-label>สิทธิ์ผู้ใช้</mat-label>
                        <mat-select flex="20" [(ngModel)]="form.userRoleCode" (ngModelChange)="userRoleChange();">
                            <mat-option [value]="''">
                                ทั้งหมด
                            </mat-option>
                            <mat-option *ngFor="let item of userRoleList" [value]="item.userRoleCode">
                                {{item.roleName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutGap="10px" class="margin" style="width: 100%;margin-bottom: 15px;">
                    <mat-form-field class="form-block" style="width: 100%">
                        <mat-label>สถานะ</mat-label>
                        <mat-select flex="20" [(ngModel)]="form.isActive" (ngModelChange)="isActiveChange();">
                            <mat-option [value]="''">
                                ทั้งหมด
                            </mat-option>
                            <mat-option [value]="'Y'"> ใช้งาน</mat-option>
                            <mat-option [value]="'N'"> ยกเลิก</mat-option>
                            <!-- <mat-option *ngFor="let leadPerSonType of leadPerSonType"
                                [value]="leadPerSonType.leadTypeCode">
                                {{leadPerSonType.leadTypeTName}}
                            </mat-option> -->
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px"
            style="margin-bottom: 20px;display:flex;justify-content:space-between;gap: 8px;">
            <div class="flex pl-10">
                <ng-container
                    *ngIf="userToken?.userGroupCode === '1' || userToken?.userGroupCode === '4' || userToken?.userGroupCode === '5'">
                    <div fxLayout="row" fxLayoutGap="10px" class="self-center" style="width: 100%">
                        <mat-radio-group class="form-block w-full" [(ngModel)]="form.groupSelect" name="book"
                            #book="ngModel" aria-label="Select an option">
                            <mat-radio-button *ngFor="let group of listGroupSelectAction" [value]="group.dCode"
                                (ngModelChange)="rdbGroupChange();">{{group.dCode}}</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </ng-container>
            </div>
            <div style="display: flex;gap: 8px;">
                <button mat-raised-button (click)="searchFormReset()" matTooltipPosition="above"
                    matTooltip="ล้างข้อมูล">ล้างข้อมูล</button>
                <button mat-raised-button color="accent" matTooltipPosition="above" (click)="searchFormSubmit()"
                    matTooltip="ค้นหา">
                    <mat-icon>search</mat-icon>ค้นหา
                </button>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>

<div class="mat-elevation-z8" style="width: 100%;">
    <dx-data-grid id="gridLogOTPList" [dataSource]="dataSourceList" [showBorders]="false" [allowColumnReordering]="true"
        [rowAlternationEnabled]="true" [allowColumnResizing]="true" [columnAutoWidth]="true"
        style="margin-top: 30px; margin-bottom: 0px;" [noDataText]="'ไม่มีข้อมูล'" [remoteOperations]="{ paging: true}">
        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 30, 50, 100]" [showInfo]="true">
        </dxo-pager>
        <dxo-scrolling [useNative]="true" mode="standard" [scrollByContent]="true" [scrollByThumb]="true"
            showScrollbar="onHover"></dxo-scrolling>
        <dxo-pager *ngIf="dataSourceList?.length > 0" [showPageSizeSelector]="true" [visible]="true"
            [allowedPageSizes]="false"></dxo-pager>

        <dxo-search-panel [visible]="false"></dxo-search-panel>
        <dxo-filter-row [visible]="false" [showAllText]="'ทั้งหมด'"></dxo-filter-row>
        <dxi-column dataField="index" caption="ลำดับ" dataType="string" alignment="center" [width]="60"
            cellTemplate="index" [allowFiltering]="false">
        </dxi-column>
        <div *dxTemplate="let row of 'index'">
            {{ (row.rowIndex+1) +(dataGrid.instance.pageIndex() * dataGrid.instance.pageSize() ) }}
        </div>
        <!-- <dxi-column [filterOperations]="['contains']" dataField="userCode" caption="รหัสผู้ใช้" dataType="string">
        </dxi-column> -->
        <dxi-column [filterOperations]="['contains']" dataField="userName" caption="Username" dataType="string">
        </dxi-column>
        <dxi-column [filterOperations]="['contains']" dataField="staffName" caption="ชื่อผู้ใช้" dataType="string">
        </dxi-column>
        <dxi-column dataField="userGroupName" caption="กลุ่มผู้ใช้" dataType="string" [allowFiltering]="false">
        </dxi-column>
        <dxi-column dataField="userRoleName" caption="สิทธิ์ผู้ใช้" dataType="string" [allowFiltering]="false">
        </dxi-column>
        <dxi-column alignment="center" dataField="isActive" caption="สถานะ" dataType="string" cellTemplate="status"
            [allowFiltering]="false">
        </dxi-column>
        <dxi-column cellTemplate="editor" caption="ผู้บันทึก"></dxi-column>
        <dxi-column caption="แก้ไข" cellTemplate="edit"> </dxi-column>
        <div *dxTemplate="let row of 'status'">
            <div *ngIf="row.data?.isActive == 'Y'" fxLayout="row" fxLayoutAlign="center center">
                ใช้งาน
            </div>
            <div *ngIf="row.data?.isActive == 'N'" fxLayout="row" fxLayoutAlign="center center">
                ยกเลิก
            </div>
        </div>
        <div *dxTemplate="let row of 'editor'">
            {{row.data.updateByName ? row.data.updateByName + " " + row.data.updateDate : row.data.createByName + " " +
            row.data.createDate }}
        </div>
        <div *dxTemplate="let row of 'edit'">
            <div *ngIf="checkButton('BT195')">
                <button mat-icon-button (click)="editUser(row.data)" matTooltip="แก้ไข" matTooltipPosition="above"
                    style="height:20px">
                    <mat-icon svgIcon="heroicons_outline:pencil-alt"></mat-icon>
                </button>
            </div>
        </div>
    </dx-data-grid>
</div>