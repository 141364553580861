<div class="flex flex-col">
    <div class="card-pd tltle-menu">
        Verify
    </div>
</div>
<div class="flex flex-col gap-4" style="margin-bottom: 5rem !important">
    <div style="gap: 30px;" class="flex justify-items-stretch flex-row px-20">
        <div class="cardx flex flex-col flex-1">
            <div class="flex justify-end gap-4">
                <div
                    [class]="getQueueAdditionData.transferNum > 0 ?'inline-flex items-center px-5 py-2.5 text-lg font-medium text-center text-green-700 bg-green-100 rounded-lg hover:bg-green-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-100 dark:focus:ring-green-800 cursor-default':'inline-flex items-center px-5 py-2.5 text-lg font-medium text-center bg-gray-100 text-gray-800 rounded-lg cursor-default'">
                    งานในความรับผิดชอบ
                    <span
                        class="getQueueAdditionData.transferNum > 0 ?'inline-flex items-center justify-center w-12 h-12 ml-2 text-4xl font-semibold text-green-800 bg-green-200 rounded-full':'inline-flex items-center justify-center w-12 h-12 ml-2 text-4xl font-semibold text-gray-800 bg-white rounded-full'">
                        {{getQueueAdditionData.transferNum |number:"1.0-0"}}
                    </span>
                </div>
                <div
                    [class]="getQueueAdditionData.recheckPendingNum > 0 ?'inline-flex items-center px-5 py-2.5 text-lg font-medium text-center text-green-700 bg-green-100 rounded-lg hover:bg-green-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-100 dark:focus:ring-green-800 cursor-default':'inline-flex items-center px-5 py-2.5 text-lg font-medium text-center bg-gray-100 text-gray-800 rounded-lg cursor-default'">
                    อนุมัติขอตรวจสอบซ้ำ
                    <span
                        class="getQueueAdditionData.recheckPendingNum > 0 ?'inline-flex items-center justify-center w-12 h-12 ml-2 text-4xl font-semibold text-green-800 bg-green-200 rounded-full':'inline-flex items-center justify-center w-12 h-12 ml-2 text-4xl font-semibold text-gray-800 bg-white rounded-full'">
                        {{getQueueAdditionData.recheckPendingNum|number:"1.0-0"}}
                    </span>
                </div>
                <div
                    [class]="getQueueAdditionData.recheckNum > 0 ?'inline-flex items-center px-5 py-2.5 text-lg font-medium text-center text-green-700 bg-green-100 rounded-lg hover:bg-green-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-100 dark:focus:ring-green-800 cursor-default':'inline-flex items-center px-5 py-2.5 text-lg font-medium text-center bg-gray-100 text-gray-800 rounded-lg cursor-default'">
                    งานรอตรวจสอบซ้ำ
                    <span
                        class="getQueueAdditionData.recheckNum > 0 ?'inline-flex items-center justify-center w-12 h-12 ml-2 text-4xl font-semibold text-green-800 bg-green-200 rounded-full':'inline-flex items-center justify-center w-12 h-12 ml-2 text-4xl font-semibold text-gray-800 bg-white rounded-full'">
                        {{getQueueAdditionData.recheckNum|number:"1.0-0"}}
                    </span>
                </div>
                <div
                    [class]="getQueueAdditionData.reprintNum > 0 ?'inline-flex items-center px-5 py-2.5 text-lg font-medium text-center text-green-700 bg-green-100 rounded-lg hover:bg-green-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-100 dark:focus:ring-green-800 cursor-default':'inline-flex items-center px-5 py-2.5 text-lg font-medium text-center bg-gray-100 text-gray-800 rounded-lg cursor-default'">
                    ปลดล๊อคการพิมพ์
                    <span
                        class="getQueueAdditionData.reprintNum > 0 ?'inline-flex items-center justify-center w-12 h-12 ml-2 text-4xl font-semibold text-green-800 bg-green-200 rounded-full':'inline-flex items-center justify-center w-12 h-12 ml-2 text-4xl font-semibold text-gray-800 bg-white rounded-full'">
                        {{getQueueAdditionData.reprintNum|number:"1.0-0"}}
                    </span>
                </div>
            </div>
            <div class="flex flex-row justify-between mt-4 mb-4 gap-4">
                <div class="flex flex-row">
                    <h2 class="text-3xl font-normal ">จำนวนงานที่รอตรวจสอบ : </h2>
                    <h2 class="text-3xl font-bold">&nbsp;{{jobNum}}</h2>
                </div>
                <div class="flex flex-row">
                    <button mat-raised-button color="accent" (click)="onRefresh()">
                        <div class="flex items-center">
                            <span class="icon-text"><mat-icon
                                    svgIcon="heroicons_outline:refresh"></mat-icon>รีเฟรชข้อมูล</span>
                        </div>
                    </button>
                </div>
            </div>

            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-6 w-full min-w-0">

                <!-- งานแก้ไขเร่งด่วน -->
                <div class="flex flex-col flex-auto p-6 bg-card shadow rounded-2xl overflow-hidden">
                    <div class="flex flex-col items-center mt-2">
                        <div class="text-7xl sm:text-8xl font-bold tracking-tight leading-none text-red-500">
                            {{eSNum}}
                        </div>
                    </div>
                    <div class="flex items-center justify-center">
                        <div class="text-lg font-medium tracking-tight leading-6 truncate ">งานแก้ไขเร่งด่วน
                        </div>
                    </div>
                </div>

                <!-- งานแก้ไข -->
                <div class="flex flex-col flex-auto p-6 bg-card shadow rounded-2xl overflow-hidden">
                    <div class="flex flex-col items-center mt-2">
                        <div class="text-7xl sm:text-8xl font-bold tracking-tight leading-none text-amber-500">
                            {{eENum}}
                        </div>
                    </div>
                    <div class="flex items-center justify-center">
                        <div class="text-lg font-medium tracking-tight leading-6 truncate ">งานแก้ไข</div>
                    </div>
                </div>
                <!-- งานแก้ไข JOB -->
                <div class="flex flex-col flex-auto p-6 bg-card shadow rounded-2xl overflow-hidden">
                    <div class="flex flex-col items-center mt-2">
                        <div class="text-7xl sm:text-8xl font-bold tracking-tight leading-none text-yellow">{{eJNum}}
                        </div>
                    </div>
                    <div class="flex items-center justify-center">
                        <div class="text-lg font-medium tracking-tight leading-6 truncate">งานแก้ไข JOB
                        </div>
                    </div>
                </div>
                <!-- งานใหม่เร่งด่วน -->
                <div class="flex flex-col flex-auto p-6 bg-card shadow rounded-2xl overflow-hidden">
                    <!-- <div class="flex items-start justify-between"> -->
                    <div class="flex flex-col items-center mt-2">
                        <div class="text-7xl sm:text-8xl font-bold tracking-tight leading-none text-blue-500">{{nSNum}}
                        </div>
                    </div>
                    <div class="flex items-center justify-center">
                        <div class="text-lg font-medium tracking-tight leading-6 truncate">งานใหม่เร่งด่วน</div>
                    </div>
                </div>
                <!-- งานปกติ -->
                <div class="flex flex-col flex-auto p-6 bg-card shadow rounded-2xl overflow-hidden">
                    <div class="flex flex-col items-center mt-2">
                        <div class="text-7xl sm:text-8xl font-bold tracking-tight leading-none text-green-500">{{nNNum}}
                        </div>
                    </div>
                    <div class="flex items-center justify-center ">
                        <div class="text-lg font-medium tracking-tight leading-6 truncate">งานปกติ</div>
                    </div>
                </div>
            </div>
            <div class="flex flex-row justify-center pt-10">
                <button class="flex-none" style="width: 200px" color="accent" *ngIf="!isLoadQueue" mat-raised-button
                    [disabled]="clickJob" (click)="getQueueOrder()">
                    <mat-icon svgIcon="heroicons_outline:check-circle"></mat-icon>
                    &nbsp;
                    รับงาน
                </button>
            </div>
        </div>
    </div>
    <ng-container *ngIf="isLoadQueue">
        <ng-container *ngFor="let item of onQueue">
            <div style="gap: 30px;margin-bottom: 0px !important;" class="card flex justify-items-stretch flex-row px-20">
                <div class="flex flex-col flex-1">
                    <!-- <mat-card-header>
                        <h2 class="text-center">รายละเอียดคำขอที่กำลังตรวจสอบ</h2>
                    </mat-card-header> -->
                    <section class="text-lg px-4 flex flex-col flex-1 py-4 gap-4">
                        <div>
                            <!-- line 1 -->
                            <div class="flex flex-row px-10 pt-5">
                                <div class="basis-1/2 flex flex-column">
                                    <div class="basis-2/5">
                                        <h4 style="font-weight: 600;" class="mr-14">Request No :</h4>
                                    </div>
                                    <div class="basis-3/5">
                                        {{item.requestNo}}
                                    </div>
                                </div>
                                <div class="basis-1/2 flex flex-column">
                                    <div class="basis-2/5">
                                        <h4 style="font-weight: 600;">ประเภทงาน :</h4>
                                    </div>
                                    <div class="basis-3/5">
                                        {{item.docType}}
                                    </div>
                                </div>
                            </div>
                            <!-- line 2 -->
                            <div class="flex flex-row px-10 pt-5">
                                <div class="basis-1/2 flex flex-column">
                                    <div class="basis-2/5">
                                        <h4 style="font-weight: 600;" class="mr-14">Request Datetime :</h4>
                                    </div>
                                    <div class="basis-3/5">
                                        {{item.requestDateTime | date:'yyyy-MM-dd HH:mm'}}
                                    </div>
                                </div>
                                <div class="basis-1/2 flex flex-column">
                                    <div class="basis-2/5">
                                        <h4 style="font-weight: 600;">สถานะ :</h4>
                                    </div>
                                    <div class="basis-3/5">
                                        {{item.docPriority}}
                                    </div>
                                </div>
                            </div>

                            <!-- line 3 -->
                            <div class="flex flex-row px-10 pt-5">
                                <div class="basis-1/2 flex flex-column">
                                    <div class="basis-2/5">
                                        <h4 style="font-weight: 600;" class="mr-14">Verify Datetime :</h4>
                                    </div>
                                    <div class="basis-3/5">
                                        {{item.verifyDateTime | date:'yyyy-MM-dd HH:mm'}}
                                    </div>
                                </div>
                                <div class="basis-1/2 flex flex-column">
                                    <div class="basis-2/5">
                                        <h4 style="font-weight: 600;">Declarator :</h4>
                                    </div>
                                    <div class="basis-3/5">
                                        {{item.declarator}}
                                    </div>
                                </div>
                            </div>
                            <!-- line 4 -->
                            <div class="flex flex-row px-10 pt-5">
                                <div class="basis-1/2 flex flex-column">
                                    <div class="basis-2/5">
                                        <h4 style="font-weight: 600;" class="mr-14">Consigner :</h4>
                                    </div>
                                    <div class="basis-3/5">
                                        {{item.consigner}}
                                    </div>
                                </div>
                                <div class="basis-1/2 flex flex-column">
                                    <div class="basis-2/5">
                                        <h4 style="font-weight: 600;">ผู้ตรวจล่าสุด :</h4>
                                    </div>
                                    <div class="basis-3/5">
                                        {{item.lastVerify?item.lastVerify:'-'}}
                                    </div>
                                </div>
                            </div>
                            <!-- line 4 -->
                            <div class="flex flex-row px-10 pt-5">
                                <div class="basis-1/2 flex flex-column">
                                    <div class="basis-2/5">
                                        <h4 style="font-weight: 600;" class="mr-14">ผู้โอนงาน :</h4>
                                    </div>
                                    <div class="basis-3/5">
                                        {{item.lastTransfer?item.lastTransfer:'-'}}
                                    </div>
                                </div>
                            </div>
                                </div>

                        <div class="flex flex-row justify-center pt-5">
                            <button class="set-btn-unlimited flex-none" style="width: 200px" mat-raised-button
                                color="accent" (click)="sendWaitingData(item)">ดำเนินการต่อ</button>
                        </div>
                    </section>
                </div>
            </div>
        </ng-container>
    </ng-container>


    <!--งานจากการรอตรวจโรงงาน-->
    <ng-container *ngIf="queueDataLists.length > 0">
        <div class="flex justify-items-stretch flex-row px-20">
            <h2 class="text-3xl font-normal ">งานจากการรอตรวจโรงงาน</h2>
        </div>
        <ng-container *ngFor="let item of queueDataLists">
            <div style="gap: 30px;" class="card flex justify-items-stretch flex-row px-20">
                <div class="flex flex-col flex-1">
                    <!-- <mat-card-header>
                    <h2 class="text-center">รายละเอียดคำขอที่กำลังตรวจสอบ</h2>
                </mat-card-header> -->
                    <section class="text-lg px-4 flex flex-col flex-1 py-4 gap-4">
                        <div>
                            <!-- line 1 -->
                            <div class="flex flex-row px-10 pt-5">
                                <div class="basis-1/2 flex flex-column">
                                    <div class="basis-2/5">
                                        <h4 style="font-weight: 600;" class="mr-14">Request No :</h4>
                                    </div>
                                    <div class="basis-3/5">
                                        {{item.requestNo}}
                                    </div>
                                </div>
                                <div class="basis-1/2 flex flex-column">
                                    <div class="basis-2/5">
                                        <h4 style="font-weight: 600;">ประเภทงาน :</h4>
                                    </div>
                                    <div class="basis-3/5">
                                        {{item.docType}}
                                    </div>
                                </div>
                            </div>
                            <!-- line 2 -->
                            <div class="flex flex-row px-10 pt-5">
                                <div class="basis-1/2 flex flex-column">
                                    <div class="basis-2/5">
                                        <h4 style="font-weight: 600;" class="mr-14">Request Datetime :</h4>
                                    </div>
                                    <div class="basis-3/5">
                                        {{item.requestDateTime | date:'yyyy-MM-dd HH:mm'}}
                                    </div>
                                </div>
                                <div class="basis-1/2 flex flex-column">
                                    <div class="basis-2/5">
                                        <h4 style="font-weight: 600;">สถานะ :</h4>
                                    </div>
                                    <div class="basis-3/5">
                                        {{item.docPriority}}
                                    </div>
                                </div>
                            </div>

                            <!-- line 3 -->
                            <div class="flex flex-row px-10 pt-5">
                                <div class="basis-1/2 flex flex-column">
                                    <div class="basis-2/5">
                                        <h4 style="font-weight: 600;" class="mr-14">Verify Datetime :</h4>
                                    </div>
                                    <div class="basis-3/5">
                                        {{item.verifyDateTime | date:'yyyy-MM-dd HH:mm'}}
                                    </div>
                                </div>
                                <div class="basis-1/2 flex flex-column">
                                    <div class="basis-2/5">
                                        <h4 style="font-weight: 600;">Declarator :</h4>
                                    </div>
                                    <div class="basis-3/5">
                                        {{item.declarator}}
                                    </div>
                                </div>
                            </div>
                            <!-- line 4 -->
                            <div class="flex flex-row px-10 pt-5">
                                <div class="basis-1/2 flex flex-column">
                                    <div class="basis-2/5">
                                        <h4 style="font-weight: 600;" class="mr-14">Consigner :</h4>
                                    </div>
                                    <div class="basis-3/5">
                                        {{item.consigner}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-row justify-center pt-5">
                            <button class="set-btn-unlimited flex-none" style="width: 200px" mat-raised-button
                                color="accent" (click)="sendWaitingData(item)">ดำเนินการต่อ</button>
                        </div>
                    </section>
                </div>
            </div>
        </ng-container>
    </ng-container>

</div>