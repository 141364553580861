<mat-card>
    <div *ngIf="formData.docType != null">
        <mat-card-header>
            <div class="flex justify-between" style="width: 100%;">
                <div class="text-lg"> รายละเอียดคำขอ </div>
                <div>
                    <button class="close-button" [mat-dialog-close]="true">
                        <mat-icon class="text-white" [mat-dialog-close]>cancel</mat-icon>
                    </button>
                </div>
            </div>
        </mat-card-header>

        <mat-dialog-content class="mat-typography text-lg mr-10 ml-10" style="color: black;">
            <!-- Line 1-->
            <div class="flex flex-row text-lg">
                <div class="w-1/2 flex flex-row ml-10">
                    <div class="w-2/6 flex flex-row">
                        <h4>เลขที่คำขอ : </h4>
                    </div>   
                    <div class="flex-auto flex-row">
                        <h4> {{formData.requestNo}}</h4>
                    </div>                    
                </div>
                <div class= "w-1/2 flex flex-row ml-10">
                    <div class="w-2/6 flex flex-row">
                        <h4>เลขที่ THT : </h4>
                    </div>  
                    <div class="flex-autoflex-row">
                        <h4>{{formData.certNo}}</h4>
                    </div>  
                </div>
            </div>
            <!-- Line 2-->
            <div class="flex flex-row text-lg">
                <div class="w-1/2 flex flex-row ml-10">
                    <div class="w-2/6 flex flex-row">
                        <h4>ชื่อผู้ยื่นคำขอ :</h4>
                    </div> 
                    <div class="flex-auto flex-row">
                        <h4>{{formData.requestBy}}</h4>
                    </div>                       
                </div>
                <div class= "w-1/2 flex flex-row ml-10">
                    <div class="w-2/6 flex flex-row">
                        <h4>ชื่อผู้ส่งออกสินค้า : </h4>
                    </div>  
                    <div class="flex-auto flex-row">
                        <h4>{{formData.consigneeName}}</h4>
                    </div>  
                </div>
            </div>
              <!-- Line 3-->
              <div class="flex flex-row text-lg">
                <div class="w-1/2 flex flex-row ml-10">
                    <div class="w-2/6 flex flex-row">
                        <h4>เลขที่เอกสาร :</h4>
                    </div>   
                    <div class="flex-auto flex-row">
                        <h4> {{formData.documentReferenceNo}}</h4>
                    </div>                     
                </div>
                <div class= "w-1/2 flex flex-row ml-10">
                    <div class="w-2/6 flex flex-row">
                        <h4>ประเภทคำขอ : </h4>
                    </div>  
                    <div class="flex-auto flex-row">
                        <h4>{{formData.docType}}</h4>
                    </div> 
                </div>
            </div>
        </mat-dialog-content>
         <!-- Paragrah 2 -->
        <div class="ml-10 mr-10 mb-10">
        <mat-card class="flex flex-col gap-4">
            <mat-card-header>
                <div class="text-center">หมายเหตุในการขอปลดล็อกการพิมพ์</div>
            </mat-card-header>
            <mat-card-content>
                <div class="w-full flex flex-row">
                    <div class="w-1/6 flex flex-col ml-10">
                        <h4>หมายเหตุ : </h4>
                    </div>
                    <mat-form-field class="flex-auto flex-row">
                        <input autocomplete="off" type="text" matInput matInput #input [(ngModel)]="formData.remark"
                            name="remark" #remark="ngModel">
                    </mat-form-field>
                </div>

                <div class="w-full flex flex-row">
                    <div class="w-1/6 flex flex-col ml-10">
                        <h4>หมายเหตุจากเจ้าหน้าที่ : </h4>
                    </div>
                    <div class="flex-auto flex-col">
                    <p>{{formData.verifyRemark}}</p>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
        </div>
        <!-- Paragrah 3 -->
        <div class="ml-10 mr-10 mb-10">
            <mat-card class="flex flex-col gap-4">
                <mat-card-header>
                    <div class="text-center">จดหมายชี้แจงหรือภาพถ่ายเอกสารเสียหาย</div>
                </mat-card-header>
                <mat-card-content>
                    <div class="w-full flex flex-row">
                        <div class="w-1/6 flex flex-col ml-10">
                            <h4>ลำดับ : </h4>
                        </div>
                        <div class="flex-auto flex-col">
                            <p>{{formData.pic}} </p>
                        </div>
                    </div>

                    <div class="w-full flex flex-row">
                        <div class="w-1/6 flex flex-col ml-10">
                            <h4>หมายเหตุ : </h4>
                        </div>
                        <mat-form-field  class="flex-auto flex-row">
                            <input autocomplete="off" type="text" matInput matInput #input [(ngModel)]="formData.attachRemark"
                                name="attachRemark" #attachRemark="ngModel">
                        </mat-form-field>
                    </div>
                </mat-card-content>
            </mat-card>
            </div>


        <div class="flex flex-row justify-center my-3 gap-4">
            <button class="set-btn-unlimited flex-none" mat-raised-button color="accent" (click)="save()">บันทึก</button>
            <button class="set-btn-unlimited flex-none" mat-raised-button color="accent"
                (click)="deleteItem()">ย้อนกลับ</button>
        </div>
   </div>
</mat-card>    