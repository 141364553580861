import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AppBankService } from 'app/business/service/app/app-bank.service';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { ConfirmPopupComponent } from '../../confirm-popup/confirm-popup.component';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'
@Component({
  selector: 'app-country-manage-popup',
  templateUrl: './country-manage-popup.component.html',
  styleUrls: ['./country-manage-popup.component.css']
})
export class CountryManagePopupComponent implements OnInit {
  form: any = {};
  bankList: any;
  landMassList: any[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef: MatDialogRef<CountryManagePopupComponent>,
    private appMasterService: AppMasterService,
    private dialog: MatDialog,
    private alertMessageService: AlertMessageService
  ) {
    if (data) {
      this.form = data;
    } else {
      this.form.isActive = 'Y';
      this.form.landmass = '';
    }
  }
  ngOnInit(): void {
    this.landMassList = [
      {
        "landMassCode": "",
        "landMassName": "กรุณาเลือก"
      },
      {
        "landMassCode": "AF",
        "landMassName": "AF - Africa"
      },
      {
        "landMassCode": "AN",
        "landMassName": "AN - Antarctic"
      },
      {
        "landMassCode": "AS",
        "landMassName": "AS - Asia"
      },
      {
        "landMassCode": "AU",
        "landMassName": "AU - Australia"
      },
      {
        "landMassCode": "EU",
        "landMassName": "EU - Europe"
      },
      {
        "landMassCode": "NA",
        "landMassName": "NA - North America"
      },
      {
        "landMassCode": "SA",
        "landMassName": "SA - South America"
      }
    ]
  }

  save() {
    if ((this.form.countryNameEn !== undefined && this.form.countryNameEn !== null && this.form.countryNameEn !== "") && (this.form.countryNameTh !== undefined && this.form.countryNameTh !== null && this.form.countryNameTh !== "") && (this.form.landmass !== undefined && this.form.landmass !== null && this.form.landmass !== "")) {
      const data = {
        "countryId": this.form.countryId,
        "countryNameEn": this.form.countryNameEn,
        "countryNameTh": this.form.countryNameTh,
        "landmass": this.form.landmass,
        "isDestinationCountry": this.form.isDestinationCountry,
        "isCountryOfOrigin": this.form.isCountryOfOrigin,
        "isActive": this.form.isActive
      };

      this.appMasterService.saveCountry(data).then((res: any) => {
        if (res === "error") {
          this.alertMessageService.open({message: "บันทึกข้อมูลไม่สำเร็จ", title:""});
        } else {
          this.dialogRef.close();
          this.alertMessageService.open({message: "บันทึกข้อมูลสำเร็จ", title:""});
        }
      });

    } else {
      this.alertMessageService.open({ message: "กรุณากรอกข้อมูลให้ครบ", title: "ข้อผิดพลาด" });
    }
  }

  onChange(e) {
    if (e.checked == true) {
      this.form.isActive = "Y";
    } else {
      this.form.isActive = "N";
    }
  }

  onChangeOrigin(e) {
    if (e.checked == true) {
      this.form.isCountryOfOrigin = "Y";
    } else {
      this.form.isCountryOfOrigin = "N";
    }
  }

  onChangeDestination(e) {
    if (e.checked == true) {
      this.form.isDestinationCountry = "Y";
    } else {
      this.form.isDestinationCountry = "N";
    }
  }

  endPopup(e) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      disableClose: false,
      // width: '30vw',
      data: {
        header: e,
        button: 'close'
      },
      maxWidth: 'none'
    });

    dialogRef.afterClosed().subscribe(res => {
      this.dialogRef.close();
    });
  }
}
