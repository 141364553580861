import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service';
import { FormHelperService } from '@bundle/service/form-helper.service';
import { IApiSaveUserRoleRequest } from 'app/business/interface/api/api-user.interface';
import { AppUserService } from 'app/business/service/app/app-user.service';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
  selector: 'app-user-role-modal',
  templateUrl: './user-role-modal.component.html',
  styleUrls: ['./user-role-modal.component.scss']
})
export class UserRoleModalComponent implements OnInit {
  dataUserRole: any;
  dataForm: any;
  userProfile: any = {}
  userToken:any;
  
  constructor(
    private appUserService: AppUserService,
    private alertService: AlertMessageService,
    private formHelper: FormHelperService,
    private dialogRef: MatDialogRef<UserRoleModalComponent>,
    private _authService:AuthService,
    @Inject(MAT_DIALOG_DATA) data: any,
  ) {
    
    this.dataUserRole = data;
   }

  ngOnInit(): void {
    this.userToken = JSON.parse(
      this._authService.loginUser
    );
    this.userProfile.grpid = this.userToken.userGroupCode
    this.userProfile.cpid = this.userToken.cpId
  }
  userRoleFormSubmit(): void {
    
    if (this.userRoleFormValidate()) {
      const data = {
        userRoleId: this.dataUserRole.userRoleId,
        userRoleCode: this.dataUserRole.userRoleCode,
        roleName: this.dataForm.value.roleName,
        isActive: this.dataForm.value.isActive,
        cpId: this.userProfile.cpid,
        groupCode: this.userProfile.grpid
      } as IApiSaveUserRoleRequest;
      

      this.appUserService.saveUserRole(data).then(res => {
        if (res !== 'error') {
          this.alertService.info({ title: 'การบันทึก', message: 'บันทึกข้อมูลเรียบร้อยแล้ว' });
          this.dialogRef.close(data);
        }
      });
    }

  }

  userRoleFormEmit(event: any): void {
    this.dataForm = event;
    
    
  }
  userRoleFormValidate(): boolean {
    const isValid = this.dataForm.valid;
    this.formHelper.validateForm(this.dataForm);
    return isValid;
  }
}
