<mat-card>
  <mat-card-header>
      <div class="text-center">รายการของการชำระเงิน | List Payment</div>
  </mat-card-header>

  <mat-card-content>
      <section class="flex flex-col flex-1 justify-evenly">
          <dx-data-grid class="mt-3" #gridContainer [dataSource]="dataSource"
              [allowColumnReordering]="true" [showBorders]="true"
              [allowColumnResizing]="true"
              [columnAutoWidth]="true"
              [showBorders]="true">
              <dxo-editing mode="row" [allowUpdating]="false" [allowDeleting]="false">
              </dxo-editing>
              <dxi-column dataField="refNo" caption="Reference Number" >
              </dxi-column>
              <dxi-column dataField="paymentTypeName" caption="Payment Type" >
              </dxi-column>
              <dxi-column dataField="bank" caption="Bank" >
              </dxi-column>
              <dxi-column dataField="reqDate" [format]="'yyyy-MM-dd hh:MM:ss'" dataType="date" caption="Requset Date" >
            </dxi-column>
            <dxi-column dataField="createDate" [format]="'yyyy-MM-dd hh:MM:ss'" dataType="date" caption="Create Date" > 
            </dxi-column>
            <dxi-column dataField="amount" caption="Amount">
              <dxo-format type="fixedPoint" [precision]="3"></dxo-format> 
            </dxi-column>
            <dxi-column dataField="payRespons" caption="Status">
            </dxi-column>
            <dxi-column dataField="createByName" caption="Create By">
            </dxi-column>
          </dx-data-grid>
      </section>
  </mat-card-content>
</mat-card>