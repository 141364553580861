import { Injectable } from "@angular/core";
import { ApiService } from "@bundle/service/api.service";
import { Observable } from "rxjs/internal/Observable";

@Injectable({
  providedIn: "root",
})
export class ApiNewsService {
  constructor(private apiService: ApiService) {}

  getNewsList(data: any): Observable<any> {
    return this.apiService.post("/api/getNewsList", data);
  }

  getCategorymenuList(data: any): Observable<any> {
    return this.apiService.post("/api/getCategorymenuList", data);
  }

  getCategoryNewsist(data: any): Observable<any> {
    return this.apiService.post("/api/getCategoryNewsist", data);
  }

  getSubCategorymenuList(data: any): Observable<any> {
    return this.apiService.post("/api/getSubCategorymenuList", data);
  }
}
