<mat-card>
  <mat-card-header>
    <div class="flex justify-between" style="width: 100%;">
      <div class="text-lg"> ค้นหารรายชือโรงงาน</div>
      <div>
        <button class="close-button" [mat-dialog-close]="true">
          <mat-icon class="text-white" [mat-dialog-close]>cancel</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-header>

  <mat-dialog-content class="mat-typography">
    <div class="flex flex-col justify-center gap-3">
      <div class="px-10 pt-5">
        <div class="flex flex-row gap-3">
          <div class="w-1/6 flex flex-col">
            <h3 class="black-text">รหัสบริษัท</h3>
            <small class="black-text">Company Code</small>
          </div>
          <form name="searchCompanyForm" [formGroup]="searchCompanyForm" class="w-2/6">
            <mat-form-field style="display: flex;flex-flow: row;flex: 1">
              <input matInput type="text" placeholder="รหัสบริษัท/เลขผู้เสียภาษี/ชื่อบริษัท" formControlName="companySearch" [matAutocomplete]="auto" name="companyCode">
              <mat-autocomplete #auto="matAutocomplete" required (optionSelected)="selectionChange($event)"
                [panelWidth]="'auto'">
                <mat-option *ngIf="isLoading">
                  <mat-spinner [diameter]="20"></mat-spinner>
                </mat-option>
                <mat-option *ngFor="let option of filteredOptions$  | async" [value]="option">
                  {{option.display}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </form>
          <!-- 
          <mat-form-field class="w-2/6">
            <input autocomplete="off" type="text" matInput placeholder="รหัสบริษัท" matInput #input
              [(ngModel)]="searchForm.compCode" name="compCode" #compCode="ngModel">
          </mat-form-field> -->
          <div class="w-1/6 flex flex-col">
            <h3 class="black-text">ชื่อบริษัท</h3>
            <small class="black-text">CompanyName</small>
          </div>
          <mat-form-field class="w-2/6">
            <input autocomplete="off" type="text" matInput placeholder="ชื่อบริษัท" matInput #input
              [(ngModel)]="searchForm.companyName" name="companyName" #companyName="ngModel">
          </mat-form-field>
        </div>

        <div class="flex flex-row justify-end pt-5 gap-3">
          <button mat-raised-button color="accent" (click)="clear()" class="w-1/6 ">
            เคลียร์
          </button>
          <button mat-raised-button color="accent" (click)="loadData()" class="w-1/6">
            ค้นหา
          </button>
        </div>
      </div>
      <div class="mat-elevation-z8" style="overflow-x: auto;">
        <table mat-table matSort matSortActive="companyNameEN" matSortDirection="asc" matSortDisableClear
          [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">
          <ng-container matColumnDef="cid" style="text-align: left">
            <th mat-header-cell *matHeaderCellDef class="justify-start text-left">
              รหัส
            </th>
            <td mat-cell *matCellDef="let element" class="justify-start text-left">
              {{ element.cid }}
            </td>
          </ng-container>
          <ng-container matColumnDef="changeUpComDate" style="width: 180px; text-align: left">
            <th mat-header-cell *matHeaderCellDef style="width: 180px;" class="justify-start text-left">
              วันที่สมัครเข้าระบบ
            </th>
            <td mat-cell style="text-align: left" *matCellDef="let element">
              {{ element.changeUpComDate | date:'yyyy-MM-dd HH:mm'}}
            </td>
          </ng-container>
          <ng-container matColumnDef="companyNameTH" style="width: 220px;  text-align: left">
            <th mat-header-cell *matHeaderCellDef style="width: 220px;" class="justify-start text-left">
              ชื่อบริษัท
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.companyNameTH }}
            </td>
          </ng-container>
          <ng-container matColumnDef="companyNameEN" style="width: 220px;  text-align: left">
            <th mat-header-cell *matHeaderCellDef style="width: 220px;">Company Name</th>
            <td mat-cell *matCellDef="let element">
              {{ element.companyNameEN }}
            </td>
          </ng-container>
          <ng-container matColumnDef="addressStreet" style="width: 220px; text-align: left">
            <th mat-header-cell *matHeaderCellDef style="width: 220px;" class="justify-start text-left">
              ที่อยู่
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.addressStreet }}
            </td>
          </ng-container>
          <ng-container matColumnDef="province" style="text-align: left">
            <th mat-header-cell *matHeaderCellDef class="justify-start text-left">
              จังหวัด
            </th>
            <td mat-cell *matCellDef="let element" class="justify-start text-left">
              {{ element.province }}
            </td>
          </ng-container>
          <ng-container matColumnDef="phone" style="width: 150px; text-align: left">
            <th mat-header-cell *matHeaderCellDef style="width: 150px;" class="justify-start text-left">
              เบอร์โทร
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.phone }}
            </td>
          </ng-container>
          <ng-container matColumnDef="blacklist" style="text-align: left">
            <th mat-header-cell *matHeaderCellDef class="justify-start text-left">
              สถานะ
            </th>
            <td mat-cell *matCellDef="let element" class="justify-start text-left">
              {{
              element.blacklist == "Y"
              ? "Lock"
              : element.blacklist == "N"
              ? "Unlock"
              : element.blacklist
              }}
            </td>
          </ng-container>
          <ng-container matColumnDef="grade" style="width: 180px; text-align: left">
            <th mat-header-cell *matHeaderCellDef style="width: 180px;" class=" justify-start text-left">
              ระดับความน่าเชื่อถือ
            </th>
            <td mat-cell *matCellDef="let element" style="width: 180px;" class="justify-start text-left">
              {{
              element.grade == 0
              ? "ยังไม่ออกตรวจ"
              : element.grade == 1
              ? "ไม่ผ่าน"
              : element.grade == 2
              ? "พอใช้"
              : element.grade == 3
              ? "ดี"
              : element.grade == 4
              ? "ดีเยี่ยม"
              : element.grade
              }}
            </td>
          </ng-container>

          <ng-container matColumnDef="action" style="width: 150px;">
            <th mat-header-cell *matHeaderCellDef style="width: 150px; text-align: left">
              Action
            </th>
            <td mat-cell *matCellDef="let element; let i = index" style=" width: 150px; text-align: left">
              <div class="flex flex-row" style="justify-content: start;">
                <button *ngIf="pageSend == 'blacklist'" mat-icon-button matTooltip="รายละเอียด"
                  (click)="getDetailBlacklist(element)">
                  <mat-icon svgIcon="heroicons_outline:document-search"></mat-icon>
                </button>
                <button *ngIf="pageSend == 'factory'" mat-icon-button matTooltip="รายละเอียด"
                  (click)="getDetailFactory(element)" matTooltipPosition="above">
                  <mat-icon svgIcon="heroicons_outline:document-search"></mat-icon>
                </button>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons
          aria-label="Select page of periodic elements" (page)="pageChanged($event)">
        </mat-paginator>
      </div>
    </div>
  </mat-dialog-content>
</mat-card>