<div style="display: flex;flex-flow: column;height: 100%;gap: 30px;">

    <div style="display: flex;flex-flow: column;margin-top: 30px;" class="pl-10 pr-10">
        <div class="menu">
            <div style="display: flex;flex-flow: row;gap: 10px;align-items: stretch;">
                <div *ngIf="checkButton('BT042') && (!isBlacklisted)">
                    <button mat-raised-button color="accent"
                        (click)="CreateReprint();   ">
                        <div class="flex items-center">
                            <span>สร้างคำร้องพิมพ์ซ้ำ</span>
                        </div>
                    </button>
                </div>
                <div class="gap-2.5 flex flex-row">
                    <button mat-raised-button color="accent" (click)="onRefresh()">
                      <div class="flex items-center">
                        <span class="icon-text"><mat-icon svgIcon="heroicons_outline:refresh"></mat-icon>รีเฟรชข้อมูล</span>
                      </div>
                    </button>
                </div>
            </div>
            <div style="display: flex;flex-flow: row;gap: 10px;flex: 1;justify-content: flex-end;">
                <div class="mat-button" style="gap: 10px;flex: 0 40%;">
                    <mat-form-field style="flex: 1" class="my-form-field flex flex-row">
                        <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" matInput #input
                            [(ngModel)]="form.search" name="search" #search="ngModel" (keyup)="applyFilter($event)">
                        <button matSuffix mat-icon-button aria-label="ค้นหา">
                            <mat-icon>search</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
                <button mat-raised-button color="accent" [matMenuTriggerFor]="menu">
                    <div style="display: flex;align-items: center;">
                        <span>เงื่อนไขการค้นหา</span>
                        <mat-icon>expand_more</mat-icon>
                    </div>
                </button>
                <mat-menu #menu="matMenu" xPosition="before" class="my-menu">
                    <div style="display: flex;flex-flow: column;gap: 15px;" class="pl-2 pr-2"
                        (click)="stopPropagation($event)">
                        <div class="mat-button p-2" style="gap: 10px;">
                            <mat-form-field appearance="fill" style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>วันที่เริ่มต้น | Start Date</mat-label>
                                    <input matInput [matDatepicker]="startDate" (click)="startDate.open()" readonly
                                        type="datetime" [(ngModel)]="searchForm.startDate" [max]="myDate"
                                        name="startDateSearch" (dateInput)="datecheck($event)"
                                        #startDateSearch="ngModel" class="cursor-pointer">
                                <mat-hint>MM/DD/YYYY</mat-hint>
                                <mat-datepicker-toggle matIconSuffix [for]="startDate"></mat-datepicker-toggle>
                                <mat-datepicker #startDate></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field appearance="fill" style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>วันที่สิ้นสุด | End Date</mat-label>
                                <input matInput [matDatepicker]="endDate"
                                    (click)="endDate.open(); checkEndDate = true" readonly type="datetime"
                                    [(ngModel)]="searchForm.endDate" [max]="myDate" [min]="searchForm.startDate"
                                    (dateInput)="endDateCheck($event)" name="endDateSearch" #endDateSearch="ngModel"
                                    class="cursor-pointer">
                                <mat-hint>MM/DD/YYYY</mat-hint>
                                <mat-datepicker-toggle matIconSuffix [for]="endDate"></mat-datepicker-toggle>
                                <mat-datepicker #endDate></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="mat-button p-2" style="gap: 10px;">
                            <mat-form-field style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>เลขที่ใบคำขอ | Request No.</mat-label>
                                <input autocomplete="off" type="text" matInput placeholder="เลขที่ใบคำขอ" matInput
                                    #input [(ngModel)]="searchForm.requestNo" name="requestNo" #requestNo="ngModel">
                            </mat-form-field>
                            <mat-form-field style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>เลขที่ THT | Certificate No.</mat-label>
                                <input autocomplete="off" type="text" matInput placeholder="เลขที่ THT" matInput #input
                                    [(ngModel)]="searchForm.certificateNo" name="certificateNo"
                                    #certificateNo="ngModel">
                            </mat-form-field>
                        </div>
                        <div class="mat-button p-2" style="gap: 10px;">
                            <mat-form-field style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>เลขที่ใบกำกับสินค้า/ เลขที่เอกสาร | Invoice No./ Reference No.</mat-label>
                                <input autocomplete="off" type="text" matInput placeholder="เลขที่ใบกำกับ/ เลขที่เอกสาร"
                                    matInput #input [(ngModel)]="searchForm.invoiceReference" name="invoiceReference"
                                    #invoiceReference="ngModel">
                            </mat-form-field>
                        </div>
                
                        <div class="flex justify-end pt-2">
                            <div>
                                <button mat-raised-button (click)="clearOption()"
                                    style="flex:0 50%; margin-right: 10px;">
                                    เคลียร์
                                </button>
                                <button mat-raised-button color="accent" (click)="saveSearch()" style="flex:0 50%;">
                                    ค้นหา
                                </button>
                            </div>
                        </div>
                        <!-- <div class="mat-button" style="gap: 10px;justify-content: flex-end;">
                            <button mat-raised-button color="accent" style="flex: 0 50%;"
                                (click)="saveSearch()">ค้นหา</button>
                        </div> -->
                    </div>
                </mat-menu>
            </div>
        </div>
    </div>

    <div class="mat-elevation-z8 ">
        <table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">

            <!-- Position Column -->
            <!-- bankAccountNo Column -->

            <ng-container matColumnDef="No">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> ลำดับที่ </th>
                <td mat-cell *matCellDef="let i = index"> {{calculateIndex(i)}} </td>
            </ng-container>

            <ng-container matColumnDef="rqNo">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> RQ No. </th>
                <td mat-cell *matCellDef="let element"> {{element.rqNo}} </td>
            </ng-container>

            <ng-container matColumnDef="docType">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> ประเภทเอกสาร </th>
                <td mat-cell *matCellDef="let element"> {{element.docType}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="refNo">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> Invoice No./ Reference No.
                </th>
                <td mat-cell *matCellDef="let element" > {{element.refNo}} </td>
            </ng-container>

            <ng-container matColumnDef="requestDate">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> วัน/เวลาที่ยื่น </th>
                <td mat-cell *matCellDef="let element" >
                    {{element.requestDate | date:'yyyy-MM-dd'}}<br> 
                    {{element.requestDate | date:'HH:mm'}} </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="thtNo">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> เลขที่ THT </th>
                <td mat-cell *matCellDef="let element" > {{element.thtNo}} </td>
            </ng-container>

            <ng-container matColumnDef="rqStatusName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> สถานะคำขอ </th>
                <td mat-cell *matCellDef="let element" >
                    {{element.rqStatusName}} </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="text-left"> Action </th>
                <td mat-cell *matCellDef="let element;let i = index">
                    <div class="flex flex-row" style="justify-content: start;">
                                <!-- <button *ngIf="element.docType == 'CF'" mat-icon-button matTooltip="รายละเอียด"
                                    (click)="showDetail(element)" routerLink="/page/request/request-cf/create-cf"
                                    [queryParams]="{
                                        id: element.docId,
                                        disabled: true
                                        }">
                                    <mat-icon svgIcon="heroicons_outline:document-search"></mat-icon>
                                </button>
                                <button *ngIf="element.docType == 'CO'" mat-icon-button matTooltip="รายละเอียด"
                                    (click)="showDetail(element)" routerLink="/page/request/request-co/create-co"
                                    [queryParams]="{
                                        id: element.docId,
                                        disabled: true,
                                        docId:element.docId
                                        }">
                                    <mat-icon svgIcon="heroicons_outline:document-search"></mat-icon>
                                </button> -->
                            <div *ngIf="checkButton('BT043')">
                                <button mat-icon-button matTooltip="แก้ไข" (click)="editItem(element)" *ngIf="element.rqStatusId == 15">
                                    <mat-icon svgIcon="heroicons_outline:pencil-alt"></mat-icon>
                                </button>
                            </div>
                            <div *ngIf="checkButton('BT183')">
                                <button mat-icon-button matTooltip="รายละเอียด" matTooltipPosition="above"
                                (click)="showDetail(element)">
                                    <mat-icon svgIcon="heroicons_outline:document-text"></mat-icon>
                                </button>
                            </div>
                            <div *ngIf="checkButton('BT180')">
                                <button mat-icon-button matTooltip="พิมพ์" (click)="print(element)"
                                    matTooltipPosition="above"
                                    *ngIf="element.rqStatusId == 18">
                                    <mat-icon svgIcon="heroicons_outline:printer"></mat-icon>
                                </button>
                            </div>
                            <div *ngIf="checkButton('BT181')">
                                <button mat-icon-button matTooltip="เปลี่ยน" (click)="openPopupSendPrintTostaff(element)"
                                    matTooltipPosition="above"
                                    *ngIf="element.selfPrint == 'Y' && element.rqStatus == '6'">
                                    <mat-icon svgIcon="mat_outline:local_post_office"></mat-icon>
                                </button>
                            </div>
                            <div *ngIf="checkButton('BT182')">
                                <button mat-icon-button matTooltip="ลบ" (click)="deleteItem(element)"
                                    matTooltipPosition="above"
                                    (keydown.enter)="$event.preventDefault()"  *ngIf="element.rqStatusId == 15">
                                    <mat-icon svgIcon="heroicons_outline:trash"></mat-icon>
                                </button>
                            </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons
            aria-label="Select page of periodic elements">
        </mat-paginator>
    </div>
</div>