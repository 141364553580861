import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core'
import { MatDialog } from '@angular/material/dialog';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service';
import { AttrachPic, MarkError } from 'app/business/interface/app/app-co.interface';
import { AppVerifyService } from 'app/business/service/app/app-verify.service';
import { VerifyImageRemarkComponent } from '../verify-image-remark/verify-image-remark.component';

@Component({
  selector: 'app-verify-image',
  templateUrl: './verify-image.component.html',
  styleUrls: ['./verify-image.component.scss'],
})
export class VerifyImageComponent implements OnInit {
  ErrorList: MarkError[] = []
  @Input() _attachmentList: any = []
  @Input()
  set attachmentList(value) {
    this._attachmentList = value.map(e => {
      return {
        ...e,
        isSelected: false
      }
    });
  }
  get attachmentList(): any {
    return this._attachmentList;
  }
  @Output() attachment: EventEmitter<AttrachPic> = new EventEmitter<AttrachPic>();
  constructor(
    private appVerifyService: AppVerifyService,
    private alertMessage: AlertMessageService,
    private dialog: MatDialog
  ) {
    this.appVerifyService.errorList.subscribe(res => {
      this.ErrorList = res;
    });
  }

  ngOnInit(): void {

  }
  ngOnChanges(changes: SimpleChanges) {
  }
  myFunction(e) {

    e.isSelected = true;
    this._attachmentList.forEach(el => {
      if (el.attachFilePic != e.attachFilePic) {
        el.isSelected = false;
      }
    });

    this.attachment.emit(e);
  }

  getStyle(selected: boolean) {
    if (selected) {
      return "max-width: 200px;cursor: pointer;border-width: 5px;border-style: solid;border-color: #E6222B;"
    } else {
      return "max-width: 200px;cursor: pointer;border-width: 5px;border-style: solid;"
    }
  }

  getNameStyle(e) {
    if (e === "Y") {
      return "color: red;"
    }
  }

  openRemark(e) {
    this.dialog.open(VerifyImageRemarkComponent, {
      disableClose: false,
      hasBackdrop: false,
      width: '30vw',
      data: e
    })
  }
}
