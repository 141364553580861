import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "@bundle/service/api.service";

@Injectable({
  providedIn: "root",
})
export class ApiMasterService {
  constructor(private apiService: ApiService) {}

  login(data: any): Observable<any> {
    return this.apiService.post("/api/LoginTcc", data);
  }

  logout(): Observable<any> {
    return this.apiService.post("/api/logout", {});
  }

  getTestList(any): Observable<any[]> {
    return this.apiService.post("/api/getMtProvinceList", any);
  }

  getListCountry(request: any): Observable<any[]> {
    return this.apiService.post("/api/getListCountry", request);
  }

  getListDeclarator(request: any): Observable<any[]> {
    return this.apiService.post("/api/getListDeclarator", request);
  }

  getListBranch(request: any): Observable<any[]> {
    return this.apiService.post("/api/getListBranch", request);
  }

  getListDoc(): Observable<any[]> {
    return this.apiService.get("/api/getListDoc");
  }

  getListNameLastName(request: any): Observable<any[]> {
    return this.apiService.post("/api/CO/getListNameLastname", request);
  }

  getAuthorizedPersonList(request: any): Observable<any[]> {
    return this.apiService.post("/api/CO/getListNameLastname", request);
  }

  getListVesselType(request: any): Observable<any[]> {
    return this.apiService.post("/api/CO/getListVesselType", request);
  }

  getListUnit(request: any): Observable<any[]> {
    return this.apiService.post("/api/getListUnit", request);
  }
  getListWeight(request: any): Observable<any[]> {
    return this.apiService.post("/api/getListWeight", request);
  }

  getListCfDocSubtitle(request: any): Observable<any[]> {
    return this.apiService.post("/api/getListCfDocSubtitle", request);
  }

  saveCfDocSubtitle(request: any): Observable<any []> {
    return this.apiService.post("/api/saveCfDocSubtitle", request);
  }

  getListCountryManage(request: any): Observable<any[]> {
    return this.apiService.post("/api/getListCountryManage", request);
  }

  saveCountry(request: any): Observable<any []> {
    return this.apiService.post("/api/saveCountry", request);
  }

  getListCountryGroupManage(request: any): Observable<any[]> {
    return this.apiService.post("/api/getListCountryGroupManage", request);
  }

  saveCountryGroup(request: any): Observable<any []> {
    return this.apiService.post("/api/saveCountryGroup", request);
  }

  getListCoBranchManage(request: any): Observable<any[]> {
    return this.apiService.post("/api/getListCoBranchManage", request);
  }

  saveCoBranch(request: any): Observable<any []> {
    return this.apiService.post("/api/saveCoBranch", request);
  }


  saveUnit(request: any): Observable<any[]> {
    return this.apiService.post("/api/saveUnit", request);
  }
  saveWeight(request: any): Observable<any[]> {
    return this.apiService.post("/api/saveWeight", request);
  }
  getUserAddress(request: any): Observable<any[]> {
    return this, this.apiService.post("/api/getUserAddress", request);
  }

  getUserManage(request: any): Observable<any[]> {
    return this.apiService.post("/api/getUserManage", request);
  }

  getListAttachType(request: any): Observable<any[]> {
    return this.apiService.post("/api/getListAttachTypePost", request);
  }

  getListCfDocType(request: any): Observable<any[]> {
    return this.apiService.post("/api/getListCfDocType", request);
  }

  saveAttachType(request: any): Observable<any[]> {
    return this.apiService.post("/api/saveAttachType", request);
  }

  saveCfDocType(request: any): Observable<any[]> {
    return this.apiService.post("/api/saveCfDocType", request);
  }
  getExchangeRate(request: any): Observable<any[]> {
    return this.apiService.post("/api/mst/exchangerate", request);
  }

  saveExchangeRate(request: any): Observable<any[]> {
    return this.apiService.post("/api/saveExchangeRate", request);
  }

  getHarmonizedCode(request: any): Observable<any[]> {
    return this.apiService.post("/api/getHarmonizedCode", request);
  }

  exchangeratemaxdate(request: any): Observable<any[]> {
    return this.apiService.post("/api/mst/exchangeratemaxdate", request);
  }

  logOutTcc(req): Observable<any> {
    return this.apiService.post("/api/logOutTcc", req);
  }

  getMarkType(request: any): Observable<any[]> {
    return this.apiService.post("/api/getMarkType", request);
  }

  getFieldType(request: any): Observable<any[]> {
    return this.apiService.post("/api/getFieldType", request);
  }
  getMarkDetail(request: any): Observable<any[]> {
    return this.apiService.post("/api/getMarkDetail", request);
  }
  getMasterBankAccount(request: any): Observable<any[]> {
    return this.apiService.post("/api/getMasterBankAccount", request);
  }
  getMasterFeeRateList(request: any): Observable<any[]> {
    return this.apiService.post("/api/getMasterFeeRateList", request);
  }
  getMasterStatus(request: any): Observable<any[]> {
    return this.apiService.post("/api/getMasterStatus", request);
  }

  getListCompany(): Observable<any>{
    return this.apiService.get("/api/co/getListCompany")
  }

  getMasterProvince(request: any): Observable<any> {
    return this.apiService.post("/api/getMasterProvince", request);
  }

  updateUnitBatch(request: any): Observable<any> {
    return this.apiService.post("/api/updateUnitBatch", request);
  }

  updateWeightBatch(request: any): Observable<any> {
    return this.apiService.post("/api/updateWeightBatch", request);
  }

  updateAttachTypeBatch(request: any): Observable<any> {
    return this.apiService.post("/api/updateAttachTypeBatch", request);
  }

  updateCfDocTypeBatch(request: any): Observable<any> {
    return this.apiService.post("/api/updateCfDocTypeBatch", request);
  }

  updateCfSubtitleBatch(request: any): Observable<any> {
    return this.apiService.post("/api/updateCfSubtitleBatch", request);
  }

  updateCountryBatch(request: any): Observable<any> {
    return this.apiService.post("/api/updateCountryBatch", request);
  }

  getListMstStatus(): Observable<any> {
    return this.apiService.post("/api/getListMstStatus", null);
  }
  getListMstGroupOfCountry(): Observable<any> {
    return this.apiService.post("/api/getListMstGroupOfCountry", null);
  }
  getListMstCoBranch(): Observable<any> {
    return this.apiService.post("/api/getListMstCoBranch", null);
  }
  getListMstAttachType(): Observable<any> {
    return this.apiService.post("/api/getListMstAttachType", null);
  }
  getListMstCountry(): Observable<any> {
    return this.apiService.post("/api/getListMstCountry", null);
  }

  saveFee(request: any): Observable<any> {
    return this.apiService.post("/api/saveFee", request);
  }

  updateFeeBatch(request: any): Observable<any> {
    return this.apiService.post("/api/updateFeeBatch", request);
  }

  getMasterBankCode(request: any): Observable<any> {
    return this.apiService.post("/api/getMasterBankCode", request);
  }

  saveBankCode(request: any): Observable<any> {
    return this.apiService.post("/api/saveBankCode", request);
  }

  updateBankCodeBatch(request: any): Observable<any> {
    return this.apiService.post("/api/updateBankCodeBatch", request);
  }

  saveProvince(request: any): Observable<any> {
    return this.apiService.post("/api/saveProvince", request);
  }

  updateProvinceBatch(request: any): Observable<any> {
    return this.apiService.post("/api/updateProvinceBatch", request);
  }

  getMasterDistrictList(request: any): Observable<any> {
    return this.apiService.post("/api/getMasterDistrictList", request);
  }

  saveDistrict(request: any): Observable<any> {
    return this.apiService.post("/api/saveDistrict", request);
  }

  updateDistrictBatch(request: any): Observable<any> {
    return this.apiService.post("/api/updateDistrictBatch", request);
  }

  getMasterSubDistrictList(request: any): Observable<any> {
    return this.apiService.post("/api/getMasterSubDistrictList", request);
  }

  saveSubDistrict(request: any): Observable<any> {
    return this.apiService.post("/api/saveSubDistrict", request);
  }

  updateSubDistrictBatch(request: any): Observable<any> {
    return this.apiService.post("/api/updateSubDistrictBatch", request);
  }

  getMasterKindOfExportationList(request: any): Observable<any> {
    return this.apiService.post("/api/getMasterKindOfExportationList", request);
  }

  saveMasterKindOfExportation(request: any): Observable<any> {
    return this.apiService.post("/api/saveMasterKindOfExportation", request);
  }

  updateKindExportBatch(request: any): Observable<any> {
    return this.apiService.post("/api/updateKindExportBatch", request);
  }

  getMarkTypeHeaderList(request: any): Observable<any> {
    return this.apiService.post("/api/getMarkTypeHeaderList", request);
  }

  getMasterFieldType(request: any): Observable<any> {
    return this.apiService.post("/api/getMasterFieldType", request);
  }

  saveMarktypeHeader(request: any): Observable<any> {
    return this.apiService.post("/api/saveMarkTypeHeader", request);
  }

  getMarktypeDetailList(request: any): Observable<any> {
    return this.apiService.post("/api/getMarktypeDetailList", request);
  }

  saveMarktypeDetail(request: any): Observable<any> {
    return this.apiService.post("/api/saveMarktypeDetail", request);
  }

  getCompanyAddressType(request: any): Observable<any> {
    return this.apiService.post("/api/getCompanyAddressType", request);
  }

  getMrConnectParameter(request: any): Observable<any> {
    return this.apiService.post("/api/getMrConnectParameter", {
      cpKeyword: request
    });
  }

  saveMrConnectParameter(request: any): Observable<any> {
    return this.apiService.post("/api/saveMrConnectParameter", request);
  }

  getHarmonizedItemList(request: any): Observable<any> {
    return this.apiService.post("/api/getHarmonizedItemList", request);
  }

  saveHarmonizedItem(request: any): Observable<any> {
    return this.apiService.post("/api/saveHarmonizedItem", request);
  }

  getTaskToVerifyList(): Observable<any> {
    return this.apiService.post("/api/getTaskToVerifyList", null);
  }
  getMstConfigByHCode(request: any): Observable<any[]> {
      return this.apiService.post("/api/getMstConfigByHCode", request);
  }
  getMstConfigByHCodeApprove(request: any): Observable<any[]> {
      return this.apiService.post("/api/getMstConfigByHCodeApprove", request);
  }

  getMarkTypeListByType(request: any): Observable<any> {
    return this.apiService.post("/api/getMarkTypeListByType", request);
  }

  getMasterStatusList(request: any): Observable<any> {
    return this.apiService.post("/api/getMasterStatusList", {
      type: request
    });
  }
  getValidateInput(request: any): Observable<any> {
    return this.apiService.post("/api/getValidateInput",request);
  }
  getIsPaymentByCompany(request: any): Observable<any> {
    return this.apiService.post("/api/getIsPaymentByCompany",request);
  }

  getActionStatus(request: any): Observable<any> {
    return this.apiService.post("/api/getActionStatus",request);
  }

  getCompanyExporterByShipping(request: any): Observable<any> {
    return this.apiService.post("/api/getCompanyExporterByShipping",request);
  }

  getConfigDByHCode(request: any): Observable<any> {
    return this.apiService.post("/api/getConfigDByHCode",request);
  }
  getListMstGroupProduct(): Observable<any[]> {
    return this.apiService.get("/api/getListMstGroupProduct");
  }
}
