<mat-card style="width: 100%;"> 
  <mat-card-header>
    <div class="flex justify-between" style="width: 100%;">
      <div class="text-lg">รายการเพิ่มเติม | Other Payment</div>
      <div>
        <button class="close-button" [mat-dialog-close]="true">
          <mat-icon class="text-white">cancel</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-dialog-content>
    <mat-tab-group (selectedTabChange)="tabChanged($event)" mat-stretch-tabs="false" mat-align-tabs="start">
      <mat-tab label="Other List">
        <div class="flex flex-col py-5 gap-4">
          <div>
            <div class="flex flex-row">
              <div class="basis-1/3">รายการค่าธรรมเนียม : </div>
              <div class="basis-2/3">
                <mat-form-field class="w100">
                  <mat-select [(ngModel)]="form.fee" (selectionChange)="selectionFeeRate(form.fee)" name="fee">
                    <mat-option *ngFor="let fee of feeRateList" [value]="fee">
                      {{fee.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div>
            <div class="flex flex-row">
              <div class="basis-1/3">จำนวน : </div>
              <div class="basis-2/3">
                <mat-form-field class="w100">
                  <input autocomplete="off" required type="number" matInput placeholder="จำนวน" #input
                    [(ngModel)]="form.qty" [min]="1" name="qty" #netQty="ngModel">
                </mat-form-field>
              </div>
            </div>
          </div>
          <div>
            <div class="flex flex-row">
              <div class="basis-1/3">หมายเหตุ : </div>
              <div class="basis-2/3">
                <mat-form-field class="w100">
                  <textarea autocomplete="off" type="text" matInput placeholder="หมายเหตุ" #input
                    [(ngModel)]="form.remark" name="remark" #remark="ngModel">
                  </textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Reprint List">
        <mat-dialog-content class="p-0">
          <dx-data-grid #gridContainer [dataSource]="dataSource" [allowColumnReordering]="true" [showBorders]="true"
            (onSelectionChanged)="selectionChanged($event)">
            <dxo-selection mode="multiple"></dxo-selection>
            <dxi-column dataField="rqNo" [width]="150" caption="เลขที่คำขอ">
            </dxi-column>
            <dxi-column dataField="th_number" [width]="150" caption="เลขที่ใบรับรอง">
            </dxi-column>
            <dxi-column dataField="companyName" caption="ชื่อผู้ลงนาม">
            </dxi-column>
            <dxi-column dataField="requestDate" [width]="170" dataType="date" [format]="'yyyy-MM-dd HH:mm:ss'"
              caption="วันที่ส่งคำขอ">
            </dxi-column>
          </dx-data-grid>
        </mat-dialog-content>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-raised-button [disabled]="form.qty == null && form.reprint == null" [mat-dialog-close]="form"
      color="accent">เพิ่มรายละเอียด</button>
    <!-- <button mat-raised-button [mat-dialog-close]>ยกเลิก</button> -->
  </mat-dialog-actions>