import { filter } from "rxjs";
import { AppMasterService } from "app/business/service/app/app-master.service";
import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AppUserService } from "app/business/service/app/app-user.service";
import { AuthService } from "app/core/auth/auth.service";

@Component({
  selector: "app-add-payment-detail-modal",
  templateUrl: "./add-payment-detail-modal.component.html",
  styleUrls: ["./add-payment-detail-modal.component.css"],
})
export class AddPaymentDetailModalComponent implements OnInit {
  form: any = {};
  paymentTypeList: any = [
    {
      name: "เงินสด / Bill Payment",
      code: 1,
    },
    {
      name: "E-payment",
      code: 2,
    },
  ];
  bankAccList: any = [];
  bankAccCodeList: any = [];
  userProfile: any;
  isEpayment: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<AddPaymentDetailModalComponent>,
    public appMasterSrevice: AppMasterService,
    private appUserService:AppUserService,
    private _authService:AuthService,
  ) {
    this.userProfile = JSON.parse(
      this._authService.loginUser
    )
  }

  async ngOnInit() {
    var billPayment = [
      {
        name: "เงินสด / Bill Payment",
        code: 1,
      },
    ];
    await this.appMasterSrevice
      .getIsPaymentByCompany({ cpId: this.data.cId })
      .then((res) => {
        this.isEpayment = res.isPayment;
        if (this.isEpayment != null) {
          this.paymentTypeList =
            this.isEpayment == "Y" ? this.paymentTypeList : billPayment;
        }
      });

    this.form.paymentType =
      this.data.paymentType != null ? this.data.paymentType : null;
    this.appMasterSrevice
      .getMasterBankAccount({ companyCode: this.data.cId })
      .then((res) => {
        this.bankAccList = res;
        this.bankAccCodeList = res;
        if (this.form.paymentType == 2) {
          this.form.bankPayer = this.bankAccList.find(
            (f) => f.code == this.data.bankPayer
          );
          this.form.bankReceriver = this.bankAccList.find(
            (f) => f.code == this.data.bankReceriver
          );
        }
      });
  }

  selectionPaymentType(e) {
    this.bankAccCodeList = this.bankAccList.filter(
      (f) => f.id == this.form.bankPayer?.id
    );
    this.form.bankReceriver = this.form.bankPayer;
  }
}
