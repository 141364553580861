<mat-card (keydown.enter)="$event.preventDefault()">
    <mat-card-header>
        <div class="flex justify-between" style="width: 100%;">
            <div class="text-lg"> ต้องการให้หอการค้าไทยพิมพ์เอกสารแทน</div>
            <div>
                <button class="close-button" [mat-dialog-close]="true">
                    <mat-icon class="text-white" [mat-dialog-close] svgIcon="heroicons_outline:x"></mat-icon>
                </button>
            </div>
        </div>
    </mat-card-header>

    <mat-dialog-content class="mat-typography">
        <div class="flex flex-col">
            <div class="flex flex-row px-10 pt-6">
                <div class="basis-1/4">
                    <mat-label class="flex flex-col">เลขที่คำขอ</mat-label>
                    <mat-label class="flex flex-col text-gray-500">Request No</mat-label>
                </div>
                <div class="basis-3/4">
                    <mat-label class="pr-2.5 ct"> : </mat-label>{{form.rqNo?form.rqNo:""}}
                </div>
            </div>
            <div class="flex flex-row px-10 pt-6">
                <div class="basis-1/4">
                    <mat-label class="flex flex-col">ประเภทเอกสาร</mat-label>
                    <mat-label class="flex flex-col gray">Document Type</mat-label>
                </div>
                <div class="basis-3/4">
                    <mat-label class="pr-2.5 ct"> : </mat-label>{{form.docType?form.docType:""}}
                </div>
            </div>
            <div class="flex flex-row px-10 pt-6">
                <div class="basis-1/4">
                    <mat-label class="flex flex-col">เร่งด่วน</mat-label>
                    <mat-label class="flex flex-col text-gray-500">Express Type</mat-label>
                </div>
                <div class="basis-3/4">
                    <mat-label class="pr-2.5 ct"> : </mat-label>{{form.expressType?form.expressType:""}}
                </div>
            </div>
            <div class="flex flex-row px-10 pt-4">
                <div class="basis-1/4 self-center">
                    <mat-label class="flex flex-col">ผู้ส่งออก</mat-label>
                    <mat-label class="flex flex-col text-gray-500">Consignor</mat-label>
                </div>
                <div class="basis-3/4">
                    <mat-label class="pr-2.5 ct" > : </mat-label>{{form.consignor?form.consignor:""}}
                </div>
            </div>
            <div class="flex flex-row px-10 pt-4">
                <div class="basis-1/4">
                    <mat-label class="flex flex-col">ระบุสาขาที่ต้องการโอน</mat-label>
                </div>
                <div class="basis-3/4">
                    <mat-label class="pr-2.5 ct"> : </mat-label>
                    <mat-form-field appearance="fill" class="w-50">
                        <mat-select [placeholder]="'สาขา'"
                            [(ngModel)]="form.sigBranchId" [ngModelOptions]="{standalone: true}">
                            <mat-option *ngFor="let branch of branchList" [value]="branch.branchId" branchId>
                                {{branch.branchName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="flex flex-row px-10 pt-6">
                <div class="basis-1/4">
                    <mat-label>หมายเหตุ</mat-label>
                </div>
                <div class="basis-3/4 flex flex-row">
                    <mat-label class="pr-2.5 ct"> : </mat-label>
                    <mat-form-field style="width: 90%" >
                        <textarea matInput placeholder="ระบุหมายเหตุ"
                            [(ngModel)]="form.remark" name="consignorName" #consignorName="ngModel"
                            id="consignorName"  row="10"
                            maxlength="100"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="center" class="pb-5">
        <button mat-raised-button color="accent"  (click)="save()">
            ยืนยันคำขอ
        </button>
        <button mat-raised-button color="accent" [mat-dialog-close]>
            ย้อนกลับ
        </button>
    </mat-dialog-actions>

</mat-card>