<mat-card>
  <mat-card-header>
    <div class="flex justify-between" style="width: 100%;">
      <div class="text-lg"> CO Branch </div>
      <div>
        <button class="close-button" [mat-dialog-close]="true">
          <mat-icon class="text-white" [mat-dialog-close]>cancel</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-dialog-content>
    <div class="pl-22 pr-22 flex flex-col gap-4">
      <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
        <div class="mat-popup-header flex flex-col">
          <h4>Branch Name <span style="color:red;">*</span></h4>
        </div>
        <div class="mat-popup flex flex-row justify-between items-center" style="width:80%">
          <div>:</div>
          <mat-form-field style="flex: 0 95%;" class="flex">
            <input required autocomplete="off" type="text" matInput placeholder="Branch Name" matInput #input [(ngModel)]="form.coBranchName"
              name="name" #name="ngModel">
          </mat-form-field>
        </div>
      </div>

      <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
        <div class="mat-popup-header flex flex-col">
          <h4>Description <span style="color:red;">*</span></h4>
        </div>
        <div class="mat-popup flex flex-row justify-between items-center" style="width:80%">
          <div>:</div>
          <mat-form-field style="flex: 0 95%;" class="flex">
            <input required autocomplete="off" type="text" matInput placeholder="Description" matInput #input [(ngModel)]="form.description"
              name="name" #name="ngModel">
          </mat-form-field>
        </div>
      </div>

      <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
        <div class="mat-popup-header flex flex-col">
          <h4>Province <span style="color:red;">*</span></h4>
        </div>
        <div class="mat-popup flex flex-row justify-between items-center" style="width:80%">
          <div>:</div>
          <mat-form-field style="flex: 0 95%;" class="flex">
            <mat-select required name="province" [(ngModel)]="form.provinceCode">
              <mat-option *ngFor="let province of provinceList" [value]="province.provinceCode">
                {{province.provinceName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>


      <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
        <div class="mat-popup-header flex flex-col">
          <h4>Tax ID <span style="color:red;">*</span></h4>
        </div>
        <div class="mat-popup flex flex-row justify-between items-center" style="width:80%">
          <div>:</div>
          <mat-form-field style="flex: 0 95%;" class="flex">
            <input required autocomplete="off" type="text" matInput placeholder="Tax ID" matInput #input [(ngModel)]="form.taxId"
              name="name" #name="ngModel" oninput="this.value=this.value.replace(/[^0-9]/g,'')">
          </mat-form-field>
        </div>
      </div>

      <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
        <div class="mat-popup-header flex flex-col">
          <h4>Rate <span style="color:red;">*</span></h4>
        </div>
        <div class="mat-popup flex flex-row justify-between items-center" style="width:80%">
          <div>:</div>
          <mat-form-field style="flex: 0 95%;" class="flex">
            <input required autocomplete="off" type="text" matInput placeholder="Rate" matInput #input [(ngModel)]="form.rateReceive"
              name="name" #name="ngModel" currency-symbol="" ng-currency fraction="2" pattern="^\d*(\.\d{0,2})?$">
          </mat-form-field>
        </div>
      </div>

      <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
        <div class="mat-popup-header flex flex-col">
          <h4>Account Reference <span style="color:red;">*</span></h4>
        </div>
        <div class="mat-popup flex flex-row justify-between items-center" style="width:80%">
          <div>:</div>
          <mat-form-field style="flex: 0 95%;" class="flex">
            <input required autocomplete="off" type="text" matInput placeholder="Account Reference" matInput #input [(ngModel)]="form.accountRef"
              name="name" #name="ngModel" oninput="this.value=this.value">
          </mat-form-field>
        </div>
      </div>

      <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
        <div class="mat-popup-header flex flex-col">
          <h4>RC Profile ID <span style="color:red;">*</span></h4>
        </div>
        <div class="mat-popup flex flex-row justify-between items-center" style="width:80%">
          <div>:</div>
          <mat-form-field style="flex: 0 95%;" class="flex">
            <input required autocomplete="off" type="text" matInput placeholder="RC Profile ID" matInput #input [(ngModel)]="form.rcProfileId"
              name="name" #name="ngModel" oninput="this.value=this.value.replace(/[^0-9]/g,'')">
          </mat-form-field>
        </div>
      </div>

      <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
        <div class="mat-popup-header flex flex-col">
          <h4>RC Profile Code <span style="color:red;">*</span></h4>
        </div>
        <div class="mat-popup flex flex-row justify-between items-center" style="width:80%">
          <div>:</div>
          <mat-form-field style="flex: 0 95%;" class="flex">
            <input required autocomplete="off" type="text" matInput placeholder="RC Profile Code" matInput #input [(ngModel)]="form.rcProfileCode"
              name="name" #name="ngModel">
          </mat-form-field>
        </div>
      </div>

      <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
        <div class="mat-popup-header flex flex-col">
          <h4>Settings <span style="color:red;">*</span></h4>
        </div>
        <div class="mat-popup flex flex-row justify-between items-center" style="width:80%">
          <div>:</div>
          <div style="flex: 0 95%;flex-direction: column;gap: 10px" class="flex">
            <mat-slide-toggle [checked]="form.isMemberDiscount === 1 ? true : false" (change)="onChangeDiscount($event)"
            style="flex: 0 95%;">
            <span style="padding-left:10px;">ได้รับส่วนลดเมื่อเป็นสมาชิกหอการค้าจังหวัด</span></mat-slide-toggle>
            <mat-slide-toggle [checked]="form.isMainCenter === 1 ? true : false" (change)="onChangeMainCenter($event)"
            style="flex: 0 95%;">
            <span style="padding-left:10px;">ใช้งานเป็นศูนย์หลัก</span></mat-slide-toggle>
          </div>
        </div>
      </div>

      <div class="flex-row flex justify-between gap-2.5">
        <div class="mat-popup-header flex flex-col">
          <h4>ใช้งาน</h4>
          <h4>Active</h4>
        </div>
        <div class="mat-popup flex flex-row justify-between items-center" style="width:80%">
          <div>:</div>
          <mat-slide-toggle [checked]="form.isActive === 'Y' ? true : false" (change)="onChange($event)"
            style="flex: 0 95%;" class="flex">
            Active</mat-slide-toggle>
        </div>
      </div>

      <div class="flex-row flex justify-center gap-2.5" style="gap: 10px;">
        <button mat-raised-button color="accent" (click)="save()">บันทึก</button>
        <button mat-raised-button [mat-dialog-close]>ยกเลิก</button>
      </div>
    </div>
  </mat-dialog-content>
