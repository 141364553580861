import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService } from '@bundle/service/api.service';
import { HttpLoadingService } from '../http-loading.service';
import { AuthService } from 'app/core/auth/auth.service';
// import { JwksClient } from "jwks-rsa";

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
    constructor(
        private apiService: ApiService,
        private httpLoadingService: HttpLoadingService,
        private _authService:AuthService,
    ) {

    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.apiService.loading.next(true);
        this.httpLoadingService.start();
        if (this._authService.token!="") {
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${this._authService.token}`
                }
            });
        } else {
        }
        return next.handle(req);
    }
}
