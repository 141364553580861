import { Injectable } from '@angular/core';
import { IGetCFDetailResponse, IgetListCOResponse } from 'app/business/interface/api/api-co.interface';
import {  GetListCORequest, IGetCFDetailRequest, IgetListCORequest, IgetListLoadTemplateRequest } from 'app/business/interface/app/app-co.interface';
import { reject } from 'lodash';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ApiCOService } from '../api/api-co.service';

@Injectable({
  providedIn: 'root'
})
export class AppCOService {
  subscription?: Subscription;

  constructor(private apiCOService: ApiCOService) { }

  getListCO(request: GetListCORequest): any {
    return new Promise((resolve, reject) => {
      this.apiCOService.getListCO(request).subscribe((res) => {
          resolve(res);
        });
    });
  }

  getListLoadTemplate(request: IgetListLoadTemplateRequest): any {
    return new Promise((resolve, reject) => {
      this.apiCOService.getListLoadTemplate(request).subscribe((res) => {
          resolve(res);
        });
    });
  }
  getListAttachType():any{
    return new Promise((resolve,reject)=>{
      this.apiCOService.getListAttachType().subscribe((res)=>{
        res = res.sort(x=>x.maT_DOC_NAME);
        resolve(res);
      })
    })
  }
  getListAttachTypeByType(request:any):any{
    return new Promise((resolve,reject)=>{
      this.apiCOService.getListAttachTypeByType(request).subscribe((res)=>{
        res = res.sort(x=>x.maT_DOC_NAME);
        resolve(res);
      })
    })
  }
  saveCO(request:any):any{
    if(this.subscription){
      this.subscription.unsubscribe();
    }
    return new Promise((resolve,reject)=>{
      this.subscription = this.apiCOService.saveCO(request).subscribe((res)=>{
        resolve(res);
      })
    })
  }
  saveTemplate(request:any):any{
    return new Promise((resolve,reject)=>{
      this.apiCOService.saveTemplate(request).subscribe((res)=>{
        resolve(res);
      })
    })
  }
  getCO(request:any):any{
    return new Promise((resolve,reject)=>{
      this.apiCOService.getCO(request).subscribe((res)=>{
        resolve(res);
      })
    })
  }

  getLoadTemplate(request:any){
    return new Promise((resolve,reject)=>{
      this.apiCOService.getLoadTemplate(request).subscribe((res)=>{
        resolve(res);
      })
    })
  }

  

  getListTemplate(request:any):any{
    return new Promise((resolve,reject)=>{
      this.apiCOService.getListTemplate(request).subscribe((res)=>{
        resolve(res);
      })
    })
  }
  getListDeclaratorCompany(request:any):any{
    return new Promise((resolve,reject)=>{
      this.apiCOService.getListDeclaratorCompany(request).subscribe((res)=>{
        resolve(res);
      })
    })
  }

  getDraftCO(request:any):any{
    return new Promise((resolve,reject)=>{
      this.apiCOService.getDraftCO(request).subscribe((res)=>{
        resolve(res);
      })
    })
  }
  deleteCO(request:any):any{
    return new Promise((resolve,reject)=>{
      this.apiCOService.deleteCO(request).subscribe((res)=>{
        resolve(res);
      })
    })
  }

  getPreviewCO(request:any):any{
    return new Promise((resolve,reject)=>{
      this.apiCOService.getPreviewCO(request).subscribe((res)=>{
        resolve(res);
      })
    })
  }
  cencelVerify(request:any):any{
    return new Promise((resolve,reject)=>{
      this.apiCOService.cancelVerify(request).subscribe((res)=>{
        resolve(res);
      })
    })
  }

  getPreviewCOPack(request:any):any{
    return new Promise((resolve,reject)=>{
      this.apiCOService.getPreviewCOPack(request).subscribe((res)=>{
        resolve(res);
      })
    })
  }

  getListCF(request: GetListCORequest): Promise<IgetListCOResponse[]> {
    return new Promise((resolve, reject) => {
      this.apiCOService.getListCF(request).subscribe((res) => {
          resolve(res);
        });
    });
  }

  getCFDetail(request: IGetCFDetailRequest): Promise<IGetCFDetailResponse> {
    return new Promise((resolve, reject) => {
      this.apiCOService.getCFDetail(request).subscribe((res) => {
          resolve(res);
        });
    });
  }

  getRelationCompany(request:any):any{
    return new Promise((resolve,reject)=>{
      this.apiCOService.getRelationCompany(request).subscribe((res)=>{
        resolve(res);
      })
    })
  }
  

  getPreviewCF(request:any):any{
    return new Promise((resolve,reject)=>{
      this.apiCOService.getPreviewCF(request).subscribe((res)=>{
        resolve(res);
      })
    })
  }

  getErrorMarkList(request:any):any{
    return new Promise((resolve,reject)=>{
      this.apiCOService.getErrorMarkList(request).subscribe((res)=>{
        resolve(res);
      })
    })
  }

  getListSearchCOCF(request: any): any{
    return new Promise((resolve,reject)=>{
      this.apiCOService.getListSearchCOCF(request).subscribe((res)=>{
        resolve(res);
      })
    })
  }
  saveCORequestJob(request: any): any{
    return new Promise((resolve,reject)=>{
      this.apiCOService.saveCORequestJob(request).subscribe((res)=>{
        resolve(res);
      })
    })
  }
  saveCFRequestJob(request: any): any{
    return new Promise((resolve,reject)=>{
      this.apiCOService.saveCFRequestJob(request).subscribe((res)=>{
        resolve(res);
      })
    })
  }

  getViewVerifyWorkList(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiCOService.getViewVerifyWorkList(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getPrintOriginal(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiCOService.getPrintOriginal(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getAuthorityViewResponseList(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiCOService.getAuthorityViewResponseList(request).subscribe((res) => {
        resolve(res);
      });
    });
  }
  getPdfFilePathReprint(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiCOService.getPdfFilePathReprint(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getListPrintCoPack(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiCOService.getListPrintCoPack(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  rejectRequestJob(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiCOService.rejectRequestJob(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getMasterErrorMarkList(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiCOService.getMasterErrorMarkList(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getCfTotalPage(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiCOService.getCfTotalPage(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  sendPrintToStaff(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiCOService.sendPrintToStaff(request).subscribe((res) => {
        resolve(res);
      });
    });
  }
  
  getCfTotalPageReprint(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiCOService.getCfTotalPageReprint(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  approveLiveSignSelfPrint(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiCOService.approveLiveSignSelfPrint(request).subscribe((res) => {
        resolve(res);
      });
    });
  }
  
  LogPrintHistory(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiCOService.LogPrintHistory(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  saveTemplateStatus(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiCOService.saveTemplateStatus(request).subscribe((res) => {
        resolve(res);
      })
    })
  }

  getQueueByPackId(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiCOService.getQueueByPackId(request).subscribe((res) => {
        resolve(res);
      })
    })
  }
}