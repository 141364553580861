import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CoErrorMarkImagePopupComponent } from '../../create-co-inquiry/co-error-mark-image-popup/co-error-mark-image-popup.component';
import { ImageModalComponent } from '../../create-co-inquiry/Image-Modal/Image-Modal.component';

@Component({
  selector: 'app-company-authorized-signature-grid',
  templateUrl: './company-authorized-signature-grid.component.html',
  styleUrls: ['./company-authorized-signature-grid.component.css']
})
export class CompanyAuthorizedSignatureGridComponent implements OnInit, AfterViewInit {
  form: any = {};
  companyTypeList: any[] = [];
  displayedColumns: string[] = [
    'number',
    'prefixTh',
    'firstnameTh',
    'lastnameTh',
    'prefixEn',
    'firstnameEn',
    'lastnameEn',
    'signature',
  ]
  dataSource = new MatTableDataSource<any>()
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input()
  set inputData(value) {
    this.dataSource = new MatTableDataSource(value);
  }
  get inputData(): any {
    return this.dataSource
  }

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`)
    } else {
      this._liveAnnouncer.announce('Sorting cleared')
    }
  }

  stopPropagation(event) {
    event.stopPropagation()
  }

  imagePopup(e) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.minHeight = '30vh';
    dialogConfig.minWidth = '30vh';
    dialogConfig.maxHeight = '100vh';
    dialogConfig.data = {
      fullImageUrl: e,
      header: "ภาพลายมือชื่อ | Signature Picture"
    };

    const dialogRef = this.dialog.open(ImageModalComponent, dialogConfig)
  }
}
