import { SelectionModel } from "@angular/cdk/collections";
import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  ViewEncapsulation,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort, Sort } from "@angular/material/sort";
import { LiveAnnouncer } from "@angular/cdk/a11y";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmPopupComponent } from "../confirm-popup/confirm-popup.component";
import { MatMenuTrigger } from "@angular/material/menu";
import { AppFactoryManagementService } from "app/business/service/app/app-factorymanagement.service";
import { BlacklistModalComponent } from "./blacklist-modal/blacklist-modal.component";
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'
import { Router } from "@angular/router";
import { AppMenuService } from "app/business/service/app/app-menu.service";
import { AppUserService } from "app/business/service/app/app-user.service";
import { BlacklistSearchFactoryModalComponent } from "./blacklist-search-factory-modal/blacklist-search-factory-modal.component";
import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import moment from "moment";
import { debounceTime, map, startWith, switchMap, takeUntil } from "rxjs/operators";
import { HttpLoadingService } from '@bundle/service/http-loading.service';
import { of, Subject } from "rxjs";
import { companySearch } from 'app/business/interface/app/app-company.interace'
import { AppCompanyService } from 'app/business/service/app/app-company.service'
import { FormControl, FormGroup, FormBuilder } from "@angular/forms";
import { AuthService } from "app/core/auth/auth.service";

@Component({
  selector: "app-list-blacklist-inquiry",
  templateUrl: "./list-blacklist-inquiry.component.html",
  styleUrls: ["./list-blacklist-inquiry.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ListBlacklistInquiryComponent implements OnInit, AfterViewInit {
  form: any = {};
  myDate = new Date()
  searchForm: any = {};
  displayedColumns: string[] = [
    "cid",
    "changeUpComDate",
    "companyNameTH",
    "companyNameEN",
    "addressStreet",
    "province",
    "phone",
    "blacklist",
    "grade",
    "action",
  ];
  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);
  search: any = {};
  data: any = {};
  companyDetail: any = {};
  count: any = 0;
  sortDirection: "name";
  sortProperty: "desc";
  url: any;
  buttonList: any[] = [];
  timer: any;

  checkEndDate: boolean = true;
  checkStartDate: boolean = true;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  searchCompanyForm: FormGroup;
  valueSelect: boolean = false;
  isLoading: boolean = false;
  filteredOptions$: any = [];
  userToken:any;
  constructor(
    private _formBuilder: FormBuilder,
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private appFactoryManagement: AppFactoryManagementService,
    private alertService: AlertMessageService,
    private router: Router,
    private appMenuService: AppMenuService,
    private appUserService: AppUserService,
    private confirmService: ConfirmDialogService,
    private httpLoadingService: HttpLoadingService,
    private appCompanyService: AppCompanyService,
    private _authService:AuthService
  ) { }

  async getRouter(): Promise<void> {
    
    this.url = this.router.url;

    const profile = JSON.parse(this.appUserService.getSessionUserProfile());
    const formData = {
      url: this.url,
      userGroupCode: profile?.userGroupCode,
      userRoleCode: profile?.userRoleCode,
      userCode: profile?.userCode
    };
    this.buttonList = await this.appMenuService.getButtonPermissionPerPages(formData);
    
  }

  async ngOnInit() {
    this.userToken = JSON.parse(
      this._authService.loginUser
    )
    this.userToken = {
      ...this.userToken,
      gId:this.userToken.gid,
      uId:this.userToken.uid,
      cid:this.userToken.cpId,
  }
  
    this.searchCompanyForm = this._formBuilder.group({
      companySearch: ['']
    });
    this.valueChange();

    await this.getRouter();
    this.loadData();
    this.search.grade = "";
  }

  ngAfterViewInit() {
    this.paginator.pageIndex = 0;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce("Sorting cleared");
    }
  }

  applyFilter(event: string) {
    clearTimeout(this.timer); // Clear any existing timer

    this.timer = setTimeout(() => {
      // This code will be executed after a specified delay (e.g., 500 milliseconds)
      
      // Send the value to the backend or perform any other necessary actions
      this.search.companyName = event;
      this.dataSource.paginator = this.paginator
      this.loadData()

    }, 500); // Specify the desired delay in milliseconds
  }

  // applyFilter(filterValue: string) {
  //   this.search.companyNameTH = filterValue;
  //   this.search.companyNameEN = filterValue;
  //   this.search.action = "searchbox";
  //   this.loadData();
  //   if (!filterValue) {
  //     this.dataSource.paginator.length = this.dataSource.data.length;
  //   }
  //   this.search = {};
  //   this.search.grade = "";
  // }

  stopPropagation(event) {
    event.stopPropagation();
  }

  // menuOpen() {}
  menuClosed() {
    
  }

  async onRefresh() {
    this.dataSource.paginator = this.paginator;
    this.data.pagination = {
      Skip:
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
      Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
    };
    this.data.cid = (this.search.cid ? this.search.cid : "").toLowerCase();
    this.data.companyNameTH = (
      this.search.companyNameTH ? this.search.companyNameTH : ""
    ).toLowerCase();
    this.data.companyNameEN = (
      this.search.companyNameEN ? this.search.companyNameEN : ""
    ).toLowerCase();
    this.data.companyName = (
      this.search.companyName ? this.search.companyName : ""
    ).toLowerCase();
    this.data.province = (
      this.search.province ? this.search.province : ""
    ).toLowerCase();
    this.data.grade = (
      this.search.grade ? this.search.grade : ""
    ).toLowerCase();
    this.data.action = (
      this.search.action ? this.search.action : ""
    ).toLowerCase();

    
    this.data.startDate = this.search.startDate;
    this.data.endDate = this.search.endDate;
    await this.appFactoryManagement.GetListBlackList(this.data).then((res) => {
      if (res === "error") {
        return;
      } else {
        let newRes = res.blacklist;
        newRes.forEach((e, i) => {
          if (e.blacklistReason.length > 0) {
            const lockReason = e.blacklistReason.map((el, j) => {
              return j+1 + ") " + el as string
            });
  
            newRes[i].lockReason = lockReason.join("\n");  
          } else {
            newRes[i].lockReason = null;
          }
        });

        this.dataSource.data = newRes;
        this.count = res.totalCount;
      }
    }); // กำหนดค่า total

    this.dataSource.paginator.length = this.count;
  }

  async loadData() {
    if (this.paginator != undefined && this.paginator != null) {
      this.paginator.pageIndex = 0
    }
    this.dataSource.paginator = this.paginator;

    this.data.pagination = {
      Skip: 0,
      Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
    };
    this.data.cid = (this.search.cid ? this.search.cid : "").toLowerCase();
    this.data.companyNameTH = (
      this.search.companyNameTH ? this.search.companyNameTH : ""
    ).toLowerCase();
    this.data.companyNameEN = (
      this.search.companyNameEN ? this.search.companyNameEN : ""
    ).toLowerCase();
    this.data.companyName = (
      this.search.companyName ? this.search.companyName : ""
    ).toLowerCase();
    this.data.province = (
      this.search.province ? this.search.province : ""
    ).toLowerCase();
    this.data.grade = (
      this.search.grade ? this.search.grade : ""
    ).toLowerCase();
    this.data.action = (
      this.search.action ? this.search.action : ""
    ).toLowerCase();

    if (
      moment(this.search.startDate).format('YYYY-MM-DD') >
      moment(this.search.endDate).format('YYYY-MM-DD')
    ) {
      this.alertService.open({
        message: 'กรุณาระบุวันที่สิ้นสุดให้มากกว่าวันที่เริ่มต้น',
        title: 'กรุณากรอกข้อมูลค้นหาให้ถูกต้อง',
      })
      return false
    }
    this.data.startDate = this.search.startDate;
    this.data.endDate = this.search.endDate;
    await this.appFactoryManagement.GetListBlackList(this.data).then((res) => {
      if (res === "error") {
        return;
      } else {
        let newRes = res.blacklist;
        newRes.forEach((e, i) => {
          if (e.blacklistReason.length > 0) {
            const lockReason = e.blacklistReason.map((el, j) => {
              return j+1 + ") " + el as string
            });
  
            newRes[i].lockReason = lockReason.join("\n");  
          } else {
            newRes[i].lockReason = null;
          }
        });

        this.dataSource.data = newRes;
        this.count = res.totalCount;
      }
    }); // กำหนดค่า total


    this.dataSource.paginator.length = this.count;
    this.trigger.closeMenu();
  }

  // Function ตอนกดเปลี่ยน page ใน mat-table
  async pageChanged(e) {
    this.data.pagination = {
      Skip: (!e.pageIndex ? 0 : e.pageIndex) * (!e.pageSize ? 0 : e.pageSize),
      Take: !e.pageSize ? 10 : e.pageSize,
    };
    this.data.cid = (this.search.cid ? this.search.cid : "").toLowerCase();
    this.data.companyNameTH = (
      this.search.companyNameTH ? this.search.companyNameTH : ""
    ).toLowerCase();
    this.data.companyNameEN = (
      this.search.companyNameEN ? this.search.companyNameEN : ""
    ).toLowerCase();
    this.data.province = (
      this.search.province ? this.search.province : ""
    ).toLowerCase();
    this.data.grade = (
      this.search.grade ? this.search.grade : ""
    ).toLowerCase();
    this.data.action = (
      this.search.action ? this.search.action : ""
    ).toLowerCase();
    await this.appFactoryManagement.GetListBlackList(this.data).then((res) => {
      if (res === "error") {
        return;
      } else {
        this.dataSource.data = res.blacklist;
        this.count = res.totalCount;
      }
    });
    this.dataSource.paginator.length = this.count; // กำหนดค่า total
    this.dataSource.paginator.pageIndex = e.pageIndex; // กำหนด pageIndex
  }

  async getDetail(e) {
    const data = {
      id: e == null ? 0 : e.id,
      companyCode: e == null ? "" : e.cid,
    };
    await this.appFactoryManagement.GetDataCompany(data).then((res: any) => {
      if (res === "error") {
        return;
      } else {
        this.companyDetail = res;
      }
    });
    const dialogRef = this.dialog.open(BlacklistModalComponent, {
      disableClose: false,
      width: "90vw",
      data: this.companyDetail,
      maxWidth: "none",
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        const add = {
          uid: this.userToken.uId,
          id: res.data.id,
          companyId: res.data.companyId,
          grade: res.data.grade,
          gradeReason: res.data.gradeReason,
          lockStatus: res.data.lockStatus,
          lockReason: res.data.lockReason,
          factoryName: res.data.factoryName,
          factoryNo: res.data.factoryNo,
          isFactory: res.data.isFactory,
          gradeDate: res.data.gradeDate,
          gradeFilePath: res.data.gradeFilePath,
          blacklistType: "M",
          refBlacklistId: res.data.lockStatus === 'N' ? res.data.refBlacklistId : null
        };
        this.appFactoryManagement.UpdateBlackList(add).then((res: any) => {
          if (res == "error") {
            return
          } else {
            this.loadData();
          }
        });
      } else {
        return;
      }
    });
  }

  lock(e) {
    
    const add = {
      uid: this.userToken.uId,
      id: e.id,
      companyId: e.cid,
      grade: e.grade,
      gradeReason: "-",
      lockStatus: "N",
      lockReason: "-",
    };
    const dialogComfirm = this.dialog.open(ConfirmPopupComponent, {
      disableClose: false,
      // width: '30vw',
      data: {
        header: "ต้องการปลดล็อคใช่หรือไม่?",
        button: "open",
      },
      maxWidth: "none",
    });
    dialogComfirm.afterClosed().subscribe((res) => {
      if (res == false) {
        return;
      } else {
        this.appFactoryManagement.UpdateBlackList(add).then((res: any) => {
          if (res == "error") {
            const dialogRef = this.dialog.open(ConfirmPopupComponent, {
              disableClose: false,
              // width: '30vw',
              data: {
                header: "ปลดล็อคไม่สำเร็ข",
                button: "close",
              },
              maxWidth: "none",
            });
            dialogRef.afterClosed().subscribe((res) => {
              if (res) {
                if (res == true) {
                  return;
                }
              }
            });
          } else {
            const dialogRef = this.dialog.open(ConfirmPopupComponent, {
              disableClose: false,
              // width: '30vw',
              data: {
                header: "ปลดล็อคสำเร็จ",
                button: "close",
              },
              maxWidth: "none",
            });

            dialogRef.afterClosed().subscribe((res) => {
              if (res) {
                if (res == true) {
                  this.loadData();
                }
              }
            });
          }
        });
      }
    });
  }

  clearOption() {
    this.search = {};
    this.search.grade = "";
    this.searchCompanyForm.get('companySearch').setValue('');
  }

  popupFactory() {
    const dialogRef = this.dialog.open(BlacklistSearchFactoryModalComponent, {
      disableClose: true,
      width: '80vw',
      data: 'blacklist',
      maxWidth: 'none',
    })
    dialogRef.afterClosed().subscribe((res) => {
      if (res != true) {

      } else {

        this.loadData();

      }
    })
  }

  datecheck(e: MatDatepickerInputEvent<Date>) {
    if (
      moment(this.search.startDate).format('YYYY-MM-DD') >
      moment(this.search.endDate).format('YYYY-MM-DD')
    ) {
      this.search.endDate = {};
      this.checkEndDate = false;
    }
  }

  endDateCheck(e) {
    if (
      moment(this.search.startDate).format('YYYY-MM-DD') ==
      moment(this.search.endDate).format('YYYY-MM-DD') ||
      moment(this.search.startDate).format('YYYY-MM-DD') <
      moment(this.search.endDate).format('YYYY-MM-DD')
    ) {
      this.checkEndDate = true;
    }
  }
  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find(e => e.buttonCode == buttonCode);

    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }

  selectionChange(e): void {
    this.searchCompanyForm.get('companySearch').setValue(e.option.value.companyCode);
    this.companySelect(e.option.value);
  }

  companySelect(e) {
    this.search.cid = e.companyCode;
    this.search.companyNameTH = e.companyNameTh;
    this.search.companyNameEN = e.companyNameEn;
    this.valueSelect = true;
  }

  valueChange() {
    this.filteredOptions$ = this.searchCompanyForm.get('companySearch').valueChanges.pipe(
      debounceTime(500),
      switchMap(value => {
        if (!value) {
          this.valueSelect = false;
          this.search.cid = null
          return of(null);
        }

        this.httpLoadingService.enabled = false;
        this.isLoading = true;

        const key = value;
        if (key !== '') {
          if (this.valueSelect) {
            return of(null);
          }
          else {
            this.valueSelect = false;
            return this.appCompanyService.getListCompanyByFilter(key);
          }
        } else {
          this.valueSelect = false;
          this.isLoading = false;
          return;
        }

      }),
      map((res: any[]) => {
        this.isLoading = false;
        this.httpLoadingService.enabled = true;

        

        return res?.map(item => {
          const { companyTaxId, companyNameTh } = (item as companySearch);
          return {
            ...item,
            // tslint:disable-next-line: max-line-length
            display: `${companyTaxId} ${companyNameTh}`
          };
        });
      })
    );
  }
}
