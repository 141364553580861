import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { IGetCFDetailRequest } from "app/business/interface/app/app-co.interface";
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import { AppCOService } from "app/business/service/app/app-co.service";
import { AppMasterService } from "app/business/service/app/app-master.service";
import { AppRequestService } from "app/business/service/app/app-request.service";
import { DxDataGridComponent } from "devextreme-angular";
import { CreateCfInquiryPopupComponent } from "./create-cf-inquiry-popup/create-cf-inquiry-popup.component";
import { DraftCFPopupComponent } from "./draft-cf-popup/draft-cf-popup.component";
import { QueuePopupComponent } from "../queue-popup/queue-popup.component";
import { CoErrorMarkImagePopupComponent } from "../create-co-inquiry/co-error-mark-image-popup/co-error-mark-image-popup.component";
import { Buffer } from "buffer";
import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import { Location } from "@angular/common";
import { ImageModalComponent } from "../create-co-inquiry/Image-Modal/Image-Modal.component";
import { DetailPaymentListTableComponent } from "../payment/detail-payment-list-table/detail-payment-list-table.component";
import { AppUserService } from "app/business/service/app/app-user.service";
import _ from "underscore";
import { AuthService } from "app/core/auth/auth.service";
@Component({
  selector: "app-create-cf-inquiry",
  templateUrl: "./create-cf-inquiry.component.html",
  styleUrls: ["./create-cf-inquiry.component.scss"],
})
export class CreateCfInquiryComponent implements OnInit, AfterViewInit {
  @Input() subEdit: boolean = false;
  @Input() disabledJob: boolean;
  @Input() packIdInput: number;
  @Input() isJobDetail: boolean = undefined;
  dataSourceAttachPic: any[] = [];
  form: any = {};
  fileImg: any;
  file: any;
  value: any[] = [];
  dataList: any[] = [];
  deleteAttachmentList: any = [];
  request: any;
  editItem: boolean;
  editorOptions: any = {
    placeholder: "Enter some text",
    maxLength: 50,
  };
  imageResize: any;
  recheck: boolean;

  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  @ViewChild(DetailPaymentListTableComponent, { static: false })
  detailPaymentListTable: DetailPaymentListTableComponent;

  packIdNo: number = 0;
  countryList: any[] = [];
  attachTypeList: any[] = [];
  declaratorList: any;
  destinationList: any;
  branchList: any;
  docList: any;
  PackidLoadData: number;
  resolution: any = {};
  userProfile: any = {};
  companyName: any[] = [];
  clicked: boolean;
  packId: string;
  documentDetail: any;
  fieldError: any = {
    declarator: true,
    consignor: true,
    consignee: true,
    notify: true,
    vessel: true,
    loading: true,
    certificateDate: true,
    destination: true,
    invoice: true,
    country: true,
    item: true,
    footer: true,
    title: true,
  };
  showErrorMessage: any = [];
  search: boolean;
  markErrorList: any = [];
  editJobData: boolean;
  staff: boolean = false;
  dataSourceUpload: any[] = [];
  formSourceUpload: any[] = [];
  symbolRegex: RegExp;
  engRegex: RegExp;
  textRegex: RegExp = /^[a-zA-Z0-9 !"$%&'()*+,-./#:;<=>?@[\\\]^_`{|}~\n\r฿]*$/;
  logPrint: any = [];
  lengthViewChildPayment: number;
  selectDeclarator: any;
  perfile: any;
  @Output() newForm: EventEmitter<any> = new EventEmitter<any>();
  etc: any;
  constructor(
    private appMasterService: AppMasterService,
    private dialog: MatDialog,
    private appRequestService: AppRequestService,
    private route: ActivatedRoute,
    private appCOService: AppCOService,
    private alertService: AlertMessageService,
    private confirmService: ConfirmDialogService,
    private router: Router,
    private _location: Location,
    private appUserService: AppUserService,
    private _authService: AuthService
  ) {
    this.form.certificateDateRequest = "approve_date";
  }
  backClicked() {
    this._location.back();
  }
  async ngOnInit() {
    this.perfile = JSON.parse(this._authService.loginUser);
    this.userProfile = {
      ...this.userProfile,
      cid: this.perfile.cpId,
      gId: this.perfile.gId,
    };

    this.editItem = false;
    const { disabled, id, search, recheck, staff } =
      this.route.snapshot.queryParams;
    if (this.disabledJob !== undefined) {
      this.editItem = this.disabledJob;
    } else {
      this.editItem = disabled;
    }
    if (id != null && id != undefined && id != "") {
      this.packId = id;
    } else {
      this.packId = "0";
    }

    if (this.disabledJob !== undefined) {
      this.editItem = this.disabledJob;
      this.editJobData = true;
    } else {
      this.editItem = disabled == "true" ? true : false;
      this.editJobData = false;
    }
    if (id != null && id != undefined && id != "") {
      this.PackidLoadData = id;
    } else if (this.packIdInput != null && this.packIdInput != undefined) {
      this.PackidLoadData = this.packIdInput;
    }
    if (this.isJobDetail !== undefined) {
      // this.editItem = this.disabledJob
      this.editJobData = this.isJobDetail;
    }
    await this.loadData(this.PackidLoadData);
    await this.loadCheckValidateConfigD();
    this.search = search;
    this.recheck = recheck;
    this.staff = staff;
    this.setDefault();
    this.lengthViewChildPayment = this.detailPaymentListTable.dataSource.length;
  }
  setDefault() {
    this.form.documentSubjectTitleType = "General";
    this.form.totalPages = 1;
    this.form.book = "N";
    this.form.attachments = [];
  }

  ngAfterViewInit() {}

  loadCheckValidateConfigD() {
    // Load Validate Input symbol
    let validateRequest = {
      configHcode: "VALIDATE",
      configDcode: "V1",
    };
    this.appMasterService.getValidateInput(validateRequest).then((res: any) => {
      if (res === "error") {
        return;
      } else {
        this.symbolRegex = new RegExp(res.configDEName);
      }
    });

    // Load Validate Input english
    let validateRequestInput = {
      configHcode: "VALIDATE",
      configDcode: "V2",
    };
    this.appMasterService
      .getValidateInput(validateRequestInput)
      .then((res: any) => {
        if (res === "error") {
          return;
        } else {
          this.engRegex = new RegExp(res.configDEName);
        }
      });
  }
  async loadData(id) {
    this.request = {
      id: id,
    };
    const requestCompany = {
      //CPID: this.userProfile.uId,
      CID: this.userProfile.cid,
      isActive: "Y",
    };
    const reqType = {
      type: "CF",
    };
    Promise.all([
      this.appMasterService.getListCountry({ id: 0 }),
      this.appCOService.getRelationCompany(requestCompany),
      this.appMasterService.getListBranch({ id: 0, isActive: "Y" }),
      this.appMasterService.getListDoc(),
      this.appCOService.getListAttachTypeByType(reqType),
      this.appCOService.getListDeclaratorCompany(requestCompany),
    ])
      .then(
        ([
          countrys,
          declarators,
          branchs,
          docs,
          attypes,
          declaratorCompany,
        ]) => {
          if (countrys === "error") {
            return;
          } else {
            this.countryList = [];
            this.countryList = this.countryList.concat(countrys);
            this.form.selectedCountry = 0;
            //this.form.destination = this.countryList[0].mC_COUNTRY_NAME
          }
          if (declarators === "error") {
            return;
          } else {
            if (declarators != null) {
              declarators = declarators.map((item) => {
                if (item.blacklistStatus == "Y") {
                  item.companyNameEn = item.companyNameEn + " " + "(Blacklist)";
                  item.companyNameTh =
                    item.companyNameTh + " " + "(ติดแบล็คลิสต์)";
                }
                return item;
              });
            }
            this.declaratorList = [];
            this.declaratorList = this.declaratorList.concat(declarators);
            //this.form.firstNameDeclaratorId = 0
            this.form.firstNameDeclaratorId =
              this.declaratorList[0]?.blacklistStatus != "Y"
                ? this.declaratorList[0]?.cid ?? 0
                : 0;
            this.selectDeclarator =
              this.declaratorList[0]?.blacklistStatus != "Y"
                ? this.declaratorList[0]?.cid ?? 0
                : 0;
          }
          if (branchs === "error") {
            return;
          } else {
            this.branchList = [];
            this.branchList = this.branchList.concat(branchs);
            this.form.branchId = 1;
          }
          if (docs === "error") {
            return;
          } else {
            this.docList = [];
            this.docList = this.docList.concat(docs);
            var etcId = this.docList.find((doc) => doc.sdtName == "ETC");
            this.etc = etcId.sdtId;
            this.form.docSubTitle = 0;
          }
          if (attypes === "error") {
            return;
          } else {
            this.attachTypeList = [];
            this.attachTypeList.push({
              maT_DOC_NAME: "- กรุณาเลือก Please Select -",
              maT_DOC_TYPE_ID: 0,
            });
            this.attachTypeList = this.attachTypeList.concat(attypes);
          }
          if (declaratorCompany === "error") {
            return;
          } else {
            this.companyName = [];
            this.companyName = this.companyName.concat(declaratorCompany);
            this.form.firstName = this.companyName[0].companyNameEn;
          }
        }
      )
      .then(async (res) => {
        if (id) {
          await this.appCOService
            .getCFDetail({ docId: id } as IGetCFDetailRequest)
            .then(async (res: any) => {
              if (res === "error") {
                return;
              } else {
                if (
                  res &&
                  (res.isRqstatus == "4" ||
                    res.isRqstatus == "40" ||
                    res.isRqstatus == "5" ||
                    res.isRqstatus == "38" ||
                    res.isRqstatus == "6" ||
                    res.isRqstatus == "7" ||
                    res.isRqstatus == "8" ||
                    res.isRqstatus == "9" ||
                    res.isRqstatus == "42")
                ) {
                  this.editItem = true;
                }
                this.form = [];
                this.form = res;
                this.form.liveSignature =
                  res.liveSignature == "Y" ? true : false;
                this.form.selectedCountry = res.selectedCountry;
                this.form.documentDate = res.documentDate;
                this.form.destination = this.countryList.find(
                  (x) => x.mC_ID == this.form.selectedCountry
                )?.mC_COUNTRY_NAME;
                this.form.certificateDateRequest = res.certificateDateRespone;
                this.form.certificateDateRequestDate =
                  res.certificateDateResponeDate;
                if (
                  res.branchId == 0 ||
                  res.branchId == null ||
                  res.branchId == undefined
                ) {
                  this.form.liveSignature = false;
                } else {
                  this.form.liveSignature = true;
                }

                this.dataSourceAttachPic =
                  this.form.attachments &&
                  this.form.attachments.map((e) => {
                    return {
                      ...e,
                      key: e.attachPicId,
                      isActive: e.isActive ?? "Y",
                      isTmp: "N",
                    };
                  });

                this.formSourceUpload = [...this.dataSourceAttachPic];
                this.dataSourceUpload = [...this.dataSourceAttachPic];

                this.documentDetail = res?.documentDetails;

                this.documentDetail.certificateList =
                  this.documentDetail.certificateNo.join(", ");

                await this.appCOService
                  .LogPrintHistory({
                    id: id,
                  })
                  .then(async (res: any) => {
                    if (res === "error") {
                      return;
                    } else {
                      this.logPrint = res;
                    }
                  });
                if (this.form.isRqstatus == "10") {
                  let id = {
                    packId: this.form.id,
                  };
                  await this.appCOService
                    .getErrorMarkList(id)
                    .then((res: any) => {
                      if (res === "error") {
                        return;
                      } else {
                        this.markErrorList = res;
                        const groupByfieldId = this.markErrorList.reduce(
                          (acc, mark) => {
                            const fieldId = mark.fieldId;
                            if (!acc[fieldId]) {
                              acc[fieldId] = [];
                            }
                            acc[fieldId].push(mark);
                            return acc;
                          },
                          {}
                        );

                        const fieldId = Object.keys(groupByfieldId);
                        Object.keys(fieldId).forEach((key) => {
                          if (fieldId[key] == 12) {
                            this.fieldError.declarator = false;
                          } else if (fieldId[key] == 1) {
                            this.fieldError.consignor = false;
                          } else if (fieldId[key] == 3) {
                            this.fieldError.consignee = false;
                          } else if (fieldId[key] == 5) {
                            this.fieldError.notify = false;
                          } else if (fieldId[key] == 6) {
                            this.fieldError.vessel = false;
                          } else if (fieldId[key] == 7) {
                            this.fieldError.loading = false;
                          } else if (fieldId[key] == 15) {
                            this.fieldError.certificateDate = false;
                          } else if (fieldId[key] == 8) {
                            this.fieldError.destination = false;
                          } else if (fieldId[key] == 10) {
                            this.fieldError.invoice = false;
                          } else if (fieldId[key] == 9) {
                            this.fieldError.country = false;
                          } else if (fieldId[key] == 11) {
                            this.fieldError.item = false;
                          } else if (fieldId[key] == 16) {
                            this.fieldError.footer = false;
                          }
                        });
                      }
                    });
                }
              }
            });
        }
      });
  }
  save() {
    this.clicked = true;
    const data = {
      firstName: this.form.firstName ?? "",
      firstNameDeclaratorId: this.form.firstNameDeclaratorId ?? "",
      destination: this.form.destination ?? "",
      docSubTitle: this.form.docSubTitle ?? "",
      docSubTitleId: this.form.docSubTitle,
      documentSubjectTitleType: this.form.documentSubjectTitleType ?? "",
      documentReferentNo: this.form.documentReferentNo,
      totalPages: this.form.totalPages,
      book: this.form.book ?? "",
      approveDatetime: this.form.approveDatetime,
      requestDatetime: this.form.requestDatetime,
      invoiceDate: this.form.invoiceDate,
      liveSignature: this.form.liveSignature == true ? "Y" : "N",
      branchId: this.form.liveSignature == true ? this.form.branchId : 0,
      documentDate: this.form.documentDate,
      docSubTitleEtc: this.form.docSubTitleEtc ?? "",
      companyId: this.perfile.cpId,
      certificateDateRequest: this.form.certificateDateRequest,
      certificateDateRequestDate: this.form.certificateDateRequestDate ?? null,
      isRqstatus: this.form.isRqstatus ? this.form.isRqstatus : 3,
      isPackRq: "N",
      docType: "CF",
      packName: "",
      docPriority: this.form.docPriority ? this.form.docPriority : "5",
      receiptNo: "",
      consignorId: this.form.firstNameDeclaratorId,
      declaratorId: this.form.firstNameDeclaratorId,
      consignor: "-",
      consignorName: this.form.firstNameDeclaratorId,
      sigBranchId: 1,
      uId: this.perfile.uId,
      requestBy: this.perfile.uId,
      isActive: "Y",
      id: this.packId,
      attachments: this.form.attachments,
    };
    let checked = this.validForm(data, this.dataSourceAttachPic, "save");
    if (!checked) {
      this.clicked = false;
      return;
    }
    data.attachments =
      this.deleteAttachmentList.length == 0
        ? this.form.attachments
        : this.form.attachments?.concat(this.deleteAttachmentList);

    if (this.subEdit) {
      return this.newForm.emit(data);
    }

    return this.appRequestService.saveCF(data).then((res: any) => {
      this.clicked = false;
      if (res === "error") {
        return;
      } else {
        this.alertService
          .open({
            message: "บันทึกสำเร็จ",
            title: "ทำรายการสำเร็จ",
          })
          .then((x) => {
            this.router.navigate(["/page/request/request-cf/list-cf"]);
          });
      }
    });
  }

  savePopup() {
    this.clicked = true;
    const data = {
      firstName: this.form.firstName ?? "",
      firstNameDeclaratorId: this.form.firstNameDeclaratorId ?? "",
      destination: this.form.destination ?? "",
      docSubTitle: this.form.docSubTitle ?? "",
      docSubTitleId: this.form.docSubTitle,
      documentSubjectTitleType: this.form.documentSubjectTitleType ?? "",
      documentReferentNo: this.form.documentReferentNo,
      totalPages: this.form.totalPages,
      book: this.form.book ?? "",
      approveDatetime: this.form.approveDatetime,
      requestDatetime: this.form.requestDatetime,
      invoiceDate: this.form.invoiceDate,
      liveSignature: this.form.liveSignature == true ? "Y" : "N",
      branchId: this.form.liveSignature == true ? this.form.branchId : 0,
      documentDate: this.form.documentDate,
      docSubTitleEtc: this.form.docSubTitleEtc ?? "",
      companyId: this.perfile.cpId,
      certificateDateRequest: this.form.certificateDateRequest,
      certificateDateRequestDate: this.form.certificateDateRequestDate ?? null,
      isRqstatus: 4,
      isPackRq: "N",
      docType: "CF",
      packName: "",
      docPriority: this.form.docPriority ? this.form.docPriority : "5",
      receiptNo: "",
      consignorId: this.form.firstNameDeclaratorId,
      declaratorId: this.form.firstNameDeclaratorId,
      consignor: "-",
      consignorName: "-",
      sigBranchId: 1,
      uId: this.perfile.uId,
      requestBy: this.perfile.uId,
      isActive: "Y",
      attachments: this.form.attachments,
      id: this.packId,
    };

    let checked = this.validForm(data, this.dataSourceAttachPic, "saveQ");
    if (!checked) {
      this.clicked = false;
      return;
    }
    data.attachments =
      this.deleteAttachmentList.length == 0
        ? this.form.attachments
        : this.form.attachments?.concat(this.deleteAttachmentList);
    const queueDialog = this.dialog.open(QueuePopupComponent, {
      disableClose: true,
      width: "25vw",
      data: {
        docPriority: this.form.docPriority ? this.form.docPriority : "5",
        id: data.id,
        rqStatus: this.form.isRqstatus,
      },
      maxWidth: "none",
    });

    queueDialog.afterClosed().subscribe((res) => {
      if (res === undefined) {
        this.clicked = false;
        return;
      } else {
        data.docPriority = res.docPriority;
        this.appRequestService.saveCF(data).then((res: any) => {
          if (res === "error") {
            this.clicked = false;
            return;
          } else {
            this.alertService
              .open({
                message: "บันทึกสำเร็จ",
                title: "ทำรายการสำเร็จ",
              })
              .then((x) => {
                this.router.navigate(["/page/request/request-cf/list-cf"]);
              });
          }
        });
      }
    });
  }

  onChange(e) {
    if (e.checked == true) {
      this.form.isActive = "Y";
    } else {
      this.form.isActive = "N";
    }
  }

  validForm(e, a, saveType): boolean {
    let fieldError: string[] = [];

    if (
      !e.firstNameDeclaratorId ||
      e.firstNameDeclaratorId === undefined ||
      e.firstNameDeclaratorId == 0 ||
      e.firstNameDeclaratorId == ""
    ) {
      fieldError.push("ระบุผู้ส่งออกสินค้า");
    }
    /*if (
      !e.destination ||
      e.destination === undefined ||
      e.destination == 0 ||
      e.destination == ''
    ) {
      fieldError.push('ระบุประเทศปลายทาง')
    }*/
    if (
      !e.destination ||
      e.destination === undefined ||
      e.destination == 0 ||
      e.destination == ""
    ) {
      fieldError.push("ระบุปลายทางสินค้าที่ถูกยกลง");
    }
    if (
      !e.docSubTitle ||
      e.docSubTitle === undefined ||
      e.docSubTitle == 0 ||
      e.docSubTitle == ""
    ) {
      fieldError.push("ระบุประเภทเอกสาร");
    }
    if (
      !e.documentSubjectTitleType ||
      e.documentSubjectTitleType === undefined ||
      e.documentSubjectTitleType == 0 ||
      e.documentSubjectTitleType == ""
    ) {
      fieldError.push("ระบุประเภทเอกสาร");
    }
    if (e.documentReferentNo === undefined || e.documentReferentNo == "") {
      fieldError.push("ระบุเลขที่เอกสาร");
    }
    if (!this.textRegex.test(e.documentReferentNo)) {
      fieldError.push("ระบุเลขที่เอกสารกรอกได้เฉพาะภาษาอังกฤษและสัญลักษณ์");
    }
    //Check วันที่ในเอกสาร
    if (
      !e.documentDate ||
      e.documentDate === undefined ||
      e.documentDate == 0 ||
      e.documentDate == ""
    ) {
      fieldError.push("ระบุวันที่ในเอกสาร");
    }
    if (
      !e.totalPages ||
      e.totalPages === undefined ||
      e.totalPages == 0 ||
      e.totalPages == ""
    ) {
      fieldError.push("ระบุจำนวนหน้า");
    }
    if (
      !e.certificateDateRequest ||
      e.certificateDateRequest === undefined ||
      e.certificateDateRequest == 0 ||
      e.certificateDateRequest == ""
    ) {
      fieldError.push("ระบุวันที่ออกเอกสาร");
    }
    if (!e.book || e.book === undefined || e.book == 0 || e.book == "") {
      fieldError.push("ระบุเอกสาร");
    }
    if (
      e.certificateDateRequest == "on_date" &&
      (!e.certificateDateRequestDate ||
        e.certificateDateRequestDate === undefined ||
        e.certificateDateRequestDate == 0 ||
        e.certificateDateRequestDate == "")
    ) {
      fieldError.push("ระบุวันที่ของวันล่วงหน้า");
    }
    if (
      e.liveSignature == "Y" &&
      (!e.branchId ||
        e.branchId === undefined ||
        e.branchId == 0 ||
        e.branchId == "")
    ) {
      fieldError.push("ระบุสาขาลายเซ็น");
    }

    if (saveType === "saveQ") {
      if (!a || a === undefined || a.length < 1) {
        fieldError.push("แนบเอกสารประกอบ");
      }

      var tmpItemDoc = true;
      //e.attachments.forEach((x) => {
      a.forEach((x) => {
        if (
          !x.attachType ||
          x.attachType === undefined ||
          x.attachType == 0 ||
          x.attachType == ""
        ) {
          fieldError.push("เลือกประเภทเอกสารแนบ");
          tmpItemDoc = false;
          return;
        }
      });
    }

    if (
      this.subEdit == true &&
      (!e.jobRemark ||
        e.jobRemark === undefined ||
        e.jobRemark == 0 ||
        e.jobRemark == "")
    ) {
      fieldError.push("ระบุสาเหตุที่ผิดพลาด");
    }

    if (fieldError.length === 0) {
      return true;
    } else {
      fieldError.forEach((e, i) => {
        fieldError[i] = (i + 1).toString() + ") " + e;
      });

      fieldError.unshift("กรุณาตรวจสอบข้อผิดพลาดดังต่อไปนี้");
      const result: string = fieldError.join("\n");

      this.alertService.open({ message: result, title: "" });
      return false;
    }
  }

  // async onFileChangedBr(event): Promise<void> {
  //   if (event.value.length === 0) {
  //     return;
  //   }
  //   // const file = event.value.files[0];
  //   event.value.forEach((item) => {
  //     this.file = item;
  //     this.onUploadBr();
  //   });

  //   this.value = [];
  // }

  async onFileChangedBr(): Promise<void> {
    if (this.value.length === 0) {
      return;
    }
    // const file = event.value.files[0];
    this.value.forEach((item) => {
      this.file = item;
      if (this.file.size > 3000000) {
        this.alertService.open({
          message: "รูปมีขนาดใหญ่กว่า 3 MB",
          title: "กรุณากรอกข้อมูลให้ครบถ้วน",
        });
      } else {
        this.resizeImage(this.file);
      }
    });
    this.value = [];
  }

  resizeImage(file) {
    var maxW = 1024; //กำหนดความกว้าง
    var maxH = 800; //กำหนดความสูง
    var myReader: FileReader = new FileReader();
    var canvas = document.createElement("canvas");
    var context = canvas.getContext("2d");

    var img = document.createElement("img");
    var that = this;
    var result = "";

    img.onload = async function () {
      var iw = img.width;
      var ih = img.height;

      var scale = Math.min(maxW / iw, maxH / ih);
      var iwScaled = iw * scale;
      var ihScaled = ih * scale;

      canvas.width = iwScaled;
      canvas.height = ihScaled;

      context.drawImage(img, 0, 0, iwScaled, ihScaled);

      result += canvas.toDataURL("image/jpeg", 0.5); //0.5 คือ คุณภาพของรูป ที่ Resize

      that.imageResize = result; //Base64 รูปภาพ ที่ย่อขนาดแล้ว

      await that.onUpload(that.dataUrlToFile(result, "test.jpeg"));
      var a = myReader.readAsDataURL(file);
    };

    img.src = URL.createObjectURL(file);
  }

  dataUrlToFile(dataUrl: string, filename: string): File | undefined {
    const arr = dataUrl.split(",");
    if (arr.length < 2) {
      return undefined;
    }
    const mimeArr = arr[0].match(/:(.*?);/);
    if (!mimeArr || mimeArr.length < 2) {
      return undefined;
    }
    const mime = mimeArr[1];
    const buff = Buffer.from(arr[1], "base64");
    return new File([buff], filename, { type: mime });
  }

  upload() {
    if (this.value.length > 0) {
      if (this.value.filter((x) => x.size > 3145728).length > 0) {
        this.alertService.open({
          message: "รูปมีขนาดใหญ่กว่า 3 MB",
          title: "โปรดตรวจสอบขนาดรูปภาพ",
        });
        return;
      }

      if (
        this.value.filter(
          (x) => x.type != "image/jpeg" && x.type != "image/png"
        ).length > 0
      ) {
        this.alertService.open({
          message: "รูปที่เลือกไม่ถูกต้อง กรุณาอัปโหลดเฉพาะไฟล์ .JPG หรือ .PNG เท่านั้น หรือตรวจสอบการตั้งค่าเครื่องสแกนเนอร์",
          title: "",
        });
        return;
      }

      let maxId = _.max(this.form.attachment, function (item) {
        return item.key;
      });

      this.value.forEach(async (file) => {
        const isValid = await this.checkIfImageIsValid(file);
        if (!isValid) {
          this.alertService.open({
            message: "รูปที่เลือกไม่ถูกต้อง กรุณาอัปโหลดไฟล์ที่ไม่ใช่ Animated PNG",
            title: "โปรดตรวจสอบรูปภาพ",
          });
          return;
        }

        const fileName = file.name;
        const formData = new FormData();
        formData.append("image", file, fileName);
        this.appMasterService.uploadFileBatch(formData).subscribe((result) => {
          if (result) {
            result.forEach((element) => {
              let index = this.form.attachments.length + 1;
              const data = {
                attachPicId: 0,
                key: maxId + 1,
                docHid: 0,
                attachFilePic: element?.filePath,
                attachType: 0,
                attachIndex: index,
                remark: "",
                attachHeaderType: 2,
                isActive: "Y",
                isTmp: "Y",
              };
              this.form.attachments.push(data);
              this.dataSourceAttachPic.push(data);
            });

            this.formSourceUpload = [];
            this.formSourceUpload = [...this.form.attachments];
            this.dataSourceUpload = [];
            this.dataSourceUpload = [...this.dataSourceAttachPic];

            this.value = [];
          } else {
            return;
          }
        });
      });
      this.value = [];
    } else {
      this.alertService.open({
        message: "กรุณาเลือกรูปภาพหรือเอกสารอย่างน้อยชนิด",
        title: "",
      });
    }
  }

  onUpload(file: any): void {
    if (file.size > 24000000) {
      this.alertService.open({
        message: "รูปมีขนาดใหญ่กว่า 3 MB",
        title: "โปรดตรวจสอบขนาดรูปภาพ",
      });
      return;
    }

    this.checkIfImageIsValid(file).then((isValid) => {
      if (!isValid) {
        this.alertService.open({
          message: "รูปที่เลือกไม่ถูกต้อง กรุณาอัปโหลดไฟล์ที่ไม่ใช่ Animated PNG",
          title: "โปรดตรวจสอบรูปภาพ",
        });
        return;
      }

      const formData = new FormData();
      formData.append("image", this.file, this.file.name);
      this.appMasterService.uploadFile(formData).subscribe((result) => {
        if (result) {
          let index = this.form.attachments.length + 1;
          const data = {
            attachPicId: 0,
            docHid: 0,
            attachFilePic: result[0]?.filePath,
            attachType: 0,
            attachIndex: index,
            remark: "",
            attachHeaderType: 2,
            isActive: "Y",
          };
          // this.fileImg = result[0]?.filePath;
          // this.pic = result[0]?.fileName + result[0]?.fileType;

          this.form.attachments.push(data);
          this.dataSourceAttachPic.push(data);

          this.formSourceUpload = [];
          this.formSourceUpload = [...this.form.attachments];
          this.dataSourceUpload = [];
          this.dataSourceUpload = [...this.dataSourceAttachPic];
        }
      });
    });
  }

  checkIfImageIsValid(file: File): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onload = () => {
        const buffer = reader.result as ArrayBuffer;
        const uint8Array = new Uint8Array(buffer);
  
        // PNG signature bytes: 137 80 78 71 13 10 26 10
        const pngSignature = [137, 80, 78, 71, 13, 10, 26, 10];
        // JPEG signature bytes: 255 216 255
        const jpgSignature = [255, 216, 255];
  
        // Check if it's a PNG by matching the signature
        const isPng = pngSignature.every((byte, i) => uint8Array[i] === byte);
  
        // Check if it's a JPEG by matching the signature
        const isJpg = jpgSignature.every((byte, i) => uint8Array[i] === byte);
  
        if (isPng) {
          // Search for the acTL chunk in the PNG file (animation chunk)
          const acTLIndex = this.findAcTLChunk(uint8Array);
          if (acTLIndex !== -1) {
            resolve(false); // Animated PNG
          } else {
            resolve(true); // Static PNG
          }
        } else if (isJpg) {
          resolve(true); // Valid JPEG
        } else {
          reject(new Error('Invalid file format')); // Not PNG or JPEG
        }
      };
  
      reader.onerror = () => reject(reader.error);
  
      reader.readAsArrayBuffer(file);
    });
  }
  
  findAcTLChunk(uint8Array: Uint8Array): number {
    // Search for the "acTL" chunk in the PNG file starting after the 8-byte PNG header
    const acTLString = "acTL";
    const decoder = new TextDecoder("ascii");
  
    for (let i = 12; i < uint8Array.length - 4; i++) {
      const chunk = decoder.decode(uint8Array.slice(i, i + 4));
      if (chunk === acTLString) {
        return i; // Found the acTL chunk
      }
    }
    return -1; // acTL chunk not found
  }  

  onDataSourceUpdate(e) {
    this.dataSourceAttachPic = e;
  }

  onFormSourceUpdate(e) {
    this.form.attachments = e;
  }

  back() {
    window.history.back();
  }

  onRowRemoving(e): void {
    for (let index = 0; index < this.dataList.length; index++) {
      if (this.dataList[index].taskID === e.key) {
        this.dataList.splice(this.dataList[index], 1);
      }
    }

    // this.removeItem(e.data);
  }

  selectionDestination() {
    this.form.destination = this.countryList.filter(
      (x) => x.mC_ID == this.form.selectedCountry
    )[0].mC_COUNTRY_NAME;
  }

  selectionDeclarator(e) {
    var checkData = this.declaratorList.find((x) => x.cid === e);

    if (checkData.emailStatus === "N") {
      this.alertService.open({
        message:
          "บริษัทผู้ส่งออกนี้ ยังไม่ได้ลงทะเบียนรับเอกสารอิเล็กทรอนิกส์ กรุณาลงทะเบียนด้วย User admin ของบริษัทผู้ส่งออก",
        title: "",
      });
    }
  }

  checkEnglish(e) {
    if (e != null && e != "" && e != undefined) {
      var validateInput = this.textRegex.test(e);
    }
    return validateInput;
  }

  selectionDocSubTitle() {}

  selectionBranch() {}

  totalChange(): void {
    if (this.form.totalPages > 9) {
      this.form.book = "Y";
    } else {
      this.form.book = "N";
    }
  }

  saveChanges(event: any) {
    event.preventDefault(); // prevent the default save behavior
    const changes = event.changes.map((c: any) => c.data); // get the changed data objects
    // TODO: save changes to the server
  }

  saveQ() {
    this.clicked = true;
    const data = {
      firstName: this.form.firstName ?? "",
      firstNameDeclaratorId: this.form.firstNameDeclaratorId ?? "",
      destination: this.form.destination ?? "",
      docSubTitle: this.form.docSubTitle ?? "",
      docSubTitleId: this.form.docSubTitle,
      documentSubjectTitleType: this.form.documentSubjectTitleType ?? "",
      documentReferentNo: this.form.documentReferentNo,
      totalPages: this.form.totalPages,
      book: this.form.book ?? "",
      approveDatetime: this.form.approveDatetime,
      requestDatetime: this.form.requestDatetime,
      invoiceDate: this.form.invoiceDate,
      liveSignature: this.form.liveSignature == true ? "Y" : "N",
      branchId: this.form.liveSignature == true ? this.form.branchId : 0,
      documentDate: this.form.documentDate,
      docSubTitleEtc: this.form.docSubTitleEtc ?? "",
      companyId: this.perfile.cpId,
      certificateDateRequest: this.form.certificateDateRequest,
      certificateDateRequestDate: this.form.certificateDateRequestDate ?? null,
      isRqstatus: "4",
      isPackRq: "N",
      docType: "CF",
      packName: "",
      docPriority: this.form.docPriority ? this.form.docPriority : "5", // ไม่ควร ทำไม่ต้องเป็น 5
      receiptNo: "",
      consignorId: "-",
      declaratorId: this.form.firstNameDeclaratorId,
      consignor: "-",
      consignorName: "-",
      sigBranchId: 1,
      uId: this.perfile.uId,
      requestBy: this.perfile.uId,
      isActive: "Y",
      id: this.packId,
      attachments: this.form.attachments,
    };
    let checked = this.validForm(data, this.dataSourceAttachPic, "saveQ");
    if (!checked) {
      this.clicked = false;
      return;
    }
    if (this.subEdit) {
      return this.newForm.emit(data);
    }
    if (this.validForm(data, this.dataSourceAttachPic, "saveQ")) {
      this.appCOService.getPreviewCF(data).then((res) => {
        if (res == "error") {
          return;
        } else {
          const DraftdialogRef = this.dialog.open(DraftCFPopupComponent, {
            disableClose: true,
            width: "25vw",
            data: {
              data: res,
            },
            maxWidth: "none",
          });
          DraftdialogRef.afterClosed().subscribe((res) => {
            if (res == false) {
              return;
            } else {
              const queueDialog = this.dialog.open(QueuePopupComponent, {
                disableClose: true,
                width: "25vw",
                data: {},
                maxWidth: "none",
              });

              queueDialog.afterClosed().subscribe((res) => {
                if (res == false) {
                  return;
                } else {
                  data.docPriority = res.docPriority;
                  this.appRequestService.saveCF(data).then((res: any) => {
                    this.clicked = false;
                    if (res === "error") {
                      return;
                    } else {
                      this.alertService
                        .open({
                          message: "บันทึกสำเร็จ",
                          title: "ทำรายการสำเร็จ",
                        })
                        .then((x) => {
                          if (x == true) {
                            this.router.navigate([
                              "/page/request/request-co/list-co",
                            ]);
                          }
                        });
                    }
                  });
                }
              });
            }
          });
        }
      });
    }
  }
  emitForm(remark: string) {
    /*const data = {
      firstName: this.form.firstName ?? '',
      firstNameDeclaratorId: this.form.firstNameDeclaratorId ?? '',
      destination: this.form.destination ?? '',
      docSubTitle: this.form.docSubTitle ?? '',
      docSubTitleId: this.form.docSubTitle,
      documentSubjectTitleType: this.form.documentSubjectTitleType ?? '',
      documentReferentNo: this.form.documentReferentNo,
      totalPages: this.form.totalPages,
      book: this.form.book ?? '',
      approveDatetime: this.form.approveDatetime,
      requestDatetime: this.form.requestDatetime,
      invoiceDate: this.form.invoiceDate,
      liveSignature: this.form.isLiveSignature == true ? 'Y' : 'N',
      branchId: this.form.branchId,
      isRqstatus: '1',
      isPackRq: 'N',
      docType: 'CF',
      packName: '',
      docPriority: '5',
      receiptNo: '',
      consignorId: this.form.firstNameDeclaratorId,
      declaratorId: this.form.firstNameDeclaratorId,
      consignor: this.form.firstNameDeclaratorId,
      consignorName: this.form.firstNameDeclaratorId,
      sigBranchId: 0,
      uId: JSON.parse(this.appUserService.getSessionUserProfile()).uId,
      requestBy: JSON.parse(this.appUserService.getSessionUserProfile()).name,
      isActive: 'Y',

      attachments: this.form.attachments,
    }*/
    const data = {
      firstName: this.form.firstName ?? "",
      firstNameDeclaratorId: this.form.firstNameDeclaratorId ?? "",
      destination: this.form.destination ?? "",
      docSubTitle: this.form.docSubTitle ?? "",
      docSubTitleId: this.form.docSubTitle,
      documentSubjectTitleType: this.form.documentSubjectTitleType ?? "",
      documentReferentNo: this.form.documentReferentNo,
      totalPages: this.form.totalPages,
      book: this.form.book ?? "",
      approveDatetime: this.form.approveDatetime,
      requestDatetime: this.form.requestDatetime,
      invoiceDate: this.form.invoiceDate,
      liveSignature: this.form.liveSignature == true ? "Y" : "N",
      branchId: this.form.liveSignature == true ? this.form.branchId : 0,
      documentDate: this.form.documentDate,
      docSubTitleEtc: this.form.docSubTitleEtc ?? "",
      companyId: this.perfile.cpId,
      certificateDateRequest: this.form.certificateDateRequest,
      certificateDateRequestDate: this.form.certificateDateRequestDate ?? null,
      isRqstatus: "3",
      isPackRq: "N",
      docType: "CF",
      packName: "",
      docPriority: this.form.docPriority ? this.form.docPriority : "5", // ไม่ควร ทำไม่ต้องเป็น 5
      receiptNo: "",
      consignorId: this.form.firstNameDeclaratorId,
      declaratorId: this.form.firstNameDeclaratorId,
      consignor: "-",
      consignorName: this.form.firstNameDeclaratorId,
      sigBranchId: 1,
      uId: this.perfile.uId,
      requestBy: this.perfile.name,
      isActive: "Y",
      id: this.packIdInput,
      jobRemark: remark,
      attachments: this.form.attachments,
    };
    data.attachments =
      this.deleteAttachmentList.length == 0
        ? this.form.attachments
        : this.form.attachments?.concat(this.deleteAttachmentList);

    let checked = this.validForm(data, this.dataSourceAttachPic, "save");
    if (!checked) {
      this.clicked = false;
      return;
    }
    if (checked == true) {
      this.newForm.emit(data);
      this.appCOService.saveCFRequestJob(data).then((res: any) => {
        if (res) {
          this.alertService
            .open({
              message: "บันทึกสำเร็จ",
              title: "ทำรายการสำเร็จ",
            })
            .then((x) => {
              this.router.navigate(["/page/request/list-job"]);
            });
        } else {
          return;
        }
      });
      /*return this.appRequestService.saveCF(data).then((res: any) => {
        if (res === 'error') {
          return
        } else {
          this.alertService
            .open({
              message: 'บันทึกเรียบร้อย',
              title: '',
            })
            .then((x) => {
              this.router.navigate(['/page/request/request-cf/list-cf'])
            })
        }
      })*/
    }
  }
  deleteAttachment(e) {
    this.confirmService
      .open({
        header: "ยืนยันการทำรายการ",
        content: "ยืนยันการลบข้อมูลนี้ใช่หรือไม่?",
      })
      .then((res) => {
        if (res == true) {
          if (e.data.attachPicId != 0) {
            this.dataSourceAttachPic.splice(e.rowIndex, 1);
            const updAttach = this.form.attachments.findIndex(
              (obj) => obj.attachPicId == e.data.attachPicId
            );
            this.form.attachments[updAttach].isActive = "N";
          } else {
            // this.form.items[e.rowIndex].isActive = "N"
            this.dataSourceAttachPic.splice(e.rowIndex, 1);
            this.form.attachments.splice(e.rowIndex, 1);
          }
        }
      });
  }
  onClick(e) {
    const dialogRef = this.dialog.open(CoErrorMarkImagePopupComponent, {
      disableClose: false,
      // width: '30vw',
      data: {
        urlPic: e.data.urlPic,
        px: e.data.pos_x,
        py: e.data.pos_y,
        mark: e.data.remark,
      },
      maxWidth: "90vw",
    });
  }

  onView(e) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.minHeight = "30vh";
    dialogConfig.minWidth = "30vh";
    dialogConfig.maxHeight = "100vh";
    dialogConfig.data = {
      fullImageUrl: e.data.attachFilePic,
    };

    const dialogRef = this.dialog.open(ImageModalComponent, dialogConfig);
  }
}
