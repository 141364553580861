<div style="display: flex;flex-flow: column;height: 100%;gap: 30px;" (keydown.enter)="$event.preventDefault()">
    <div style="display: flex;flex-flow: column;margin-top: 30px;" class="pl-10 pr-10">
        <div class="menu">
            <div class="gap-2.5 flex flex-row w-full">
                <div class="justify-start flex gap-4 flex-row " style="flex: 0 100%;">
                    <div (click)="searchSelfPrintStaffBranch()"
                        [class]="printStatus == 3 ?'inline-flex items-center px-5 py-2.5 text-lg font-medium text-center text-green-700 bg-green-100 rounded-lg hover:bg-green-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-100 dark:focus:ring-green-800 cursor-default' :'inline-flex items-center px-5 py-2.5 text-lg font-medium text-center hover:bg-gray-200 bg-gray-100 text-gray-800 rounded-lg cursor-default'">
                        เอกสารที่หอการค้าพิมพ์(เฉพาะสาขา)
                    </div>
                    <div (click)="searchSelfPrintStaff()"
                        [class]="printStatus == 2 ?'inline-flex items-center px-5 py-2.5 text-lg font-medium text-center text-green-700 bg-green-100 rounded-lg hover:bg-green-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-100 dark:focus:ring-green-800 cursor-default' :'inline-flex items-center px-5 py-2.5 text-lg font-medium text-center hover:bg-gray-200 bg-gray-100 text-gray-800 rounded-lg cursor-default'">
                        เอกสารที่หอการค้าพิมพ์
                    </div>
                    <div (click)="searchAll()"
                        [class]="printStatus == 1 ?'inline-flex items-center px-5 py-2.5 text-lg font-medium text-center text-green-700 bg-green-100 rounded-lg hover:bg-green-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-100 dark:focus:ring-green-800 cursor-default' :'inline-flex items-center px-5 py-2.5 text-lg font-medium text-center hover:bg-gray-200 bg-gray-100 text-gray-800 rounded-lg cursor-default'">
                        เอกสารทั้งหมด
                    </div>
                </div>
            </div>
            <div style="display: flex;flex-flow: row;gap: 10px;flex: 1;justify-content: flex-end;">
                <div class="gap-2.5 flex flex-row">
                    <button mat-raised-button color="accent" (click)="onRefresh(status)">
                      <div class="flex items-center">
                        <span class="icon-text"><mat-icon svgIcon="heroicons_outline:refresh"></mat-icon>รีเฟรชข้อมูล</span>
                      </div>
                    </button>
                </div>
                <button mat-raised-button color="accent" [matMenuTriggerFor]="menu">
                    <div style="display: flex;align-items: center;">
                        <span>เงื่อนไขการค้นหา</span>
                        <mat-icon>expand_more</mat-icon>
                    </div>
                </button>
                <mat-menu #menu="matMenu" xPosition="before" class="my-menu">
                    <div style="display: flex;flex-flow: column;gap: 15px;" class="pl-2 pr-2"
                        (click)="stopPropagation($event)">
                        <div class="mat-button p-2" style="gap: 10px;">
                            <mat-form-field appearance="fill" style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>วันที่เริ่มต้น | Start Date</mat-label>
                                <input matInput [matDatepicker]="startDate" type="datetime"
                                    [(ngModel)]="searchForm.startDate" (click)="startDate.open()" readonly
                                    name="startDateSearch" #startDateSearch="ngModel" class="cursor-pointer">
                                <mat-hint>MM/DD/YYYY</mat-hint>
                                <mat-datepicker-toggle matIconSuffix [for]="startDate"></mat-datepicker-toggle>
                                <mat-datepicker #startDate></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field appearance="fill" style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>วันที่สิ้นสุด | End Date</mat-label>
                                <input matInput [matDatepicker]="endDate" type="datetime"
                                    [(ngModel)]="searchForm.endDate" name="endDateSearch" (click)="endDate.open()"
                                    readonly #endDateSearch="ngModel" class="cursor-pointer">
                                <mat-hint>MM/DD/YYYY</mat-hint>
                                <mat-datepicker-toggle matIconSuffix [for]="endDate"></mat-datepicker-toggle>
                                <mat-datepicker #endDate></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="mat-button p-2" style="gap: 10px;">
                            <mat-form-field style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>เลขที่ใบคำขอ | Request No.</mat-label>
                                <input autocomplete="off" type="text" matInput placeholder="เลขที่ใบคำขอ" matInput
                                    #input [(ngModel)]="searchForm.requestNo" name="requestNo" #requestNo="ngModel">
                            </mat-form-field>
                            <mat-form-field style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>เลขที่ THT | Certificate No.</mat-label>
                                <input autocomplete="off" type="text" matInput placeholder="เลขที่ THT" matInput #input
                                    [(ngModel)]="searchForm.certificateNo" name="certificateNo"
                                    #certificateNo="ngModel">
                            </mat-form-field>
                        </div>
                        <div class="mat-button p-2" style="gap: 10px;">
                            <mat-form-field style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>เลขที่ใบกำกับสินค้า/ เลขที่เอกสาร | Invoice No./ Reference No.</mat-label>
                                <input autocomplete="off" type="text" matInput placeholder="เลขที่ใบกำกับ/ เลขที่เอกสาร"
                                    matInput #input [(ngModel)]="searchForm.invoiceReference" name="invoiceReference"
                                    #invoiceReference="ngModel">
                            </mat-form-field>
                            <mat-form-field style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>เลขที่ใบเสร็จ | Receipt No.</mat-label>
                                <input autocomplete="off" type="text" matInput placeholder="เลขที่ใบเสร็จ" matInput
                                    #input [(ngModel)]="searchForm.receiptNo" name="receiptNo"
                                    #invoiceReference="ngModel">
                            </mat-form-field>
                        </div>
                        <div class="mat-button p-2" style="gap: 10px;">
                            <mat-form-field style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>เลขที่อ้างอิงชำระเงิน | Payment Reference No.</mat-label>
                                <input autocomplete="off" type="text" matInput placeholder="เลขที่อ้างอิงชำระเงิน"
                                    matInput #input [(ngModel)]="searchForm.payRefNo" name="payRefNo"
                                    #invoiceReference="ngModel">
                            </mat-form-field>
                        </div>
                        <div class="mat-button  p-2" style="gap: 10px;">
                            <form name="searchCompanyForm" [formGroup]="searchCompanyForm"
                                style="display: flex;flex-flow: row;flex: 1">
                                <mat-form-field style="display: flex;flex-flow: row;flex: 1">
                                    <mat-label>รหัสบริษัท | Company Code</mat-label>
                                    <input matInput type="text" formControlName="companySearch" [matAutocomplete]="auto"
                                        name="companyCode">
                                    <mat-autocomplete #auto="matAutocomplete" required
                                        (optionSelected)="selectionChange($event)" [panelWidth]="'auto'">
                                        <mat-option *ngIf="isLoading">
                                            <mat-spinner [diameter]="20"></mat-spinner>
                                        </mat-option>
                                        <mat-option *ngFor="let option of filteredOptions$  | async" [value]="option">
                                            {{option.display}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </form>
                        </div>
                        <div class="mat-button  p-2" style="gap: 10px;">
                            <mat-form-field style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>ชื่อบริษัท | Company Name</mat-label>
                                <input autocomplete="off" type="text" matInput placeholder="ชื่อบริษัท" matInput #input
                                    [(ngModel)]="searchForm.companyName" name="companyName" #companyName="ngModel">
                            </mat-form-field>
                        </div>

                        <div class="mat-button p-2" style="gap: 10px;">
                            <mat-form-field appearance="fill" style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>สถานะ | Status</mat-label>
                                <mat-select [(ngModel)]="searchForm.status" name="status" #status="ngModel">
                                    <mat-option *ngFor="let statusOption of statusOptions"
                                        [value]="statusOption.statusId">
                                        {{ statusOption.statusNameTh}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="flex justify-end pt-2">
                            <div>
                                <button mat-raised-button (click)="clearOption()"
                                    style="flex:0 50%; margin-right: 10px;">
                                    เคลียร์
                                </button>
                                <button mat-raised-button color="accent" (click)="saveSearch(printStatus)"
                                    style="flex:0 50%;">
                                    ค้นหา
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-menu>
            </div>
        </div>
    </div>

    <div class="mat-elevation-z8 ">
        <table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">

            <!-- Position Column -->
            <!-- bankAccountNo Column -->
            <ng-container matColumnDef="requestNo">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> RQ No. </th>
                <td mat-cell *matCellDef="let element"> {{element.requestNo}} </td>
            </ng-container>

            <ng-container matColumnDef="companyName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> ชื่อบริษัท </th>
                <td mat-cell *matCellDef="let element"> {{element.companyName}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="invoiceRefNo">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class=""> Invoice No./ Reference No.
                </th>
                <td mat-cell *matCellDef="let element"> {{element.invoiceRefNo}} {{element.refNo}}
                </td>
            </ng-container>

            <ng-container matColumnDef="requestDatetime">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class=""> วัน/เวลาที่ยื่น </th>
                <td mat-cell *matCellDef="let element">
                    {{element.requestDatetime | date:'yyyy-MM-dd'}} <br>
                    {{element.requestDatetime | date:'HH:mm'}} </td>
            </ng-container>

            <ng-container matColumnDef="docType">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class=""> ประเภทเอกสาร </th>
                <td mat-cell *matCellDef="let element">
                    {{element.docType}} </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="certificateNo">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class=""> เลขที่ THT </th>
                <td mat-cell *matCellDef="let element"> {{element.thtNo}} </td>
            </ng-container>

            <ng-container matColumnDef="paymentReferenceNo">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="">เลขที่อ้างอิง</th>
                <td mat-cell *matCellDef="let element"> {{element.paymentReferenceNo}} </td>
            </ng-container>

            <ng-container matColumnDef="receiptNo">
                <th mat-header-cell mat-sort-header *matHeaderCellDef
                    style="text-align: left;white-space: normal; width:100px;">เลขที่ใบเสร็จ</th>
                <td mat-cell *matCellDef="let element"> {{element.receiptNo}} </td>
            </ng-container>

            <ng-container matColumnDef="isRqStatus">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> สถานะคำขอ </th>
                <td mat-cell *matCellDef="let element">
                    {{element.isRqStatus}} </td>
            </ng-container>

            <ng-container matColumnDef="expressType">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left;">เร่งด่วน</th>
                <td mat-cell *matCellDef="let element">
                    {{element.docPriority}} </td>
            </ng-container>

            <ng-container matColumnDef="isPrinted">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> สถานะการพิมพ์เอกสาร </th>
                <td mat-cell *matCellDef="let element">
                    {{element.isPrinted == 'Y' ? 'พิมพ์เอกสารแล้ว' : 'เอกสารยังไม่ถูกพิมพ์'}} </td>
            </ng-container>

            <ng-container matColumnDef="selfPrint">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> ผู้พิมพ์เอกสาร </th>
                <td mat-cell *matCellDef="let element">
                    {{element.selfPrint == 'Y' ? 'ลูกค้า' : 'หอการค้า'}}
                    <ng-container *ngIf="element.isPrinted == 'Y'">
                        <br> <!-- Add a line break -->
                        {{ "(" + element.printBy + ")" }}
                    </ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="sigBranchName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> ลายเซ็นสด </th>
                <td mat-cell *matCellDef="let element">
                    {{element.sigBranchName }} </td>
            </ng-container>

            <!-- Action ไม่ขึ้น -->
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="text-left"> Action </th>
                <td mat-cell *matCellDef="let element;let i = index" class="text-left" style="text-align: left;">
                    <div class="flex flex-row" style="justify-content: start;">
                        <div *ngIf="checkButton('BT039')">
                            <button mat-icon-button matTooltip="รายละเอียด" matTooltipPosition="above"
                                (click)="showDocDetail(element)">
                                <mat-icon svgIcon="heroicons_outline:document-search"></mat-icon>
                            </button>
                        </div>
                        <div *ngIf="checkButton('BT040')">
                            <!-- <button *ngIf="element.docType == 'CF' && element.printStatus == 'N' " mat-icon-button
                                    matTooltip="พิมพ์" (click)="printCFPopup(element)">
                                    <mat-icon svgIcon="heroicons_outline:printer"></mat-icon>
                                </button> -->
                            <button mat-icon-button matTooltip="พิมพ์" (click)="print(element)"
                                matTooltipPosition="above"
                                *ngIf="element.isPrinted =='N' && (element.rqstatusId == 6) && element.selfPrint != 'Y' && (element.sigBranchId > 0 && element.sigBranchId == userProfile.branchId)">
                                <mat-icon svgIcon="heroicons_outline:printer"></mat-icon>
                            </button>
                            <button mat-icon-button matTooltip="พิมพ์" (click)="print(element)"
                                matTooltipPosition="above"
                                *ngIf="(element.isPrinted =='N') && element.selfPrint != 'Y' && (element.rqstatusId == 6) && (element.sigBranchId == 0)">
                                <mat-icon svgIcon="heroicons_outline:printer"></mat-icon>
                            </button>
                        </div>
                        <div *ngIf="checkButton('BT196')">
                            <button mat-icon-button matTooltip="เปลี่ยน" (click)="openPopupSendPrintTostaff(element)"
                                matTooltipPosition="above"
                                *ngIf="(element.isPrinted =='N') && element.selfPrint != 'Y' && (element.rqstatusId == 6)">
                                <mat-icon svgIcon="mat_outline:local_post_office"></mat-icon>
                            </button>
                        </div>
                        <div>
                            <button mat-icon-button matTooltip="อนุมัติการพิมพ์ลายเซ็นสด"
                                (click)="approveLiveSelfPrint(element)" matTooltipPosition="above"
                                *ngIf="(element.isPrinted =='N') && (element.rqstatusId == 42) && (element.sigBranchId == userProfile.branchId)">
                                <mat-icon svgIcon="task"></mat-icon>
                            </button>
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons
            aria-label="Select page of periodic elements" (page)="pageChanged($event, printStatus)">
        </mat-paginator>
    </div>

</div>