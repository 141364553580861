<mat-card>
  <mat-card-header>
    <div class="flex justify-between" style="width: 100%;">
      <div class="text-lg">ดึงงานกลับ</div>
      <div>
        <button class="close-button" [mat-dialog-close]="true">
          <mat-icon class="text-white" [mat-dialog-close]>cancel</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-dialog-content>
    <div class="flex flex-col py-5 gap-4 ">  
      <div>
        <div class="flex flex-row">
          <div class="basis-1/3">เหตุผล : </div>
          <div class="basis-2/3">
            <mat-form-field class="w100">
              <input autocomplete="off" required type="text" matInput placeholder="เหตุผล" #input
                [(ngModel)]="form.remark" name="remark" #remark="ngModel">
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-raised-button [mat-dialog-close]="form" [disabled]="form.remark == null"
      color="accent">ยืนยัน</button>
    <button mat-raised-button [mat-dialog-close]>ยกเลิก</button>
  </mat-dialog-actions>