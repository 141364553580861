import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { ApiMenuService } from "../api/api-menu.service";
import {
  IGetButtonPermissionResponse,
  IGetMenuAllResponse,
  IGetMenuButtomAllResponse,
  IGetUserProfileResponse,
  IMenuButtonResponse,
  IMenuDataModel,
  IMenuGroupButtonResponse,
  IMenuResponse,
  IGetComponentPermissionPerPagesResponse,
  IReqGetMenuPersmissionByGroupCode,
} from "app/business/interface/api/api-menu.interface";
import {
  IMenuNavigation,
  IResMenuPersmissionByGroupCode,
} from "app/business/interface/app/app-menu.interface";
import { AuthService } from "app/core/auth/auth.service";

@Injectable({
  providedIn: "root",
})
export class AppMenuService {
  actionMenuPermission: BehaviorSubject<any>;
  actionButtonPermission: BehaviorSubject<any>;
  actionMenuTo: BehaviorSubject<any>;
  actionButtonTo: BehaviorSubject<any>;

  menuList: BehaviorSubject<IMenuResponse[]>;
  buttonList: BehaviorSubject<IMenuResponse[]>;
  menuPermissionList: BehaviorSubject<IMenuResponse[]>;
  buttonPermissionList: BehaviorSubject<IGetButtonPermissionResponse[]>;

  userMenuList: BehaviorSubject<IMenuDataModel[]>;
  userRoleMenuList: BehaviorSubject<IMenuDataModel[]>;
  userGroupMenuList: BehaviorSubject<IMenuDataModel[]>;
  userProfile: BehaviorSubject<any>;
  userButtonList: BehaviorSubject<IMenuDataModel[]>;
  userRoleButtonList: BehaviorSubject<IMenuDataModel[]>;
  userGroupButtonList: BehaviorSubject<IMenuDataModel[]>;
  isMenuDisable: BehaviorSubject<boolean>;
  navigation: BehaviorSubject<IMenuNavigation[]>;
  componentPermisionList: BehaviorSubject<
    IGetComponentPermissionPerPagesResponse[]
  >;

  constructor(
    private apiMenuService: ApiMenuService,
    private _authService:AuthService
    ) {
    this.actionMenuPermission = new BehaviorSubject(new Array<any>());
    this.actionButtonPermission = new BehaviorSubject(new Array<any>());

    this.actionMenuTo = new BehaviorSubject(null);
    this.actionButtonTo = new BehaviorSubject(null);

    this.buttonList = new BehaviorSubject(new Array<IMenuResponse>());
    this.menuList = new BehaviorSubject(new Array<IMenuResponse>());
    this.menuPermissionList = new BehaviorSubject(new Array<IMenuResponse>());
    this.buttonPermissionList = new BehaviorSubject(
      new Array<IGetButtonPermissionResponse>()
    );

    this.userMenuList = new BehaviorSubject(new Array<IMenuDataModel>());
    this.userRoleMenuList = new BehaviorSubject(new Array<IMenuDataModel>());
    this.userGroupMenuList = new BehaviorSubject(new Array<IMenuDataModel>());
    this.userProfile = new BehaviorSubject(null);

    this.userButtonList = new BehaviorSubject(new Array<IMenuDataModel>());
    this.userRoleButtonList = new BehaviorSubject(new Array<IMenuDataModel>());
    this.userGroupButtonList = new BehaviorSubject(new Array<IMenuDataModel>());

    this.navigation = new BehaviorSubject(new Array<IMenuNavigation>());
    this.isMenuDisable = new BehaviorSubject(null);
  }

  getMenuAll(dataReq: any): Promise<IGetMenuAllResponse> {
    return new Promise((resolve, reject) => {
      this.apiMenuService.getMenuAll(dataReq).subscribe((res) => {
        // set data result
        this.menuList.next(res.menuList);
        // this.userProfile.next(res.userProfile);
        this.userMenuList.next(res.userMenuList);
        this.userRoleMenuList.next(res.userRoleMenuList);
        this.userGroupMenuList.next(res.userGroupMenuList);
        resolve(res);
      });
    });
  }
  getMenuPermission(): Promise<IMenuResponse[]> {
    return new Promise((resolve, reject) => {
      this.apiMenuService.getMenuPermission().subscribe((res) => {
        this.menuPermissionList.next(res);
        this.navigation.next(this.setNavigation(res));
        var loginUser = JSON.parse(this._authService.loginUser);
        var userProfile = JSON.parse(this._authService.userProfile);
        //debugger;
        this.userProfile.next(userProfile);
        var isDisable = loginUser.isDisable;
        this.isMenuDisable.next(isDisable);
        resolve(res);
      });
    });
  }
  setNavigation(datas: IMenuResponse[]): IMenuNavigation[] {
    const nav = datas.map((e) => {
      if (e.children.length > 0) {
        return {
          id: e.menuCode,
          title: e.name,
          icon: e.icon,
          type: "collapsable",
          children: this.setNavigation(e.children),
          jsonDisPlayConfig: e.jsonDisPlayConfig
        } as IMenuNavigation;
      } else {
        return {
          id: e.menuCode,
          title: e.name,
          icon: e.icon,
          type: "item",
          url: e.url,
          jsonDisPlayConfig: e.jsonDisPlayConfig
        } as IMenuNavigation;
      }
    });

    return nav;
  }

  getButtonAll(dataReq: any): Promise<IGetMenuButtomAllResponse> {
    return new Promise((resolve, reject) => {
      this.apiMenuService.getButtonAll(dataReq).subscribe((res) => {
        // set data result
        this.buttonList.next(res.menuList);
        
        this.userButtonList.next(res.userMenuList);
        this.userRoleButtonList.next(res.userRoleMenuList);
        this.userGroupButtonList.next(res.userGroupMenuList);
        resolve(res);
      });
    });
  }

  getButtonPermissionPerPages(
    dataReq: any
  ): Promise<IGetButtonPermissionResponse[]> {
    return new Promise((resolve, reject) => {
      this.apiMenuService
        .getButtonPermissionPerPages(dataReq)
        .subscribe((res) => {
          // set data result
          this.buttonPermissionList.next(res);
          resolve(res);
        });
    });
  }

  savePermissionMenuOrButton(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMenuService
        .savePermissionMenuOrButton(dataReq)
        .subscribe((res) => {
          // set data result
          resolve(res);
        });
    });
  }

  setSessionMenu(sessionMenu: any): any {
    
    this._authService.userMenu =JSON.stringify(sessionMenu); //localStorage.setItem("menu", JSON.stringify(sessionMenu));
  }

  getSessionMenu(): string {
    
    return this._authService.userMenu;
  }

  clearSessionMenu(): void {
    this._authService.clearSessionMenu(); // localStorage.removeItem("menu");
  }

  setMenuDisable(bool: boolean) {
    this.isMenuDisable.next(bool);
  }

  getComponentPermissionPerPages(
    dataReq: any
  ): Promise<IGetComponentPermissionPerPagesResponse[]> {
    return new Promise((resolve, reject) => {
      this.apiMenuService
        .getComponentPermissionPerPages(dataReq)
        .subscribe((res) => {
          // set data result
          //this.componentPermisionList.next(res);
          resolve(res);
        });
    });
  }
  getComponentPermissionPerPagesOnlyCode(
    dataReq: any
  ): Promise<IGetComponentPermissionPerPagesResponse[]> {
    return new Promise((resolve, reject) => {
      this.apiMenuService
        .getComponentPermissionPerPages(dataReq)
        .subscribe((res) => {
          this.componentPermisionList.next(res);
          resolve(res);
        });
    });
  }

  GetMenuPersmissionByGroupCode(
    req: IReqGetMenuPersmissionByGroupCode
  ): Promise<IResMenuPersmissionByGroupCode[]> {
    return new Promise((resolve, reject) => {
      this.apiMenuService
        .GetMenuPersmissionByGroupCode(req)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }
}

// setNavigation(datas: IMenuResponse[]): IMenuNavigation[] {
//   const nav = datas.map(e => {
//     if (e.children.length > 0) {
//       return {
//         id: e.menuCode,
//         title: e.name,
//         icon: e.icon,
//         type: 'collapsable',
//         children: this.setNavigation(e.children),
//         jsonDisPlayConfig: e.jsonDisPlayConfig

//       } as IMenuNavigation;
//     } else {
//       return {
//         id: e.menuCode,
//         title: e.name,
//         icon: e.icon,
//         type: 'item',
//         url: e.url,
//         jsonDisPlayConfig: e.jsonDisPlayConfig

//       } as IMenuNavigation;
//     }
//   });

//   // 
//   return nav;
// }
