import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpLoadingService {
  loading = new BehaviorSubject<boolean>(false);
  enabled = true;
  countRequest = 0;
  constructor() { }
  start(): void {
    if (!this.enabled) { return; }
    
    this.countRequest += 1;
    if (this.countRequest === 1) {
    }
  }
  stop(): void {
    if (this.countRequest > 0) {
      this.countRequest -= 1;
    }

    if (this.countRequest === 0) {

    }
  }
}
