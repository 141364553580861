import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '@bundle/service/api.service';

@Injectable({
  providedIn: 'root'
})
export class ApiBankService {

  constructor(private apiService: ApiService) { }

  getTestList(any): Observable<any[]> {
    return this.apiService.post("/api/bankaccount", any);
  }

  bankAccount(any): Observable<any[]> {
    return this.apiService.post("/api/bankAccount", any);
  }

  saveBankAccount(any): Observable<any[]> {
    return this.apiService.post("/api/saveBankAccount", any);
  }

  bankCode(any): Observable<any[]> {
    return this.apiService.post("/api/bankCode", any);
  }

  updateBankAccountBatch(request): Observable<any> {
    return this.apiService.post("/api/updateBankAccountBatch", request);
  }
}
