<div style="display: flex;flex-flow: column;height: 100%;gap: 30px; justify-content: stretch;">
    <div style="display: flex;flex-flow: column;margin-top: 30px;" class="pl-10 pr-10">
        <div class="menu">
            <div style="display: flex;flex-flow: row;gap: 10px;align-items: stretch;">
                <div *ngIf="checkButton('BT071')">
                    <button class="set-btn-unlimited" mat-raised-button color="accent"
                        (click)="addRelationship(null)">Add
                        Relationship</button>
                </div>
            </div>
            <div style="display: flex;flex-flow: row;gap: 10px;flex: 1;justify-content: flex-end;">
                <div class="mat-button" style="gap: 10px;flex: 0 40%;">
                    <mat-form-field style="display: flex;flex-flow: row;flex: 1" class="my-form-field">
                        <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" matInput #input
                            [(ngModel)]="searchForm.search" name="search" #search="ngModel"
                            (keyup)="applyFilter($event)">
                        <button matSuffix mat-icon-button aria-label="ค้นหา">
                            <mat-icon>search</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <div class="mat-elevation-z8 ">
        <table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">
            <ng-container matColumnDef="companyNameTh">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left;" class="pl-20">Thai
                    Name </th>
                <td mat-cell *matCellDef="let element" class="pl-20"> {{element.companyNameTh}} </td>
            </ng-container>
            <ng-container matColumnDef="companyNameEn">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left;">English Name </th>
                <td mat-cell *matCellDef="let element"> {{element.companyNameEn}} </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style="text-align: left;"> Action </th>
                <td mat-cell *matCellDef="let element;let i = index" style="text-align: left;">
                    <div class="flex flex-row" style="justify-content: start;">
                        <div *ngIf="checkButton('BT073')">
                            <button mat-icon-button matTooltip="ลบ" (click)="delete(element)"
                                matTooltipPosition="above">
                                <mat-icon svgIcon="heroicons_outline:trash"></mat-icon>
                            </button>
                        </div>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons
            aria-label="Select page of periodic elements">
        </mat-paginator>
    </div>
</div>