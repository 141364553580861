import { ReturnStatement } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '@bundle/service/api.service';

@Injectable({
  providedIn: 'root'
})
export class ApiPrintService {

  constructor(private apiService: ApiService) { }

  getListPrint(request:any): Observable<any[]> {
    return this.apiService.post("/api/getListPrint", request);
  }

  getListRePrint(request: any): Observable<any[]> {
    return this.apiService.post("/api/getReprintList", request);
  }

  saveRePrint(request: any): Observable<any[]> {
    return this.apiService.post("/api/saveRePrint", request);
  }

  saveVerifyReprint(request: any): Observable<any[]> {
    return this.apiService.post("/api/saveVerifyReprint", request);
  }

  getPrintOriginal(request: any): Observable<any[]> {
    return this.apiService.post("/api/getPrintOriginal", request);
  }
  getPrintOriginalCF(request: any): Observable<any[]> {
    return this.apiService.post("/api/getPrintOriginalCF", request);
  }
  getCreateReprintList(request: any): Observable<any[]> {
    return this.apiService.post("/api/getCreateReprintList", request);
  }
}
