import { LiveAnnouncer } from '@angular/cdk/a11y'
import { AfterViewInit, Component, OnInit, ViewChild,Inject } from '@angular/core'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatTableDataSource } from '@angular/material/table'
import { Router } from '@angular/router'
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'
import { IReqGetCreateVerifyTransferRequest } from 'app/business/interface/app/app-verify-interface'
import { AppRequestService } from 'app/business/service/app/app-request.service'
import { ConfirmDialogService } from '@bundle/component/confirm-dialog/confirm-dialog.service'
import { AppMasterService } from 'app/business/service/app/app-master.service'
import { AppVerifyService } from 'app/business/service/app/app-verify.service'


@Component({
  selector: 'app-create-transfer-job',
  templateUrl: './create-transfer-job.component.html',
  styleUrls: ['./create-transfer-job.component.css']
})
export class CreateTransferJobComponent implements OnInit {

  form: IReqGetCreateVerifyTransferRequest = {
    packId : 0,
    createBy : 0,
    requestNo : "",
    getTaskFromVerifyId : 0,
    getTaskFromVerifyName : " ",
    getTaskToVerifyId  : 0,
    getTaskToVerifyName : " "
  }
  requestNoData : string
  uId : number
  userVeerify : any[] = []
  companyName : string
  packId : number
  getTaskFromVerifyId : number
  getTaskFromVerifyName : string
  getTaskToVerifyId  : number
  getTaskToVerifyName : string

  searchString: string
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private _liveAnnouncer: LiveAnnouncer,
    private appRequestService: AppRequestService,
    private dialog: MatDialog,
    private alertService: AlertMessageService,
    private router: Router,
    private appVerifyService : AppVerifyService,
    private appMastersService : AppMasterService,
    private confirmService: ConfirmDialogService,
    private dialogRef: MatDialogRef<CreateTransferJobComponent>,
  ) {
    this.requestNoData = data.requestNo
    this.uId = data.uId,
    this.companyName = data.companyName
    this.packId = data.packId
    this.getTaskFromVerifyId = data.getTaskFromVerifyId
    this.getTaskFromVerifyName = data.getTaskFromVerifyName

  }
  ngOnInit() {
    this.form.requestNo = this.requestNoData
    this.form.createBy = this.uId
    this.form.packId = this.packId
    this.form.getTaskFromVerifyId  = this.getTaskFromVerifyId
    this.form.getTaskFromVerifyName = this.getTaskFromVerifyName
    this.loadData()
    
  }


 
  close() {
    this.dialog.closeAll()
  }
  
  async loadData() {
    await this.appMastersService.getTaskToVerifyList().then((res: any) => {
      if (res === 'error') {
        return
      } else {
        if (res !== null) {
          this.userVeerify = res
          var index = res.findIndex((x) => x.userId == this.form.getTaskFromVerifyId) 
          if (index >0){
          this.form.getTaskToVerifyId = res[index].userId
          }
        } else {
          this.userVeerify = []
        }
      }
    })
  }

  save() {
    let currentStaff = this.userVeerify.find(e => e.userId == this.form.getTaskToVerifyId).staffName;
    this.confirmService
      .open({
        header: 'ยืนยันการทำรายการ',
        content: 'ต้องการยืนยันการโอนงานไปให้เจ้าหน้าที่ ' + currentStaff + ' หรือไม่?',
      })
      .then((res) => {
        if (res == true) {
          let apiRequest = {
            packId : this.form.packId,
            createBy : this.uId,
            requestNo :this.form.requestNo,
            getTaskFromVerifyId : this.form.getTaskFromVerifyId,
            getTaskToVerifyId  : this.form.getTaskToVerifyId
          }
          this.appVerifyService.saveTranferDocumentRequest(apiRequest).then((res) => {
            if (res === 'error' || res != null) {
              return
            } else {
              this.alertService
                .open({ message: 'บันทึกสำเร็จ', title: 'ทำรายการสำเร็จ' })
                .then((res) => {
                  this.dialogRef.close();
                  this.router.navigate(['/page/verify/verify'])
                })
            }
          })
        }
      })
  }
  


 

}
