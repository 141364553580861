<div style="gap: 30px;padding-top: 30px;" class="flex flex-col h-full">
  <div class="menu justify-between flex items-stretch flex-row gap-5 px-20 pr-10 pl-10">
    <div class="gap-2.5 flex flex-row">
      <!-- <div >
        <button mat-raised-button color="accent" (click)="addItem()">
          <div class="flex items-center">
            <span>Add</span>
          </div>
        </button>
      </div> -->
    </div>

    <div style="flex: 1;" class="gap-2.5 flex justify-end flex-row">
      <div class="justify-evenly flex gap-2.5 items-center flex-row" style="flex: 0 40%;">
        <mat-form-field style="flex: 1" class="my-form-field flex flex-row">
          <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" matInput #input [(ngModel)]="form.search"
            name="search" #search="ngModel" (keyup)="applyFilter($event.target.value)">
          <button matSuffix mat-icon-button aria-label="ค้นหา">
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <button mat-raised-button color="accent" [matMenuTriggerFor]="menu" style="height: 50px;">
        <div style="display: flex;align-items: center;">
          <span>เงื่อนไขการค้นหา</span>
          <mat-icon>expand_more</mat-icon>
        </div>
      </button>
      <mat-menu #menu="matMenu" xPosition="before" class="w-240 my-menu">
        <div class="pl-2 pr-2 flex flex-col" (click)="stopPropagation($event)">
          <div class="pt-2 pl-2 pr-2 flex flex-row">
            <mat-label style="font-size: 0.85em !important">วันเวลาที่อนุมัติ | Approve Date</mat-label>
          </div>
          <div class="p-2 justify-evenly flex gap-2.5 items-center flex-row">
            <mat-form-field style="flex: 1" class="my-form-field flex flex-row">
              <mat-label>จากวันที่ | From</mat-label>
              <input matInput [matDatepicker]="pickstart" placeholder="จากวันที่" [(ngModel)]="form.startDate"
                [ngModelOptions]="{standalone: true}" [max]="form.endDate" readonly (click)="pickstart.open()">
              <mat-datepicker-toggle matIconSuffix [for]="pickstart"></mat-datepicker-toggle>
              <mat-datepicker #pickstart></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="p-2 justify-evenly flex gap-2.5 items-center flex-row">
            <mat-form-field style="flex: 1" class="my-form-field flex flex-row">
              <mat-label>ถึงวันที่ | To</mat-label>
              <input matInput [matDatepicker]="pickend" placeholder="ถึงวันที่" [(ngModel)]="form.endDate"
                [ngModelOptions]="{standalone: true}" [min]="form.startDate" readonly (click)="pickend.open()">
              <mat-datepicker-toggle matIconSuffix [for]="pickend"></mat-datepicker-toggle>
              <mat-datepicker #pickend></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="p-2 justify-evenly flex gap-2.5 items-center flex-row ">
            <mat-form-field style="flex: 1" class="my-form-field flex flex-row">
              <mat-label>เลขที่คำขออ้างอิง | Request No. </mat-label>
              <input autocomplete="off" type="text" matInput placeholder="เลขที่ใบกำกับ/ เลขที่เอกสาร" matInput #input
                [(ngModel)]="form.invoiceReference" name="invoiceReference" #invoiceReference="ngModel">
            </mat-form-field>
          </div>
          <div class="flex justify-end pt-2">
            <div>
              <button mat-raised-button (click)="clearFilter()" style="flex:0 50%; margin-right: 10px;">
                เคลียร์
              </button>
              <button mat-raised-button color="accent" (click)="onSearch()" style="flex:0 50%;">
                ค้นหา
              </button>
            </div>
          </div>
        </div>
      </mat-menu>
    </div>
  </div>
  <!-- </div> -->
  <div class="mat-elevation-z8 ">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <table mat-table matSort style="width: 100%;" [dataSource]="dataSource"
      (matSortChange)="announceSortChange($event)">
      <ng-container matColumnDef="docPackName">
        <th mat-header-cell *matHeaderCellDef class="text-left"> ชื่อชุดเอกสารคำขอ </th>
        <td mat-cell *matCellDef="let element"> {{element.docPackName}} </td>
      </ng-container>

      <ng-container matColumnDef="requestDate">
        <th mat-header-cell *matHeaderCellDef class="text-left"> วันที่ยื่นคำขอ </th>
        <td mat-cell *matCellDef="let element" class="text-left"> {{element.requestDate | date: 'yyyy-MM-dd HH:mm:ss'}} </td>
      </ng-container>

      <ng-container matColumnDef="docType">
        <th mat-header-cell *matHeaderCellDef class="text-left"> ประเภทเอกสาร </th>
        <td mat-cell *matCellDef="let element" class="text-left"> {{element.docType}} </td>
      </ng-container>

      <ng-container matColumnDef="requestNo">
        <th mat-header-cell *matHeaderCellDef class="text-left"> เลขที่คำขอ </th>
        <td mat-cell *matCellDef="let element" class="text-left"> {{element.requestNo}} </td>
      </ng-container>

      <ng-container matColumnDef="requestAmount">
        <th mat-header-cell *matHeaderCellDef class="text-left"> จำนวนคำขอ </th>
        <td mat-cell *matCellDef="let element" class="text-left"> {{element.requestAmount}} </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef class="text-left"> สถานะ </th>
        <td mat-cell *matCellDef="let element" class="text-left"> {{element.status}} </td>
      </ng-container>


      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="text-left"> การจัดการ </th>
        <td mat-cell *matCellDef="let element;let i = index" class="text-left">
          <div class="flex flex-row" style="justify-content: start;">
            <div *ngIf="checkButton('BT188')">
              <button mat-icon-button matTooltip="รายละเอียด" (click)="detail(element)" matTooltipPosition="above">
                <mat-icon>search</mat-icon>
              </button>
            </div>
            <div *ngIf="checkButton('BT189')">
              <button mat-icon-button matTooltip="โอนสาขา" (click)="transfer(element)" matTooltipPosition="above">
                <mat-icon>repeat</mat-icon>
              </button>
            </div>
            <div *ngIf="checkButton('BT190')">
              <button mat-icon-button matTooltip="พิมพ์เอกสาร" (click)="printDoc(element)" matTooltipPosition="above">
                <mat-icon svgIcon="heroicons_outline:printer"></mat-icon>
              </button>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]" (page)="_page($event)" showFirstLastButtons
      aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>

</div>