import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '@bundle/service/api.service';
import { IGetVerifyCOResponse } from 'app/business/interface/app/app-co.interface';

@Injectable({
  providedIn: 'root'
})
export class ApiVerifyService {

  constructor(private apiService: ApiService) { }

  getQueue(): Observable<any[]> {
    return this.apiService.get("/api/getQueue");
  }

  checkQueue(): Observable<any[]> {
    return this.apiService.get("/api/checkQueue");
  }

  getQueueOrder():Observable<any[]>{
    return this.apiService.get("/api/getQueueOrder")
  }
  getVerifyCO(request: any): Observable<IGetVerifyCOResponse> {
    return this.apiService.post("/api/getVerifyCO",request)

  }

  saveErrorMark(request:any):Observable<any[]>{
    return this.apiService.post("/api/saveErrorMark",request)
  }
  
  saveVerifyCO(request:any):Observable<any[]>{
    return this.apiService.post("/api/saveVerifyCO",request)
  }

  setQueueStatus(request:any):Observable<any[]>{
    return this.apiService.post("/api/setQueueStatus",request)
  }

  saveVerifyRecheck(request:any):Observable<any>{
    return this.apiService.post("/api/CO/saveVerifyRecheck",request)
  }

  saveTranferDocumentRequest(request:any):Observable<any>{
    return this.apiService.post("/api/tranferDocumentRequest",request)
  }

  getVerifyReprintList(request:any):Observable<any>{
    return this.apiService.post("/api/getVerifyReprintList",request)
  }

  getVerifyReprintListDetail(request:any):Observable<any>{
    return this.apiService.post("/api/getVerifyReprintListDetail",request)
  }

  saveReprintStatus(request:any):Observable<any>{
    return this.apiService.post("/api/CO/saveReprintStatus",request)
  }

  
  getVeirfyHistoryList(request: any): Observable<any> {
    return this.apiService.post("/api/getVerifyHistoryList", request);
  }

  getAllQueueOrder(request: any): Observable<any> {
    return this.apiService.post("/api/getAllQueueOrder", request);
  }

  getQueueAddition(request: any): Observable<any> {
    return this.apiService.post("/api/getQueueAddition", request);
  }

  checkUserQ(): Observable<any> {
    return this.apiService.post("/api/checkUserQ", {});
  }

  saveErrorMarker(request: any): Observable<any> {
    return this.apiService.post("/api/saveErrorMarker", request);
  }

  saveErrorMarkActiveStatus(request: any): Observable<any> {
    return this.apiService.post("/api/saveErrorMarkActiveStatus", request);
  }

  getCoErrorMarkList(request: number): Observable<any> {
    return this.apiService.post("/api/getCoErrorMarkList", {
      packId: request
    });
  }
}
