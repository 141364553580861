import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { AppUserService } from 'app/business/service/app/app-user.service';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
  selector: 'app-user-group-filter-form',
  templateUrl: './user-group-filter-form.component.html',
  styleUrls: ['./user-group-filter-form.component.css']
})
export class UserGroupFilterFormComponent implements OnInit {

  filterList: any;
  userToken: any;
  form: any = {};
  showField = false;
  userGroupList: any[];
  dataFilter = [
    { seq: '1', property: 'userGroupCode', value: '', label: 'รหัสกลุ่มผู้ใช้ : ทั้งหมด', hasValue: true },
    { seq: '2', property: 'groupName', value: '', label: 'ชื่อกลุ่มผู้ใช้ : ทั้งหมด', hasValue: true },
    { seq: '3', property: 'isActive', value: '', label: 'สถานะ  : ทั้งหมด', hasValue: true },
  ];

  @Output() filter: EventEmitter<any> = new EventEmitter();

  constructor(
    private appUserService: AppUserService,
    private appMasterService: AppMasterService,
    private _authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.userToken = JSON.parse(
      this._authService.loginUser
    )
    this.searchFormInit();
   
    // this.setSubcribe();
  }
  setSubcribe(): void {
    this.appUserService.userGroupList.subscribe(res => {
      if (res && res.length > 0) {
        this.userGroupList = res;
      } else {
        this.userGroupList = new Array();
      }
    });
  }
  
  searchFormReset(): void {
    this.searchFormInit();
    this.searchFormSubmit();
  }

  searchFormInit(): void {
    this.form = {
      groupName: '',
      userGroupCode: '',
      isActive: '',
    };
    const data = this.dataFilter;
    this.filterList = data.filter(a => a.hasValue);
    this.filterList = this.filterList.sort((first, second) => {
      return (first.seq - second.seq);
    });
    this.filter.emit(this.form);
    // return data;
  }
  searchFormSubmit(): void {
    const request: any = {
      userGroupCode: this.form.userGroupCode,
      groupName: this.form.groupName,
      isActive: this.form.isActive,
     
    };
    this.appUserService.getUserGroupList(request);
  }

  getUserGroupList(): void {
    this.searchFormSubmit();
  }

  groupNameChange(): void {
    let data = this.filterList.filter(res => {
      return res.property === 'groupName';
    });
    // 

    data = data.map(res => {
      return {
        ...res,
        label: this.form.groupName !== '' ? 'ชื่อกลุ่มผู้ใช้ : ' + this.form.groupName : 'ชื่อกลุ่มผู้ใช้ : ทั้งหมด',
        value: this.form.groupName !== '' ? this.form.groupName : ''
      };
    });
    this.dataFilterChange('groupName', data);
  }

  userGroupCodeChange(): void {
    let data = this.filterList.filter(res => {
      return res.property === 'userGroupCode';
    });
    // 

    data = data.map(res => {
      return {
        ...res,
        label: this.form.userGroupCode !== '' ? 'รหัสกลุ่มผู้ใช้ : ' + this.form.userGroupCode : 'รหัสกลุ่มผู้ใช้ : ทั้งหมด',
        value: this.form.userGroupCode !== '' ? this.form.userGroupCode : ''
      };
    });
    this.dataFilterChange('userGroupCode', data);
  }

  isActiveChange(): void {
    let data = this.filterList.filter(res => {
      return res.property === 'isActive';
    });
    // 
    const status = this.form.isActive === 'Y' ? 'ใช้งาน' : 'ยกเลิก';
    data = data.map(res => {
      return {
        ...res,
        label: this.form.isActive !== '' ? 'สถานะ : ' + status : 'สถานะ : ทั้งหมด',
        value: this.form.isActive !== '' ? this.form.isActive : ''
      };
    });
    this.dataFilterChange('isActive', data);
  }

  dataFilterChange(propName: string, dataChange: any): void {
    const datanotFind = this.filterList.filter(res => {
      return res.property !== propName;
    });
    this.filterList = [];
    this.filterList = this.filterList.concat(
      datanotFind,
      dataChange
    );
    this.filterList = this.filterList.sort((first, second) => {
      return (first.seq - second.seq);
    });
  }

  removableFilter(item): boolean {
    if (item.property !== 'userGroupCode' && item.property !== 'groupName' && item.property !== 'isActive') {
      return true;
    } else {
      return item.value !== '';
    }
  }
  removeFilter(item): void {
    this.filterList.map(element => {
      if (element.property === item.property) {
        element.value = '';
        if (!this.dataFilter.find(e => e.property === item.property)) {
          element.hasValue = false;
        } else {
          switch (item.property) {
            case 'groupName': {
              element.label = 'ชื่อกลุ่มผู้ใช้ : ทั้งหมด';
              this.form.groupName = '';
              this.getUserGroupList();
              break;
            }
           
            case 'userGroupCode': {
              element.label = 'รหัสกลุ่มผู้ใช้ : ทั้งหมด';
              this.form.userGroupCode = '';
              this.getUserGroupList();
              break;
            }
            
            case 'isActive': {
              element.label = 'สถานะ  : ทั้งหมด';
              this.form.isActive = '';
              this.getUserGroupList();
              break;
            }
          }
        }
      }
      return element;
    });
  }

}
