<div style="display: flex;flex-flow: column;height: 100%;gap: 30px;">

    <div style="display: flex;flex-flow: column;margin-top: 30px;" class="pl-10 pr-10">
        <div class="menu">
            <div style="display: flex;flex-flow: row;gap: 10px;align-items: stretch;">
                <button mat-raised-button color="accent" (click)="CreateReprint();">
                    <div class="flex items-center">
                        <span>สร้างคำร้องพิมพ์ซ้ำ</span>
                    </div>
                </button>
            </div>
            <div class="gap-2.5 flex flex-row">
                <button mat-raised-button color="accent" (click)="onRefresh()">
                  <div class="flex items-center">
                    <span class="icon-text"><mat-icon svgIcon="heroicons_outline:refresh"></mat-icon>รีเฟรชข้อมูล</span>
                  </div>
                </button>
            </div>
            <div style="display: flex;flex-flow: row;gap: 10px;flex: 1;justify-content: flex-end;">
                <div (click)="statusChange()"
                    [class]="status == 2 ?'inline-flex items-center px-5 py-2.5 text-lg font-medium text-center text-white bg-green-700 rounded-lg hover:bg-green-100 hover:text-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-100 dark:focus:ring-green-800 cursor-default' :'inline-flex items-center px-5 py-2.5 text-lg font-medium text-center hover:bg-gray-200 bg-gray-100 text-gray-800 rounded-lg cursor-default'">
                    ปลดล็อกการพิมพ์ (รอการอนุมัติ)
                </div>
                <div (click)="searchAll()"
                    [class]="status == 1 ?'inline-flex items-center px-5 py-2.5 text-lg font-medium text-center text-white bg-green-700 rounded-lg hover:bg-green-100 hover:text-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-100 dark:focus:ring-green-800 cursor-default' :'inline-flex items-center px-5 py-2.5 text-lg font-medium text-center hover:bg-gray-200 bg-gray-100 text-gray-800 rounded-lg cursor-default'">
                    เอกสารทั้งหมด
                </div>

                <button mat-raised-button color="accent" [matMenuTriggerFor]="menu">
                    <div style="display: flex;align-items: center;">
                        <span>เงื่อนไขการค้นหา</span>
                        <mat-icon>expand_more</mat-icon>
                    </div>
                </button>
                <mat-menu #menu="matMenu" xPosition="before" class="my-menu">
                    <div style="display: flex;flex-flow: column;gap: 15px;" class="pl-2 pr-2"
                        (click)="stopPropagation($event)">
                        <div class="mat-button p-2" style="gap: 10px;">
                            <mat-form-field appearance="fill" style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>วันที่เริ่มต้น | Start Date</mat-label>
                                <input matInput [matDatepicker]="startDate" type="datetime"
                                    [(ngModel)]="searchForm.startDate" (click)="startDate.open()" readonly
                                    name="startDateSearch" #startDateSearch="ngModel" class="cursor-pointer">
                                <mat-hint>MM/DD/YYYY</mat-hint>
                                <mat-datepicker-toggle matIconSuffix [for]="startDate"></mat-datepicker-toggle>
                                <mat-datepicker #startDate></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field appearance="fill" style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>วันที่สิ้นสุด | End Date</mat-label>
                                <input matInput [matDatepicker]="endDate" type="datetime"
                                    [(ngModel)]="searchForm.endDate" name="endDateSearch" (click)="endDate.open()"
                                    readonly #endDateSearch="ngModel" class="cursor-pointer">
                                <mat-hint>MM/DD/YYYY</mat-hint>
                                <mat-datepicker-toggle matIconSuffix [for]="endDate"></mat-datepicker-toggle>
                                <mat-datepicker #endDate></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="mat-button p-2" style="gap: 10px;">
                            <mat-form-field style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>เลขที่ใบคำขอ | Request No.</mat-label>
                                <input autocomplete="off" type="text" matInput placeholder="เลขที่ใบคำขอ" matInput
                                    #input [(ngModel)]="searchForm.requestNo" name="requestNo" #requestNo="ngModel">
                            </mat-form-field>
                            <mat-form-field style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>เลขที่ THT | Certificate No.</mat-label>
                                <input autocomplete="off" type="text" matInput placeholder="เลขที่ THT" matInput #input
                                    [(ngModel)]="searchForm.certificateNo" name="certificateNo"
                                    #certificateNo="ngModel">
                            </mat-form-field>
                        </div>
                        <div class="mat-button  p-2" style="gap: 10px;">
                            <form name="searchCompanyForm" [formGroup]="searchCompanyForm" style="display: flex;flex-flow: row;flex: 1">
                                <mat-form-field style="display: flex;flex-flow: row;flex: 1">
                                    <mat-label>รหัสบริษัท | Company Code</mat-label>
                                    <input matInput type="text" formControlName="companySearch" [matAutocomplete]="auto"
                                        name="companyCode">
                                    <mat-autocomplete #auto="matAutocomplete" required (optionSelected)="selectionChange($event)" [panelWidth]="'auto'">
                                        <mat-option *ngIf="isLoading">
                                            <mat-spinner [diameter]="20"></mat-spinner>
                                        </mat-option>
                                        <mat-option *ngFor="let option of filteredOptions$  | async" [value]="option">
                                            {{option.display}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </form>
                        </div>
                        <div class="mat-button  p-2" style="gap: 10px;">
                            <mat-form-field style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>ชื่อบริษัท | Company Name</mat-label>
                                <input autocomplete="off" type="text" matInput placeholder="ชื่อบริษัท" matInput #input
                                    [(ngModel)]="searchForm.companyName" name="companyName" #companyName="ngModel">
                            </mat-form-field>
                        </div>


                        <div class="flex justify-end pt-2">
                            <div>
                                <button mat-raised-button (click)="clearOption()"
                                    style="flex:0 50%; margin-right: 10px;">
                                    เคลียร์
                                </button>
                                <button mat-raised-button color="accent" (click)="saveSearch()" style="flex:0 50%;">
                                    ค้นหา
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-menu>
            </div>
        </div>
    </div>

    <div class="mat-elevation-z8 " style="overflow-x: auto;">
        <table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">

            <ng-container matColumnDef="No">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> ลำดับที่ </th>
                <td mat-cell *matCellDef="let i = index"> {{calculateIndex(i)}} </td>
            </ng-container>

            <ng-container matColumnDef="requestByName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> ผู้ยื่นคำขอ </th>
                <td mat-cell *matCellDef="let element"> {{element.requestByName}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="declaratorName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> ผู้ส่งออก </th>
                <td mat-cell *matCellDef="let element"> {{element.declaratorName}} </td>
            </ng-container>

            <ng-container matColumnDef="rqNo">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> เลขที่คำขอ RQ NO. </th>
                <td mat-cell *matCellDef="let element">
                    {{element.rqNo}} </td>
            </ng-container>

            <ng-container matColumnDef="docType">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left">ประเภทเอกสาร</th>
                <td mat-cell *matCellDef="let element">
                    {{element.docType}} </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="refNo">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> Invoice No. /<br> Reference No.
                </th>
                <td mat-cell *matCellDef="let element"> {{element.refNo}} </td>
            </ng-container>

            <ng-container matColumnDef="thtNo">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> เลขที่ THT </th>
                <td mat-cell *matCellDef="let element">
                    {{element.thtNo}} </td>
            </ng-container>

            <ng-container matColumnDef="requestDate">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> วัน/เวลาที่ยื่น </th>
                <td mat-cell *matCellDef="let element">
                    {{element.requestDate | date:'yyyy-MM-dd'}} <br>{{element.requestDate | date:'HH:mm'}}</td>
            </ng-container>

            <ng-container matColumnDef="verifyName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> เจ้าหน้าที่ตรวจสอบ </th>
                <td mat-cell *matCellDef="let element">
                    {{element.verifyName}} </td>
            </ng-container>

            <ng-container matColumnDef="rqStatusName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> สถานะคำขอ </th>
                <td mat-cell *matCellDef="let element">
                    {{element.rqStatusName}} </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="text-left"> Action </th>
                <td mat-cell *matCellDef="let element;let i = index">
                    <div class="flex flex-row" style="justify-content: start;">
                        <!-- <div *ngIf="checkButton('BT192')">
                            <button *ngIf="element.docType == 'CF'" mat-icon-button matTooltip="รายละเอียด"
                                (click)="showDetail(element)" routerLink="/page/request/request-cf/create-cf"
                                [queryParams]="{
                                    id: element.docId,
                                    disabled: true
                                    }">
                                <mat-icon svgIcon="heroicons_outline:document-search"></mat-icon>
                            </button>
                            <button *ngIf="element.docType == 'CO'" mat-icon-button matTooltip="รายละเอียด"
                                (click)="showDetail(element)" routerLink="/page/request/request-co/create-co"
                                [queryParams]="{
                                    id: element.docId,
                                    disabled: true,
                                    docId:element.docId
                                    }">
                                <mat-icon svgIcon="heroicons_outline:document-search"></mat-icon>
                            </button>
                        </div> -->
                        <div *ngIf="checkButton('BT193')">
                            <button mat-icon-button matTooltip="อนุมัติ" (click)="editItem(element)"
                                *ngIf="element.rqStatusId == 16">
                                <mat-icon svgIcon="heroicons_outline:upload"></mat-icon>
                            </button>
                        </div>
                        <div *ngIf="checkButton('BT192')">
                            <button mat-icon-button matTooltip="รายละเอียด" matTooltipPosition="above"
                                (click)="showDetail(element)">
                                <mat-icon svgIcon="heroicons_outline:document-text"></mat-icon>
                            </button>
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons
            aria-label="Select page of periodic elements" (page)="pageChanged($event)">
        </mat-paginator>
    </div>
</div>