import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { ApiQueueService } from 'app/business/service/api/api-queue.service'
import { AppMasterService } from 'app/business/service/app/app-master.service'
import { AppQueueService } from 'app/business/service/app/app-queue.service'

@Component({
  selector: 'app-queue-popup',
  templateUrl: './queue-popup.component.html',
  styleUrls: ['./queue-popup.component.scss'],
})
export class QueuePopupComponent implements OnInit {
  queueWaiting: string

  isNS: boolean = false
  isChkOldPriority : boolean = false;
  form: any = {
    queuePriority: 5,
    docPriority: '5',
  }
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public apiMasterService: AppMasterService,
    public dialogRef: MatDialogRef<QueuePopupComponent>,
    public apiQueueService: AppQueueService,
  ) {
    if (data.rqStatus == "10") {
      this.form = {
        queuePriority:data.docPriority == 2 || data.docPriority == 5 ? 2 : data.docPriority == 4 || data.docPriority == 1 ? 1 : 5 ,
        docPriority: data.docPriority == 2 || data.docPriority == 5 ? '2' : data.docPriority == 4 || data.docPriority == 1 ? '1' : '5' 
      };
      this.isChkOldPriority = true;
    }
    else {
      this.form = { queuePriority: 5, docPriority: '5'}
      this.isChkOldPriority = false;
    }
  }
  ngOnInit(): void {
    this.loadQueue()
  }
  loadQueue() {
    this.apiQueueService.getQueueCount(this.form).then((res) => {
      if (res !== null) {
        if (res.total == null || res.total == undefined || res.total == 0) {
          this.queueWaiting = '0'
        } else {
          this.queueWaiting = res.total
        }
      }
    })
  }
  cancel() {
    this.dialogRef.close()
  }
  save() {
    this.dialogRef.close(this.form)
  }

  modelChange(e) {
    switch (e) {
      case true:
        this.form.queuePriority = 4
        this.form.docPriority = '4'
        break
      case false:
        this.form.queuePriority = 5
        this.form.docPriority = '5'
        break
    }
    this.loadQueue()
  }
}
