import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AppBankService } from 'app/business/service/app/app-bank.service';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'
import { ConfirmDialogService } from '@bundle/component/confirm-dialog/confirm-dialog.service';

@Component({
  selector: 'app-harmonized-setting-dialog',
  templateUrl: './harmonized-setting-dialog.component.html',
  styleUrls: ['./harmonized-setting-dialog.component.css']
})
export class HarmonizedSettingDialogComponent implements OnInit {
  form: any = {};
  bankList: any;
  feetypeList: any[] = [];
  isDisabled: boolean = false;
  isRestricted: any;
  buttonList: any[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef: MatDialogRef<HarmonizedSettingDialogComponent>,
    private appMasterService: AppMasterService,
    private dialog: MatDialog,
    private alertMessageService: AlertMessageService,
    private confirmDialog: ConfirmDialogService,
  ) {
    this.buttonList = data.buttonList;
    if (data.e) {
      this.form = data.e;
      if (this.form.isRestricted === "Y") {
        this.isRestricted = "สินค้าควบคุม"
      } else {
        this.isRestricted = "ไม่เป็นสินค้าควบคุม"
      }
      this.isDisabled = true;
    } else {
      this.form.isActive = 'Y';
      this.form.isRestricted = 'Y';
      this.isRestricted = "สินค้าควบคุม"
      this.isDisabled = false;
    }
    
  }
  ngOnInit(): void {
  }

  save() {
    if (!this.isDisabled) {
      this.confirmDialog.open({ content: "คุณต้องการบันทึกข้อมูลใหม่หรือไม่ ?", header: "" }).then((res) => {
        if (res) {
          this.saveData(true);
        }
      })
    } else {
      this.saveData(true);
    }
  }
  
  saveData(allowSave: boolean) {
    
    
    if (allowSave) {
      if ((this.form.itemCode !== undefined && this.form.itemCode !== null && this.form.itemCode !== "") &&
      (this.form.itemDescription !== undefined && this.form.itemDescription !== null && this.form.itemDescription !== "")) {
        const data = {
          "itemCode": this.form.itemCode,
          "itemDescription": this.form.itemDescription,
          "isActive": this.form.isActive,
          "isRestricted": this.form.isRestricted
        };

        this.appMasterService.saveHarmonizedItem(data).then((res: any) => {
          if (res === "error") {
            this.alertMessageService.open({ message: "บันทึกข้อมูลไม่สำเร็จ", title: "" });
          } else {
            this.dialogRef.close();
            this.alertMessageService.open({ message: "บันทึกข้อมูลสำเร็จ", title: "" });
          }
        });
      } else {
        this.alertMessageService.open({ message: "กรุณากรอกข้อมูลให้ครบ", title: "ข้อผิดพลาด" });
      }
    }
  }

  onChange(e) {
    if (e.checked == true) {
      this.form.isActive = "Y";
    } else {
      this.form.isActive = "N";
    }
  }

  onChangeRestricted(e) {
    if (e.checked == true) {
      this.form.isRestricted = "Y";
      this.isRestricted = "สินค้าควบคุม"
    } else {
      this.form.isRestricted = "N";
      this.isRestricted = "ไม่เป็นสินค้าควบคุม"
    }
  }
}
