import { LiveAnnouncer } from '@angular/cdk/a11y';
import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { ConfigBankPopupComponent } from '../config-bank-inquiry/config-bank-popup/config-bank-popup.component';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'
import { DatePipe } from '@angular/common';
import { AppCOService } from 'app/business/service/app/app-co.service';
import { IReqGetViewVerifyWorkListRequest } from 'app/business/interface/app/app-verify-interface';

@Component({
  selector: 'app-view-verify-inquiry',
  templateUrl: './view-verify-inquiry.component.html',
  styleUrls: ['./view-verify-inquiry.component.css']
})
export class ViewVerifyInquiryComponent implements OnInit, AfterViewInit {
  BenefitTarget: any = [];
  form: any = {};
  BenefitTargetValue: string;
  isLoading = false;
  count: number = 0;
  timer: any;
  apiRequest: IReqGetViewVerifyWorkListRequest = {
    filterValue: null,
    requestNo: null,
    verifyPerson: null,
    invoice: null,
    status: null,
    expressType: null,
    getTaskDate: null,
    pagination: {
      Skip: 0,
      Take: 10
    }
  }

  expressTypeList: any[] = [
    {
      name: "ทั้งหมด",
      value: ""
    },
    {
      name: "เร่งด่วน",
      value: "Y"
    },
    {
      name: "ไม่เร่งด่วน",
      value: "N"
    }
  ]

  displayedColumns: string[] = ['requestNo','docType','verifyPersonName', 'expressType','status' , 'getTaskDate', 'invoice'];
  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);
  datePipe: DatePipe = new DatePipe('en-US');

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private appCoService: AppCOService,
    private alertMessageService: AlertMessageService
  ) {
  }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator
    this.apiRequest.pagination = {
      Skip:
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
      Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
    }
    
    this.loadData(this.apiRequest)
  }

  async loadData(request: IReqGetViewVerifyWorkListRequest) {
    this.isLoading = true;
    
    if (this.paginator != undefined && this.paginator != null) {
      this.paginator.pageIndex = 0
    }
    
    request.pagination = {
      Skip: 0, // Start from page 1 (skip 0)
      Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
    };

    await this.appCoService.getViewVerifyWorkList(request).then((res: any) => {
      if (res === "error") {
        return;
      } else {
        if (res !== null) {
          this.dataSource.data = res.viewVerifyList;
          this.count = res.totalCount;
          this.isLoading = false;
        } else {
          this.dataSource.data = []
          this.count = 0
          this.isLoading = false;
        }
      }
    });

    this.dataSource.paginator.length = this.count
    this.isLoading = false;
  }

  ngAfterViewInit() {
    this.paginator.pageIndex = 0
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
  }

  onRefresh() {
    this.apiRequest.requestNo = this.form.requestNo;
    this.apiRequest.verifyPerson = this.form.verifyPerson;
    this.apiRequest.expressType = this.form.expressType;
    this.apiRequest.status = this.form.status;
    this.apiRequest.invoice = this.form.invoice;
    this.apiRequest.getTaskDate = this.form.getTaskDate;
    this.dataSource.paginator = this.paginator
    this.loadData(this.apiRequest)
  }

  selectionPercent() {

  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
    
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  applyFilter(event: string) {
    clearTimeout(this.timer); // Clear any existing timer
  
    this.timer = setTimeout(() => {
      // This code will be executed after a specified delay (e.g., 500 milliseconds)
      
      // Send the value to the backend or perform any other necessary actions
      this.apiRequest.filterValue = event;
      this.dataSource.paginator = this.paginator
      this.loadData(this.apiRequest)

    }, 500); // Specify the desired delay in milliseconds
  }

  onExpressTypeChange(event) {
    const value = event.value;

    if (value !== "") {
      this.apiRequest.expressType = value;
    } else {
      this.apiRequest.expressType = null;
    }
    
    this.dataSource.paginator = this.paginator
    this.loadData(this.apiRequest);
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  async _page(e) {
    this.isLoading = true;
    this.apiRequest.pagination = {
      Skip: (!e.pageIndex ? 0 : e.pageIndex) * (!e.pageSize ? 0 : e.pageSize),
      Take: !e.pageSize ? 10 : e.pageSize,
    }

    await this.appCoService.getViewVerifyWorkList(this.apiRequest).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        if (res.response !== null) {
          this.dataSource.data = res.viewVerifyList;
        } else {
          this.dataSource = new MatTableDataSource((res.response3 = []))
        }
        this.count = res.totalCount
      }
    })
    this.dataSource.paginator.length = this.count
    this.dataSource.paginator.pageIndex = e.pageIndex
    this.isLoading = false;
  }
}
