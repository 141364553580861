import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'environments/environment'
import { BehaviorSubject, map, Observable } from 'rxjs'
import { ApiRequestService } from '../api/api-request.service'
import { IGetListJobRequest } from 'app/business/interface/app/app-job.interface'
import { IGetListJobResponse } from 'app/business/interface/api/api-job.interface'
import { reject } from 'lodash'

@Injectable({
  providedIn: 'root',
})
export class AppRequestService {
  constructor(
    private httpClient: HttpClient,
    private apiRequestService: ApiRequestService,
  ) {}

  saveCF(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiRequestService.saveCF(request).subscribe((res) => {
        resolve(res)
      })
    })
  }

  getListJob(request: IGetListJobRequest): any {
    return new Promise((resolve, reject) => {
      this.apiRequestService.getListJob(request).subscribe((res) => {
        resolve(res)
      })
    })
  }

  deleteCF(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiRequestService.deleteCF(request).subscribe((res) => {
        resolve(res)
      })
    })
  }

  getDocCreate(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiRequestService.getDocCreate(request).subscribe((res) => {
        resolve(res)
      })
    })
  }
  createJobRequest(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiRequestService.createJobRequest(request).subscribe((res) => {
        resolve(res)
      })
    })
  }
  sendToVerify(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiRequestService.sendToVerify(request).subscribe((res) => {
        resolve(res)
      })
    })
  }
  getMark(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiRequestService.getMark(request).subscribe((res) => {
        resolve(res)
      })
    })
  }

  getDocumentEdit(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiRequestService.getDocumentEdit(request).subscribe((res) => {
        resolve(res);
      })
    })
  }

  saveDocumentEdit(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiRequestService.saveDocumentEdit(request).subscribe((res) => {
        resolve(res);
      })
    })
  }
}
