import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogService } from '@bundle/component/confirm-dialog/confirm-dialog.service';
import { MatSort, Sort } from '@angular/material/sort';
import { AppCOService } from 'app/business/service/app/app-co.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { CoErrorMarkImagePopupComponent } from '../../create-co-inquiry/co-error-mark-image-popup/co-error-mark-image-popup.component';
import { ImageModalComponent } from '../../create-co-inquiry/Image-Modal/Image-Modal.component';

@Component({
  selector: 'app-upload-document-reprint',
  templateUrl: './upload-document-reprint.component.html',
  styleUrls: ['./upload-document-reprint.component.css']
})
export class UploadDocumentReprintComponent implements OnInit {
  displayedColumns = ['position', 'attachUrl', 'remark'];
  dataSource:MatTableDataSource<any>= new MatTableDataSource<any>();
  form: any[] = [];
  attachTypeList: any[] = [];
  isDisabled: boolean = false;

  @Input()
  set uploadPhotoData(value) {
    value = value.filter(v => v.isActive == "Y");
    this.dataSource = new MatTableDataSource(value);
  }
  get uploadPhotoData(): any {
    return this.dataSource
  }

  @Input()
  set uploadPhotoForm(value) {
    this.form = value;
  }
  get uploadPhotoForm(): any {
    return this.form;
  }

  @Input()
  set disabled(value) {
    this.isDisabled = value;
    if (!value) {
      this.displayedColumns.push('action');
    }
  }
  get disabled(): any {
    return this.isDisabled;
  }

  @Output() dataSourceUpdate = new EventEmitter();
  @Output() formSourceUpdate = new EventEmitter();

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private confirmService: ConfirmDialogService,
    private _liveAnnouncer: LiveAnnouncer,
    private appCOService: AppCOService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.dataSource.sort = this.sort;
    this.loadOption();
  }

  loadOption() {
  }


  onChanged(e) {
    this.formUpdate();
    this.sourceUpdate();
  }

  onSelectionChanged(e, item) {
    this.formUpdate();
    this.sourceUpdate();
  }

  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  
  onView(e) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.minHeight = '30vh';
    dialogConfig.minWidth = '30vh';
    dialogConfig.maxHeight = '100vh';
    dialogConfig.data = {
      fullImageUrl: e,
    };

    const dialogRef = this.dialog.open(ImageModalComponent, dialogConfig)
  }

  deleteAttachment(e, ind) {
    this.confirmService
    .open({
      header: 'ยืนยันการทำรายการ',
      content: 'ต้องการยกเลิกคำขอ',
    })
    .then((res) => {
      if (res == true) {
        const data = this.dataSource.data;
        
        if (e.attachId != 0) {
          data.splice(ind, 1)
          this.form[ind].isActive = 'N'
        } else {
          let delData = data[ind];

          data.splice(ind, 1)
          this.form = this.form.filter(e => e.attachUrl !== delData.attachUrl);
        }

        this.dataSource = new MatTableDataSource(data);

        this.formUpdate();
        this.sourceUpdate();
      }
    })
  }

  sourceUpdate() {
    const data = this.dataSource.data;
    this.dataSourceUpdate.emit(data);
  }

  formUpdate() {
    this.formSourceUpdate.emit(this.form);
  }
}