import { LiveAnnouncer } from "@angular/cdk/a11y";
import { SelectionModel } from "@angular/cdk/collections";
import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import { IcoStatus } from "app/business/interface/app/app-co.interface";
import { AppUserService } from "app/business/service/app/app-user.service";
import _ from "underscore";
import jwt_decode from "jwt-decode";
import { EmailConfigAgreementDialogComponent } from "./email-config-agreement-dialog/email-config-agreement-dialog.component";
import { AppMasterService } from "app/business/service/app/app-master.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AppMenuService } from "app/business/service/app/app-menu.service";
import { AuthService } from "app/core/auth/auth.service";

@Component({
  selector: "app-email-config-inquiry",
  templateUrl: "./email-config-inquiry.component.html",
  styleUrls: ["./email-config-inquiry.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class EmailConfigInquiryComponent implements OnInit, AfterViewInit {
  searchForm: any = {
    certificateNo: "",
    endDate: null,
    startDate: null,
    isRqStatus: null,
    receiptNo: "",
    requestNo: "",
    invoiceReference: "",
  };
  displayedColumns: string[] = [
    "tcaAddressStreet",
    "subDistrictName",
    "districtName",
    "province",
    "postCode",
    "phone",
    "fax",
    "tcaTypeComAddName",
  ];
  searchString: string;
  apiRequest: any;
  apiResponse: any[];
  statusOptions: any[];
  statusControl = new FormControl("");
  exporterControl = new FormControl("");
  exporterOptions: string[];
  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);
  emailList: any[] = [];
  companyTypeList: any[] = [];
  key: any;
  signInForm: UntypedFormGroup;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  form: any = {};
  dataEmail: any;
  companyData: any = {};
  userToken: any;
  regexPattern: RegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _liveAnnouncer: LiveAnnouncer,
    private _authService: AuthService,
    private _formBuilder: UntypedFormBuilder,
    private appUserService: AppUserService,
    private appMasterService: AppMasterService,
    private alertMessageService: AlertMessageService,
    private appMenuService: AppMenuService,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.regexPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  }

  ngOnInit() {
    this.userToken = JSON.parse(this._authService.loginUser);

    this.signInForm = this._formBuilder.group({
      username: ["", Validators.required],
      password: ["", Validators.required],
    });
    this.loadData(this.apiRequest);
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  loadData(request: any) {
    if (request == null) {
      request = {
        id: this.userToken.cpId ?? 0,
      };
    }

    this.appMasterService.getCompanyAddressType("").then((res) => {
      if (res === "error") {
        this.companyTypeList = [];
      } else {
        this.companyTypeList = res;
      }
    });

    this.appUserService.getEmail(request).then((res: any) => {
      if (res === "error") {
        return;
      } else {
        this.dataEmail = res;
        this.dataSource = new MatTableDataSource(res);

        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        const firstRes = _.first(res);
        this.companyData = firstRes;

        if (this.emailList.length > 0) {
          this.emailList = [];
        }

        if (
          firstRes.tccEmailReceiveTaxInvoice1 !== undefined &&
          firstRes.tccEmailReceiveTaxInvoice1 !== null
        ) {
          const mailData = {
            email: firstRes.tccEmailReceiveTaxInvoice1,
            readonly: true,
          };

          this.emailList.push(mailData);
        }

        if (
          firstRes.tccEmailReceiveTaxInvoice2 !== undefined &&
          firstRes.tccEmailReceiveTaxInvoice2 !== null
        ) {
          const mailData = {
            email: firstRes.tccEmailReceiveTaxInvoice2,
            readonly: true,
          };

          this.emailList.push(mailData);
        }

        if (
          firstRes.tccEmailReceiveTaxInvoice3 !== undefined &&
          firstRes.tccEmailReceiveTaxInvoice3 !== null
        ) {
          const mailData = {
            email: firstRes.tccEmailReceiveTaxInvoice3,
            readonly: true,
          };

          this.emailList.push(mailData);
        }
        if (
          firstRes.tccEmailReceiveTaxInvoice4 !== undefined &&
          firstRes.tccEmailReceiveTaxInvoice4 !== null
        ) {
          const mailData = {
            email: firstRes.tccEmailReceiveTaxInvoice4,
            readonly: true,
          };

          this.emailList.push(mailData);
        }
        if (
          firstRes.tccEmailReceiveTaxInvoice5 !== undefined &&
          firstRes.tccEmailReceiveTaxInvoice5 !== null
        ) {
          const mailData = {
            email: firstRes.tccEmailReceiveTaxInvoice5,
            readonly: true,
          };

          this.emailList.push(mailData);
        }

        if (
          firstRes.tccEmailReceiveTaxInvoice6 !== undefined &&
          firstRes.tccEmailReceiveTaxInvoice6 !== null
        ) {
          const mailData = {
            email: firstRes.tccEmailReceiveTaxInvoice6,
            readonly: true,
          };

          this.emailList.push(mailData);
        }
      }
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce("Sorting cleared");
    }
  }
  stopPropagation(event) {
    event.stopPropagation();
  }
  saveSearch() {
    this.apiRequest = {
      id: 0,
      endDate: this.searchForm.endDate,
      startDate: this.searchForm.startDate,
      isRqstatus: this.searchForm.status,
      receiptNo: this.searchForm.receiptNo,
      requestNo: this.searchForm.requestNo,
      invoiceNo: this.searchForm.invoiceReference,
    };

    this.loadData(this.apiRequest);
  }

  print() {}

  async save() {
    if (this.emailList.length > 0) {
      var dialogRef = this.dialog.open(EmailConfigAgreementDialogComponent, {
        width: "60vw",
        disableClose: true,
      });

      dialogRef.afterClosed().subscribe(async (res) => {
        if (res) {
          const fieldname = {
            tccEmailReceiveTaxInvoice1: null,
            tccEmailReceiveTaxInvoice2: null,
            tccEmailReceiveTaxInvoice3: null,
            tccEmailReceiveTaxInvoice4: null,
            tccEmailReceiveTaxInvoice5: null,
            tccEmailReceiveTaxInvoice6: null,
          };

          // Mapping array members to empty fields
          for (let i = 0; i < this.emailList.length; i++) {
            const email = this.emailList[i].email;
            const fieldName = `tccEmailReceiveTaxInvoice${i + 1}`;
            fieldname[fieldName] = email;
          }
          const loginUser = JSON.parse(localStorage.getItem("loginUser"));
          //debugger
          const data = {
            id: this.userToken.cpId ? this.userToken.cpId : loginUser.cpId,
            uId: this.userToken.uId ? this.userToken.uId : loginUser.uId,
            tcc_EmailReceiveTaxInvoice1: fieldname.tccEmailReceiveTaxInvoice1,
            tcc_EmailReceiveTaxInvoice2: fieldname.tccEmailReceiveTaxInvoice2,
            tcc_EmailReceiveTaxInvoice3: fieldname.tccEmailReceiveTaxInvoice3,
            tcc_EmailReceiveTaxInvoice4: fieldname.tccEmailReceiveTaxInvoice4,
            tcc_EmailReceiveTaxInvoice5: fieldname.tccEmailReceiveTaxInvoice5,
            tcc_EmailReceiveTaxInvoiceOther:
              fieldname.tccEmailReceiveTaxInvoice6,
          };
          //debugger;
          // this.key = JSON.parse(localStorage.getItem("userProfile")).key;
          // localStorage.setItem("token", this.key);
          // localStorage.setItem("accessToken", this.key);
          let emailList = [];
          const expression: RegExp =
            /^[A-Z0-9,._%+-]+@[A-Z0-9,.-]+\.[A-Z]{2,}$/i;
          var char = [",", " "];
          if (
            data.tcc_EmailReceiveTaxInvoice1 != "" &&
            data.tcc_EmailReceiveTaxInvoice1 != null
          ) {
            var Invoice1 = data.tcc_EmailReceiveTaxInvoice1.split(",");
            emailList.push(...Invoice1);
          }
          if (
            data.tcc_EmailReceiveTaxInvoice2 != "" &&
            data.tcc_EmailReceiveTaxInvoice2 != null
          ) {
            var Invoice2 = data.tcc_EmailReceiveTaxInvoice2.split(",");
            emailList.push(...Invoice2);
          }
          if (
            data.tcc_EmailReceiveTaxInvoice3 != "" &&
            data.tcc_EmailReceiveTaxInvoice3 != null
          ) {
            var Invoice3 = data.tcc_EmailReceiveTaxInvoice3.split(",");
            emailList.push(...Invoice3);
          }
          if (
            data.tcc_EmailReceiveTaxInvoice4 != "" &&
            data.tcc_EmailReceiveTaxInvoice4 != null
          ) {
            var Invoice4 = data.tcc_EmailReceiveTaxInvoice4.split(",");
            emailList.push(...Invoice4);
          }
          if (
            data.tcc_EmailReceiveTaxInvoice5 != "" &&
            data.tcc_EmailReceiveTaxInvoice5 != null
          ) {
            var Invoice5 = data.tcc_EmailReceiveTaxInvoice5.split(",");
            emailList.push(...Invoice5);
          }
          if (
            data.tcc_EmailReceiveTaxInvoiceOther != "" &&
            data.tcc_EmailReceiveTaxInvoiceOther != null
          ) {
            var Invoice6 = data.tcc_EmailReceiveTaxInvoiceOther.split(",");
            emailList.push(...Invoice6);
          }
          console.log("Data", emailList);
          var checkFormat = true;
          emailList.forEach((element) => {
            const isValid = expression.test(element);
            console.log("element", isValid);
            if (!isValid) {
              this.alertMessageService.open({
                message: "กรุณาระบุอีเมล์ให้ถูกต้อง",
                title: "ข้อผิดพลาด",
              });
              checkFormat = false;
            }
            // console.log("element", element);
            // if (this.regexPattern.test(element)) {
            //   console.log("true", element);
            //   checkFormat = true;
            //   // return true;
            // } else {
            //   this.alertMessageService.open({
            //     message: "รูปแบบอีเมล์ไม่ถูกต้อง " + element + ";",
            //     title: "รูปแบบอีเมล์ไม่ถูกต้อง",
            //   });
            //   checkFormat = false;
            //   return true;
            // }
          });
          console.log("checkFormat", checkFormat);
          // return;
          if (checkFormat) {
            await this.appUserService.saveEmail(data).then(async (res: any) => {
              if (res === "error") {
                return;
              } else {
                // const getToken = this.getDecodedAccessToken(res);
                //debugger;
                res = {
                  ...res,
                  uId: res.uId,
                  uid: res.uId,
                };
                this._authService.userProfile = JSON.stringify(res); //localStorage.setItem("userProfile", JSON.stringify(res));
                this._authService.loginUser = JSON.stringify(res); //localStorage.setItem("loginUser", JSON.stringify(res));

                // localStorage.setItem("token", this.key);

                // this.loadData(this.apiRequest);

                // localStorage.setItem("accessToken", this.key);

                const menu = await this.appMenuService.getMenuPermission();
                const navigation = this.appMenuService.setNavigation(menu);

                // this.appMenuService.setSessionMenu(navigation);

                let dataUserProfile = JSON.parse(this._authService.userProfile);
                //debugger;
                const data = {
                  id: res.uId,
                  cid: parseInt(res.cpId),
                };

                this.appMenuService.getMenuPermission().then((resMenu: any) => {
                  if (res || res == null) {
                    this._authService.userMenu = JSON.stringify(resMenu);
                    // localStorage.removeItem("login");
                    this._authService.signIn(this.signInForm.value).subscribe(
                      () => {
                        // Set the redirect url.
                        // The '/signed-in-redirect' is a dummy url to catch the request and redirect the user
                        // to the correct page after a successful sign in. This way, that url can be set via
                        // routing file and we don't have to touch here.
                        const redirectURL =
                          this._activatedRoute.snapshot.queryParamMap.get(
                            "redirectURL"
                          ) || "/signed-in-redirect";

                        // Navigate to the redirect url
                        // this._router.navigateByUrl(redirectURL);

                        this.router.navigate(["page/profile/profile"]);
                      },
                      (response) => {
                        this.router.navigate(["page/profile/profile"]);
                        // Re-enable the form
                        this.signInForm.enable();

                        // Reset the form
                        this.signInForm.patchValue({
                          password: null,
                        });

                        this.router.navigate(["page/profile/profile"]);
                      }
                    );
                  }
                });

                //localStorage.setItem("userProfile", JSON.stringify(data));
              }
            });
          }
        }
      });
    } else {
      this.alertMessageService.open({
        message: "กรุณาเพิ่มอีเมล์อย่างน้อย 1 อีเมล์",
        title: "ข้อผิดพลาด",
      });
    }
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }
  showDetail() {}

  checkItem() {
    const input = document.getElementById("newTaxEmail1") as HTMLInputElement;
    const expression: RegExp = /^[A-Z0-9,._%+-]+@[A-Z0-9,.-]+\.[A-Z]{2,}$/i;
    let emailList = [];

    if (input.value != "" && input.value != null) {
      var Invoice5 = input.value.split(",");
      emailList.push(...Invoice5);
    }
    emailList.forEach((element) => {
      const isValid = expression.test(element);
      console.log("element", isValid);
      if (!isValid) {
        this.alertMessageService.open({
          message: "กรุณาระบุอีเมล์ให้ถูกต้อง",
          title: "ข้อผิดพลาด",
        });
      }
    });
  }

  addItem() {
    const input = document.getElementById("newTaxEmail") as HTMLInputElement;
    const expression: RegExp = /^[A-Z0-9,._%+-]+@[A-Z0-9,.-]+\.[A-Z]{2,}$/i;

    console.log("addItem input", input.value);
    let emailList = [];

    if (input.value != "" && input.value != null) {
      var Invoice5 = input.value.split(",");
      emailList.push(...Invoice5);
    }

    emailList.forEach((element) => {
      const isValid = expression.test(element);
      console.log("element", isValid);
      if (isValid) {
        const newItem = {
          email: input.value,
          readonly: true,
        };
        this.emailList.push(newItem);
        input.value = null;
      } else {
        this.alertMessageService.open({
          message: "กรุณาระบุอีเมล์ให้ถูกต้อง",
          title: "ข้อผิดพลาด",
        });
      }
    });
  }
  checkFormat(i) {
    const input = document.getElementById(
      "tccEmailReceiveTaxInvoice" + i
    ) as HTMLInputElement;
    // const expression: RegExp = /^[A-Z0-9,._%+-\,\]+@[A-Z0-9,.-]+\.[A-Z]{2,}$/i;
    const expression: RegExp =
      /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/i;
    //
    const isValid = expression.test(input.value);

    if (isValid) {
      const newItem = {
        email: input.value,
        readonly: true,
      };
      this.emailList.push(newItem);
      input.value = null;
    } else {
      this.alertMessageService.open({
        message: "กรุณาระบุอีเมล์ให้ถูกต้อง",
        title: "ข้อผิดพลาด",
      });
    }
  }

  deleteItem(index) {
    this.emailList = this.emailList.filter((e, ind) => ind !== index);
  }

  editItem() {}

  checkEmailValidity(event: any) {
    const inputValue: string = event.target.value;
    // กำหนด pattern สำหรับอีเมล
    const emailPattern: RegExp = /^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,})(,{0,1})?$/;
    // ตรวจสอบค่าที่ป้อนเข้ามาใน input field ว่าตรงกับ pattern หรือไม่
    if (!emailPattern.test(inputValue)) {
      console.log("Invalid email format");
      // ถ้ารูปแบบอีเมลไม่ถูกต้องให้แทนที่ค่าที่ไม่ถูกต้องด้วย ""
      event.target.value = inputValue.replace(/[^0-9]/g, "");
    } else {
      console.log("Valid email format");
      // ทำอะไรก็ตามที่ต้องการสำหรับรูปแบบอีเมลที่ถูกต้อง
    }
  }
}
