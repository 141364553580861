import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { Observable } from 'rxjs';
import { CreateGoodListPopupComponent } from '../../create-co-inquiry/create-goodlist-popup/create-goodlist-popup.component';

@Component({
  selector: 'app-verify-item-detail',
  templateUrl: './verify-item-detail.component.html',
  styleUrls: ['./verify-item-detail.component.scss']
})
export class VerifyItemDetailComponent implements OnInit {
  _data: any;
  @Input()
  set data(value) {
    this._data = value;
    this.ngOnInit();
  }
  get data() {
    return this._data;
  }

  form: any = {
    markItem: '',
    //markCHK: false,
    description: '',
    descriptionCHK: false,
    quantity: null,
    quantityObj: {},
    quantityCHK: false,
    weight: null,
    weightObj: {},
    weightCHK: false,
    grossWeight: null,
    grossWeightObj: {},
    grossWeightCHK: false,
    harmonizedCode: '',
    exportValue: null,
    exchangeRate: 29.93,
    exchangeValue: null,
  }
  datasource: any[] = []
  weightList: any[] = []
  unitList: any[] = []
  harmonizedList: any[] = []
  Harmonizedform: any = {}

  filteredOptions: Observable<string[]>
  apiRequest: any = {
    name: '',
    code: '',
  }
  detail = {
    quantity: '',
    weight: '',
    grossWeight: '',
  }
  apiExchangeRateRequest: any = {}
  form1: any = {}
  floatData: string = ' '
  edit: boolean
  constructor(
    public apiMasterService: AppMasterService,
    public alertService: AlertMessageService,
  ) {

  }
  ngOnInit(): void {
    if (this.data == undefined || this.data == null || this.data.data == undefined || this.data.data == null) {
      return;
    }
    this.edit = this.data.edit
    this.loadData(this.data.data)
  }
  async loadData(data) {
    const dataUnit = {}
    await this.apiMasterService.getListUnit(dataUnit).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        if (res !== null) {
          this.unitList = res
        }
      }
    })
    const dataWeight = {}
    await this.apiMasterService.getListWeight(dataWeight).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        if (res !== null) {
          this.weightList = res
        }
      }
    })
    this.getExchangeRate()
    if (!(Object.keys(data).length === 0 && data.constructor === Object)) {
      this.apiRequest.code = data.harmonizedCode
      await this.getHarmonizedCode()
      this.form = data
      this.form.descriptionCHK = data.descriptionChkForm == 'Y' ? true : false
      this.form.grossWeightCHK = data.grossWeightChkForm == 'Y' ? true : false
      this.form.markCHK = data.markChkForm == 'Y' ? true : false
      this.form.quantityCHK = data.quantityChkForm == 'Y' ? true : false
      this.form.weightCHK = data.netWeightChkForm == 'Y' ? true : false
      this.Harmonizedform = this.harmonizedList.find(
        (x) => x.code == data.harmonizedCode,
      )
      this.form1.weightObj = data.weightUnitCode
      this.form1.quantityObj = data.quantityUnitCode
      this.form1.grossWeightObj = data.grossWeightUnitCode

      await this.getExchangeRate()
      this.updateExchangeValue()
      if (this.edit == false) {
        this.form.quantityObj = this.unitList.find(
          (x) => x.code == this.form1.quantityObj,
        )
        this.detail.quantity =
          this.form.quantity + ' ' + this.form.quantityObj.name

        this.form.weightObj = this.weightList.find(
          (x) => x.code == this.form1.weightObj,
        )
        this.detail.weight = this.form.weight + ' ' + this.form.weightObj.name

        this.form.grossWeightObj = this.weightList.find(
          (x) => x.code == this.form1.grossWeightObj,
        )
        this.detail.grossWeight =
          this.form.grossWeight + ' ' + this.form.grossWeightObj.name
      }
    } else {
      this.form1.weightObj = this.weightList[30].code
      this.form1.grossWeightObj = this.weightList[30].code
      this.form1.quantityObj = this.unitList[0].code

      await this.getHarmonizedCode()
    }
  }

  async getHarmonizedCode() {
    await this.apiMasterService
      .getHarmonizedCode(this.apiRequest)
      .then((res: any) => {
        if (res == 'error') {
          return
        } else {
          this.harmonizedList = res
          this.filteredOptions = res
        }
      })
  }
  getExchangeRate() {
    this.apiMasterService
      .exchangeratemaxdate(this.apiExchangeRateRequest)
      .then((res: any) => {
        if (res == 'error') {
          return
        } else {
          this.form.exchangeRate = res.rate
        }
      })
  }
  updateExchangeValue() {
    this.form.exchangeValue = (this.form.exportValue * this.form.exchangeRate).toFixed(3);
    // this.form.exchangeValue = parseInt(this.floatData).toFixed(3);
  }
  save() {
    this.form.harmonizedCode = this.Harmonizedform.code
    this.form.weightObj = this.weightList.find(
      (x) => x.code == this.form1.weightObj,
    )
    this.form.quantityObj = this.unitList.find(
      (x) => x.code == this.form1.quantityObj,
    )
    this.form.grossWeightObj = this.weightList.find(
      (x) => x.code == this.form1.grossWeightObj,
    )
    let validate = this.validate(this.form)
    if (validate) {
    } else {
      return
    }
  }

  validate(form): boolean {
    const symbolRegex = /^[a-zA-Z0-9 !"$%&'()*+,-./#:;<=>?@[\\\]^_`{|}~\n\r฿]*$/;

    if (
      !form.markItem ||
      form.markItem === undefined ||
      form.markItem == ''
    ) {
      this.alertService.open({
        message: 'กรุณาระบุเครื่องหมายหีบห่อ',
        title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
      })
      return false
    }
    if (
      !form.description ||
      form.description === undefined ||
      form.description == ''
    ) {
      this.alertService.open({
        message: 'กรุณาระบุชื่อสินค้า',
        title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
      })
      return false
    }
    if (
      !form.quantity ||
      form.quantity === undefined ||
      form.quantity == 0 ||
      form.quantity == ''
    ) {
      this.alertService.open({
        message: 'กรุณาระบุปริมาณสินค้า',
        title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
      })
      return false
    }
    if (
      !form.quantityObj ||
      form.quantityObj === undefined ||
      form.quantityObj == 0 ||
      (Object.keys(form.quantityObj).length === 0 &&
        form.quantityObj.constructor === Object)
    ) {
      this.alertService.open({
        message: 'กรุณาระบุหน่วยปริมาณ',
        title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
      })
      return false
    }
    if (
      !form.weight ||
      form.weight === undefined ||
      form.weight == 0 ||
      form.weight == ''
    ) {
      this.alertService.open({
        message: 'กรุณาระบุน้ำหนักสินค้า',
        title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
      })
      return false
    }
    if (
      !form.weightObj ||
      form.weightObj === undefined ||
      form.weightObj == 0 ||
      (Object.keys(form.weightObj).length === 0 &&
        form.weightObj.constructor === Object)
    ) {
      this.alertService.open({
        message: 'กรุณาระบุหน่วยน้ำหนัก',
        title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
      })
      return false
    }
    if (
      !form.grossWeight ||
      form.grossWeight === undefined ||
      form.grossWeight == 0 ||
      form.grossWeight == ''
    ) {
      this.alertService.open({
        message: 'กรุณาระบุน้ำหนักรวมสินค้า',
        title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
      })
      return false
    }
    if (
      !form.grossWeightObj ||
      form.grossWeightObj === undefined ||
      form.grossWeightObj == 0 ||
      (Object.keys(form.grossWeightObj).length === 0 &&
        form.grossWeightObj.constructor === Object)
    ) {
      this.alertService.open({
        message: 'กรุณาระบุหน่วยน้ำหนัก',
        title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
      })
      return false
    }
    if (
      !form.harmonizedCode ||
      form.harmonizedCode === undefined ||
      form.harmonizedCode == 0 ||
      form.harmonizedCode == ''
    ) {
      this.alertService.open({
        message: 'กรุณาระบุพิกัดศุลกากร',
        title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
      })
      return false
    }
    if (
      !form.exportValue ||
      form.exportValue === undefined ||
      form.exportValue == 0 ||
      form.exportValue == ''
    ) {
      this.alertService.open({
        message: 'กรุณาระบุมูลค่าการส่งออก',
        title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
      })
      return false
    }
    if (
      !form.exchangeRate ||
      form.exchangeRate === undefined ||
      form.exchangeRate == 0 ||
      form.exchangeRate == ''
    ) {
      this.alertService.open({
        message: 'กรุณาระบุอัตราแลกเปลี่ยน',
        title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
      })
      return false
    }
    if (
      !form.exchangeValue ||
      form.exchangeValue === undefined ||
      form.exchangeValue == 0 ||
      form.exchangeValue == ''
    ) {
      this.alertService.open({
        message: 'กรุณาระบุมูลค่าการแลกเปลี่ยน',
        title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
      })
      return false
    }
    if (!(symbolRegex.test(form.markCHK))) {
      this.alertService.open({
        message: 'เครื่องหมายหีบห่อกรอกได้เฉพาะภาษาอังกฤษกับสัญลักษณ์',
        title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
      })
      return false
    }
    if (!(symbolRegex.test(form.descriptionCHK))) {
      this.alertService.open({
        message: 'ชื่อสินค้ากรอกได้เฉพาะภาษาอังกฤษกับสัญลักษณ์',
        title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
      })
      return false
    }
    return true
  }
  selectedOption(e) { }
  valuechange(e) {
    var preName: string
    if (typeof e == 'object') {
      preName = e.name
    } else {
      preName = e
    }

    this.apiRequest = {
      name: preName,
      code: '',
    }
    this.getHarmonizedCode()
  }

  onInput(e) {
    // 
    // this.apiRequest.searchText = e.value;
    this.apiRequest.name = e.option.value.name
    this.apiRequest.code = e.option.value.code

    this.apiRequest = {
      name: e.option.value.name == null ? '' : e.option.value.name,
      code: e.option.value.code == null ? '' : e.option.value.code,
    }
    // 
    // 
  }
  displayFn(value: any): string | undefined {
    if (!value) {
      return ''
    }
    return value ? value.name : undefined
  }

}
