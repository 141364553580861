<mat-card>
    <mat-card-header>
        <div class="flex justify-between" style="width: 100%;">
            <div class="text-lg">Exchange Rate</div>
            <div>
                <button class="close-button" [mat-dialog-close]="true">
                    <mat-icon class="text-white" [mat-dialog-close]>cancel</mat-icon>
                </button>
            </div>
        </div>
    </mat-card-header>

    <mat-dialog-content class="mat-typography">
        <div style="display: flex;flex-flow: column;gap: 15px;align-items: center;max-width: 100%;"
            class="pl-5 pr-5 mb-3 justify-start">
            <div class="mat-button flex flex-col gap-3" style="width: 80%;">
                <div class="flex-auto flex flex-row gap-3 justify-start" style="width: 100%">
                    <div class="flex flex-col" style="width: 25%">
                        <h4>สกุลเงิน <span style="color:red;">*</span></h4>
                        <h4>Currency</h4>
                    </div>
                    <div class="flex-auto flex flex-row gap-3" style="width:75%">
                        <div style="display: flex; align-items: center;">:</div>
                        <div style="width: 100%">
                            <mat-form-field class="flex-auto" style="width: 100%">
                                <input autocomplete="off" type="text" required matInput [(ngModel)]="form.currency"
                                    name="currency" #currency="ngModel">
                            </mat-form-field>
                        </div>
                        <!-- <mat-form-field class="flex-auto ml-2">
                        <mat-select [(ngModel)]="form.currency" required name="currency" #currency="ngModel">
                            <mat-option *ngFor=" let currency of  currencyList" [value]="currency">
                                {{currency.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field> -->
                    </div>
                </div>
                <div class="flex-auto flex flex-row gap-3 justify-start" style="width: 100%">
                    <div class="flex flex-col" style="width: 25%">
                        <h4>อัตรา <span style="color:red;">*</span></h4>
                        <h4>Rate Value</h4>
                    </div>
                    <div class="flex-auto flex flex-row gap-3" style="width:75%">
                        <div style="display: flex; align-items: center;">:</div>
                        <div style="width: 100%">
                            <mat-form-field class="flex-auto" style="width: 100%">
                                <input autocomplete="off" type="number" minlength="0" required matInput
                                    [(ngModel)]="form.rate" name="rate" #rate="ngModel">
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="flex-auto flex flex-row gap-3 justify-start" style="width: 100%">
                    <div class="flex flex-col" style="width: 25%">
                        <h4>วันที่เริ่ม <span style="color:red;">*</span></h4>
                        <h4>Start Date</h4>
                    </div>
                    <div class="flex-auto flex flex-row gap-3" style="width:75%">
                        <div style="display: flex; align-items: center;">:</div>
                        <div style="width: 100%;">
                            <mat-form-field class="flex-auto" style="width: 100%">
                                <input matInput readonly [matDatepicker]="Datepick" type="datetime"
                                    [(ngModel)]="form.startDate" name="startDate" #startDate="ngModel"
                                    (click)="Datepick.open()"
                                    class="cursor-pointer">
                                <mat-hint>MM/DD/YYYY</mat-hint>
                                <mat-datepicker-toggle matIconSuffix [for]="Datepick"></mat-datepicker-toggle>
                                <mat-datepicker #Datepick></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="flex-auto flex flex-row gap-3 justify-start" style="width: 100%">
                    <div class="flex flex-col" style="width: 25%">
                        <h4>ใช้งาน</h4>
                        <h4>Enable flag</h4>
                    </div>
                    <div class="flex-auto flex flex-row gap-3" style="width:75%">
                        <div style="display: flex; align-items: center;">:</div>
                        <div style="width: 100%;display: flex; align-items: center;">
                            <mat-slide-toggle [checked]="form.isActive === 'Y' ? true : false"
                                (change)="onChange($event)" style="width: 100%;" class="flex">
                                Active</mat-slide-toggle>
                        </div>
                    </div>
                </div>


            </div>
        </div>


    </mat-dialog-content>
    <mat-dialog-actions align="center" style="padding-bottom: 1.5em;">
        <button mat-raised-button color="accent" (click)="save()">บันทึก</button>
        <button mat-raised-button mat-dialog-close (click)="cancel()">ยกเลิก</button>

    </mat-dialog-actions>
</mat-card>