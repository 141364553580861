import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { AppCOService } from 'app/business/service/app/app-co.service'
import { AppVerifyService } from 'app/business/service/app/app-verify.service'
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'
import { AppMasterService } from 'app/business/service/app/app-master.service'
import { MarkError } from 'app/business/interface/app/app-co.interface'
import { AppUserService } from 'app/business/service/app/app-user.service'
import { AuthService } from 'app/core/auth/auth.service'

@Component({
  selector: 'app-error-mark-popup',
  templateUrl: './error-mark-popup.component.html',
  styleUrls: ['./error-mark-popup.component.scss'],
})
export class ErrorMarkPopupComponent implements OnInit {
  placeholderField = 'ตัวอย่าง Mark'
  placeholderDocType = 'ประเภท Field'
  markTypeList = []
  markDetailList = []
  examplemark: string
  fieldName: any = {}
  form: MarkError = {} as MarkError
  dataForm: any = {}
  userToken:any;
  constructor(
    private appVerifyService: AppVerifyService,
    private appCOService: AppCOService,
    private appMasterService: AppMasterService,
    private appUserService:AppUserService,
    private alertService: AlertMessageService,
    private _authService:AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ErrorMarkPopupComponent>,
  ) {

  }
  ngOnInit(): void {
    this.userToken = JSON.parse(
      this._authService.loginUser
    );
    this.form.attachPicId = this.data.form.attachPicId
    this.form.docTypeName = this.data.attachment.attachTypeName
    this.form.docTypeId = parseInt(this.data.attachment.attachType)
    this.dataForm = this.data.form
    debugger;
    this.loadData()
  }

  async loadData() {
    const request = {
      docType: this.data.docType,
    }

    this.appMasterService.getMarkTypeListByType({ typeId: this.data.form.docTypeId }).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        this.markTypeList = res;
        if (this.dataForm.fieldId !== 0 && this.dataForm.fieldId !== undefined) {
          let fieldType = this.dataForm.fieldId;
          const request = {
            markTypeId: this.markTypeList.find(x => x.fieldType == fieldType).markTypeId
          }
          this.appMasterService.getMarkDetail(request).then((res) => {
            this.markDetailList = res
          })
        }
      }
    })

   
  }

  fieldTypeChange(e) {
    let fieldType = e.value;
    const request = {
      markTypeId: this.markTypeList.find(x => x.fieldType == fieldType).markTypeId
    }

    this.appMasterService.getMarkDetail(request).then((res) => {
      this.markDetailList = res
    })
  }

  exampleChange(e) {
    this.dataForm.remark = e.name
  }

  cancel() {
    this.dialogRef.close(null)
  }
  
  save() {
      // TODO attachTypeId
      this.form.markId = this.dataForm.markId ?? 0;
      this.form.packId = this.dataForm.packId;
      this.form.indexNo = this.dataForm.indexNo;
      this.form.fieldId = this.dataForm.fieldId;
      this.form.fieldName = this.markTypeList.find(
        (x) => x.id == this.dataForm.fieldId,
      ).name;
      this.form.remark = this.dataForm.remark;
      this.form.markById = this.userToken.uId;
      this.form.markByName = this.userToken.name;
      this.form.docTypeId = this.dataForm.docTypeId;
      this.form.docTypeName = this.dataForm.docTypeName;
      this.form.pos_x = this.dataForm.pos_x;
      this.form.pos_y = this.dataForm.pos_y;
      this.form.typeDescription = typeof(this.dataForm.typeDescription) === 'string' ? this.dataForm.typeDescription : this.dataForm.typeDescription.name
      
      this.appVerifyService.saveErrorMarker(this.form).then(res => {
        if (res === 'error') {
          this.alertService.open({message: "เกิดข้อผิดพลาดระหว่างการบันทึกข้อมูล", title: ""});
        } else {
          this.form.markId = res.markId;
          this.form.markById = res.markById;
  
          this.dialogRef.close(this.form);  
        }
      });

  }
}
