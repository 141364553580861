<mat-card>
    <mat-card-header>
        <div class="flex justify-between" style="width: 100%;">
            <div class="text-lg">Attach Type</div>
            <div>
                <button class="close-button" [mat-dialog-close]>
                    <mat-icon class="text-white" [mat-dialog-close]>cancel</mat-icon>
                </button>
            </div>
        </div>
    </mat-card-header>
    <mat-dialog-content>
        <div class="pl-22 pr-22 flex flex-col gap-4">
            <div class="flex-row flex justify-between gap-2.5">
                <div class="mat-popup-header flex flex-col">
                    <h4>ชนิดเอกสาร <span style="color:red;">*</span></h4>
                    <h4>Document Name</h4>
                </div>
                <div class="mat-popup flex flex-row justify-between items-center">
                    <div>:</div>
                    <mat-form-field style="flex: 0 80%;" class="flex">
                        <input required autocomplete="off" type="text" matInput placeholder="ชนิดเอกสาร" matInput #input
                            [(ngModel)]="form.longName" name="Name" #Name="ngModel">
                    </mat-form-field>
                </div>
            </div>
            <div class="flex-row flex justify-between gap-2.5">
                <div class="mat-popup-header flex flex-col">
                    <h4>รูปแบบเอกสาร <span style="color:red;">*</span></h4>
                    <h4>Document Type</h4>
                </div>
                <div class="mat-popup flex flex-row justify-between items-center">
                    <div>:</div>
                    <mat-form-field style="flex: 0 80%;" class="flex">
                        <mat-select required name="type" [(ngModel)]="form.type">
                            <mat-option *ngFor="let type of typeList" [value]="type.typeCode">
                              {{type.typeName}}
                            </mat-option>
                          </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="flex-row flex justify-between gap-2.5">
                <div class="mat-popup-header flex flex-col">
                    <h4>ใช้งาน</h4>
                    <h4>Active</h4>
                </div>
                <div class="mat-popup flex flex-row justify-between items-center">
                    <div>:</div>
                    <mat-slide-toggle [checked]="form.isActive === 'Y' ? true : false" (change)="onChange($event)"
                        style="flex: 0 80%;" class="flex">
                        Active</mat-slide-toggle>
                </div>
            </div>
            <div class="flex-row flex justify-center gap-2.5" style="gap: 10px;">
                <button mat-raised-button color="accent" (click)="save()">บันทึก</button>
                <button mat-raised-button [mat-dialog-close]>ยกเลิก</button>
            </div>
        </div>
    </mat-dialog-content>