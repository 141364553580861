import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { LiveAnnouncer } from "@angular/cdk/a11y";
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import { MatTableDataSource } from "@angular/material/table";
import moment from "moment";
import { IcoStatus } from "app/business/interface/app/app-co.interface";
import { AppRecheckService } from "app/business/service/app/app-recheck.service";
import { ISaveRecheckStatusRequest } from "app/business/interface/api/api-verify.interface";
import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import { RecheckManagementComponent } from "app/page/recheck-management/recheck-management";
import { RecheckManagementPopupComponent } from "./recheck-management-popup/recheck-management-popup.component";
import { Router } from "@angular/router";
import { AppMenuService } from "app/business/service/app/app-menu.service";
import { AppUserService } from "app/business/service/app/app-user.service";
import { AppMasterService } from "app/business/service/app/app-master.service";
import { AuthService } from 'app/core/auth/auth.service'

@Component({
  selector: "app-recheck-management-inquiry",
  templateUrl: "./recheck-management-inquiry.component.html",
  styleUrls: ["./recheck-management-inquiry.component.scss"],
})
export class RecheckManagementInquiryComponent
  implements OnInit, AfterViewInit
{
  myDate = new Date();
  form: any = {};
  searchForm: any = {
    startDate: null,
    endDate: null,
    rqNo: "",
    invoiceRef: "",
    thtNo: "",
    status: "",
  };
  statusOptions: any = {};
  displayedColumns: string[] = [
    "no",
    "rqNo",
    "docType",
    "requestDate",
    "createDate",
    "requestBy",
    "reason",
    "status",
    "action",
  ];
  count: any = 0;
  searchString: string;
  apiRequest: any = {
    startDate: null,
    endDate: null,
    rqNo: "",
    status: "",
    pagination: {
      Skip: 0,
      Take: 10,
    },
  };
  apiResponse: any[];
  status: number = 1;
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  userProfile: any = {};
  url: any;
  buttonList: any[] = [];
  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private AlertMessageService: AlertMessageService,
    private appRecheckService: AppRecheckService,
    private confirmDialogService: ConfirmDialogService,
    private router: Router,
    private appUserService: AppUserService,
    private appMenuService: AppMenuService,
    private appMasterService: AppMasterService,
    private _authService : AuthService,
  ) {}

  async getRouter(): Promise<void> {
    this.url = this.router.url;

    const formData = {
      url: this.url,
      userGroupCode: this.userProfile.userGroupCode,
      userRoleCode: this.userProfile.userRoleCode,
      userCode: this.userProfile.userCode,
    };
    this.buttonList = await this.appMenuService.getButtonPermissionPerPages(
      formData
    );
  }
  async ngOnInit() {

    this.userProfile = JSON.parse(
      this._authService.loginUser
    );
  
    await this.getRouter();

    this.apiRequest.UID = this.userProfile.uId;
    this.apiRequest.userName = this.userProfile.userName;
    this.dataSource.paginator = this.paginator;
    this.apiRequest.pagination = {
      Skip:
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
      Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
    };
    this.loadData(this.apiRequest);
  }

  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find((e) => e.buttonCode == buttonCode);

    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }

  ngAfterViewInit() {
    this.paginator.pageIndex = 0;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  applyFilter(event: string) {
    this.apiRequest.requestNo = event;
    this.apiRequest.certNo = event;
    this.dataSource.paginator = this.paginator;
    // this.loadData(this.apiRequest);
  }
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce("Sorting cleared");
    }
  }
  stopPropagation(event) {
    event.stopPropagation();
  }

  onRefresh() {
    this.apiRequest = {
      id: 0,
      startDate: this.searchForm.startDate,
      endDate: this.searchForm.endDate,
      rqNo: this.searchForm.requestNo,
      status: this.searchForm.status,
    };

    this.apiRequest.pagination = {
      Skip:
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
      Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
    };
    this.dataSource.paginator = this.paginator;
    this.loadData(this.apiRequest);
  }

  saveSearch() {
    if (
      moment(this.searchForm.startDate).format("YYYY-MM-DD") >
      moment(this.searchForm.endDate).format("YYYY-MM-DD")
    ) {
      this.AlertMessageService.open({
        message: "กรุณาระบุวันที่สิ้นสุดให้มากกว่าวันที่เริ่มต้น",
        title: "กรุณากรอกข้อมูลค้นหาให้ถูกต้อง",
      });
      return false;
    }
    this.apiRequest = {
      id: 0,
      startDate: this.searchForm.startDate,
      endDate: this.searchForm.endDate,
      rqNo: this.searchForm.requestNo,
      status: this.searchForm.status,
    };

    this.apiRequest.pagination = {
      Skip:
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
      Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
    };
    this.dataSource.paginator = this.paginator;
    this.loadData(this.apiRequest);
  }

  async loadData(request) {
    const pageIndex = this.dataSource?.paginator?.pageIndex ?? 0;
    let config = {
      hCode: "APPROVETYPE",
    };
    await this.appMasterService.getConfigDByHCode(config).then((res: any) => {
      if (res === "error") {
        return;
      } else {
        this.statusOptions = res;
        if (this.statusOptions) {
          this.statusOptions.unshift({ dCode: "", dTName: "ทั้งหมด" });
        }
      }
    });
    await this.appRecheckService
      .getManagementRecheckList(request)
      .then((res: any) => {
        if (res === "error") {
          return;
        } else {
          if (res !== null) {
            let a = res.response.map((item) => {
              moment.locale("th"); // set default locale to Thai
              item.createDate = moment(item.createDate).format("YYYY-MM-DD");
              item.requestDate = moment(item.requestDate).format("YYYY-MM-DD");
              return item;
            });
            this.dataSource.data = a;
            this.count = res.totalCount;
          } else {
            this.dataSource.data = [];
            this.count = 0;
          }
        }
      });
    this.dataSource.paginator.length = this.count;
    this.dataSource.paginator.pageIndex = pageIndex;
  }

  async pageChanged(e) {
    this.apiRequest.pagination = {
      Skip: (!e.pageIndex ? 0 : e.pageIndex) * (!e.pageSize ? 0 : e.pageSize),
      Take: !e.pageSize ? 10 : e.pageSize,
    };
    await this.appRecheckService
      .getManagementRecheckList(this.apiRequest)
      .then((res: any) => {
        if (res === "error") {
          return;
        } else {
          if (res !== null) {
            let a = res.response.map((item) => {
              moment.locale("th"); // set default locale to Thai
              item.createDate = moment(item.createDate).format("YYYY-MM-DD");
              item.requestDate = moment(item.requestDate).format("YYYY-MM-DD");
              return item;
            });
            this.dataSource.data = a;
          } else {
            this.dataSource = new MatTableDataSource((res.response3 = []));
            this.count = 0;
          }
          this.count = res.totalCount;
        }
      });
    this.dataSource.paginator.length = this.count;
    this.dataSource.paginator.pageIndex = e.pageIndex;
  }
  clearOption() {
    this.searchForm = {};
  }
  edit(e) {
    //
    // const data = {
    //   declarator: e.declarator,
    //   recheckId: e.recheckId,
    //   packId: e.packId,
    //   reason: e.reason,
    //   rqNo: e.rqNo,
    // }
    // const dialogRef = this.dialog.open(VerifyRecheckPopupDetailComponent, {
    //   disableClose: false,
    //   width: '60vw',
    //   data: data,
    //   maxWidth: 'none',
    // })
    // dialogRef.afterClosed().subscribe((res) => {
    //   if (res == false) {
    //     return
    //   } else {
    //     return
    //   }
    // })
  }

  deleteItem(e) {
    const request = {
      recheckId: e.recheckId,
      isActive: "N",
    } as ISaveRecheckStatusRequest;
    this.confirmDialogService
      .open({ header: "", content: "ยืนยันที่จะลบข้อมูลนี้?" })
      .then((res) => {
        if (res == true) {
          this.appRecheckService.saveRecheckStatus(request).then((res) => {
            if (res) {
              this.AlertMessageService.open({
                title: "",
                message: "ลบข้อมูลสำเร็จ",
              }).then((res) => {
                this.loadData(this.apiRequest);
              });
            } else {
              return;
            }
          });
        } else {
          return;
        }
      });
  }

  approve(e) {
    const data = {
      recheckId: e.recheckid,
      packId: e.packId,
      rqNo: e.rqNo,
      compName: "",
      reason: e.reason,
      forRecheck: "",
      forRecheckName: "",
      approveType: "",
      approveTypeName: "",
    };

    const dialogRef = this.dialog.open(RecheckManagementPopupComponent, {
      data: { data: data, flag: true },
      width: "60vw",
      maxWidth: "none",
    });

    dialogRef.afterClosed().subscribe((res) => {
      this.loadData(this.apiRequest);
    });
  }

  showDetail(e) {
    const data = {
      recheckId: e.recheckid,
      packId: e.packId,
      rqNo: e.rqNo,
      compName: "",
      reason: e.reason,
      forRecheck: "",
      forRecheckName: "",
      approveType: "",
      approveTypeName: "",
    };

    const dialogRef = this.dialog.open(RecheckManagementPopupComponent, {
      data: { data: data, flag: false },
      width: "60vw",
      maxWidth: "none",
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.loadData(this.apiRequest);
      }
    });
  }

  cancel(e) {
    const request = {
      recheckId: e.recheckId,
      isActive: "Y",
      approveType: "-",
    } as ISaveRecheckStatusRequest;
    this.confirmDialogService
      .open({ header: "", content: "ยืนยันที่จะยกเลิกคำขอ?" })
      .then((res) => {
        if (res == true) {
          this.appRecheckService.saveRecheckStatus(request).then((res) => {
            if (res) {
              this.AlertMessageService.open({
                title: "",
                message: "ยกเลิกขอสำเร็จ",
              }).then((res) => {
                this.loadData(this.apiRequest);
              });
            } else {
              return;
            }
          });
        } else {
          return;
        }
      });
  }
}
