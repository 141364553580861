import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AppBankService } from 'app/business/service/app/app-bank.service';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { ConfirmPopupComponent } from '../../confirm-popup/confirm-popup.component';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'

@Component({
  selector: 'app-sub-district-manage-popup',
  templateUrl: './sub-district-manage-popup.component.html',
  styleUrls: ['./sub-district-manage-popup.component.css']
})
export class SubDistrictManagePopupComponent implements OnInit {
  form: any = {};
  bankList: any;
  regionList: any[] = [];
  provinceList: any[] = [];
  districtList: any[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef: MatDialogRef<SubDistrictManagePopupComponent>,
    private appMasterService: AppMasterService,
    private dialog: MatDialog,
    private alertMessageService: AlertMessageService
  ) {
    if (data) {
      this.form = data;
    } else {
      this.form.isActive = 'Y';
      this.form.geoId = 0;
      this.form.provinceId = 0;
      this.form.districtId = 0;
    }
    
  }
  ngOnInit(): void {
    this.regionList = [
      {
        "regionCode": 0,
        "regionName": "กรุณาเลือก"
      },
      {
        "regionCode": 1,
        "regionName": "ภาคเหนือ"
      },
      {
        "regionCode": 2,
        "regionName": "ภาคกลาง"
      },
      {
        "regionCode": 3,
        "regionName": "ภาคตะวันออกเฉียงเหนือ"
      },
      {
        "regionCode": 4,
        "regionName": "ภาคตะวันตก"
      },
      {
        "regionCode": 5,
        "regionName": "ภาคตะวันออก"
      },
      {
        "regionCode": 6,
        "regionName": "ภาคใต้"
      }
    ]

    this.loadProvince();
    this.loadDistrict();
  }

  loadProvince() {
    const data = {
      geoId: this.form.geoId
    };

    this.appMasterService.getMasterProvince(data).then(res => {
      if (res === "error") {
        return;
      } else {
        this.provinceList = res;

        const CheckAll = this.provinceList.find(element => element.projectName === "ทั้งหมด");
        if (CheckAll === undefined) {
          this.provinceList.unshift({ "provinceId": 0, "provinceName": "กรุณาเลือก" });
        }
      }
    });
  }

  loadDistrict() {
    const data = {
      "provinceId": this.form.provinceId,
    };

    this.appMasterService.getMasterDistrictList(data).then(res => {
      if (res === "error") {
        return;
      } else {
        this.districtList = res;

        const CheckAll = this.districtList.find(element => element.districtName === "ทั้งหมด");
        if (CheckAll === undefined) {
          this.districtList.unshift({ "id": 0, "districtName": "ทั้งหมด" });
        }
      }
    });
  }

  onGeoChange(e) {
    if (e.value == 0) {
      this.form.geoId = null;
    }

    this.loadProvince();
    this.form.provinceId = 0;
  }

  onProvinceChange(e) {
    if (e.value == 0) {
      this.form.provinceId = null;
    }

    this.loadDistrict();
    this.form.districtId = 0;
  }


  save() {
    
    if ((this.form.subDistrictCode !== undefined && this.form.subDistrictCode !== null && this.form.subDistrictCode !== "") &&
        (this.form.subDistrictName !== undefined && this.form.subDistrictName !== null && this.form.subDistrictName !== "") &&
        (this.form.subDistrictEname !== undefined && this.form.subDistrictEname !== null && this.form.subDistrictEname !== "") &&
        (this.form.geoId !== 0) && (this.form.provinceId !== 0) && (this.form.districtId !== 0)) {
      const data = {
        id: this.form.id,
        subDistrictCode: this.form.subDistrictCode,
        subDistrictName: this.form.subDistrictName,
        subDistrictEname: this.form.subDistrictEname,
        geoId: this.form.geoId,
        provinceId: this.form.provinceId,
        districtId: this.form.districtId,
        isActive: this.form.isActive
      };

      this.appMasterService.saveSubDistrict(data).then((res: any) => {
        if (res === "error") {
          this.alertMessageService.open({message: "บันทึกข้อมูลไม่สำเร็จ", title:""});
        } else {
          this.dialogRef.close();
          this.alertMessageService.open({message: "บันทึกข้อมูลสำเร็จ", title:""});
        }
      });

    } else {
      this.alertMessageService.open({ message: "กรุณากรอกข้อมูลให้ครบ", title: "ข้อผิดพลาด" });
    }
  }

  onChange(e) {
    if (e.checked == true) {
      this.form.isActive = "Y";
    } else {
      this.form.isActive = "N";
    }
  }

  endPopup(e) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      disableClose: false,
      // width: '30vw',
      data: {
        header: e,
        button: 'close'
      },
      maxWidth: 'none'
    });

    dialogRef.afterClosed().subscribe(res => {
      this.dialogRef.close();
    });
  }
}

