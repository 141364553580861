<div class="card-pd tltle-menu">
    Create Template
</div>
<div class="flex flex-col h-full gap-4">
    <div style="gap: 30px;" class="flex justify-items-stretch flex-row px-20">
        <div class="flex flex-col flex-1 grid-col-1 gap-4 justify-between ">
            <!-- Template Management -->
            <mat-card>
                <mat-card-header>
                    <div class="text-center">ข้อมูลผู้ส่งออก | EXPORTER FORM</div>
                </mat-card-header>
                <mat-card-content>
                    <div class="flex flex-row mt-4 gap-3">
                    </div>
                    <div class="flex flex-row mt-4 gap-3" >
                        <div class="w-1/2 flex flex-row">
                            <div class="w-1/4 flex flex-col">
                                <h4>ชื่อแม่แบบ<span class="required">*</span></h4>
                                <h4>Template Name</h4>
                            </div>
                            <div class="flex-auto form-field-container">
                                <mat-form-field appearance="fill" class="flex-auto pr-1 w-full">
                                    <input matInput placeholder="ระบุชื่อแม่แบบที่ต้องการบันทึก"
                                        [(ngModel)]="form.tempName" [ngModelOptions]="{standalone: true}" maxlength="50" [disabled]="PackidLoadData != undefined ? true : false">
                                </mat-form-field>
                                <p class="flex-auto text-end" *ngIf="form.tempName?.length !=null">
                                    {{form.tempName?.length+"/50"}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="flex mt-4 gap-3 flexCheckJobRow">
                        <div class="flex flex-row flexWidth50">
                            <div class="w-1/4 flex flex-col">
                                <h4>ชื่อผู้ยื่นคำขอ</h4>
                                <h4>Request By</h4>
                            </div>
                            <mat-form-field class="flex-auto">
                                <input matInput placeholder="ชื่อผู้ยื่นคำขอ" [(ngModel)]="form.requestBy"
                                    [ngModelOptions]="{standalone: true}" [disabled]="true">
                            </mat-form-field>
                        </div>
                        <div class="flex flex-row flexWidth50">
                            <div class="w-1/4 flex flex-col">
                                <div>
                                    <h4 class="">ผู้ส่งออกสินค้า <span class="required">*</span></h4>
                                </div>
                                <h4 class="">Declarator</h4>
                            </div>
                            <mat-form-field appearance="fill" class="flex-auto">
                                <mat-select [placeholder]="placeholderDeclarator" [(ngModel)]="form.declaratorId"
                                    name="declaratorId" #declaratorId="ngModel"
                                    (ngModelChange)="DropdromwChange($event)" required>
                                    
                                    <mat-option *ngFor="let companyRelation of declaratorListRelation"
                                        [value]="companyRelation.cid"
                                        [disabled]="companyRelation.blacklistStatus == 'Y'">
                                        {{companyRelation.companyNameEn}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                    </div>
                    <div class="flex mt-4 gap-3 flexCheckJobRow">
                        <div class="flex flex-row flexWidth50">
                            <div class="w-1/4 flex flex-col">
                                <h4 class="">ชื่อผู้ส่งออกสินค้า<span class="required">*</span></h4>
                                <h4 class="">(ปรากฏบนฟอร์ม)</h4>
                                <h4 class="">Consignor Name</h4>
                            </div>
                            <div class="flex-auto form-field-container">
                                <mat-form-field class="flex-auto w-full">
                                    <textarea matInput placeholder="ชื่อผู้ส่งออกสินค้า"
                                        [(ngModel)]="form.consignorName" name="consignorName"
                                        id="consignorName" required rows="4" cols="40"
                                        maxlength="100"
                                        (ngModelChange)="checkEnglish(form.consignorName) == false ? showErrorMessage.consignorNameEng = true : showErrorMessage.consignorNameEng = false;"></textarea> 
                                </mat-form-field>
                                <div class="flex flex-row">  
                                    <label *ngIf="showErrorMessage.consignorNameEng == true" class = "required position-absolute">กรอกได้เฉพาะภาษาอังกฤษและสัญลักษณ์</label>
                                    <label *ngIf="showErrorMessage.consignorName == true && showErrorMessage.consignorNameEng == false" class = "required position-absolute">ไม่สามารถกรอกอักขระพิเศษ</label>
                                    <p class="flex-auto text-end" *ngIf="form.consignorName?.length !=null">
                                        {{form.consignorName?.length+"/100"}}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-row flexWidth50 ">
                            <div class="w-1/4 flex flex-col">
                                <h4 class="">ที่อยู่<span class="required">*</span></h4>
                                <h4 class="">Consignor Address</h4>
                            </div>
                            <div class="flex-auto form-field-container">
                                <mat-form-field class="flex-auto" style="width: 100%">
                                    <textarea matInput placeholder="ที่อยู่" [(ngModel)]="form.consignorAddress"
                                        name="consignorAddress"  required rows="4" cols="40"
                                        maxlength="150" 
                                        (ngModelChange)="checkEnglish(form.consignorAddress) == false ? showErrorMessage.consignorAddressEng = true : showErrorMessage.consignorAddressEng = false;" 
                                        ></textarea>
                                </mat-form-field>
                                <div class="flex flex-row">  
                                    <label *ngIf="showErrorMessage.consignorAddressEng == true" class = "required position-absolute">กรอกได้เฉพาะภาษาอังกฤษและสัญลักษณ์</label>
                                    <label *ngIf="showErrorMessage.consignorAddress == true && showErrorMessage.consignorAddressEng == false" class = "required position-absolute">ไม่สามารถกรอกอักขระพิเศษ</label>
                                    <p class="flex-auto text-end" *ngIf="form.consignorAddress?.length != null">
                                        {{ form.consignorAddress?.length + "/150" }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex  mt-4 gap-3 flexCheckJobRow">
                        <div class="flex flex-row flexWidth50">
                            <div class="w-1/4 flex flex-col">
                                <h4 class="">วันที่ออกใบรับรอง</h4>
                                <h4 class="">Certificate Date</h4>
                            </div>
                            <mat-radio-group aria-label="Select an option" class="flex flex-col"
                                [(ngModel)]="form.certificateDateRequest" [ngModelOptions]="{standalone: true}" disabled="true">
                                <mat-radio-button value="approve_date">
                                    <div class="flex flex-col">
                                        <div>ตามวันที่อนุมัติ</div>
                                    </div>
                                </mat-radio-button>
                                <mat-radio-button value="first_request">
                                    <div class="flex flex-col">
                                        <div>ตามวันที่ยื่นคำขอครั้งแรก</div>
                                    </div>
                                </mat-radio-button>
                                <mat-radio-button value="on_date" class="flex flex-row">
                                    <div class="flex flex-row gap-3">
                                        <div class="flex flex-col">
                                            <div>วันที่ย้อนหลัง/ล่วงหน้า</div>
                                            <div>(ชำระค่าธรรมเนียมเพิ่มเติม)</div>
                                        </div>
                                        <mat-form-field appearance="fill"
                                            *ngIf="!(form.certificateDateRequest!='on_date')">
                                            <input matInput [matDatepicker]="picker" type="datetime"
                                                [(ngModel)]="form.certificateDate" name="certificateDate"
                                                #certificateDate="ngModel"
                                                (click)="picker.open()"
                                                readonly 
                                                class="cursor-pointer">
                                            <mat-hint>MM/DD/YYYY</mat-hint>
                                            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </mat-form-field>
                                    </div>

                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="flex flex-row flexWidth50">
                            <div class="w-1/4 flex flex-col">
                                <h4 class="">ลายเซ็นสด</h4>
                                <h4 class="">Live Signature</h4>
                            </div>
                            <mat-checkbox class="w-1/4 flexHeight40 example-margin " [(ngModel)]="form.liveSignature"
                                [ngModelOptions]="{standalone: true}" disabled="true">ระบุสาขา
                            </mat-checkbox>
                            <mat-form-field appearance="fill" class="flex-auto flexHeight40">
                                <mat-select [placeholder]="placeholderBranch" [(ngModel)]="form.sigBranchId"
                                    [ngModelOptions]="{standalone: true}" disabled="true">
                                    <mat-option *ngFor="let branch of branchList" [value]="branch.branchId" branchId>
                                        {{branch.branchName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="flex flex-row mt-4 gap-3">
                        <h3>ลายมือชื่อผู้มอบอำนาจ | SIGNATURE</h3>
                    </div>
                    <div class="flex mt-4 gap-3 flexCheckJobRow">
                        <div class="flex flex-row flexWidth50">
                            <div class="w-1/4 flex flex-col">
                                <h4 class="">ลายมือชื่อผู้มอบอำนาจ<span class="required">*</span></h4>
                                <h4 class="">SIGNATURE</h4>
                            </div>
                            <mat-form-field appearance="fill" class="flex-auto">
                                <mat-select [placeholder]="placeholderSignature" [(ngModel)]="form.signAuthorizedId"
                                    name="signAuthorizedId" #signAuthorizedId="ngModel" required>
                                    <mat-option *ngFor="let nameLastName of nameLastNameList  "
                                        [value]="nameLastName.csid">
                                        {{nameLastName.fullNameThai}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>

    <div>
        <div style="gap: 30px;" class="flex flex-row justify-items-stretch content-start px-20">
            <div class="flex flex-col flex-1 grid-col-1 gap-4 justify-between">
                <!-- Template Management -->
                <mat-card class="flex flex-col flex-1">
                    <mat-card-header>
                        <div class="text-center">ข้อมูลผู้รับสินค้าปลายทาง | CONSIGNEE FORM </div>
                    </mat-card-header>
                    <mat-card-content>
                        <section class="px-4 flex flex-col flex-1 py-4 gap-4">
                            <div class="flex gap-4 flex-row">
                                <div class="flex-1 flex flex-col">
                                    <h4 class="">ผู้รับสินค้าปลายทาง</h4>
                                    <h4 class="">Consignee</h4>
                                </div>
                
                                <div class="flex-auto form-field-container">
                                    <mat-form-field class="flex-auto w-full">
                                        <textarea matInput placeholder="ผู้รับสินค้าปลายทาง"
                                            [(ngModel)]="form.consigneeName" 
                                            [ngModelOptions]="{standalone: true}"  rows="4" cols="40"
                                            maxlength="185" 
                                            (ngModelChange)="checkEnglish(form.consigneeName) == false ? showErrorMessage.consigneeNameEng = true : showErrorMessage.consigneeNameEng = false;">
                                        </textarea>
                                    </mat-form-field>
                                    <div class="flex flex-row">  
                                        <label *ngIf="showErrorMessage.consigneeNameEng == true" class = "required position-absolute">กรอกได้เฉพาะภาษาอังกฤษและสัญลักษณ์</label>
                                        <label *ngIf="showErrorMessage.consigneeName == true && showErrorMessage.consigneeNameEng == false" class = "required position-absolute">ไม่สามารถกรอกอักขระพิเศษ</label>            
                                        <p class="flex-auto text-end" *ngIf="form.consigneeName?.length !=null">
                                            {{form.consigneeName?.length+"/185"}}
                                        </p>
                                    </div>
                                    
                                </div>
                                <div class="flex-1 flex flex-col">
                                    <h4 class="">ผู้ที่จะได้รับแจ้งเมื่อสินค้าไปถึง</h4>
                                    <h4 class="">Notify</h4>
                                </div>
                                <div class="flex-auto form-field-container">
                                    <mat-form-field class="flex-auto w-full">
                                        <textarea matInput placeholder="ผู้ที่จะได้รับแจ้งเมื่อสินค้าไปถึง"
                                            [(ngModel)]="form.consigneeNotify"
                                            [ngModelOptions]="{standalone: true}" rows="4" cols="40" maxlength="380"
                                            (ngModelChange)="checkEnglish(form.consigneeNotify) == false ? showErrorMessage.consigneeNotifyEng = true : showErrorMessage.consigneeNotifyEng = false;">>
                                    </textarea>
                                    </mat-form-field>
                                    <div class="flex flex-row">  
                                        <label *ngIf="showErrorMessage.consigneeNotifyEng == true" class = "required position-absolute">กรอกได้เฉพาะภาษาอังกฤษและสัญลักษณ์</label>
                                        <label *ngIf="showErrorMessage.consigneeNotify == true && showErrorMessage.consigneeNotifyEng == false" class = "required position-absolute">ไม่สามารถกรอกอักขระพิเศษ</label>
                                        <p class="flex-auto text-end" *ngIf="form.consigneeNotify?.length !=null">
                                            {{form.consigneeNotify?.length+"/380"}}
                                        </p>
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="flex gap-4 flex-row ">
                                <div class="flex-1 flex flex-col ">
                                    <h4 class="">ชื่อพาหนะ<span class="required">*</span></h4>
                                    <h4 class="">Vessel/Flight No.</h4>
                                </div>
                                <mat-form-field appearance="fill" class="flex-auto">
                                    <mat-select [placeholder]="placeholderVessel" [(ngModel)]="form.vesselType"
                                        [ngModelOptions]="{standalone: true}" >
                                        <mat-option *ngFor="let vesselType of vesselTypeList" [value]="vesselType.name">
                                            {{vesselType.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="flex-1 flex flex-col">
                                    <h4 class="">วันที่ขนสินค้าขึ้นพาหนะ</h4>
                                    <h4 class="">Loading on About/</h4>
                                    <h4 class="">Flight Date</h4>
                                </div>
                                <mat-form-field appearance="fill" class="flex-auto w-30">
                                    <input matInput [matDatepicker]="pick" [(ngModel)]="form.loadingDate"
                                        [ngModelOptions]="{standalone: true}" 
                                        (click)="pick.open()"
                                        readonly 
                                        class="cursor-pointer">
                                    <mat-datepicker-toggle matIconSuffix [for]="pick"></mat-datepicker-toggle>
                                    <mat-datepicker #pick></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="flex gap-4 flex-row ">
                                <div class="flex-1 flex flex-col">
                                </div>
                                <div class="flex-auto form-field-container">
                                <mat-form-field class="flex-auto w-full">
                                    <textarea matInput placeholder="ชื่อพาหนะ" [(ngModel)]="form.vesselNo"
                                        [ngModelOptions]="{standalone: true}" rows="4" cols="40"
                                        (ngModelChange)="checkEnglish(form.vesselNo) == false ? showErrorMessage.vesselNoEng = true : showErrorMessage.vesselNoEng = false;">
                                    </textarea>
                                </mat-form-field>
                                <div class="flex flex-row">  
                                    <label *ngIf="showErrorMessage.vesselNoEng == true" class = "required position-absolute">กรอกได้เฉพาะภาษาอังกฤษและสัญลักษณ์</label>
                                    <label *ngIf="showErrorMessage.vesselNo == true && showErrorMessage.vesselNoEng == false" class = "required position-absolute">ไม่สามารถกรอกอักขระพิเศษ</label>
                                    <p class="flex-auto text-end" *ngIf="form.vesselNo?.length !=null">
                                        {{form.vesselNo?.length+"/50"}}
                                    </p>
                                </div>
                                </div>
                                <div class="flex-1 flex flex-col">
                                    <h4 class="">ปลายทางสินค้าที่ถูกยกลง</h4>
                                    <h4 class="">Destination</h4>
                                </div>
                                <div class="flex-auto form-field-container">
                                <mat-form-field class="flex-auto w-full">
                                    <input matInput placeholder="ปลายทางที่สินค้าถูกยกลง "
                                        [(ngModel)]="form.destination" [ngModelOptions]="{standalone: true}"
                                        (ngModelChange)="checkEnglish(form.destination) == false ? showErrorMessage.destinationEng = true : showErrorMessage.destinationEng = false;">
                                </mat-form-field>
                                <div class="flex flex-row">  
                                    <label *ngIf="showErrorMessage.destinationEng == true" class = "required position-absolute">กรอกได้เฉพาะภาษาอังกฤษและสัญลักษณ์</label>
                                    <label *ngIf="showErrorMessage.destination == true && showErrorMessage.destinationEng == false" class = "required position-absolute">ไม่สามารถกรอกอักขระพิเศษ</label>
                                    <p class="flex-auto text-end" *ngIf="form.destination?.length !=null">
                                        {{form.destination?.length+"/100"}}
                                    </p>
                                </div>
                                </div>
                            </div>
                            <div class="flex gap-4 flex-row ">
                                <div class="flex-1 flex flex-col">
                                    <h4 class="">ประเทศปลายทาง</h4>
                                    <h4 class="">(ไม่ปรากฎบนฟอร์ม)</h4>
                                    <h4 class="">Destination Country</h4>
                                </div>
                                <mat-form-field appearance="fill" class="flex-auto">
                                    <mat-select [placeholder]="placeholderDestination"
                                        [(ngModel)]="form.destinationCountry" [ngModelOptions]="{standalone: true}">
                                        <mat-option *ngFor="let destination of destinationList  "
                                            [value]="destination.mC_COUNTRY_NAME">
                                            <!-- <[value]="destination.mC_ID"> -->

                                            {{destination.mC_COUNTRY_NAME}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="w-3/6" *ngIf="form.isPackRq"></div>
                                <div class="flex-1 flex flex-col" *ngIf="!form.isPackRq">
                                    <h4>ประเทศแหล่งกำเนิดสินค้า</h4>
                                    <h4>Country Of Origin</h4>
                                </div>
                                <mat-form-field class="flex-auto" *ngIf="!form.isPackRq">
                                    <mat-select [placeholder]="placeholderOrigin" [(ngModel)]="form.countryOfOrigin"
                                        [ngModelOptions]="{standalone: true}">
                                        <mat-option *ngFor="let destination of destinationList  "
                                            [value]="destination.mC_COUNTRY_NAME">
                                            {{destination.mC_COUNTRY_NAME}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div>
                                <div class="flex gap-4 flex-row" *ngIf="!form.isPackRq">
                                <div class="flex-1 flex flex-col">
                                    <h4>เลขใบกำกับสินค้า/วันที่</h4>
                                    <h4>Invoice No./Date</h4>
                                </div>
                                <!-- <div class="w-1/4" *ngIf="!form.isPackRq"> -->
                                        <mat-checkbox class="flex-auto ml-5 self-center" [(ngModel)]="form.invoiceChk"
                                            [ngModelOptions]="{standalone: true}" [checked]="isCheckedInvoice" >
                                            ไม่ปรากฎบนฟอร์ม</mat-checkbox>                                            
                                <!-- </div> -->
                                <div class="w-3/6 justify-end self-center text-end">
                                    <!-- <div class="flex flex-row justify-end my-3"> -->
                                        <dx-button class="justify-end w-12 h-12" icon="plus" (onClick)="addInvoice()">
                                        </dx-button>
                                    <!-- </div> -->
                                </div>
                                </div>
                                <div class="flex gap-4 flex-row" *ngIf="!form.isPackRq">
                                    <div class="w-2/12">
                                    </div>
                                    <div class="w-10/12 ml-20">
                                        <dx-data-grid #gridContainer [dataSource]="dataSourceInvoice"
                                            [allowColumnReordering]="true" [showBorders]="true"
                                            (onInitNewRow)="onInitNewRow($event)">
                                            <dxi-column dataField="invoiceId" [visible]="false">
                                            </dxi-column>
                                            <dxi-column dataField="docHid" [visible]="false">
                                            </dxi-column>
                                            <dxi-column dataField="invoiceNum" [visible]="false">
                                            </dxi-column>
                                            <dxi-column dataField="invoiceNo" caption="เลขที่ใบกำกับสินค้า">
                                            </dxi-column>
                                            <dxi-column dataField="invoiceDate" caption="วันที่ใบกำกับสินค้า"
                                                [format]="'yyyy-MM-dd'" dataType="date">
                                            </dxi-column>
                                            <dxi-column cellTemplate="action" [width]="100">
                                            </dxi-column>
                                            <div *dxTemplate="let row of 'action'" style="font-size:9px;">
                                                <button (click)="editInvoice(row)" >
                                                    <mat-icon svgIcon="heroicons_outline:pencil-alt"></mat-icon>
                                                </button>
                                                <button (click)="deleteInvoice(row)" (keydown.enter)="$event.preventDefault()">
                                                    <mat-icon svgIcon="heroicons_outline:trash"></mat-icon>
                                                </button>
                                            </div>
                                        </dx-data-grid>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </mat-card-content>
                </mat-card>
                <!-- CO PACK -->
                <mat-card *ngIf="!form.isPackRq">
                    <mat-card-header>
                        <div class="text-center">รายการสินค้า | GOOD LIST FORM </div>
                    </mat-card-header>
                    <mat-card-content>
                        <section class="px-4 flex flex-col flex-1 pb-4  justify-evenly">
                            <div class="flex flex-row justify-end my-3">
                                <dx-button class="justify-end w-12 h-12" icon="plus" (onClick)="addGoods()">
                                </dx-button>
                            </div>

                            <dx-data-grid [dataSource]="dataSourceGoodItem" [allowColumnReordering]="true"
                                [showBorders]="true">
                                <dxo-paging [pageSize]="50"> </dxo-paging>
                                <dxi-column dataField="no" caption="ลำดับ/No." [width]="100" cellTemplate="index"
                                    alignment="center">
                                </dxi-column>
                                <div *dxTemplate="let row of 'index'">
                                    {{ (row.rowIndex+1) +(dataGrid.instance.pageIndex() *
                                    dataGrid.instance.pageSize() )
                                    }}
                                </div>
                                <dxi-column dataField="markItem" caption="เครื่องหมายหีบห่อ/Mark"></dxi-column>
                                <dxi-column dataField="description" caption="ชื่อสินค้า/Description"></dxi-column>
                                <dxi-column dataField="quantity" caption="ปริมาณ/Quantity" cellTemplate="quantity"
                                    alignment="center" [width]="170"></dxi-column>
                                <div *dxTemplate="let row of 'quantity'">
                                    {{row.data?.quantity}} {{row.data?.quantityUnit}}
                                </div>
                                <dxi-column dataField="weight" caption="น้ำหนัก/Weight" cellTemplate="weight"
                                    alignment="center" [width]="170" ><dxo-format type="fixedPoint" [precision]="3"></dxo-format> </dxi-column>
                                <div *dxTemplate="let row of 'weight'">
                                    {{row.data?.weight}} {{row.data?.weightUnit}}
                                </div>
                                <dxi-column cellTemplate="action" [width]="100">
                                </dxi-column>
                                <div *dxTemplate="let row of 'action'">
                                    <button (click)="editGood(row)" >
                                        <mat-icon svgIcon="heroicons_outline:pencil-alt"></mat-icon>
                                    </button>
                                    <button (click)="deleteGood(row)"
                                        (keydown.enter)="$event.preventDefault()">
                                        <mat-icon svgIcon="heroicons_outline:trash"></mat-icon>
                                    </button>
                       
                                </div>
                            </dx-data-grid>
                            <mat-divider class="mt-3"></mat-divider>
                            <mat-label>รายละเอียดเพิ่มเติมท้ายรายการสินค้า</mat-label>
                            <div class="flex-auto form-field-container">
                                <mat-form-field class="flex-auto w-full">
                                    <textarea matInput [(ngModel)]="form.remark" name="remark" #remark="ngModel"
                                        [disabled]="editItem || editJobData ||isDisableFieldFooterDescription"
                                        rows="4" cols="40" maxlength="512"></textarea>
                                </mat-form-field>
                                <p class="flex-auto text-end" *ngIf="form.remark?.length !=null">
                                    {{form.remark?.length+"/512"}}
                                </p>
                            </div>
                        </section>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>

        <div class="flex flex-row justify-center my-3 gap-4">
            <button class="set-btn-unlimited flex-none " mat-raised-button color="accent" (click)="save()"
            [disabled]="clicked">บันทึก</button>
            <button class="set-btn-unlimited flex-none " mat-raised-button color="accent"
                routerLink="/page/configuration/config-template/list-template">ย้อนกลับ</button>
       
        </div>
    </div>
</div>