import { Injectable } from '@angular/core';
import { ApiService } from '@bundle/service/api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiNotificationService {

  constructor(
    private apiService: ApiService
  ) { }

  getNotificationList(request: any): Observable<any> {
    return this.apiService.post("/api/getNotificationListExternal", request);
  }

  getNotRegisteratedEmailInvoiceList(request: number): Observable<any> {
    return this.apiService.post("/api/getNotRegisteratedEmailInvoiceList", {
      cpId: request
    });
  }
}
