import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { ConfirmPopupComponent } from '../../confirm-popup/confirm-popup.component';

@Component({
  selector: 'app-attach-type-staff-popup',
  templateUrl: './attach-type-staff-popup.component.html',
  styleUrls: ['./attach-type-staff-popup.component.scss']
})
export class AttachTypeStaffPopupComponent implements OnInit {
  form: any = {};
  bankList: any;
  typeList: any[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef: MatDialogRef<AttachTypeStaffPopupComponent>,
    private appMasterService: AppMasterService,
    private dialog: MatDialog,
    private alertMessageService: AlertMessageService
  ) {
    if (data) {
      this.form = data;
    } else {
      this.form.isActive = 'Y';
      this.form.type = '';
    }
  }
  ngOnInit(): void {
    this.typeList = [
      {
        "typeCode":"",
        "typeName":"กรุณาเลือก"
      },
      {
        "typeCode":"CO",
        "typeName":"CO"
      },
      {
        "typeCode":"CF",
        "typeName":"CF"
      }
    ];
  }

  save() {
    if ((this.form.type !== undefined && this.form.type !== null && this.form.type !== "") && (this.form.longName !== undefined && this.form.longName !== null && this.form.longName !== "")) {
      const data = {
        id: this.form.id,
        type: this.form.type,
        longName: this.form.longName,
        isActive: this.form.isActive
      };
      this.appMasterService.saveAttachType(data).then((res: any) => {
        if (res === "error") {
          this.alertMessageService.open({message: "เกิดข้อผิดพลาดระหว่างบันทึกข้อมูล", title:"ข้อผิดพลาด"});
        } else {
          this.dialogRef.close();
          this.alertMessageService.open({message: "บันทึกข้อมูลสำเร็จ", title:""});
        }
      });
    } else {
      this.alertMessageService.open({message: "กรุณากรอกข้อมูลให้ครบ", title:"ข้อผิดพลาด"});
    }
  }

  onChange(e) {
    if (e.checked == true) {
      this.form.isActive = "Y";
    } else {
      this.form.isActive = "N";
    }
  }

  endPopup(e) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      disableClose: false,
      // width: '30vw',
      data: {
        header: e,
        button: 'close'
      },
      maxWidth: 'none'
    });

    dialogRef.afterClosed().subscribe(res => {
      this.dialogRef.close();
    });
  }

}