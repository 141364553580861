<mat-card>
  <mat-card-header>
    <div class="flex justify-between" style="width: 100%;">
      <div class="text-lg"> District </div>
      <div>
        <button class="close-button" [mat-dialog-close]="true">
          <mat-icon class="text-white" [mat-dialog-close]>cancel</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-dialog-content>
    <div class="pl-22 pr-22 flex flex-col gap-4">
      <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
        <div class="mat-popup-header flex flex-col">
          <h4>รหัสเขต/อำเภอ <span style="color:red;">*</span></h4>
          <h4>District Code</h4>
        </div>
        <div class="mat-popup flex flex-row justify-between items-center" style="width:74%">
          <div>:</div>
          <mat-form-field style="flex: 0 95%;" class="flex">
            <input required autocomplete="off" type="text" matInput placeholder="รหัสเขต/อำเภอ" matInput #input [(ngModel)]="form.districtCode"
              name="name" #name="ngModel" oninput="this.value=this.value.replace(/[^0-9]/g,'')">
          </mat-form-field>
        </div>
      </div>

      <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
        <div class="mat-popup-header flex flex-col">
          <h4>ชื่อเขต/อำเภอ (ภาษาไทย) <span style="color:red;">*</span></h4>
          <h4>District Name (Thai)</h4>
        </div>
        <div class="mat-popup flex flex-row justify-between items-center" style="width:74%">
          <div>:</div>
          <mat-form-field style="flex: 0 95%;" class="flex">
            <input required autocomplete="off" type="text" matInput placeholder="ชื่อเขต/อำเภอ (ภาษาไทย)" matInput #input [(ngModel)]="form.districtName"
              name="name" #name="ngModel">
          </mat-form-field>
        </div>
      </div>

      <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
        <div class="mat-popup-header flex flex-col">
          <h4>ชื่อเขต/อำเภอ (อังกฤษ) <span style="color:red;">*</span></h4>
          <h4>District Name (English)</h4>
        </div>
        <div class="mat-popup flex flex-row justify-between items-center" style="width:74%">
          <div>:</div>
          <mat-form-field style="flex: 0 95%;" class="flex">
            <input required autocomplete="off" type="text" matInput placeholder="ชื่อเขต/อำเภอ (อังกฤษ)" matInput #input [(ngModel)]="form.districtEname"
              name="name" #name="ngModel">
          </mat-form-field>
        </div>
      </div>

      <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
        <div class="mat-popup-header flex flex-col">
          <h4>ภูมิภาค <span style="color:red;">*</span></h4>
          <h4>Region</h4>
        </div>
        <div class="mat-popup flex flex-row justify-between items-center" style="width:74%">
          <div>:</div>
          <mat-form-field style="flex: 0 95%;" class="flex">
            <mat-select required name="type" (selectionChange)="onGeoChange($event)" [(ngModel)]="form.geoId">
              <mat-option *ngFor="let region of regionList" [value]="region.regionCode">
                {{region.regionName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
        <div class="mat-popup-header flex flex-col">
          <h4>จังหวัด <span style="color:red;">*</span></h4>
          <h4>Province</h4>
        </div>
        <div class="mat-popup flex flex-row justify-between items-center" style="width:74%">
          <div>:</div>
          <mat-form-field style="flex: 0 95%;" class="flex">
            <mat-select required name="type" [(ngModel)]="form.provinceId">
              <mat-option *ngFor="let province of provinceList" [value]="province.provinceId">
                {{province.provinceName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>


      <div class="flex-row flex justify-between gap-2.5">
        <div class="mat-popup-header flex flex-col">
          <h4>ใช้งาน</h4>
          <h4>Active</h4>
        </div>
        <div class="mat-popup flex flex-row justify-between items-center" style="width:74%">
          <div>:</div>
          <mat-slide-toggle [checked]="form.isActive === 'Y' ? true : false" (change)="onChange($event)"
            style="flex: 0 95%;" class="flex">
            Active</mat-slide-toggle>
        </div>
      </div>
      <div class="flex-row flex justify-center gap-2.5" style="gap: 10px;">
        <button mat-raised-button color="accent" (click)="save()">บันทึก</button>
        <button mat-raised-button [mat-dialog-close]>ยกเลิก</button>
      </div>
    </div>
  </mat-dialog-content>