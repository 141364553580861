<div style="display: flex;flex-flow: column;height: 100%;gap: 30px;">
    <div class="flex flex-col pl-10 pr-10 pt-10">
        <div class="menu">
            <div class="gap-2.5 flex flex-row">
                <button mat-raised-button color="accent" (click)="onRefresh()">
                  <div class="flex items-center">
                    <span class="icon-text"><mat-icon svgIcon="heroicons_outline:refresh"></mat-icon>รีเฟรชข้อมูล</span>
                  </div>
                </button>
            </div>
            <div style="display: flex;flex-flow: row;gap: 10px;align-items: stretch;">

            </div>
            <div style="display: flex;flex-flow: row;gap: 10px;flex: 1;justify-content: flex-end;">
                 <div class="mat-button" style="gap: 10px;flex: 0 40%;">
                    <mat-form-field style="flex: 1" class="my-form-field flex flex-row">
                        <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" matInput #input
                            [(ngModel)]="form.search" name="search" #search="ngModel" (keyup)="applyFilter($event)">
                        <button matSuffix aria-label="ค้นหา">
                            <mat-icon svgIcon="heroicons_outline:search"></mat-icon>
                        </button>
                    </mat-form-field>
                </div> 
              
                <button mat-raised-button color="accent" [matMenuTriggerFor]="menu">
                    <div style="display: flex;align-items: center;">
                        <span>เงื่อนไขการค้นหา</span>
                        <mat-icon svgIcon="heroicons_outline:chevron-down"></mat-icon>
                    </div>
                </button>
                <mat-menu #menu="matMenu" yPosition="below" xPosition="before" class="my-menu">
                    <div style="display: flex;flex-flow: column;gap: 15px;" class="pl-2 pr-2 "
                        (click)="stopPropagation($event)">
                        <div class="mat-button p-2" style="gap: 10px;">
                            <mat-form-field appearance="fill" style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>วันที่เริ่มต้น | Start Date
                                </mat-label>
                                <input matInput [matDatepicker]="startDate" type="datetime"
                                    [(ngModel)]="searchForm.startDate" [max]="myDate" name="startDateSearch"
                                    #startDateSearch="ngModel" (click)="startDate.open()" readonly
                                    class="cursor-pointer">
                                <mat-hint>MM/DD/YYYY</mat-hint>
                                <mat-datepicker-toggle matIconSuffix [for]="startDate"></mat-datepicker-toggle>
                                <mat-datepicker #startDate></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field appearance="fill" style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>วันที่สิ้นสุด | End Date</mat-label>
                                <input matInput [matDatepicker]="endDate" type="datetime"
                                    [(ngModel)]="searchForm.endDate" [max]="myDate" [min]="searchForm.startDate"
                                    name="endDateSearch" #endDateSearch="ngModel" (click)="endDate.open()" readonly
                                    class="cursor-pointer">
                                <mat-hint>MM/DD/YYYY</mat-hint>
                                <mat-datepicker-toggle matIconSuffix [for]="endDate"></mat-datepicker-toggle>
                                <mat-datepicker #endDate></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="mat-button  p-2" style="gap: 10px;">
                            <mat-form-field style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>เลขที่ใบคำขอ | Request No. </mat-label>
                                <input autocomplete="off" type="text" matInput placeholder="เลขที่ใบคำขอ" matInput
                                    #input [(ngModel)]="searchForm.requestNo" name="requestNo" #requestNo="ngModel">
                            </mat-form-field>
                            <mat-form-field appearance="fill" style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>สถานะ | Status</mat-label>
                                <mat-select [(ngModel)]="searchForm.status" name="status" #status="ngModel">
                                    <mat-option *ngFor="let statusOption of statusOptions" [value]="statusOption.dCode">
                                        {{statusOption.dTName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="flex justify-end pt-2">
                            <div>
                                <button mat-raised-button (click)="clearOption()"
                                    style="flex:0 50%; margin-right: 10px;">
                                    เคลียร์
                                </button>
                                <button mat-raised-button color="accent" (click)="saveSearch()" style="flex:0 50%;">
                                    ค้นหา
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-menu>
            </div>
        </div>
    </div>

    <div class="mat-elevation-z8 ">
        <table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">
            <ng-container matColumnDef="no">
                <th mat-header-cell *matHeaderCellDef class="text-center"> ลำดับ </th>
                <td mat-cell *matCellDef="let element;let i = index" class="text-center">
                    {{ (i+1)+(paginator?.pageIndex * 10) }} </td>
            </ng-container>
            <ng-container matColumnDef="rqNo">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> เลขที่คำขอ RQ NO. </th>
                <td mat-cell *matCellDef="let element" class="text-left"> {{element.rqNo}} </td>
            </ng-container>
            <ng-container matColumnDef="docType">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> ประเภทเอกสาร </th>
                <td mat-cell *matCellDef="let element" class="text-left"> {{element.docType}} </td>
            </ng-container>
            <ng-container matColumnDef="requestDate">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> วันที่ยื่นคำขอ</th>
                <td mat-cell *matCellDef="let element"> {{element.requestDate | date:'yyyy-MM-dd'}}
                </td>
            </ng-container>
            <ng-container matColumnDef="createDate">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> วันที่สร้าง </th>
                <td mat-cell *matCellDef="let element">
                    {{element.createDate | date:'yyyy-MM-dd'}} </td>
            </ng-container>
            <ng-container matColumnDef="requestBy">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> ผู้ยื่นคำขอ </th>
                <td mat-cell *matCellDef="let element"> {{element.requestBy}} </td>
            </ng-container>
            <ng-container matColumnDef="reason">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> เหตุผล </th>
                <td mat-cell *matCellDef="let element"> {{element.reason}} </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> สถานะแก้ไข </th>
                <td mat-cell *matCellDef="let element"> {{element.status}} </td>
            </ng-container>
            <!-- Action ไม่ขึ้น -->
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="text-left"> Action </th>
                <td mat-cell *matCellDef="let element;let i = index" class="text-left">
                    <div class="flex">
                        <div *ngIf="checkButton('BT185')">
                            <button mat-icon-button matTooltip="รายละเอียดคำขอ" matTooltipPosition="above"
                                (click)="showDetail(element)">
                                <mat-icon svgIcon="heroicons_outline:document-search"></mat-icon>
                            </button>
                        </div>
                        <div *ngIf="checkButton('BT186')">
                            <button mat-icon-button matTooltip="อนุมัติ"
                                *ngIf="element.action =='W'" (click)="approve(element)"
                                matTooltipPosition="above">
                                <mat-icon svgIcon="heroicons_outline:upload"></mat-icon>
                            </button>
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons
            aria-label="Select page of periodic elements" (page)="pageChanged($event)">
        </mat-paginator>
    </div>

</div>