import { LiveAnnouncer } from '@angular/cdk/a11y';
import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { MarkTypeHeaderPopupComponent } from './mark-type-header-popup/mark-type-header-popup.component';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { AppMenuService } from 'app/business/service/app/app-menu.service';
import { AppUserService } from 'app/business/service/app/app-user.service';
import { ConfirmDialogService } from '@bundle/component/confirm-dialog/confirm-dialog.service';
import { AuthService } from 'app/core/auth/auth.service';


@Component({
  selector: 'app-mark-type-header-inquiry',
  templateUrl: './mark-type-header-inquiry.component.html',
  styleUrls: ['./mark-type-header-inquiry.component.css']
})
export class MarkTypeHeaderInquiryComponent implements OnInit, AfterViewInit {
  BenefitTarget: any = [];
  form: any = {};
  BenefitTargetValue: string;
  fieldType: any[] = [];
  attachType: any[] = [];
  displayedColumns: string[] = ['attachTypeName', 'fieldTypeName', 'createDate', 'updateDate', 'action'];
  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);
  datePipe: DatePipe = new DatePipe('en-US');
  url: any;
  buttonList: any[] = [];
  userProfile:any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private appMasterService: AppMasterService,
    private router: Router,
    private appMenuService: AppMenuService,
    private appUserService: AppUserService,
    private confirmDialogService: ConfirmDialogService,
    private _authService :AuthService,
  ) { }

  async getRouter(): Promise<void> {
    
    this.url = this.router.url;

    const formData = {
      url: this.url,
      userGroupCode: this.userProfile.userGroupCode,
      userRoleCode: this.userProfile.userRoleCode,
      userCode: this.userProfile.userCode
    };
    this.buttonList = await this.appMenuService.getButtonPermissionPerPages(formData);
    
  }

  async ngOnInit() {
    
    this.userProfile = JSON.parse(
      this._authService.loginUser
    );


    await this.getRouter();
    this.BenefitTarget = [{ benefitTargetCode: 1, benefitTargetTName: '10' }, { benefitTargetCode: 2, benefitTargetTName: '20' }];
    this.form.isActive = 'A';
    this.loadField();
    this.loadData();
  }
  loadData() {
    const data = {
      "attachTypeId": this.form.attachTypeId,
      "fieldTypeId": this.form.fieldTypeId,
    };
    this.appMasterService.getMarkTypeHeaderList(data).then((res: any) => {
      if (res === "error") {
        return;
      } else {
        this.dataSource = new MatTableDataSource(res)
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        
      }
    });
  }

  loadField() {
    this.appMasterService.getListAttachType({}).then(res => {
      if (res === "error") {
        return;
      } else {
        this.attachType = res;
      }
    });

    this.appMasterService.getMasterFieldType({}).then(res => {
      if (res === "error") {
        return;
      } else {
        this.fieldType = res;
      }
    })
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  selectionPercent() {

  }
  editItem(e) {
    const data = {
      e: { ...e },
      buttonList: this.buttonList
    };
    const dialogRef = this.dialog.open(MarkTypeHeaderPopupComponent, {
      disableClose: false,
      width: '60vw',
      data: data,
      maxWidth: 'none'
    });

    dialogRef.afterClosed().subscribe(res => {
      this.loadData();
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
    
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onAttachTypeChange(e) {
    if (e.value === 0) {
      this.form.attachTypeId = null;
    } else {
      this.form.attachTypeId = e.value;
    }

    this.loadData();
  }

  onFieldChange(e) {
    if (e.value === 0) {
      this.form.fieldTypeId = null;
    } else {
      this.form.fieldTypeId = e.value;
    }

    this.loadData();
  }

  save() {

  }

  addAccount(data): void {
    const d = {
      e: null,
      buttonList: this.buttonList
    };
    const dialogRef = this.dialog.open(MarkTypeHeaderPopupComponent, {
      disableClose: false,
      width: '60vw',
      data: d,
      maxWidth: 'none'
    });

    dialogRef.afterClosed().subscribe(res => {
      this.loadData();
    });
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  delete(e) {
    this.confirmDialogService.open({ header: "", content: "คุณต้องการลบข้อมูลนี้หรือไม่?" }).then(res => {
      if (res) {
        const data = {
          "marktypeHId": e.marktypeHId,
          "attachTypeId": e.attachTypeId,
          "fieldTypeId": e.fieldTypeId,
          "fieldTypeName": e.fieldTypeName,
          "isActive": "N"
        };

        this.appMasterService.saveMarktypeHeader(data).then((res: any) => {
          if (res === "error") {
            return;
          } else {
            this.loadData();
          }
        });
      }
    });
  }

  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find(e => e.buttonCode == buttonCode);

    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }
}
