import { LiveAnnouncer } from '@angular/cdk/a11y';
import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { ConfigBankPopupComponent } from '../config-bank-inquiry/config-bank-popup/config-bank-popup.component';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'
import { DatePipe } from '@angular/common';
import { IReqGetVerifyHistoryListRequest } from 'app/business/interface/app/app-verify-interface';
import { AppVerifyService } from 'app/business/service/app/app-verify.service';
import { AppUserService } from 'app/business/service/app/app-user.service';
import { debounceTime, map, startWith, switchMap, takeUntil } from "rxjs/operators";
import { HttpLoadingService } from '@bundle/service/http-loading.service';
import { of, Subject } from "rxjs";
import { companySearch } from 'app/business/interface/app/app-company.interace'
import { AppCompanyService } from 'app/business/service/app/app-company.service'
import { FormControl, FormGroup, FormBuilder } from "@angular/forms";

@Component({
  selector: 'app-verify-history-list-inquiry',
  templateUrl: './verify-history-list-inquiry.component.html',
  styleUrls: ['./verify-history-list-inquiry.component.css']
})
export class VerifyHistoryListInquiryComponent implements OnInit, AfterViewInit {
  BenefitTarget: any = [];
  form: any = {};
  BenefitTargetValue: string;
  isLoading = false;
  count: number = 0;
  timer: any;
  apiRequest: IReqGetVerifyHistoryListRequest = {
    filterValue: null,
    startDate: null,
    endDate: null,
    queueBy: null,
    companyCode: null,
    companyName: null,
    approvalStatus: null,
    pagination: {
      Skip: 0,
      Take: 10
    }
  }

  userList: any[] = [];
  displayedColumns: string[] = ['requestNo', 'consignor', 'documentType', 'expressType', 'queueBy', 'approvalStatus', 'approvalDate'];
  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);
  datePipe: DatePipe = new DatePipe('en-US');

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  searchCompanyForm: FormGroup;
  valueSelect: boolean = false;
  isSearchLoading: boolean = false;
  filteredOptions$: any = [];
  statusOptions: any[] = [];
  constructor(
    private _formBuilder: FormBuilder,
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private alertMessageService: AlertMessageService,
    private appVerifyService: AppVerifyService,
    private appUserService: AppUserService,
    private httpLoadingService: HttpLoadingService,
    private appCompanyService: AppCompanyService,
    private appMasterService: AppMasterService,
  ) { }

  ngOnInit(): void {
    this.searchCompanyForm = this._formBuilder.group({
      companySearch: ['']
    });
    this.valueChange();

    this.statusOptions = [
      {
        name: "ทั้งหมด",
        value: ""
      },
      {
        name: "อนุมัติงาน",
        value: "อนุมัติงาน"
      },
      {
        name: "ไม่อนุมัติงาน",
        value: "ไม่อนุมัติงาน"
      },
      {
        name: "รับงาน",
        value: "รับงาน"
      },
      {
        name: "รับงานโอน",
        value: "รับงานโอน"
      },
      {
        name: "โอนงาน",
        value: "โอนงาน"
      },
    ]

    this.appUserService.getUserAll().then((res) => {
      if (res === 'error' || res?.length === 0) {
        this.userList = []
      } else {
        const targetGroup = ["1", "4", "5"];
        this.userList = res.filter(e => targetGroup.includes(e.groupCode));
        
        const checkAll = this.userList.find(e => e.staffName === "ทั้งหมด");
        if (checkAll === undefined) {
          this.userList.unshift({ userId: 0, staffName: "ทั้งหมด" });
        }
      }
    })

    this.dataSource.paginator = this.paginator
    this.apiRequest.pagination = {
      Skip:
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
      Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
    }
    
    this.loadData(this.apiRequest)
  }

  async loadData(request: IReqGetVerifyHistoryListRequest) {
    this.isLoading = true;
    
    if (this.paginator != undefined && this.paginator != null) {
      this.paginator.pageIndex = 0
    }
    
    request.pagination = {
      Skip: 0, // Start from page 1 (skip 0)
      Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
    };

    await this.appVerifyService.getVerifyHistoryList(request).then((res: any) => {
      if (res === "error") {
        return;
      } else {
        if (res !== null) {
          this.dataSource.data = res.verifyHistoryList;
          this.count = res.totalItem;
          this.isLoading = false;
        } else {
          this.dataSource.data = []
          this.count = 0
          this.isLoading = false;
        }
      }
    });

    this.dataSource.paginator.length = this.count
    this.isLoading = false;
  }

  ngAfterViewInit() {
    this.paginator.pageIndex = 0
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
  }

  onRefresh() {
    this.apiRequest.startDate = this.form.startDate;
    this.apiRequest.endDate = this.form.endDate;
    this.apiRequest.queueBy = this.form.queueBy;
    this.apiRequest.companyCode = this.form.companyCode;
    this.apiRequest.companyName = this.form.companyName;
    this.apiRequest.approvalStatus = this.form.status;
    this.dataSource.paginator = this.paginator
    this.loadData(this.apiRequest)
  }

  onSearch() {
    this.apiRequest.startDate = this.form.startDate;
    this.apiRequest.endDate = this.form.endDate;
    this.apiRequest.companyCode = this.form.companyCode;
    this.apiRequest.companyName = this.form.companyName;
    this.apiRequest.approvalStatus = this.form.status;

    if (this.form.queueBy !== 0) {
      this.apiRequest.queueBy = this.form.queueBy;
    } else {
      this.apiRequest.queueBy = null;
    }

    this.dataSource.paginator = this.paginator
    this.loadData(this.apiRequest)
  }

  clearFilter() {
    this.form.startDate = null;
    this.form.endDate = null;
    this.form.queueBy = null;
    this.form.companyCode = null;
    this.form.companyName = null;
    this.form.status = null;
    this.searchCompanyForm.get('companySearch').setValue('');

    this.onSearch();
  }
  selectionPercent() {

  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
    
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  applyFilter(event: string) {
    clearTimeout(this.timer); // Clear any existing timer
  
    this.timer = setTimeout(() => {
      // This code will be executed after a specified delay (e.g., 500 milliseconds)
      
      // Send the value to the backend or perform any other necessary actions
      this.apiRequest.filterValue = event;
      this.apiRequest.pagination = {
        Skip: 0,
        Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
      }
      this.dataSource.paginator = this.paginator
      this.loadData(this.apiRequest)

    }, 500); // Specify the desired delay in milliseconds
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  async _page(e) {
    this.isLoading = true;
    this.apiRequest.pagination = {
      Skip: (!e.pageIndex ? 0 : e.pageIndex) * (!e.pageSize ? 0 : e.pageSize),
      Take: !e.pageSize ? 10 : e.pageSize,
    }

    await this.appVerifyService.getVerifyHistoryList(this.apiRequest).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        if (res.response !== null) {
          this.dataSource.data = res.verifyHistoryList;
        } else {
          this.dataSource = new MatTableDataSource((res.response3 = []))
        }
        this.count = res.totalItem
      }
    })
    this.dataSource.paginator.length = this.count
    this.dataSource.paginator.pageIndex = e.pageIndex
    this.isLoading = false;
  }
  
  selectionChange(e): void {
    this.searchCompanyForm.get('companySearch').setValue(e.option.value.companyCode);
    this.companySelect(e.option.value);
  }

  companySelect(e) {
    this.form.companyCode = e.cpid;
    this.form.companyName = e.companyNameEn;

    this.valueSelect = true;
  }

  valueChange() {
    this.filteredOptions$ = this.searchCompanyForm.get('companySearch').valueChanges.pipe(
      debounceTime(500),
      switchMap(value => {
        if (!value) {
          this.valueSelect = false;
          this.form.companyCode = null;
          return of(null);
        }

        this.httpLoadingService.enabled = false;
        this.isSearchLoading = true;

        const key = value;
        if (key !== '') {
          if (this.valueSelect) {
            return of(null);
          }
          else {
            this.valueSelect = false;
            return this.appCompanyService.getListCompanyByFilter(key);
          }
        } else {
          this.valueSelect = false;
          this.isSearchLoading = false;
          this.form.companyCode = null;
          return;
        }

      }),
      map((res: any[]) => {
        this.isSearchLoading = false;
        this.httpLoadingService.enabled = true;

        

        return res?.map(item => {
          const { companyTaxId, companyNameEn } = (item as companySearch);
          return {
            ...item,
            // tslint:disable-next-line: max-line-length
            display: `${companyTaxId} ${companyNameEn}`
          };
        });
      })
    );
  }
}
