import { SelectionModel } from '@angular/cdk/collections'
import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  ViewEncapsulation,
} from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { MatSort, Sort } from '@angular/material/sort'
import { LiveAnnouncer } from '@angular/cdk/a11y'
import { MatDialog } from '@angular/material/dialog'
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component'
import { MatMenuTrigger } from '@angular/material/menu'
import { AppFactoryManagementService } from 'app/business/service/app/app-factorymanagement.service'
import { FactoryHistoryModalComponent } from './factory-history-modal/factory-history-modal.component'
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'
import { ConfirmDialogService } from '@bundle/component/confirm-dialog/confirm-dialog.service'
import { Router } from '@angular/router'
import { AppMenuService } from 'app/business/service/app/app-menu.service'
import { AppUserService } from 'app/business/service/app/app-user.service'
import { IReqGetFactoryListRequest } from 'app/business/interface/app/app-verify-interface'
import _ from 'underscore';
import { BlacklistSearchFactoryModalComponent } from '../list-blacklist-inquiry/blacklist-search-factory-modal/blacklist-search-factory-modal.component'
import { debounceTime, map, startWith, switchMap, takeUntil } from "rxjs/operators";
import { HttpLoadingService } from '@bundle/service/http-loading.service';
import { of, Subject } from "rxjs";
import { companySearch } from 'app/business/interface/app/app-company.interace'
import { AppCompanyService } from 'app/business/service/app/app-company.service'
import { FormControl, FormGroup, FormBuilder } from "@angular/forms";
import { AuthService } from 'app/core/auth/auth.service'

@Component({
  selector: 'app-list-factory-history-inquiry',
  templateUrl: './list-factory-history-inquiry.component.html',
  styleUrls: ['./list-factory-history-inquiry.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ListFactoryHistoryInquiryComponent
  implements OnInit, AfterViewInit {
  form: any = {}
  displayedColumns: string[] = [
    "cid",
    "changeUpComDate",
    "companyNameTH",
    "companyNameEN",
    "addressStreet",
    "province",
    "phone",
    "blacklist",
    "grade",
    "action",
  ];
  dataSource = new MatTableDataSource<any>()
  selection = new SelectionModel<any>(true, [])
  data: any = {}
  companyDetail: any = {}
  count: any = 0
  sortDirection: 'name'
  sortProperty: 'desc'
  isLoading: boolean = false;
  timer: any;
  url: any;
  buttonList: any[] = [];
  apiReq: IReqGetFactoryListRequest = {
    filterValue: null,
    gradeId: null,
    companyCode: null,
    companyName: null,
    pagination: {
      Skip: 0,
      Take: 10
    }
  }

  status: any = 1 ;

  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger

  searchCompanyForm: FormGroup;
  valueSelect: boolean = false;
  isSearchLoading: boolean = false;
  filteredOptions$: any = [];
  userToken:any;
  gradeList: any[] = [
    {
        gradeId: 4,
        gradeName: "ดีเยี่ยม"
    },
    {
        gradeId: 3,
        gradeName: "ดี"
    },
    {
        gradeId: 2,
        gradeName: "พอใช้"
    },
    {
        gradeId: 1,
        gradeName: "ไม่ผ่าน"
    },
];

  constructor(
    private _formBuilder: FormBuilder,
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private appFactoryManagement: AppFactoryManagementService,
    private alertService: AlertMessageService,
    private confirmService: ConfirmDialogService,
    private router: Router,
    private appMenuService: AppMenuService,
    private appUserService: AppUserService,
    private httpLoadingService: HttpLoadingService,
    private appCompanyService: AppCompanyService,
    private _authService:AuthService,
  ) { }

  async getRouter(): Promise<void> {
    
    this.url = this.router.url;

    // const profile = JSON.parse(this.appUserService.getSessionUserProfile());
    const formData = {
      url: this.url,
      userGroupCode: this.userToken.userGroupCode,
      userRoleCode: this.userToken.userRoleCode,
      userCode: this.userToken.userCode
    };
    this.buttonList = await this.appMenuService.getButtonPermissionPerPages(formData);
    
  }

  async ngOnInit() {
    this.userToken = JSON.parse(
      this._authService.loginUser
    );

    this.searchCompanyForm = this._formBuilder.group({
      companySearch: ['']
    });
    this.valueChange();

    await this.getRouter();
    this.loadData()
  }

  ngAfterViewInit() {
    this.paginator.pageIndex = 0
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`)
    } else {
      this._liveAnnouncer.announce('Sorting cleared')
    }
  }

  applyFilter(event) {
    clearTimeout(this.timer); // Clear any existing timer
  
    this.timer = setTimeout(() => {
      // This code will be executed after a specified delay (e.g., 500 milliseconds)
      
      // Send the value to the backend or perform any other necessary actions
      this.data.filterValue = event;
      this.dataSource.paginator = this.paginator
      this.apiRequest(this.data)

    }, 500); // Specify the desired delay in milliseconds
  }

  stopPropagation(event) {
    event.stopPropagation()
  }

  // menuOpen() {}
  menuClosed() {
    
  }

  async loadData() {
    if (this.paginator != undefined && this.paginator != null) {
      this.paginator.pageIndex = 0
    }
    this.dataSource.paginator = this.paginator
    this.data.pagination = {
      Skip: 0,
      Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
    }
    this.data.filterValue = this.status;

    this.apiRequest(this.data);
  }

  async apiRequest(request) {
    await this.appFactoryManagement.GetListFactory(request).then((res) => {
      if (res === 'error') {
        return
      } else {
        this.dataSource.data = res.companyDataList
        this.count = res.totalItem
      }
    })
    
    this.dataSource.paginator.length = this.count // กำหนดค่า total
  }

  onRefresh() {
    this.apiReq.companyCode = this.form.companyCode;
    this.apiReq.companyName = this.form.companyName;

    if (this.form.gradeId === -1) {
      this.apiReq.gradeId = null;
    } else {
      this.apiReq.gradeId = this.form.gradeId
    }

    this.dataSource.paginator = this.paginator
    this.apiReq.pagination = {
      Skip:
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
      Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
    }

    this.apiReq.filterValue = this.status;
    this.apiRequest(this.apiReq);
  }

  onSearch() {
    this.apiReq.companyCode = this.form.companyCode;
    this.apiReq.companyName = this.form.companyName;

    if (this.form.gradeId === -1) {
      this.apiReq.gradeId = null;
    } else {
      this.apiReq.gradeId = this.form.gradeId
    }

    this.dataSource.paginator = this.paginator
    this.apiReq.pagination = {
      Skip:
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
      Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
    }

    this.apiReq.filterValue = this.status;
    this.apiRequest(this.apiReq);
  }

  // Function ตอนกดเปลี่ยน page ใน mat-table
  async pageChanged(e) {
    this.isLoading = true;
    this.apiReq.pagination = {
      Skip: (!e.pageIndex ? 0 : e.pageIndex) * (!e.pageSize ? 0 : e.pageSize),
      Take: !e.pageSize ? 10 : e.pageSize,
    }

    await this.appFactoryManagement.GetListFactory(this.apiReq).then((res) => {
      if (res === 'error') {
        return
      } else {
        
        this.dataSource.data = res.companyDataList
        this.count = res.totalItem
      }
    })

    this.dataSource.paginator.length = this.count // กำหนดค่า total
    this.dataSource.paginator.pageIndex = e.pageIndex // กำหนด pageIndex

    this.isLoading = false;
  }

  async getDetail(e) {
    
    const dialogRef = this.dialog.open(FactoryHistoryModalComponent, {
      disableClose: false,
      width: '90vw',
      data: e.companyFactoryData,
      maxWidth: 'none',
    })

    dialogRef.afterClosed().subscribe((res) => {
      this.onRefresh();
    });
    //   if (res) {
    //     const add = {
    //       uid: JSON.parse(localStorage.getItem('userProfile')).uId,
    //       id: res.data.id,
    //       companyId: res.data.companyId,
    //       grade: res.data.grade,
    //       gradeReason: res.data.gradeReason,
    //       lockStatus: res.data.lockStatus,
    //       lockReason: res.data.lockReason,
    //     }
    //     this.confirmService
    //       .open({
    //         header: 'ยืนยันการทำรายการ',
    //         content: 'ยืนยันการบันทึกข้อมูลใช่หรือไม่?',
    //       })
    //       .then((res) => {
    //         if (res == true) {
    //           this.appFactoryManagement
    //             .UpdateBlackList(add)
    //             .then((res: any) => {
    //               if (res == 'error') {
    //                 this.alertService
    //                   .open({
    //                     title: 'ผิดพลาด',
    //                     message: 'บันทึกข้อมูลไม่สำเร็จ',
    //                   })
    //                   .then((res) => {
    //                     if (res == true) {
    //                       return
    //                     }
    //                   })
    //               } else {
    //                 this.alertService
    //                   .open({
    //                     title: 'ทำรายการสำเร็จ',
    //                     message: 'บันทึกข้อมูลสำเร็จ',
    //                   })
    //                   .then((res) => {
    //                     if (res == true) {
    //                       this.loadData()
    //                     }
    //                   })
    //               }
    //             })
    //         }
    //       })
    //   } else {
    //     return
    //   }
    // })
  }

  popupFactory(){
    const dialogRef = this.dialog.open(BlacklistSearchFactoryModalComponent, {
      disableClose: true,
      width: '80vw',
      data: 'factory',
      maxWidth: 'none',
    })
    dialogRef.afterClosed().subscribe((res) => {
      this.loadData();
    })
}
  print() {}

  clearOption() {
    this.form = {};
    this.searchCompanyForm.get('companySearch').setValue('');
    this.onSearch();
  }

  statusChange(e){
    this.status = e;
    this.data.filterValue = e;
    this.apiRequest(this.data);
  }
  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find(e => e.buttonCode == buttonCode);

    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }
  
  selectionChange(e): void {
    this.searchCompanyForm.get('companySearch').setValue(e.option.value.companyCode);
    this.companySelect(e.option.value);
  }

  companySelect(e) {
    this.form.companyCode = e.companyCode;
    this.form.companyName = e.companyNameTh;
    this.valueSelect = true;
  }

  valueChange() {
    this.filteredOptions$ = this.searchCompanyForm.get('companySearch').valueChanges.pipe(
      debounceTime(500),
      switchMap(value => {
        if (!value) {
          this.valueSelect = false;
          this.form.companyCode = null;
          return of(null);
        }

        this.httpLoadingService.enabled = false;
        this.isSearchLoading = true;

        const key = value;
        if (key !== '') {
          if (this.valueSelect) {
            return of(null);
          }
          else {
            this.valueSelect = false;
            return this.appCompanyService.getListCompanyByFilter(key);
          }
        } else {
          this.valueSelect = false;
          this.isSearchLoading = false;
          return;
        }

      }),
      map((res: any[]) => {
        this.isLoading = false;
        this.httpLoadingService.enabled = true;

        

        return res?.map(item => {
          const { companyTaxId, companyNameTh } = (item as companySearch);
          return {
            ...item,
            // tslint:disable-next-line: max-line-length
            display: `${companyTaxId} ${companyNameTh}`
          };
        });
      })
    );
  }
}
