import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, CanMatch, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import jwtDecode from 'jwt-decode';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivateChild {
    /**
     * Constructor
     */
    constructor(
        private _authService: AuthService,
        private _router: Router
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can match
     *
     * @param route
     * @param segments
     */

    canActivateChild(route: ActivatedRouteSnapshot): boolean {
        // debugger;
        const { sessionId } = route.queryParams;
        console.log("canActivateChild(route:>>>",sessionId);
        if (sessionId)
        {
            this._authService.setUniqueId(sessionId);
            return true;
        } else {
            if(this._authService.token!=""){
                return true;
            } else {
                this._router.navigate(["sign-in"]);
                return false;    
            }
        }
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Check the authenticated status
     *
     * @param segments
     * @private
     */
    private _check(segments: UrlSegment[]): Observable<boolean | UrlTree> {
        // Check the authentication status
        return this._authService.check().pipe(
            switchMap((authenticated) => {
                // If the user is not authenticated...
                //debugger;
                if (!authenticated) {
                    // Redirect to the sign-in page with a redirectUrl param
                    const redirectURL = `/${segments.join('/')}`;
                    const urlTree = this._router.parseUrl(`sign-in`);

                    return of(urlTree);
                }

                // Allow the access
                return of(true);
            })
        );
    }

    decodeJwt(token: string) {
        try {
            return jwtDecode(token);
        } catch (error) {
            return null;
        }
    }
}
