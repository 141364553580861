<div style="display: flex;flex-flow: column;height: 100%;gap: 30px;">
    <div style="margin-top: 30px;" class="flex flex-col pl-10 pr-10">
        <div class="menu">
            <div class="gap-2.5 flex flex-row">
                <button mat-raised-button color="accent" (click)="onRefresh()">
                    <div class="flex items-center">
                        <span class="icon-text"><mat-icon
                                svgIcon="heroicons_outline:refresh"></mat-icon>รีเฟรชข้อมูล</span>
                    </div>
                </button>
            </div>
            <div style="display: flex;flex-flow: row;gap: 10px;flex: 1;justify-content: flex-end;">
                <div class="mat-button" style="gap: 10px;flex: 0 40%;">

                    <mat-form-field style="display: flex;flex-flow: row;flex: 1" class="my-form-field">
                        <input autocomplete="off" type="text" matInput
                            placeholder="Request No. / เลขที่ THT / Invoice / เลขที่ผู้เสียภาษี (13 หลัก)" matInput
                            #input [(ngModel)]="searchString" name="searchText" #searchText="ngModel"
                            (keyup)="applyFilter($event.target.value)">
                        <button matSuffix mat-icon-button aria-label="ค้นหา">
                            <mat-icon>search</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
                <button mat-raised-button color="accent" [matMenuTriggerFor]="menu">
                    <div style="display: flex;align-items: center;">
                        <span>เงื่อนไขการค้นหา</span>
                        <mat-icon>expand_more</mat-icon>
                    </div>
                </button>
                <mat-menu #menu="matMenu" yPosition="below" xPosition="before" class="my-menu">
                    <div style="display: flex;flex-flow: column;" class="pl-2 pr-2" (click)="stopPropagation($event)">
                        <div class="pt-2 pl-2 pr-2 flex flex-row">
                            <mat-label style="font-size: 0.85em !important">วันที่คำขอ | Request Date</mat-label>
                        </div>
                        <div class="mat-button p-2" style="gap: 10px;">
                            <mat-form-field appearance="fill" style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>วันที่เริ่มต้น | Start Date</mat-label>
                                <input matInput [matDatepicker]="startDate" type="datetime"
                                    [(ngModel)]="searchForm.startDate" (click)="startDate.open()" readonly
                                    name="startDateSearch" #startDateSearch="ngModel" class="cursor-pointer">
                                <mat-hint>MM/DD/YYYY</mat-hint>
                                <mat-datepicker-toggle matIconSuffix [for]="startDate"></mat-datepicker-toggle>
                                <mat-datepicker #startDate></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field appearance="fill" style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>วันที่สิ้นสุด | End Date </mat-label>
                                <input matInput [matDatepicker]="endDate" type="datetime"
                                    [(ngModel)]="searchForm.endDate" (click)="endDate.open()" readonly
                                    name="endDateSearch" #endDateSearch="ngModel" class="cursor-pointer">
                                <mat-hint>MM/DD/YYYY</mat-hint>
                                <mat-datepicker-toggle matIconSuffix [for]="endDate"></mat-datepicker-toggle>
                                <mat-datepicker #endDate></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="pt-2 pl-2 pr-2 flex flex-row">
                            <mat-label style="font-size: 0.85em !important">วันที่อนุมัติ | Approve Date</mat-label>
                        </div>
                        <div class="mat-button p-2" style="gap: 10px;">
                            <mat-form-field appearance="fill" style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>วันที่เริ่มต้น | Start Date</mat-label>
                                <input matInput [matDatepicker]="approveStartDate" type="datetime"
                                    [(ngModel)]="searchForm.approveStartDate" (click)="approveStartDate.open()" readonly
                                    name="approveStartDateSearch" #approveStartDateSearch="ngModel"
                                    class="cursor-pointer">
                                <mat-hint>MM/DD/YYYY</mat-hint>
                                <mat-datepicker-toggle matIconSuffix [for]="approveStartDate"></mat-datepicker-toggle>
                                <mat-datepicker #approveStartDate></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field appearance="fill" style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>วันที่สิ้นสุด | End Date </mat-label>
                                <input matInput [matDatepicker]="approveEndDate" type="datetime"
                                    [(ngModel)]="searchForm.approveEndDate" (click)="approveEndDate.open()" readonly
                                    name="approveEndDateSearch" #approveEndDateSearch="ngModel" class="cursor-pointer">
                                <mat-hint>MM/DD/YYYY</mat-hint>
                                <mat-datepicker-toggle matIconSuffix [for]="approveEndDate"></mat-datepicker-toggle>
                                <mat-datepicker #approveEndDate></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="mat-button p-2" style="gap: 10px;">
                            <mat-form-field style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>เลขที่ใบคำขอ | Request No.</mat-label>
                                <input autocomplete="off" type="text" matInput placeholder="เลขที่ใบคำขอ" matInput
                                    #input [(ngModel)]="searchForm.requestNo" name="requestNo" #requestNo="ngModel">
                            </mat-form-field>
                            <mat-form-field style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>เลขที่ THT | Certificate No.</mat-label>
                                <input autocomplete="off" type="text" matInput placeholder="เลขที่ THT" matInput #input
                                    [(ngModel)]="searchForm.certificateNo" name="certificateNo"
                                    #certificateNo="ngModel">
                            </mat-form-field>
                        </div>
                        <div class="mat-button p-2" style="gap: 10px;">
                            <!-- <mat-form-field style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>รหัสบริษัท | Company Code</mat-label>
                                <mat-select [(ngModel)]="searchForm.companyCode" name="companyCode" #status="ngModel">
                                    <mat-option [value]="0">
                                        แสดงทั้งหมด
                                    </mat-option>
                                    <mat-option *ngFor="let companyCode of companyCodeList" [value]="companyCode.cid">
                                        {{companyCode.cid}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field> -->
                            <form name="searchCompanyForm" [formGroup]="searchCompanyForm"
                                style="display: flex;flex-flow: row;flex: 1">
                                <mat-form-field style="display: flex;flex-flow: row;flex: 1">
                                    <mat-label>รหัสบริษัท | Company Code</mat-label>
                                    <input matInput type="text" formControlName="companySearch" [matAutocomplete]="auto"
                                        name="companyCode">
                                    <mat-autocomplete #auto="matAutocomplete" required
                                        (optionSelected)="selectionChange($event)" [panelWidth]="'auto'">
                                        <mat-option *ngIf="isLoading">
                                            <mat-spinner [diameter]="20"></mat-spinner>
                                        </mat-option>
                                        <mat-option *ngFor="let option of filteredOptions$  | async" [value]="option">
                                            {{option.display}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </form>
                            <mat-form-field appearance="fill" style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>สถานะ | Status</mat-label>
                                <mat-select [(ngModel)]="searchForm.status" name="status" #status="ngModel">
                                    <mat-option *ngFor="let statusOption of statusOptions" [value]="statusOption.code">
                                        {{statusOption.text}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="mat-button p-2" style="gap: 10px;">
                            <mat-form-field style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>ชื่อบริษัท | Company Name</mat-label>
                                <input autocomplete="off" type="text" matInput placeholder="ชื่อบริษัท" matInput #input
                                    [(ngModel)]="searchForm.companyName" name="companyName" #companyName="ngModel">
                            </mat-form-field>
                        </div>
                        <div class="mat-button p-2" style="gap: 10px;">
                            <mat-form-field style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>เลขที่ใบกำกับสินค้า/ เลขที่เอกสาร | Invoice No./ Reference No. </mat-label>
                                <input autocomplete="off" type="text" matInput placeholder="เลขที่ใบกำกับ/ เลขที่เอกสาร"
                                    matInput #input [(ngModel)]="searchForm.invoiceReference" name="invoiceReference"
                                    #invoiceReference="ngModel">
                            </mat-form-field>
                        </div>

                        <div class="flex justify-end pt-2">
                            <div>
                                <button mat-raised-button (click)="clearOption()"
                                    style="flex:0 50%; margin-right: 10px;">
                                    เคลียร์
                                </button>
                                <button mat-raised-button color="accent" (click)="saveSearch()" style="flex:0 50%;">
                                    ค้นหา
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-menu>
            </div>
        </div>
    </div>

    <div class="mat-elevation-z8 " style="overflow-x:auto;">
        <table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)"
            style="width: 1400px;">
            <ng-container matColumnDef="requestNo">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left;">Request No. </th>
                <td mat-cell *matCellDef="let element"> {{element.requestNo}} </td>
            </ng-container>

            <ng-container matColumnDef="companyName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="white-space: normal;">ชื่อบริษัท</th>
                <td mat-cell style="white-space: normal;" *matCellDef="let element"> {{element.companyName}} </td>
            </ng-container>

            <ng-container matColumnDef="docType">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left;">ประเภท</th>
                <td mat-cell *matCellDef="let element"> {{element.docType}} </td>
            </ng-container>

            <ng-container matColumnDef="expressType">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left;">เร่งด่วน</th>
                <td mat-cell *matCellDef="let element"> {{element.docPriority}} </td>
            </ng-container>

            <ng-container matColumnDef="invoiceReference">

                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left;">Invoice /<br>Reference
                    No.
                </th>
                <td mat-cell *matCellDef="let element" style="white-space: normal;">
                    {{element.invoiceNo !== undefined || element.invoiceNo !== '' ? element.invoiceNo : '-'}}{{
                    element.refNo !== undefined || element.refNo != '' ? ''+element.refNo:'' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="approveBy">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left;">เจ้าหน้าที่ตรวจสอบ
                </th>
                <td mat-cell *matCellDef="let element"> {{element.approveByName}} </td>
            </ng-container>
            <ng-container matColumnDef="note">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left;">บันทึกช่วยจำ</th>
                <td mat-cell *matCellDef="let element"> {{element.note}} </td>
            </ng-container>
            <ng-container matColumnDef="isRqStatus">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left;">สถานะคำขอ</th>
                <td mat-cell *matCellDef="let element"> {{element.isRqStatus}} </td>
            </ng-container>
            <ng-container matColumnDef="requestDateTime">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left;">วันที่คำขอ</th>
                <td mat-cell *matCellDef="let element"> {{element.requestDatetime | date:
                    'yyyy-MM-dd'}}<br>{{element.requestDatetime | date: 'HH:mm:ss'}} </td>
            </ng-container>
            <ng-container matColumnDef="approveDateTime">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left;">วันที่อนุมัติ</th>
                <td mat-cell *matCellDef="let element"> {{element.approveDatetime | date:
                    'yyyy-MM-dd'}}<br>{{element.approveDatetime | date: 'HH:mm:ss'}} </td>
            </ng-container>
            <ng-container matColumnDef="thtNo">

                <th mat-header-cell mat-sort-header *matHeaderCellDef style="white-space: normal; width:100px;">เลขที่
                    THT</th>
                <td style="white-space: normal; width:100px;" mat-cell *matCellDef="let element">
                    {{element.thtNo}}
                </td>
            </ng-container>
            <ng-container matColumnDef="receiptNo">
                <th mat-header-cell mat-sort-header *matHeaderCellDef
                    style="text-align: left;white-space: normal; width:100px;">เลขที่ใบเสร็จ</th>
                <td mat-cell *matCellDef="let element"> {{element.receiptNo}} </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style="text-align: left;"> Action </th>
                <td mat-cell *matCellDef="let element;let i = index" style="text-align: left;">
                    <div style="display: flex">
                        <div *ngIf="checkButton('BT028')">
                            <!-- <button mat-icon-button matTooltip="รายละเอียด" matTooltipPosition="above"
                                (click)="showDetail(element)" *ngIf="element.docType=='CO' || element.docType=='CO/Pack'"
                                [queryParams]="{
                                    id: element.packId,
                                    disabled: true,
                                    search:true
                                    }" routerLink="/page/request/request-co/create-co"
                                    target="_blank">
                                <mat-icon svgIcon="heroicons_outline:document-search"></mat-icon>
                            </button>
                            <button mat-icon-button matTooltip="รายละเอียด" matTooltipPosition="above"
                                (click)="showDetail(element)" *ngIf="element.docType=='CF'"
                                routerLink="/page/request/request-cf/create-cf" [queryParams]="{
                                    id: element.packId,
                                    disabled: true,
                                    search:true
                                    }" target="_blank">
                                <mat-icon svgIcon="heroicons_outline:document-search"></mat-icon>
                            </button> -->
                            <button mat-icon-button matTooltip="รายละเอียด" matTooltipPosition="above"
                                (click)="showDocDetail(element)">
                                <mat-icon svgIcon="heroicons_outline:document-search"></mat-icon>
                            </button>
                        </div>
                        <div>
                            <button mat-icon-button matTooltip="พิมพ์เอกสารตัวอย่าง" (click)="printDraft(element)"
                                matTooltipPosition="above"
                                *ngIf="element.docType=='CO' || element.docType=='CO/Pack'">
                                <mat-icon svgIcon="heroicons_outline:printer"></mat-icon>
                            </button>
                        </div>    
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons
            aria-label="Select page of periodic elements" (page)="pageChanged($event)" [length]="count">
        </mat-paginator>
    </div>

</div>