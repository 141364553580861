import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import {
  IAppDeleteRelationship,
  IAppGetListRelationship,
  IAppGetShipperRequest,
  IsaveRelationship,
} from 'app/business/interface/app/app-config-relationship'
import { ApiConfigRelationshipService } from '../api/api-config-relationship.service'

@Injectable({
  providedIn: 'root',
})
export class AppConfigRelationshipService {
  constructor(
    private apiConfigRelationshipService: ApiConfigRelationshipService,
  ) {}

  getListRelationship(request: IAppGetListRelationship): any {
    return new Promise((resolve, reject) => {
      this.apiConfigRelationshipService
        .getListRelationship(request)
        .subscribe((res) => {
          resolve(res)
        })
    })
  }

  getShipperRelationship(request: IAppGetShipperRequest): any {
    return new Promise((resolve, reject) => {
      this.apiConfigRelationshipService
        .getShipperRelationship(request)
        .subscribe((res) => {
          resolve(res)
        })
    })
  }

  saveRelationship(request: IsaveRelationship): any {
    return new Promise((resolve, reject) => {
      this.apiConfigRelationshipService.saveRelationship(request).subscribe((res) => {
          resolve(res)
        })
    })
  }

  deleteRelationship(request: IAppDeleteRelationship): any {
    return new Promise((resolve, reject) => {
      this.apiConfigRelationshipService
        .deleeteRelationship(request)
        .subscribe((res) => {
          resolve(res)
        })
    })
  }
}
