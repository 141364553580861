<div style="display: flex;flex-flow: column;height: 100%;gap: 30px;">
    <div style="display: flex;flex-flow: row;gap: 30px;height: 5vh;justify-content: space-between;align-items: center;color: white;
    background-color: #003f80;font-size: 20px;" class="pl-20 pr-20">
        <div style="display: flex;flex-flow: row;gap: 30px">
            <h2 style="cursor:pointer;text-align: center;">Request</h2>
            <h2 style="cursor:pointer;text-align: center;">Payment</h2>
            <h2 style="cursor:pointer;text-align: center;">Print</h2>
            <h2 style="cursor:pointer;text-align: center;">Configuration</h2>
        </div>


        <div>
            <h2 style="cursor:pointer;text-align: center;">Template Management</h2>
        </div>
    </div>
    <div style="display: flex;flex-flow: column;" class="pl-20 pr-20">
        <div class="menu">
            <div style="display: flex;flex-flow: row;gap: 10px">
                <mat-card class="mat-button" color="accent">
                    <mat-card-content class="button" style="cursor:pointer;text-align: center;" matTooltip="ทั้งหมด"
                        matTooltipPosition="above">
                        Create CO Template
                    </mat-card-content>
                </mat-card>
                <mat-card class="mat-button" color="accent">
                    <mat-card-content class="button" style="cursor:pointer;text-align: center;" matTooltip="ใช้งาน"
                        matTooltipPosition="above">
                        Delete
                    </mat-card-content>
                </mat-card>
            </div>
            <div style="display: flex;flex-flow: row;gap: 10px;flex: 1;justify-content: flex-end;">
                <div class="mat-button" style="gap: 10px;flex: 0 40%;">
                    <h4>record</h4>
                    <mat-form-field style="display: flex;flex-flow: row;flex: 1" class="my-form-field">
                        <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" matInput #input
                            [(ngModel)]="form.search" name="search" #search="ngModel" (keyup)="applyFilter($event)">
                        <button matSuffix mat-icon-button aria-label="ค้นหา">
                            <mat-icon>search</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
                <mat-card class="mat-button" [matMenuTriggerFor]="menu" color="accent">
                    <mat-card-content class="button" style="cursor:pointer;text-align: center;" matTooltip="ทั้งหมด"
                        matTooltipPosition="above">
                        เงื่อนไขการค้นหา
                    </mat-card-content>
                </mat-card>
                <mat-menu #menu="matMenu" xPosition="before" class="my-menu">
                    <div style="display: flex;flex-flow: column;gap: 15px;" class="pl-2 pr-2">
                        <div class="mat-button" style="gap: 10px;">
                            <mat-form-field style="display: flex;flex-flow: row;flex: 1" class="my-form-field">
                                <mat-label>วัน/เวลาที่สร้าง</mat-label>
                                <input autocomplete="off" type="text" matInput placeholder="เลขที่บัญชี" matInput #input
                                    [(ngModel)]="form.accountNo" name="accountNo" #accountNo="ngModel">
                            </mat-form-field>
                            <mat-form-field style="display: flex;flex-flow: row;flex: 1" class="my-form-field">
                                <mat-label>ชื่อต้นแบบ</mat-label>
                                <input autocomplete="off" type="text" matInput placeholder="ชื่อบัญชี" matInput #input
                                    [(ngModel)]="form.accountName" name="accountName" #accountName="ngModel">
                            </mat-form-field>
                        </div>
                        <div class="mat-button" style="gap: 10px;justify-content: flex-end;">
                            <button mat-raised-button color="accent" (click)="save()"
                                style="flex: 0 50%;">ค้นหา</button>
                        </div>
                    </div>

                </mat-menu>
            </div>

        </div>
    </div>
    <div class="mat-elevation-z8">
        <table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">
            <ng-container matColumnDef="select">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                        Select
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row" style="text-align: center;">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                </td>
            </ng-container>
            <!-- Position Column -->
            <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number">
                    วัน/เวลาที่สร้าง
                </th>
                <td mat-cell *matCellDef="let element"> {{element.position}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> วัน/เวลาที่แก้ไขล่าสุด </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="weight">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>ชื่อต้นแบบ </th>
                <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
            </ng-container>

            <ng-container matColumnDef="tax">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> ผู้ส่งออกสินค้า </th>
                <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
            </ng-container>


            <ng-container matColumnDef="enabled">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> ปลายทางที่สินค้าถูกยกลง </th>
                <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Action </th>
                <td mat-cell *matCellDef="let element;let i = index">
                    <button mat-icon-button matTooltip="รายละเอียด">
                        <mat-icon svgIcon="heroicons_outline:pencil-alt"></mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="ลบ" (click)="editItem(element)" matTooltipPosition="above">
                        <mat-icon svgIcon="heroicons_outline:trash"></mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons
            aria-label="Select page of periodic elements">
        </mat-paginator>
    </div>
</div>