<div class="">
    <div  *ngIf="data.button != 'ok'" style="display: flex;flex-flow: row;gap: 30px;height: 5vh;justify-content: space-between;align-items: center;color: white;
    background-color: #003f80;font-size: 20px;border-radius: 6px;" class=" pl-5 pr-5">
        <h2 style="text-align: center;"> กรุณายืนยัน</h2>
        <mat-icon style="cursor:pointer; color: white;" mat-dialog-close>cancel</mat-icon>
    </div>
    <h3 mat-dialog-title align="center" style="height: 100px; margin-bottom: 0px; padding-top: 9px;" >{{data.header}}</h3>
    <div mat-dialog-actions align="center" *ngIf="data.button == 'open'">
        <button mat-stroked-button class="default-button" [mat-dialog-close]="false" [mat-dialog-close]>
            ยกเลิก
        </button>
        <button mat-raised-button class="primary" style="background-color: #003f80 !important;
  color: white!important; " [mat-dialog-close]="true" cdkFocusInitial>
            ยืนยัน
        </button>
    </div>
    <div mat-dialog-actions align="center" *ngIf="data.button == 'close'">
        <button mat-raised-button class="primary" style="background-color: #003f80 !important;
  color: white!important; " [mat-dialog-close]="true" cdkFocusInitial>
            ปิด
        </button>
    </div>
    <div mat-dialog-actions align="center" *ngIf="data.button == 'ok'">
        <button mat-raised-button class="primary" style="background-color: #003f80 !important;
  color: white!important; " [mat-dialog-close]="true" cdkFocusInitial>
            ตกลง
        </button>
    </div>
    <div mat-dialog-actions align="center" *ngIf="data.button == 'cancel'">
        <button mat-stroked-button class="default-button" [mat-dialog-close]="false" [mat-dialog-close]>
            ปิด
        </button>
        <button mat-raised-button class="primary" style="background-color: #003f80 !important;
  color: white!important; " [mat-dialog-close]="true" cdkFocusInitial>
            ยืนยัน
        </button>
    </div>
</div>