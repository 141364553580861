import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ActivatedRoute, Router } from '@angular/router'
import {
  IgetListLoadTemplateRequest,
} from 'app/business/interface/app/app-co.interface'
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'
import { AppCOService } from 'app/business/service/app/app-co.service'
import { AppMasterService } from 'app/business/service/app/app-master.service'
import { DxDataGridComponent, DxTemplateHost } from 'devextreme-angular'
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component'

import {
  IReqSaveTemplate,
} from 'app/business/interface/api/api-co.interface'
import { debounce } from 'lodash'
import { CreateCoPackPopupComponent } from '../create-co-inquiry/create-copack-popup/create-copack-popup.component'
import { CreateGoodListPopupComponent } from '../create-co-inquiry/create-goodlist-popup/create-goodlist-popup.component'
import { CreateInvoicePopupComponent } from '../create-co-inquiry/create-invoice-popup/create-invoice-popup'
import _ from 'underscore'
import { AppUserService } from 'app/business/service/app/app-user.service'
import { AuthService } from 'app/core/auth/auth.service'
@Component({
  selector: 'app-create-template-inquiry',
  templateUrl: './create-template-inquiry.component.html',
  styleUrls: ['./create-template-inquiry.component.scss'],
})
export class CreateTemplateInquiryComponent implements OnInit, AfterViewInit {
  @Input() subEdit: boolean = false
  @Input() disabledJob: boolean
  @Input() packIdInput: number
  @Input() isJobDetail: boolean = undefined
  search: any
  isCheckedInvoice : boolean
  dataSourceGoodItem: any[] = []
  dataSourceInvoice: any[] = []
  dataSourceAttachPic: any[] = []
  dataSourcecoPAck: any[] = []
  showErrorMessage: any = {}
  file: any
  value: any[] = []
  attachTypeList: any[] = []
  isCreateForm: boolean
  isCreateTemplate: boolean
  editItem: boolean = false
  clicked: boolean
  editJobData: boolean
  PackidLoadData: number
  placeholderTemplate: String = 'เลือกไฟล์แม่แบบ'
  placeholderSignature: String = 'ชื่อ-นามสกุล'
  placeholderDeclarator: String = 'ชื่อผู้ส่งออกสินค้า'
  placeholderVessel: String = 'ขนส่งโดย'
  placeholderDestination: String = 'ประเทศปลายทาง'
  placeholderBranch: string = 'สาขารับเอกสาร'
  placeholderOrigin: string = 'ประเทศแหล่งกำเนิดสินค้า'
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent

  @ViewChild('gridContainer', { static: false })
  invoiceGrid: DxDataGridComponent
  invoiceGridLength: number
  templateOptions: any[]
  templateList: any[]
  declaratorList: any[]
  declaratorListRelation: any[]
  nameLastNameList: any[]
  branchList: any[]
  vesselTypeList: any[]
  destinationList: any[]
  form: any = {
    id: 0,
    docType: 'CO',
    lastDraftVersion: 0,
    items: [],
    attachments: [],
    invoice: [],
    packId: 0,
    countryOfOrigin: 0,
    destinationCountry: 0,
  }
  imageResize: any
  imgSrc: string
  userProfile: any = {}
  cOPackList: any[] = []
  deleteCOPackList: any = []
  deleteInvoiceList: any = []
  deleteItemsList: any = []
  deleteAttachmentList: any = []
  displayStyle = 'none'
  resolution: any = {}
  editorOptions: any = {
    placeholder: 'Enter some text',
    maxLength: 50,
  }

  fieldError: any = {
    declarator: true,
    consignor: true,
    consignee: true,
    notify: true,
    vessel: true,
    loading: true,
    certificateDate: true,
    destination: true,
    invoice: true,
    country: true,
    item: true,
    footer: true,
  }
  markErrorList: any = []
  signId: any;
  symbolRegex: RegExp
  engRegex: RegExp
  regexPattern: RegExp = /^[a-zA-Z0-9 !"$%&'()*+,-./#:;<=>?@[\\\]^_`{|}~\n\r฿]*$/;
  @Output() newForm: EventEmitter<any> = new EventEmitter<any>()
  constructor(
    private router: Router,
    private appCOService: AppCOService,
    private appMasterService: AppMasterService,
    private dialog: MatDialog,
    private alertService: AlertMessageService,
    private route: ActivatedRoute,
    private appUserService: AppUserService,
    private _authService: AuthService,
  ) {
    this.isCreateForm = false
    this.form.isCreateTemplate = false
    this.form.certificateDateRequest = 'approve_date'
    this.save = debounce(this.save, 500)
  }

  ngOnInit() { 
    this.isCheckedInvoice = false; 

    this.userProfile = JSON.parse(this._authService.loginUser);
    this.userProfile = {
      ...this.userProfile,
      uId:this.userProfile.uid,
      cid:this.userProfile.cpId,
      userName:this.userProfile.userName,
    } 

    this.editItem = false
    const { id, disabled } = this.route.snapshot.queryParams
    this.PackidLoadData = id
    this.editItem = disabled
    this.loadData(this.PackidLoadData)
    this.loadMasterConfigD()
  }
  ngAfterViewInit() { }
  async loadData(id) {
    let requestTemplate: IgetListLoadTemplateRequest = {
      UID: this.userProfile.uId,
      CID: this.userProfile.cid,
      tempName: '',
      isActive: 'Y',
    }
  await this.appCOService.getListLoadTemplate(requestTemplate).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        this.templateList = []
        this.templateList = res
      }
    })

   
    let requestmaster = {
      id: 0,
      isActive: 'Y',
    }
    await this.appMasterService.getListBranch(requestmaster).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        this.branchList = []
        this.branchList = res
      }
    })
    await this.appMasterService.getListVesselType(requestmaster).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        this.vesselTypeList = []
        this.vesselTypeList = res
        if (this.vesselTypeList.length != 0) {
          this.form.vesselType = _.first(this.vesselTypeList).name;
        }
      }
    })
    this.appCOService.getListAttachType().then((res: any) => {
      if (res === 'error') {
        return
      } else {
        this.attachTypeList = []
        this.attachTypeList.push({
          maT_DOC_NAME: '- กรุณาเลือก Please Select -',
          maT_DOC_TYPE_ID: 0,
        })
        this.attachTypeList = this.attachTypeList.concat(res)
      }
    })
    await this.appMasterService.getListCountry(requestmaster).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        var index = res.findIndex((x) => x.mC_COUNTRY_NAME == 'THAILAND')
        this.form.countryOfOrigin = res[index].mC_COUNTRY_NAME
        this.destinationList = res
      }
    })

    await this.appMasterService
      .getUserAddress({ id: this.userProfile.cid })
      .then((res: any) => {
        if (res === 'error') {
          return
        } else {
          this.form.consignorAddress = res?.address
        }
      })
    if (id) {
      const data = {
        id: id,
        tempName: '',
      }
      await this.appCOService.getLoadTemplate(data).then((res: any) => {
        if (res === 'error') {
          return
        } else {
          var data = _.first(res);
          if (!data || data === null) {
            return;
          }

          this.form.id = data.id
          this.form.certificateDateRequest = data.certificateDateRequest
          this.form.companyId = data.companyId
          this.form.consigneeName = data.consigneeName
          this.form.consigneeNotify = data.consigneeNotify
          this.form.consignor = data.consignor
          this.form.consignorAddress = data.consignorAddress
          this.form.consignorId = data.consignorId
          this.form.consignorName = data.consignor
          this.form.countryOfOrigin = data.countryOfOrigin
          this.form.declarator = data.declarator
          this.form.declaratorId = data.declaratorId
          this.form.destination = data.destination
          this.form.destinationCountry = data.destinationCountry
          this.form.docType = data.docType
          this.form.invoice = data.invoice
          //this.form.invoiceChk = data.invoiceChk;
          this.form.invoiceChk = (data.invoiceChk == "N") ? false : true; 
          this.form.invoiceDate = data.invoiceDate
          this.form.invoiceNo = data.invoiceNo
          this.form.isActive = data.isActive
          this.form.items = data.items
          this.form.loadingDate = data.loadingDate
          this.form.signAuthorizedId = data.signAuthorizedId
          this.form.tempName = data.tempName
          this.form.vesselNo = data.vesselNo
          this.form.vesselType = data.vesselType
          this.form.remark = data.footerDescription
          this.signId = data.signAuthorizedId
          //this.dataSourceInvoice = data.invoice
          //this.dataSourceGoodItem = data.items
          this.DropdromwChange(data.declaratorId, true);

          this.dataSourceInvoice = this.form.invoice == null ? [] : [...this.form.invoice];
          this.dataSourceGoodItem = this.form.items == null ? [] : this.form.items.map(item => {
            return {
              ...item,
              exportValue: parseFloat(item.exportValue).toFixed(3),
              weight: parseFloat(item.weight).toFixed(3),
              grossWeight: parseFloat(item.grossWeight).toFixed(3),
              exchangeValue: parseFloat(item.exchangeValue).toFixed(3),
            }
          });
        }
      })
    }
    let request = {
      CPID: this.userProfile.uId,
      CID: this.userProfile.cid,
      isActive: 'Y',
    }
    await this.appCOService.getListDeclaratorCompany(request).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        this.declaratorList = []
        this.declaratorList = res
        this.form.requestBy = this.declaratorList[0]?.companyNameEn
        this.form.consignorName =
          this.form.consignorName ?? this.declaratorList[0]?.companyNameEn
      }
    })


    let requestCompanyRelation = {
      CPID: this.userProfile.uId,
      CID: this.userProfile.cid,
      isActive: 'Y',
    }
    await this.appCOService
      .getRelationCompany(requestCompanyRelation)
      .then((res: any) => {
        if (res === 'error') {
          return
        } else {
          this.declaratorListRelation = []
          if (res != null) {
            res = res.map((item) => {
              if (item.blacklistStatus == 'Y') {
                item.companyNameEn = item.companyNameEn + ' ' + '(Blacklist)';
                item.companyNameTh = item.companyNameTh + ' ' + '(ติดแบล็คลิส)';
              }
              return item
            })
          }
          //this.form.declaratorId = data?.blacklistStatus != 'Y' ? (data?.cid ?? 0) : 0;
          this.form.declaratorId = this.PackidLoadData !== undefined ? this.form.declaratorId : (res[0]?.blacklistStatus != 'Y' ? (res[0]?.cid ?? 0) : 0); 
          this.declaratorListRelation = res

        }
      })
      let getListNameLastNameRequest = {
        CPID: this.form.declaratorId,
        isActive: 'Y',
      }
      await this.appMasterService
        .getListNameLastName(getListNameLastNameRequest)
        .then((res: any) => {
          if (res === 'error') {
            return
          } else {
            this.nameLastNameList = []
            this.nameLastNameList = res
            if (res?.length === 0) {
              this.nameLastNameList.unshift({ csid: "", fullNameThai: "ไม่พบข้อมูลลายมือชื่อผู้มอบอำนาจ" });
              if (!this.form.signAuthorizedId) {
                // this.form.signAuthorizedId = "";
              }
            } else {
              this.nameLastNameList = res
  
              if (this.nameLastNameList.length === 1) {
                const firstRecord = _.first(res);
                this.form.signAuthorizedId = firstRecord.csid;
              } else {
                const checkAll = this.nameLastNameList.find(e => e.fullNameThai === "กรุณาเลือก");
                if (checkAll === undefined) {
                  this.nameLastNameList.unshift({ csid: "", fullNameThai: "กรุณาเลือก" });
                  if (!this.form.signAuthorizedId) {
                    // this.form.signAuthorizedId = "";
                  }
                }
              }
            }
          }
        })
  }
  loadTemplate() {
    const data = {
      id: this.form.templateId,
      tempName: this.form.tempName == undefined ? '' : this.form.tempName,
    }

    this.appCOService.getLoadTemplate(data).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        res[0].destinationCountry =
          res[0].destinationCountry == null ||
            res[0].destinationCountry == undefined
            ? this.form.destinationCountry
            : res[0].destinationCountry
        this.form = { ...this.form, ...res[0] }
        this.form.tempName = ' '

        if (this.form.invoice != undefined && this.form.invoice != null) {
          this.form.invoice.forEach((item) => {
            item.invoiceId = 0
            item.docHid = 0
          })
          this.dataSourceInvoice = []
          this.form.invoice.forEach((item) => {
            this.dataSourceInvoice.push(item)
          })
        }

        if (this.form.items != undefined && this.form.items != null) {
          this.form.items.forEach((item) => {
            item.docHeaderId = 0
            item.docItemId = 0
          })
          this.dataSourceGoodItem = []
          this.form.items.forEach((item) => {
            this.dataSourceGoodItem.push(item)
          })
        }
      }
    })
  }
  loadMasterConfigD() {
    // Load Validate Input symbol
    let validateRequest = {
      configHcode: "VALIDATE",
      configDcode: 'V1',
    }
    // Load Validate Input english
    let validateRequestInput = {
      configHcode: "VALIDATE",
      configDcode: 'V2',
    }

    Promise.all([this.appMasterService.getValidateInput(validateRequest),
    this.appMasterService.getValidateInput(validateRequestInput)]).then(([res1, res2]) => {
      if (res1 != 'error') {
        this.symbolRegex = new RegExp(res1.configDEName)
      }
      if (res2 != 'error') {
        this.engRegex = new RegExp(res2.configDEName);
      }
    })
  }
  validate(e, f): boolean {
    let fieldError: string[] = [];
    if (
      (!e.tempName ||
        e.tempName === undefined ||
        e.tempName == 0 ||
        e.tempName == '') 
    ) {
      fieldError.push('ระบุชื่อแม่แบบที่ต้องการบันทึก')
    }
    if (
      !e.declaratorId ||
      e.declaratorId === undefined ||
      e.declaratorId == 0 ||
      e.declaratorId == ''
    ) {
      fieldError.push('ระบุผู้ส่งออกสินค้า')
    }

    // if (
    //   !e.consignorName ||
    //   e.consignorName === undefined ||
    //   e.consignorName == 0 ||
    //   e.consignorName == ''
    // ) {
    //   fieldError.push('ระบุชื่อผู้ส่งออกสินค้า')
    // }//ตรวจสอบอักขระพิเศษ
    // else 
    if (!(this.regexPattern.test(e.consignorName)) && (e.consignorName != null || e.consignorName || undefined || e.consignorName != '')) {
      fieldError.push('ชื่อผู้ส่งออกกรอกข้อมูลได้เฉพาะภาษาอังกฤษและสัญลักษณ์')
    }
    // if (
    //   !e.consignorAddress ||
    //   e.consignorAddress === undefined ||
    //   e.consignorAddress == 0 ||
    //   e.consignorAddress == ''
    // ) {
    //   fieldError.push('ระบุที่อยู่')
    // }else 
    if (!(this.regexPattern.test(e.consignorAddress)) && (e.consignorAddress != null || e.consignorAddress == undefined || e.consignorAddress != '')) {
      fieldError.push('ที่อยู่กรอกข้อมูลได้เฉพาะภาษาอังกฤษและสัญลักษณ์')
    }
    
    if (e.certificateDateRequest == 'on_date') {
      if (e.certificateDate == null || e.certificateDate == undefined || e.certificateDate == '') {
        fieldError.push('ตรวจสอบวันที่ย้อนหลัง/ล่วงหน้า')
      }
    }


    if (
      e.liveSignature == 'Y' &&
      (e.sigBranchId == 0 ||
        e.sigBranchId == undefined ||
        e.sigBranchId == null ||
        e.sigBranchId == '')
    ) {
      fieldError.push('ระบุสาขาลายเซ็นสด')
    }
    if (
      !e.signAuthorizedId ||
      e.signAuthorizedId === undefined ||
      e.signAuthorizedId == 0 ||
      e.signAuthorizedId == ''
    ) {
      fieldError.push('ระบุลายมือชื่อผู้รับมอบอำนาจ')
    }
    // if(e.invoice.length < 1) {      
    //   fieldError.push('ระบุเลขใบกำกับสินค้า/วันที่')
    // }
    if (
      (!e.coTotalPage ||
        e.coTotalPage === undefined ||
        e.coTotalPage == 0 ||
        e.coTotalPage == '') &&
      this.form.isPackRq == true
    ) {
      fieldError.push('ระบุจำนวนชุดที่ต้องการ')
    }
    if (
      (!e.packName ||
        e.packName === undefined ||
        e.packName == 0 ||
        e.packName == '') &&
      this.form.isPackRq == true
    ) {
      fieldError.push('ระบุชื่อชุดเอกสาร')
    }

    //Check ขนส่งโดย
    if (
      !e.vesselType ||
      e.vesselType === undefined ||
      e.vesselType == 0 ||
      e.vesselType == '' ||
      e.vesselType == '-'
    ) {
      fieldError.push('ระบุขนส่งโดย')
    }
    //Check ชื่อพาหนะ
    // if (
    //   !e.vesselNo ||
    //   e.vesselNo === undefined ||
    //   e.vesselNo == 0 ||
    //   e.vesselNo == '' ||
    //   e.vesselNo == '-'
    // ) {
    //   fieldError.push('ระบุชื่อพาหนะ')
    if (!(this.regexPattern.test(e.vesselNo)) && (e.vesselNo != null || e.vesselNo == undefined || e.vesselNO != '')) {
      fieldError.push('ชื่อพาหนะกรอกข้อมูลได้เฉพาะภาษาอังกฤษและสัญลักษณ์')
    }
    //Check วันที่ขนสินค้าขึ้นพาหนะ
    // if (
    //   !e.loadingDate ||
    //   e.loadingDate === undefined ||
    //   e.loadingDate == 0 ||
    //   e.loadingDate == '' ||
    //   e.loadingDate == ''
    // ) {
    //   fieldError.push('ระบุวันที่ขนสินค้าขึ้นพาหนะ')
    // }

    //Check ปลายทางสินค้าที่ถูกยกลง
    // if (
    //   !e.destination ||
    //   e.destination === undefined ||
    //   e.destination == 0 ||
    //   e.destination == ''
    // ) {
    //   fieldError.push('ระบุปลายทางสินค้าที่ถูกยกลง')
    if (!(this.regexPattern.test(e.destination)) && (e.destination != null || e.destination == undefined || e.destination != '')) {
      fieldError.push('ปลายทางที่ถูกยกลงกรอกข้อมูลได้เฉพาะภาษาอังกฤษและสัญลักษณ์')
    }
    //Check ประเทศปลายทางไม่ปรากฎบนฟอร์ม
    if (
      !e.destinationCountry ||
      e.destinationCountry === undefined ||
      e.destinationCountry == '' ||
      e.destinationCountry == '0' ||
      e.destinationCountry == 0
    ) {
      fieldError.push('ระบุประเทศปลายทางไม่ปรากฎบนฟอร์ม')
    }

    
    //Check ผู่รับสินค้าปลายทาง
    // if (!e.consigneeName || e.consigneeName === undefined || e.consigneeName == 0 || e.consigneeName == ''
    // ) {
    //   fieldError.push('ระบุผู้รับสินค้าปลายทาง')
    // }
    if (!(this.regexPattern.test(e.consigneeName)) && (e.consigneeName != null || e.consigneeName == undefined || e.consigneeName != '')) {
      fieldError.push('ผู้รับสินค้าปลายทางกรอกข้อมูลได้เฉพาะภาษาอังกฤษและสัญลักษณ์')
    }

    if (e.isPackRq == 'Y') {
      var tmpItemDoc = true
      if (e.coPack.length == 0) {
        fieldError.push('ระบุรายการใบ CO')
      } else {
        e.coPack.map((x) => {
          if (x.items === undefined || x.invoice === undefined) {
            tmpItemDoc = false
            return
          }
        })
      }
      if (!tmpItemDoc) {
        fieldError.push('ระบุรายการใบ CO')
      }
    }
    // } else {
    //   if (f.items.length < 1) {
    //     fieldError.push('ระบุรายการสินค้า')
    //   }
    // }


    if (!(this.regexPattern.test(e.consigneeNotify)) && (e.consigneeNotify != null || e.consigneeNotify == undefined || e.consigneeNotify != '')) {
      fieldError.push('ผู้ที่จะได้รับแจ้งเมื่อสินค้าไปถึงกรอกข้อมูลได้เฉพาะภาษาอังกฤษและสัญลักษณ์')
    }

    if (fieldError.length === 0) {
      return true
    } else {
      fieldError.forEach((e, i) => {
        fieldError[i] = (i + 1).toString() + ") " + e;
      })

      fieldError.unshift("กรุณาตรวจสอบข้อผิดพลาดดังต่อไปนี้")
      const result: string = fieldError.join("\n");

      this.alertService.open({ message: result, title: "" });
      return false
    }

  }
  save() {

    this.clicked = true
    const data = {
      id: this.PackidLoadData,
      declarator:
        this.form.declaratorId !== undefined
          ? this.declaratorListRelation.find(
            (x) => x.cid == this.form.declaratorId,
          ).companyNameEn
          : '',
      declaratorId: this.form.declaratorId,
      consignorName: this.form.consignorName,
      consignor:
        this.form.declaratorId !== undefined
          ? this.declaratorListRelation.find(
            (x) => x.cid == this.form.declaratorId,
          ).companyNameEn
          : '',
      consignorId: this.form.declaratorId,
      consignorAddress: this.form.consignorAddress,
      certificateDate: this.form.certificateDate,
      certificateDateRequest: this.form.certificateDateRequest,
      consigneeName: this.form.consigneeName,
      consigneeNotify: this.form.consigneeNotify == undefined ? ' ' : this.form.consigneeNotify,
      vesselType:
        this.form.vesselType == undefined ? '' : this.form.vesselType,
      vesselNo: this.form.vesselNo == undefined ? '' : this.form.vesselNo,
      loadingDate: this.form.loadingDate,
      destination: this.form.destination,
      destinationCountry: this.form.destinationCountry,
      invoiceChk: this.form.invoiceChk == true ? 'Y' : 'N',
      countryOfOrigin: this.form.countryOfOrigin,
      docType: this.form.docType,
      tempName: this.form.tempName,
      signAuthorizedId: this.form.signAuthorizedId,
      footerDescription: this.form.remark,
      isActive: 'Y',
    }

    //Revise Request API
    let dataReq = { ...data } as IReqSaveTemplate
    let dataSource = {
      items: this.dataSourceGoodItem,
      invoice: this.dataSourceInvoice,
    }
    debugger;
    let valid = this.validate(data, dataSource)

    if (valid) {
      dataReq.invoice =
        this.deleteInvoiceList.length == 0
          ? this.form.invoice
          : this.form.invoice?.concat(this.deleteInvoiceList)
      dataReq.items =
        this.deleteItemsList.length == 0
          ? this.form.items
          : this.form.items?.concat(this.deleteItemsList)

      this.appCOService.saveTemplate(dataReq).then((res: any) => {
        if (res == 'error') {
          return
        } else {
          this.alertService
            .open({
              message: 'บันทึกสำเร็จ',
              title: '',
            })
            .then((x) => {
              this.router.navigate([
                '/page/configuration/config-template/list-template',
              ])
            })
        }
      })
    } else {
      this.clicked = false
      return
    }
  }

  addInvoice() {
    const dialogRef = this.dialog.open(CreateInvoicePopupComponent, {
      disableClose: false,
      width: '70vw',
      data: {
        form: { invoiceId: 0, docHid: 0, invoiceNum: 0, isActive: 'Y' },
        editItem: this.editItem,
      },
      maxWidth: 'none',
    })
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.form.invoice.push(res)
        this.dataSourceInvoice.push(res)
      }
    })
  }
  deleteInvoice(e) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      disableClose: false,
      // width: '30vw',
      data: {
        header: 'คุณต้องการลบข้อมูลนี้หรือไม่',
        button: 'open',
      },
      maxWidth: 'none',
    })

    dialogRef.afterClosed().subscribe((res) => {
      if (res == true) {
        if (e.data.invoiceId != 0) {
          this.dataSourceInvoice.splice(e.rowIndex, 1)
          const updInvoice = this.form.invoice.findIndex(
            (obj) => obj.invoiceId == e.data.invoiceId,
          )
          this.form.invoice[updInvoice].isActive = 'N'
        } else {
          // this.form.items[e.rowIndex].isActive = "N"
          this.dataSourceInvoice.splice(e.rowIndex, 1)
          this.form.invoice.splice(e.rowIndex, 1)
        }
      }
    })
  }
  editInvoice(e) {
    const dialogRef = this.dialog.open(CreateInvoicePopupComponent, {
      disableClose: false,
      width: '70vw',
      data: {
        form: {
          invoiceNo: e.data.invoiceNo,
          invoiceDate: e.data.invoiceDate,
          invoiceId: e.data.invoiceId,
          docHid: e.data.docHid,
          invoiceNum: e.data.invoiceNum,
          isActive: e.data.isActive
        },
        editItem: this.editItem,
      },
      maxWidth: 'none',
    })
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.form.invoice.splice(e.rowIndex, 1, res)
        this.dataSourceInvoice.splice(e.rowIndex, 1, res)
      }
    })
  }
  addGoods() {
    const dialogRef = this.dialog.open(CreateGoodListPopupComponent, {
      disableClose: false,
      width: '70vw',
      maxHeight: '100vh',
      data: {
        edit: true,
        data: {},
      },
      maxWidth: 'none',
    })

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        res.id = this.form.items.length + 1
        const data = {
          docItemId: 0,
          harmonizedCode: res.harmonizedCode,
          markChk: res.markCHK == true ? 'Y' : 'N',
          markItem: res.markItem,
          descriptionChk: res.descriptionCHK == true ? 'Y' : 'N',
          description: res.description,
          quantity: res.quantity,
          quantityChk: res.quantityCHK == true ? 'Y' : 'N',
          quantityUnit: res.quantityObj.name,
          quantityUnitCode: res.quantityObj.code,
          weight: parseFloat(res.weight).toFixed(3),
          weightChk: res.weightCHK == true ? 'Y' : 'N',
          weightUnit: res.weightObj.name,
          weightUnitCode: res.weightObj.code,
          grossWeight: parseFloat(res.grossWeight).toFixed(3),
          grossWeightChk: res.grossWeightCHK == true ? 'Y' : 'N',
          grossWeightUnit: res.grossWeightObj.name,
          grossWeightUnitCode: res.grossWeightObj.code,
          exportValue: parseFloat(res.exportValue).toFixed(3),
          exchangeRate: res.exchangeRate,
          exchangeValue: parseFloat(res.exchangeValue).toFixed(3),
          docHeaderId: 0,
          isActive: 'Y',

        }
        this.form.items.push(data)
        this.dataSourceGoodItem.push(data)
      }
      // receive data from dialog in res
    })
  }
  onUpload(file: any): void {
    if (file.size > 400000) {
      this.alertService.open({
        message: 'รูปมีขนาดใหญ่กว่า 400 KB',
        title: 'โปรดตรวจสอบขนาดรูปภาพ',
      })
      return
    }
    const formData = new FormData()
    formData.append('image', this.file, this.file.name)
    formData.append('name', 'Attachments');

    this.appMasterService.uploadImageToBlobs(formData).subscribe((result) => {
      if (result) {
        let index = this.form.attachments.length + 1
        const data = {
          attachPicId: 0,
          docHid: 0,
          attachFilePic: result[0]?.filePath,
          attachType: 0,
          attachIndex: index,
          remark: '-',
          attachHeaderType: 2,
          isActive: 'Y',
        }
        // this.fileImg = result[0]?.filePath;
        // this.pic = result[0]?.fileName + result[0]?.fileType;
        this.form.attachments.push(data)
        this.dataSourceAttachPic.push(data)
      }
    })
  }
  addCOPack(e) {
    const dialogRef = this.dialog.open(CreateCoPackPopupComponent, {
      disableClose: false,
      width: '100vw',
      height: '100vh',
      data: {
        id: this.dataSourcecoPAck[e.rowIndex].docHid != 0 ? e.rowIndex : 0,
        invoice:
          this.dataSourcecoPAck[e.rowIndex].docHid != 0
            ? this.cOPackList[e.rowIndex].invoice
            : [],
        items:
          this.dataSourcecoPAck[e.rowIndex].docHid != 0
            ? this.cOPackList[e.rowIndex].items
            : [],
        countryOfOrigin:
          this.dataSourcecoPAck[e.rowIndex].docHid != 0
            ? this.cOPackList[e.rowIndex].countryOfOrigin
            : '',
        invoiceChk:
          this.dataSourcecoPAck[e.rowIndex].docHid != 0
            ? this.cOPackList[e.rowIndex].invoiceChk
            : false,
        remark:
          this.dataSourcecoPAck[e.rowIndex].docHid != 0
            ? this.cOPackList[e.rowIndex].remark
            : '',
        editItem: this.editItem,
        docHid:
          this.dataSourcecoPAck[e.rowIndex].docHid != 0 ? e.data.docHid : 0,
        isActive: 'Y',
        invoiceEdit: this.fieldError.invoice,
        itemEdit: this.fieldError.item,
        countryOfOriginEdit: this.fieldError.country,
        footerEdit: this.fieldError.footer,
        isRqstatus: this.form.isRqstatus,
      },

      maxWidth: 'none',
    })
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        res.packId = 0
        res.invoiceChk = res.invoiceChk == true ? 'Y' : 'N'
        // receive data from dialog in res
        res.invoiceNo = res.invoice[0].invoiceNo
        res.invoiceDate = res.invoice[0].invoiceDate
        this.cOPackList.splice(e.rowIndex, 1, res)
        this.dataSourcecoPAck.splice(e.rowIndex, 1, res)
      }
    })
  }

  amountRequestChanged(e) {
    if (this.form.coTotalPage > 15) {
      this.form.coTotalPage = 15
    }

    let number = this.cOPackList.length - this.form.coTotalPage
    if (number < 0) {
      for (let i = 0; i < Math.abs(number); i++) {
        this.cOPackList.push({
          invoiceId: 0,
          docHid: 0,
          invoiceNum: 0,
          invoiceNo: 'กรุณาระบุ Invoice No',
          invoiceDate: 'กรุณาระบุ Invoice Date',
        })
        this.dataSourcecoPAck.push({
          invoiceId: 0,
          docHid: 0,
          invoiceNum: 0,
          invoiceNo: 'กรุณาระบุ Invoice No',
          invoiceDate: 'กรุณาระบุ Invoice Date',
        })
      }
    } else {
      for (let i = 0; i < number; i++) {
        this.cOPackList.pop()
        this.dataSourcecoPAck.pop()
      }
    }
    if (this.form.coTotalPage > 15) {
      this.form.coTotalPage = 15
    }
  }

  addNewRow() {
    if (this.dataSourcecoPAck.length >= 15) {
      return
    }
    this.cOPackList.push({
      invoiceId: 0,
      docHid: 0,
      invoiceNum: 0,
      invoiceNo: 'กรุณาระบุ Invoice No',
      invoiceDate: 'กรุณาระบุ Invoice Date',
      isActive: 'Y',
    })
    this.dataSourcecoPAck.push({
      invoiceId: 0,
      docHid: 0,
      invoiceNum: 0,
      invoiceNo: 'กรุณาระบุ Invoice No',
      invoiceDate: 'กรุณาระบุ Invoice Date',
      isActive: 'Y',
    })
    this.form.coTotalPage = this.dataSourcecoPAck.length
  }
  deleteCOPack(e) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      disableClose: false,
      // width: '30vw',
      data: {
        header: 'คุณต้องการลบข้อมูลนี้หรือไม่',
        button: 'open',
      },
      maxWidth: 'none',
    })

    dialogRef.afterClosed().subscribe((res) => {
      if (res == true) {
        if (e.data.docHid != 0) {
          this.dataSourcecoPAck.splice(e.rowIndex, 1)
          const updCoPack = this.cOPackList.findIndex(
            (obj) => obj.docHid == e.data.docHid,
          )
          this.cOPackList[updCoPack].isActive = 'N'
          this.form.coTotalPage = this.dataSourcecoPAck.length
        } else {
          // this.form.items[e.rowIndex].isActive = "N"
          this.dataSourcecoPAck.splice(e.rowIndex, 1)
          this.cOPackList.splice(e.rowIndex, 1)
          this.form.coTotalPage = this.dataSourcecoPAck.length
        }
      }
    })
  }

  editGood(e) {
    const dialogRef = this.dialog.open(CreateGoodListPopupComponent, {
      disableClose: false,
      width: '70vw',
      maxHeight: '100vh',
      data: { data: e.data, edit: true },
      maxWidth: 'none',
    })

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        res.id = e.data.id
        const data = {
          docItemId: res.docItemId,
          harmonizedCode: res.harmonizedCode,
          markChk: res.markCHK == true ? 'Y' : 'N',
          markItem: res.markItem,
          descriptionChk: res.descriptionCHK == true ? 'Y' : 'N',
          description: res.description,
          quantity: res.quantity,
          quantityChk: res.quantityCHK == true ? 'Y' : 'N',
          quantityUnit: res.quantityObj.name,
          quantityUnitCode: res.quantityObj.code,
          weight: res.weight,
          weightChk: res.weightCHK == true ? 'Y' : 'N',
          weightUnit: res.weightObj.name,
          weightUnitCode: res.weightObj.code,
          grossWeight: res.grossWeight,
          grossWeightChk: res.grossWeightCHK == true ? 'Y' : 'N',
          grossWeightUnit: res.grossWeightObj.name,
          grossWeightUnitCode: res.grossWeightObj.code,
          exportValue: res.exportValue,
          exchangeRate: res.exchangeRate,
          exchangeValue: res.exchangeValue,
          docHeaderId: res.docHeaderId,
          isActive: 'Y',
        }
        this.form.items.splice(e.rowIndex, 1, data)
        this.dataSourceGoodItem.splice(e.rowIndex, 1, data)
      }
    })
  }

  weighDisplay(rowData) {
    return rowData.weight + " " + rowData.weightUnit;
  }

  limitTextareaLines(event: Event) {
    const textarea = event.target as HTMLTextAreaElement;
    const maxLines = 4; // Specify the maximum number of lines you want to allow

    // Split the textarea value by line breaks and check the number of lines
    const lines = textarea.value.split('\n');
    if (lines.length > maxLines) {
      // Truncate the textarea value to the specified maximum number of lines
      textarea.value = lines.slice(0, maxLines).join('\n');
    }
    
  }

  quantityDisplay(rowData) {
    return rowData.quantity + " " + rowData.quantityUnit;
  }
  deleteGood(e) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      disableClose: false,
      // width: '30vw',
      data: {
        header: 'คุณต้องการลบข้อมูลนี้หรือไม่',
        button: 'open',
      },
      maxWidth: 'none',
    })

    dialogRef.afterClosed().subscribe((res) => {
      if (res == true) {
        if (e.data.docHeaderId != 0) {
          this.dataSourceGoodItem.splice(e.rowIndex, 1)
          const updGoodItem = this.form.items.findIndex(
            (obj) => obj.docItemId == e.data.docItemId,
          )
          this.form.items[updGoodItem].isActive = 'N'
        } else {
          // this.form.items[e.rowIndex].isActive = "N"
          this.dataSourceGoodItem.splice(e.rowIndex, 1)
          this.form.items.splice(e.rowIndex, 1)
        }
      }
    })

  }

  async DropdromwChange(e, isLoad = false) {
    var checkData = this.declaratorListRelation.find(x => x.cid === e);

    if (checkData.emailStatus === "N") {
      this.alertService.open({ message: "บริษัทผู้ส่งออกนี้ ยังไม่ได้ลงทะเบียนรับเอกสารอิเล็กทรอนิกส์ กรุณาลงทะเบียนด้วย User admin ของบริษัทผู้ส่งออก", title: "" })
    }

    let request = {
      CPID: this.userProfile.uId,
      CID: this.form.declaratorId,
      isActive: 'Y',
    }
    await this.appCOService
      .getListDeclaratorCompany(request)
      .then((res: any) => {
        if (res === 'error') {
          return
        } else {
          this.declaratorList = []
          this.declaratorList = res
          this.form.consignorName = this.declaratorList[0]?.companyNameEn
        }
      })
    this.appMasterService
      .getUserAddress({ id: this.form.declaratorId })
      .then((res: any) => {
        if (res === 'error') {
          return
        } else {
          this.form.consignorAddress = res?.address
        }
      })
    let getListNameLastNameRequest = {
      CPID: e,
      isActive: 'Y',
    }
    this.appMasterService
      .getListNameLastName(getListNameLastNameRequest)
      .then((res: any) => {
        if (res === 'error') {
          return
        } else {
          this.nameLastNameList = []
          this.nameLastNameList = res
          //this.form.signAuthorizedId = null
          if (res?.length === 0) {
            this.nameLastNameList.unshift({ csid: "", fullNameThai: "ไม่พบข้อมูลลายมือชื่อผู้มอบอำนาจ" });
            this.form.signAuthorizedId = "";
          } else {
            this.nameLastNameList = res

            if (this.nameLastNameList.length === 1) {
              const firstRecord = _.first(res);
              this.form.signAuthorizedId = firstRecord.csid;
            } else {
              const checkAll = this.nameLastNameList.find(e => e.fullNameThai === "กรุณาเลือก");

              if (checkAll === undefined) {
                this.nameLastNameList.unshift({ csid: "", fullNameThai: "กรุณาเลือก" });
                this.form.signAuthorizedId = isLoad ? this.signId : "";
              }
            }
          }
        }
      })
  }
  checkSymbol(e) {
    if (e != null) {
      var validateInput = this.symbolRegex.test(e);
    }
    return validateInput;
  }
  checkEnglish(e) {
    if (e != null && e!= "" && e != undefined) {
      var validateInput = this.regexPattern.test(e);
      //validateInput = validateInput === true ? false : true;
    }
    return validateInput;
  }
}
