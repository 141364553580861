import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from '@bundle/service/api.service'
import { IGetListJobRequest } from 'app/business/interface/app/app-job.interface'

@Injectable({
  providedIn: 'root',
})
export class ApiRequestService {
  constructor(private apiService: ApiService) {}

  saveCF(any): Observable<any[]> {
    return this.apiService.post('/api/saveCF', any)
  }
  getListJob(request: IGetListJobRequest): Observable<any[]> {
    return this.apiService.post('/api/CO/getJobRequestList', request)
  }

  deleteCF(request: any): Observable<any[]> {
    return this.apiService.post('/api/deleteCF', request)
  }
  getDocCreate(request: any): Observable<any[]> {
    return this.apiService.post('/api/CO/getDocCreate', request)
  } 

  createJobRequest(request:any):Observable<any[]>{
    return this.apiService.post('/api/CO/createJobRequest',request)
  }

  sendToVerify(request:any):Observable<any[]>{
    return this.apiService.post('/api/CO/sendToVerify',request);
  }

  getMark(request:any) :Observable<any[]>{
    return this.apiService.post('/api/CO/getMark',request);
  }

  getDocumentEdit(request: any):Observable<any> {
    return this.apiService.post('/api/getDocumentEdit', request);
  }

  saveDocumentEdit(request: any):Observable<any> {
    return this.apiService.post('/api/saveDocumentEdit', request);
  }
}
