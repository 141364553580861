import { LiveAnnouncer } from '@angular/cdk/a11y'
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatDialog } from '@angular/material/dialog'
import { MatSort, Sort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { ConfigRelationshipPopupComponent } from './config-relationship-popup/config-relationship-popup.component'
import { MatMenuTrigger } from '@angular/material/menu'
import { AppConfigRelationshipService } from 'app/business/service/app/app-config-relationship'
import { ActivatedRoute, Router } from '@angular/router'
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component'
import { IAppDeleteRelationship } from 'app/business/interface/app/app-config-relationship'
import { AppMenuService } from 'app/business/service/app/app-menu.service'
import { AppUserService } from 'app/business/service/app/app-user.service'
import { ConfirmDialogService } from '@bundle/component/confirm-dialog/confirm-dialog.service'
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'
import { AuthService } from 'app/core/auth/auth.service'

@Component({
  selector: 'app-config-relationship-inquiry',
  templateUrl: './config-relationship-inquiry.component.html',
  styleUrls: ['./config-relationship-inquiry.component.scss'],
})
export class ConfigRelationshipInquiryComponent
  implements OnInit, AfterViewInit {
  searchForm: any = {}
  form: any = {}
  dataSource = new MatTableDataSource<any>()
  displayedColumns: string[] = [
    'companyNameTh',
    'companyNameEn',
    'action',
  ]
  uId: number
  userName:string
  companyId : number
  url: any;
  buttonList: any[];
  userToken:any;

  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private appConfigRelationshipService: AppConfigRelationshipService,
    private route: ActivatedRoute,
    private router: Router,
    private appMenuService: AppMenuService,
    private appUserService: AppUserService,
    private confirmDialogService: ConfirmDialogService,
    private alertMessageService: AlertMessageService,
    private _authService:AuthService,
  ) { }

  async getRouter(): Promise<void> {
    this.url = this.router.url;

    const formData = {
      url: this.url,
      userGroupCode: this.userToken.userGroupCode,
      userRoleCode: this.userToken.userRoleCode,
      userCode: this.userToken.userCode
    };
    this.buttonList = await this.appMenuService.getButtonPermissionPerPages(formData);
  }

  async ngOnInit() {
    this.userToken = JSON.parse(
      this._authService.loginUser
    ) 

    await this.getRouter();
    this.searchForm.nameSearch = ''
    this.uId = this.userToken.uId;
    this.userName = this.userToken.userName; 
    this.companyId = this.userToken.cpId;
    this.loadDataList()
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`)
    } else {
      this._liveAnnouncer.announce('Sorting cleared')
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }

  applyFilterName(event: Event) {
    const filterValueName = (event.target as HTMLInputElement).value
    this.dataSource.filter = filterValueName.trim().toLowerCase()
  }

  addRelationship(e) {
    const dialogRef = this.dialog.open(ConfigRelationshipPopupComponent, {
      disableClose: false,
      width: '60vw',
      data: {
        EXID: this.companyId,
        ID: e == null ? 0 : e.id,
        companyNameTh: e == null ? '' : e.companyNameTh,
        companyNameEn: e == null ? '' : e.companyNameEn,
        userName:this.userName
      },
      maxWidth: 'none',
    })

    dialogRef.afterClosed().subscribe((res) => {
      if (res){
        this.loadDataList()
      }
    })
  }

  stopPropagation(event) {
    event.stopPropagation()
  }
  // menuOpen() {}
  menuClosed() {

  }

  searchOption() {
    this.dataSource.filter = this.searchForm.searchName.trim().toLowerCase()
    this.searchForm.searchName = ''
  }

  loadDataList() {
    const data = {
      ID: 0,
      EXID: this.companyId,
      SHID: 0,
      isActive: '',
    }


    this.appConfigRelationshipService
      .getListRelationship(data)
      .then((res: any) => {
        if (res === 'error') {
          return
        } else {
          let a = res.map((item) => {
            switch (item.isActive) {
              case 'Y':
                item.isActive = 'เปิดใช้งาน'
                break
              case 'N':
                item.isActive = 'ปิดใช้งาน'
                break
            }
            return item
          })
          this.dataSource = new MatTableDataSource(a)
          this.dataSource.paginator = this.paginator
          this.dataSource.sort = this.sort
          this.searchForm.search = ''
        }
      })
  }

  delete(e) {
    this.confirmDialogService.open({ header: "", content: "คุณต้องการลบข้อมูลนี้หรือไม่?" }).then(res => {
      if (res == false) {
        return
      } else {
        const request: IAppDeleteRelationship = {
          id: e.id,
          isActive: 'N',
          uId: this.uId,
          userName: this.userName
        }
        this.appConfigRelationshipService
          .deleteRelationship(request)
          .then((res: any) => {
            if (res === 'error') {
              return
            } else {
              this.alertMessageService.open({message:"ลบข้อมูลสำเร็จ", title:""}).then((res) => {
                this.loadDataList()
              })
            }
          })
      }
    })
  }
  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList?.find(e => e.buttonCode == buttonCode);

    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }
}
