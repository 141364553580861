import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { environment } from 'environments/environment'
import { reject } from 'lodash'
import { BehaviorSubject, map, Observable } from 'rxjs'
import { ApiMasterService } from '../api/api-master.service'
import { IApiMtCountryListResponse } from 'app/business/interface/api/api-master.interface'

@Injectable({
  providedIn: 'root',
})
export class AppMasterService {
  testList: BehaviorSubject<any[]>
  clientList: BehaviorSubject<IApiMtCountryListResponse[]>
  constructor(
    private apiMasterService: ApiMasterService,
    private httpClient: HttpClient,
    private router: Router,
  ) {
    this.testList = new BehaviorSubject(new Array<any>())
  }

  login(requestData: any): Promise<any> {
    return new Promise((resolve) => {
      this.apiMasterService.login(requestData).subscribe((res) => {
        // 
        resolve(res)
      })
    })
  }
  // tslint:disable-next-line:typedef
  logout(user) {
    this.apiMasterService.logOutTcc(user).subscribe((res) => {
      this.router.navigate(['/sign-in'])
    })
  }

  getTestList(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getTestList(request).subscribe((res) => {
        this.testList.next(res)
        resolve(res)
      })
    })
  }

  uploadFile(formData: FormData): Observable<any> {
    const url = `${environment.api}/api/uploadFile`
    return this.httpClient.post(url, formData).pipe(map((res) => res['items']))
  }

  uploadFileBatch(formData: FormData): Observable<any> {
    const url = `${environment.api}/api/uploadBatchImage`
    return this.httpClient.post(url, formData).pipe(map((res) => res['items']))
  }


  getListCountry(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getListCountry(request).subscribe((res) => {
        resolve(res)
      })
    })
  }

  getListDeclarator(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getListDeclarator(request).subscribe((res) => {
        resolve(res)
      })
    })
  }

  getListBranch(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getListBranch(request).subscribe((res) => {
        resolve(res)
      })
    })
  }

  getListDoc(): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getListDoc().subscribe((res) => {
        resolve(res)
      })
    })
  }
  getListNameLastName(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getListNameLastName(request).subscribe((res) => {
        resolve(res)
      })
    })
  }

  getAuthorizedPersonList(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getAuthorizedPersonList(request).subscribe((res) => {
        resolve(res)
      })
    })
  }
  getListVesselType(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getListVesselType(request).subscribe((res) => {
        resolve(res)
      })
    })
  }

  getListUnit(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getListUnit(request).subscribe((res) => {
        resolve(res)
      })
    })
  }

  getListWeight(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getListWeight(request).subscribe((res) => {
        resolve(res)
      })
    })
  }

  getListCfDocSubtitle(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getListCfDocSubtitle(request).subscribe((res) => {
        resolve(res)
      });
    });
  }

  getListCountryManage(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getListCountryManage(request).subscribe((res) => {
        resolve(res)
      });
    });
  }

  getListCountryGroupManage(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getListCountryGroupManage(request).subscribe((res) => {
        resolve(res)
      });
    });
  }

  getListCoBranchManage(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getListCoBranchManage(request).subscribe((res) => {
        resolve(res)
      });
    });
  }

  saveUnit(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.saveUnit(request).subscribe((res) => {
        resolve(res)
      })
    })
  }

  saveWeight(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.saveWeight(request).subscribe((res) => {
        resolve(res)
      })
    })
  }

  saveCfDocSubtitle(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.saveCfDocSubtitle(request).subscribe((res) => {
        resolve(res)
      });
    });
  }

  saveCountry(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.saveCountry(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  saveCountryGroup(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.saveCountryGroup(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  saveCoBranch(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.saveCoBranch(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getUserManage(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getUserManage(request).subscribe((res) => {
        resolve(res)
      })
    })
  }

  getUserAddress(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getUserAddress(request).subscribe((res) => {
        resolve(res)
      })
    })
  }

  getListAttachType(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getListAttachType(request).subscribe((res) => {
        resolve(res)
      })
    })
  }

  getListCfDocType(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getListCfDocType(request).subscribe((res) => {
        resolve(res)
      })
    })
  }

  saveAttachType(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.saveAttachType(request).subscribe((res) => {
        resolve(res)
      })
    })
  }

  saveCfDocType(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.saveCfDocType(request).subscribe((res) => {
        resolve(res)
      })
    })
  }
  getExchangeRate(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getExchangeRate(request).subscribe((res) => {
        resolve(res)
      })
    })
  }

  saveExchangeRate(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.saveExchangeRate(request).subscribe((res) => {
        resolve(res)
      })
    })
  }

  getHarmonizedCode(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getHarmonizedCode(request).subscribe((res) => {
        resolve(res)
      })
    })
  }

  exchangeratemaxdate(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.exchangeratemaxdate(request).subscribe((res) => {
        resolve(res)
      })
    })
  }

  getMarkType(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getMarkType(request).subscribe((res) => {
        resolve(res)
      })
    })
  }

  getFieldType(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getFieldType(request).subscribe((res) => {
        resolve(res)
      })
    })
  }

  getMarkDetail(request:any):any{
    return new Promise((resolve,reject)=>{
      this.apiMasterService.getMarkDetail(request).subscribe((res)=>{
        resolve(res)
      })
    })
  }


  getListCompany():any{
    return new Promise((resolve,reject)=>{
      this.apiMasterService.getListCompany().subscribe((res)=>{
        resolve(res)
      })
    })
  }

  getMasterProvince(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getMasterProvince(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  updateUnitBatch(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.updateUnitBatch(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  updateWeightBatch(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.updateWeightBatch(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  updateAttachTypeBatch(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.updateAttachTypeBatch(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  updateCfDocTypeBatch(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.updateCfDocTypeBatch(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  updateCfSubtitleBatch(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.updateCfSubtitleBatch(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  updateCountryBatch (request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.updateCountryBatch(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getMasterFeeRateList(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getMasterFeeRateList(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  saveFee(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.saveFee(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  updateFeeBatch(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.updateFeeBatch(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getMasterBankCode(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getMasterBankCode(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  saveBankCode(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.saveBankCode(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  updateBankCodeBatch(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.updateBankCodeBatch(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  saveProvince(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.saveProvince(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  updateProvinceBatch(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.updateProvinceBatch(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getMasterDistrictList(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getMasterDistrictList(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  saveDistrict(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.saveDistrict(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  updateDistrictBatch(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.updateDistrictBatch(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getMasterSubDistrictList(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getMasterSubDistrictList(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  saveSubDistrict(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.saveSubDistrict(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  updateSubDistrictBatch(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.updateSubDistrictBatch(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getMasterKindOfExportationList(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getMasterKindOfExportationList(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  saveMasterKindOfExportation(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.saveMasterKindOfExportation(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  updateKindExportBatch(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.updateKindExportBatch(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getMarkTypeHeaderList(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getMarkTypeHeaderList(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getMasterFieldType(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getMasterFieldType(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  saveMarktypeHeader(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.saveMarktypeHeader(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getMarktypeDetailList(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getMarktypeDetailList(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getMasterBankAccount(request:any):any{
    return new Promise((resolve,reject)=>{
      this.apiMasterService.getMasterBankAccount(request).subscribe((res)=>{
        resolve(res)
      })
    })
  }
  getListMstStatus():any{
    return new Promise((resolve,reject)=>{
      this.apiMasterService.getListMstStatus().subscribe((res)=>{
        resolve(res)
      })
    })
  }
  getListMstGroupOfCountry():any{
    return new Promise((resolve,reject)=>{
      this.apiMasterService.getListMstGroupOfCountry().subscribe((res)=>{
        resolve(res)
      })
    })
  }
  getMasterStatus(request:any):any{
    return new Promise((resolve,reject)=>{
      this.apiMasterService.getMasterStatus(request).subscribe((res)=>{
        resolve(res)
      })
    })
  }
  
  getListMstCoBranch():any{
    return new Promise((resolve,reject)=>{
      this.apiMasterService.getListMstCoBranch().subscribe((res)=>{
        resolve(res)
      })
    })
  }
  getListMstAttachType():any{
    return new Promise((resolve,reject)=>{
      this.apiMasterService.getListMstAttachType().subscribe((res)=>{
        resolve(res)
      })
    })
  }
  getListMstCountry():any{
    return new Promise((resolve,reject)=>{
      this.apiMasterService.getListMstCountry().subscribe((res)=>{
        resolve(res)
      })
    })
  }

  saveMarktypeDetail(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.saveMarktypeDetail(request).subscribe((res) => {
        resolve(res);
      })
    })
  }

  getCompanyAddressType(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getCompanyAddressType(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getMrConnectParameter(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getMrConnectParameter(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  saveMrConnectParameter(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.saveMrConnectParameter(request).subscribe((res) => {
        resolve(res);
      });
    });
  }
  
  getHarmonizedItemList(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getHarmonizedItemList(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  saveHarmonizedItem(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.saveHarmonizedItem(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getTaskToVerifyList(): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getTaskToVerifyList().subscribe((res)=>{
        resolve(res)
      })
    });
  }
  getMstConfigByHCode(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getMstConfigByHCode(request).subscribe((res) => {
        resolve(res);
      });
    });
  }
  getMstConfigByHCodeApprove(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getMstConfigByHCodeApprove(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getMarkTypeListByType(request :any) : Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getMarkTypeListByType(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getMasterStatusList(request: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getMasterStatusList(request).subscribe((res: any) => {
        resolve(res);
      })
    })
  }

  getValidateInput(request: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getValidateInput(request).subscribe((res: any) => {
        resolve(res);
      })
    })
  }

  getActionStatus(request: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getActionStatus(request).subscribe((res: any) => {
        resolve(res);
      })
    })
  }

  getIsPaymentByCompany(request: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getIsPaymentByCompany(request).subscribe((res: any) => {
        resolve(res);
      })
    })
  }

  getCompanyExporterByShipping(request: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getCompanyExporterByShipping(request).subscribe((res: any) => {
        resolve(res);
      })
    })
  }

  uploadImageToBlobs(formData: FormData): Observable<any> {
    const url = `${environment.api}/api/uploadImageToBlobs`;
    return this.httpClient.post(url, formData)
      .pipe(map(res => res['items']));
  }

  getConfigDByHCode(request: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getConfigDByHCode(request).subscribe((res: any) => {
        resolve(res);
      })
    })
  }
  getListMstGroupProduct(): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getListMstGroupProduct().subscribe((res) => {
        resolve(res);
        });
    });
  }
}
