import { LiveAnnouncer } from '@angular/cdk/a11y';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IReqSaveVerifyReprint } from 'app/business/interface/api/api-co.interface';
import { AppPrintService } from 'app/business/service/app/app-print.service';
import { ReprintPopupInquiryComponent } from '../reprint-popup-inquiry/reprint-popup-inquiry.component';

@Component({
  selector: 'app-reprint-verify-modal',
  templateUrl: './reprint-verify-modal.component.html',
  styleUrls: ['./reprint-verify-modal.component.css']
})
export class ReprintVerifyModalComponent implements OnInit {

  searchForm: any = {
    certificateNo: '',
    endDate: null,
    startDate: null,
    isRqStatus: null,
    receiptNo: '',
    requestNo: '',
    invoiceReference: '',
  }
  displayedColumns: string[] = [
    'requestNo',
    'invoiceRefNo',
    'requestDatetime',
    'certNo',
    'paymentStatus',
    'action',
  ]
  searchString: string;
  apiRequest: any;
  apiResponse: any[];
  statusControl = new FormControl('')
  exporterControl = new FormControl('')

  exporterOptions: string[]
  dataSource = new MatTableDataSource<any>()
  selection = new SelectionModel<any>(true, []);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  formData: any = {};

  buttonList: any[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef: MatDialogRef<ReprintPopupInquiryComponent>,
    private dialog: MatDialog,
    private _liveAnnouncer: LiveAnnouncer,
    private appPrintService: AppPrintService,
  ) {
    if (data) {
      this.formData = data;
    } else {
      this.formData.isActive = 'Y';
    }
  }

  ngOnInit() {
    this.loadData(this.apiRequest);
  }
  ngAfterViewInit() {
  }
  loadData(request: any) {
    
  }
  saveSearch() {
  }

  print() {

  }
  showDetail() {

  }
  deleteItem() {
    this.dialogRef.close()
  }

  save() {
    var req = {} as IReqSaveVerifyReprint;
    const request = {
      ...this.formData,
    }
    
    if (request != undefined && request != null) {
      req.docId = request.docId;
      req.hasPayment = this.formData.hasPayment;
      req.remark = this.formData.staffRemark;
    }
    
    this.appPrintService.saveVerifyReprint(req).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        this.dialogRef.close(res);
      }
    })
  }
  editItem(e) {
    this.formData = e;
  }

  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find(e => e.buttonCode == buttonCode);

    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }
}
