import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IApiSaveUserGroupRequest, IApiSaveUserRequest, IApiSaveUserRoleRequest } from 'app/business/interface/api/api-user.interface';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { AppMenuService } from 'app/business/service/app/app-menu.service';
import { AppUserService } from 'app/business/service/app/app-user.service';
import { UserRoleModalComponent } from '../user-role-modal/user-role-modal.component';
import { UserModalComponent } from '../user-modal/user-modal.component';
import { UserGroupModalComponent } from '../user-group-modal/user-group-modal.component';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
  selector: 'app-config-user-all',
  templateUrl: './config-user-all.component.html',
  styleUrls: ['./config-user-all.component.css']
})
export class ConfigUserAllComponent implements OnInit {
  userFilter: any;
  userProfile: any;
  userToken: any;
  userRoleFilter: any;
  userGroupFilter: any;
  menuPermissionFilter: any;
  buttonPermissionFilter: any;
  actionMenuItem: any;
  actionMenuTo: any;
  url;
  buttonList: any[] = [];
  constructor(
    private appUserService: AppUserService,
    private dialog: MatDialog,
    private appMasterService: AppMasterService,
    private appMenuService: AppMenuService,
    private _authService:AuthService,
    private router: Router
  ) {
  }

  async ngOnInit() {
    this.userToken = JSON.parse(
      this._authService.loginUser
    )

    await this.getRouter();
  
    //this.getDataUserList();
    this.getUserGroupList();
    this.getUserRoleList();
    // this.getClientList();

    this.setSubcribe();
  }
  setSubcribe(): void {    
    
    const formData = {
      username: this.userToken.userName,
      uId: this.userToken.uId ? this.userToken.uId : this.userToken.uid,
    };

    this.appUserService.getUserProfile(formData).then(res => {
      this.userProfile = res;
    });
  }
  // Call Api
  getDataUserList(): void {
    this.appUserService.getUserList({
      cilentCode: '',
      userName: '',
      name: '',
      isActive: '',
      userRoleCode: '',
      userCode: '',
      userGroupCode: '',
      cpId: parseInt(this.userToken.cpId),
      pagination : {
        Skip:0,
        Take: 10,
      }
    });
  }
  async getRouter(): Promise<void> {
    this.url = this.router.url;

    const formData = {
      url: this.url,
      userGroupCode: this.userToken.userGroupCode,
      userRoleCode: this.userToken.userRoleCode,
      userCode: this.userToken.userCode
    };
    this.buttonList = await this.appMenuService.getButtonPermissionPerPages(formData);

  }
  getUserRoleList(): void {
    const searchData = {
      gId : this.userToken.gId,
      cpId : this.userToken.cpId
    };
    this.appUserService.getUserRoleList(searchData);
  }

  getUserGroupList(): void {
    const searchData = {
      userGroupId : this.userToken.gId
    };
    this.appUserService.getUserGroupList(searchData);
  }

  // getClientList(): void {
  //   this.appMasterService.getMtClientList();
  // }

  // Menu Function
  dataMenuPermissionFilterEmit(data: any): void {
    this.menuPermissionFilter = data;
  }

  // Button Function
  dataButtonPermissionFilterEmit(data: any): void {
    this.buttonPermissionFilter = data;
  }

  // User Function
  addUser(): void {
    const userModalRef = this.dialog.open(UserModalComponent, {
      disableClose: true,
      width: '60vw',
      data: {
        userId: 0,
        userCode: '',
        userName: '',
        userGroupCode: '',
        userRoleCode: '',
        clientCode: '',
        empNo: '',
        name: '',
        email: '',
        isActive: 'Y',
        statusLogin: 'OFF',
      } as IApiSaveUserRequest
    });

    userModalRef.afterClosed().subscribe(res => {
      this.getDataUserList();
    });
  }
  dataFormFilterEmit(data: any): void {
    this.userFilter = data;
  }

  // User role Function
  addUserRole(): void {
    const userModalRef = this.dialog.open(UserRoleModalComponent, {
      disableClose: true,
      width: '60vw',
      data: {
        userRoleId: 0,
        userRoleCode: '',
        roleName: '',
        isActive: 'Y',
        createBy: '',
        updateBy: ''
      } as IApiSaveUserRoleRequest
    });

    userModalRef.afterClosed().subscribe(res => {
      this.getUserRoleList();
    });
  }
  dataFormRoleFilterEmit(data: any): void {
    this.userRoleFilter = data;
  }

  // User Group Function
  addUserGroup(): void {
    const userModalRef = this.dialog.open(UserGroupModalComponent, {
      disableClose: true,
      width: '60vw',
      data: {
        userGroupId: 0,
        userGroupCode: '',
        groupName: '',
        isActive: 'Y',
        createBy: '',
        updateBy: ''
      } as IApiSaveUserGroupRequest
    });

    userModalRef.afterClosed().subscribe(res => {
      this.getUserGroupList();
    });
  }

  dataFormGroupFilterEmit(data: any): void {
    this.userGroupFilter = data;
  }
  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList?.find(e => e.buttonCode == buttonCode);

    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }

  checkAccessTab(groupCode: string) {
    if (groupCode !== "1" && groupCode !== "4" && groupCode !== "5") {
      if (this.userProfile?.isRoleAdmin === "Y") {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  checkAccessGroupTab(groupCode: string) {
    if (groupCode !== "1" && groupCode !== "4" && groupCode !== "5") {
      return false;
    } else {
      return true;
    }
  }
}
