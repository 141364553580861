<mat-card
    style="display: flex;flex-flow: column;height: 100%;gap: 30px; justify-content: stretch; padding-bottom: 0px;">
    <div style="display: flex;flex-flow: column;margin-top: 30px;" class="pl-10 pr-10">
        <div class="menu">
            <div style="display: flex;flex-flow: row;gap: 10px;align-items: stretch;">
                <div *ngIf="checkButton('BT133')">
                    <button class="set-btn-unlimited" mat-raised-button color="accent"
                        (click)="addExchangeRate()">Add</button>
                </div>
                <div *ngIf="checkButton('BT134')">
                    <button class="set-btn-unlimited" mat-raised-button color="warn"
                        (click)="deleteSelection()">Delete</button>
                </div>
            </div>
            <div style="display: flex;flex-flow: row;gap: 10px;flex: 1;justify-content: flex-end;">
                <div class="mat-button" style="gap: 10px;flex: 0 40%;">
                    <mat-form-field style="display: flex;flex-flow: row;flex: 1" class="my-form-field">
                        <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" matInput #input
                        [(ngModel)]="searchForm.search" name="search" #search="ngModel" (keyup)="applyFilter($event)">
                        <button matSuffix mat-icon-button aria-label="ค้นหา">
                            <mat-icon>search</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
                <button mat-raised-button color="accent" [matMenuTriggerFor]="menu">
                    <div style="display: flex;align-items: center;">
                        <span>เงื่อนไขการค้นหา</span>
                        <mat-icon>expand_more</mat-icon>
                    </div>
                </button>
                <mat-menu #menu="matMenu" yPosition="below" xPosition="before" class="my-menu">
                    <div style="display: flex;flex-flow: column;gap: 15px;" class="pl-2 pr-2"
                        (click)="stopPropagation($event)">
                        <div class="mat-button p-2" style="gap: 10px;">
                            <mat-form-field appearance="fill" style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>วันที่เริ่ม | Start Date</mat-label>
                                <input matInput [matDatepicker]="createDate" type="datetime"
                                    [(ngModel)]="searchForm.startDate" name="startDateSearch" #startDateSearch="ngModel"
                                    (click)="createDate.open()" readonly class="cursor-pointer">
                                <mat-hint>MM/DD/YYYY</mat-hint>
                                <mat-datepicker-toggle matIconSuffix [for]="createDate"></mat-datepicker-toggle>
                                <mat-datepicker #createDate></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field appearance="fill" style="display: flex;flex-flow: row;flex: 1">
                                <mat-label>วันที่แก้ไข | Modify Date</mat-label>
                                <input matInput [matDatepicker]="updateDate" type="datetime"
                                    [(ngModel)]="searchForm.updateDate" name="updateDateSearch"
                                    #updateDateSearch="ngModel" (click)="updateDate.open()" readonly
                                    class="cursor-pointer">
                                <mat-hint>MM/DD/YYYY</mat-hint>
                                <mat-datepicker-toggle matIconSuffix [for]="updateDate"></mat-datepicker-toggle>
                                <mat-datepicker #updateDate></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="flex justify-end pt-2">
                            <div>
                                <button mat-raised-button (click)="clearOption()"
                                    style="flex:0 50%; margin-right: 10px;">
                                    เคลียร์
                                </button>
                                <button mat-raised-button color="accent" (click)="saveSearch()" style="flex:0 50%;">
                                    ค้นหา
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-menu>
            </div>
        </div>
    </div>
    <div class="mat-elevation-z8 " style="margin-bottom: 2.5em;">
        <table mat-table matSort [dataSource]="exchangeRateList" (matSortChange)="announceSortChange($event)">
            <ng-container matColumnDef="select">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left">
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                        Select
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                </td>
            </ng-container>
            <ng-container matColumnDef="currency">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left;">Currency </th>
                <td mat-cell *matCellDef="let element"> {{element.currency}} </td>
            </ng-container>

            <ng-container matColumnDef="rate">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left;">Rate</th>
                <td mat-cell *matCellDef="let element"> {{element.rate}} </td>
            </ng-container>
            <ng-container matColumnDef="startDate">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left;">Start Date</th>
                <td mat-cell *matCellDef="let element"> {{element.startDate | date:'yyyy-MM-dd HH:mm'}} </td>
            </ng-container>
            <ng-container matColumnDef="modifyDate">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left;">Modify Date</th>
                <td mat-cell *matCellDef="let element"> {{element.modifyDate | date:'yyyy-MM-dd HH:mm'}} </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style="text-align: left;"> Action </th>
                <td mat-cell *matCellDef="let element;let i = index" style="text-align: left;">
                    <div class="flex flex-row" style="justify-content: start;">
                        <div *ngIf="checkButton('BT135')">
                            <button mat-icon-button matTooltip="แก้ไข" (click)="editItem(element)"
                                matTooltipPosition="above">
                                <mat-icon svgIcon="heroicons_outline:pencil-alt"></mat-icon>
                            </button>
                        </div>
                        <div *ngIf="checkButton('BT134')">
                            <button mat-icon-button matTooltip="ลบ" (click)="deleteItem(element)"
                                matTooltipPosition="above">
                                <mat-icon svgIcon="heroicons_outline:trash"></mat-icon>
                            </button>
                        </div>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons
            aria-label="Select page of periodic elements">
        </mat-paginator>
    </div>

</mat-card>