<!-- Template Management -->
<mat-card class="flex flex-col flex-1">
  <mat-card-header>
      <div class="text-center">ข้อมูลบันทึกช่วยจำ </div>
  </mat-card-header>
  <mat-card-content>
      <section class="px-4 flex flex-col flex-1 py-4 gap-4">
        <div class="flex gap-4 ">
          <div class="flex flex-row gap-2.5 basis-1/2 items-center">
            <div div class="basis-1/4">
              <div class="flex-column" style="margin-top: 24px">
                <mat-label> บันทึกช่วยจำ </mat-label>
              </div>
              <div class="flex-column" style="color: #9ca3af">
                <mat-label> Meno </mat-label>
              </div>
            </div>
            <div div class="basis-3/4">
              <mat-label class="pr-2.5"> : </mat-label>
              <mat-label>
                {{ memotext }}
              </mat-label>
            </div>
          </div>
        </div>
      </section>
  </mat-card-content>

</mat-card>