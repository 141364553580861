import { LiveAnnouncer } from '@angular/cdk/a11y'
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core'
import { MatSort, Sort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { FormControl } from '@angular/forms'
import {
  GetListCORequest,
  IcoStatus,
  IgetListCORequest,
} from 'app/business/interface/app/app-co.interface'
import { AppCOService } from 'app/business/service/app/app-co.service'
import { IgetListCOResponse } from 'app/business/interface/api/api-co.interface'
import { MatPaginator } from '@angular/material/paginator'
import moment from 'moment'
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'
import { MatDialog } from "@angular/material/dialog";
import { ConfirmPopupComponent } from "../confirm-popup/confirm-popup.component";
import { AppRequestService } from "app/business/service/app/app-request.service";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { ConfirmDialogService } from '@bundle/component/confirm-dialog/confirm-dialog.service'
import { Router } from '@angular/router'
import { AppUserService } from 'app/business/service/app/app-user.service'
import { AppMenuService } from 'app/business/service/app/app-menu.service'
import { PrintPopupInquiryComponent } from '../list-print-inquiry/print-popup-inquiry/print-popup-inquiry.component'
import { AppPrintService } from 'app/business/service/app/app-print.service'
import { AppMasterService } from 'app/business/service/app/app-master.service'
import { ListCoSendPrintToStaffPopupComponent } from '../list-co-inquiry/list-co-send-print-to-staff-popup-inquiry/list-co-send-print-to-staff-popup-inquiry.component'
import { AuthService } from 'app/core/auth/auth.service'

@Component({
  selector: 'app-list-cf-inquiry',
  templateUrl: './list-cf-inquiry.component.html',
  styleUrls: ['./list-cf-inquiry.component.scss'],
})
export class ListCfInquiryComponent implements OnInit {
  dataSource = new MatTableDataSource<any>()
  myDate = new Date()
  displayedColumns: string[] = [
    'createDate',
    'requestNo',
    // 'queueNumber',
    'docType',
    'docPriority',
    'invoiceReference',
    'isRqStatus',
    'companyName',
    'certificateNo',
    'receiptNo',
    'action',
  ]
  searchForm: any = {
    certificateNo: '',
    endDate: null,
    startDate: null,
    isRqStatus: null,
    receiptNo: '',
    requestNo: '',
    invoiceReference: '',
  }
  searchString: string
  apiRequest: IgetListCORequest = {
    UID: 0,
    userName: '',
    requestNo: '',
    certificateNo: '',
    receiptNo: '',
    payReferenceNo: '',
    invoiceReferenceNo: '',
    documentReferenceNo: '',
    isRqstatus: '',
    docType: 'CF',
    docId: 0,
  }
  apiResponse: IgetListCOResponse[]
  statusControl = new FormControl('')
  exporterControl = new FormControl('')
  statusOptions: any[] = [];
  exporterOptions: string[]
  count: number = 0

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  userProfile: any = {};

  checkEndDate: boolean = true;
  checkStartDate: boolean = true;
  url: any;
  buttonList: any[] = [];
  timer: any;
  userToken:any;
  totalPageCF: number;
  docHid: number;
  isBlacklisted: boolean = false;
 
  packIdList: any[] = [];

  public timerInterval: any;
  isShowQ: boolean = false;
  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private appCOService: AppCOService,
    private alertService: AlertMessageService,
    private dialog: MatDialog,
    private appRequestService: AppRequestService,
    private confirmService: ConfirmDialogService,
    private router: Router,
    private appUserService: AppUserService,
    private appMenuService: AppMenuService,
    private appPrintService: AppPrintService,
    private appMasterService: AppMasterService,
    private _authService:AuthService,
  ) { }

  
  async getRouter(): Promise<void> {
    
    this.url = this.router.url;
    let profile = JSON.parse(this._authService.loginUser);
    profile  = {
        ...profile,
        gId:profile.gid,
        uId:profile.uid,
    }

    const formData = {
      url: this.url,
      userGroupCode: profile?.userGroupCode,
      userRoleCode: profile?.userRoleCode,
      userCode: profile?.userCode
    };
    this.buttonList = await this.appMenuService.getButtonPermissionPerPages(formData);
    
  }

  async ngOnInit() {
    this.userToken = JSON.parse(this._authService.loginUser);
    
    await this.getRouter();

    this.isBlacklisted = this.userToken.isBlacklisted;

    this.userProfile.uId = this.userToken.uId
    this.userProfile.cid = this.userToken.cpId
    this.userProfile.userName = this.userToken.name
    this.apiRequest.UID = this.userProfile.uId
    this.apiRequest.userName = this.userProfile.userName
    this.dataSource.paginator = this.paginator
    this.apiRequest.pagination = {
      Skip:
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
      Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
    }
    this.userProfile.gId = this.userToken.gId
    if(this.userProfile.gId == "2"){

      this.displayedColumns=[
        'createDate',
        'requestNo',  
        // 'queueNumber',
        'docType',
        'docPriority',
        'invoiceReference',
        'isRqStatus',
        'certificateNo',
        'receiptNo',
        'action',
      ]
    }
    this.loadMaster();
    this.loadData(this.apiRequest)
  }

  loadMaster() {
    this.appMasterService.getMasterStatusList("CO").then(res => {
      if (res === 'error') {
        this.statusOptions = [];
      } else {
        this.statusOptions = res;

        const checkAll = this.statusOptions.find(e => e.statusNameTh === "ทั้งหมด");

        if (checkAll === undefined) {
          this.statusOptions.unshift({ statusId: 1, statusNameTh: "ทั้งหมด" })
        }
      }
    })
  }

  ngAfterViewInit() {
    this.paginator.pageIndex = 0
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
  }
  async loadData(request: IgetListCORequest) {
    this.apiRequest.keyword = typeof (this.apiRequest.keyword) !== "string" ? "" : this.apiRequest.keyword;
    let rqStatus = parseInt(this.apiRequest.isRqstatus);

    // Reset to page 1 before calling the API
    if (this.paginator != undefined && this.paginator != null) {
      this.paginator.pageIndex = 0
    }

    let reqList = {
      uId: this.userProfile.uId,
      gId: this.userProfile.gId,
      compId: this.apiRequest.companyCode,
      compName: this.apiRequest.companyName,
      endDate: this.apiRequest.endDate,
      refNo: this.apiRequest.invoiceReferenceNo, //ถ้าไม่เช็คมันจะเอาไป where แบบ and มันจะพัง
      reqNo: this.apiRequest.requestNo, //ถ้าไม่เช็คมันจะเอาไป where แบบ and มันจะพัง
      rqStatus: isNaN(rqStatus) ? null : rqStatus,
      thtNo: this.apiRequest.certificateNo, //ถ้าไม่เช็คมันจะเอาไป where แบบ and มันจะพัง
      startDate: this.apiRequest.startDate,
      keyword: this.apiRequest.keyword,
      pagination: {
        Skip: 0, // Start from page 1 (skip 0)
        Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
      }
    } as GetListCORequest
    await this.appCOService.getListCF(reqList).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        
        if (res !== null) {
          let a = res.response.map((item) => {
            moment.locale('th') // set default locale to Thai
            item.createDate = moment(item.createDate).format('YYYY-MM-DD HH:mm')
            if (
              item.queueNumber != null &&
              item.queueNumber != undefined &&
              item.queueNumber != ''
            ) {
              item.queueNumber = parseInt(item.queueNumber.slice(-4))
            }
            return item
          })
          this.dataSource.data = a
          this.count = res.totalCount
        } else {
          this.dataSource.data = []
          this.count = 0
        }
      }
    })
    this.dataSource.paginator.length = this.count
  }
  // applyFilter(event: string) {
  //   // this.apiRequest.UID = this.userProfile.uId
  //   // this.apiRequest.certificateNo = this.searchForm.certificateNo
  //   // this.apiRequest.endDate = this.searchForm.endDate
  //   // this.apiRequest.startDate = this.searchForm.startDate
  //   // this.apiRequest.docType = 'CO'
  //   // this.apiRequest.isRqstatus = this.searchForm.status
  //   // this.apiRequest.receiptNo = event
  //   // this.apiRequest.requestNo = event
  //   // this.apiRequest.invoiceNo = event
  //   this.apiRequest.keyword = event
  //   this.dataSource.paginator = this.paginator
  //   this.loadData(this.apiRequest)
  // }

  applyFilter(event: string) {
    clearTimeout(this.timer); // Clear any existing timer

    this.timer = setTimeout(() => {
      // This code will be executed after a specified delay (e.g., 500 milliseconds)
      
      // Send the value to the backend or perform any other necessary actions
      this.apiRequest.keyword = event;
      this.dataSource.paginator = this.paginator
      this.loadData(this.apiRequest)

    }, 500); // Specify the desired delay in milliseconds
  }

  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`)
    } else {
      this._liveAnnouncer.announce('Sorting cleared')
    }
  }
  stopPropagation(event) {
    event.stopPropagation()
  }
  saveSearch() {
    this.apiRequest.keyword = "";
    this.searchString = "";
    if (
      moment(this.searchForm.startDate).format('YYYY-MM-DD') >
      moment(this.searchForm.endDate).format('YYYY-MM-DD')
    ) {
      this.alertService.open({
        message: 'กรุณาระบุวันที่สิ้นสุดให้มากกว่าวันที่เริ่มต้น',
        title: 'กรุณากรอกข้อมูลค้นหาให้ถูกต้อง',
      })
      return false
    }
    this.apiRequest.UID = this.userProfile.uId;
    this.apiRequest.certificateNo = this.searchForm.certificateNo;
    this.apiRequest.endDate = this.searchForm.endDate;
    this.apiRequest.startDate = this.searchForm.startDate;
    this.apiRequest.docType = "CF";
    this.apiRequest.isRqstatus = this.searchForm.status == "1" ? null : this.searchForm.status;
    this.apiRequest.receiptNo = this.searchForm.receiptNo;
    this.apiRequest.requestNo = this.searchForm.requestNo;
    this.apiRequest.invoiceReferenceNo = this.searchForm.invoiceReferenceNo;
    this.apiRequest.documentReferenceNo = this.searchForm.invoiceReference;
    this.dataSource.paginator = this.paginator;
    this.checkEndDate = true;
    this.loadData(this.apiRequest);
  }

  onRefresh() {
    this.loadData(this.apiRequest);
  }
  
  showDetail() { }
  deleteItem(e) {
    if (this.isBlacklisted) {
      this.alertService.open({ message: "ไม่สามารถลบรายการ CF ได้ เนื่องจากบริษัทของคุณติดสถานะ Blacklist", title: "" });
    } else {
      var actionReq = {
        id: e.packId,
        action: "DELETE",
        refTable: "DOCUMENT_REQUEST_OBJ"
      };

      this.appMasterService.getActionStatus(actionReq).then(res => {
        if (res === 'error') {
          return;
        } else {
          var data = res;

          if (data.allowAction === 'Y') {

            const reqData = {
              packId: e.packId,
            }
            this.confirmService
              .open({
                header: 'ยืนยันการทำรายการ',
                content: 'ยืนยันการลบข้อมูลนี้ใช่หรือไม่?',
              })
              .then((res) => {
                if (res == true) {
                  this.appRequestService.deleteCF(reqData).then((res: any) => {
                    if (res === 'error') {
                      return
                    } else {
                      this.alertService
                        .open({ title: 'ทำรายการสำเร็จ', message: 'ลบข้อมูลสำเร็จ' })
                        .then((res) => {
                          this.loadData(this.apiRequest)
                        })
                    }
                  })
                }
              });
          } else {
            this.alertService.open({ message: "ไม่สามารถลบรายการได้ เนื่องจากเอกสารถูกลบในระบบแล้ว", title: "" }).then(res => {
              this.loadData(this.apiRequest);
              return;
            });
          }
        }
      });
    }
  }
  editItem() { }

  async _page(e) {
    this.apiRequest.pagination = {
      Skip: (!e.pageIndex ? 0 : e.pageIndex) * (!e.pageSize ? 0 : e.pageSize),
      Take: !e.pageSize ? 10 : e.pageSize,
    }
    this.apiRequest.keyword = typeof (this.apiRequest.keyword) !== "string" ? "" : this.apiRequest.keyword;
    let rqStatus = parseInt(this.apiRequest.isRqstatus);
    let reqList = {
      uId: this.userProfile.uId,
      gId: this.userProfile.gId,
      compId: this.apiRequest.companyCode,
      compName: this.apiRequest.companyName,
      endDate: this.apiRequest.endDate,
      refNo: this.apiRequest.invoiceReferenceNo, //ถ้าไม่เช็คมันจะเอาไป where แบบ and มันจะพัง
      reqNo: this.apiRequest.requestNo, //ถ้าไม่เช็คมันจะเอาไป where แบบ and มันจะพัง
      rqStatus: isNaN(rqStatus) ? null : rqStatus,
      thtNo: this.apiRequest.certificateNo, //ถ้าไม่เช็คมันจะเอาไป where แบบ and มันจะพัง
      startDate: this.apiRequest.startDate,
      keyword: this.apiRequest.keyword,
      pagination: {
        Skip: (!e.pageIndex ? 0 : e.pageIndex) * (!e.pageSize ? 0 : e.pageSize),
        /*(!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),*/
        Take: (!e.pageSize ? 10 : e.pageSize), //!this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
      }
    } as GetListCORequest
    await this.appCOService.getListCF(reqList).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        if (res.response !== null) {
          let a = res.response.map((item) => {
            // item.createDate = new Date(item.createDate)
            //   .toISOString()
            //   .split('T')[0]
            item.createDate = moment(item.createDate).format('YYYY-MM-DD HH:mm')
            if (
              item.queueNumber != null &&
              item.queueNumber != undefined &&
              item.queueNumber != ''
            ) {
              item.queueNumber = parseInt(item.queueNumber.slice(-4))
            }
            return item
          })

          this.dataSource.data = a
          if (this.isShowQ == true) {
            this.getQueue();
          } else {
            this.displayedColumns = [
              'createDate',
              'requestNo', 
              'docType',
              'docPriority',
              'invoiceReference',
              'isRqStatus',
              'companyName',
              'certificateNo',
              'receiptNo',
              'action',
            ];
          }
        } else {
          this.dataSource = new MatTableDataSource((res.response3 = []))
        }
        this.count = res.totalCount
      }
    })
    this.dataSource.paginator.length = this.count
    this.dataSource.paginator.pageIndex = e.pageIndex
  }

  cancel(e) {
    if (this.isBlacklisted) {
      this.alertService.open({ message: "ไม่สามารถยกเลิกคำขอส่งตรวจได้ เนื่องจากบริษัทของคุณติดสถานะ Blacklist", title: "" });
    } else {
      var actionReq = {
        id: e.packId,
        action: "UNSEND",
        refTable: "DOCUMENT_REQUEST_OBJ"
      };

      this.appMasterService.getActionStatus(actionReq).then(res => {
        if (res === 'error') {
          return;
        } else {
          var data = res;

          if (data.allowAction === 'Y') {
            const data = {
              docId: e.docId,
              packId: e.packId,
              queueId: e.queueId,
              isRqStatus: '34',
            }

            this.confirmService
              .open({
                header: 'ยืนยันการทำรายการ',
                content: 'ต้องการยกเลิกคำขอรายการนี้ใช่หรือไม่?',
              })
              .then((res) => {
                if (res == true) {
                  this.appCOService.cencelVerify(data).then((res) => {
                    if (res == 'error') {
                      return
                    } else {
                      this.alertService
                        .open({ title: 'ทำรายสำเร็จ', message: 'ยกเลิกคำขอสำเร็จ' })
                        .then((res) => {
                          this.dataSource.paginator = this.paginator
                          this.loadData(this.apiRequest)
                        })
                    }
                  })
                }
              })
          } else {
            this.alertService.open({ message: "ไม่สามารถยกเลิกส่งตรวจได้ เนื่องจากสถานะเอกสารถูกเปลี่ยนในระบบแล้ว", title: "" }).then(res => {
              this.loadData(this.apiRequest);
              return;
            });
          }
        }
      });
    }
  }

  clearOption() {
    this.searchForm = {};
    this.apiRequest = {
      UID: 0,
      userName: "",
      requestNo: "",
      certificateNo: "",
      receiptNo: "",
      payReferenceNo: "",
      invoiceNo: "",
      documentReferenceNo: "",
      isRqstatus: "",
      docType: "CO",
      docId: 0,
    };

    this.checkEndDate = true;
    this.loadData(this.searchForm);
  }

  datecheck(e: MatDatepickerInputEvent<Date>) {
    if (
      moment(this.searchForm.startDate).format('YYYY-MM-DD') >
      moment(this.searchForm.endDate).format('YYYY-MM-DD')
    ) {
      this.searchForm.endDate = {};
      this.checkEndDate = false;
    }
  }

  endDateCheck(e) {
    if (
      moment(this.searchForm.startDate).format('YYYY-MM-DD') ==
      moment(this.searchForm.endDate).format('YYYY-MM-DD') ||
      moment(this.searchForm.startDate).format('YYYY-MM-DD') <
      moment(this.searchForm.endDate).format('YYYY-MM-DD')
    ) {
      this.checkEndDate = true;
    }
  }

  createCf() {
    if (this.isBlacklisted) {
      this.alertService.open({ message: "ไม่สามารถสร้าง CF ใหม่ได้ เนื่องจากบริษัทของคุณติดสถานะ Blacklist", title: "" });
    } else {
      this.router.navigateByUrl('/page/request/request-cf/create-cf');
    }
  }

  editCf(e) {
    if (this.isBlacklisted) {
      this.alertService.open({ message: "ไม่สามารถแก้ไข CF ได้ เนื่องจากบริษัทของคุณติดสถานะ Blacklist", title: "" });
    } else {
      var actionReq = {
        id: e.packId,
        action: "EDIT",
        refTable: "DOCUMENT_REQUEST_OBJ"
      };

      this.appMasterService.getActionStatus(actionReq).then(res => {
        if (res === 'error') {
          return;
        } else {
          var data = res;

          if (data.allowAction === 'Y') {
            this.router.navigate(['/page/request/request-cf/create-cf'], {
              queryParams: {
                id: e.packId,
                disabled: undefined
              }
            });
          } else {
            this.alertService.open({ message: "ไม่สามารถแก้ไข CF ได้ เนื่องจากสถานะเอกสารถูกเปลี่ยนในระบบแล้ว", title: "" }).then(res => {
              this.loadData(this.apiRequest);
              return;
            });
          }
        }
      });
    }
  }


  async printCFPopup(e) {
    if (this.isBlacklisted) {
      this.alertService.open({ message: "ไม่สามารถพิมพ์เอกสาร CF ได้ เนื่องจากบริษัทของคุณติดสถานะ Blacklist", title: "" });
    } else {
      var actionReq = {
        id: e.packId,
        action: "PRINT",
        refTable: "DOCUMENT_REQUEST_OBJ"
      };

      this.appMasterService.getActionStatus(actionReq).then(async res => {
        if (res === 'error') {
          return;
        } else {
          var data = res;

          if (data.allowAction === 'Y') {
            if (e.printStatus == "Y") {
              this.alertService.open({
                title: "ผิดพลาด",
                message:
                  "คุณได้ดำเนินการพิมพ์เอกสารไปแล้ว กรุณาติดต่อเจ้าหน้าที่ หรือทำการขอ Reprint ได้ใหม่โดยการเข้าไปที่เมนู Print > Reprint",
              }).then((x) => {
                this.dialog.closeAll();
              });
            } else {
              if (e.selfPrint != "Y") {
                this.alertService
                  .open({ title: 'Error Message', message: 'ท่านได้แจ้งความประสงค์ให้ทางหอการค้าไทยพิมพ์เอกสาร' })
                  .then((res) => {
                  })
              }
              else {
                let data = {
                  docId: e.packId
                }
                await this.appCOService.getCfTotalPage(data).then((x) => {
                  this.docHid = x?.docId;
                  this.totalPageCF = x?.totalPage;
                });
                let dataPopup = {
                  id: e.packId,
                  totalPage: this.totalPageCF ? this.totalPageCF : 0,
                  hid: this.docHid,
                  rqNo: e.requestNo
                }
                const dialogRef = this.dialog.open(PrintPopupInquiryComponent, {
                  disableClose: false,
                  width: "60vw",
                  maxHeight: "100vh",
                  data: dataPopup,
                  maxWidth: "none",
                });
                dialogRef.afterClosed().subscribe((res) => {
                  if (res) {
                    if (res.data.checkPrint.filter(Boolean).length !== 0) {
                      const data = {
                        uid: this.userToken.uId,
                        Hid: res.data.hid,
                        id: res.data.id,
                        checkPrint: res.data.checkPrint,
                      };
                      this.appPrintService.getPrintOriginalCF(data).then((res: any) => {
                        if (res === "error") {
                          return;
                        } else {
                          this.dataSource.paginator = this.paginator;
                          this.loadData(this.apiRequest);
                          window.open(res.linkUrl, "_blank");
                        }
                      });
                    } else {
                      this.alertService.open({
                        title: "กรุณากรอกข้อมูลให้ครบถ้วน",
                        message: "กรุณาเลือกตำแหน่งที่ต้องการพิมพ์",
                      }).then((x) => {
                        this.dialog.closeAll();
                      });
                    }
                  }
                });
              }
            }
          } else {
            this.alertService.open({ message: "ไม่สามารถพิมพ์เอกสารได้ เนื่องจากเอกสารได้ถูกพิมพ์แล้ว", title: "" }).then(res => {
              this.loadData(this.apiRequest);
              return;
            });
          }
        }
      });
    }
  }
  openPopupSendPrintTostaff(e) {
    if (this.isBlacklisted) {
      this.alertService.open({ message: "ไม่สามารถสั่งพิมพ์เอกสารที่หอการค้าไทยได้ เนื่องจากบริษัทของคุณติดสถานะ Blacklist", title: "" });
    } else {

      var actionReq = {
        id: e.packId,
        action: "PRINT",
        refTable: "DOCUMENT_REQUEST_OBJ"
      };

      this.appMasterService.getActionStatus(actionReq).then(res => {
        if (res === 'error') {
          return;
        } else {
          var data = res;

          if (data.allowAction === 'Y') {
            let data = {
              packId: e.packId,
              requestNo: e.requestNo,
              docType: e.docType,
              expressType: e.expressType,
              consignor: e.consignor
            }
            
            const dialogRef = this.dialog.open(ListCoSendPrintToStaffPopupComponent, {
              disableClose: false,
              width: '70vw',
              data: data,
              maxWidth: 'none',
            });
            dialogRef.afterClosed().subscribe((res) => {
              if (!res) {
                return
              } else {
                this.loadData(this.apiRequest);
                return
              }
            });
          } else {
            this.alertService.open({ message: "ไม่สามารถสั่งพิมพ์เอกสารได้ เนื่องจากได้ยื่นเรื่องให้หอการค้าไทยพิมพ์เอกสารแล้ว", title: "" }).then(res => {
              this.loadData(this.apiRequest);
              return;
            });
          }
        }
      });
    }
  }

  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find(e => e.buttonCode == buttonCode);

    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }

  
  getQueue() {
    this.isShowQ = true;
    this.timers(1);
    if (this.isShowQ) {
      this.displayedColumns = [
        'createDate',
        'requestNo',
        'queueNumber',
        'docType',
        'docPriority',
        'invoiceReference',
        'isRqStatus',
        'companyName',
        'certificateNo',
        'receiptNo',
        'action',
      ];
      this.getQueuePackId();
    } else {
      this.displayedColumns = [
        'createDate',
        'requestNo', 
        'docType',
        'docPriority',
        'invoiceReference',
        'isRqStatus',
        'companyName',
        'certificateNo',
        'receiptNo',
        'action',
      ];
    }
  }

  timers(minute) {
    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec: number = 60;

    const prefix = minute < 10 ? "0" : "";

    this.timerInterval = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;
      if (seconds == 0) {
        console.log("finished");
        this.isShowQ = false;
        this.displayedColumns = [
          'createDate',
          'requestNo', 
          'docType',
          'docPriority',
          'invoiceReference',
          'isRqStatus',
          'companyName',
          'certificateNo',
          'receiptNo',
          'action',
        ];
        clearInterval(this.timerInterval);
      }
    }, 1000);
  }

  getQueuePackId() {
    this.packIdList = [];
    this.dataSource.data.forEach((element) => {
      this.packIdList.push(element.packId);
    });
    const req = this.dataSource.data.map((m) => {
      return {
        docPriority: m.priorityId,
        packId: m.packId,
      };
    });
    this.appCOService.getQueueByPackId({ docPriorities: req }).then((res) => { 
      let a = [];
      res.forEach((ele) => {
        a = this.dataSource.data.map((m) => {
          if (ele.packId === m.packId) {
            m.queueNumber = ele.queue != 0 ? ele.queue : "";
          }
          return m;
        });
      }); 
      this.dataSource.connect().next(a);   
    });
  }
}
