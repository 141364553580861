<mat-card>
    <mat-card-header>
        <div class="flex justify-between" style="width: 100%;">
            <div class="text-lg">Add Relationship </div>
            <div>
                <button   class="close-button" [mat-dialog-close]="true">
                    <mat-icon class="text-white" [mat-dialog-close]>cancel</mat-icon>
                </button>
            </div>
        </div>
    </mat-card-header>
    <mat-dialog-content class="mat-typography">
        <div class="pl-5  flex flex-row justify-center gap-3">
            <div class="flex-1 flex flex-col">
                <h4>บริษัทตัวแทน หรือ ชิปปิ้ง</h4>
                <h4>Shipping</h4>
            </div>
            <div class="flex-auto flex flex-row gap-3">
                <div class="flex-none">:</div>
                <!-- <div [formGroup]="searchShipperForm" class="flex-1"> -->
                <mat-form-field [formGroup]="searchShipperForm" class="flex-1">
                    <input type="text" formControlName="shipperSearch" matInput [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                        <mat-option *ngFor="let options of filteredOptions | async" [value]="options">
                            {{options.companyNameTh}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <!-- </div> -->
            </div>

        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <button mat-raised-button color="accent" (click)="save()">บันทึก</button>
    </mat-dialog-actions>
</mat-card>