<mat-card>
  <mat-card-header>
    <div class="flex justify-between" style="width: 100%;">
      <div class="text-lg"> การโอนงานให้เจ้าหน้าที่คนอื่น </div>
      <div>
        <button class="close-button" [mat-dialog-close]="true">
          <mat-icon class="text-white" [mat-dialog-close]>cancel</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-header>

  <mat-dialog-content class="mat-typography">

    <div class="flex flex-col justify-center gap-3">
      <!-- line 1 -->
      <div class="flex mt-4 gap-3 flex-col">
        <div class="flex flex-row items-center">
          <div class="flex w-1/4">
            <h4>เลขที่คำขอ</h4>
          </div>
          <mat-form-field class="flex-auto">
            <input matInput placeholder="เลขที่คำขอ" [(ngModel)]="form.requestNo" [ngModelOptions]="{standalone: true}"
              disabled>
          </mat-form-field>
        </div>
        <!-- line 2 -->
        <div class="flex flex-row items-center">
          <div class="flex w-1/4">
            <h4>ผู้ตรวจสอบคนก่อน</h4>
          </div>
          <mat-form-field class="flex-auto">
            <input matInput placeholder="ผู้ตรวจสอบคนก่อน" [(ngModel)]="form.getTaskFromVerifyName"
              [ngModelOptions]="{standalone: true}" disabled>
          </mat-form-field>
        </div>
        <!-- line 3 -->
        <div class="flex flex-row items-center">
          <div class="flex w-1/4">
            <h4>ผู้ตรวจสอบคนถัดไป</h4>
          </div>
          <mat-form-field class="flex-auto">
            <mat-select [(ngModel)]="form.getTaskToVerifyId" [ngModelOptions]="{standalone: true}">
              <mat-option *ngFor="let verifyList of userVeerify" [value]="verifyList.userId">
                {{verifyList.staffName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>



      </div>
    </div>
    <div class="flex flex-row justify-center mt-4 gap-4">
      <button class="set-btn-unlimited flex-none " mat-raised-button color="accent" (click)="close()">ยกเลิก</button>
      <button class="set-btn-unlimited flex-none " mat-raised-button color="accent" (click)="save()">บันทึก</button>
    </div>
  </mat-dialog-content>
</mat-card>