import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { MarkTypeHeaderPopupComponent } from '../../mark-type-header-inquiry/mark-type-header-popup/mark-type-header-popup.component';
import { AppUserService } from 'app/business/service/app/app-user.service';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.css']
})
export class PasswordChangeComponent implements OnInit {
  form: any = {};
  bankList: any;
  userProfile:any;
  attachType: any[] = [];
  fieldType: any[] = [];
  buttonList: any[] = [];
  constructor(
    public dialogRef: MatDialogRef<MarkTypeHeaderPopupComponent>,
    private appMasterService: AppMasterService,
    private dialog: MatDialog,
    private alertMessageService: AlertMessageService,
    private appUserService: AppUserService,
    private _authService:AuthService,
  ) {
  }

  ngOnInit(): void {

  }

  save() {

    this.userProfile = JSON.parse(
      this._authService.loginUser
    );
    var reqData = {
      uId: this.userProfile.uId,
      newPassword: this.form.password
    };

    this.appUserService.saveNewPassword(reqData).then((res: any) => {
      if (res === 'error') {
        this.alertMessageService.open({message: "เกิดข้อผิดพลาดระหว่างบันทึกข้อมูล", title: ""});
      } else {
        this.dialogRef.close(true);
        this.alertMessageService.open({message: "เปลี่ยนรหัสผ่านสำเร็จ", title:""});
      }
    })
  }
}
