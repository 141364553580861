import { Injectable } from '@angular/core';
import { ApiService } from '@bundle/service/api.service';
import { IApiGetUserGroupListRequest, IApiGetUserListRequest, IApiGetUserRoleListRequest, IApiSaveUserRequest, IApiSaveUserRoleRequest } from 'app/business/interface/api/api-user.interface';
import { IAppUserGroupResponse, IAppUserRoleResponse } from 'app/business/interface/app/app-user.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiUserService {

  constructor(private apiService: ApiService) { }

  getUserManage(any): Observable<any[]> {
    return this.apiService.post("/api/getUserManage", any);
  }
  saveUserActivityLog(any): Observable<any[]> {
    return this.apiService.post("/api/saveUserActivityLog", any);
  }
  getEmail(any): Observable<any[]> {
    return this.apiService.post("/api/getEmail", any);
  }

  saveEmail(any): Observable<any[]> {
    return this.apiService.post("/api/saveEmail", any);
  }

  checkToken(): Observable<boolean> {
    return this.apiService.get('/api/checkToken');
  }

  getUserList(data: IApiGetUserListRequest): Observable<any[]> {
    return this.apiService.post('/api/getUserList', data);
  }

  getUserDropdownList(data: IApiGetUserListRequest): Observable<any[]> {
    return this.apiService.post('/api/getUserDropdownList', data);
  }
  
  getUserRoleList(data: IApiGetUserRoleListRequest): Observable<IAppUserRoleResponse[]> {
    return this.apiService.post('/api/getMstUserRoleList', data);
  }

  getUserGroupList(data: IApiGetUserGroupListRequest): Observable<IAppUserGroupResponse[]> {
    return this.apiService.post('/api/getMstUserGroupList', data);
  }

  saveUserRole(data: IApiSaveUserRoleRequest): Observable<any> {
    return this.apiService.post('/api/saveUserRole', data);
  }

  saveUserGroup(data: any): Observable<any> {
    return this.apiService.post('/api/saveUserGroup', data);
  }

  saveUser(data: IApiSaveUserRequest): Observable<any> {
    return this.apiService.post('/api/saveUser', data);
  }

  getUserProfile(data: any): Observable<any> {
    return this.apiService.post('/api/getUserProfile', data);
  }

  getUserAll(): Observable<any[]> {
    return this.apiService.get("/api/getUserAll");
  }

  getUserVerify(request): Observable<any[]> {
    return this.apiService.post("/api/getUserVerify", {
      fillter: request
    });
  }

  getUserPassword(request: any): Observable<any> {
    return this.apiService.post("/api/getUserPassword", request);
  }

  saveNewPassword(request: any): Observable<any> {
    return this.apiService.post("/api/saveNewPassword", request);
  }
}
