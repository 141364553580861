import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiUserService } from '../api/api-user.service';
import { Router } from '@angular/router';
import { IAppGetMtUserListResponse, IAppUserGroupResponse, IAppUserRoleResponse } from 'app/business/interface/app/app-user.interface';
import { AuthService } from 'app/core/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AppUserService {
  userList: BehaviorSubject<IAppGetMtUserListResponse[]>;
  userDdList: BehaviorSubject<IAppGetMtUserListResponse[]>;
  userRoleList: BehaviorSubject<IAppUserRoleResponse[]>;
  userGroupList: BehaviorSubject<IAppUserGroupResponse[]>;
  constructor(
    private apiUserService: ApiUserService,
    private _authService:AuthService,
    private router: Router,
  ) { 
    this.userList = new BehaviorSubject(new Array<IAppGetMtUserListResponse>());
    this.userDdList = new BehaviorSubject(new Array<IAppGetMtUserListResponse>());
    this.userRoleList = new BehaviorSubject(new Array<IAppUserRoleResponse>());
    this.userGroupList = new BehaviorSubject(new Array<IAppUserGroupResponse>());
  }

  getUserManage(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiUserService.getUserManage(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getEmail(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiUserService.getEmail(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  saveEmail(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiUserService.saveEmail(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  loadToken(): string {
    let token = '';
    const session = this._authService.token;// localStorage.getItem('token');
    if (session) {
      token = session;
    }
    return token;
  }

  logout() {
    this.clearToken().then(() => {
      this.router.navigate(['/login']);
    });
  }

  clearToken() {
    return new Promise((resolve) => {
      this._authService.clear();// localStorage.clear();
    });
  }

  setSessionToken(sessionToken: string): void {
    this._authService.token = sessionToken; // localStorage.setItem('token', sessionToken);
  }

  checkToken(): Promise<boolean> {
    return new Promise((resolve) => {
      this.apiUserService.checkToken()
        .subscribe(res => {
          resolve(res);
        });
    });
  }

  // getUserList(requestData: any): Promise<any[]> {
  //   return new Promise((resolve) => {
  //     this.apiUserService.getUserList(requestData).subscribe(res => {
  //       // 
  //       const result = res?.map((item, i) => {
  //         return {
  //           ...item,
  //           status: item.isActive === 'Y' ? 'ใช้งาน' : 'ยกเลิก',
  //           index: i + 1,
  //           // createBy: 'test (02/11/2020)',
  //           // updateBy: 'test1 (02/11/2020)'
  //         };
  //       });
  //       this.userList.next(result);
  //       resolve(res);
  //     });
  //   });
  // }

  // getUserList(requestData: any): Promise<any[]> {
  //   return new Promise((resolve) => {
  //     this.apiUserService.getUserList(requestData).subscribe(res => {
  //       resolve(res);
  //     });
  //   });
  // }
  getUserList(reqData: any): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.apiUserService.getUserList(reqData).subscribe(res => {
        //this.saleOrderList.next(res);
        resolve(res);
      });
    });
  }

  getUserDropdownList(reqData: any): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.apiUserService.getUserDropdownList(reqData).subscribe((res) => {
        this.userDdList.next(res);
        resolve(res);
      })
    })
  }

  getSessionUserProfile(): any {
    // debugger
    return this._authService.userProfile;// localStorage.getItem('UserProfile');
  }
  saveUserActivityLog(requestData: any): Promise<any[]> {
    return new Promise((resolve) => {
      this.apiUserService.saveUserActivityLog(requestData).subscribe(res => {
        // 
        resolve(res);
      });
    });
  }

  getUserRoleList(requestData: any): Promise<any[]> {
    return new Promise((resolve) => {
      this.apiUserService.getUserRoleList(requestData).subscribe(res => {
        // 
        const result = res?.map((item, i) => {
          return {
            ...item,
            status: item.isActive === 'Y' ? 'ใช้งาน' : 'ยกเลิก',
            index: i + 1
          };
        });
        this.userRoleList.next(result);
        resolve(res);
      });
    });
  }
  getUserGroupList(requestData: any): Promise<any[]> {
    return new Promise((resolve) => {
      this.apiUserService.getUserGroupList(requestData).subscribe(res => {
        const result = res?.map((item, i) => {
          return {
            ...item,
            status: item.isActive === 'Y' ? 'ใช้งาน' : 'ยกเลิก',
            index: i + 1
          };
        });
        this.userGroupList.next(result);
        resolve(res);
      });
    });
  }

  saveUserRole(requestData: any): Promise<any> {
    return new Promise((resolve) => {
      this.apiUserService.saveUserRole(requestData).subscribe(res => {
        // 
        resolve(res);
      });
    });
  }

  saveUserGroup(requestData: any): Promise<any> {
    return new Promise((resolve) => {
      this.apiUserService.saveUserGroup(requestData).subscribe(res => {
        // 
        resolve(res);
      });
    });
  }

  saveUser(requestData: any): Promise<any> {
    return new Promise((resolve) => {
      this.apiUserService.saveUser(requestData).subscribe(res => {
        // 
        resolve(res);
      });
    });
  }
  
  getUserProfile(requestData: any): Promise<any> {
    return new Promise((resolve) => {
      this.apiUserService.getUserProfile(requestData).subscribe(res => {
        // 
        resolve(res);
      });
    });
  }
  getUserAll(): any {
    return new Promise((resolve, reject) => {
      this.apiUserService.getUserAll().subscribe((res) => {
        resolve(res);
      });
    });
  }
  
  getUserVerify(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiUserService.getUserVerify(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getUserPassword(request: any) {
    return new Promise((resolve, reject) => {
      this.apiUserService.getUserPassword(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  saveNewPassword(request: any) {
    return new Promise((resolve, reject) => {
      this.apiUserService.saveNewPassword(request).subscribe((res) => {
        resolve(res);
      })
    })
  }
}

