<mat-card>
  <mat-card-header>
    <div class="flex justify-between" style="width: 100%;">
      <div class="text-lg"> เปลี่ยนรหัสผ่าน </div>
      <div>
        <button class="close-button" [mat-dialog-close]="true">
          <mat-icon class="text-white" [mat-dialog-close]>cancel</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-dialog-content>
    <div class="pl-16 pr-16 flex flex-col gap-4">
      <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
        <div class="mat-popup-header flex flex-col" style="width:35%">
          <h4>รหัสผ่านใหม่ <span style="color:red;">*</span></h4>
          <h4>New Password</h4>
        </div>
        <div class="mat-popup flex flex-row justify-between items-center" style="width:75%; gap: 10px;">
          <div>:</div>
          <mat-form-field class="w-full">
            <input id="password" matInput type="password" [(ngModel)]="form.password" #passwordField>
            <button mat-icon-button type="button"
              (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'"
              matSuffix>
              <mat-icon class="icon-size-5" *ngIf="passwordField.type === 'password'" [svgIcon]="'eye-open'"></mat-icon>
              <mat-icon class="icon-size-5" *ngIf="passwordField.type === 'text'" [svgIcon]="'eye-close'"></mat-icon>
            </button>
          </mat-form-field>
          <div class="flex flex-row">  
            <label *ngIf="form.password?.length > 0 && form.password?.length < 8" class = "required position-absolute">กรุณาระบุรหัสผ่านอย่างน้อย 8 หลัก</label>
          </div>
        </div>
      </div>

      <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
        <div class="mat-popup-header flex flex-col" style="width:35%">
          <h4>ยืนยันรหัสผ่าน <span style="color:red;">*</span></h4>
          <h4>Confirm New Password</h4>
        </div>
        <div class="mat-popup flex flex-row justify-between items-center" style="width:75%; gap: 10px;">
          <div>:</div>
          <mat-form-field class="w-full">
            <input id="password" matInput type="password" [(ngModel)]="form.repassword" #repasswordField>
            <button mat-icon-button type="button"
              (click)="repasswordField.type === 'password' ? repasswordField.type = 'text' : repasswordField.type = 'password'"
              matSuffix>
              <mat-icon class="icon-size-5" *ngIf="repasswordField.type === 'password'" [svgIcon]="'eye-open'"></mat-icon>
              <mat-icon class="icon-size-5" *ngIf="repasswordField.type === 'text'" [svgIcon]="'eye-close'"></mat-icon>
            </button>
          </mat-form-field>
          <div class="flex flex-row">  
            <label *ngIf="(form.repassword?.length > 0) && (form.repassword !== form.password)" class = "required absolute-line-re">รหัสผ่านไม่ตรงกัน</label>
          </div>
        </div>
      </div>

      <div class="flex-row flex justify-center gap-2.5" style="gap: 10px;">
        <button mat-raised-button color="accent" (click)="save()" [disabled]="!(form.repassword === form.password && form.password?.length >= 8)">บันทึก</button>
        <button mat-raised-button [mat-dialog-close]>ยกเลิก</button>
      </div>
    </div>
  </mat-dialog-content>