import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AppBankService } from 'app/business/service/app/app-bank.service';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { ConfirmPopupComponent } from '../../confirm-popup/confirm-popup.component';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'

@Component({
  selector: 'app-bank-code-manage-popup',
  templateUrl: './bank-code-manage-popup.component.html',
  styleUrls: ['./bank-code-manage-popup.component.css']
})
export class BankCodeManagePopupComponent implements OnInit {
  form: any = {};
  bankList: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef: MatDialogRef<BankCodeManagePopupComponent>,
    private appMasterService: AppMasterService,
    private dialog: MatDialog,
    private alertMessageService: AlertMessageService
  ) {
    if (data) {
      this.form = data;
    } else {
      this.form.isActive = 'Y';
      this.form.enableFlag = 'Y'
    }
  }
  ngOnInit(): void {

  }

  save() {
    if ((this.form.name !== undefined && this.form.name !== null && this.form.name !== "") && (this.form.code !== undefined && this.form.code !== null && this.form.code !== "")) {
      const data = {
        "id": this.form.id,
        "code": this.form.code,
        "name": this.form.name,
        "enableFlag": this.form.enableFlag,
        "isActive": this.form.isActive
      };

      this.appMasterService.saveBankCode(data).then((res: any) => {
        if (res === "error") {
          this.alertMessageService.open({message: "บันทึกข้อมูลไม่สำเร็จ", title:""});
        } else {
          this.dialogRef.close();
          this.alertMessageService.open({message: "บันทึกข้อมูลสำเร็จ", title:""});
        }
      });
    } else {
      this.alertMessageService.open({ message: "กรุณากรอกข้อมูลให้ครบ", title: "ข้อผิดพลาด" });
    }
  }

  onChange(e) {
    if (e.checked == true) {
      this.form.isActive = "Y";
    } else {
      this.form.isActive = "N";
    }
  }

  onChangeFlag(e) {
    if (e.checked == true) {
      this.form.enableFlag = "Y";
    } else {
      this.form.enableFlag = "N";
    }
  }

  endPopup(e) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      disableClose: false,
      // width: '30vw',
      data: {
        header: e,
        button: 'close'
      },
      maxWidth: 'none'
    });

    dialogRef.afterClosed().subscribe(res => {
      this.dialogRef.close();
    });
  }
}
