import { LiveAnnouncer } from '@angular/cdk/a11y'
import { Component, OnInit, ViewChild, Inject } from '@angular/core'
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort, Sort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { Router } from '@angular/router'
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'
import { IGetListCreatePopupVerifyRecheckRequest } from 'app/business/interface/app/app-verify-interface'
import { AppRecheckService } from 'app/business/service/app/app-recheck.service'
import { AppCOService } from 'app/business/service/app/app-co.service'

@Component({
  selector: 'app-list-co-print-popup-inquiry',
  templateUrl: './list-co-print-popup-inquiry.component.html',
  styleUrls: ['./list-co-print-popup-inquiry.component.css']
})
export class ListCoPrintPopupInquiryComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort

  displayedColumns: string[] = [
    'thNumber',
    'consigneeName',
    'invoiceStatus',
    'invoiceNoAndInvoiceDate',
    'action',
  ]

  dataSource = new MatTableDataSource<any>()
  count: number
  form: any = {};
  searchString: string
  requestNo: string
  docType: string
  expressType: string
  packId: number
  statusHeader: number = 0
  listPrintCo: boolean = false
  rqstatusObj: number = 0

  constructor(
    public dialogRef: MatDialogRef<ListCoPrintPopupInquiryComponent>,
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private router: Router,
    private appRecheckService: AppRecheckService,
    private appCOService: AppCOService,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.requestNo = data.requestNo;
    this.docType = data.docType;
    this.expressType = data.expressType;
    this.packId = data.packId;
    this.listPrintCo = data.listPrintCo == null ? false : data.listPrintCo;
    this.rqstatusObj = data.rqstatusObj;
  }
  ngOnInit() {
    this.dataSource.paginator = this.paginator
    this.loadData()
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
  }

  async loadData() {
    const request = {
      packId: this.packId
    }
    await this.appCOService.getListPrintCoPack(request).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        this.dataSource = new MatTableDataSource(res);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    })
  }


  applyFilter(event: string) {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`)
    } else {
      this._liveAnnouncer.announce('Sorting cleared')
    }
  }
  createReprint(e) {
    this.dialogRef.close(e.hId)
  }

  printDraf(e) {
    const data = {
      Hid: e.headerId,
      isCoPack: "Y"
    }
    this.appCOService.getPreviewCOPack(data).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        window.open(res.linkUrl, '_blank')
        this.loadData()
      }
    })
  }

  printReal(e) {
    const data = {
      Hid: e.headerId,
      isCoPack: "Y"
    }
    this.appCOService.getPrintOriginal(data).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        window.open(res.linkUrl, '_blank')
        this.loadData()
      }
    })
  }
}
