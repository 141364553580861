import { Component, Inject, ViewChild } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { AppMasterService } from 'app/business/service/app/app-master.service'
import { DxDataGridComponent } from 'devextreme-angular'

@Component({
  selector: 'app-draft-cf-popup',
  templateUrl: './draft-cf-popup.component.html',
  styleUrls: ['./draft-cf-popup.component.scss'],
})
export class DraftCFPopupComponent {
  dataSource: any = []
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent
  form: any = {
    queuePriority: 5,
    docPriority: '5',
  }
  isNS: boolean
  isCheck: boolean = false
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public apiMasterService: AppMasterService,
    public dialogRef: MatDialogRef<DraftCFPopupComponent>,
  ) {
    this.dataSource = data.data
  }
  preview(e) {
    window.open(e.data.data.linkUrl, '_blank')
  }
  save() {
    this.dialogRef.close(this.form)
  }
  cancel() {
    this.dialogRef.close(false)
  }
  // modelChange(e) {
  //   switch (e) {
  //     case true:
  //       this.form.queuePriority = 4
  //       this.form.docPriority = '4'
  //       break
  //     case false:
  //       this.form.queuePriority = 5
  //       this.form.docPriority = '5'
  //       break
  //   }
  // }
}
