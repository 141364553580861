import { Observable } from 'rxjs';
import { ApiService } from '@bundle/service/api.service';
import { Injectable } from '@angular/core';
import { IGetButtonPermissionResponse, IGetComponentPermissionPerPagesResponse, IGetMenuAllResponse, IGetMenuButtomAllResponse, IMenuResponse, IReqGetMenuPersmissionByGroupCode, ISetPermissionMenuRequest } from 'app/business/interface/api/api-menu.interface';
import { IResMenuPersmissionByGroupCode } from 'app/business/interface/app/app-menu.interface';

@Injectable({
    providedIn: 'root'
})
export class ApiMenuService {
    constructor(
        private apiSercive: ApiService
    ) {

    }

    getMenuAll(data: any): Observable<IGetMenuAllResponse>{
        return this.apiSercive.post('/api/getMenuAll', data);
    }

    getMenuPermission(): Observable<IMenuResponse[]> {
        return this.apiSercive.post('/api/getMenuPermission', {});
    }

    getButtonAll(data: any): Observable<IGetMenuButtomAllResponse>{
        return this.apiSercive.post('/api/getButtonAll', data);
    }

    getButtonPermissionPerPages(data: any): Observable<IGetButtonPermissionResponse[]>{
        return this.apiSercive.post('/api/getButtonPermissionPerPages', data);
    }

    savePermissionMenuOrButton(data: ISetPermissionMenuRequest): Observable<any>{
        return this.apiSercive.post('/api/savePermissionMenuOrButton', data);
    }
    
    getComponentPermissionPerPages(data: any): Observable<IGetComponentPermissionPerPagesResponse[]> {
        return this.apiSercive.post('/api/getComponentPermissionPerPages', data);
    }

    GetMenuPersmissionByGroupCode(data: any): Observable<IResMenuPersmissionByGroupCode[]> {
        return this.apiSercive.post('/api/GetMenuPersmissionByGroupCode', data);
    }
}
