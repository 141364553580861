import { LiveAnnouncer } from '@angular/cdk/a11y'
import { SelectionModel } from '@angular/cdk/collections'
import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort, Sort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { ActivatedRoute, Router } from '@angular/router'
import { IgetListCOResponse } from 'app/business/interface/api/api-co.interface'
import {
  IcoStatus,
  IgetListCORequest,
} from 'app/business/interface/app/app-co.interface'
import { AppCOService } from 'app/business/service/app/app-co.service'
import moment from 'moment'
import { ConfirmDialogService } from '@bundle/component/confirm-dialog/confirm-dialog.service'
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'
import { AppMenuService } from 'app/business/service/app/app-menu.service'
import { AppUserService } from 'app/business/service/app/app-user.service'
import { AuthService } from 'app/core/auth/auth.service'
@Component({
  selector: 'app-list-template-inquiry',
  templateUrl: './list-template-inquiry.component.html',
  styleUrls: ['./list-template-inquiry.component.scss'],
})
export class ListTemplateInquiryComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort
  searchString: string
  templateList = new MatTableDataSource<any>()
  displayedColumns: string[] = [
    'createDate',
    'updateDate',
    'tempName',
    'declarator',
    'destination',
    'action',
  ]
  selection = new SelectionModel<any>(true, [])
  searchForm: any = {
    UID: 0,
    CID: 0,
    isActive: 'Y',
    createDate: '',
    tempName: '',
    searchString: '',
    pagination: {
      Skip: 0,
      Take: 10,
    },
  }
  count: number
  url: any;
  buttonList: any[] = [];
  userProfile:any;
  itemList: any[] = [];
  constructor(
    private appCOService: AppCOService,
    private _liveAnnouncer: LiveAnnouncer,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private confirmDialogService: ConfirmDialogService,
    private alertService: AlertMessageService,
    private router: Router,
    private appMenuService: AppMenuService,
    private appUserService: AppUserService,
    private _authService:AuthService,
  ) { }

  async getRouter(): Promise<void> {
    this.url = this.router.url;

    const formData = {
      url: this.url,
      userGroupCode:this.userProfile.userGroupCode,
      userRoleCode: this.userProfile.userRoleCode,
      userCode: this.userProfile.userCode
    };
    this.buttonList = await this.appMenuService.getButtonPermissionPerPages(formData);
  }

  async ngOnInit() {
    this.userProfile = JSON.parse(
      this._authService.loginUser
    )
    await this.getRouter();
    this.searchForm.UID = this.userProfile.uId
    this.searchForm.CID = this.userProfile.cpId
    this.templateList.paginator = this.paginator
    this.searchForm.pagination = {
      Skip:
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
      Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
    }

    this.buttonList.forEach(element => {
      if (element.buttonCode === "BT076") {
        this.displayedColumns.unshift("select");
      }
    });

    this.loadData(this.searchForm)
  }
  ngAfterViewInit() {
    this.templateList.paginator = this.paginator
    this.templateList.sort = this.sort
  }

  async loadData(request) {
    await this.appCOService.getListTemplate(request).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        let a = res.response.map((item) => {
          moment.locale('th')
          item.createDate = moment(item.createDate).format('YYYY-MM-DD')
          item.updateDate = moment(item.updateDate).format('YYYY-MM-DD')
          return item
        })
        this.itemList = a;
        this.templateList = new MatTableDataSource(a);
        this.templateList.paginator = this.paginator;
        this.templateList.sort = this.sort;
      }
    })
  }
  stopPropagation(event) {
    event.stopPropagation()
  }

  applyFilter(filterValue: string) {

    if (filterValue?.length > 0) {
      var res = this.itemList.filter(e => (e.tempName.toLowerCase().includes(filterValue) || e.destination.toLowerCase().includes(filterValue)));

      this.templateList = new MatTableDataSource(res);
      this.templateList.paginator = this.paginator;
      this.templateList.sort = this.sort;
    } else {
      this.templateList = new MatTableDataSource(this.itemList);
      this.templateList.paginator = this.paginator;
      this.templateList.sort = this.sort;
    }
  }
  saveSearch() {
    this.loadData(this.searchForm)
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length
    const numRows = this.templateList.data.length
    return numSelected === numRows
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.templateList.data.forEach((row) => this.selection.select(row))
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`)
    } else {
      this._liveAnnouncer.announce('Sorting cleared')
    }
  }
  deleteItem(e) {
    var request = {
      templateId: [e.id],
      isActive: 'N'
    }
    this.confirmDialogService
      .open({
        header: 'ยืนยันการทำรายการ',
        content: 'ยืนยันการลบข้อมูลนี้ใช่หรือไม่?',
        showCancelBtn: true,
      })
      .then((res) => {
        if (res == true) {
          this.appCOService.saveTemplateStatus(request).then((res: any) => {
            if (res == 'error') {
              this.alertService.open({
                title: 'ผิดพลาด',
                message: 'ลบข้อมูลไม่สำเร็จ',
              })
            } else {
              this.alertService
                .open({ title: 'ทำรายการสำเร็จ', message: 'ลบข้อมูลสำเร็จ' })
                .then((res) => {
                  this.loadData(this.searchForm)
                })
            }
          })
        }
      })
  }
  deleteSelection() {
    let number = 0
    if (this.selection.selected.length !== 0) {
      this.confirmDialogService
        .open({
          header: 'ยืนยันการทำรายการ',
          content: 'ยืนยันการลบข้อมูลนี้ใช่หรือไม่?',
          showCancelBtn: true,
        })
        .then((res) => {
          if (res == true) {
            var request = {
              templateId: this.selection.selected.map(e => e.id),
              isActive: 'N'
            }

            this.appCOService.saveTemplateStatus(request).then((res: any) => {
              if (res == 'error') {
                this.alertService.open({
                  title: 'ผิดพลาด',
                  message: 'ลบข้อมูลไม่สำเร็จ',
                })
              } else {
                this.alertService
                  .open({
                    title: 'ทำรายการสำเร็จ',
                    message: 'ลบข้อมูลสำเร็จ',
                  })
                  .then((res) => {
                    this.ngAfterViewInit();
                    this.loadData(this.searchForm)
                    this.selection.clear(true);
                  });
              }
            });
          }
        })
    } else {
      this.alertService
        .open({
          title: 'กรุณายืนยันการทำรายการ',
          message: 'กรุณาเลือกรายการที่ต้องการลบ',
        })
        .then((res) => {
          return
        })
    }
  }
  clearOption() {
    this.searchForm = {
      UID: 0,
      CID: 0,
      isActive: 'Y',
      createDate: '',
      tempName: '',
      searchString: '',
      pagination: {
        Skip: 0,
        Take: 10,
      },
    }
  }

  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find(e => e.buttonCode == buttonCode);

    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }
}
