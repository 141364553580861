import { LiveAnnouncer } from "@angular/cdk/a11y";
import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import {
  IcoStatus,
  IgetListPrintRequest,
  GetListPrintRequest,
  GetListCORequest,
} from "app/business/interface/app/app-co.interface";
import { AppCOService } from "app/business/service/app/app-co.service";
import { IgetListCOResponse } from "app/business/interface/api/api-co.interface";
import { MatPaginator } from "@angular/material/paginator";
import { AppPrintService } from "app/business/service/app/app-print.service";
import { DxDataGridComponent } from "devextreme-angular";
import { SelectionModel } from "@angular/cdk/collections";
import { MatDialog } from "@angular/material/dialog";
import { PrintPopupInquiryComponent } from "./print-popup-inquiry/print-popup-inquiry.component";
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import moment from "moment";
import { Router } from "@angular/router";
import { AppMenuService } from "app/business/service/app/app-menu.service";
import { AppUserService } from "app/business/service/app/app-user.service";
import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import { ListCoPrintPopupInquiryComponent } from "../list-co-inquiry/list-co-print-popup-inquiry/list-co-print-popup-inquiry.component";
import { PrintCoPackPopupInquiryComponent } from "./print-co-pack-popup-inquiry/print-co-pack-popup-inquiry.component";
import { AppMasterService } from "app/business/service/app/app-master.service";
import { ListCoSendPrintToStaffPopupComponent } from "../list-co-inquiry/list-co-send-print-to-staff-popup-inquiry/list-co-send-print-to-staff-popup-inquiry.component";
import {
  debounceTime,
  map,
  startWith,
  switchMap,
  takeUntil,
} from "rxjs/operators";
import { HttpLoadingService } from "@bundle/service/http-loading.service";
import { of, Subject } from "rxjs";
import { companySearch } from "app/business/interface/app/app-company.interace";
import { AppCompanyService } from "app/business/service/app/app-company.service";
import { AuthService } from "app/core/auth/auth.service";

@Component({
  selector: "app-list-print-inquiry",
  templateUrl: "./list-print-inquiry.component.html",
  styleUrls: ["./list-print-inquiry.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ListPrintInquiryComponent implements OnInit, AfterViewInit {
  searchForm: any = {
    certificateNo: "",
    endDate: null,
    startDate: null,
    isRqStatus: "6",
    receiptNo: "",
    requestNo: "",
    invoiceReference: "",
  };
  displayedColumns: string[] = [
    "requestNo",
    "companyName",
    "invoiceRefNo",
    "requestDatetime",
    "docType",
    "certificateNo",
    "paymentReferenceNo",
    "receiptNo",
    "isRqStatus",
    "expressType",
    "isPrinted",
    "selfPrint",
    "sigBranchName",
    "action",
  ];
  searchString: string;
  apiResponse: any[];
  statusControl = new FormControl("");
  exporterControl = new FormControl("");
  count: any = 0;
  exporterOptions: string[];
  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);
  url: any;
  buttonList: any[] = [];
  apiRequest: IgetListPrintRequest = {
    UID: 0,
    userName: "",
    requestNo: "",
    certificateNo: "",
    receiptNo: "",
    payReferenceNo: "",
    invoiceNo: "",
    documentReferenceNo: "",
    isRqstatus: "6",
    docType: "CO",
    docId: 0,
    selfPrint: "",
    sigBranchId: 0,
    companyCode: null,
    companyName: "",
    payRefNo: "",
  };
  printStatus: number = 1;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  form: any = {};
  timer: any;

  totalPageCF: number;
  docHid: number;
  statusOptions: any[] = [];
  searchCompanyForm: FormGroup;
  valueSelect: boolean = false;
  isLoading: boolean = false;
  filteredOptions$: any = [];
  userProfile: any;
  constructor(
    private _formBuilder: FormBuilder,
    private _liveAnnouncer: LiveAnnouncer,
    private appPrintService: AppPrintService,
    private dialog: MatDialog,
    private AlertMessageService: AlertMessageService,
    private router: Router,
    private appMenuService: AppMenuService,
    private appUserService: AppUserService,
    private alertService: AlertMessageService,
    private confirmService: ConfirmDialogService,
    private appCOService: AppCOService,
    private appMasterService: AppMasterService,
    private httpLoadingService: HttpLoadingService,
    private appCompanyService: AppCompanyService,
    private _authService: AuthService
  ) {}

  async getRouter(): Promise<void> {
    this.url = this.router.url;
    const formData = {
      url: this.url,
      userGroupCode: this.userProfile.userGroupCode,
      userRoleCode: this.userProfile.userRoleCode,
      userCode: this.userProfile.userCode,
    };
    this.buttonList = await this.appMenuService.getButtonPermissionPerPages(
      formData
    );
  }

  async ngOnInit() {
    this.userProfile = JSON.parse(this._authService.loginUser);

    this.searchCompanyForm = this._formBuilder.group({
      companySearch: [""],
    });
    this.valueChange();
    // this.apiRequest.isRqstatus = "6";
    await this.getRouter();
    this.loadMaster();

    this.searchAll();
  }

  loadMaster() {
    this.appMasterService.getMasterStatusList("CO").then((res) => {
      if (res === "error") {
        this.statusOptions = [];
      } else {
        this.statusOptions = res;

        const checkAll = this.statusOptions.find(
          (e) => e.statusNameTh === "ทั้งหมด"
        );

        if (checkAll === undefined) {
          this.statusOptions.unshift({ statusId: 1, statusNameTh: "ทั้งหมด" });
        }
      }
    });
  }

  ngAfterViewInit() {
    this.paginator.pageIndex = 0;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  async loadData(request: IgetListPrintRequest) {
    this.apiRequest.keyword =
      typeof this.apiRequest.keyword !== "string"
        ? ""
        : this.apiRequest.keyword;
    let rqStatus = parseInt(this.apiRequest.isRqstatus);
    // console.log("this.apiRequest", this.apiRequest);
      
    if (this.paginator != undefined && this.paginator != null) {
      this.paginator.pageIndex = 0
    }
  
    let reqList = {
      uId: this.userProfile.uId,
      gId: this.userProfile.gId,
      compId: this.apiRequest.companyCode,
      compName: this.apiRequest.companyName,
      endDate: this.apiRequest.endDate,
      refNo: this.apiRequest.invoiceNo, //ถ้าไม่เช็คมันจะเอาไป where แบบ and มันจะพัง
      reqNo: this.apiRequest.requestNo, //ถ้าไม่เช็คมันจะเอาไป where แบบ and มันจะพัง
      rqStatus: isNaN(rqStatus) ? null : rqStatus,
      thtNo: this.apiRequest.certificateNo, //ถ้าไม่เช็คมันจะเอาไป where แบบ and มันจะพัง
      startDate: this.apiRequest.startDate,
      keyword: this.apiRequest.keyword,
      receiptNo: this.apiRequest.receiptNo,
      payRefNo: this.apiRequest.payRefNo,
      pagination: {
        Skip: 0,
        Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
      },
      selfPrint: request.selfPrint != null ? request.selfPrint : null,
      sigBranchId: request.sigBranchId != 0 ? request.sigBranchId : 0,
    } as GetListPrintRequest;
    console.log("resq", reqList);
    // return;
    await this.appPrintService.getListPrint(reqList).then((res: any) => {
      if (res === "error") {
        return;
      } else {
        if (res !== null) {
          let a = res.response.map((item) => {
            moment.locale("th"); // set default locale to Thai
            item.createDate = moment(item.createDate).format(
              "YYYY-MM-DD HH:mm"
            );
            if (
              item.queueNumber != null &&
              item.queueNumber != undefined &&
              item.queueNumber != ""
            ) {
              item.queueNumber = parseInt(item.queueNumber.slice(-4));
            }
            return item;
          });
          this.dataSource.data = a;
          this.count = res.totalCount;
        } else {
          this.dataSource.data = [];
          this.count = 0;
        }
      }
    });
    this.dataSource.paginator.length = this.count;
  }
  // applyFilter(filterValue: string) {
  //   // this.apiRequest.receiptNo = filterValue
  //   // this.apiRequest.requestNo = filterValue
  //   // this.apiRequest.invoiceNo = filterValue
  //   this.apiRequest.keyword = filterValue
  //   this.dataSource.paginator = this.paginator
  //   this.loadData(this.apiRequest)
  // }

  applyFilter(event: string) {
    clearTimeout(this.timer); // Clear any existing timer

    this.timer = setTimeout(() => {
      // This code will be executed after a specified delay (e.g., 500 milliseconds)

      // Send the value to the backend or perform any other necessary actions
      this.apiRequest.keyword = event;
      this.dataSource.paginator = this.paginator;
      this.loadData(this.apiRequest);
    }, 500); // Specify the desired delay in milliseconds
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce("Sorting cleared");
    }
  }
  stopPropagation(event) {
    event.stopPropagation();
  }
  onRefresh(status) {
    this.apiRequest.keyword = "";
    this.searchString = "";
    this.searchForm.status = "6"; 
    switch (status) {
      case 1:
        this.apiRequest.selfPrint = " ";
        this.apiRequest.sigBranchId = 0;
        break;
      case 2:
        this.apiRequest.selfPrint = "N";
        break;
      case 3:
        this.apiRequest.sigBranchId = this.userProfile.branchId;
        break;
    }

    this.apiRequest.UID = this.userProfile.uId;
    this.apiRequest.certificateNo = this.searchForm.certificateNo;
    this.apiRequest.endDate =
      this.searchForm.endDate == null
        ? null
        : new Date(this.searchForm.endDate);
    this.apiRequest.startDate =
      this.searchForm.startDate == null
        ? null
        : new Date(this.searchForm.startDate);
    this.apiRequest.isRqstatus =
      this.searchForm.status == "1" ? null : this.searchForm.status;
    this.apiRequest.receiptNo = this.searchForm.receiptNo;
    this.apiRequest.requestNo = this.searchForm.requestNo;
    this.apiRequest.invoiceNo = this.searchForm.invoiceReference;
    this.apiRequest.documentReferenceNo = this.searchForm.invoiceReference;
    this.apiRequest.companyCode = this.searchForm.companyCode;
    this.apiRequest.companyName = this.searchForm.companyName;
    this.apiRequest.payRefNo = this.searchForm.payRefNo;
    this.dataSource.paginator = this.paginator;
    console.log("onRefresh");
    this.loadData(this.apiRequest);
  }
  saveSearch(status) {
    this.apiRequest.keyword = "";
    this.searchString = "";

    switch (status) {
      case 1:
        this.apiRequest.selfPrint = " ";
        this.apiRequest.sigBranchId = 0;
        break;
      case 2:
        this.apiRequest.selfPrint = "N";
        break;
      case 3:
        this.apiRequest.sigBranchId = this.userProfile.branchId;
        break;
    }

    if (
      moment(this.searchForm.startDate).format("YYYY-MM-DD") >
        moment(this.searchForm.endDate).format("YYYY-MM-DD") &&
      !moment(this.searchForm.startDate).format("YYYY-MM-DD")
    ) {
      this.alertService.open({
        message: "กรุณาระบุวันที่สิ้นสุดให้มากกว่าวันที่เริ่มต้น",
        title: "กรุณากรอกข้อมูลค้นหาให้ถูกต้อง",
      });
      this.searchForm.startDate = {};
      this.searchForm.endDate = {};
      return false;
    }
    this.apiRequest.UID = this.userProfile.uId;
    this.apiRequest.certificateNo = this.searchForm.certificateNo;
    this.apiRequest.endDate =
      this.searchForm.endDate == null
        ? null
        : new Date(this.searchForm.endDate);
    this.apiRequest.startDate =
      this.searchForm.startDate == null
        ? null
        : new Date(this.searchForm.startDate);
    this.apiRequest.isRqstatus =
      this.searchForm.status == "1" ? null : this.searchForm.status == null ? "6" : this.searchForm.status;
    this.apiRequest.receiptNo = this.searchForm.receiptNo;
    this.apiRequest.requestNo = this.searchForm.requestNo;
    this.apiRequest.invoiceNo = this.searchForm.invoiceReference;
    this.apiRequest.documentReferenceNo = this.searchForm.invoiceReference;
    this.apiRequest.companyCode = this.searchForm.companyCode;
    this.apiRequest.companyName = this.searchForm.companyName;
    this.apiRequest.payRefNo = this.searchForm.payRefNo;
    this.dataSource.paginator = this.paginator;
    // console.log("saveSearch");
    this.loadData(this.apiRequest);
  }

  print(e) {
    this.confirmService
      .open({
        header: "ยืนยันการทำรายการ",
        content:
          e.rqstatusId == 6
            ? "ต้องการพิมพ์เอกสาร"
            : "ต้องการพิมพ์ Draft หรือไม่",
      })
      .then((res) => {
        if (res == true && e.docType == "CO/Pack") {
          const dataList = {
            requestNo: e.requestNo,
            docType: e.docType,
            expressType: e.expressType,
            packId: e.packId,
            printPack: true,
          };
          const dialogRef = this.dialog.open(PrintCoPackPopupInquiryComponent, {
            disableClose: false,
            width: "70vw",
            data: dataList,
            maxWidth: "none",
          });
          dialogRef.afterClosed().subscribe((res) => {
            if (res == false) {
              return;
            } else {
              return;
            }
          });
        } else if (res == true && e.docType == "CO") {
          const data = {
            Hid: e.packId,
          };
          this.appCOService.getPrintOriginal(data).then((res: any) => {
            if (res === "error") {
              return;
            } else {
              window.open(res.linkUrl, "_blank");
              // this.apiRequest.isRqstatus = "6";
              console.log("print" );
              this.loadData(this.apiRequest);
            }
          });
        } else if (res == true && e.docType == "CF") {
          this.printCFPopup(e);
        }
      });
  }
  showDetail() {}
  deleteItem(e) {
    this.confirmService
      .open({
        header: "ยืนยันการทำรายการ",
        content: "ยืนยันการลบข้อมูลนี้ใช่หรือไม่?",
      })
      .then((res) => {
        if (res == true) {
          const data = {
            id: e.docId,
            docId: e.docId,
            packId: e.packId,
            receiptNo: e.receiptNo,
            userName: this.userProfile.userName,
          };
          this.appCOService.deleteCO(data).then((res: any) => {
            if (res == "error") {
              return;
            } else {
              this.alertService
                .open({ title: "ทำรายการสำเร็จ", message: "ลบข้อมูลสำเร็จ" })
                .then((res) => {
                  this.loadData(this.apiRequest);
                });
            }
          });
        }
      });
  }
  cancel(e) {
    const data = {
      docId: e.docId,
      packId: e.packId,
      isRqStatus: "34",
    };
    this.confirmService
      .open({
        header: "ยืนยันการทำรายการ",
        content: "ต้องการยกเลิกคำขอ",
      })
      .then((res) => {
        if (res == true) {
          this.appCOService.cencelVerify(data).then((res) => {
            if (res == "error") {
              return;
            } else {
              this.alertService
                .open({ title: "ทำรายการสำเร็จ", message: "ยกเลิกคำขอสำเร็จ" })
                .then((res) => {
                  this.loadData(this.apiRequest);
                });
            }
          });
        }
      });
  }

  async printCFPopup(e) {
    if (e.printStatus == "Y") {
      this.alertService
        .open({
          title: "ผิดพลาด",
          message:
            "คุณได้ดำเนินการพิมพ์เอกสารไปแล้ว กรุณาติดต่อเจ้าหน้าที่ หรือทำการขอ Reprint ได้ใหม่โดยการเข้าไปที่เมนู Print > Reprint",
        })
        .then((x) => {
          this.dialog.closeAll();
        });
    } else {
      let data = {
        docId: e.packId,
      };
      await this.appCOService.getCfTotalPage(data).then((x) => {
        this.docHid = x?.docId;
        this.totalPageCF = x?.totalPage;
      });
      let dataPopup = {
        id: e.packId,
        totalPage: this.totalPageCF ? this.totalPageCF : 0,
        hid: this.docHid,
        rqNo: e.requestNo,
      };
      const dialogRef = this.dialog.open(PrintPopupInquiryComponent, {
        disableClose: false,
        width: "60vw",
        data: dataPopup,
        maxWidth: "none",
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          if (res.data.checkPrint.filter(Boolean).length !== 0) {
            const data = {
              uid: this.userProfile.uId,
              Hid: res.data.hid,
              id: res.data.id,
              checkPrint: res.data.checkPrint,
            };
            this.appPrintService.getPrintOriginalCF(data).then((res: any) => {
              if (res === "error") {
                return;
              } else {
                this.dataSource.paginator = this.paginator;
                this.loadData(this.apiRequest);
                window.open(res.linkUrl, "_blank");
              }
            });
          } else {
            this.alertService
              .open({
                title: "กรุณากรอกข้อมูลให้ครบถ้วน",
                message: "กรุณาเลือกตำแหน่งที่ต้องการพิมพ์",
              })
              .then((x) => {
                this.dialog.closeAll();
              });
          }
        }
      });
    }
  }

  clearOption() {
    this.searchForm = {};
    this.searchCompanyForm.get("companySearch").setValue("");
    this.clearApiRequest();
  }
  clearApiRequest() {
    this.apiRequest = {
      UID: 0,
      userName: "",
      requestNo: "",
      certificateNo: "",
      receiptNo: "",
      payReferenceNo: "",
      invoiceNo: "",
      documentReferenceNo: "",
      isRqstatus: "6",
      docType: "CO",
      docId: 0,
      selfPrint: "",
      sigBranchId: 0,
      companyCode: null,
      companyName: "",
      payRefNo: "",
    };
    // this.searchForm.status = 6;
  }

  async pageChanged(e, status: number) {
    this.apiRequest.pagination = {
      Skip: (!e.pageIndex ? 0 : e.pageIndex) * (!e.pageSize ? 0 : e.pageSize),
      Take: !e.pageSize ? 10 : e.pageSize,
    };
    //clear data
    // this.apiRequest.selfPrint = "";
    this.apiRequest.sigBranchId = 0;

    switch (status) {
      case 1:
        this.apiRequest.selfPrint = " ";
        this.apiRequest.sigBranchId = 0;
        break;
      case 2:
        this.apiRequest.selfPrint = "N";
        break;
      case 3:
        this.apiRequest.sigBranchId = this.userProfile.branchId;
        break;
    }

    let rqStatus = parseInt(this.apiRequest.isRqstatus);
    let reqList = {
      uId: this.userProfile.uId,
      gId: this.userProfile.gId,
      compId: this.userProfile.cid,
      compName: this.apiRequest.companyName,
      endDate: this.apiRequest.endDate,
      refNo: this.apiRequest.invoiceNo, //ถ้าไม่เช็คมันจะเอาไป where แบบ and มันจะพัง
      reqNo: this.apiRequest.requestNo, //ถ้าไม่เช็คมันจะเอาไป where แบบ and มันจะพัง
      rqStatus: isNaN(rqStatus) ? null : rqStatus,
      thtNo: this.apiRequest.certificateNo, //ถ้าไม่เช็คมันจะเอาไป where แบบ and มันจะพัง
      startDate: this.apiRequest.startDate,
      keyword: this.apiRequest.keyword,
      pagination: this.apiRequest.pagination,
      selfPrint: this.apiRequest.selfPrint,
      sigBranchId: this.apiRequest.sigBranchId,
      receiptNo: this.apiRequest.receiptNo,
    } as GetListPrintRequest;

    await this.appPrintService.getListPrint(reqList).then((res: any) => {
      if (res === "error") {
        return;
      } else {
        if (res !== null) {
          moment.locale("th"); // set default locale to Thai

          let a = res.response.map((item) => {
            item.createDate = moment(item.createDate).format(
              "YYYY-MM-DD HH:mm"
            );
            if (
              item.queueNumber != null &&
              item.queueNumber != undefined &&
              item.queueNumber != ""
            ) {
              item.queueNumber = parseInt(item.queueNumber.slice(-4));
            }
            return item;
          });

          this.dataSource.data = a;
          this.count = res.totalCount;
        } else {
          this.dataSource.data = [];
          this.count = 0;
        }
      }
    });
    this.dataSource.paginator.length = this.count;
    this.dataSource.paginator.pageIndex = e.pageIndex;
  }
  searchAll() {
    this.printStatus = 1;
    this.clearApiRequest(); 
    this.loadData(this.apiRequest);
  }
  searchSelfPrintStaff() {
    this.printStatus = 2;
    // this.clearApiRequest()

    this.apiRequest.pagination = {
      Skip:
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
      Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
    };
    this.apiRequest.UID = this.userProfile.uId;
    this.apiRequest.selfPrint = "N";
    this.apiRequest.sigBranchId = 0;
    this.dataSource.paginator = this.paginator;
    this.loadData(this.apiRequest);
  }
  searchSelfPrintStaffBranch() {
    this.printStatus = 3;
    // this.clearApiRequest()
    this.apiRequest.pagination = {
      Skip:
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
      Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
    };
    this.apiRequest.UID = this.userProfile.uId;
    this.apiRequest.sigBranchId = this.userProfile.branchId;
    this.apiRequest.selfPrint = "N";
    this.dataSource.paginator = this.paginator;
    this.loadData(this.apiRequest);
  }
  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find((e) => e.buttonCode == buttonCode);

    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }

  approveLiveSelfPrint(e) {
    var actionReq = {
      id: e.packId,
      action: "SELFPRINT",
      refTable: "DOCUMENT_REQUEST_OBJ",
    };
    this.appMasterService.getActionStatus(actionReq).then((res) => {
      if (res === "error") {
        return;
      } else {
        var data = res;

        if (data.allowAction === "Y") {
          this.confirmService
            .open({
              header: "ยืนยันการทำรายการ",
              content: "อนุมัติการพิมพ์",
            })
            .then((res) => {
              if (res == true) {
                let data = {
                  packId: e.packId,
                  remark: e.docType,
                  saveLog: {
                    docType: "P",
                    amount: 0,
                    roundPrintAmount: 0,
                    refId:
                      e.docType == "CF"
                        ? e.packId
                        : e.docType == "CO" || e.docType == "CO/Pack"
                        ? 0
                        : 0,
                    refTableName:
                      e.docType == "CF"
                        ? "DOCUMENT_REQUEST_OBJ"
                        : e.docType == "CO" || e.docType == "CO/Pack"
                        ? "DOCUMENT_REQUEST_HEADER"
                        : "",
                    branch: e.sigBranchId,
                    action: "A",
                  },
                };
                this.appCOService.approveLiveSignSelfPrint(data).then((res) => {
                  if (res == "error") {
                    return;
                  } else {
                    this.alertService
                      .open({
                        title: "ทำรายการสำเร็จ",
                        message: "อนุมัติการพิมพ์สำเร็จ",
                      })
                      .then((res) => {
                        this.loadData(this.apiRequest);
                      });
                  }
                });
              }
            });
          //
        } else {
          this.alertService
            .open({
              message: "ไม่สามารถทำรายการได้ เนื่องจากเอกสารได้ถูกพิมพ์แล้ว",
              title: "",
            })
            .then((res) => {
              this.loadData(this.apiRequest);
              return;
            });
        }
      }
    });
  }

  openPopupSendPrintTostaff(e) {
    var actionReq = {
      id: e.packId,
      action: "PRINT",
      refTable: "DOCUMENT_REQUEST_OBJ",
    };

    this.appMasterService.getActionStatus(actionReq).then((res) => {
      if (res === "error") {
        return;
      } else {
        var data = res;

        if (data.allowAction === "Y") {
          let data = {
            packId: e.packId,
            requestNo: e.requestNo,
            docType: e.docType,
            expressType: e.expressType,
            consignor: e.consignor,
          };
          const dialogRef = this.dialog.open(
            ListCoSendPrintToStaffPopupComponent,
            {
              disableClose: false,
              width: "70vw",
              data: data,
              maxWidth: "none",
            }
          );
          dialogRef.afterClosed().subscribe((res) => {
            if (!res) {
              return;
            } else {
              this.loadData(this.apiRequest);
              return;
            }
          });
        } else {
          this.alertService
            .open({
              message: "ไม่สามารถดำเนินการได้ เนื่องจากมีการโอนงานไปแล้ว",
              title: "",
            })
            .then((res) => {
              this.loadData(this.apiRequest);
              return;
            });
        }
      }
    });
  }

  selectionChange(e): void {
    this.searchCompanyForm
      .get("companySearch")
      .setValue(e.option.value.companyCode);
    this.companySelect(e.option.value);
  }

  companySelect(e) {
    this.searchForm.companyCode = e.cpid;
    this.searchForm.companyName = e.companyNameTh;

    this.valueSelect = true;
  }

  valueChange() {
    this.filteredOptions$ = this.searchCompanyForm
      .get("companySearch")
      .valueChanges.pipe(
        debounceTime(500),
        switchMap((value) => {
          if (!value) {
            this.valueSelect = false;
            this.searchForm.companyCode = null;
            return of(null);
          }

          this.httpLoadingService.enabled = false;
          this.isLoading = true;

          const key = value;
          if (key !== "") {
            if (this.valueSelect) {
              return of(null);
            } else {
              this.valueSelect = false;
              return this.appCompanyService.getListCompanyByFilter(key);
            }
          } else {
            this.valueSelect = false;
            this.isLoading = false;
            return;
          }
        }),
        map((res: any[]) => {
          this.isLoading = false;
          this.httpLoadingService.enabled = true;

          return res?.map((item) => {
            const { companyTaxId, companyNameTh } = item as companySearch;
            return {
              ...item,
              // tslint:disable-next-line: max-line-length
              display: `${companyTaxId} ${companyNameTh}`,
            };
          });
        })
      );
  }

  showDocDetail(element) {
    // Generate the URL with queryParams
    const queryParams = {
      id: element.packId,
      disabled: true,
      search: true,
      staff: true,
      sessionId: this._authService.getUniqueId(),
    };

    // Create the URL for the new tab
    let url = "";
    if (element.docType == "CO" || element.docType == "CO/Pack") {
      url = this.router
        .createUrlTree(["/page/request/request-co/create-co"], {
          queryParams,
        })
        .toString();
    } else if (element.docType == "CF") {
      url = this.router
        .createUrlTree(["/page/request/request-cf/create-cf"], {
          queryParams,
        })
        .toString();
    }

    var fullUrl = this.buildDynamicURL(url);
    // Open the URL in a new tab when the button is clicked
    window.open(fullUrl, "_blank");
  }

  buildDynamicURL(path) {
    // Get the current host from the browser's location
    const currentProtocol = window.location.protocol;
    const currentHost = window.location.hostname;
    const currentPort = window.location.port;

    // Remove any trailing slashes from the pathname
    const pathname = window.location.pathname.replace(/\/$/, "");

    // Combine the host and pathname to create the base URL
    const baseURL = currentPort
      ? `${currentProtocol}//${currentHost}:${currentPort}${pathname}/#`
      : `${currentProtocol}//${currentHost}${pathname}/#`;
    debugger;

    // Combine the base URL with the provided path
    const fullURL = `${baseURL}${path}`;

    return fullURL;
  }
}
