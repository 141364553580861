  <mat-card>
    <mat-card-header>
      <div class="flex justify-between" style="width: 100%;">
        <div class="text-lg"> Country Manage </div>
        <div>
          <button class="close-button" [mat-dialog-close]="true">
            <mat-icon class="text-white" [mat-dialog-close]>cancel</mat-icon>
          </button>
        </div>
      </div>
    </mat-card-header>
    <mat-dialog-content>
      <div class="pl-22 pr-22 flex flex-col gap-4">
        <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
          <div class="mat-popup-header flex flex-col">
            <h4>ชื่อประเทศ <span style="color:red;">*</span></h4>
            <h4>Country Name</h4>
          </div>
          <div class="mat-popup flex flex-row justify-between items-center" style="width:75%">
            <div>:</div>
            <mat-form-field style="flex: 0 95%;" class="flex">
              <input required autocomplete="off" type="text" matInput placeholder="ชื่อประเทศ" matInput #input [(ngModel)]="form.countryNameEn"
                name="name" #name="ngModel">
            </mat-form-field>
          </div>
        </div>

        <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
          <div class="mat-popup-header flex flex-col">
            <h4>ชื่อประเทศ (ภาษาไทย) <span style="color:red;">*</span></h4>
            <h4>Country Name (Thai)</h4>
          </div>
          <div class="mat-popup flex flex-row justify-between items-center" style="width:75%">
            <div>:</div>
            <mat-form-field style="flex: 0 95%;" class="flex">
              <input required autocomplete="off" type="text" matInput placeholder="ชื่อประเทศ (ภาษาไทย)" matInput #input [(ngModel)]="form.countryNameTh"
                name="name" #name="ngModel">
            </mat-form-field>
          </div>
        </div>
    
        <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
          <div class="mat-popup-header flex flex-col">
            <h4>ทวีป <span style="color:red;">*</span></h4>
            <h4>Landmass</h4>
          </div>
          <div class="mat-popup flex flex-row justify-between items-center" style="width:75%">
            <div>:</div>
            <mat-form-field style="flex: 0 95%;" class="flex">
              <mat-select required name="type" [(ngModel)]="form.landmass">
                <mat-option *ngFor="let landmass of landMassList" [value]="landmass.landMassCode">
                  {{landmass.landMassName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
  
        <div class="flex-row flex justify-between gap-2.5">
          <div class="mat-popup-header flex flex-col">
            <h4>ประเทศต้นทาง <span style="color:red;">*</span></h4>
            <h4>Country of Origin</h4>
          </div>
          <div class="mat-popup flex flex-row justify-between items-center" style="width:75%">
            <div>:</div>
            <mat-slide-toggle [checked]="form.isCountryOfOrigin === 'Y' ? true : false" (change)="onChangeOrigin($event)"
              style="flex: 0 95%;" class="flex">
              ใช้งาน</mat-slide-toggle>
          </div>
        </div>
  
        <div class="flex-row flex justify-between gap-2.5">
          <div class="mat-popup-header flex flex-col">
            <h4>ประเทศปลายทาง <span style="color:red;">*</span></h4>
            <h4>Destination Country</h4>
          </div>
          <div class="mat-popup flex flex-row justify-between items-center" style="width:75%">
            <div>:</div>
            <mat-slide-toggle [checked]="form.isDestinationCountry === 'Y' ? true : false" (change)="onChangeDestination($event)"
              style="flex: 0 95%;" class="flex">
              ใช้งาน</mat-slide-toggle>
          </div>
        </div>
  
        <div class="flex-row flex justify-between gap-2.5">
          <div class="mat-popup-header flex flex-col">
            <h4>ใช้งาน</h4>
            <h4>Active</h4>
          </div>
          <div class="mat-popup flex flex-row justify-between items-center" style="width:75%">
            <div>:</div>
            <mat-slide-toggle [checked]="form.isActive === 'Y' ? true : false" (change)="onChange($event)"
              style="flex: 0 95%;" class="flex">
              Active</mat-slide-toggle>
          </div>
        </div>
        <div class="flex-row flex justify-center gap-2.5" style="gap: 10px;">
          <button mat-raised-button color="accent" (click)="save()">บันทึก</button>
          <button mat-raised-button [mat-dialog-close]>ยกเลิก</button>
        </div>
      </div>
    </mat-dialog-content>