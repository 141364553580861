import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { IgetListLoadTemplateRequest } from "app/business/interface/app/app-co.interface";
import { AppCOService } from "app/business/service/app/app-co.service";
import { AppMasterService } from "app/business/service/app/app-master.service";
import { DxDataGridComponent } from "devextreme-angular";
import { QueuePopupComponent } from "../queue-popup/queue-popup.component";
import { CreateCoPackPopupComponent } from "./create-copack-popup/create-copack-popup.component";

import { CreateGoodListPopupComponent } from "./create-goodlist-popup/create-goodlist-popup.component";
import { CreateInvoicePopupComponent } from "./create-invoice-popup/create-invoice-popup";
import { DraftCOPopupComponent } from "./draft-co-popup/draft-co-popup.component";
import { IReqSaveCO } from "app/business/interface/api/api-co.interface";
import { debounce } from "lodash";
import { CoErrorMarkImagePopupComponent } from "./co-error-mark-image-popup/co-error-mark-image-popup.component";
import { Buffer } from "buffer";
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import { Location } from "@angular/common";

import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import _ from "underscore";
import { VerifyItemDetailComponent } from "../verify-co-inquiry/verify-item-detail/verify-item-detail.component";
import { ImageModalComponent } from "./Image-Modal/Image-Modal.component";
import moment from "moment";
import { DetailPaymentListTableComponent } from "../payment/detail-payment-list-table/detail-payment-list-table.component";
import { AbstractControl, FormGroup } from "@angular/forms";
import { HttpLoadingService } from "@bundle/service/http-loading.service";
import { AppUserService } from "app/business/service/app/app-user.service";
import { AuthService } from "app/core/auth/auth.service";
@Component({
  selector: "app-create-co-inquiry",
  templateUrl: "./create-co-inquiry.component.html",
  styleUrls: ["./create-co-inquiry.component.scss"],
})
export class CreateCOInquiryComponent implements OnInit {
  @Input() subEdit: boolean = false;
  @Input() disabledJob: boolean;
  @Input() packIdInput: number;
  @Input() isJobDetail: boolean = undefined;
  _onlyCert: string = "";
  @Input()
  set onlyCert(value) {
    this._onlyCert = value;
    this.ngOnInit();
  }
  get onlyCert() {
    return this._onlyCert;
  }

  search: any;
  dataSourceGoodItem: any[] = [];
  dataSourceInvoice: any[] = [];
  dataSourceAttachPic: any[] = [];
  dataSourcecoPAck: any[] = [];
  file: any;
  value: any[] = [];
  attachTypeList: any[] = [];
  isCreateForm: boolean;
  isCreateTemplate: boolean;
  editItem: boolean = false;
  clicked: boolean;
  editJobData: boolean;
  PackidLoadData: number;
  placeholderTemplate: String = "เลือกไฟล์แม่แบบ";
  placeholderSignature: String = "ชื่อ-นามสกุล";
  placeholderDeclarator: String = "ชื่อผู้ส่งออกสินค้า";
  placeholderVessel: String = "ขนส่งโดย";
  placeholderDestination: String = "ประเทศปลายทาง";
  placeholderBranch: string = "สาขารับเอกสาร";
  placeholderOrigin: string = "ประเทศแหล่งกำเนิดสินค้า";
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  @ViewChild("gridContainer", { static: false })
  invoiceGrid: DxDataGridComponent;

  @ViewChild(DetailPaymentListTableComponent, { static: false })
  detailPaymentListTable: DetailPaymentListTableComponent;

  invoiceGridLength: number;
  templateOptions: any[] = [];
  templateList: any[] = [];
  declaratorList: any[] = [];
  declaratorListRelation: any[] = [];
  nameLastNameList: any[] = ([] = []);
  branchList: any[] = [];
  vesselTypeList: any[] = [];
  destinationList: any[] = [];
  searchTemplateForm: FormGroup;

  isDisableFieldTemplate: boolean = false;
  isDisableFieldDeclarator: boolean = false;
  isDisableFieldConsignor: boolean = false;
  isDisableFieldCertificate: boolean = false;
  isDisableFieldDestination: boolean = false;
  isDisableFieldItemDescription: boolean = false;
  isDisableFieldConsignee: boolean = false;
  isDisableFieldNotify: boolean = false;
  isDisableFieldVesselAndFlight: boolean = false;
  isDisableFieldCountryOfOrigin: boolean = false;
  isDisableFieldLoadingOnAboutFlightDate: boolean = false;
  isDisableFieldInvoiceNoDate: boolean = false;
  isDisableFieldFooterDescription: boolean = false;

  form: any = {
    id: 0,
    docType: "CO",
    lastDraftVersion: 0,
    items: [],
    attachments: [],
    invoice: [],
    packId: 0,
    countryOfOrigin: 0,
    approveDateTime: null,
    approveBy: null,
    destinationCountry: 0,
  };
  showErrorMessage: any = [];
  imageResize: any;
  formTempsignAuthorizedId: any;
  imgSrc: string;
  userProfile: any = {};
  cOPackList: any[] = [];
  deleteCOPackList: any = [];
  deleteInvoiceList: any = [];
  deleteItemsList: any = [];
  deleteAttachmentList: any = [];

  displayStyle = "none";
  resolution: any = {};
  editorOptions: any = {
    placeholder: "Enter some text",
    maxLength: 50,
  };
  counter: number = 4;
  recheck: any;
  print: boolean = false;
  reprint: boolean = false;
  staff: boolean = false;
  fieldError: any = {
    declarator: true,
    consignor: true,
    consignee: true,
    notify: true,
    vessel: true,
    loading: true,
    certificateDate: true,
    destination: true,
    invoice: true,
    country: true,
    item: true,
    footer: true,
  };
  markErrorList: any = [];
  dataSourceUpload: any = [];
  formSourceUpload: any = [];
  symbolRegex: any;
  engRegex: any;
  textRegEx: RegExp = /^[a-zA-Z0-9 !"$%&'()*+,-./#:;<=>?@[\\\]^_`{|}~\n\r฿]*$/;
  lengthViewChildPayment: number;
  documentDetail: any;
  logPrint: any = [];
  selectDeclarator: any;
  valueChange: boolean = false;
  signId: any;

  get templateSearch(): AbstractControl {
    return this.searchTemplateForm.get("templateSearch");
  }
  @Input() isRequestJob: boolean = false;
  @Output() newForm: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private router: Router,
    private appCOService: AppCOService,
    private appMasterService: AppMasterService,
    private dialog: MatDialog,
    private alertService: AlertMessageService,
    private confirmService: ConfirmDialogService,
    private httpLoadingService: HttpLoadingService,
    private appUserService: AppUserService,
    private route: ActivatedRoute,
    private _location: Location,
    private _authService: AuthService
  ) {
    this.isCreateForm = false;
    this.form.isCreateTemplate = false;
    this.form.certificateDateRequest = "approve_date";
    this.saveQ = debounce(this.saveQ, 500);
    this.save = debounce(this.save, 500);
  }

  async ngOnInit() {
    //debugger;
    this.userProfile = JSON.parse(this._authService.loginUser);
    this.userProfile = {
      ...this.userProfile,
      uId: this.userProfile.uid,
      cid: this.userProfile.cpId,
    };
    await this.LoadParam();
    await this.loadData(this.PackidLoadData);
    await this.loadMasterConfigD();
    this.lengthViewChildPayment =
      this.detailPaymentListTable?.dataSource?.length;
  }

  LoadParam() {
    this.editItem = false;
    const { disabled, id, search, recheck, print, staff, reprint } =
      this.route.snapshot.queryParams;
    this.PackidLoadData = id;
    if (this.disabledJob !== undefined) {
      this.editItem = this.disabledJob;
      this.editJobData = true;
    } else {
      this.editItem = disabled == "true" ? true : false;
      this.editJobData = false;
    }
    if (this.isJobDetail !== undefined) {
      // this.editItem = this.disabledJob
      this.editJobData = this.isJobDetail;
    }
    if (id != null && id != undefined && id != "") {
      this.PackidLoadData = id;
    } else if (this.packIdInput != null && this.packIdInput != undefined) {
      // this.form.requestBy = this.userProfile.userName;
      this.PackidLoadData = this.packIdInput;
    }
    this.search = search;
    this.recheck = recheck;
    this.print = print;
    this.reprint = reprint;
    this.staff = staff;
  }

  async loadData(id) {
    let requestTemplate: IgetListLoadTemplateRequest = {
      UID: this.userProfile.uId,
      CID: this.userProfile.cid,
      tempName: "",
      isActive: "Y",
    };
    let requestmaster = {
      id: 0,
      isActive: "Y",
    };

    let requestCompanyRelation = {
      CPID: this.userProfile.uId,
      CID: this.userProfile.cid,
      isActive: "Y",
    };

    let request = {
      CPID: this.userProfile.uId,
      CID: this.userProfile.cid,
      isActive: "Y",
    };
    let packId = {
      id: id,
    };

    await Promise.all([
      this.appCOService.getListLoadTemplate(requestTemplate), //1
      this.appMasterService.getListBranch(requestmaster), //2
      this.appMasterService.getListVesselType(requestmaster), //3
      this.appCOService.getListAttachType(), //4
      this.appMasterService.getListCountry(requestmaster), //5
      this.appCOService.getRelationCompany(requestCompanyRelation), //6
      this.appCOService.getListDeclaratorCompany(request), //8
    ]).then(([res1, res2, res3, res4, res5, res6, res8]) => {
      //======================================Res1
      if (res1 != "error") {
        this.templateList = res1;
      }
      //=======================================Res2
      if (res2 != "error") {
        this.branchList = res2;
      }
      //=======================================Res3
      if (res3 != "error") {
        this.vesselTypeList = res3;
        if (this.vesselTypeList.length != 0) {
          this.form.vesselType = _.first(this.vesselTypeList).name;
        }
      }
      //=======================================Res4
      if (res4 != "error") {
        this.attachTypeList.push({
          maT_DOC_TYPE_ID: 0,
        });
        this.attachTypeList = this.attachTypeList.concat(res4);
      }
      //=======================================Res5
      if (res5 != "error") {
        var index = res5.findIndex((x) => x.mC_COUNTRY_NAME == "THAILAND");
        this.form.countryOfOrigin = res5[index].mC_COUNTRY_NAME;
        this.destinationList = res5;
      }
      //=======================================Res6
      if (res6 != "error") {
        if (res6 != null) {
          res6 = res6.map((item) => {
            if (item.blacklistStatus == "Y") {
              var txtBlacklistReason = "";
              if (item.blacklistReason != null) {
                txtBlacklistReason = " (" + item.blacklistReason + ")";
              }
              item.companyNameEn =
                item.companyNameEn + " " + txtBlacklistReason;
              item.companyNameTh =
                item.companyNameTh + " " + txtBlacklistReason;
            }
            return item;
          });
        }
        this.form.declaratorId =
          res6[0]?.blacklistStatus != "Y" ? res6[0]?.cid ?? 0 : 0;
        this.selectDeclarator =
          res6[0]?.blacklistStatus != "Y" ? res6[0]?.cid ?? 0 : 0;
        this.declaratorListRelation = res6;
        const compName: string = res6[0]?.companyNameEn;
        this.form.consignorName =
          this.form.consignorName ?? compName ? compName.toUpperCase() : "";
      }
      //=======================================Res8
      if (res8 != "error") {
        this.declaratorList = res8;
        this.form.requestBy = !id
          ? _.first(this.declaratorList)?.companyNameEn
          : this.form.requestBy;
      }
    });

    let getListNameLastNameRequest = {
      CPID: this.form.declaratorId,
      isActive: "Y",
    };

    await Promise.all([
      this.appMasterService.getUserAddress({
        id: this.form.declaratorId ?? this.userProfile.cid,
      }), //1
      this.appMasterService.getListNameLastName(getListNameLastNameRequest), //1
    ]).then(([res1, res2]) => {
      if (res1 != "error") {
        const address: string = res1?.address;
        this.form.consignorAddress = address ? address.toUpperCase() : "";
      }
      if (res2 === "error") {
        return;
      } else {
        this.nameLastNameList = [];
        this.nameLastNameList = res2;
        //this.form.signAuthorizedId = null
        if (res2?.length === 0) {
          this.nameLastNameList.unshift({
            csid: "",
            fullNameThai: "ไม่พบข้อมูลลายมือชื่อผู้มอบอำนาจ",
          });
          if (!this.form.signAuthorizedId) {
            // this.form.signAuthorizedId = "";
          }
        } else {
          this.nameLastNameList = res2;

          if (this.nameLastNameList.length === 1) {
            const firstRecord = _.first(res2);
            this.form.signAuthorizedId = firstRecord.csid;
            this.formTempsignAuthorizedId = firstRecord.csid;
          } else {
            const checkAll = this.nameLastNameList.find(
              (e) => e.fullNameThai === "กรุณาเลือก"
            );
            const checkSign = this.nameLastNameList.find(
              (f) => f.csid == this.signId
            );
            if (checkAll === undefined) {
              this.nameLastNameList.unshift({
                csid: "",
                fullNameThai: "กรุณาเลือก",
              });
              this.form.signAuthorizedId =
                checkSign != undefined ? this.signId : "";
            }

            // const checkAll = this.nameLastNameList.find(e => e.fullNameThai === "กรุณาเลือก");
            // if (checkAll === undefined) {
            //   this.nameLastNameList.unshift({ csid: "", fullNameThai: "กรุณาเลือก" });
            //   this.form.signAuthorizedId = "";
            // }
          }
        }
      }
    });
    if (!id) {
      this.DropdromwChange(this.form.declaratorId, false);
    }
    if (id) {
      const request = {
        id: id,
        docType: "CO",
        referNo: this.onlyCert,
      };
      this.isCreateForm = true; // แสดงหน้าเลยเมื่อกด Update หรือแสดงรายละเอียด
      await this.appCOService.getCO(request).then(async (res: any) => {
        if (res === "error") {
          return;
        }
        res.isPackRq = res.isPackRq == "Y" ? true : false;
        this.cOPackList =
          res.isPackRq == "Y" || res.isPackRq == true ? res.coPack : [];
        this.cOPackList = this.cOPackList.map((item, index) => {
          return {
            ...item,
            key: index + 1,
            invoiceDate:
              item.invoice.length > 0 ? item.invoice[0].invoiceDate : "",
            invoiceNo: item.invoice.length > 0 ? item.invoice[0].invoiceNo : "",
          };
        });

        this.documentDetail = res?.documentDetails;

        this.documentDetail.certificateList =
          this.documentDetail.certificateNo.join(", ");

        this.form = { ...this.form, ...res };

        this.nameLastNameList = this.form.authorizePersonList;
        if (this.nameLastNameList.length === 1) {
          const firstRecord = _.first(this.nameLastNameList);
          this.form.signAuthorizedId = firstRecord.csid;
          this.formTempsignAuthorizedId = firstRecord.csid;
        } else {
          const checkAll = this.nameLastNameList.find(
            (e) => e.fullNameThai === "กรุณาเลือก"
          );
          const checkSign = this.nameLastNameList.find(
            (f) => f.csid == this.form.signAuthorizedId
          );
          if (checkAll === undefined) {
            this.nameLastNameList.unshift({
              csid: "",
              fullNameThai: "กรุณาเลือก",
            });
            this.form.signAuthorizedId =
              checkSign != undefined ? this.form.signAuthorizedId : "";
          }
          // const checkAll = this.nameLastNameList.find(e => e.fullNameThai === "กรุณาเลือก");
          // if (checkAll === undefined) {
          //   this.nameLastNameList.unshift({ csid: "", fullNameThai: "กรุณาเลือก" });
          //   this.form.signAuthorizedId = "";
          // }
        }

        this.form.invoiceChk = this.form.invoiceChk == "Y" ? true : false;
        this.form.liveSignature = this.form.liveSignature == "Y" ? true : false;
        this.dataSourceGoodItem =
          this.form.items == null
            ? []
            : this.form.items.map((item) => {
                return {
                  ...item,
                  exportValue: parseFloat(item.exportValue).toFixed(3),
                  weight: parseFloat(item.weight).toFixed(3),
                  grossWeight: parseFloat(item.grossWeight).toFixed(3),
                  exchangeValue: parseFloat(item.exchangeValue).toFixed(3),
                };
              });
        this.dataSourceInvoice =
          this.form.invoice == null ? [] : [...this.form.invoice];
        this.dataSourceAttachPic =
          this.form.attachments == null ? [] : [...this.form.attachments];
        this.dataSourcecoPAck =
          this.cOPackList == null ? [] : [...this.cOPackList];
        this.formSourceUpload =
          this.form.attachments == null ? [] : [...this.form.attachments];
        this.dataSourceUpload =
          this.form.attachments == null ? [] : [...this.form.attachments];

        if (this.dataSourcecoPAck.length != 0) {
          this.dataSourcecoPAck.forEach((res) => {
            const invoiceList = res.invoice.map((invoice) => invoice.invoiceNo);
            res.invoiceNo = invoiceList.join(", ");
            const invoiceDate = res.invoice.map((e) => {
              e.invoiceDate = moment(e.invoiceDate).format("YYYY-MM-DD");
              return e.invoiceDate;
            });

            res.invoiceDate = invoiceDate;
          });
        }
        let rqstatusList = ["4", "40", "5", "38", "6", "7", "8", "9", "42"];
        this.editItem =
          rqstatusList.filter((x) => x == res.isRqstatus).length > 0;
      });
      await this.appCOService
        .LogPrintHistory({
          id: id,
        })
        .then(async (res: any) => {
          if (res === "error") {
            return;
          } else {
            this.logPrint = res;
          }
        });
      await this.appCOService
        .getErrorMarkList({
          packId: id,
        })
        .then(async (res: any) => {
          if (res === "error") {
            return;
          } else {
            this.markErrorList = res;
            await this.setDisplay();
          }
        });
    }
  }

  loadMasterConfigD() {
    // Load Validate Input symbol
    let validateRequest = {
      configHcode: "VALIDATE",
      configDcode: "V1",
    };
    // Load Validate Input english
    let validateRequestInput = {
      configHcode: "VALIDATE",
      configDcode: "V2",
    };

    Promise.all([
      this.appMasterService.getValidateInput(validateRequest),
      this.appMasterService.getValidateInput(validateRequestInput),
    ]).then(([res1, res2]) => {
      if (res1 != "error") {
        this.symbolRegex = new RegExp(res1.configDEName);
      }
      if (res2 != "error") {
        this.engRegex = new RegExp(res2.configDEName);
      }
    });
  }
  backClicked() {
    this._location.back();
  }
  async loadTemplate() {
    if (
      this.form.templateId != "" &&
      this.form.templateId != null &&
      this.form.templateId != undefined
    ) {
      const data = {
        id: this.form.templateId,
        tempName: this.form.tempName == undefined ? "" : this.form.tempName,
      };

      await this.appCOService.getLoadTemplate(data).then((res: any) => {
        if (res === "error" || res === null) {
          return;
        } else {
          let data = _.first(res);
          data.destinationCountry =
            data.destinationCountry !== null
              ? data.destinationCountry
              : this.form.destinationCountry;
          this.form = { ...this.form, ...data };
          this.form.remark = data.footerDescription;
          this.form.invoiceChk = res[0].invoiceChk == "Y" ? true : false;
          this.form.tempName = " ";
          this.signId = data.signAuthorizedId;
          console.log(" this.signId", this.signId);
        }

        if (this.form.invoice != undefined && this.form.invoice != null) {
          this.form.invoice.forEach((item) => {
            item.invoiceId = 0;
            item.docHid = 0;
          });
          this.dataSourceInvoice = [];
          this.form.invoice.forEach((item) => {
            this.dataSourceInvoice.push(item);
          });
        }

        if (this.form.items != undefined && this.form.items != null) {
          this.form.items.forEach((item) => {
            item.docHeaderId = 0;
            item.docItemId = 0;
          });

          this.dataSourceGoodItem =
            this.form.items == null
              ? []
              : this.form.items.map((item) => {
                  return {
                    ...item,
                    exportValue: parseFloat(item.exportValue).toFixed(3),
                    weight: parseFloat(item.weight).toFixed(3),
                    grossWeight: parseFloat(item.grossWeight).toFixed(3),
                    exchangeValue: parseFloat(item.exchangeValue).toFixed(3),
                  };
                });
        }
        if (this.form.isPackRq == true) {
          this.form.consigneeName = "";
          this.form.consigneeNotify = "";
          this.form.vesselType = "";
          this.form.loadingDate = "";
          this.form.vesselNo = "";
          this.form.destination = "";
          this.form.destinationCountry = "";
          this.form.remark = "";
        }
        this.form.consignorName = this.form.consignor;

        const es = {
          value: this.form.declaratorId,
        };
        this.DropdromwChange(es, true);
      });
    }
    //this.DropdromwChange(this.form.declaratorId)
  }
  validate(e, f, saveType): boolean {
    let fieldError: string[] = [];

    if (
      (!e.tempName ||
        e.tempName === undefined ||
        e.tempName == 0 ||
        e.tempName == "") &&
      this.form.isCreateTemplate == true
    ) {
      fieldError.push("ระบุชื่อแม่แบบที่ต้องการบันทึก");
    }
    if (
      !e.declaratorId ||
      e.declaratorId === undefined ||
      e.declaratorId == 0 ||
      e.declaratorId == ""
    ) {
      fieldError.push("ระบุผู้ส่งออกสินค้า");
    }

    // if (
    //   !e.consignorName ||
    //   e.consignorName === undefined ||
    //   e.consignorName == 0 ||
    //   e.consignorName == ''
    // ) {
    //   fieldError.push('ระบุชื่อผู้ส่งออกสินค้า')
    // }//ตรวจสอบอักขระพิเศษ
    // else
    if (
      !this.textRegEx.test(e.consignorName) &&
      (e.consignorName != null ||
        e.consignorName ||
        undefined ||
        e.consignorName != "")
    ) {
      fieldError.push(
        "ชื่อผู้ส่งออกกรอกข้อมูลได้เฉพาะภาษาอังกฤษและอักขระพิเศษ"
      );
    }
    // if (
    //   !e.consignorAddress ||
    //   e.consignorAddress === undefined ||
    //   e.consignorAddress == 0 ||
    //   e.consignorAddress == ''
    // ) {
    //   fieldError.push('ระบุที่อยู่')
    // } else
    if (
      !this.textRegEx.test(e.consignorAddress) &&
      (e.consignorAddress != null ||
        e.consignorAddress == undefined ||
        e.consignorAddress != "")
    ) {
      fieldError.push("ที่อยู่กรอกข้อมูลได้เฉพาะภาษาอังกฤษและอักขระพิเศษ");
    }

    if (e.certificateDateRequest == "on_date") {
      if (
        e.certificateDate == null ||
        e.certificateDate == undefined ||
        e.certificateDate == ""
      ) {
        fieldError.push("ตรวจสอบวันที่ย้อนหลัง/ล่วงหน้า");
      }
    }

    if (
      e.liveSignature == "Y" &&
      (e.sigBranchId == 0 ||
        e.sigBranchId == undefined ||
        e.sigBranchId == null ||
        e.sigBranchId == "")
    ) {
      fieldError.push("ระบุสาขาลายเซ็นสด");
    }
    if (
      !e.signAuthorizedId ||
      e.signAuthorizedId === undefined ||
      e.signAuthorizedId == 0 ||
      e.signAuthorizedId == ""
    ) {
      fieldError.push("ระบุลายมือชื่อผู้รับมอบอำนาจ");
    }

    if (
      (!e.coTotalPage ||
        e.coTotalPage === undefined ||
        e.coTotalPage == 0 ||
        e.coTotalPage == "") &&
      this.form.isPackRq == true
    ) {
      fieldError.push("ระบุจำนวนชุดที่ต้องการ");
    }
    if (
      (!e.packName ||
        e.packName === undefined ||
        e.packName == 0 ||
        e.packName == "") &&
      this.form.isPackRq == true
    ) {
      fieldError.push("ระบุชื่อชุดเอกสาร");
    }

    //Check ขนส่งโดย
    if (
      !e.vesselType ||
      e.vesselType === undefined ||
      e.vesselType == 0 ||
      e.vesselType == "" ||
      e.vesselType == "-"
    ) {
      fieldError.push("ระบุขนส่งโดย");
    }
    //Check ชื่อพาหนะ
    // if (
    //   !e.vesselNo ||
    //   e.vesselNo === undefined ||
    //   e.vesselNo == 0 ||
    //   e.vesselNo == '' ||
    //   e.vesselNo == '-'
    // ) {
    //   fieldError.push('ระบุชื่อพาหนะ')
    // } else
    if (
      !this.textRegEx.test(e.vesselNo) &&
      (e.vesselNo != null || e.vesselNo == undefined || e.vesselNO != "")
    ) {
      fieldError.push("ชื่อพาหนะกรอกข้อมูลได้เฉพาะภาษาอังกฤษและอักขระพิเศษ");
    }
    //Check วันที่ขนสินค้าขึ้นพาหนะ
    if (
      !e.loadingDate ||
      e.loadingDate === undefined ||
      e.loadingDate == 0 ||
      e.loadingDate == "" ||
      e.loadingDate == ""
    ) {
      fieldError.push("ระบุวันที่ขนสินค้าขึ้นพาหนะ");
    }

    //Check ปลายทางสินค้าที่ถูกยกลง
    if (
      !e.destination ||
      e.destination === undefined ||
      e.destination == 0 ||
      e.destination == ""
    ) {
      fieldError.push("ระบุปลายทางสินค้าที่ถูกยกลง");
    } else if (
      !this.textRegEx.test(e.destination) &&
      (e.destination != null ||
        e.destination == undefined ||
        e.destination != "")
    ) {
      fieldError.push(
        "ปลายทางที่ถูกยกลงกรอกข้อมูลได้เฉพาะภาษาอังกฤษและอักขระพิเศษ"
      );
    }
    //Check ประเทศปลายทางไม่ปรากฎบนฟอร์ม
    if (
      !e.destinationCountry ||
      e.destinationCountry === undefined ||
      e.destinationCountry == "" ||
      e.destinationCountry == "0" ||
      e.destinationCountry == 0
    ) {
      fieldError.push("ระบุประเทศปลายทาง");
    }

    //Check ผู่รับสินค้าปลายทาง
    // if (!e.consigneeName || e.consigneeName === undefined || e.consigneeName == 0 || e.consigneeName == ''
    // ) {
    //   fieldError.push('ระบุผู้รับสินค้าปลายทาง')
    // }
    // else
    if (
      !this.textRegEx.test(e.consigneeName) &&
      (e.consigneeName != null ||
        e.consigneeName == undefined ||
        e.consigneeName != "")
    ) {
      fieldError.push(
        "ผู้รับสินค้าปลายทางกรอกข้อมูลได้เฉพาะภาษาอังกฤษและอักขระพิเศษ"
      );
    }

    if (e.isPackRq == "Y") {
      var tmpItemDoc = true;
      if (e.coPack.length == 0) {
        fieldError.push("ระบุรายการใบ CO");
      } else {
        e.coPack.map((x) => {
          if (x.items === undefined || x.invoice === undefined) {
            tmpItemDoc = false;
            return;
          }
        });
      }
      if (!tmpItemDoc) {
        fieldError.push("ระบุรายการใบ CO");
      }
    } else {
      if (f.items.length < 1) {
        fieldError.push("ระบุรายการสินค้า");
      }
      if (e.invoice.length < 1) {
        fieldError.push("ระบุเลขใบกำกับสินค้า/วันที่");
      }
    }

    if (
      !this.textRegEx.test(e.consigneeNotify) &&
      (e.consigneeNotify != null ||
        e.consigneeNotify == undefined ||
        e.consigneeNotify != "")
    ) {
      fieldError.push(
        "ผู้ที่จะได้รับแจ้งเมื่อสินค้าไปถึงกรอกข้อมูลได้เฉพาะภาษาอังกฤษและอักขระพิเศษ"
      );
    }

    if (saveType == "saveQ") {
      if (e.attachments.length < 1) {
        fieldError.push("ระบุเอกสารประกอบคำขอ");
      }

      if (f.attachPic.length < 1) {
        fieldError.push("แนบรูปภาพประกอบการพิจารณา");
      }

      var tmpItemDoc = true;
      f.attachPic.map((x) => {
        if (
          !x.attachType ||
          x.attachType === undefined ||
          x.attachType == 0 ||
          x.attachType == ""
        ) {
          fieldError.push("เลือกประเภทเอกสารแนบ");
          tmpItemDoc = false;
          return;
        }
      });
    }

    if (fieldError.length === 0) {
      return true;
    } else {
      fieldError.forEach((e, i) => {
        fieldError[i] = (i + 1).toString() + ") " + e;
      });

      fieldError.unshift("กรุณาตรวจสอบข้อผิดพลาดดังต่อไปนี้");
      const result: string = fieldError.join("\n");

      this.alertService.open({ message: result, title: "" });
      return false;
    }
  }

  checkValidateInput() {}
  save() {
    this.clicked = true;
    const data = {
      docHid: this.form.docHid,
      Hid: this.form.id,
      requestBy: this.userProfile.uId,
      uId: this.userProfile.uId,
      userName: this.userProfile.userName,
      companyId: this.userProfile.cid,
      certificateDateRequest: this.form.certificateDateRequest,
      declarator:
        this.form.declaratorId !== undefined
          ? this.declaratorListRelation.find(
              (x) => x.cid == this.form.declaratorId
            ).companyNameEn
          : "",
      declaratorId: this.form.declaratorId,
      consignorName: this.form.consignorName,
      consignor:
        this.form.declaratorId !== undefined
          ? this.declaratorListRelation.find(
              (x) => x.cid == this.form.declaratorId
            ).companyNameEn
          : "",
      consignorId: this.form.declaratorId,
      coTotalPage: this.dataSourcecoPAck.length,
      liveSignature: this.form.liveSignature == true ? "Y" : "N",
      sigBranchId: this.form.sigBranchId,
      consignorAddress: this.form.consignorAddress,
      consigneeName: this.form.consigneeName,
      consigneeNotify:
        this.form.consigneeNotify == undefined
          ? " "
          : this.form.consigneeNotify,
      signAuthorizedId: this.form.signAuthorizedId,
      vesselType: this.form.vesselType == undefined ? "" : this.form.vesselType,
      vesselNo: this.form.vesselNo == undefined ? "" : this.form.vesselNo,
      loadingDate: this.form.loadingDate,
      destination: this.form.destination,
      destinationCountry: this.form.destinationCountry,
      invoiceChk: this.form.invoiceChk == true ? "Y" : "N",
      countryOfOrigin: this.form.countryOfOrigin,
      isPackRq: this.form.isPackRq == true ? "Y" : "N",
      //lastDraftVersion: this.form.lastDraftVersion,
      isRqstatus: this.form.isRqstatus ? this.form.isRqstatus : 3,
      remark: this.form.remark == undefined ? null : this.form.remark,
      attachments: this.form.attachments,
      items: this.form.items,
      invoice: this.form.invoice,
      isActive: "Y",
      packName: this.form.packName,
      docType: this.form.docType,
      tempName: this.form.tempName,
      certificateDate: this.form.certificateDate,
      coPack: this.cOPackList.filter((x) => x.invoice.length > 0),
      docPriority: this.form.docPriority ? this.form.docPriority : "5",
      id: this.form.id,
      sendType: this.form.sendType,
      footerDescription: this.form.remark?.length > 0 ? this.form.remark : "",
    };
    if (!this.form.isPackRq) {
      const coPackList = [
        {
          packId: this.form.docHid,
          remark: this.form.remark,
          items: this.form.items,
          invoice: this.form.invoice,
          countryOfOrigin: this.form.countryOfOrigin,
          // invoiceChk: this.form.invoiceChk == true  ? 'Y' : 'N',
        },
      ];
      data.coPack = coPackList;
    }
    if (this.subEdit) {
      const newData = {
        ...data,
        isCreateTemplate: this.form.isCreateTemplate,
      };
      return this.newForm.emit(newData);
    }

    //Revise Request API
    let dataReq = { ...data } as IReqSaveCO;
    if (dataReq.certificateDateRequest != "on_date") {
      dataReq.certificateDate = null;
    }
    if (dataReq.liveSignature == "N") {
      dataReq.sigBranchId = 0;
    }
    if (
      dataReq.isPackRq == "N" &&
      (dataReq.docHid == undefined || dataReq.docHid == null)
    ) {
      dataReq.docHid = 0;
    }

    if (dataReq.isPackRq == "Y") {
      dataReq.coPack.forEach((item) => {
        item.attachments = dataReq.attachments;
      });
    }
    let dataSource = {
      items: this.dataSourceGoodItem,
      attachPic: this.dataSourceAttachPic,
      invoice: this.dataSourceInvoice,
    };
    let valid = this.validate(data, dataSource, "save");
    if (valid) {
      data.invoice =
        this.deleteInvoiceList.length == 0
          ? this.form.invoice
          : this.form.invoice?.concat(this.deleteInvoiceList);
      data.attachments =
        this.deleteAttachmentList.length == 0
          ? this.form.attachments
          : this.form.attachments?.concat(this.deleteAttachmentList);
      data.items =
        this.deleteItemsList.length == 0
          ? this.form.items
          : this.form.items?.concat(this.deleteItemsList);

      this.appCOService.saveCO(dataReq).then((res: any) => {
        if (res === "error") {
          this.clicked = false;
          return;
        } else {
          if (this.form.isCreateTemplate) {
            if (this.form.isPackRq) {
              data.invoice = this.cOPackList[0].invoice;
              data.items = this.cOPackList[0].items;
            }

            data.footerDescription = data.remark;
            this.appCOService.saveTemplate(data).then((res: any) => {
              if (res == "error") {
                this.clicked = false;
                return;
              } else {
                this.alertService
                  .open({
                    message: "บันทึกสำเร็จ",
                    title: "ทำรายการสำเร็จ",
                  })
                  .then((x) => {
                    this.router.navigate(["/page/request/request-co/list-co"]);
                  });
              }
            });
          } else {
            this.alertService
              .open({
                message: "บันทึกสำเร็จ",
                title: "ทำรายการสำเร็จ",
              })
              .then((x) => {
                this.router.navigate(["/page/request/request-co/list-co"]);
              });
          }
        }
      });
    } else {
      this.clicked = false;
      return;
    }
  }

  saveQ() {
    this.clicked = true;
    const data = {
      docHid: this.form.docHid,
      //packId : this.form.packId,
      requestBy: this.userProfile.uId,
      uId: this.userProfile.uId,
      userName: this.userProfile.userName,
      companyId: this.userProfile.cid,
      certificateDateRequest: this.form.certificateDateRequest,
      declarator:
        this.form.declaratorId !== undefined
          ? this.declaratorListRelation.find(
              (x) => x.cid == this.form.declaratorId
            ).companyNameEn !== undefined
            ? this.declaratorListRelation.find(
                (x) => x.cid == this.form.declaratorId
              ).companyNameEn
            : ""
          : "",
      declaratorId: this.form.declaratorId,
      consignorName: this.form.consignorName,
      consignor:
        this.form.declaratorId !== undefined
          ? this.declaratorListRelation.find(
              (x) => x.cid == this.form.declaratorId
            ).companyNameEn
          : "",
      consignorId: this.form.declaratorId,
      coTotalPage: this.dataSourcecoPAck.length,
      liveSignature: this.form.liveSignature == true ? "Y" : "N",
      sigBranchId: this.form.sigBranchId,
      consignorAddress: this.form.consignorAddress,
      consigneeName: this.form.consigneeName,
      consigneeNotify:
        this.form.consigneeNotify == undefined
          ? " "
          : this.form.consigneeNotify,
      signAuthorizedId: this.form.signAuthorizedId,
      vesselType: this.form.vesselType == undefined ? "" : this.form.vesselType,
      vesselNo: this.form.vesselNo == undefined ? "" : this.form.vesselNo,
      loadingDate: this.form.loadingDate,
      destination: this.form.destination,
      destinationCountry: this.form.destinationCountry,
      invoiceChk: this.form.invoiceChk == true ? "Y" : "N",
      countryOfOrigin: this.form.countryOfOrigin,
      isPackRq: this.form.isPackRq == true ? "Y" : "N",
      lastDraftVersion: this.form.lastDraftVersion,
      isRqstatus: 4,
      remark: this.form.remark == undefined ? "" : this.form.remark,
      attachments: this.form.attachments,
      items: this.form.items,
      invoice: this.form.invoice,
      isActive: "Y",
      packName: this.form.packName,
      docType: this.form.docType,
      tempName: this.form.tempName,
      certificateDate: this.form.certificateDate,
      coPack: this.cOPackList.filter((x) => x.invoice.length > 0),
      docPriority: this.form.docPriority ? this.form.docPriority : "5", // ไม่ควร ทำไม่ต้องเป็น 5
      id: this.form.id,
      sendType: this.form.sendType,
    };
    if (!this.form.isPackRq) {
      const coPackList = [
        {
          packId: this.form.docHid,
          remark: this.form.remark,
          items: this.form.items,
          invoice: this.form.invoice,
          countryOfOrigin: this.form.countryOfOrigin,
          invoiceChk: this.form.invoiceChk == true ? "Y" : "N",
        },
      ];
      data.coPack = coPackList;
    }
    if (this.subEdit) {
      const newData = {
        ...data,
        isCreateTemplate: this.form.isCreateTemplate,
      };
      return this.newForm.emit(newData);
    }

    //Revise Request API
    let dataReq = { ...data } as IReqSaveCO;
    if (dataReq.certificateDateRequest != "on_date") {
      dataReq.certificateDate = null;
    }
    if (dataReq.liveSignature == "N") {
      dataReq.sigBranchId = 0;
    }
    if (
      dataReq.isPackRq == "N" &&
      (dataReq.docHid == undefined || dataReq.docHid == null)
    ) {
      dataReq.docHid = 0;
    }

    if (dataReq.isPackRq == "Y") {
      dataReq.coPack.forEach((item) => {
        item.attachments = dataReq.attachments;
      });
    }

    let dataSource = {
      items: this.dataSourceGoodItem,
      attachPic: this.dataSourceAttachPic,
      invoice: this.dataSourceInvoice,
    };
    let valid = this.validate(data, dataSource, "saveQ");
    if (valid) {
      (data.invoice =
        this.deleteInvoiceList.length == 0
          ? this.form.invoice
          : this.form.invoice?.concat(this.deleteInvoiceList)),
        (dataReq.invoice =
          this.deleteInvoiceList.length == 0
            ? this.form.invoice
            : this.form.invoice?.concat(this.deleteInvoiceList));
      let print = {
        docHid: this.form.docHid,
        //packId : this.form.packId,
        requestBy: this.userProfile.uId,
        uId: this.userProfile.uId,
        userName: this.userProfile.userName,
        companyId: this.userProfile.cid,
        certificateDateRequest: this.form.certificateDateRequest,
        declarator:
          this.form.declaratorId !== undefined
            ? this.declaratorListRelation.find(
                (x) => x.cid == this.form.declaratorId
              ).companyNameEn
            : "",
        declaratorId: this.form.declaratorId,
        consignorName: this.form.consignorName,
        consignor:
          this.form.declaratorId !== undefined
            ? this.declaratorListRelation.find(
                (x) => x.cid == this.form.declaratorId
              ).companyNameEn
            : "",
        consignorId: this.form.declaratorId,
        coTotalPage: this.dataSourcecoPAck.length,
        liveSignature: this.form.liveSignature == true ? "Y" : "N",
        sigBranchId: this.form.sigBranchId,
        consignorAddress: this.form.consignorAddress,
        consigneeName: this.form.consigneeName,
        consigneeNotify: this.form.consigneeNotify,
        signAuthorizedId: this.form.signAuthorizedId,
        vesselType:
          this.form.vesselType == undefined ? "" : this.form.vesselType,
        vesselNo: this.form.vesselNo == undefined ? "" : this.form.vesselNo,
        loadingDate: this.form.loadingDate,
        destination: this.form.destination,
        destinationCountry: this.form.destinationCountry,
        invoiceChk: this.form.invoiceChk == true ? "Y" : "N",
        countryOfOrigin: this.form.countryOfOrigin,
        isPackRq: this.form.isPackRq == true ? "Y" : "N",
        lastDraftVersion: this.form.lastDraftVersion,
        isRqstatus: 4,
        remark: this.form.remark == undefined ? "" : this.form.remark,
        attachments: this.form.attachments,
        items: this.form.items,
        invoice: this.form.invoice,
        isActive: "Y",
        packName: this.form.packName,
        docType: this.form.docType,
        tempName: this.form.tempName,
        certificateDate: this.form.certificateDate,
        coPack: this.cOPackList.filter(
          (x) => x.invoice.length > 0 && x.isActive == "Y"
        ),
        docPriority: this.form.docPriority ? this.form.docPriority : "5", // ไม่ควร ทำไม่ต้องเป็น 5
        id: this.form.id,
        sendType: this.form.sendType,
      };
      if (!this.form.isPackRq) {
        const coPackList = [
          {
            packId: this.form.docHid,
            remark: this.form.remark,
            items: this.form.items,
            invoice: this.form.invoice,
            countryOfOrigin: this.form.countryOfOrigin,
            invoiceChk: this.form.invoiceChk == true ? "Y" : "N",
          },
        ];
        print.coPack = coPackList;
      }
      const previewPrint = {
        previewCO: print,
      };
      if (dataReq.isPackRq == "Y" || this.form.isPackRq == true) {
        this.runProcessCoByPack(previewPrint, dataReq, data);
      } else {
        this.runProcessCoByItem(previewPrint, dataReq, data);
      }
    } else {
      this.clicked = false;
      return;
    }
  }
  runProcessCoByPack(previewPrint: any, dataReq: any, data: any) {
    let dataModale = {
      isPack: "Y",
      previewPrint: previewPrint.previewCO,
      respone: null,
    };
    const dialogRef = this.dialog.open(DraftCOPopupComponent, {
      disableClose: true,
      width: "40vw",
      data: {
        data: dataModale,
      },
      maxWidth: "none",
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res == false) {
        this.clicked = false;
        return;
      } else {
        let dataPopup = {
          ...res,
          docPriority: this.form.docPriority ? this.form.docPriority : "5",
          id: data.id,
          rqStatus: this.form.isRqstatus,
        };

        const queueDialog = this.dialog.open(QueuePopupComponent, {
          disableClose: true,
          width: "25vw",
          data: dataPopup,
          maxWidth: "none",
        });
        queueDialog.afterClosed().subscribe((res) => {
          if (res == false || res === undefined) {
            this.clicked = false;
            return;
          } else {
            dataReq.docPriority = res.docPriority;
            this.appCOService.saveCO(dataReq).then((res: any) => {
              if (res === "error") {
                this.clicked = false;
                return;
              } else {
                if (this.form.isCreateTemplate) {
                  if (this.form.isPackRq) {
                    data.invoice = this.cOPackList[0].invoice;
                    data.items = this.cOPackList[0].items;
                  }

                  data.footerDescription = dataReq.remark;

                  this.appCOService.saveTemplate(data).then((res: any) => {
                    if (res == "error") {
                      return;
                    } else {
                    }
                  });
                }
                this.alertService
                  .open({
                    message: "บันทึกสำเร็จ",
                    title: "ทำรายการสำเร็จ",
                  })
                  .then((x) => {
                    this.router.navigate(["/page/request/request-co/list-co"]);
                  });
              }
            });
          }
        });
      }
    });
  }
  runProcessCoByItem(previewPrint: any, dataReq: any, data: any) {
    this.appCOService.getPreviewCOPack(previewPrint).then((res) => {
      if (res == "error") {
        this.clicked = false;
        return;
      } else {
        let dataModale = {
          isPack: "N",
          previewPrint: previewPrint.previewCO,
          respone: res,
        };
        const dialogRef = this.dialog.open(DraftCOPopupComponent, {
          disableClose: true,
          width: "40vw",
          data: {
            data: res,
          },
          maxWidth: "none",
        });
        dialogRef.afterClosed().subscribe((res) => {
          if (res == false) {
            this.clicked = false;
            return;
          } else {
            let dataPopup = {
              ...res,
              docPriority: this.form.docPriority ? this.form.docPriority : "5",
              id: data.id,
              rqStatus: this.form.isRqstatus,
            };
            const queueDialog = this.dialog.open(QueuePopupComponent, {
              disableClose: true,
              width: "25vw",
              data: dataPopup,
              maxWidth: "none",
            });
            queueDialog.afterClosed().subscribe((res) => {
              if (res == false || res === undefined) {
                this.clicked = false;
                return;
              } else {
                dataReq.docPriority = res.docPriority;
                this.appCOService.saveCO(dataReq).then((res: any) => {
                  if (res === "error") {
                    this.clicked = false;
                    return;
                  } else {
                    if (this.form.isCreateTemplate) {
                      if (this.form.isPackRq) {
                        data.invoice = this.cOPackList[0].invoice;
                        data.items = this.cOPackList[0].items;
                      }

                      data.footerDescription = dataReq.remark;

                      this.appCOService.saveTemplate(data).then((res: any) => {
                        if (res == "error") {
                          return;
                        } else {
                        }
                      });
                    }
                    this.alertService
                      .open({
                        message: "บันทึกสำเร็จ",
                        title: "ทำรายการสำเร็จ",
                      })
                      .then((x) => {
                        this.router.navigate([
                          "/page/request/request-co/list-co",
                        ]);
                      });
                  }
                });
              }
            });
          }
        });
      }
    });
  }

  addInvoice() {
    if (this.dataSourceInvoice.length >= 4) {
      return;
    }
    const dialogRef = this.dialog.open(CreateInvoicePopupComponent, {
      disableClose: false,
      width: "70vw",
      data: {
        form: { invoiceId: 0, docHid: 0, invoiceNum: 0, isActive: "Y" },
        editItem: this.editItem,
      },
      maxWidth: "none",
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.form.invoice.push(res);
        this.dataSourceInvoice.push(res);
      }
    });
  }
  deleteInvoice(e) {
    this.confirmService
      .open({
        header: "ยืนยันการทำรายการ",
        content: "ต้องการลบรายการใบกำกับภาษี",
      })
      .then((res) => {
        if (res == true) {
          if (e.data.invoiceId != 0) {
            this.dataSourceInvoice.splice(e.rowIndex, 1);
            const updInvoice = this.form.invoice.findIndex(
              (obj) => obj.invoiceId == e.data.invoiceId
            );
            this.form.invoice[updInvoice].isActive = "N";
          } else {
            // this.form.items[e.rowIndex].isActive = "N"
            this.dataSourceInvoice.splice(e.rowIndex, 1);
            this.form.invoice.splice(e.rowIndex, 1);
          }
        }
      });
  }
  editInvoice(e) {
    const dialogRef = this.dialog.open(CreateInvoicePopupComponent, {
      disableClose: false,
      width: "70vw",
      data: {
        form: {
          invoiceNo: e.data.invoiceNo,
          invoiceDate: e.data.invoiceDate,
          invoiceId: e.data.invoiceId,
          docHid: e.data.docHid,
          invoiceNum: e.data.invoiceNum,
        },
        editItem: this.editItem,
      },
      maxWidth: "none",
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.form.invoice.splice(e.rowIndex, 1, res);
        this.dataSourceInvoice.splice(e.rowIndex, 1, res);
      }
    });
  }
  addGoods() {
    const dialogRef = this.dialog.open(CreateGoodListPopupComponent, {
      disableClose: false,
      width: "70vw",
      maxHeight: "100vh",
      data: {
        edit: true,
        data: {},
      },
      maxWidth: "none",
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        res.id = this.form.items.length + 1;
        const data = {
          docItemId: 0,
          harmonizedCode: res.harmonizedCode,
          markChk: res.markCHK == true ? "Y" : "N",
          markItem: res.markItem,
          descriptionChk: res.descriptionCHK == true ? "Y" : "N",
          description: res.description,
          quantity: res.quantity,
          quantityChk: res.quantityCHK == true ? "Y" : "N",
          quantityUnit: res.quantityObj.name,
          quantityUnitCode: res.quantityObj.code,
          weight: parseFloat(res.weight).toFixed(3),
          weightChk: res.weightCHK == true ? "Y" : "N",
          weightUnit: res.weightObj.name,
          weightUnitCode: res.weightObj.code,
          grossWeight: parseFloat(res.grossWeight).toFixed(3),
          grossWeightChk: res.grossWeightCHK == true ? "Y" : "N",
          grossWeightUnit: res.grossWeightObj.name,
          grossWeightUnitCode: res.grossWeightObj.code,
          exportValue: parseFloat(res.exportValue).toFixed(3),
          exchangeRate: res.exchangeRate,
          exchangeValue: parseFloat(res.exchangeValue).toFixed(3),
          docHeaderId: 0,
          isActive: "Y",
        };
        this.form.items.push(data);
        this.dataSourceGoodItem.push(data);
      }
      // receive data from dialog in res
    });
  }

  async onFileChangedBr(event): Promise<void> {
    if (event.value.length === 0) {
      return;
    }
    // const file = event.value.files[0];
    event.value.forEach((item) => {
      this.file = item;
      this.resizeImage(this.file);
    });
    this.value = [];
  }

  resizeImage(file) {
    var maxW = 1024; //กำหนดความกว้าง
    var maxH = 800; //กำหนดความสูง
    var myReader: FileReader = new FileReader();
    var canvas = document.createElement("canvas");
    var context = canvas.getContext("2d");

    var img = document.createElement("img");
    var that = this;
    var result = "";

    img.onload = async function () {
      var iw = img.width;
      var ih = img.height;

      var scale = Math.min(maxW / iw, maxH / ih);
      var iwScaled = iw * scale;
      var ihScaled = ih * scale;

      canvas.width = iwScaled;
      canvas.height = ihScaled;

      context.drawImage(img, 0, 0, iwScaled, ihScaled);

      result += canvas.toDataURL("image/jpeg", 0.5); //0.5 คือ คุณภาพของรูป ที่ Resize

      that.imageResize = result; //Base64 รูปภาพ ที่ย่อขนาดแล้ว

      await that.onUpload(file);
      var a = myReader.readAsDataURL(file);
    };

    img.src = URL.createObjectURL(file);
  }

  dataUrlToFile(dataUrl: string, filename: string): File | undefined {
    const arr = dataUrl.split(",");
    if (arr.length < 2) {
      return undefined;
    }
    const mimeArr = arr[0].match(/:(.*?);/);
    if (!mimeArr || mimeArr.length < 2) {
      return undefined;
    }
    const mime = mimeArr[1];
    const buff = Buffer.from(arr[1], "base64");
    return new File([buff], filename, { type: mime });
  }

  upload() {
    if (this.value.length > 0) {
      if (this.value.filter((x) => x.size > 3145728).length > 0) {
        this.alertService.open({
          message: "รูปมีขนาดใหญ่กว่า 3 MB",
          title: "โปรดตรวจสอบขนาดรูปภาพ",
        });
        return;
      }

      if (
        this.value.filter(
          (x) => x.type != "image/jpeg" && x.type != "image/png"
        ).length > 0
      ) {
        this.alertService.open({
          message:
            "รูปที่เลือกไม่ถูกต้อง กรุณาอัปโหลดเฉพาะไฟล์ .JPG หรือ .PNG เท่านั้น หรือตรวจสอบการตั้งค่าเครื่องสแกนเนอร์",
          title: "",
        });
        return;
      }

      this.value.forEach(async (file) => {
        const isValid = await this.checkIfImageIsValid(file);
        if (!isValid) {
          this.alertService.open({
            message:
              "รูปที่เลือกไม่ถูกต้อง กรุณาอัปโหลดไฟล์ที่ไม่ใช่ Animated PNG",
            title: "โปรดตรวจสอบรูปภาพ",
          });
          return;
        }

        const fileName = file.name;
        const formData = new FormData();
        formData.append("image", file, fileName);
        this.appMasterService.uploadFileBatch(formData).subscribe((result) => {
          if (result) {
            result.forEach((element) => {
              let index = this.form.attachments.length + 1;
              const data = {
                attachPicId: 0,
                docHid: 0,
                attachFilePic: element?.filePath,
                attachType: 0,
                attachIndex: index,
                remark: "",
                attachHeaderType: 2,
                isActive: "Y",
              };
              this.form.attachments.push(data);
              this.dataSourceAttachPic.push(data);
            });

            this.formSourceUpload = [];
            this.formSourceUpload = [...this.form.attachments];
            this.dataSourceUpload = [];
            this.dataSourceUpload = [...this.dataSourceAttachPic];
          } else {
            return;
          }
        });
      });
      this.value = [];
    } else {
      this.alertService.open({
        message: "กรุณาเลือกรูปภาพหรือเพิ่มเอกสาร 1 ชนิด",
        title: "",
      });
    }
  }

  onUpload(file: any): void {
    if (file.size > 24000000) {
      this.alertService.open({
        message: "รูปมีขนาดใหญ่กว่า 3 MB",
        title: "โปรดตรวจสอบขนาดรูปภาพ",
      });
      return;
    }
    this.checkIfImageIsValid(file).then((isValid) => {
      if (!isValid) {
        this.alertService.open({
          message: "รูปที่เลือกไม่ถูกต้อง กรุณาอัปโหลดไฟล์ที่ไม่ใช่ Animated PNG",
          title: "โปรดตรวจสอบรูปภาพ",
        });
        return;
      }

      const formData = new FormData();
      formData.append("image", file, file.name);
      this.appMasterService.uploadFile(formData).subscribe((result) => {
        if (result) {
          let index = this.form.attachments.length + 1;
          const data = {
            attachPicId: 0,
            docHid: 0,
            attachFilePic: result[0]?.filePath,
            attachType: 0,
            attachIndex: index,
            remark: "",
            attachHeaderType: 2,
            isActive: "Y",
          };

          this.form.attachments.push(data);
          this.dataSourceAttachPic.push(data);

          this.formSourceUpload = [];
          this.formSourceUpload = [...this.form.attachments];
          this.dataSourceUpload = [];
          this.dataSourceUpload = [...this.dataSourceAttachPic];
        }
      });
    });
  }

  checkIfImageIsValid(file: File): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onload = () => {
        const buffer = reader.result as ArrayBuffer;
        const uint8Array = new Uint8Array(buffer);
  
        // PNG signature bytes: 137 80 78 71 13 10 26 10
        const pngSignature = [137, 80, 78, 71, 13, 10, 26, 10];
        // JPEG signature bytes: 255 216 255
        const jpgSignature = [255, 216, 255];
  
        // Check if it's a PNG by matching the signature
        const isPng = pngSignature.every((byte, i) => uint8Array[i] === byte);
  
        // Check if it's a JPEG by matching the signature
        const isJpg = jpgSignature.every((byte, i) => uint8Array[i] === byte);
  
        if (isPng) {
          // Search for the acTL chunk in the PNG file (animation chunk)
          const acTLIndex = this.findAcTLChunk(uint8Array);
          if (acTLIndex !== -1) {
            resolve(false); // Animated PNG
          } else {
            resolve(true); // Static PNG
          }
        } else if (isJpg) {
          resolve(true); // Valid JPEG
        } else {
          reject(new Error('Invalid file format')); // Not PNG or JPEG
        }
      };
  
      reader.onerror = () => reject(reader.error);
  
      reader.readAsArrayBuffer(file);
    });
  }
  
  findAcTLChunk(uint8Array: Uint8Array): number {
    // Search for the "acTL" chunk in the PNG file starting after the 8-byte PNG header
    const acTLString = "acTL";
    const decoder = new TextDecoder("ascii");
  
    for (let i = 12; i < uint8Array.length - 4; i++) {
      const chunk = decoder.decode(uint8Array.slice(i, i + 4));
      if (chunk === acTLString) {
        return i; // Found the acTL chunk
      }
    }
    return -1; // acTL chunk not found
  }  

  onDataSourceUpdate(e) {
    this.dataSourceAttachPic = e;
  }

  onFormSourceUpdate(e) {
    this.form.attachments = e;
  }

  // onUploadBr(): void {
  //   const formData = new FormData()
  //   formData.append('image', this.file, this.file.name)
  //   this.appMasterService.uploadFile(formData).subscribe((result) => {
  //     if (result) {
  //       let index = this.form.attachments.length + 1
  //       const data = {
  //         attachPicId: 0,
  //         docHid: 0,
  //         attachFilePic: result[0]?.filePath,
  //         attachType: 0,
  //         attachIndex: index,
  //         remark: '-',
  //         attachHeaderType: 2,
  //         isActive: 'Y',
  //       }
  //       // this.fileImg = result[0]?.filePath;
  //       // this.pic = result[0]?.fileName + result[0]?.fileType;
  //       this.form.attachments.push(data)
  //       this.dataSourceAttachPic.push(data)
  //     }
  //   })
  // }
  addCOPack(e: any) {
    var coPackItem = this.cOPackList.find((x) => x.key == e.data.key);
    var data = {
      ...coPackItem,
      id: e.data.docHid != 0 ? e.data.docHid : coPackItem.key,
      invoice: e.data.docHid != 0 ? coPackItem.invoice : coPackItem.invoice,
      items: e.data.docHid != 0 ? coPackItem.items : coPackItem.items,
      countryOfOrigin:
        e.data.docHid != 0
          ? coPackItem.countryOfOrigin
          : coPackItem.countryOfOrigin,
      invoiceChk:
        e.data.docHid != 0 ? coPackItem.invoiceChk : coPackItem.invoiceChk,
      remark: e.data.docHid != 0 ? coPackItem.remark : coPackItem.remark,
      editItem: this.editItem,
      docHid: e.data.docHid != 0 ? e.data.docHid : 0,
      isActive: "Y",
      invoiceEdit: this.isDisableFieldInvoiceNoDate,
      itemEdit: this.isDisableFieldItemDescription,
      countryOfOriginEdit: this.isDisableFieldCountryOfOrigin,
      footerEdit: this.isDisableFieldFooterDescription,
      isRqstatus: this.form.isRqstatus,
    };
    const dialogRef = this.dialog.open(CreateCoPackPopupComponent, {
      disableClose: false,
      width: "70vw",
      data: data,
      maxWidth: "none",
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        moment.locale("th");
        res.packId = 0;
        res.invoiceChk = res.invoiceChk == true ? "Y" : "N";
        // receive data from dialog in res
        const invoiceList = res.invoice.map((e) => e.invoiceNo);
        res.invoiceNo = invoiceList.join(", ");
        const invoiceDate = res.invoice.map((e) => {
          e.invoiceDate = moment(e.invoiceDate).format("YYYY-MM-DD");
          return e.invoiceDate;
        });
        res.invoiceDate = invoiceDate;
        this.dataSourcecoPAck = this.dataSourcecoPAck.map((x) => {
          if (x.key != res.key) {
            return {
              ...x,
            };
          }
          return {
            ...res,
          };
        });

        this.cOPackList = this.cOPackList.map((x) => {
          if (x.key != res.key) {
            return {
              ...x,
            };
          }
          return {
            ...res,
          };
        });

        // this.cOPackList.splice(e.rowIndex, 1, res)

        // this.dataSourcecoPAck.splice(e.rowIndex, 1, res)
      }
    });
  }
  handlePackList(num: Number, action: String) {
    switch (action) {
      case "add":
        this.addPackList(num);
        break;
      case "remove":
        this.removePackList(num);
        break;
      default:
        break;
    }
  }
  removePackList(num): void {
    let count = this.dataSourcecoPAck.length;
    this.dataSourcecoPAck = this.dataSourcecoPAck.filter(
      (x) => x.key <= count - num
    );
  }

  addPackList(num): void {
    let count = this.dataSourcecoPAck.length;

    for (let index = 1; index <= num; index++) {
      this.dataSourcecoPAck.push({
        countryOfOrigin: "",
        docHid: 0,
        id: 0,
        key: count + index,
        invoice: [],
        invoiceChk: "N",
        invoiceNo: "กรุณาระบุ Invoice No",
        invoiceDate: "กรุณาระบุ Invoice Date",
        isActive: "Y",
        isNew: false,
        items: [],
        packId: 0,
        remark: "",
      });
      this.cOPackList.push({
        countryOfOrigin: "",
        docHid: 0,
        id: 0,
        key: count + index,
        invoice: [],
        invoiceChk: "N",
        invoiceNo: "กรุณาระบุ Invoice No",
        invoiceDate: "กรุณาระบุ Invoice Date",
        isActive: "Y",
        isNew: false,
        items: [],
        packId: 0,
        remark: "",
      });
    }
  }

  amountRequestChanged(e) {
    if (this.form.coTotalPage > 10) {
      this.form.coTotalPage = 10;
    }
    if (this.dataSourcecoPAck.filter((x) => x.isNew).length > 0) {
      this.confirmService
        .open({
          header: "คุณต้องการเปลียนจำนวนชุดใช่หรือไม่",
          content: "หากต้องการข้อมูลที่บันทึกรายการ CO | LIST CO จะหายไป",
        })
        .then((res) => {
          if (res) {
            if (this.dataSourcecoPAck.length == 0) {
              this.handlePackList(this.form.coTotalPage, "add");
              return;
            }
            if (this.dataSourcecoPAck.length > this.form.coTotalPage) {
              this.handlePackList(
                this.dataSourcecoPAck.length - this.form.coTotalPage,
                "remove"
              );
              return;
            }
            if (this.dataSourcecoPAck.length < this.form.coTotalPage) {
              this.handlePackList(
                this.form.coTotalPage - this.dataSourcecoPAck.length,
                "add"
              );
            }
          }
          return;
        });
    } else {
      if (this.dataSourcecoPAck.length == 0) {
        this.handlePackList(this.form.coTotalPage, "add");
        return;
      }
      if (this.dataSourcecoPAck.length > this.form.coTotalPage) {
        this.handlePackList(
          this.dataSourcecoPAck.length - this.form.coTotalPage,
          "remove"
        );
        return;
      }
      if (this.dataSourcecoPAck.length < this.form.coTotalPage) {
        this.handlePackList(
          this.form.coTotalPage - this.dataSourcecoPAck.length,
          "add"
        );
      }
    }
  }

  addNewRow() {
    if (this.dataSourcecoPAck.length >= 10) {
      return;
    }
    this.handlePackList(1, "add");
    this.form.coTotalPage = this.form.coTotalPage + 1;
  }
  deleteCOPack(e: any) {
    this.confirmService
      .open({
        header: "ยืนยันการทำรายการ",
        content: "ต้องการลบรายการใบ CO",
      })
      .then((res) => {
        if (res) {
          if (e.data.docHid != 0) {
            // this.dataSourcecoPAck.splice(e.rowIndex, 1)
            this.dataSourcecoPAck = this.dataSourcecoPAck.filter((x) => {
              let date = x.key;
              return x.key != e.data.key;
            });
            const updCoPack = this.cOPackList.findIndex(
              (obj) => obj.docHid == e.data.docHid
            );
            this.cOPackList[updCoPack].isActive = "N";
            this.form.coTotalPage = this.form.coTotalPage - 1;
          } else {
            // this.form.items[e.rowIndex].isActive = "N"
            this.dataSourcecoPAck = this.dataSourcecoPAck
              .filter((x) => {
                let date = x.key;
                return x.key != e.data.key;
              })
              .map((x, i) => {
                return {
                  ...x,
                  key: i + 1,
                };
              });
            this.cOPackList = this.cOPackList
              .filter((x) => {
                let date = x.key;
                return x.key != e.data.key;
              })
              .map((x, i) => {
                return {
                  ...x,
                  key: i + 1,
                };
              });

            this.form.coTotalPage = this.form.coTotalPage - 1;
          }
        }
      });
  }

  isPackChange(e) {
    if (e == false) {
      this.cOPackList = [];
      this.dataSourcecoPAck = [];
      this.form.coTotalPage = 0;
      this.form.packName = "";
    } else {
      this.form.coTotalPage = 1;
    }
  }

  emitForm() {
    console.log("emitForm", this.form.signAuthorizedId);
    const data = {
      requestBy: this.userProfile.uId,
      uId: this.userProfile.uId,
      userName: this.userProfile.userName,
      companyId: this.userProfile.cid,
      certificateDateRequest: this.form.certificateDateRequest,
      declarator:
        this.form.declaratorId !== undefined
          ? this.declaratorList.find((x) => x.cid == this.form.declaratorId)
              .companyNameTh
          : "",
      declaratorId: this.form.declaratorId,
      consignorName: this.form.consignorName,
      consignor:
        this.form.declaratorId !== undefined
          ? this.declaratorList.find((x) => x.cid == this.form.declaratorId)
              .companyNameTh
          : "",
      consignorId: this.form.declaratorId,
      coTotalPage:
        this.form.coTotalPage == undefined
          ? 0
          : this.cOPackList.filter((x) => x.isActive == "Y").length,
      liveSignature: this.form.liveSignature == true ? "Y" : "N",
      sigBranchId: this.form.sigBranchId,
      consignorAddress: this.form.consignorAddress,
      consigneeName: this.form.consigneeName,
      consigneeNotify: this.form.consigneeNotify,
      signAuthorizedId: this.form.signAuthorizedId,
      vesselType: this.form.vesselType == undefined ? "" : this.form.vesselType,
      vesselNo: this.form.vesselNo == undefined ? "" : this.form.vesselNo,
      loadingDate: this.form.loadingDate,
      destination: this.form.destination,
      destinationCountry: this.form.destinationCountry,
      invoiceChk: this.form.invoiceChk == true ? "Y" : "N",
      countryOfOrigin: this.form.countryOfOrigin,
      isPackRq: this.form.isPackRq == true ? "Y" : "N",
      lastDraftVersion: this.form.lastDraftVersion,
      isRqstatus: 5,
      remark: this.form.remark == undefined ? "" : this.form.remark,
      attachments: this.form.attachments,
      items: this.form.items,
      invoice: this.form.invoice,
      isActive: "Y",
      packName: this.form.packName,
      docType: this.form.docType,
      tempName: this.form.tempName,
      certificateDate: this.form.certificateDate,
      coPack: this.cOPackList,
      docPriority: "5",
      id: this.form.id,
    };
    if (!this.form.isPackRq) {
      const coPackList = [
        {
          packId: this.form.packId,
          remark: this.form.remark,
          items: this.form.items,
          invoice: this.form.invoice,
          countryOfOrigin: this.form.countryOfOrigin,
          invoiceChk: this.form.invoiceChk == true ? "Y" : "N",
        },
      ];
      data.coPack = coPackList;
    }

    if (this.subEdit) {
      const newData = {
        ...data,
        isCreateTemplate: this.form.isCreateTemplate,
      };
      let checked = this.validate(data, this.dataSourceAttachPic, "save");
      if (!checked) {
        this.clicked = false;
        return;
      }
      return this.newForm.emit(newData);
    }
  }

  editGood(e) {
    let newData = { ...e.data };

    const dialogRef = this.dialog.open(CreateGoodListPopupComponent, {
      disableClose: false,
      width: "70vw",
      maxHeight: "100vh",
      data: { data: newData, edit: true },
      maxWidth: "none",
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        e.data = newData;
        res.id = e.data.id;
        const data = {
          docItemId: res.docItemId,
          harmonizedCode: res.harmonizedCode,
          markChk: res.markCHK == true ? "Y" : "N",
          markItem: res.markItem,
          descriptionChk: res.descriptionCHK == true ? "Y" : "N",
          description: res.description,
          quantity: res.quantity,
          quantityChk: res.quantityCHK == true ? "Y" : "N",
          quantityUnit: res.quantityObj.name,
          quantityUnitCode: res.quantityObj.code,
          weight: res.weight,
          weightChk: res.weightCHK == true ? "Y" : "N",
          weightUnit: res.weightObj.name,
          weightUnitCode: res.weightObj.code,
          grossWeight: res.grossWeight,
          grossWeightChk: res.grossWeightCHK == true ? "Y" : "N",
          grossWeightUnit: res.grossWeightObj.name,
          grossWeightUnitCode: res.grossWeightObj.code,
          exportValue: res.exportValue,
          exchangeRate: res.exchangeRate,
          exchangeValue: res.exchangeValue,
          docHeaderId: res.docHeaderId,
          isActive: "Y",
        };
        this.form.items.splice(e.rowIndex, 1, data);
        this.dataSourceGoodItem.splice(e.rowIndex, 1, data);
      }
    });
  }

  previewGood(e) {
    let newData = { ...e.data };
    const dialogRef = this.dialog.open(CreateGoodListPopupComponent, {
      disableClose: false,
      width: "70vw",
      maxHeight: "100vh",
      data: { data: newData, edit: false },
      maxWidth: "none",
    });
  }

  deleteGood(e) {
    this.confirmService
      .open({
        header: "ยืนยันการทำรายการ",
        content: "ต้องการลบรายการสินค้า",
      })
      .then((res) => {
        if (res == true) {
          if (e.data.docHeaderId != 0) {
            this.dataSourceGoodItem.splice(e.rowIndex, 1);
            const updGoodItem = this.form.items.findIndex(
              (obj) => obj.docItemId == e.data.docItemId
            );
            this.form.items[updGoodItem].isActive = "N";
          } else {
            // this.form.items[e.rowIndex].isActive = "N"
            this.dataSourceGoodItem.splice(e.rowIndex, 1);
            this.form.items.splice(e.rowIndex, 1);
          }
        }
      });
  }

  selectType(e, row) {
    row.attachType = e.value;
  }

  inputRemark(e, row) {
    row.remark = e.target.value;
  }

  async onChange(e) {
    let requestCompanyRelation = {
      CPID: this.userProfile.uId,
      CID: this.userProfile.cid,
      isActive: "Y",
    };

    this.appCOService
      .getRelationCompany(requestCompanyRelation)
      .then((res: any) => {
        if (res === "error") {
          return;
        } else {
          // ผู้ส่งออกสินค้า
          if (res != null) {
            res = res.map((item) => {
              if (item.blacklistStatus == "Y") {
                item.companyNameEn = item.companyNameEn + " " + "(Blacklist)";
                item.companyNameTh = item.companyNameTh + " " + "(ติดแบล็คลิส)";
              }
              return item;
            });
          }
          this.declaratorListRelation = [];
          this.declaratorListRelation = res;
        }
      });

    let request = {
      CPID: this.userProfile.uId,
      CID: this.userProfile.cid,
      isActive: "Y",
    };
    this.appCOService.getListDeclaratorCompany(request).then((res: any) => {
      if (res === "error") {
        return;
      } else {
        this.declaratorList = [];
        this.declaratorList = res;
        // this.form.requestBy = this.declaratorList[0]?.companyNameEn
        this.form.consignorName =
          this.form.consignorName ?? this.declaratorList[0]?.companyNameEn;
      }
    });
    await this.appMasterService
      .getUserAddress({ id: this.userProfile.cid })
      .then((res: any) => {
        if (res === "error") {
          return;
        } else {
          this.form.consignorAddress = res?.address;
        }
      });
  }
  deleteAttachment(e) {
    this.confirmService
      .open({
        header: "ยืนยันการทำรายการ",
        content: "ต้องการยกเลิกคำขอ",
      })
      .then((res) => {
        if (res == true) {
          if (e.data.attachPicId != 0) {
            this.dataSourceAttachPic.splice(e.rowIndex, 1);
            const updAttach = this.form.attachments.findIndex(
              (obj) => obj.attachPicId == e.data.attachPicId
            );
            this.form.attachments[updAttach].isActive = "N";
          } else {
            // this.form.items[e.rowIndex].isActive = "N"
            this.dataSourceAttachPic.splice(e.rowIndex, 1);
            this.form.attachments.splice(e.rowIndex, 1);
          }
        }
      });
  }
  checkchange(e, c, a) {
    this.form.attachments[c.rowIndex].attachType = e.value;
  }

  onClick(e) {
    const dialogRef = this.dialog.open(CoErrorMarkImagePopupComponent, {
      disableClose: false,
      // width: '30vw',
      width: "auto",
      height: "auto",
      data: {
        urlPic: e.data.urlPic,
        px: e.data.pos_x,
        py: e.data.pos_y,
        mark: e.data.remark,
      },
    });
  }

  onView(e) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.minHeight = "30vh";
    dialogConfig.minWidth = "30vh";
    dialogConfig.maxHeight = "100vh";
    dialogConfig.data = {
      fullImageUrl: e.data.attachFilePic,
    };

    const dialogRef = this.dialog.open(ImageModalComponent, dialogConfig);
  }
  async DropdromwChange(event, isTemplate) {
    let e = event.value == undefined ? event : event.value;
    var checkData = this.declaratorListRelation.find((x) => x.cid === e);

    if (checkData.emailStatus === "N") {
      this.alertService.open({
        message:
          "บริษัทผู้ส่งออกนี้ ยังไม่ได้ลงทะเบียนรับเอกสารอิเล็กทรอนิกส์ กรุณาลงทะเบียนด้วย User admin ของบริษัทผู้ส่งออก",
        title: "",
      });
    }

    let request = {
      CPID: this.userProfile.uId,
      CID: this.form.declaratorId,
      isActive: "Y",
    };
    await this.appCOService
      .getListDeclaratorCompany(request)
      .then((res: any) => {
        if (res === "error") {
          return;
        } else {
          this.declaratorList = [];
          this.declaratorList = res;
          var data = _.first(res);
          const compName: string = data?.companyNameEn;
          this.form.consignorName = isTemplate
            ? this.form.consignorName
            : compName
            ? compName.toUpperCase()
            : "";
        }
      });
    this.appMasterService
      .getUserAddress({ id: this.form.declaratorId })
      .then((res: any) => {
        if (res === "error") {
          return;
        } else {
          const address: string = res?.address;
          this.form.consignorAddress = isTemplate
            ? this.form.consignorAddress
            : address
            ? address.toUpperCase()
            : "";
        }
      });
    let getListNameLastNameRequest = {
      CPID: e,
      isActive: "Y",
    };
    this.appMasterService
      .getListNameLastName(getListNameLastNameRequest)
      .then((res: any) => {
        if (res === "error") {
          return;
        } else {
          this.nameLastNameList = [];
          this.nameLastNameList = res;
          //this.form.signAuthorizedId = null
          if (res?.length === 0) {
            this.nameLastNameList.unshift({
              csid: "",
              fullNameThai: "ไม่พบข้อมูลลายมือชื่อผู้มอบอำนาจ",
            });
            if (!this.form.signAuthorizedId) {
              // this.form.signAuthorizedId = "";
            }
          } else {
            this.nameLastNameList = res;
            if (this.nameLastNameList.length === 1) {
              const firstRecord = _.first(res);
              this.form.signAuthorizedId = isTemplate
                ? this.signId
                : firstRecord.csid;
            } else {
              const checkAll = this.nameLastNameList.find(
                (e) => e.fullNameThai === "กรุณาเลือก"
              );
              const checkSign = this.nameLastNameList.find((f) =>
                isTemplate ? f.csid == this.signId : ""
              );
              if (checkAll === undefined) {
                this.nameLastNameList.unshift({
                  csid: "",
                  fullNameThai: "กรุณาเลือก",
                });
                this.form.signAuthorizedId =
                  isTemplate && checkSign != undefined ? this.signId : "";
              }
            }
          }
        }
      });
  }

  limitTextareaLines(event: Event) {
    const textarea = event.target as HTMLTextAreaElement;
    const maxLines = 4; // Specify the maximum number of lines you want to allow

    // Split the textarea value by line breaks and check the number of lines
    const lines = textarea.value.split("\n");
    if (lines.length > maxLines) {
      // Truncate the textarea value to the specified maximum number of lines
      textarea.value = lines.slice(0, maxLines).join("\n");
    }

    //check textarea and check input line-------------------------------------------------------------------
    // const textarea = event.target as HTMLTextAreaElement;
    // const maxLines = 4; // Specify the maximum number of lines you want to allow
    // const maxCharsPerLine = 95; // Specify the maximum number of characters per line

    // let lines = textarea.value.split('\n');

    // // Check if the number of lines exceeds the maximum allowed
    // if (lines.length > maxLines) {
    //   // Truncate the textarea value to the specified maximum number of lines
    //   lines = lines.slice(0, maxLines);
    //   textarea.value = lines.join('\n');
    // }

    // // Check the length of each line and truncate if it exceeds the maximum allowed characters
    // lines.forEach((line, index) => {
    //   if (line.length > maxCharsPerLine) {
    //     lines[index] = line.slice(0, maxCharsPerLine);
    //   }
    // });

    // // Update the textarea value with the truncated lines
    // textarea.value = lines.join('\n');
  }

  weighDisplay(rowData) {
    return rowData.weight + " " + rowData.weightUnit;
  }

  quantityDisplay(rowData) {
    return rowData.quantity + " " + rowData.quantityUnit;
  }

  // const fieldId = Object.keys(groupByfieldId)
  // Object.keys(fieldId).forEach((key) => {
  //   if (fieldId[key] == 12) {
  //     this.fieldError.declarator = false
  //   } else if (fieldId[key] == 2) {
  //     this.fieldError.consignor = false
  //   } else if (fieldId[key] == 3) {
  //     this.fieldError.consignee = false
  //   } else if (fieldId[key] == 4) {
  //     this.fieldError.notify = false
  //   } else if (fieldId[key] == 5) {
  //     this.fieldError.vessel = false
  //   } else if (fieldId[key] == 6) {
  //     this.fieldError.loading = false
  //   } else if (fieldId[key] == 7) {
  //     this.fieldError.certificateDate = false
  //   } else if (fieldId[key] == 8) { //fsdfsdsdfds
  //     this.fieldError.destination = false
  //   } else if (fieldId[key] == 9) {
  //     this.fieldError.invoice = false
  //   } else if (fieldId[key] == 10) {
  //     this.fieldError.country = false
  //   } else if (fieldId[key] == 11) {
  //     this.fieldError.item = false
  //   } else if (fieldId[key] == 12) {
  //     this.fieldError.footer = false
  //   }
  // })

  setDisplayTemplate() {
    this.isDisableFieldTemplate =
      this.editItem ||
      this.form.isRqstatus == 10 ||
      this.form.isRqstatus == 3 ||
      this.form.isRqstatus == 4;
  }

  setDisplayDeclarator() {
    this.isDisableFieldDeclarator =
      this.editItem ||
      this.editJobData ||
      (this.form.isRqstatus == 10 &&
        this.markErrorList.filter((x) => x.fieldName == "Declarator").length ==
          0);
  }

  setDisplayConsignor() {
    this.isDisableFieldConsignor =
      this.editItem ||
      this.editJobData ||
      (this.form.isRqstatus == 10 &&
        this.markErrorList.filter((x) => x.fieldName == "Consignor").length ==
          0);
  }
  setDisplayCertificate() {
    this.isDisableFieldCertificate =
      this.editItem ||
      this.editJobData ||
      (this.form.isRqstatus == 10 &&
        this.markErrorList.filter((x) => x.fieldName == "Certificate Date")
          .length == 0);
  }
  setDisplayConsignee() {
    this.isDisableFieldConsignee =
      this.editItem ||
      this.editJobData ||
      (this.form.isRqstatus == 10 &&
        this.markErrorList.filter((x) => x.fieldName == "Consignee").length ==
          0);
  }
  setDisplayNotify() {
    this.isDisableFieldNotify =
      this.editItem ||
      this.editJobData ||
      (this.form.isRqstatus == 10 &&
        this.markErrorList.filter((x) => x.fieldName == "Notify").length == 0);
  }
  setDisplayVesselAndFlight() {
    this.isDisableFieldVesselAndFlight =
      this.editItem ||
      this.editJobData ||
      (this.form.isRqstatus == 10 &&
        this.markErrorList.filter(
          (x) =>
            x.fieldName == "Vessel / Flight No." ||
            x.fieldName == "Vessel / Flight No"
        ).length == 0);
  }
  setDisplayCountryOfOrigin() {
    this.isDisableFieldCountryOfOrigin =
      this.editItem ||
      this.editJobData ||
      (this.form.isRqstatus == 10 &&
        this.markErrorList.filter((x) => x.fieldName == "Country Of Origin")
          .length == 0);
  }
  setDisplayLoadingOnAboutFlightDate() {
    this.isDisableFieldLoadingOnAboutFlightDate =
      this.editItem ||
      this.editJobData ||
      (this.form.isRqstatus == 10 &&
        this.markErrorList.filter(
          (x) => x.fieldName == "Loading on About / Flight Date"
        ).length == 0);
  }
  setDisplayInvoiceNoDate() {
    this.isDisableFieldInvoiceNoDate =
      this.form.isRqstatus == 10 &&
      this.markErrorList.filter((x) => x.fieldName == "Invoice No./ Date")
        .length == 0;
  }
  setDisplayItemDescription() {
    this.isDisableFieldItemDescription =
      this.form.isRqstatus == 10 &&
      this.markErrorList.filter((x) => x.fieldName == "Item Description")
        .length == 0;
  }
  setDisplayFooterDescription() {
    this.isDisableFieldFooterDescription =
      this.form.isRqstatus == 10 &&
      this.markErrorList.filter(
        (x) =>
          x.fieldName == "Footer Description" ||
          x.fieldName == "footer_description"
      ).length == 0;
  }
  setDisplayDestination() {
    this.isDisableFieldDestination =
      this.editItem ||
      this.editJobData ||
      (this.form.isRqstatus == 10 &&
        this.markErrorList.filter((x) => x.fieldName == "Destination").length ==
          0);
  }
  setDisplay() {
    this.setDisplayDeclarator();
    this.setDisplayTemplate();
    this.setDisplayConsignor();
    this.setDisplayCertificate();
    this.setDisplayDestination();
    this.setDisplayConsignee();
    this.setDisplayVesselAndFlight();
    this.setDisplayLoadingOnAboutFlightDate();
    this.setDisplayCountryOfOrigin();
    this.setDisplayItemDescription();
    this.setDisplayInvoiceNoDate();
    this.setDisplayNotify();
    this.setDisplayFooterDescription();
  }

  checkSymbol(e) {
    if (e != null) {
      var validateInput = this.symbolRegex.test(e);
    }
    return validateInput;
  }
  checkEnglish(e) {
    if (e != null && e != "" && e != undefined) {
      const regexPattern: RegExp =
        /^[a-zA-Z0-9 !"$%&'()*+,-./#:;<=>?@[\\\]^_`{|}~\n\r฿]*$/;
      var validateInput = regexPattern.test(e);
      //validateInput = validateInput === true ? false : true;
    }
    return validateInput;
  }

  contentReady(e) {
    if (!e.component.getSelectedRowKeys().length) {
      e.component.selectRowsByIndexes(0);
    }
  }

  selectionChanged(e) {
    e.component.collapseAll(-1);
    e.component.expandRow(e.currentSelectedRowKeys[0]);
  }

  checkInfoDiv() {
    if (this.form.isRqstatus == 10 && this.markErrorList.length != 0) {
      return true;
    } else {
      if (
        this.PackidLoadData != 0 &&
        this.PackidLoadData != undefined &&
        this.lengthViewChildPayment != 0
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
  valuechange(e) {
    this.httpLoadingService.enabled = false;

    if (!this.valueChange) {
      var preName: string;
      if (typeof e == "object") {
        preName = e.name;
      } else {
        preName = e;
      }

      let requestTemplate: IgetListLoadTemplateRequest = {
        UID: this.userProfile.uId,
        CID: this.userProfile.cid,
        tempName: preName,
        isActive: "Y",
      };

      this.appCOService
        .getListLoadTemplate(requestTemplate)
        .then((res: any) => {
          if (res === "error") {
            return;
          } else {
            this.templateList = res;
          }
        });
    }

    this.httpLoadingService.enabled = true;
  }

  onSelected(e) {
    this.valueChange = true;
    this.form.templateId = e.option.value;
    this.form.tmpSearchName = this.templateList.find(
      (ex) => ex.id == e.option.value
    ).tempName;
  }

  clearSelected() {
    this.form.templateId = "";
    this.form.tmpSearchName = "";
    this.valueChange = false;
    this.valuechange(this.form.templateId);
  }
}
