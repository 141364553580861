import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiQueueService } from '../api/api-queue.service';

@Injectable({
  providedIn: 'root'
})
export class AppQueueService {

  constructor(private apiQueueService: ApiQueueService,) { }

  getQueueCount(request:any): any {
    return new Promise((resolve, reject) => {
      this.apiQueueService.getQueueCount(request).subscribe((res) => {
        resolve(res);
        });
    });
  }
  

 
}
