<mat-card>
  <mat-card-header>
      <div class="flex justify-between" style="width: 100%;">
          <div class="text-lg"> List Print CO</div>
          <div>
              <button class="close-button" [mat-dialog-close]="true">
                  <mat-icon class="text-white" [mat-dialog-close]>cancel</mat-icon>
              </button>
          </div>
      </div>
  </mat-card-header>

  <mat-dialog-content class="mat-typography">

      <div class="flex flex-col justify-center gap-3">
          <div class="flex flex-row justify-center pl-10 pr-10">
            <mat-card class="flex flex-col w-full">
              <mat-card-header>
                  <h2 class="text-center cursor-pointer">ข้อมูลผู้ส่งออก | Exporter Form </h2>
              </mat-card-header>
              <mat-card-content>
                  <!-- line 1 -->
                  <div class="flex mt-4 gap-3 flex-row">
                          <div class="w-1/4 flex flex-col">
                              <h4>เลขที่คำขอ</h4>
                              <h4>Request No</h4>
                          </div>
                          {{" : "+requestNo}}
                  </div>
                  <!-- line 2 -->
                  <div class="flex mt-4 gap-3 flex-row">
                    <div class="w-1/4 flex flex-col">
                        <h4>ประเภทเอกสาร</h4>
                        <h4>Document Type</h4>
                    </div>
                    {{" : "+docType}}
                  </div>
                  <!-- line 3 -->
                  <div class="flex mt-4 gap-3 flex-row">
                    <div class="w-1/4 flex flex-col">
                        <h4>เร่งด่วน</h4>
                        <h4>Express Type</h4>
                    </div>
                    {{" : "+expressType}}
                  </div>
              </mat-card-content>
          </mat-card>
          </div>
          <div class="mat-elevation-z8">
              <table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">
                  <ng-container matColumnDef="thNumber">
                      <th mat-header-cell *matHeaderCellDef class="text-left"> TH Number</th>
                      <td mat-cell *matCellDef="let element"  class="text-left">
                          {{element.thNumber}}
                      </td>
                  </ng-container>
                  <ng-container matColumnDef="consigneeName">
                      <th mat-header-cell *matHeaderCellDef class="text-left"> Consignee
                      </th>
                      <td mat-cell *matCellDef="let element" class="text-left">
                          {{element.consigneeName}} </td>
                  </ng-container>
                  <ng-container matColumnDef="invoiceStatus">
                      <th mat-header-cell *matHeaderCellDef class="text-left"> Invoice Status</th>
                      <td mat-cell *matCellDef="let element" class="text-left"> {{element.invoiceStatus}} </td>
                  </ng-container>
                  <ng-container matColumnDef="invoiceNoAndInvoiceDate">
                      <th mat-header-cell *matHeaderCellDef class="text-left"> Invoice No. / Invoice Date
                      </th>
                      <td mat-cell *matCellDef="let element" class="text-left">
                          {{element.invoiceNoAndInvoiceDate}}
                      </td>
                  </ng-container>
                  <ng-container matColumnDef="action">
                      <th mat-header-cell *matHeaderCellDef class="text-left"> Action </th>
                      <td mat-cell *matCellDef="let element;let i = index" class="text-left">
                          <button  *ngIf="element.rqstatusIdHeader == 6" mat-icon-button matTooltip="พิมพ์" (click)="print(element)">
                              <mat-icon svgIcon="heroicons_outline:printer"></mat-icon>
                          </button>
                      </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>

              <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons
                  aria-label="Select page of periodic elements">
              </mat-paginator>
          </div>
      </div>

  </mat-dialog-content>
</mat-card>