import { LiveAnnouncer } from '@angular/cdk/a11y'
import { Component, OnInit, ViewChild, Inject } from '@angular/core'
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort, Sort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { Router } from '@angular/router'
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'
import { IGetListCreatePopupVerifyRecheckRequest } from 'app/business/interface/app/app-verify-interface'
import { AppRecheckService } from 'app/business/service/app/app-recheck.service'

@Component({
  selector: 'app-list-reprint-create-popup',
  templateUrl: './list-reprint-create-popup.component.html',
  styleUrls: ['./list-reprint-create-popup.component.scss']
})
export class ListReprintCreatePopupComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort
  displayedColumns: string[] = [
    'rqNo',
    'refNo',
    'requestDate',
    'docType',
    'rqStatusName',
    'declaratorName',
    'action',
  ]

  dataSource = new MatTableDataSource<any>()
  count: number
  apiRequest: IGetListCreatePopupVerifyRecheckRequest = {
    rqNo: '',
    payNo: '',
    thtNo: '',
    compCode: '',
    pagination: {
      Skip: 0,
      Take: 10,
    },
  }
  searchString: string
  constructor(
    public dialogRef: MatDialogRef<ListReprintCreatePopupComponent>,
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private router: Router,
    private appRecheckService: AppRecheckService,
  ) { }
  ngOnInit() {
    this.dataSource.paginator = this.paginator
    this.apiRequest.pagination = {
      Skip:
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
      Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
    }
    this.loadData(this.apiRequest)
  }
  ngAfterViewInit() {
    this.paginator.pageIndex = 0
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
  }

  async loadData(request: any) {
    
    if (this.paginator != undefined && this.paginator != null) {
      this.paginator.pageIndex = 0
    }
    
    request.pagination = {
      Skip: 0, // Start from page 1 (skip 0)
      Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
    };

    await this.appRecheckService.getCreateReprintList(request).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        if (res !== null) {
          this.dataSource.data = res.response
          this.count = res.totalCount
        } else {
          this.dataSource.data = []
          this.count = 0
        }
      }
    })
    this.dataSource.paginator.length = this.count
  }


  async pageChanged(e) {
    this.apiRequest.pagination = {
      Skip: (!e.pageIndex ? 0 : e.pageIndex) * (!e.pageSize ? 0 : e.pageSize),
      Take: !e.pageSize ? 10 : e.pageSize,
    }
    await this.appRecheckService.getCreateReprintList(this.apiRequest)
      .then((res: any) => {
        if (res === 'error') {
          return
        } else {
          if (res !== null) {
            this.dataSource.data = res.response
            this.count = res.totalCount
          } else {
            this.dataSource.data = []
            this.count = 0
          }
        }
      })

    this.dataSource.paginator.length = this.count
    this.dataSource.paginator.pageIndex = e.pageIndex
  }


  applyFilter(event: string) {
    this.apiRequest.keyword = event
    this.dataSource.paginator = this.paginator
    this.loadData(this.apiRequest)
  }
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`)
    } else {
      this._liveAnnouncer.announce('Sorting cleared')
    }
  }
  createReprint(e) {
    this.dialogRef.close(e.hId)
  }
}
