import { CdkDragMove, CdkDrag, CdkDragDrop, CdkDragEnd, CdkDragStart, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog'
import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  HostListener,
  enableProdMode,
  NgModule,
} from '@angular/core'
import { ErrorMarkPopupComponent } from '../error-mark-popup/error-mark-popup.component'
import { ActivatedRoute } from '@angular/router'
import { AttrachPic } from 'app/business/interface/app/app-co.interface'
import { MarkError } from 'app/business/interface/app/app-co.interface'
import { AppVerifyService } from 'app/business/service/app/app-verify.service';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service';
import { ConfirmDialogService } from '@bundle/component/confirm-dialog/confirm-dialog.service';

@Component({
  selector: 'app-verify-image-drag-drop',
  templateUrl: './verify-image-drag-drop.component.html',
  styleUrls: ['./verify-image-drag-drop.component.scss'],
})
export class VerifyImageDragDropComponent implements OnInit {
  @ViewChild('imagePreview', { static: true }) imagePreview: ElementRef;
  isDragging = false;
  lastX;
  lastY;
  newLeft: number = 0;
  newTop: number = 0;
  imgTransform: string = "rotate(0deg)";

  // markErrorList: MarkError[] = []
  markErrorListFilter: MarkError[] = []
  isError: boolean = false
  dragPosition = { x: 0, y: 0 }
  AttrachPicChoose: AttrachPic = {} as AttrachPic
  dropTarget: any
  documentData: any = {}
  transform: string = 'rotate(0deg) scale(1)'; // initial scale factor
  remarkData: string = ' '
  max: number = 0
  rotate: number = 0;
  scale: number = 1;
  displayShow: string = "block";
  packId: any;
  /*pos = { top: 0, left: 0, x: 0, y: 0 };*/

  _markErrorList: MarkError[];

  @Input()
  set getPackId(value) {
    console.log(value);
  }
  get getPackId(): any {
    return this.packId;
  }

  @Input()
  set attachment(value) {
    this.AttrachPicChoose = value
    this.resetPreviewImage();
  }
  get attachment(): any {
    return this.AttrachPicChoose
  }
  @ViewChild('previewImg') previewImg: ElementRef;
  @ViewChild('divData') divData: ElementRef;
  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private appVerifyService: AppVerifyService,
    private alertService: AlertMessageService,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private confirmService : ConfirmDialogService
  ) {
  }
  ngOnInit() {
    const { id, docType, oldPackId, recheckId } = this.route.snapshot.queryParams;
    this.packId = id;

    this.transform = 'rotate(0deg) scale(1)';
    this.imgTransform = 'rotate(0deg) scale(1)';

    this.appVerifyService.errorList.subscribe(res => {
      this._markErrorList = res;
      this.markErrorListFilter = res.filter(item => item.attachPicId === this.AttrachPicChoose.attachPicId && item.pos_x != null && item.pos_y != null);
    });
  }

  ngAfterViewInit() {
    this.transform = 'rotate(0deg) scale(1)';
    this.imgTransform = 'rotate(0deg) scale(1)';
  }

  onDragReleased2(event, index?: number) {
    let markImage = this._markErrorList.filter(item => item.attachPicId === this.AttrachPicChoose.attachPicId);
    const data = markImage[index];
    data.pos_x = event.layerX - event.offsetX;
    data.pos_y = event.layerY - event.offsetY;
    if (data.pos_x < 0) {
      data.pos_x = 0
    }
    if (data.pos_y < 0) {
      data.pos_y = 0
    }

    this.appVerifyService.saveErrorMarker(data).then(res => {
      if (res === 'error') {
        this.alertService.open({message: "เกิดข้อผิดพลาดระหว่างการบันทึกข้อมูล", title: ""});
      } else {
        this.appVerifyService.getCoErrorMarkList(data.packId);
      }
    });
  }
  addDraggable() {

    this.resetPreviewImage();

    this.transform = 'rotate(0deg) scale(1)';
    this.imgTransform = 'rotate(0deg) scale(1)';

    if (Object.keys(this.AttrachPicChoose).length === 0) {
      this.alertService.open({
        title: "ผิดพลาด",
        message: "โปรดเลือกรูปภาพ"
      }).then((res) => {
        this.isError = false
      })
    } else {
      var maxIndex = Math.max(...this._markErrorList.map(o => o.indexNo));

      const data: MarkError = {
        packId: parseInt(this.packId),
        docTypeId: parseInt(this.AttrachPicChoose.attachType),
        remark: '',
        markId: 0,
        indexNo: maxIndex + 1,
        docTypeName: this.AttrachPicChoose.attachTypeName,
        fieldId: 0,
        fieldName: '',
        markByName: '',
        pos_x: 0,
        pos_y: 0,
        typeDescription: '',
        attachPicId: this.AttrachPicChoose.attachPicId,
        markById: 0,
        markTypeId: 0,
      } as MarkError
      debugger;
      const dialogRef = this.dialog.open(ErrorMarkPopupComponent, {
        disableClose: false,
        width: '50vw',
        data: {
          attachment: this.AttrachPicChoose,
          form: data,
        },
        maxWidth: 'none',
        hasBackdrop: false,
      })

      dialogRef.afterClosed().subscribe((res) => {
        if (res != null) {
          this.appVerifyService.getCoErrorMarkList(parseInt(this.packId));
        }
      })
    }
  }


  deleteDraggable(ErrorMark: any, index: number) {
    this.confirmService
      .open({
        header: 'ยืนยันการทำรายการ',
        content: 'ยืนยันการลบ Mark Error ?',
      })
      .then((res) => {
        if (res == true) {
          var delReq = {
            markId: ErrorMark.markId,
            isActive: "N"
          };

          this.appVerifyService.saveErrorMarkActiveStatus(delReq).then(res => {
            if (res === 'error') {
              this.alertService.open({message: "เกิดข้อผิดพลาดระหว่างการบันทึกข้อมูล", title:""})
            } else {
              //handle mark on image
              this.markErrorListFilter = this._markErrorList.filter(item => item.attachPicId === ErrorMark.attachPicId);
              this.markErrorListFilter.splice(index, 1);

              //handle grobal list
              const indexToRemove = this._markErrorList.findIndex(item => item.attachPicId === ErrorMark.attachPicId);
              if (indexToRemove !== -1) {
                this._markErrorList.splice(indexToRemove + index, 1);
              }

              this.appVerifyService.getCoErrorMarkList(parseInt(this.packId));
            }
          })
        }
      })
  }

  @HostListener('wheel', ['$event'])
  onWheel(event: WheelEvent) {
    event.preventDefault()
    // Calculate the new scale factor based on the wheel delta
    const scaleFactor = event.deltaY > 0 ? 0.9 : 1.1 // reduce or increase scale factor
    const newScale = this.scale * scaleFactor

    // Limit the scale factor between 1 and 5
    const scale = Math.min(Math.max(newScale, 1), 5)

    this.scale = scale
    // Update the transform property
    this.imgTransform = 'rotate(' + this.rotate + 'deg) scale(' + this.scale + ')'
    this.needHideShowError();
  }

  zoominPic(event) {
    // Calculate the new scale factor based on the wheel delta
    const scaleFactor = event.deltaY > 0 ? 0.9 : 1.1 // reduce or increase scale factor
    const newScale = this.scale * scaleFactor

    // Limit the scale factor between 1 and 5
    const scale = Math.min(Math.max(newScale, 1), 5)
    this.scale = scale
    // Update the transform property
    this.imgTransform = 'rotate(' + this.rotate + 'deg) scale(' + this.scale + ')'
  }
  zoomoutPic(event) {
    // Calculate the new scale factor based on the wheel delta
    const scaleFactor = event.deltaY > 0 ? 0.9 : 1.1 // reduce or increase scale factor
    const newScale = this.scale / scaleFactor

    // Limit the scale factor between 1 and 5
    const scale = Math.min(Math.max(newScale, 1), 5)
    this.scale = scale
    // Update the transform property
    this.imgTransform = 'rotate(' + this.rotate + 'deg) scale(' + this.scale + ')';
    this.needHideShowError();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.markErrorListFilter = this._markErrorList.filter(item => item.attachPicId === this.AttrachPicChoose.attachPicId && item.pos_x != null && item.pos_y != null);
  }
  updateErrorList() {
    this.markErrorListFilter = this._markErrorList.filter(item => item.attachPicId === this.AttrachPicChoose.attachPicId && item.pos_x != null && item.pos_y != null);
  }

  OnchangesEmitErrorMark(ExportErrorMark: MarkError[]) {
    this.appVerifyService.setErrorList(ExportErrorMark);
  }
  FindIndexList(ExportErrorMark: MarkError[]) {
    this.max = 0
    const CheckArray = ExportErrorMark.length
    if (CheckArray != 0) {
      this.max = Math.max(...ExportErrorMark.map(x => x.indexNo));
    }
  }

  rotateLeft() {
    this.rotate = this.rotate - 90;
    if (this.rotate % 360 == 0) {
      this.rotate = 0;
    }
    this.imgTransform = 'rotate(' + this.rotate + 'deg) scale(' + this.scale + ')';
    this.needHideShowError();
  }
  rotateRight() {
    this.rotate = this.rotate + 90;
    if (this.rotate % 360 == 0) {
      this.rotate = 0;
    }
    this.imgTransform = 'rotate(' + this.rotate + 'deg) scale(' + this.scale + ')';
    this.needHideShowError();
  }

  beginDragImg(e) {
    e.preventDefault();
    this.isDragging = true;
    this.lastX = e.clientX;
    this.lastY = e.clientY;
  }

  releaseDragImg(e) {
    this.isDragging = false;
    this.needHideShowError();
  }

  DragingImg(e) {
    if (this.isDragging) {
      var deltaX = e.clientX - this.lastX;
      var deltaY = e.clientY - this.lastY;
      this.newLeft = this.newLeft + deltaX;
      this.newTop = this.newTop + deltaY;
      this.lastX = e.clientX;
      this.lastY = e.clientY;

      const element = this.elementRef.nativeElement.querySelector('#imagePreview');
      this.renderer.setStyle(element, 'left', this.newLeft + 'px');
      this.renderer.setStyle(element, 'top', this.newTop + 'px');
    }

  }

  resetPreviewImage() {
    const element = this.elementRef.nativeElement.querySelector('#imagePreview');
    this.renderer.setStyle(element, 'left', '0px');
    this.renderer.setStyle(element, 'top', '0px');

    this.rotate = 0;
    this.imgTransform = 'rotate(0deg) scale(1)';

    this.scale = 1;
    this.isDragging = false;
    this.newLeft = 0
    this.newTop = 0

    this.needHideShowError();
  }
  needHideShowError() {
    if (this.rotate == 0 && this.scale <= 1 && this.newLeft == 0 && this.newTop == 0) {
      this.displayShow = "block";
    } else {
      this.displayShow = "none";
    }
  }
}