import { Injectable } from '@angular/core';
import { ApiCompanyService } from '../api/api-company.service';
import { IReqGetGradeFactory } from 'app/business/interface/api/api-company.inteface';
import { IResGetGradeFactory } from 'app/business/interface/app/app-company.interace';

@Injectable({
  providedIn: 'root'
})
export class AppCompanyService {

  constructor(
    private apiCompanyService: ApiCompanyService
  ) { }

  getCompanyProfileList(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiCompanyService.getCompanyProfileList(request).subscribe(res => {
        resolve(res);
      });
    });
  }

  getCompanyProfile(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiCompanyService.getCompanyProfile(request).subscribe(res => {
        resolve(res);
      });
    });
  }

  getGradeFactory(request: IReqGetGradeFactory): Promise<IResGetGradeFactory> {
    return new Promise((resolve, reject) => {
      this.apiCompanyService.getGradeFactory(request).subscribe(res => {
        resolve(res);
      });
    });
  }

  updateCompanyEPayment(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiCompanyService.updateCompanyEPayment(request).subscribe(res => {
        resolve(res);
      });
    });
  }

  getListCompanyByFilter(request: any) {
    return new Promise((resolve, reject) => {
      this.apiCompanyService.getListCompanyByFilter(request).subscribe(res => {
        resolve(res);
      })
    })
  }getListReqCompanyByFilter(request: any) {
    return new Promise((resolve, reject) => {
      this.apiCompanyService.getListReqCompanyByFilter(request).subscribe(res => {
        resolve(res);
      })
    })
  }

  getListDeclaratorByFilter(request: any) {
    return new Promise((resolve, reject) => {
      this.apiCompanyService.getListDeclaratorFilter(request).subscribe(res => {
        resolve(res);
      })
    })
  }
  getListThtByFilter(request: any) {
    return new Promise((resolve, reject) => {
      this.apiCompanyService.getListThtByFilter(request).subscribe(res => {
        resolve(res);
      })
    })
  }
  getListTariffByFilter(request: any) {
    return new Promise((resolve, reject) => {
      this.apiCompanyService.getListTariffByFilter(request).subscribe(res => {
        resolve(res);
      })
    })
  }
}
