<mat-card-header>
    <div class="text-center">ประวัติการพิมพ์ | Print Document History</div>
</mat-card-header>

<mat-card-content >
    <section class="px-4 flex flex-col flex-1 pb-4  justify-center">
        <dx-data-grid class="mt-3" #gridContainer [dataSource]="dataSource"
            [allowColumnReordering]="true" [showBorders]="true">
            <dxo-editing mode="row" [allowUpdating]="false" [allowDeleting]="false">
            </dxo-editing>
            <dxi-column dataField="no" caption="ลำดับ/No." [width]="100" cellTemplate="index"
                alignment="center">
            </dxi-column>
            <div *dxTemplate="let row of 'index'">
                {{ (row.rowIndex+1) }}
            </div>
            <dxi-column dataField="history" caption="ประวัติการพิมพ์">
            </dxi-column>
            <dxi-column dataField="totalPrint" caption="จำนวนที่พิมพ์/หน้า" *ngIf="doc == 'CO'">
            </dxi-column>
            <dxi-column dataField="totalPrint" caption="จำนวนที่พิมพ์/ดวง" *ngIf="doc == 'CF'">
            </dxi-column>
            <dxi-column dataField="amount" caption="จำนวนดวงที่พิมพ์ได้" *ngIf="doc == 'CF'">
            </dxi-column>
            <dxi-column dataField="remark" caption="หมายเหตุ">
            </dxi-column>
            <dxi-column dataField="createDate" caption="วันที่">
            </dxi-column>
            <dxi-column dataField="name" caption="ผู้ดำเนินการ" [width]="100">
            </dxi-column>
        </dx-data-grid>
    </section>
</mat-card-content>