import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AppBankService } from 'app/business/service/app/app-bank.service';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { ConfirmPopupComponent } from '../../confirm-popup/confirm-popup.component';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'
@Component({
  selector: 'app-cf-subtitle-staff-popup',
  templateUrl: './cf-subtitle-staff-popup.component.html',
  styleUrls: ['./cf-subtitle-staff-popup.component.css']
})
export class CfSubtitleStaffPopupComponent implements OnInit {
  form: any = {};
  bankList: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef: MatDialogRef<CfSubtitleStaffPopupComponent>,
    private appMasterService: AppMasterService,
    private dialog: MatDialog,
    private alertMessageService: AlertMessageService
  ) {
    if (data) {
      this.form = data;
    } else {
      this.form.isActive = 'Y';
    }
  }
  ngOnInit(): void {

  }

  save() {
    if ((this.form.nameTh !== undefined && this.form.nameTh !== null && this.form.nameTh !== "") && (this.form.nameEn !== undefined && this.form.nameEn !== null && this.form.nameEn !== "")) {
      const data = {
        "docSubId": this.form.docSubId,
        "nameTh": this.form.nameTh,
        "nameEn": this.form.nameEn,
        "isActive": this.form.isActive
      };

      this.appMasterService.saveCfDocSubtitle(data).then((res: any) => {
        if (res === "error") {
          this.alertMessageService.open({message: "บันทึกข้อมูลไม่สำเร็จ", title:""});
        } else {
          this.dialogRef.close();
          this.alertMessageService.open({message: "บันทึกข้อมูลสำเร็จ", title:""});
        }
      });
    } else {
      this.alertMessageService.open({ message: "กรุณากรอกข้อมูลให้ครบ", title: "ข้อผิดพลาด" });
    }
  }

  onChange(e) {
    if (e.checked == true) {
      this.form.isActive = "Y";
    } else {
      this.form.isActive = "N";
    }
  }

  endPopup(e) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      disableClose: false,
      // width: '30vw',
      data: {
        header: e,
        button: 'close'
      },
      maxWidth: 'none'
    });

    dialogRef.afterClosed().subscribe(res => {
      this.dialogRef.close();
    });
  }
}
