import { SelectionModel } from "@angular/cdk/collections";
import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  ViewEncapsulation,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort, Sort } from "@angular/material/sort";
import { LiveAnnouncer } from "@angular/cdk/a11y";
import { ConfigBankPopupComponent } from "./config-bank-popup/config-bank-popup.component";
import { MatDialog } from "@angular/material/dialog";
import { AppBankService } from "app/business/service/app/app-bank.service";
import { ConfirmPopupComponent } from "../confirm-popup/confirm-popup.component";
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import { Router } from "@angular/router";
import { AppMenuService } from "app/business/service/app/app-menu.service";
import { AppUserService } from "app/business/service/app/app-user.service";
import { AppCompanyService } from "app/business/service/app/app-company.service";
import { Location } from '@angular/common'
import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import { AuthService } from "app/core/auth/auth.service";

@Component({
  selector: "app-config-bank-inquiry",
  templateUrl: "./config-bank-inquiry.component.html",
  styleUrls: ["./config-bank-inquiry.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ConfigBankInquiryComponent implements OnInit, AfterViewInit {
  BenefitTarget: any = [];
  form: any = {};
  BenefitTargetValue: string;
  displayedColumns: string[] = [
    "bankCodeName",
    "bankAccountName",
    "bankAccountNo",
    "bankBranch",
    "accTaxNo",
    "isActive",
    "action",
  ];
  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);
  url: any;
  buttonList: any[] = [];
  itemList: any;
  userToken:any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private appBankService: AppBankService,
    private alertMessageService: AlertMessageService,
    private router: Router,
    private _location: Location,
    private appMenuService: AppMenuService,
    private appUserService: AppUserService,
    private appCompanyService: AppCompanyService,
    private confirmDialogService: ConfirmDialogService,
    private _authService:AuthService,
  ) { }

  async getCompanyProfile() {
    let userProfile = this.userToken;

    if (userProfile.gId === "2" || userProfile.gId === "3" || userProfile.gId === "6" || userProfile.gId === "7" || userProfile.gId === "8") {
      let cpId = userProfile.cpId;

      await this.appCompanyService.getCompanyProfile(cpId).then(res => {
        if (res === 'error') {
          return;
        } else {
          let data = res;

          if (data.isAllowEpayment !== "Y") {
            this.alertMessageService.open({ message: "บริษัทของท่านยังไม่แจ้งความจำนงค์ขอใช้ระบบ E-Payment กรุณาติดต่อเจ้าหน้าที่ทะเบียน", title: "" }).then(res => {
              this.router.navigate(["/page/profile/profile"]);
            })
          } else {
            return;
          }
        }
      });
    }
  }

  async getRouter(): Promise<void> {
    this.url = this.router.url;

    const formData = {
      url: this.url,
      userGroupCode: this.userToken.userGroupCode,
      userRoleCode: this.userToken.userRoleCode,
      userCode: this.userToken.userCode
    };
    this.buttonList = await this.appMenuService.getButtonPermissionPerPages(formData);
  }

  async ngOnInit() {

    this.userToken = JSON.parse(
      this._authService.loginUser
    )   

    await this.getCompanyProfile();
    await this.getRouter();
    this.BenefitTarget = [
      { benefitTargetCode: 1, benefitTargetTName: "10" },
      { benefitTargetCode: 2, benefitTargetTName: "20" },
    ];
    this.form.isActive = "A";

    this.buttonList.forEach(element => {
      if (element.buttonCode === "BT068") {
        this.displayedColumns.unshift("select");
      }
    });

    this.loadData();
  }
  async loadData() {
    let cpId = parseInt(this.userToken.cpId);

    const data = {
      bankCode: "",
      bankAccountNo: this.form.bankAccountNo,
      bankAccountName: this.form.bankAccountName,
      bankBranch: "",
      accTaxNo: this.form.accTaxNo,
      isBotAcc: this.form.isBotAcc,
      isActive: this.form.isActive,
      cId: cpId
    };
    await this.appBankService.bankAccount(data).then((res: any) => {
      if (res === "error") {
        return;
      } else {
        this.itemList = res;
        this.dataSource = new MatTableDataSource(res);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    });
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  selectionPercent() { }
  editItem(e) {
    const data = {
      e: { ...e },
      buttonList: this.buttonList
    };
    const dialogRef = this.dialog.open(ConfigBankPopupComponent, {
      disableClose: false,
      width: "60vw",
      data: data,
      maxWidth: "none",
    });

    dialogRef.afterClosed().subscribe((res) => {
      this.loadData();
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce("Sorting cleared");
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();

    if (filterValue?.length > 0) {
      var res = this.itemList.filter(e => (e.bankAccountNo.toLowerCase().includes(filterValue) || e.bankAccountName.toLowerCase().includes(filterValue) || e.accTaxNo.toLowerCase().includes(filterValue)));

      this.dataSource = new MatTableDataSource(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    } else {
      this.dataSource = new MatTableDataSource(this.itemList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  save() { }

  addAccount(data): void {
    const d = {
      e: null,
      buttonList: this.buttonList
    };
    const dialogRef = this.dialog.open(ConfigBankPopupComponent, {
      disableClose: false,
      width: "60vw",
      data: d,
      maxWidth: "none",
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.loadData();
      }
      this.selection.clear(true);
    });
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  delete(e) {
    this.confirmDialogService.open({ header: "", content: "คุณต้องการลบข้อมูลนี้หรือไม่?" }).then(res => {
      if (res) {
        const data = {
          id: e.id,
          bankCode: e.bankCode,
          bankAccountNo: e.bankAccountNo,
          bankAccountName: e.bankAccountName,
          bankBranch: e.bankBranch,
          accTaxNo: e.accTaxNo,
          isBotAcc: e.isBotAcc,
          cId: e.cId,
          isActive: "N",
        };
        
        this.appBankService.saveBankAccount(data).then((res: any) => {
          if (res === "error") {
            return;
          } else {
            this.loadData();
          }
        });
      }
    });
  }

  deleteAccount() {
    if (this.selection?.selected?.length > 0) {
      this.confirmDialogService.open({ header: "", content: "คุณต้องการลบข้อมูลนี้หรือไม่?" }).then(res => {
        if (res) {
          var selectedKey = this.selection.selected.map((item, index, chats) => {
            return {
              id: item.id,
              bankCode: item.bankCode,
              bankAccountNo: item.bankAccountNo,
              bankAccountName: item.bankAccountName,
              bankBranch: item.bankBranch,
              accTaxNo: item.accTaxNo,
              isBotAcc: item.isBotAcc,
              cId: item.cId,
              isActive: "N",
            }
          });

          const req = {
            saveList: selectedKey
          };

          this.appBankService.updateBankAccountBatch(req).then(res => {
            if (res === 'error') {
              this.alertMessageService.open({ message: "เกิดข้อผิดพลาดระหว่างบันทึกข้อมูล", title: "ข้อผิดพลาด" });
            } else {
              this.selection.clear(true);
              this.loadData();
            }
          });
        }
      });
    } else {
      this.alertMessageService.open({ message: "กรุณาเลือกรายการที่จะลบข้อมูล", title: "ข้อผิดพลาด" });
    }
  }

  pageChanged(e) {
  }

  clearOption() {
    this.form = {};
    this.loadData();
  }
  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find(e => e.buttonCode == buttonCode);

    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }
}
