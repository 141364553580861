<mat-card>
  <mat-card-header>
    <div class="flex justify-between" style="width: 100%;">
      <div class="text-lg">เลือกผู้ส่งออก | Add Exporter</div>
      <div>
        <button class="close-button" [mat-dialog-close]="true">
          <mat-icon class="text-white" [mat-dialog-close]>cancel</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-dialog-content>
    <div class="flex flex-col py-5 gap-4 ">
      <div>
        <div class="flex flex-row">
          <div class="basis-1/3">รายชื่อบริษัท : </div>
          <div class="basis-2/3">
            <mat-form-field class="w100">
              <mat-select [(ngModel)]="form.company" (selectionChange)="selectionCompany(form.company)" name="company">
                <mat-option *ngFor="let company of companyList" [value]="company">
                  {{company.companyNameEn}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <!-- <div>
        <div class="flex flex-row">
          <div class="basis-1/3">รายการที่อยู่ : </div>
          <div class="basis-2/3">
            <mat-form-field class="w100">
              <input autocomplete="off" required readonly type="text" matInput placeholder="ที่อยู่" #input
                [(ngModel)]="form.address" name="address" #address="ngModel">
            </mat-form-field>
          </div>
        </div>
      </div>
      <div>
        <div class="flex flex-row">
          <div class="basis-1/3">สาขา : </div>
          <div class="basis-2/3">
            <mat-form-field class="w100">
              <input autocomplete="off" required readonly type="text" matInput placeholder="สาขา" #input
                [(ngModel)]="form.branch" name="branch" #branch="ngModel">
            </mat-form-field>
          </div>
        </div>
      </div> -->
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-raised-button [mat-dialog-close]="form" [disabled]="form.companyNameTh == null"
      color="accent">เพิ่มใบเสร็จ</button>
    <!-- <button mat-raised-button [mat-dialog-close]>ยกเลิก</button> -->
  </mat-dialog-actions>