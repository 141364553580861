import { Component, Input, OnInit } from '@angular/core';
import { IReqGetGradeFactory } from 'app/business/interface/api/api-company.inteface';
import { IResGetGradeFactory } from 'app/business/interface/app/app-company.interace';
import { AppCompanyService } from 'app/business/service/app/app-company.service';
import { ImageModalComponent } from '../../create-co-inquiry/Image-Modal/Image-Modal.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-verify-factory-detail',
  templateUrl: './verify-factory-detail.component.html',
  styleUrls: ['./verify-factory-detail.component.css']
})
export class VerifyFactoryDetailComponent implements OnInit {

  form: IResGetGradeFactory = {} as IResGetGradeFactory
  _cId: number = 0;
  @Input()
  set cId(value) {
    this._cId = value
    this.loadData();
  }
  get cId(): any {
    return this._cId
  }
  constructor(
    private appCompanyService: AppCompanyService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.loadData();
  }
  loadData() {
    let req: IReqGetGradeFactory = {
      cId: this.cId
    }
    this.appCompanyService.getGradeFactory(req).then(res => {
      if (res) {
        this.form = res;
      }
    });
  }

  onView(e) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.minHeight = '30vh';
    dialogConfig.minWidth = '30vh';
    dialogConfig.maxHeight = '100vh';
    dialogConfig.data = {
      fullImageUrl: e,
    };
  
    const dialogRef = this.dialog.open(ImageModalComponent, dialogConfig)
  }
}
