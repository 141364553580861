<div class="border rounded-lg p-4 md:p-8">
  <div class="flex flex-wrap -mx-2">
    <div class="w-full sm:w-1/2 md:w-1/4 px-2">
      <h4 for="gradeTypeName" class="block mb-2">ระดับบริษัท</h4>
    </div>
    <div class="w-full sm:w-1/2 md:w-1/4 px-2">
      <label for="gradeTypeName" class="block mb-2">: {{form.gradeTypeName ? form.gradeTypeName : ''}}</label>
    </div>
    <div class="w-full sm:w-1/2 md:w-1/4 px-2">
      <h4 for="gradeTypeName" class="block mb-2">วันที่ตรวจ</h4>
    </div>
    <div class="w-full sm:w-1/2 md:w-1/4 px-2">
      <label for="verifyDate" class="block mb-2">: {{form.verifyDate | date:'yyyy-MM-dd'}}</label>
    </div>
    <div class="w-full sm:w-1/2 md:w-1/4 px-2" style="margin-top: 10px;">
      <h4 for="gradeTypeName" class="block mb-2">เหตุผลระดับที่ได้รับ</h4>
    </div>
    <div class="w-full sm:w-1/2 md:w-3/4 px-2" style="margin-top: 10px;">
      <label for="verifyDate" class="block mb-2">: {{form.gradeReason ? form.gradeReason : ''}}</label>
    </div>
    <div class="w-full sm:w-1/2 md:w-1/4 px-2">
      <h4 for="gradeTypeName" class="block mb-2" style="margin-top: 10px;" *ngIf="form!==null && form.attachFile!==null && form.attachFile">เอกสารแนบ</h4>
    </div>
    <div class="w-full sm:w-1/2 md:w-3/4 px-2 flex items-center" *ngIf="form!==null && form.attachFile!==null && form.attachFile">
      <label for="verifyDate" class="block mb-2">: </label>
      <div style="width: 50px; height: 50px">
        <a href="{{form.attachFile}}" target="_blank"><img src="assets/images/logo/pdf-icon-png-pdf.png"></a>
      </div>

    </div>
  </div>
</div>