import { ReturnStatement } from '@angular/compiler'
import { Injectable } from '@angular/core'
import {
  IgetListCFResponse,
  IgetListCOResponse,
  IgetListLoadTemplateResponse,
} from 'app/business/interface/api/api-co.interface'
import {
  GetListCORequest,
  IgetListCORequest,
  IgetListLoadTemplateRequest,
} from 'app/business/interface/app/app-co.interface'
import { Observable } from 'rxjs'
import { ApiService } from '@bundle/service/api.service';

@Injectable({
  providedIn: 'root',
})
export class ApiCOService {
  constructor(private apiService: ApiService) {}

  getListCO(request: GetListCORequest): Observable<IgetListCOResponse[]> {
    return this.apiService.post('/api/getListCO', request)
  }
  getListLoadTemplate(
    request: IgetListLoadTemplateRequest,
  ): Observable<IgetListLoadTemplateResponse[]> {
    return this.apiService.post('/api/CO/getListloadtemplate', request)
  }
  getListAttachType(): Observable<any[]> {
    return this.apiService.get('/api/getListAttachType')
  }
  getListAttachTypeByType(request: any): Observable<any[]> {
    return this.apiService.post('/api/getListAttachTypeByType', request)
  }
  saveCO(request: any): Observable<any[]> {
    return this.apiService.post('/api/CO/saveCO', request)
  }
  saveTemplate(request: any): Observable<any[]> {
    return this.apiService.post('/api/CO/saveTemplate', request)
  }
  getCO(request: any): Observable<any[]> {
    return this.apiService.post('/api/getCO', request)
  }
  getLoadTemplate(request: any): Observable<any[]> {
    return this.apiService.post('/api/CO/getLoadtemplate', request)
  }
 

  getListTemplate(request:any):Observable<any[]>{
    return this.apiService.post("/api/getListTemplate",request);
  }
  getListDeclaratorCompany(request:any):Observable<any[]>{
    return this.apiService.post("/api/co/getDeclaratorCO",request);
  }
  getRelationCompany(request:any):Observable<any[]>{
    return this.apiService.post("/api/co/getRelationCompany",request);
  }

  getDraftCO(request:any):Observable<any[]>{
    return this.apiService.post("/api/getDraftCO",request);
  }
  deleteCO(request:any):Observable<any[]>{
    return this.apiService.post("/api/deleteCO",request);
  }

  getPreviewCO(request:any):Observable<any[]>{
    return this.apiService.post("/api/getPreviewCO",request);
  }

  cancelVerify(request:any):Observable<any[]>{
    return this.apiService.post("/api/cancelVerify",request);
  }

  getPreviewCOPack(request:any):Observable<any[]>{
    return this.apiService.post("/api/getPreviewCOPack",request);
  }
  getListCF(request: any): Observable<IgetListCFResponse[]> {
    return this.apiService.post('/api/getListCF', request)
  } 
  getCFDetail(request: any): Observable<any> {
    return this.apiService.post('/api/getCFDetail', request)
  }
  getPreviewCF(request: any): Observable<any> {
    return this.apiService.post('/api/getPreviewCF', request)
  }
  getErrorMarkList(request: any): Observable<any>{
    return this.apiService.post('/api/getErrorMark',request)
  }

  getListSearchCOCF(request: any):Observable<any>{
    return this.apiService.post('/api/getListSearchCOCF',request)
  }

  saveCORequestJob(request: any):Observable<any>{
    return this.apiService.post('/api/saveCORequestJob',request)
  }
  saveCFRequestJob(request: any):Observable<any>{
    return this.apiService.post('/api/saveCFRequestJob',request)
  }

  getViewVerifyWorkList(request: any): Observable<any>{
    return this.apiService.post('/api/getViewVerifyWorkList', request);
  }

  getPrintOriginal(request: any): Observable<any>{
    return this.apiService.post('/api/getPrintOriginal', request);
  }

  getAuthorityViewResponseList(request: any): Observable<any>{
    return this.apiService.post('/api/getAuthorityViewResponseList', request);
  }

  getPdfFilePathReprint(request: any): Observable<any>{
    return this.apiService.post('/api/getPdfFilePathReprint', request);
  }

  getListPrintCoPack(request: any): Observable<any>{
    return this.apiService.post('/api/getListPrintCoPack', request);
  }

  rejectRequestJob(request: any): Observable<any>{
    return this.apiService.post('/api/rejectRequestJob', request);
  }
  getMasterErrorMarkList(request:any): Observable<any>{
    return this.apiService.post('/api/getMasterErrorMarkList', request);
  }
  getCfTotalPage(request:any): Observable<any>{
    return this.apiService.post('/api/getCfTotalPage', request);
  }
  sendPrintToStaff(request:any): Observable<any>{
    return this.apiService.post('/api/sendPrintToStaff', request);
  }
  getCfTotalPageReprint(request:any): Observable<any>{
    return this.apiService.post('/api/getCfTotalPageReprint', request);
  }
  
  approveLiveSignSelfPrint(request:any): Observable<any>{
    return this.apiService.post('/api/ApproveLiveSignSelfPrint', request);
  }
  LogPrintHistory(request:any): Observable<any>{
    return this.apiService.post('/api/LogPrintHistory', request);
  }

  saveTemplateStatus(request: any) : Observable<any> {
    return this.apiService.post('/api/saveTemplateStatus', request);
  }

  getQueueByPackId(request: any) : Observable<any> {
    return this.apiService.post('/api/getQueueByPackId', request);
  }
}
