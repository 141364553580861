import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common'
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CoErrorMarkImagePopupComponent } from '../create-co-inquiry/co-error-mark-image-popup/co-error-mark-image-popup.component';
import { AppCompanyService } from 'app/business/service/app/app-company.service';
import { ActivatedRoute } from '@angular/router';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service';
import { ImageModalComponent } from '../create-co-inquiry/Image-Modal/Image-Modal.component';

@Component({
  selector: 'app-company-profile-form',
  templateUrl: './company-profile-form.component.html',
  styleUrls: ['./company-profile-form.component.css']
})

export class CompanyProfileFormComponent implements OnInit, AfterViewInit {
  form: any = {};
  companyTypeList: any[] = [];
  isMREnabled: boolean = false;
  companyId: any;
  displayedColumns: string[] = [
    'tcaAddressStreet',
    'subDistrictName',
    'districtName',
    'province',
    'postCode',
    'phone',
    'fax',
    'tcaTypeComAddName',
  ]
  isEpayment: any[] = [
    {
      value: "Y",
      name: "ใช้งาน"
    },
    {
      value: "N",
      name: "ไม่ใช้งาน"
    }
  ];

  isFactory: any[] = [
    {
      value: "Y",
      name: "ใช่"
    },
    {
      value: "N",
      name: "ไม่ใช่"
    }
  ];

  provinceList: any[] = [];

  dataSource = new MatTableDataSource<any>()
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private _location: Location,
    private appMasterService: AppMasterService,
    private appCompanyService: AppCompanyService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private alertMessage: AlertMessageService
  ) {
    const { companyId } = this.route.snapshot.queryParams;
    this.companyId = companyId;
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.appMasterService.getMrConnectParameter("MR_CONNECT").then(res => {
      if (res === 'error') {
        this.isMREnabled = false;
      } else {
        this.isMREnabled = res?.cpParamiter === 'ON' ? true : false;
      }
    });

    this.appMasterService.getCompanyAddressType('').then(res => {
      if (res === 'error') {
        this.companyTypeList = [];
      } else {
        this.companyTypeList = res;
      }
    });

    this.appCompanyService.getCompanyProfile(this.companyId).then(res => {
      if (res === 'error') {
        return;
      } else {
        this.form = res;
      }
    });

    this.appMasterService.getMasterProvince({}).then(res => {
      if (res === "error") {
        return;
      } else {
        this.provinceList = res;

        const CheckAll = this.provinceList.find(element => element.provinceName === "กรุณาเลือก");
        if (CheckAll === undefined) {
          this.provinceList.unshift({ "provinceId": 0, "provinceName": "กรุณาเลือก" });
        }
      }
    });

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`)
    } else {
      this._liveAnnouncer.announce('Sorting cleared')
    }
  }

  stopPropagation(event) {
    event.stopPropagation()
  }

  imagePopup(e) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.minHeight = '30vh';
    dialogConfig.minWidth = '30vh';
    dialogConfig.maxHeight = '100vh';
    dialogConfig.data = {
      fullImageUrl: e,
      header: "ภาพตราบริษัท"
    };

    const dialogRef = this.dialog.open(ImageModalComponent, dialogConfig)
  }

  backClicked() {
    this._location.back()
  }

  save() {
    const req = {
      cId: this.companyId,
      isAllowEpayment: this.form.isAllowEpayment
    }

    this.appCompanyService.updateCompanyEPayment(req).then(res => {
      if (res === 'error') {
        this.alertMessage.open({message: "เกิดข้อผิดพลาดระหว่างบันทึกข้อมูล", title: ""});
      } else {
        this.alertMessage.open({message: "บันทึกข้อมูลสำเร็จ", title: ""});
      }
    })
  }
}
