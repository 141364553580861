<!-- <div class="pl-10 pr-20 menu-top pb-4 pt-3">
    <div class="menu-top-sub">
        <div *ngFor="let item of menuForm">
            <h2 style="cursor:pointer;text-align: center;" *ngIf="item.type == 'collapsable'"
                [matMenuTriggerFor]="menu">{{item.title}}</h2>
            <h2 style="cursor:pointer;text-align: center;" *ngIf="item.type == 'item' || item.type == 'basic'"
                [routerLink]="item.url">
                {{item.title}}</h2>
            <mat-menu #menu="matMenu" xPosition="before" class="fixWidth">
                <div class="pl-2 pr-2 gap-2 flex flex-col" style="color: #003f80;background-color: white;"
                    (click)="stopPropagation($event)">
                    <h2 class="selectMenu py-2 rounded px-1" *ngFor="let itemChildren of item.children"
                        style="cursor:pointer;text-align: left;" [routerLink]="itemChildren.url">{{itemChildren.title}}
                    </h2>

                </div>
            </mat-menu>
        </div>
    </div>
    <div>
    </div>
</div> -->

<div class="menu-top-main">
    <div class="menu-top-sub">
        <div *ngFor="let item of menuForm">
            <button mat-button *ngIf="item.type == 'collapsable' && (isDisable == true)" [matMenuTriggerFor]="menu">
                <mat-icon svgIcon="{{item.icon}}"></mat-icon>
                <!-- <mat-icon style="font-size: 24px; " class="fontSize">{{item.icon}}</mat-icon> -->
                &nbsp;
                {{item.title}}
            </button>

            <button mat-button *ngIf="item.type == 'item' && (userProfile.isDisable == true)" [routerLink]="[item.url]">
                <mat-icon svgIcon="{{item.icon}}"></mat-icon>
                <!-- <mat-icon style="font-size: 24px;" class="fontSize">{{item.icon}} </mat-icon> -->
                &nbsp;
                {{item.title}}
            </button>

            <mat-menu #menu="matMenu" routerLinkActive="active-link">
                <div *ngFor="let itemChildren of item.children">
                    <div *ngIf="itemChildren.type == 'collapsable'">
                        <button mat-menu-item [matMenuTriggerFor]="menuChild">
                            {{itemChildren.title}}
                        </button>

                        <mat-menu #menuChild="matMenu"  routerLinkActive="active-link">
                            <div *ngFor="let itemChild of itemChildren.children">
                                <button mat-menu-item class="setWidth"   [routerLink]="[itemChild.url]">
                                    <!-- <mat-icon svgIcon="{{itemChild.icon}}"></mat-icon> -->
                                    {{itemChild.title}}
                                </button>
                            </div>
                        </mat-menu>

                    </div>
                    <div *ngIf="itemChildren.type == 'item'">
                        <button mat-menu-item [routerLink]="[itemChildren.url]">{{itemChildren.title}}</button>
                    </div>

                    <!-- <mat-divider class="my-2"></mat-divider> -->
                </div>
            </mat-menu>
        </div>
    </div>
    <div>
        <!-- <h2 style="cursor:pointer;text-align: center;">{{type == 'o2' ? 'อ.2' : type == 'ms13' ? 'มส.13' : type == 'history-ms13'? 'แก้ไขข้อมูลและปลดล็อกการพิมพ์ มส. 13': type == 'dailyreport' ? 'รายงานประจำวัน' : 'มส.24' }}</h2> -->
    </div>
</div>