import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AppBankService } from 'app/business/service/app/app-bank.service';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { ConfirmPopupComponent } from '../../confirm-popup/confirm-popup.component';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'

@Component({
  selector: 'app-mark-type-detail-popup',
  templateUrl: './mark-type-detail-popup.component.html',
  styleUrls: ['./mark-type-detail-popup.component.css']
})
export class MarkTypeDetailPopupComponent implements OnInit {
  form: any = {};
  bankList: any;
  attachType: any[] = [];
  fieldType: any[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef: MatDialogRef<MarkTypeDetailPopupComponent>,
    private appMasterService: AppMasterService,
    private dialog: MatDialog,
    private alertMessageService: AlertMessageService
  ) {
    this.form = data;
    
  }

  ngOnInit(): void {
    this.appMasterService.getListAttachType({}).then(res => {
      if (res === "error") {
        return;
      } else {
        this.attachType = res;
      }
    });

    this.appMasterService.getMasterFieldType({}).then(res => {
      if (res === "error") {
        return;
      } else {
        this.fieldType = res;
      }
    })
  }

  save() {
    
    if (this.form.marktypeDetail !== undefined && this.form.marktypeDetail !== null && this.form.marktypeDetail !== "") {
      const data = {
        "marktypeDId": this.form.marktypeDId,
        "marktypeHId": this.form.marktypeHId,
        "marktypeDetail": this.form.marktypeDetail,
        "isActive": this.form.isActive
      };

      this.appMasterService.saveMarktypeDetail(data).then((res: any) => {
        if (res === "error") {
          this.alertMessageService.open({message: "บันทึกข้อมูลไม่สำเร็จ", title:""});
        } else {
          this.dialogRef.close();
          this.alertMessageService.open({message: "บันทึกข้อมูลสำเร็จ", title:""});
        }
      });

    } else {
      this.alertMessageService.open({ message: "กรุณากรอกข้อมูลให้ครบ", title: "ข้อผิดพลาด" });
    }
  }

  onChange(e) {
    if (e.checked == true) {
      this.form.isActive = "Y";
    } else {
      this.form.isActive = "N";
    }
  }

  endPopup(e) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      disableClose: false,
      // width: '30vw',
      data: {
        header: e,
        button: 'close'
      },
      maxWidth: 'none'
    });

    dialogRef.afterClosed().subscribe(res => {
      this.dialogRef.close();
    });
  }
}
