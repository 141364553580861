import { Injectable } from '@angular/core';
import { IReqSaveVerifyReprint,IReqGetPrintOriginal } from 'app/business/interface/api/api-co.interface';
import { ApiPrintService } from '../api/api-print.service';

@Injectable({
  providedIn: 'root'
})
export class AppPrintService {

  constructor(private apiPrintService: ApiPrintService) { }

  getListPrint(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiPrintService.getListPrint(request).subscribe((res) => {
          resolve(res);
        });
    });
  }

  getListRePrint(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiPrintService.getListRePrint(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  saveRePrint(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiPrintService.saveRePrint(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  saveVerifyReprint(request: IReqSaveVerifyReprint): any {
    return new Promise((resolve, reject) => {
      this.apiPrintService.saveVerifyReprint(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getPrintOriginal(request: IReqGetPrintOriginal): any {
    return new Promise((resolve, reject) => {
      this.apiPrintService.getPrintOriginal(request).subscribe((res) => {
        resolve(res);
      });
    });
  }
  getPrintOriginalCF(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiPrintService.getPrintOriginalCF(request).subscribe((res) => {
        resolve(res);
      });
    });
  }
  getCreateReprintList(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiPrintService.getCreateReprintList(request).subscribe((res) => {
        resolve(res);
      });
    });
  }
}
