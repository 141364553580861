import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-not-registered-email-alert-dialog',
  templateUrl: './not-registered-email-alert-dialog.component.html',
  styleUrls: ['./not-registered-email-alert-dialog.component.css']
})
export class NotRegisteredEmailAlertDialogComponent implements OnInit {
  dataSource: any[];
  displayedColumns: string[] = ['number', 'companyName'];

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<NotRegisteredEmailAlertDialogComponent>
  ) {
    if (data) {
      this.dataSource = data.notRegisteredList;
    }
  }

  ngOnInit() {
  }
}
