import { LiveAnnouncer } from "@angular/cdk/a11y";
import { SelectionModel } from "@angular/cdk/collections";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { AppMasterService } from "app/business/service/app/app-master.service";

import { ConfirmPopupComponent } from "../confirm-popup/confirm-popup.component";
import { ExchangeRatePopupComponent } from "./exchange-rate-popup/exchange-rate-popup.component";
import { AppMenuService } from "app/business/service/app/app-menu.service";
import { AppUserService } from "app/business/service/app/app-user.service";
import { ConfirmDialogService } from '@bundle/component/confirm-dialog/confirm-dialog.service';
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";

@Component({
  selector: "app-exchange-rate-inquiry",
  templateUrl: "./exchange-rate-inquiry.component.html",
  styleUrls: ["./exchange-rate-inquiry.component.scss"],
})
export class ExchangeRateInquiryComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  searchString: string;
  exchangeRateList = new MatTableDataSource<any>();
  displayedColumns: string[] = [
    "select",
    "currency",
    "rate",
    "startDate",
    "modifyDate",
    "action",
  ];
  selection = new SelectionModel<any>(true, []);
  searchForm: any = {
    startDate: "",
    modifyDate: "",
  };
  url: any;
  buttonList: any[] = [];
  itemList: any[];
  constructor(
    private appMasterService: AppMasterService,
    private _liveAnnouncer: LiveAnnouncer,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private appMenuService: AppMenuService,
    private appUserService: AppUserService,
    private confirmDialogService: ConfirmDialogService,
    private alertMessageService: AlertMessageService,
  ) { }

  async getRouter(): Promise<void> {
    
    this.url = this.router.url;

    const profile = JSON.parse(this.appUserService.getSessionUserProfile());
    const formData = {
      url: this.url,
      userGroupCode: profile?.userGroupCode,
      userRoleCode: profile?.userRoleCode,
      userCode: profile?.userCode
    };
    this.buttonList = await this.appMenuService.getButtonPermissionPerPages(formData);
    
  }

  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find(e => e.buttonCode == buttonCode);

    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }

  async ngOnInit() {
    await this.getRouter();
    this.loadData(this.searchForm);
  }
  loadData(request) {
    this.appMasterService.getExchangeRate(request).then((res: any) => {
      if (res === "error") {
        return;
      } else {
        let a = res.map((item) => {
          if (item.modifyDate == null) {
            item.modifyDate = new Date(item.createDate);
          }
          item.rate = item.rate.toFixed(2);

          return item;
        });
        this.itemList = a;
        this.exchangeRateList = new MatTableDataSource(a);
        // this.exchangeRateList = new MatTableDataSource(res)

        this.exchangeRateList.paginator = this.paginator;
        this.exchangeRateList.sort = this.sort;
        
      }
    });
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();

    if (filterValue?.length > 0) {
      var res = this.itemList.filter(e => (e.currency.toLowerCase().includes(filterValue) || e.rate.includes(filterValue)));

      this.exchangeRateList = new MatTableDataSource(res);
      this.exchangeRateList.paginator = this.paginator;
      this.exchangeRateList.sort = this.sort;
    } else {
      this.exchangeRateList = new MatTableDataSource(this.itemList);
      this.exchangeRateList.paginator = this.paginator;
      this.exchangeRateList.sort = this.sort;
    }
  }
  setupFilter(column: string) {
    
    this.exchangeRateList.filterPredicate = (
      d: MatTableDataSource<any>,
      filter: string
    ) => {
      const textToSearch = (d[column] && d[column].toLowerCase()) || "";
      return textToSearch.indexOf(filter) !== -1;
    };
  }
  saveSearch() {
    this.loadData(this.searchForm);
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.exchangeRateList.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.exchangeRateList.data.forEach((row) => this.selection.select(row));
    
  }
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce("Sorting cleared");
    }
  }
  deleteItem(e) {
    e.isActive = "N";
    this.confirmDialogService.open({ header: "", content: "คุณต้องการลบข้อมูลนี้หรือไม่?" }).then(res => {
      
      if (res == false) {
        return;
      } else {
        this.appMasterService.saveExchangeRate(e).then((res: any) => {
          if (res == "error") {

          } else {
            if (res === 'error') {
              return
            } else {
              this.alertMessageService.open({ message: "ลบข้อมูลสำเร็จ", title: "" }).then((res) => {
                this.loadData(this.searchForm);
              })
            }
          }
        });
      }
    });
  }
  deleteSelection() {
    let number = 0;
    this.confirmDialogService.open({ header: "", content: "คุณต้องการลบข้อมูลนี้หรือไม่?" }).then(res => {
      if (res == false) {
        return;
      } else {
        this.selection.selected.map((item) => {
          item.isActive = "N";
          this.appMasterService.saveExchangeRate(item).then((res: any) => {
            if (res === 'error') {
              this.alertMessageService.open({ message: "เกิดข้อผิดพลาดในการลบข้อมูล", title: "" })
            } else {
              number += 1;
              if (number == this.selection.selected.length) {
                this.alertMessageService.open({ message: "ลบข้อมูลสำเร็จ", title: "" }).then((res) => {
                  this.selection.clear();
                  this.loadData(this.searchForm);
                })
              }
            }
          });
        });
      }
    });
  }

  editItem(e) {
    
    const editItemDialog = this.dialog.open(ExchangeRatePopupComponent, {
      disableClose: false,
      width: "60vw",
      data: {
        form: e,
      },
      maxWidth: "none",
    });
    editItemDialog.afterClosed().subscribe((res) => {
      
      if (res) {
        this.loadData(this.searchForm);
      }
    });
  }
  addExchangeRate() {
    const addItemDialog = this.dialog.open(ExchangeRatePopupComponent, {
      disableClose: false,
      width: "60vw",
      data: {
        form: {
          id: 0,
          isActive: "Y",
        },
      },
      maxWidth: "none",
    });
    addItemDialog.afterClosed().subscribe((res) => {
      
      if (res) {
        this.loadData(this.searchForm);
      }
      this.selection.clear();
    });
  }

  clearOption() {
    this.searchForm = {};
  }
}
