<!-- <ng-container *ngIf="show">
    <mat-progress-bar [mode]="mode" [value]="progress"></mat-progress-bar>
</ng-container> -->
<ng-container *ngIf="show">
    <div class="loading">
        <img src="assets/images/logo/icon.png" alt="Fuse logo">
        <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
    </div>
</ng-container>