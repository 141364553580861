<meta name="viewport" content="width=device-width, initial-scale=1">
<div class="flex flex-col h-full gap-4">
    <div style="gap: 15px;" class="flex-col justify-items-stretch flex">
        <mat-card class="flex flex-col gap-4">
            <mat-card-header>
                <h2 class="text-center cursor-pointer">รายละเอียดคำขอ | RQ </h2>
            </mat-card-header>
            <mat-card-content>
                <!-- line 1 -->
                <div class="flex mt-4 gap-3 flex-row">
                    <div class="w-1/2 flex flex-row">
                        <div class="w-1/4 flex flex-col">
                            <h4>เลขที่คำขอ</h4>
                            <h4>Request No</h4>
                        </div>
                        <mat-form-field class="flex-auto">
                            <input matInput [(ngModel)]="form.rqNo" disabled [ngModelOptions]="{standalone: true}">
                        </mat-form-field>
                    </div>
                    <div class="w-1/2 flex flex-row">
                        <div class="w-1/4 flex flex-col">
                            <h4>เลขที่ THT</h4>
                            <h4>Certificate no</h4>
                        </div>
                        <mat-form-field class="flex-auto">
                            <input matInput [(ngModel)]="form.thtNo" disabled [ngModelOptions]="{standalone: true}">
                        </mat-form-field>
                    </div>
                </div>
                <!-- line 2 -->
                <div class="flex mt-4 gap-3 flex-row">
                    <div class="w-1/2 flex flex-row">
                        <div class="w-1/4 flex flex-col">
                            <h4>ชื่อผู้ยื่นคำขอ</h4>
                            <h4>Request By</h4>
                        </div>
                        <mat-form-field class="flex-auto">
                            <input matInput [(ngModel)]="form.requestByName" disabled
                                [ngModelOptions]="{standalone: true}">
                        </mat-form-field>
                    </div>
                    <div class="w-1/2 flex flex-row">
                        <div class="w-1/4 flex flex-col">
                            <h4>ชื่อผู้ส่งออกสินค้า</h4>
                            <h4>Consignor Name</h4>
                        </div>
                        <mat-form-field class="flex-auto">
                            <input matInput [(ngModel)]="form.declaratorName" disabled
                                [ngModelOptions]="{standalone: true}">
                        </mat-form-field>
                    </div>
                </div>
                <!-- line 3 -->
                <div class="flex mt-4 gap-3 flex-row">
                    <div class="w-1/2 flex flex-row">
                        <div class="w-1/4 flex flex-col">
                            <h4>เลขที่ใบกำกับสินค้า</h4>
                            <h4>Invoice No</h4>
                        </div>
                        <mat-form-field class="flex-auto">
                            <input matInput [(ngModel)]="form.refNo" disabled [ngModelOptions]="{standalone: true}">
                        </mat-form-field>
                    </div>
                    <div class="w-1/2 flex flex-row">
                        <div class="w-1/4 flex flex-col">
                            <h4>ประเภทคำขอ</h4>
                            <h4>Document Type</h4>
                        </div>
                        <mat-form-field class="flex-auto">
                            <input matInput [(ngModel)]="form.docType" disabled [ngModelOptions]="{standalone: true}">
                        </mat-form-field>
                    </div>
                </div>

            </mat-card-content>
        </mat-card>
        <!-- Remark -->
        <mat-card class="flex flex-col gap-4">
            <mat-card-header>
                <h2 class="text-center cursor-pointer">หมายเหตุในการขอปลดล็อคการพิมพ์ | Remark </h2>
            </mat-card-header>
            <mat-card-content>
                <!-- line 1 -->
                <div class="flex mt-4 gap-3 flex-row">
                    <div class="w-1/2 flex flex-row">
                        <div class="w-1/4 flex flex-col">
                            <div class="flex flex-row">
                                <h4>หมายเหตุ </h4>
                                <h4 style="color:red">*</h4>
                            </div>
                            <h4>Remark</h4>
                        </div>
                        <div class="flex-auto">
                            <mat-form-field class="w-full">
                                <textarea matInput [(ngModel)]="form.remark" [ngModelOptions]="{standalone: true}"
                                    [disabled]="true"></textarea>
                            </mat-form-field>
                            <h4 style="color:red">*** กรุณาใส่ชื่อกับเบอร์โทรศัพท์ติดต่อได้โดยตรง</h4>
                        </div>
                    </div>
                    <div class="w-1/2 flex flex-row"
                        *ngIf="userProfile.gId == '2' || userProfile.gId == '3' || userProfile.gId == '8'">
                        <div class="w-1/4 flex flex-col">
                            <h4>หมายเหตุจากเจ้าหน้าที่</h4>
                            <h4>Verify Remark</h4>
                        </div>
                        <div class="flex-auto">
                            <mat-form-field class="w-full">
                                <textarea matInput [(ngModel)]="form.verifyRemake" [ngModelOptions]="{standalone: true}"
                                    [disabled]="true"></textarea>
                            </mat-form-field>
                        </div>

                    </div>
                </div>

            </mat-card-content>
        </mat-card>
        <!-- จดหมายชี้แจ้งหรือภาพถ่ายเอกสารเสียหาย -->
        <mat-card class="flex flex-col gap-4">
            <mat-card-header>
                <div class="text-center">เอกสารประกอบคำขอ | ATTACHMENT <span class="requiredField">*</span></div>
            </mat-card-header>
            <mat-card-content>
                <mat-label *ngIf="!editJobData" [class.hidden]="editItem" style="color : red;">
                    รายละเอียดของไฟล์ภาพควรมีขนาดไม่เกิน 3 MB / รูปแบบไฟล์ที่อนุญาต .jpg, .jpeg, .png
                </mat-label>
                <section class="flex flex-row gap-4 ">
                    <div class="file-uploader-block" *ngIf="!editJobData" [class.hidden]="editItem">
                        <dx-file-uploader style="border: none;padding: 0;" [allowCanceling]="true" [(value)]="value"
                            #fileUploader [multiple]="false" [uploadMode]="'useForm'"
                            accept="image/jpeg, image/png ,image/jpg"
                            [allowedFileExtensions]="['.jpg', '.jpeg', '.png']">
                        </dx-file-uploader>

                    </div>

                </section>
                <div class="flex flex-row gap-4 mb-3 ml-3" *ngIf="!editJobData" [class.hidden]="editItem">
                    <dx-button text="Upload" (onClick)="onFileChangedBr()">
                    </dx-button>
                </div>

                <div class="flex-auto" style="margin-top:12px;">
                    <app-upload-document-reprint [disabled]="editJobData || editItem"
                        [uploadPhotoData]="dataSourceUpload" [uploadPhotoForm]="formSourceUpload">
                    </app-upload-document-reprint>
                </div>
            </mat-card-content>
        </mat-card>
        <!-- หมายเหคุการพิจารณา -->
        <mat-card class="flex flex-col gap-4">
            <mat-card-header>
                <div class="text-center">จดหมายชี้แจ้งหรือภาพถ่ายเอกสารเสียหาย <span class="requiredField">*</span>
                </div>
            </mat-card-header>
            <mat-card-content>
                <!-- line 1 -->
                <div class="flex mt-4 gap-3 flex-row">
                    <div class="w-1/2 flex flex-row">
                        <div class="w-1/4 flex flex-col">
                            <h4>พิจารณาค่าใช้จ่าย<span style="color:red">*</span></h4>
                            <h4>Charges</h4>
                        </div>
                        <div class="flex-auto">
                            <mat-radio-group aria-label="Select an option" class="flex flex-col"
                                [(ngModel)]="form.isPayment" [ngModelOptions]="{standalone: true}"
                                [disabled]="editItem">
                                <mat-radio-button value="Y">
                                    <div class="flex flex-col">
                                        <div>ต้องชำระค่าใช้จ่าย</div>
                                    </div>
                                </mat-radio-button>
                                <mat-radio-button value="N">
                                    <div class="flex flex-col">
                                        <div>ยกเลิกค่าใช้จ่าย</div>
                                    </div>
                                </mat-radio-button>

                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="w-1/2 flex flex-row">


                    </div>
                </div>

                <!-- line 2 -->
                <div class="flex mt-4 gap-3 flex-row">
                    <div class="w-1/2 flex flex-row">
                        <div class="w-1/4 flex flex-col">
                            <h4>หมายเหตุ<span style="color:red">*</span></h4>
                            <h4>Remark</h4>
                        </div>
                        <div class="flex-auto">
                            <mat-form-field class="w-full">
                                <textarea matInput [(ngModel)]="form.verifyRemark" [ngModelOptions]="{standalone: true}"
                                    [disabled]="editItem"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="w-1/2 flex flex-row">


                    </div>
                </div>

            </mat-card-content>
        </mat-card>
        <!-- Button -->
        <div class="flex flex-row justify-center my-3 gap-4" *ngIf="!staff">
            <button class="set-btn-unlimited flex-none " mat-raised-button color="accent" (click)="save()"
                *ngIf="!editItem" [disabled]="clicked">อนุมัติคำขอ</button>
            <button class="set-btn-unlimited flex-none " mat-raised-button color="accent" (click)="saveReject(36)"
                *ngIf="!editItem" [disabled]="clicked">ไม่อนุมัติคำขอ</button>

            <button class="set-btn-unlimited flex-none " mat-raised-button color="accent"
                (click)="backClicked()">ย้อนกลับ</button>

        </div>


    </div>
</div>