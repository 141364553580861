import { Injectable, EventEmitter } from '@angular/core';
import { ConfirmDialogInputModel } from './confirm-dialog-model';
import { Output } from '@angular/core';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class ConfirmDialogService {

  constructor(
    private dialog: MatDialog
  ) { }
  
  open(config: ConfirmDialogInputModel): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '32vw',
        data: config,
        disableClose: config.disableClose != undefined && config.disableClose != null ? config.disableClose : false
      });
      dialogRef.afterClosed().subscribe(res => {
        resolve(res || false);
      });
    });
  }
}
