import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { AppMenuService } from 'app/business/service/app/app-menu.service';
import { AppUserService } from 'app/business/service/app/app-user.service';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import moment from 'moment';
import { UserModalComponent } from '../user-modal/user-modal.component';
import { debounceTime, map, startWith, switchMap, takeUntil } from "rxjs/operators";
import { HttpLoadingService } from '@bundle/service/http-loading.service';
import { of, Subject } from "rxjs";
import { companySearch } from 'app/business/interface/app/app-company.interace'
import { AppCompanyService } from 'app/business/service/app/app-company.service'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { AuthService } from 'app/core/auth/auth.service';

@Component({
  selector: 'app-user-filter-form',
  templateUrl: './user-filter-form.component.html',
  styleUrls: ['./user-filter-form.component.scss']
})
export class UserFilterFormComponent implements OnInit {
  filterList: any;
  form: any = {};
  showField = false;
  userRoleList: any[];
  userGroupList: any[];
  clientList: any[];
  dataSourceList: any;
  url: any;
  buttonList: any[] = [];
  apiRequest: any = {};
  tempData: any = {};
  listGroupSelectAction: any ={}
  userToken: any;
  lookupStatusCode: any[] = [
    { code: "Y", name: "ใช้งาน" },
    { code: "N", name: "ยกเลิก" },
  ];
;

  dataFilter = [
    { seq: '1', property: 'userName', value: '', label: 'Username : ทั้งหมด', hasValue: true },
    { seq: '2', property: 'name', value: '', label: 'ชื่อผู้ใช้ : ทั้งหมด', hasValue: true },
    { seq: '3', property: 'email', value: '', label: 'Email : ทั้งหมด', hasValue: true },
    { seq: '4', property: 'clientCode', value: '', label: 'ลูกค้า : ทั้งหมด', hasValue: true },
    { seq: '5', property: 'userGroupCode', value: '', label: 'กลุ่มผู้ใช้ : ทั้งหมด', hasValue: true },
    { seq: '6', property: 'userRoleCode', value: '', label: 'สิทธิ์ผู้ใช้ : ทั้งหมด', hasValue: true },
    { seq: '7', property: 'isActive', value: '', label: 'สถานะ  : ทั้งหมด', hasValue: true },
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;

  searchCompanyForm: FormGroup;
  valueSelect: boolean = false;
  isLoading: boolean = false;
  filteredOptions$: any = [];
  constructor (
    private _formBuilder: FormBuilder,
    private appUserService: AppUserService,
    private appMasterService: AppMasterService,
    private router: Router,
    private appMenuService: AppMenuService,
    private dialog: MatDialog,
    private httpLoadingService: HttpLoadingService,
    private appCompanyService: AppCompanyService,
    private _authService:AuthService,
  ) {
  }

  async ngOnInit() {
    this.userToken = JSON.parse(
      this._authService.loginUser
    )
    this.searchCompanyForm = this._formBuilder.group({
      companySearch: ['']
    });
    this.valueChange();

    await this.getRouter();
    this.searchFormReset();
    this.setSubcribe();
    this.loadMaster();
  }

  async getRouter(): Promise<void> {
    
    this.url = this.router.url;

    const formData = {
      url: this.url,
      userGroupCode: this.userToken.userGroupCode,
      userRoleCode: this.userToken.userRoleCode,
      userCode: this.userToken.userCode
    };
    this.buttonList = await this.appMenuService.getButtonPermissionPerPages(formData);
  }
  async loadMaster() {
    let request ={
      hCode : 'GROUP_SELECT_ACTION'
    }
    await this.appMasterService
      .getConfigDByHCode(request)
      .then((res: any) => {
        if (res === 'error') {
          return
        } else {
          this.listGroupSelectAction = res;
          this.form.groupSelect = 'ALL'
        }
      }
    );

  }

  setSubcribe(): void {
   
    this.appUserService.userRoleList.subscribe(res => {
      if (res && res.length > 0) {
        this.userRoleList = res?.filter(x => x.isActive === 'Y');
      } else {
        this.userRoleList = new Array();
      }
    });
    this.appUserService.userGroupList.subscribe(res => {
      if (res && res.length > 0) {
        this.userGroupList = res?.filter(x => x.isActive === 'Y');
      } else {
        this.userGroupList = new Array();
      }
    });
    // this.appMasterService.clientList.subscribe(res => {
    //   if (res && res.length > 0) {
    //     this.clientList = res;
    //   } else {
    //     this.clientList = new Array();
    //   }
    // });
  }

  searchFormReset(): void {
    this.searchCompanyForm.get('companySearch').setValue('');
    this.searchFormInit();
    this.searchFormSubmit();
  }
  searchFormInit(): void {
    this.form = {
      userName: '',
      name: '',
      email: '',
      userGroupCode: '',
      userRoleCode: '',
      isActive: '',
      companyCode: null
    };

    this.form.groupSelect = 'ALL'

    const data = this.dataFilter;
    // const data = this.searchFormInit();
    this.filterList = data.filter(a => a.hasValue);
    this.filterList = this.filterList.sort((first, second) => {
      return (first.seq - second.seq);
    });
  }
  searchFormSubmit(): void {
    this.apiRequest = {
      userName: this.form.userName,
      name: this.form.name,
      email: this.form.email,
      userGroupCode: this.form.userGroupCode,
      userRoleCode: this.form.userRoleCode,
      isActive: this.form.isActive,
      rdbGroupList : this.form.groupSelect,
      companyCode: this.form.companyCode,
      cpId: parseInt(this.userToken.cpId),
      pagination: {
        Skip: 0,
        Take: 10,
      }
    };

    this.customStore()
  }

  getDataUserList(): void {
    this.searchFormSubmit();
  }

  userNameChange(): void {
    let data = this.filterList.filter(res => {
      return res.property === 'userName';
    });
    // 

    data = data.map(res => {
      return {
        ...res,
        label: this.form.userName !== '' ? 'Username : ' + this.form.userName : 'Username : ทั้งหมด',
        value: this.form.userName !== '' ? this.form.userName : ''
      };
    });
    this.dataFilterChange('userName', data);
  }

  nameChange(): void {
    let data = this.filterList.filter(res => {
      return res.property === 'name';
    });
    // 

    data = data.map(res => {
      return {
        ...res,
        label: this.form.name !== '' ? 'ชื่อผู้ใช้ : ' + this.form.name : 'ชื่อผู้ใช้ : ทั้งหมด',
        value: this.form.name !== '' ? this.form.name : ''
      };
    });
    this.dataFilterChange('name', data);
  }

  rdbGroupChange(): void {
    let data = this.filterList.filter(res => {
      return res.property === 'name';
    });
    // 

    data = data.map(res => {
      return {
        ...res,
        label: this.form.name !== '' ? 'ชื่อผู้ใช้ : ' + this.form.name : 'ชื่อผู้ใช้ : ทั้งหมด',
        value: this.form.name !== '' ? this.form.name : ''
      };
    });
    this.dataFilterChange('name', data);
  }

  emailChange(): void {
    let data = this.filterList.filter(res => {
      return res.property === 'email';
    });
    // 

    data = data.map(res => {
      return {
        ...res,
        label: this.form.email !== '' ? 'Email : ' + this.form.email : 'Email : ทั้งหมด',
        value: this.form.email !== '' ? this.form.email : ''
      };
    });
    this.dataFilterChange('email', data);
  }

  clientChange(): void {
    let data = this.filterList.filter(res => {
      return res.property === 'clientCode';
    });
    // 
    const clientItem = this.clientList.find(e => e.clientCode === this.form.clientCode);
    data = data.map(res => {
      return {
        ...res,
        label: this.form.clientCode !== '' ? 'ลูกค้า : ' + clientItem?.compName : 'ลูกค้า : ทั้งหมด',
        value: this.form.clientCode !== '' ? this.form.clientCode : ''
      };
    });
    this.dataFilterChange('clientCode', data);
  }

  userGroupChange(): void {
    let data = this.filterList.filter(res => {
      return res.property === 'userGroupCode';
    });
    // 
    const userGroup = this.userGroupList.find(e => e.userGroupCode === this.form.userGroupCode);
    data = data.map(res => {
      return {
        ...res,
        label: this.form.userGroupCode !== '' ? 'กลุ่มผู้ใช้ : ' + userGroup?.groupName : 'กลุ่มผู้ใช้ : ทั้งหมด',
        value: this.form.userGroupCode !== '' ? this.form.userGroupCode : ''
      };
    });
    this.dataFilterChange('userGroupCode', data);
  }

  userRoleChange(): void {
    let data = this.filterList.filter(res => {
      return res.property === 'userRoleCode';
    });
    // 
    const userRole = this.userRoleList.find(e => e.userRoleCode === this.form.userRoleCode);
    data = data.map(res => {
      return {
        ...res,
        label: this.form.userRoleCode !== '' ? 'สิทธิ์ผู้ใช้ : ' + userRole?.roleName : 'สิทธิ์ผู้ใช้ : ทั้งหมด',
        value: this.form.userRoleCode !== '' ? this.form.userRoleCode : ''
      };
    });
    this.dataFilterChange('userRoleCode', data);
  }

  isActiveChange(): void {
    let data = this.filterList.filter(res => {
      return res.property === 'isActive';
    });
    // 
    const status = this.form.isActive === 'Y' ? 'ใช้งาน' : 'ยกเลิก';
    data = data.map(res => {
      return {
        ...res,
        label: this.form.isActive !== '' ? 'สถานะ : ' + status : 'สถานะ : ทั้งหมด',
        value: this.form.isActive !== '' ? this.form.isActive : ''
      };
    });
    this.dataFilterChange('isActive', data);
  }

  dataFilterChange(propName: string, dataChange: any): void {
    const datanotFind = this.filterList.filter(res => {
      return res.property !== propName;
    });
    this.filterList = [];
    this.filterList = this.filterList.concat(
      datanotFind,
      dataChange
    );
    this.filterList = this.filterList.sort((first, second) => {
      return (first.seq - second.seq);
    });
  }

  removableFilter(item): boolean {
    // if (this.data.find(e => e.property === item.property)) {
    //     return item.value !== '';
    // }
    // return true;
    if (item.property !== 'userName' && item.property !== 'name' && item.property !== 'email'
      && item.property !== 'clientCode' && item.property !== 'userGroupCode' && item.property !== 'userRoleCode'
      && item.property !== 'isActive') {
      return true;
    } else {
      return item.value !== '';
    }
  }

  removeFilter(item): void {
    this.filterList.map(element => {
      if (element.property === item.property) {
        element.value = '';
        if (!this.dataFilter.find(e => e.property === item.property)) {
          element.hasValue = false;
        } else {
          switch (item.property) {
            case 'userName': {
              element.label = 'Username : ทั้งหมด';
              this.form.userName = '';
              this.getDataUserList();
              break;
            }
            case 'name': {
              element.label = 'ชื่อผู้ใช้ : ทั้งหมด';
              this.form.name = '';
              this.getDataUserList();
              break;
            }
            case 'email': {
              element.label = 'Email : ทั้งหมด';
              this.form.email = '';
              this.getDataUserList();
              break;
            }
            case 'clientCode': {
              element.label = 'ลูกค้า : ทั้งหมด';
              this.form.clientCode = '';
              this.getDataUserList();
              break;
            }

            case 'userGroupCode': {
              element.label = 'กลุ่มผู้ใช้ : ทั้งหมด';
              this.form.userGroupCode = '';
              this.getDataUserList();
              break;
            }
            case 'userRoleCode': {
              element.label = 'สิทธิ์ผู้ใช้ : ทั้งหมด';
              this.form.userRoleCode = '';
              this.getDataUserList();
              break;
            }
            case 'isActive': {
              element.label = 'สถานะ  : ทั้งหมด';
              this.form.isActive = '';
              this.getDataUserList();
              break;
            }
          }
        }
      }
      return element;
    });
  }

  customStore(): void {
    this.dataSourceList = new CustomStore({
      useDefaultSearch: true,
      load: async (loadOptions) => {
        
        if (loadOptions.take > 0) {
          this.apiRequest.pagination = {
            skip: loadOptions.skip,
            take: loadOptions.take
          };
          const result: any = await this.appUserService.getUserList(this.apiRequest);
          var resDet = result.dataDetail;
          if (resDet || resDet.length !== 0) {
            resDet.forEach(result => {
              result.createDate = result.createDate ? moment(result.createDate).format('DD/MM/YYYY') : '';
              result.updateDate = result.updateDate ? moment(result.updateDate).format('DD/MM/YYYY') : '';
              result.status = result.isActive === 'Y' ? 'ใช้งาน' : 'ยกเลิก';
            });
          }
          this.tempData = {
            data: resDet,
            totalCount: result.totalResults
          };
          return this.tempData;
        }
        else {
          return this.tempData;
        }
      }
    });
  }

  editUser(row: any): void {
    
    const userModalRef = this.dialog.open(UserModalComponent, {
      disableClose: true,
      width: '60vw',
      data: row
    });

    userModalRef.afterClosed().subscribe(res => {
      if (res) {
        this.getDataUserList();
      }
    });
  }

  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find(e => e.buttonCode == buttonCode);

    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }

  selectionChange(e): void {
    this.searchCompanyForm.get('companySearch').setValue(e.option.value.display);
    this.companySelect(e.option.value);
  }

  companySelect(e) {
    this.form.companyCode = e.cpid;
    this.valueSelect = true;
  }

  valueChange() {
    this.filteredOptions$ = this.searchCompanyForm.get('companySearch').valueChanges.pipe(
      debounceTime(500),
      switchMap(value => {
        if (!value) {
          this.valueSelect = false;
          this.form.companyCode = null;
          return of(null);
        }

        this.httpLoadingService.enabled = false;
        this.isLoading = true;

        const key = value;
        if (key !== '') {
          if (this.valueSelect) {
            return of(null);
          }
          else {
            this.valueSelect = false;
            return this.appCompanyService.getListCompanyByFilter(key);
          }
        } else {
          this.valueSelect = false;
          this.isLoading = false;
          return;
        }
      }),
      map((res: any[]) => {
        this.isLoading = false;
        this.httpLoadingService.enabled = true;

        return res?.map(item => {
          const { companyTaxId, companyNameTh } = (item as companySearch);
          return {
            ...item,
            // tslint:disable-next-line: max-line-length
            display: `${companyTaxId} ${companyNameTh}`
          };
        });
      })
    );
  }

}
