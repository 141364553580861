<div style="display: flex;flex-flow: row;gap: 30px;height: 5vh;justify-content: space-between;align-items: center;color: white;
    background-color: white;font-size: 20px;" class=" pl-5 pr-5">
    <!-- #003f80 -->
    <!-- <h2 style="text-align: center;">จำนวนคิวที่รอ</h2> -->
    <mat-icon style="cursor:pointer; color: white;" mat-dialog-close (click)="cancel()">cancel</mat-icon>
</div>
<!-- class="mat-typography" -->
<!-- <mat-dialog-content class="flex justify-center"> -->
<div class="flex justify-center">
    <div class="flex flex-col justify-center gap-4">
        <h2 class="QTextSize  tracking-tight leading-none">จำนวนคิวที่รอ</h2>
        <h1 class="QFontSize  font-bold tracking-tight leading-none text-red-500" >{{queueWaiting}}</h1>
        <mat-checkbox class="example-margin px-1" matInput [(ngModel)]="isNS" (ngModelChange)="modelChange($event)" *ngIf="!isChkOldPriority">
            ต้องการใบรับรองด่วน
            (ชำระค่าธรรมเนียมเพิ่มเติม)
        </mat-checkbox>
    </div>

</div>
<!-- </mat-dialog-content> -->
<mat-dialog-actions align="center">
    <button mat-raised-button color="accent" (click)="cancel()">ยกเลิก</button>
    <button mat-raised-button color="accent" (click)="save()">ยืนยันรับคิว</button>

</mat-dialog-actions>