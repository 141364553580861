import { LiveAnnouncer } from '@angular/cdk/a11y'
import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  Inject,
} from '@angular/core'
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort, Sort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { Router } from '@angular/router'
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'
import { IGetListJobRequest } from 'app/business/interface/app/app-job.interface'
import { AppCOService } from 'app/business/service/app/app-co.service'
import { AppRecheckService } from 'app/business/service/app/app-recheck.service'
import { AppRequestService } from 'app/business/service/app/app-request.service'
import { messages } from 'app/mock-api/apps/chat/data'
import { DxDataGridComponent } from 'devextreme-angular'
import moment from 'moment'

@Component({
  selector: 'app-verify-recheck-popup-detail',
  templateUrl: './verify-recheck-popup-detail.component.html',
  styleUrls: ['./verify-recheck-popup-detail.component.css'],
})
export class VerifyRecheckPopupDetailComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort
  displayedColumns: string[] = [
    'rqNo',
    'thtNo',
    'requestDate',
    'docType',
    'rqStatus',
    'declarator',
    'action',
  ]
  createJobList = new MatTableDataSource<any>()
  count: number
  apiRequest: IGetListJobRequest = {
    thtNo: '',
    rqNo: '',
    pagination: {
      Skip: 0,
      Take: 10,
    },
  }
  searchString: string
  form: any = {
    recheckId: null,
    packId: '',
    rqNo: '',
    declarator: '',
  }
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private _liveAnnouncer: LiveAnnouncer,
    private appRequestService: AppRequestService,
    private dialog: MatDialog,
    private alertService: AlertMessageService,
    public dialogRef: MatDialogRef<VerifyRecheckPopupDetailComponent>,
    private router: Router,
    private appRecheckService: AppRecheckService,
  ) {
    
    this.form = data
  }
  ngOnInit() {
  }

  save() {
    
    if (this.form.reason.length === 0) {
      this.alertService.open({message: "กรุณาระบุเหตุผลของการขอตรวจซ้ำ", title: ""});
    } else {
      this.appRecheckService.saveCreateRecheck(this.form).then((res) => {
        this.form = {
          ...this.form,
          res:res,
        }
        debugger;
        if (res === 'error') {
          this.dialogRef.close(this.form)
        } else {
          this.alertService
          .open({ title: '', message: 'บันทึกคำขอสำเร็จ' })
          .then((res) => {
              this.dialogRef.close(this.form)
          });
        }
      });  
    }
  }
  close() {
    this.dialog.closeAll()
  }
}
