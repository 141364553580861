<meta name="viewport" content="width=device-width, initial-scale=1">
<div class="flex flex-col h-full gap-4">
    <div style="gap: 15px;" class="flex-col justify-items-stretch flex">
        <mat-card class="flex flex-col gap-4">
            <mat-card-header>
                <h2 class="text-center cursor-pointer">รายละเอียดคำขอ | RQ </h2>
            </mat-card-header>
            <mat-card-content>
                <!-- line 1 -->
                <div class="flex mt-4 gap-3 flex-row">
                    <div class="w-1/2 flex flex-row">
                        <div class="w-1/4 flex flex-col">
                            <h4>เลขที่คำขอ</h4>
                            <h4>Request No</h4>
                        </div>
                        <mat-form-field class="flex-auto">
                            <input matInput [(ngModel)]="form.rqNo" disabled [ngModelOptions]="{standalone: true}">
                        </mat-form-field>
                    </div>
                    <div class="w-1/2 flex flex-row">
                        <div class="w-1/4 flex flex-col">
                            <h4>เลขที่ THT</h4>
                            <h4>Certificate no</h4>
                        </div>
                        <mat-form-field class="flex-auto">
                            <input matInput [(ngModel)]="form.thtNo" disabled [ngModelOptions]="{standalone: true}">
                        </mat-form-field>
                    </div>
                </div>
                <!-- line 2 -->
                <div class="flex mt-4 gap-3 flex-row">
                    <div class="w-1/2 flex flex-row">
                        <div class="w-1/4 flex flex-col">
                            <h4>ชื่อผู้ยื่นคำขอ</h4>
                            <h4>Request By</h4>
                        </div>
                        <mat-form-field class="flex-auto">
                            <input matInput [(ngModel)]="form.requestByName" disabled
                                [ngModelOptions]="{standalone: true}">
                        </mat-form-field>
                    </div>
                    <div class="w-1/2 flex flex-row">
                        <div class="w-1/4 flex flex-col">
                            <h4>ชื่อผู้ส่งออกสินค้า</h4>
                            <h4>Consignor Name</h4>
                        </div>
                        <mat-form-field class="flex-auto">
                            <input matInput [(ngModel)]="form.declaratorName" disabled
                                [ngModelOptions]="{standalone: true}">
                        </mat-form-field>
                    </div>
                </div>

            </mat-card-content>
        </mat-card>
        <!-- Remark -->
        <mat-card class="flex flex-col gap-4">
            <mat-card-header>
                <h2 class="text-center cursor-pointer">เหตุผลในการขอปลดล็อคการพิมพ์ | Reason </h2>
            </mat-card-header>
            <mat-card-content>
                <!-- line 1 -->
                <div class="flex mt-4 gap-3 flex-row">
                    <div class="w-1/2 flex flex-row">
                        <div class="w-1/4 flex flex-col">
                            <div class="flex flex-row">
                                <h4>เหตุผล </h4>
                                <h4 style="color:red">*</h4>
                            </div>
                            <h4>Reason</h4>
                        </div>
                        <div class="flex-auto">
                            <mat-form-field class="w-full">
                                <textarea matInput placeholder="โปรดระบุหมายเหตุ" [(ngModel)]="form.remark"
                                    [ngModelOptions]="{standalone: true}" [disabled]="editItem"></textarea>
                            </mat-form-field>
                            <h4 style="color:red">*** กรุณาใส่ชื่อกับเบอร์โทรศัพท์ติดต่อได้โดยตรง</h4>
                        </div>
                    </div>
                    <div class="w-1/2 flex flex-row" *ngIf="editItem == true">
                        <div class="w-1/4 flex flex-col">
                            <h4>หมายเหตุจากเจ้าหน้าที่</h4>
                            <h4>Verify Remark</h4>
                        </div>
                        <div class="flex-auto">
                            <mat-form-field class="w-full">
                                <textarea matInput [(ngModel)]="form.verifyRemake" [ngModelOptions]="{standalone: true}"
                                    [disabled]="true"></textarea>
                            </mat-form-field>
                        </div>

                    </div>
                </div>

            </mat-card-content>
        </mat-card>
        <!-- จดหมายชี้แจ้งหรือภาพถ่ายเอกสารเสียหาย -->
        <mat-card class="flex flex-col gap-4">
            <mat-card-header>
                <div class="text-center">เอกสารประกอบคำขอ | ATTACHMENT</div>
            </mat-card-header>
            <mat-card-content>
                <mat-label *ngIf="!editJobData" [class.hidden]="editItem">
                    รายละเอียดของไฟล์ภาพควรมีขนาดไม่เกิน 3 MB / รูปแบบไฟล์ที่อนุญาต .jpg, .jpeg, .png
                </mat-label>
                <section class="flex flex-row gap-4 ">
                    <div class="file-uploader-block" *ngIf="!editJobData" [class.hidden]="editItem">
                        <dx-file-uploader style="border: none;padding: 0;" [allowCanceling]="true" [(value)]="value"
                            #fileUploader [multiple]="true" [uploadMode]="'useForm'"
                            accept="image/jpeg, image/png ,image/jpg"
                            [allowedFileExtensions]="['.jpg', '.jpeg', '.png']">
                        </dx-file-uploader>

                    </div>

                </section>
                <div class="flex flex-row gap-4 mb-3 ml-3" *ngIf="!editJobData" [class.hidden]="editItem">
                    <dx-button text="Upload" (onClick)="upload()">
                    </dx-button>
                </div>

                <div class="flex-auto" style="margin-top:12px;">
                    <app-upload-document-reprint [disabled]="editJobData || editItem"
                        [uploadPhotoData]="dataSourceUpload" [uploadPhotoForm]="formSourceUpload"
                        (dataSourceUpdate)="onDataSourceUpdate($event)" (formSourceUpdate)="onFormSourceUpdate($event)">
                    </app-upload-document-reprint>
                </div>
            </mat-card-content>
        </mat-card>
        <!-- Button -->
        <div class="flex flex-row justify-center my-3 gap-4" *ngIf="!staff">
            <button class="set-btn-unlimited flex-none " mat-raised-button color="accent" (click)="save(15)"
                *ngIf="!editItem" [disabled]="clicked">บันทึก</button>
            <button class="set-btn-unlimited flex-none " mat-raised-button color="accent" (click)="save(16)"
                *ngIf="!editItem" [disabled]="clicked">บันทึกและส่งตรวจ</button>

            <button class="set-btn-unlimited flex-none " mat-raised-button color="accent"
                (click)="backClicked()">ย้อนกลับ</button>

        </div>


    </div>
</div>