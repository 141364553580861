import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AppBankService } from 'app/business/service/app/app-bank.service';
import { ConfirmPopupComponent } from '../../confirm-popup/confirm-popup.component';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service';
import { AppUserService } from 'app/business/service/app/app-user.service';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
  selector: 'app-config-bank-popup',
  templateUrl: './config-bank-popup.component.html',
  styleUrls: ['./config-bank-popup.component.scss']
})
export class ConfigBankPopupComponent implements OnInit {
  form: any = {};
  bankList: any;
  buttonList: any[] = [];
  userToken:any;
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef: MatDialogRef<ConfigBankPopupComponent>,
    private appBankService: AppBankService,
    private dialog: MatDialog,
    private alertMessageService: AlertMessageService,
    private appUserService:AppUserService,
    private _authService:AuthService,
  ) {
    this.buttonList = data.buttonList;
    if (data.e) {
      this.form = data.e;
    } else {
      this.form.isActive = 'Y';
      this.form.isBotAcc = "Y";
      this.form.bankCode = '';
    }
  }
  ngOnInit(): void {
    this.userToken = JSON.parse(
      this._authService.loginUser
    )   

    const data = {

    };
    this.appBankService.bankCode(data).then((res: any) => {
      if (res === "error") {
        return;
      } else {
        this.bankList = res;
      }
    });
  }

  save() {
    if ((this.form.accTaxNo !== undefined || this.form.accTaxNo !== null || this.form.accTaxNo !== "") &&
    (this.form.bankAccountNo !== undefined || this.form.bankAccountNo !== null || this.form.bankAccountNo !== "") &&
    (this.form.bankAccountName !== undefined || this.form.bankAccountName !== null || this.form.bankAccountName !== "") &&
    (this.form.bankBranch !== undefined || this.form.bankBranch !== null || this.form.bankBranch !== "") &&
    (this.form.bankCode !== "")) {
      let cpId = parseInt(this.userToken.cpId);

      const data = {
        id: this.form.id,
        "bankCode": this.form.bankCode,
        "bankAccountNo": this.form.bankAccountNo,
        "bankAccountName": this.form.bankAccountName,
        "bankBranch": this.form.bankBranch,
        "accTaxNo": this.form.accTaxNo,
        "isBotAcc": this.form.isBotAcc,
        "cId": this.form.cId ?? cpId,
        "isActive": this.form.isActive
      };
      this.appBankService.saveBankAccount(data).then((res: any) => {
        if (res === "error") {
          this.alertMessageService.open({ message: "บันทึกข้อมูลไม่สำเร็จ", title: "" });
        } else {
          this.dialogRef.close(true);
          this.alertMessageService.open({ message: "บันทึกข้อมูลสำเร็จ", title: "" });
        }
      });
    } else {
      this.alertMessageService.open({ message: "กรุณากรอกข้อมูลให้ครบ", title: "ข้อผิดพลาด" });
    }
  }

  onChange(e) {
    if (e.checked == true) {
      this.form.isActive = "Y";
    } else {
      this.form.isActive = "N";
    }
  }

  endPopup(e) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      disableClose: false,
      // // width: '30vw',
      data: {
        header: e,
        button: 'close'
      },
      maxWidth: 'none'
    });

    dialogRef.afterClosed().subscribe(res => {
      this.dialogRef.close();
    });
  }

}
