import { LiveAnnouncer } from "@angular/cdk/a11y";
import { SelectionModel } from "@angular/cdk/collections";
import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { IcoStatus } from "app/business/interface/app/app-co.interface";
import { AppPrintService } from "app/business/service/app/app-print.service";
import { CreateCfInquiryPopupComponent } from "../create-cf-inquiry/create-cf-inquiry-popup/create-cf-inquiry-popup.component";
import { ReprintPopupInquiryComponent } from "./reprint-popup-inquiry/reprint-popup-inquiry.component";
import { ReprintVerifyModalComponent } from "./reprint-verify-modal/reprint-verify-modal.component";
import { ListReprintCreatePopupComponent } from "./list-reprint-create-popup/list-reprint-create-popup.component";
import { Router } from "@angular/router";
import { AppMenuService } from "app/business/service/app/app-menu.service";
import { AppUserService } from "app/business/service/app/app-user.service";
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import { AppRecheckService } from "app/business/service/app/app-recheck.service";
import { ConfirmPopupComponent } from "../confirm-popup/confirm-popup.component";
import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import { AppCOService } from "app/business/service/app/app-co.service";
import { ListCoSendPrintToStaffPopupComponent } from "../list-co-inquiry/list-co-send-print-to-staff-popup-inquiry/list-co-send-print-to-staff-popup-inquiry.component";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import moment from 'moment'
import { PrintPopupInquiryComponent } from "../list-print-inquiry/print-popup-inquiry/print-popup-inquiry.component";
import { AuthService } from "app/core/auth/auth.service";
@Component({
  selector: "app-list-re-print-inquiry",
  templateUrl: "./list-re-print-inquiry.component.html",
  styleUrls: ["./list-re-print-inquiry.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ListRePrintInquiryComponent implements OnInit, AfterViewInit {
  searchForm: any = {
    certificateNo: "",
    endDate: null,
    startDate: null,
    isRqStatus: null,
    receiptNo: "",
    requestNo: "",
    invoiceReference: "",
  };
  
  pagination : any ={} 
  color = "#009efa72";
  userProfile: any = {}
  displayedColumns: string[] = [
    "No",
    "rqNo",
    "docType",
    "refNo",
    "requestDate",
    "thtNo",
    "rqStatusName",
    "action",
  ];
  searchString: string;
  apiRequest: any;
  apiResponse: any[];
  statusControl = new FormControl("");
  exporterControl = new FormControl("");
  exporterOptions: string[];
  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  form: any = {};
  formData: any = {};
  url: any;
  buttonList: any[] = [];
  myDate = new Date();
  
  checkEndDate: boolean = true;
  checkStartDate: boolean = true;
  
  isBlacklisted: boolean = false;
  totalPageCF : number;
  docHid : number;
  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private appPrintService: AppPrintService,
    private router: Router,
    private appMenuService: AppMenuService,
    private appUserService: AppUserService,
    private alertService: AlertMessageService,
    private appRecheckService: AppRecheckService,
    private confirmService: ConfirmDialogService,
    private appCOService: AppCOService,
    private _authService:AuthService,
  ) { }

  async getRouter(): Promise<void> {
    
    this.url = this.router.url;

    const formData = {
      url: this.url,
      userGroupCode: this.userProfile.userGroupCode,
      userRoleCode: this.userProfile.userRoleCode,
      userCode: this.userProfile.userCode
    };
    this.buttonList = await this.appMenuService.getButtonPermissionPerPages(formData);
    
  }

  async ngOnInit() {
    this.userProfile = JSON.parse(
      this._authService.loginUser
    )
   
    await this.getRouter();
    this.formData = {};
    this.pagination ={
      Skip:
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
      Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
    }
    this.loadData(this.apiRequest);
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  loadData(request: any) {
    if (request == null) {
      request = {
        UID: this.userProfile.uId,
        pagination : { 
          Skip:
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
        Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
        }
      };
    }

    this.appPrintService.getListRePrint(request).then((res: any) => {
      if (res === "error") {
        return;
      } else {
        this.dataSource = new MatTableDataSource(res);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

      }
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce("Sorting cleared");
    }
  }
  stopPropagation(event) {
    event.stopPropagation();
  }
  onRefresh() {
    this.apiRequest = {
      UID: this.userProfile.uId,
      startDatetime: this.searchForm.startDate,
      endDatetime: this.searchForm.endDate,
      requestNo: this.searchForm.requestNo,
      thtNo: this.searchForm.certificateNo,
      invoiceRefNo: this.searchForm.invoiceReference,
    };

    this.loadData(this.apiRequest);
  }
  saveSearch() {
    this.apiRequest = {
      UID: this.userProfile.uId,
      startDatetime: this.searchForm.startDate,
      endDatetime: this.searchForm.endDate,
      requestNo: this.searchForm.requestNo,
      thtNo: this.searchForm.certificateNo,
      invoiceRefNo: this.searchForm.invoiceReference,
    };

    this.loadData(this.apiRequest);
  }

  editItem(e) {
    this.router.navigate(["/page/print/create-reprint"], { queryParams: { reprintId: e.reprintId, hId: e.hid } })
  }
  showDetail(e) {
    const queryParams = {
      reprintId: e.reprintId,
      disable: true,
      hId: e.hid,
      search:true,
      staff: true,
      sessionId: this._authService.getUniqueId()
    };

    var url = this.router.createUrlTree(["/page/print/create-reprint"], {
      queryParams
    }).toString();

    var fullUrl = this.buildDynamicURL(url);
    // Open the URL in a new tab when the button is clicked
    window.open(fullUrl, '_blank');
  }

  buildDynamicURL(path) {
    // Get the current host from the browser's location
    const currentProtocol = window.location.protocol;
    const currentHost = window.location.hostname;
    const currentPort = window.location.port;

    // Remove any trailing slashes from the pathname
    const pathname = window.location.pathname.replace(/\/$/, '');

    // Combine the host and pathname to create the base URL
    const baseURL = currentPort ? `${currentProtocol}//${currentHost}:${currentPort}${pathname}/#` : `${currentProtocol}//${currentHost}${pathname}/#`;
    debugger;

    // Combine the base URL with the provided path
    const fullURL = `${baseURL}${path}`;

    return fullURL;
  }

  showApproveUnlock(e): void {
    const dialogRef = this.dialog.open(ReprintVerifyModalComponent, {
      disableClose: false,
      width: "60vw",
      data: e,
      maxWidth: "none",
    });

    dialogRef.afterClosed().subscribe((res) => {
      this.loadData(this.apiRequest);
    });
  }

  clearOption() {
    this.searchForm = {};
  }


  CreateReprint() {
    const dialogRef = this.dialog.open(ListReprintCreatePopupComponent, {
      disableClose: false,
      width: '80vw',
      data: {},
      maxWidth: 'none',
    })
    dialogRef.afterClosed().subscribe((res) => {
      if (res != undefined && res != true) {
        this.router.navigate(["/page/print/create-reprint"], {
          queryParams: {
            hId: res,
          }
        })
      } else {
        return
      }
    })
  }
  calculateIndex(index: number): number {
    return this.dataSource.paginator.pageIndex * this.dataSource.paginator.pageSize + index + 1;
  }

  async deleteItem(e) {
    this.confirmService
      .open({
        header: 'ยืนยันการทำรายการ',
        content: 'ต้องการลบรายการ',
      })
      .then((res) => {
        if (res == true) {
          const request  = {
            hId : e.hid,
            statusReprint : "N",
            reprintId : e.reprintId
          }
           this.appRecheckService.deleteReprint(request).then((res: any) => {
                this.alertService
                .open({
                  message: 'ลบข้อมูลสำเร็จ',
                  title: 'ทำรายการสำเร็จ',
                })
                .then((x) => {
                  this.loadData(this.apiRequest);
                })
          })
          } else {

          }
        })
   
  }
  print(e) {
    this.confirmService
      .open({
        header: 'ยืนยันการทำรายการ',
        content: 'ต้องการพิมพ์เอกสาร',
      })
    .then((res) => {
    if(res == true && (e.docType == 'CO' || e.docType == 'CO/Pack' )){
        const data = {
        Hid: e.hid,
        reprintId : e.reprintId
        }
        this.appCOService.getPdfFilePathReprint(data).then((res: any) => {
          if (res === 'error' || res === null) {
            return
          } else {
            window.open(res, '_blank')
            this.loadData(this.apiRequest);
          }
        })
      }else if(res == true && e.docType == 'CF'){
        this.printCFPopup(e)
      }
    })
    
  }
  openPopupSendPrintTostaff(e){
    let data = {
      packId : e.hid,
      requestNo : e.rqNo,
      docType : e.docType,
      expressType : e.expressType,
      consignor : e.consignor
    }
    
    const dialogRef = this.dialog.open(ListCoSendPrintToStaffPopupComponent, {
      disableClose: false,
      width: '70vw',
      data: data,
      maxWidth: 'none',
    })
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return
      } else {
        this.loadData(this.apiRequest);
        return
      }
    })
  }
  datecheck(e: MatDatepickerInputEvent<Date>) {
    if (
      moment(this.searchForm.startDate).format('YYYY-MM-DD') >
      moment(this.searchForm.endDate).format('YYYY-MM-DD')
    ) {
      this.searchForm.endDate = {};
      this.checkEndDate = false;
    }
  }

  endDateCheck(e) {
    if (
      moment(this.searchForm.startDate).format('YYYY-MM-DD') ==
      moment(this.searchForm.endDate).format('YYYY-MM-DD') ||
      moment(this.searchForm.startDate).format('YYYY-MM-DD') <
      moment(this.searchForm.endDate).format('YYYY-MM-DD')
    ) {
      this.checkEndDate = true;
    }
  }

  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find(e => e.buttonCode == buttonCode);

    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }
  async printCFPopup(e) {
    
    if (e.printStatus == "Y") {
      this.alertService.open({
        title: "ผิดพลาด",
        message:
          "คุณได้ดำเนินการพิมพ์เอกสารไปแล้ว กรุณาติดต่อเจ้าหน้าที่ หรือทำการขอ Reprint ได้ใหม่โดยการเข้าไปที่เมนู Print > Reprint",
      }).then((x) => {
        this.dialog.closeAll();
      });
    } else {
      let data = {
        docId : e.reprintId
      }
      await this.appCOService.getCfTotalPageReprint(data).then((x) => {
        this.docHid = x?.docId;
        this.totalPageCF = x?.totalPage;
      }); 
      let dataPopup = {
        id : e.packId,
        totalPage : this.totalPageCF ? this.totalPageCF : 0,
        hid : this.docHid,
        rqNo : e.rqNo
      }
      const dialogRef = this.dialog.open(PrintPopupInquiryComponent, {
        disableClose: false,
        width: "60vw",
        data: dataPopup,
        maxWidth: "none",
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          if (res.data.checkPrint.filter(Boolean).length !== 0) {
            const data = {
              uid: this.userProfile.uId,
              Hid: res.data.hid,
              id: res.data.id,
              checkPrint: res.data.checkPrint,
              action : "R" ,
              reprintId : e.reprintId
            };
            this.appPrintService.getPrintOriginalCF(data).then((res: any) => {
              if (res === "error") {
                return;
              } else {            
                this.dataSource.paginator = this.paginator;
                this.loadData(this.apiRequest);
                window.open(res.linkUrl, "_blank");
              }
            });
          } else {
            this.alertService.open({
              title: "กรุณากรอกข้อมูลให้ครบถ้วน",
              message: "กรุณาเลือกตำแหน่งที่ต้องการพิมพ์",
            }).then((x) => {
              this.dialog.closeAll();
            });
          }
        }
      });
    }
  }
  
}
