import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AppBankService } from 'app/business/service/app/app-bank.service';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { ConfirmPopupComponent } from '../../confirm-popup/confirm-popup.component';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'

@Component({
  selector: 'app-fee-rate-manage-popup',
  templateUrl: './fee-rate-manage-popup.component.html',
  styleUrls: ['./fee-rate-manage-popup.component.css']
})
export class FeeRateManagePopupComponent implements OnInit {
  form: any = {};
  bankList: any;
  feetypeList: any[] = [];
  buttonList: any[] = [];
  
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef: MatDialogRef<FeeRateManagePopupComponent>,
    private appMasterService: AppMasterService,
    private dialog: MatDialog,
    private alertMessageService: AlertMessageService
  ) {
    this.buttonList = data.buttonList;
    if (data.e) {
      this.form = data.e;
    } else {
      this.form.isActive = 'Y';
      this.form.type = '';
    }
    
  }
  ngOnInit(): void {
    this.feetypeList = [
      {
        "typeCode": "",
        "typeName": "กรุณาเลือก"
      },
      {
        "typeCode": "A",
        "typeName": "คำนวณค่าธรรมเนียมอัตโนมัติ"
      },
      {
        "typeCode": "M",
        "typeName": "คำนวณค่าธรรมเนียมแยกต่างหาก"
      }
    ]
  }

  save() {
    
    if ((this.form.code !== undefined && this.form.code !== null && this.form.code !== "") &&
        (this.form.name !== undefined && this.form.name !== null && this.form.name !== "") &&
        (this.form.val !== undefined && this.form.val !== null && this.form.val !== "") &&
        (this.form.feeunit !== undefined && this.form.feeunit !== null && this.form.feeunit !== "") &&
        (this.form.type !== undefined && this.form.type !== null && this.form.type !== "")) {
      const data = {
        "id": this.form.id,
        "code": this.form.code,
        "name": this.form.name,
        "val": this.form.val,
        "feeunit": this.form.feeunit,
        "type": this.form.type,
        "isActive": this.form.isActive
      };

      this.appMasterService.saveFee(data).then((res: any) => {
        if (res === "error") {
          this.alertMessageService.open({message: "บันทึกข้อมูลไม่สำเร็จ", title:""});
        } else {
          this.dialogRef.close();
          this.alertMessageService.open({message: "บันทึกข้อมูลสำเร็จ", title:""});
        }
      });

    } else {
      this.alertMessageService.open({ message: "กรุณากรอกข้อมูลให้ครบ", title: "ข้อผิดพลาด" });
    }
  }

  onChange(e) {
    if (e.checked == true) {
      this.form.isActive = "Y";
    } else {
      this.form.isActive = "N";
    }
  }

  onChangeOrigin(e) {
    if (e.checked == true) {
      this.form.isCountryOfOrigin = "Y";
    } else {
      this.form.isCountryOfOrigin = "N";
    }
  }

  onChangeDestination(e) {
    if (e.checked == true) {
      this.form.isDestinationCountry = "Y";
    } else {
      this.form.isDestinationCountry = "N";
    }
  }

  endPopup(e) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      disableClose: false,
      // width: '30vw',
      data: {
        header: e,
        button: 'close'
      },
      maxWidth: 'none'
    });

    dialogRef.afterClosed().subscribe(res => {
      this.dialogRef.close();
    });
  }
}
