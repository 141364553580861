import { Component, Inject, OnInit } from '@angular/core'
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'
import { AppMasterService } from 'app/business/service/app/app-master.service'
import { map, Observable, startWith } from 'rxjs'

@Component({
  selector: 'app-create-goodlist-popup',
  templateUrl: './create-goodlist-popup.component.html',
  styleUrls: ['./create-goodlist-popup.component.scss'],
})
export class CreateGoodListPopupComponent implements OnInit {
  form: any = {
    markItem: '',
    //markCHK: false,
    description: '',
    descriptionCHK: false,
    quantity: null,
    quantityObj: {},
    quantityCHK: false,
    weight: null,
    weightObj: {},
    weightCHK: false,
    grossWeight: null,
    grossWeightObj: {},
    grossWeightCHK: false,
    harmonizedCode: '',
    exportValue: null,
    exchangeRate: 29.93,
    exchangeValue: null,
  }
  datasource: any[] = []
  showErrorMessage: any = []
  weightList: any[] = []
  unitList: any[] = []
  harmonizedList: any[] = []
  Harmonizedform: any = {}
  symbolRegex : RegExp
  engRegex : RegExp

  filteredOptions: Observable<string[]>
  apiRequest: any = {
    name: '',
    code: '',
  }
  detail = {
    quantity: '',
    weight: '',
    grossWeight: '',
    exportValue: ''
  }
  apiExchangeRateRequest: any = {}
  form1: any = {}
  floatData: string = ' '
  edit: boolean
  textRegex: RegExp = /^[a-zA-Z0-9 !"$%&'()*+,-./#:;<=>?@[\\\]^_`{|}~\n\r฿]*$/;
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private formBuilder: FormBuilder,
    public apiMasterService: AppMasterService,
    public dialogRef: MatDialogRef<CreateGoodListPopupComponent>,
    public alertService: AlertMessageService,
    private appMasterService :AppMasterService,
  ) {
    this.edit = data.edit
    this.loadData(data.data)
  }
  ngOnInit(): void {
    this.loadCheckValidateConfigD()
  }
  async loadData(data) {
    const dataUnit = {}
    await this.apiMasterService.getListUnit(dataUnit).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        if (res !== null) {
          this.unitList = res
        }
      }
    })
    const dataWeight = {}
    await this.apiMasterService.getListWeight(dataWeight).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        if (res !== null) {
          this.weightList = res
        }
      }
    })
    this.getExchangeRate()
    if (!(Object.keys(data).length === 0 && data.constructor === Object)) {
      this.apiRequest.code = data.harmonizedCode
      await this.getHarmonizedCode()
      this.form = data
      const  {items}  = data;
      if(items) {
        const  dataGoods = items[0];
        this.form = dataGoods;
        this.form.descriptionCHK = dataGoods.descriptionChk == 'Y' ? true : false
        this.form.grossWeightCHK = dataGoods.grossWeightChk == 'Y' ? true : false
        this.form.markCHK = dataGoods.markChk == 'Y' ? true : false
        this.form.quantityCHK = dataGoods.quantityChk == 'Y' ? true : false
        this.form.weightCHK = dataGoods.weightChk == 'Y' ? true : false
        this.Harmonizedform = this.harmonizedList.find(
          (x) => x.code == dataGoods.harmonizedCode,
        )
        this.form1.weightObj = dataGoods.weightUnitCode
        this.form1.quantityObj = dataGoods.quantityUnitCode
        this.form1.grossWeightObj = dataGoods.grossWeightUnitCode
        this.form.quantity = dataGoods.quantity
        this.form.weight = parseFloat(dataGoods.weight).toFixed(3);
        this.form.grossWeight = parseFloat(dataGoods.grossWeight).toFixed(3);
        this.form.exportValue = parseFloat(dataGoods.exportValue).toFixed(3);
        

      }else{
        this.form.descriptionCHK = data.descriptionChk == 'Y' ? true : false
        this.form.grossWeightCHK = data.grossWeightChk == 'Y' ? true : false
        this.form.markCHK = data.markChk == 'Y' ? true : false
        this.form.quantityCHK = data.quantityChk == 'Y' ? true : false
        this.form.weightCHK = data.weightChk == 'Y' ? true : false
        this.Harmonizedform = this.harmonizedList.find(
          (x) => x.code == data.harmonizedCode,
        )
        this.form1.weightObj = data.weightUnitCode
        this.form1.quantityObj = data.quantityUnitCode
        this.form1.grossWeightObj = data.grossWeightUnitCode
      }
     

      await this.getExchangeRate()
      this.updateExchangeValue()
      if (this.edit == false) {
        this.form.quantityObj = this.unitList.find(
          (x) => x.code == this.form1.quantityObj,
        )
        this.detail.quantity = this.formatNumber(this.form.quantity) + ' ' + this.form.quantityObj.name

        this.form.weightObj = this.weightList.find(
          (x) => x.code == this.form1.weightObj,
        )
        this.detail.weight = this.formatDecimalNumber(this.form.weight) + ' ' + this.form.weightObj.name

        this.form.grossWeightObj = this.weightList.find(
          (x) => x.code == this.form1.grossWeightObj,
        )
        this.detail.grossWeight =
        this.formatDecimalNumber(this.form.grossWeight) + ' ' + this.form.grossWeightObj.name

        this.detail.exportValue = this.formatDecimalNumber(this.form.exportValue);
      }
    } else {
      this.form1.weightObj = this.weightList[30].code
      this.form1.grossWeightObj = this.weightList[30].code
      this.form1.quantityObj = this.unitList[0].code

      await this.getHarmonizedCode()
    }
  }

  formatNumber(number) {
    var no = parseInt(number);
    return no.toLocaleString();
  }

  formatDecimalNumber(number) {
    var no = parseFloat(number);
    return no.toLocaleString(undefined, { minimumFractionDigits: 3 });
  }


  async getHarmonizedCode() {
    await this.apiMasterService
      .getHarmonizedCode(this.apiRequest)
      .then((res: any) => {
        if (res == 'error') {
          return
        } else {
          this.harmonizedList = res
          this.filteredOptions = res
        }
      })
  }
  getExchangeRate() {
    this.apiMasterService
      .exchangeratemaxdate(this.apiExchangeRateRequest)
      .then((res: any) => {
        if (res == 'error') {
          return
        } else {
          this.form.exchangeRate = res.rate
        }
      })
  }
  updateExchangeValue() {
    this.form.exchangeValue = (this.form.exportValue * this.form.exchangeRate).toLocaleString();
    // this.form.exchangeValue = parseInt(this.floatData).toFixed(3);
  }
  save() {
    this.form.harmonizedCode = this.Harmonizedform.code
    this.form.weightObj = this.weightList.find(
      (x) => x.code == this.form1.weightObj,
    )
    this.form.quantityObj = this.unitList.find(
      (x) => x.code == this.form1.quantityObj,
    )
    this.form.grossWeightObj = this.weightList.find(
      (x) => x.code == this.form1.grossWeightObj,
    )
    let validate = this.validate(this.form)
    if (validate) {
      this.dialogRef.close(this.form)
    } else {
      return
    }
  }

  loadCheckValidateConfigD() {
    // Load Validate Input symbol
    let validateRequest = {
      configHcode: "VALIDATE",
      configDcode: 'V1',
    }
    this.appMasterService.getValidateInput(validateRequest).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        this.symbolRegex = new RegExp(res.configDEName)
      }
    })

    // Load Validate Input english
    let validateRequestInput = {
      configHcode: "VALIDATE",
      configDcode: 'V2',
    }
    this.appMasterService.getValidateInput(validateRequestInput).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        this.engRegex = new RegExp(res.configDEName);
      }
    })
  }

  validate(form): boolean {
    if (
      !form.markItem ||
      form.markItem === undefined 
    ) {
      this.alertService.open({
        message: 'กรุณาระบุเครื่องหมายหีบห่อ',
        title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
      })
      return false
    }
    if (
      !form.description ||
      form.description === undefined ||
      form.description == ''
    ) {
      this.alertService.open({
        message: 'กรุณาระบุชื่อสินค้า',
        title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
      })
      return false
    }
    // if (
    //   !form.quantity ||
    //   form.quantity === undefined ||
    //   (form.quantity == 0 && form.quantityCHK == false)||
    //   form.quantity == ''
    // ) {
    //   this.alertService.open({
    //     message: 'กรุณาระบุปริมาณสินค้า',
    //     title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
    //   })
    //   return false
    // }
    // if (
    //   !form.quantityObj ||
    //   form.quantityObj === undefined ||
    //   form.quantityObj == 0 ||
    //   (Object.keys(form.quantityObj).length === 0 &&
    //     form.quantityObj.constructor === Object)
    // ) {
    //   this.alertService.open({
    //     message: 'กรุณาระบุหน่วยปริมาณ',
    //     title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
    //   })
    //   return false
    // }
    // if (
    //   !form.weight ||
    //   form.weight === undefined ||
    //   (form.weight == 0  && form.weightCHK == false)||
    //   form.weight == ''
    // ) {
    //   this.alertService.open({
    //     message: 'กรุณาระบุน้ำหนักสินค้า',
    //     title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
    //   })
    //   return false
    // }
    // if (
    //   !form.weightObj ||
    //   form.weightObj === undefined ||
    //   form.weightObj == 0 ||
    //   (Object.keys(form.weightObj).length === 0 &&
    //     form.weightObj.constructor === Object)
    // ) {
    //   this.alertService.open({
    //     message: 'กรุณาระบุหน่วยน้ำหนัก',
    //     title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
    //   })
    //   return false
    // }
    // if (
    //   !form.grossWeight ||
    //   form.grossWeight === undefined ||
    //   (form.grossWeight == 0 && form.grossWeightCHK == false)||
    //   form.grossWeight == ''
    // ) {
    //   this.alertService.open({
    //     message: 'กรุณาระบุน้ำหนักรวมสินค้า',
    //     title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
    //   })
    //   return false
    // }
    // if (
    //   !form.grossWeightObj ||
    //   form.grossWeightObj === undefined ||
    //   form.grossWeightObj == 0 ||
    //   (Object.keys(form.grossWeightObj).length === 0 &&
    //     form.grossWeightObj.constructor === Object)
    // ) {
    //   this.alertService.open({
    //     message: 'กรุณาระบุหน่วยน้ำหนัก',
    //     title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
    //   })
    //   return false
    // }
    if (
      !form.harmonizedCode ||
      form.harmonizedCode === undefined ||
      form.harmonizedCode == 0 ||
      form.harmonizedCode == ''
    ) {
      this.alertService.open({
        message: 'กรุณาระบุพิกัดศุลกากร',
        title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
      })
      return false
    }
    if (
      !form.exportValue ||
      form.exportValue === undefined ||
      form.exportValue == 0 ||
      form.exportValue == ''
    ) {
      this.alertService.open({
        message: 'กรุณาระบุมูลค่าการส่งออก',
        title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
      })
      return false
    }
    if (
      !form.exchangeRate ||
      form.exchangeRate === undefined ||
      form.exchangeRate == 0 ||
      form.exchangeRate == ''
    ) {
      this.alertService.open({
        message: 'กรุณาระบุอัตราแลกเปลี่ยน',
        title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
      })
      return false
    }
    if (
      !form.exchangeValue ||
      form.exchangeValue === undefined ||
      form.exchangeValue == 0 ||
      form.exchangeValue == ''
    ) {
      this.alertService.open({
        message: 'กรุณาระบุมูลค่าการแลกเปลี่ยน',
        title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
      })
      return false
    }
    if (!this.textRegex.test(form.markItem)) {
      this.alertService.open({
        message: 'เครื่องหมายหีบห่อกรอกได้เฉพาะภาษาอังกฤษและสัญลักษณ์',
        title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
      })
      return false
    }
    if (!this.textRegex.test(form.description)) {
      this.alertService.open({
        message: 'ชื่อสินค้ากรอกได้เฉพาะภาษาอังกฤษและสัญลักษณ์',
        title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
      })
      return false
    }
    return true
  }
  cancel(): void {
    this.dialogRef.close()
  }
  selectedOption(e) {}
  valuechange(e) {
    var preName: string
    if (typeof e == 'object') {
      preName = e.name
    } else {
      preName = e
    }

    this.apiRequest = {
      name: preName,
      code: '',
    }
    this.getHarmonizedCode()
  }

  onInput(e) {
    // this.apiRequest.searchText = e.value;
    this.apiRequest.name = e.option.value.name
    this.apiRequest.code = e.option.value.code

    this.apiRequest = {
      name: e.option.value.name == null ? '' : e.option.value.name,
      code: e.option.value.code == null ? '' : e.option.value.code,
    }
  }
  displayFn(value: any): string | undefined {
    if (!value) {
      return ''
    }
    return value ? value.name : undefined
  }
  checkSymbol(e) {
    if (e != null) {
      var validateInput = this.symbolRegex.test(e);
    }
    return validateInput;
  }
  checkEnglish(e) {
    if (e != null && e != "" && e != undefined) {
      const regexPattern: RegExp = /^[a-zA-Z0-9 !"$%&'()*+,-./#:;<=>?@[\\\]^_`{|}~\n\r฿]*$/;
      var validateInput = regexPattern.test(e);
      //validateInput = validateInput === true ? false : true;
    }
    return validateInput;
  }

}
