<mat-card class="flex flex-col h-full gap-4 ">
  <div>
    <!-- <button (click)="zoominPic($event)" class="icon-display"></button> -->
    <button (click)="zoominPic($event)" type="button"
      class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
      <mat-icon svgIcon="heroicons_outline:zoom-in" class="text-white"></mat-icon>
    </button>
    <!-- <button (click)="zoomoutPic($event)" class="icon-display"><mat-icon>zoom_out</mat-icon></button> -->
    <button (click)="zoomoutPic($event)" type="button"
      class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
      <mat-icon svgIcon="heroicons_outline:zoom-out" class="text-white"></mat-icon>
    </button>
    <button (click)="rotateLeft()" type="button"
      class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
      <mat-icon svgIcon="mat_outline:rotate_left" style="color:white;"></mat-icon>
    </button>
    <button (click)="rotateRight()" type="button"
      class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
      <mat-icon svgIcon="mat_outline:rotate_right" style="color:white;"></mat-icon>
    </button>
    <button (click)="addDraggable()" type="button"
      class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
      <mat-icon svgIcon="heroicons_outline:plus" class="text-white"></mat-icon>
    </button>
  </div>
  <div class="boundary">
    <div id="previewImg" lass="boundary" style="width: 100%;
    height: 100%;z-index: -1;" [style.transform]="transform">
      <img id="imagePreview" #imagePreview src="{{AttrachPicChoose.attachFilePic}}" style="position: absolute; 
          max-height: 100%;
          max-width: 100%;
          display: block; 
          cursor: move;
          z-index: 0;"
          [style.transform]="imgTransform"
          (mousedown)="beginDragImg($event)" (mouseup)="releaseDragImg($event)"
        (mousemove)="DragingImg($event)" />

      <div id="divData" #divData *ngFor="let draggable of markErrorListFilter; let i = index" class="drag-source box"
        cdkDrag cdkDragBoundary=".boundary" style="position: absolute; 
               text-align: center;z-index: 99; max-width: 500px;word-wrap: break-word;" [style.display]="displayShow"
        [ngStyle]="{'transform': 'translate(' + markErrorListFilter[i].pos_x+ 'px, ' + markErrorListFilter[i].pos_y + 'px)'}"
        (mouseup)="onDragReleased2($event,i)">
        <label style="white-space: pre-wrap;">{{markErrorListFilter[i].remark}}</label>
        <button (click)="deleteDraggable(markErrorListFilter[i],i)" style="right: 0;top:0;position: absolute;margin-right: -25px;"><mat-icon
            [ngStyle]="{'color':'red'}">close</mat-icon></button>
      </div>
    </div>
  </div>
</mat-card>