<div class="ww">
<div style="display: flex;flex-flow: row;gap: 30px;height: 5vh;justify-content: space-between;align-items: center;color: white;
    background-color: #003f80;font-size: 20px;border-radius: 6px;" class=" pl-5 pr-5"
    (keydown.enter)="$event.preventDefault()">
    <h2 style="text-align: center;">ยืนยันการขอเอกสาร CF</h2>
    <mat-icon style="cursor:pointer; color: white;" mat-dialog-close (click)="cancel()">cancel</mat-icon>
</div>

<mat-dialog-content class="mat-typography">
    <div class="flex flex-col">
        <div class="flex flex-row justify-center">
            <mat-checkbox class="example-margin px-1 pt-3" matInput [(ngModel)]="isCheck">
                ยืนยันเอกสารถูกต้องครบถ้วนสมบูรณ์
            </mat-checkbox>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-raised-button color="accent" mat-dialog-close (click)="cancel()">ยกเลิก</button>
    <button mat-raised-button color="accent" (click)="save()" [disabled]="isCheck == false">บันทึกและส่งตรวจ</button>
</mat-dialog-actions>

</div>