import { Component, ElementRef, HostListener, Inject, ViewChild } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { AppMasterService } from 'app/business/service/app/app-master.service'
import { DxDataGridComponent } from 'devextreme-angular'
interface Position {
	left: number;
	top: number;
}
@Component({
  selector: 'app-draft-co-popup',
  templateUrl: './co-error-mark-image-popup.component.html',
  styleUrls: ['./co-error-mark-image-popup.component.scss'],
})
export class CoErrorMarkImagePopupComponent {
	public anchorPosition: Position;
	public anchorRef!: ElementRef;
  imgSrc:string;
  header: string;
  px: number;
  py: number;
  transform: string = 'scale(1)'; // initial scale factor
  mark: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public apiMasterService: AppMasterService,
    public dialogRef: MatDialogRef<CoErrorMarkImagePopupComponent>,
  ) {
    this.imgSrc = data.urlPic;
    this.mark = data.mark;
    this.header = data.header;
    this.px = data.px;
    this.py = data.py;
  }
  cancel() {
    this.dialogRef.close(false)
  }  
}
