import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogService } from '@bundle/component/confirm-dialog/confirm-dialog.service';
import { MatSort, Sort } from '@angular/material/sort';
import { AppCOService } from 'app/business/service/app/app-co.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { CoErrorMarkImagePopupComponent } from '../create-co-inquiry/co-error-mark-image-popup/co-error-mark-image-popup.component';
import { ImageModalComponent } from '../create-co-inquiry/Image-Modal/Image-Modal.component';


@Component({
  selector: 'app-log-print-history-component',
  templateUrl: './log-print-history.component.html',
  styleUrls: ['./log-print-history.component.scss']
})
export class LogPrintHistoryComponent implements OnInit {
  displayedColumns = ['position', 'attachType', 'attachFilePic', 'remark'];
  dataSource: any = [];

  doc : any ;
  
  @Input()
  set logPrintHistory(value) {
    this.dataSource = value;
  }

  
  @Input()
  set docType(value) {
    this.doc = value;
  }


  constructor(
    private confirmService: ConfirmDialogService,
    private _liveAnnouncer: LiveAnnouncer,
    private appCOService: AppCOService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
  }

 

}