<div style="gap: 30px;padding-top: 30px;" class="flex flex-col h-full">
  <div class="menu justify-between flex items-stretch flex-row gap-5 px-20  pl-10  pr-10">
    <div class="gap-2.5 flex flex-row">
      <button mat-raised-button color="accent" (click)="onRefresh()">
        <div class="flex items-center">
          <span class="icon-text"><mat-icon svgIcon="heroicons_outline:refresh"></mat-icon>รีเฟรชข้อมูล</span>
        </div>
      </button>
    </div>
    <div style="flex: 1;" class="gap-2.5 flex justify-end flex-row">
      <div class="justify-evenly flex gap-2.5 items-center flex-row" style="flex: 0 50%;">
        <mat-form-field style="flex: 0 40%" class="my-form-field flex flex-row">
          <mat-select required placeholder="ประเภทงานด่วน" name="type" (selectionChange)="onExpressTypeChange($event)">
            <mat-option *ngFor="let expressType of expressTypeList" [value]="expressType.value">
              {{expressType.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field style="flex: 1" class="my-form-field flex flex-row">
          <input autocomplete="off" type="text" matInput placeholder="RQ No / ผู้ตรวจ / Invoice No" matInput #input [(ngModel)]="form.search"
            name="search" #search="ngModel" (keyup)="applyFilter($event.target.value)">
          <button matSuffix mat-icon-button aria-label="ค้นหา">
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>

  </div>
  <div class="mat-elevation-z8">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <table mat-table matSort style="width: 100%;" [dataSource]="dataSource"
      (matSortChange)="announceSortChange($event)">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef class="text-center">
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()">
            Select
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>
      <!-- Position Column -->
      <ng-container matColumnDef="requestNo">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by number"
          class="text-left"> RQ No.
        </th>
        <td mat-cell *matCellDef="let element" class="text-left"> {{element.requestNo}} </td>
      </ng-container>

      <ng-container matColumnDef="docType">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by number"
          class="text-left"> ประเภทเอกสาร
        </th>
        <td mat-cell *matCellDef="let element" class="text-left"> {{element.docType}} </td>
      </ng-container>

      <ng-container matColumnDef="verifyPersonName">
        <th mat-header-cell *matHeaderCellDef class="text-left"> คนที่กำลังตรวจ </th>
        <td mat-cell *matCellDef="let element"> {{element.verifyPersonName}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="expressType">
        <th mat-header-cell *matHeaderCellDef class="text-left"> ประเภทงานด่วน </th>
        <td mat-cell *matCellDef="let element" class="text-left"> {{element.expressType === 'Y' ? 'เร่งด่วน' : 'ไม่เร่งด่วน'}} </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef class="text-left"> สถานะ </th>
        <td mat-cell *matCellDef="let element" class="text-left"> {{element.status === 'W' ? 'รอตรวจโรงงาน' : 'กำลังตรวจสอบ'}} </td>
      </ng-container>

      <ng-container matColumnDef="getTaskDate">
        <th mat-header-cell *matHeaderCellDef class="text-left"> เวลาที่ get งาน </th>
        <td mat-cell *matCellDef="let element" class="text-left"> {{ datePipe.transform(element.getTaskDate, 'yyyy-MM-dd') }}<br>
          {{ datePipe.transform(element.getTaskDate, 'HH:mm') }} </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="invoice">
        <th mat-header-cell *matHeaderCellDef> Invoice </th>
        <td mat-cell *matCellDef="let element"> {{element.invoice}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]" (page)="_page($event)" showFirstLastButtons
      aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>
</div>